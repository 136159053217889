import React, { Component } from 'react';
import { Button, Intent } from "@blueprintjs/core";
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Toast from 'helpers/toaster';
import FooterIllustration from 'components/FooterIllustration';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import { Helmet } from 'react-helmet';
import { TopNavbar } from 'components/Styles';
import MainSiteLogo from 'components/MainSiteLogo';
import * as EmailValidator from 'email-validator';

@inject('viewStore') @observer
class Login extends Component {

  @observable loggingIn = false;
  @observable isDisabled = true;
  @observable user = {
    email: '',
    password: ''
  }

  validateEmailAndPassword() {
    if(EmailValidator.validate(this.user.email) && this.user.password.length > 0) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  onLogin() {
    const {
      props: {
        viewStore
      }
    } = this;
    this.loggingIn = true;
    viewStore.performLogin(this.user)
      .then((response) => {
        this.loggingIn = false;
        Toast.show({ icon: 'thumbs-up', message: 'Logged in successfully!', intent: Intent.SUCCESS })
      })
      .catch((error) => {
        this.loggingIn = false;
        if (error.response.data) {
          Toast.show({ icon: 'warning-sign', message: error.response.data.error, intent: Intent.DANGER })
        } else {
          Toast.show({ icon: 'warning-sign', message: 'Unable to login, please ensure your internet is working fine.', intent: Intent.DANGER })
        }
      })
  }

  
  _handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.onLogin();
    }
  }

  render() {
    const {
      props: {
        viewStore
      }
    } = this;
    return (
      
      <UnauthenticatedPage>
        <Helmet>
          <title>Login | FieldNexus</title>
        </Helmet>

        <TopNavbar>
          <div className="tp-nav">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-8">
                  <MainSiteLogo />
                </div>
                <div className="col-4 text-right">
                  <div className="right-link-tp">
                    <a onClick={() => viewStore.signup()} >
                        Signup
                    </a>
                  </div>
                </div>
              </div>
            </div>      
          </div>
        </TopNavbar>
        <div className="form-wrap" style={{marginTop:'100px'}}>
          <p>Welcome to Field Nexus!</p>
          <h3 className="login-heading">Log in and run your business.</h3>
          <div className="row input-wrap">
            <div className="col-sm-6">
              <input 
                type="text"
                className="form-control"
                placeholder="Email"
                value={this.user.email}
                onChange={e => { this.user.email = e.target.value; this.validateEmailAndPassword() }} 
                onKeyPress={this._handleKeyPress.bind(this)}
                required 
              />
            </div>
            <div className="col-sm-6">
              <input 
                type="password"
                className="form-control"
                placeholder="Password"
                value={this.user.password}
                onChange={e => {this.user.password = e.target.value; this.validateEmailAndPassword()}}
                onKeyPress={this._handleKeyPress.bind(this)}
                required
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-6 col-5 fg-pass">
              <a onClick={() => { viewStore.forgetPassword() } }>Forgot Password?</a>         
            </div>
            <div className="col-sm-6 col-7 fg-next">
              <Button
                className="btn-gray"
                loading={this.loggingIn}
                onClick={this.onLogin.bind(this)} 
                disabled={this.isDisabled}
              >
                Log In
              </Button>
            </div>
          </div>
        </div>
        
        <FooterIllustration />
      </UnauthenticatedPage>
    )
  }
}

export default Login;