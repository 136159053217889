import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PaymentGrid } from 'components/Styles';
import styled from '@emotion/styled';

const PaymentWrapper = styled('div')`
  display: flex;
  width: 768px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`

@inject('paymentStore') @observer
class PaymentFooter extends Component {

  render() {
    return (
      <PaymentGrid>
        <PaymentWrapper>
          <div className="payment-footer">
            <div><h5><span style={{ color: 'rgba(2, 32, 71, 0.5)' }}>Powered by</span> <a target="_blank" href="https://fieldnexus.com"><img src="\fieldnexus-logo.png" /></a></h5></div>
            <div><p><a target="_blank" href="https://fieldnexus.com/terms_and_conditions" style={{ color: "#91A4B9" }}> Terms & Conditions</a> | <a target="_blank" href="https://fieldnexus.com/privacy_policy  " style={{ color: "#91A4B9" }}>Privacy Policy</a></p></div>
          </div>
        </PaymentWrapper>
      </PaymentGrid>
    )
  }

};

export default PaymentFooter;