import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import { 
  Spinner,
  Dialog,
  Classes,
  Button, Icon
} from '@blueprintjs/core';
import PDF from 'react-pdf-js';

@inject('viewStore', 'workReportStore') @observer
export class PDFViewer extends React.Component {

  @observable loading = true;
  @observable page = 1;
  @observable pages = 0;

  componentWillMount() {
    this.url = this.props.workReportStore.workOrderPDFDownloadUrl.fullUrl;
  }

  renderTitle() {
    let {
      workReportStore: {
        workReport: {
          object: {
            attributes: {
              job: {
                event_number
              },
              kind,
              number
            }
          }
        }
      }
    } = this.props;
    return `PDF: Job #: ${event_number} | ${kind} #: ${number}`
  }

  @action
  handleClose() {
    this.props.workReportStore.showPdfViewer = false;
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  setPage = (page_number) => {
    this.page = page_number;
  }

  renderPagination = (page, pages) => {
    if (!pages) {
      return null;
    }
    let previousButton = (
      <li className="previous" onClick={() => this.setPage(page - 1)}>
        <a href="#" className="bp3-pdf-button">
          <Icon icon="caret-left" /> Previous
        </a>
      </li>
    );
    if (page === 1) {
      previousButton = (
        <li className="previous disabled">
          <a href="#">
            <Icon icon="caret-left" /> Previous
          </a>
        </li>
      );
    }
    let nextButton = (
      <li className="next" onClick={() => this.setPage(page + 1)}>
        <a href="#" className="bp3-pdf-button">
          Next <Icon icon="caret-right" />
        </a>
      </li>
    );
    if (page === pages) {
      nextButton = (
        <li className="next disabled">
          <a href="#">
            Next <Icon icon="caret-right" />
          </a>
        </li>
      );
    }
    return (
      <nav>
        <ul className="pager">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    );
  };

  render() {
    let { 
      workReportStore: {
        showPdfViewer
      }
    } = this.props;
    return(
      <Dialog
        iconName="eye-open"
        title={this.renderTitle.bind(this)()}
        canOutsideClickClose={false}
        canEscapeKeyClose={true}
        onClose={this.handleClose.bind(this)}
        isOpen={showPdfViewer}
        style={{width: 635}}
        onOpened={() => { this.loading = true; }}
      >
        <div className={Classes.DIALOG_BODY}>
          {
            this.loading && <div>
              <Spinner />
            </div>
          }
          {
            this.url && <PDF
              file={this.url}
              onDocumentComplete={(pages) => { 
                this.loading = false; 
                this.pages = pages;
              }}
              page={this.page}
            />
          }
        </div>
        {
          this.renderPagination(this.page, this.pages)
        }
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleClose.bind(this)}>Close</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default PDFViewer;