import React from 'react';
import {
  Spinner, Icon, Button, Tag
} from '@blueprintjs/core';
import Avatar from '@atlaskit/avatar';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import qs from 'qs';
import { formatTimeOnly, formatDateOnly, getDaysDifferenceFrom } from 'helpers/time';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';

@inject('viewStore', 'jobStore') @observer
class ClientJobsTable extends React.Component {

  @observable jobs = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    this.props.viewStore.clientJobSearch.where.client_id = this.props.client.attributes.id;
    this.props.jobStore.loadClientJobs();
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        clientJobSearch
      }
    } = this.props;
    this.props.viewStore.clientJobSearch = {
      ...clientJobSearch,
      page: newPage
    };
    this.props.jobStore.loadClientJobs();
  }

  render() {
    let {
      props: {
        jobStore: {
          clientJobs: {
            objects,
            isLoading,
            meta
          }
        },
        viewStore: {
          jobTab: {
            section
          }
        },
      }
    } = this;
    let { viewStore } = this.props;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No jobs have been created for this client yet.
        </div>
      )
    }
    return(
      <div className="card rounded-0 table-responsive">

        <table className='table table-data-presentation table-vertical-align table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Job #</th>
              <th>Job Title</th>
              <th>Technicians</th>
              <th>Start - End Time</th>
              <th>PO#</th>
              <th>Status</th>
              <th className="item-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((job) => {
                return(                  
                  <tr key={job.id} >                    
                    <td>
                      <TableCellWithSubtext
                        mainText={job.attributes.event_number}
                        mainLink={`/dashboard/jobs/${job.attributes.id}/${section}`}
                      />
                      <Tag style={{backgroundColor: `#${job.attributes.service_type_color}`}}>
                        {job.attributes && job.attributes.service_type_name}
                      </Tag>
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={job.attributes.title}
                      />                 
                    </td>
                    <td>
                      { job.attributes.assignments && job.attributes.assignments.map((task) => {
                          return (
                            <div style={{display:'flex',alignItems:'center'}} key={Math.random()}>
                              <span className="docs-color-bubble" style={{marginRight:'5px', backgroundColor: task.color}}></span>
                              <TableCellWithSubtext
                                mainText={task.name}
                              />
                            </div>
                          ) 
                        })
                      }
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(job.attributes.start_date)}
                        subText={`${formatTimeOnly(job.attributes.start_date)} - ${formatTimeOnly(job.attributes.end_date)}${getDaysDifferenceFrom(job.attributes.start_date, job.attributes.end_date)}`}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={job.attributes.po_number || 'N/A'}
                      />
                    </td>
                    <td>
                      <Tag style={{backgroundColor: job.attributes.status === 'invoiced' ? '#449a61' : '#868f95'}}>
                        {job.attributes && job.attributes.status === 'invoiced' ? 'Invoiced' : 'Not Invoiced'}
                      </Tag>
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <a className="bp3-button bp3-minimal bp3-small" href={`/dashboard/jobs/${job.id}/${section}`}>
                          <img src="/view-icon.svg"/>
                        </a>
                      </div>
                    </td>                    
                  </tr>                  
                )
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </div>
    )
  }
};

export default ClientJobsTable;