import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import CustomItem from './CustomItem';
import _ from 'lodash';
import { currencyFormattedAmount, currencySymbol } from 'helpers/currency';

@inject('store', 'quoteStore') @observer
class QuoteCustomLineItems extends Component { 

  calculateSubTotal(custom_line_items) {
    if(custom_line_items.length > 0) {
      let total = 0;
      total = _.sumBy(custom_line_items, function(custom) {
                let taxTotal = _.sumBy(custom.tax_line_items, function(taxLineItem) {
                              return (taxLineItem.percentage_value/100) * custom.total
                          });
                return custom.total + taxTotal;
              })
      return currencyFormattedAmount(total)
    } else {
      return currencyFormattedAmount(0)
    }
  }

  refreshLineItems() {
    this.forceUpdate()
  }

  calculateSubTotalBeforeMarkup(custom_line_items) {
    if(custom_line_items.length > 0) {
      var sum = 0;
      _.each(custom_line_items, function(line_item) {
        sum += parseFloat(line_item.quantity) * parseFloat(line_item.rate_per_unit)
      })
      return currencyFormattedAmount(sum)
    } else {
      return currencyFormattedAmount(0)
    }
  }

  render() {
    const {
      quoteStore,
      quoteStore: {
        quote: {
          object
        },
        showCustomLineItemsLoader
      }
    } = this.props;
    return (
      <React.Fragment>
        <div className="table-title" style={{ paddingLeft: '10px' }}>
          <h5>
            <strong>Others <Button icon='add' className="add-btn bp3-minimal" onClick={() => {quoteStore.addBlankLineItem('custom', object.attributes.id)} } loading={showCustomLineItemsLoader}>Add Others</Button></strong>
          </h5>
        </div>

          {
            object.attributes.custom_line_items.length > 0 && <div className="wr-row">
              <div style={{ flex: 1 }}>
                <table className="table table-bordered border-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: 450}}>Description</th>
                      <th scope="col" style={{ width: 120}}>Quantity</th>
                      <th scope="col" style={{ width: 150 }}>Rate({currencySymbol()})</th>
                      <th scope="col" style={{ width: 120 }}>Markup</th>
                      <th scope="col">Total</th>
                      <th scope="col" style={{ width: 62 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    
                        {
                          object.attributes.custom_line_items.map((lineItem) => {
                            return (
                              <CustomItem lineItem={lineItem} key={lineItem.id} refresh={this.refreshLineItems.bind(this)} />
                            )
                          })
                        }
                     
                  </tbody>
                </table>
              </div>
            </div>
          }
        {object.attributes.custom_line_items.length > 0 && <div className="lineitem_sub_total">
          <p><span style={{ marginRight: 25 }}>Sub Total Before markup: {this.calculateSubTotalBeforeMarkup(object.attributes.custom_line_items)}</span> Sub Total: {this.calculateSubTotal(object.attributes.custom_line_items)}</p>
        </div>}
      </React.Fragment>
    )
  }
}

export default QuoteCustomLineItems;