import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { 
  Button, Intent
} from "@blueprintjs/core";
import QuoteHeader from 'components/Quote/Header';
import QuoteCustomerDetails from 'components/Quote/CustomerDetails';
import QuoteDescription from 'components/Quote/Description';
import QuoteLabourLineItems from 'components/Quote/LabourLineItems';
import QuoteMaterialLineItems from 'components/Quote/MaterialLineItems';
import QuoteCustomLineItems from 'components/Quote/CustomLineItems';
import QuoteEquipments from 'components/Quote/Equipments';
import ProposalCoverEditor from 'components/Quote/ProposalCoverEditor';
import QuoteImages from 'components/Quote/Images';
import {
  ColumnContainer,
  Column,
  Card
} from 'components/Styles';

import Toast from 'helpers/toaster';
import _ from 'lodash';
import QuoteSignatureSection from 'components/Quote/SignatureSection';
import { currencyFormattedAmount, currency } from 'helpers/currency';
import UpdateClientModal from './UpdateClientModal';

@inject('store', 'quoteStore', 'viewStore') @observer
class QuoteShow extends Component {

  calculateGrandTotal() {    

    const {
      quoteStore: {
        quote: {
          object
        }
      } 
    } = this.props;
    let total = 0.0;
    
    if(object.attributes.labour.length > 0) {  
      total = total + _.sumBy(object.attributes.labour, function(labour) {
        let taxTotal = _.sumBy(labour.tax_line_items, function(taxLineItem) {
                      return (taxLineItem.percentage_value/100) * labour.total
                  });
        return labour.total + taxTotal;   
      })           
    } 
    if(object.attributes.materials.length > 0) {
      total = total + _.sumBy(object.attributes.materials, function(material) {
        let taxTotal = _.sumBy(material.tax_line_items, function(taxLineItem) {
                      return (taxLineItem.percentage_value/100) * material.total
                  });
        return material.total + taxTotal;   
      })
    } 
    if(object.attributes.custom_line_items.length > 0) {
      total = total + _.sumBy(object.attributes.custom_line_items, function(line_item) {
        let taxTotal = _.sumBy(line_item.tax_line_items, function(taxLineItem) {
                      return (taxLineItem.percentage_value/100) * line_item.total
                  });
        return line_item.total + taxTotal;   
      })
    } 
    return currencyFormattedAmount(total)
  }

  updateQuote() {
    this.props.quoteStore.update()
      .then(this.onUpdateSuccessfully.bind(this))
      .catch(this.onFailUpdate.bind(this))

  }

  onUpdateSuccessfully(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Updated successfully!', intent: Intent.SUCCESS })
  }

  onFailUpdate(error) {
    Toast.show({ icon: 'warning-sign', message: 'Unable to update!', intent: Intent.DANGER })
  }

  render() {
    const {
      quoteStore: {
        quote: {
          object,
          isLoading
        }
      },
      store
    } = this.props;
    return (
      <div  className="pdf-view-layout">
        <ColumnContainer>
          <Column>
            <Card>
              {/* Header: Logo + Tenant Details */}
              <QuoteHeader />

              {/* Customer Details */}
              <QuoteCustomerDetails selectedTechnician={[{ id: object.attributes.created_by.id, label: object.attributes.created_by.full_name, value: object.attributes.created_by.id, selected: true }]} selectedJob={[{ id: object.attributes.job.id, label: object.attributes.job.number, value: object.attributes.job.id, selected: true }]} />
              
              {/* editor */}
              <ProposalCoverEditor />

              {/* Equipments */}
              <QuoteEquipments />

              {/* Work Report Images */}
              <QuoteImages />

              {/* Labor Line Items */}
              <QuoteLabourLineItems />

              <div style={{marginTop: 40}}></div>

              {/* Material Line Items */}
              <QuoteMaterialLineItems />

              <div style={{marginTop: 40}}></div>

              {/* Custom Line Items */}
              <QuoteCustomLineItems />

              <div style={{marginTop: 45}}></div>

              {/* Signatures */}
              <QuoteSignatureSection />

              {/* Reason For Call/Description */}
              <QuoteDescription />

              <div style={{marginTop: 45}}></div>

              <div style={{marginTop: 0, clear: 'both'}}></div>
              <br/>
            </Card>

            <div className="invoice-save">
              <div className="lineitem_grand_total" style={{display: 'inline-block'}}>
                <h5 style={{marginBottom: 10}}>Grand Total ({currency()}): {this.calculateGrandTotal()}</h5>
              </div>
              <Button 
                large={true}                
                loading={isLoading} 
                onClick={this.updateQuote.bind(this)}
              >
                Save
              </Button>
            </div>
          </Column>
        </ColumnContainer>
        <UpdateClientModal
          isOpen={store.isOpenUpdateClientDialog}
          onClose={store.handleCloseUpdateClientDailog.bind(store)}
        />
      </div>
    )
  }
}

export default QuoteShow;