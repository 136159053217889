import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { currencyFormattedAmount, currency } from 'helpers/currency';
import numeral from 'numeral';

@inject('store', 'invoiceStore') @observer
class InvoiceGrandTotal extends Component {

  formatNumber(total){
    return currencyFormattedAmount(total)
  }

  render() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    return(
      <React.Fragment>
        <div className="payment-padding">
          <table style={{width: '100%'}}>
            <tbody>
              <tr>
                <td width="50%" className="td-mobile-d-none"></td>
                <td>
                  <table style={{width: '100%'}}>
                    <tbody>
                      <tr>
                        <td align="right">
                            <div style={{ marginBottom: 10, marginRight: 0, color: "#91A4B9"}}>
                            Sub Total:
                          </div>
                        </td>
                        <td width="21%" align="right">
                          <div style={{marginBottom: 10}}>
                            { 
                              this.formatNumber(object.attributes.subtotal)
                            }
                          </div>
                        </td>
                      </tr>
                      {
                        this.props.invoiceStore.invoice.object.attributes.taxes.length > 0 && this.props.invoiceStore.invoice.object.attributes.taxes.map((obj) => {
                          if (obj.total > 0) {
                            return (
                              <tr key={Math.random()}>
                                <td width="67%" align="right">
                                  <div style={{ marginBottom: 10, marginRight: 0, color: "#91A4B9"}}>
                                    {obj.name} {obj.tax_percentage_value}%:
                                  </div>
                                </td>
                                <td align="right">
                                  <div style={{marginBottom: 10}}>
                                    {this.formatNumber(obj.total) }
                                  </div>
                                </td>
                              </tr>
                            )
                          } else {
                            return null;
                          }
                        })
                      }
                      <tr>
                        <td width="67%" align="right">
                            <div style={{ marginTop: 10, marginBottom: 10, marginRight: 0, color: "#91A4B9"}}>
                            Total:
                          </div>
                        </td>
                        <td align="right">
                          <div style={{marginTop: 10, marginBottom: 10}}>
                            { this.formatNumber(object.attributes.grand_total) }
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td width="67%" align="right">
                            <div style={{ marginTop: 10, marginBottom: 10, marginRight: 0, color: "#91A4B9"}}>
                            Amount Due ({ object.attributes.currency } { object.attributes.currency_symbol }):
                          </div>
                        </td>
                        <td align="right">
                          <div style={{ marginTop: 10, marginBottom: 10, fontSize: 16, fontWeight: 700, color: "#1800FF"}}>
                            { this.formatNumber(object.attributes.due_amount) }
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          </div> 
          <div style={{height: 40}}></div>
      </React.Fragment>
    )
  }

};

export default InvoiceGrandTotal;