import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import Card from 'components/Card';
import { 
  ButtonGroup, Button, NonIdealState, Spinner
} from "@blueprintjs/core";
import PaymentMethodTable from 'components/Tables/PaymentMethod';
import PaymentMethodModal from 'components/PaymentMethod/PaymentMethodModal';
import SettingsSidebar from '../preferences/settings-sidebar';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'paymentMethodStore','quickbooksStore','viewStore','xeroStore') @observer
class PaymentMethodIndexPage extends Component {
  @observable isPaymentMethodDialogVisible = false;

  componentDidMount() {
    let { paymentMethodStore, xeroStore } = this.props;
    paymentMethodStore.loadPaymentMethods();
    //xeroStore.loadXeroPaymentMethods()
  }

  get canRender() {
    let {
      paymentMethodStore: {
        paymentMethods: {
          isLoading,
          isError,
          objects
        }
      }
    } = this.props;
    return (!isLoading && !isError && objects);
  }
  
  onClosePaymentMethodDialog() {
    this.isPaymentMethodDialogVisible = false;
  }

  onSucessCreatePaymentMethod() {
    this.isPaymentMethodDialogVisible = false;
  }

  openPaymentMethodModal() {
    this.isPaymentMethodDialogVisible = true;
    this.props.paymentMethodStore.paymentMethod.object = {}
    this.props.quickbooksStore.loadQuickbooksPaymentMethods();
  }

  render() {
    let content;
    let { currentUser } = this.props.viewStore;
    let {
      paymentMethodStore: {
        paymentMethods: {
          isLoading,
          isError
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      content = <PaymentMethodTable />;
    }
    let domain = '';
    if (currentUser && currentUser.attributes.email) {
       domain = currentUser.attributes.email.substring(currentUser.attributes.email.lastIndexOf("@") +1);
    }
    return(
      <div className="taxed-page-layout">
        <Helmet>
          <title>Payment Methods | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <SettingsSidebar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
            <Card>
              
              <div>            
                <div className="text-right mb-2">
                  {/* { domain === 'metawarelabs.com' ?
                    <ButtonGroup>
                      <Button icon='add' onClick={this.openPaymentMethodModal.bind(this)}>
                        Add
                      </Button>
                    </ButtonGroup>
                    :''
                  } */}
                </div>
              </div>
            
              {content}
          
            </Card>
            </PageWrapGray>
          </div>
        </div>
        <PaymentMethodModal 
          isOpen={this.isPaymentMethodDialogVisible} 
          title={"Add Payment Method"}
          edit={false}
          onClose={this.onClosePaymentMethodDialog.bind(this)}
          onSuccess={this.onSucessCreatePaymentMethod.bind(this)}
        />
      </div>
    )
  }

};

export default PaymentMethodIndexPage;