import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup, Button, Dialog, Classes, Intent
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';

@inject('formStore') @observer
class AddFormModal extends Component {

  createForm() {
    this.props.formStore.createForm()
      .then(this.onSuccessfulCreateForm.bind(this))
      .catch(this.onFailCreateForm.bind(this))
  }

  onSuccessfulCreateForm(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Form saved successfully. Lets add some contents to form.', intent: Intent.SUCCESS })
    this.props.onSuccess()
  }

  onFailCreateForm(error) {
    console.log("Error in create form: " + error)
  }

  handleCloseFormDailog() {
    this.props.onClose()
    this.props.formStore.form.object = {}
  }

  render() {
    let { object } = this.props.formStore.form;
    return(
      <Dialog
        title="Add Form"
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Name"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Name'
              onChange={(event) => { object.name = event.target.value } } 
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseFormDailog.bind(this)}>Close</Button>
            <Button intent={Intent.PRIMARY} loading={this.props.formStore.form.isLoading} onClick={this.createForm.bind(this)}>Save</Button>
          </div>
        </div>
      </Dialog>
    )
  }

};

export default AddFormModal;