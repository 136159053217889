import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Helmet } from 'react-helmet';
import {
  Tab, Tabs
} from "@blueprintjs/core";
import Card from 'components/Card';
import styled from '@emotion/styled';
import BillingOverview from 'components/Billing/Overview';
import BillingHistory from 'components/Billing/History';
import BillingCreditCard from 'components/Billing/CreditCard';
import SettingsSidebar from '../preferences/settings-sidebar';

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;  
  min-height: calc(100vh - 75px);
`

@inject('store', 'viewStore', 'billingStore') @observer
class BillingIndexPage extends Component {

  @observable selectedTabId;

  componentDidMount() {
    let { billingInfo } = this.props.viewStore;
    this.selectedTabId = billingInfo.section
  }

  handleTabChange = (tabId) => {
    this.selectedTabId = tabId;
    let viewStore = this.props.viewStore;
    viewStore.billingInfo = { section: tabId };
    viewStore.loadBillingOverview();
  }

  render() {
    const {
      selectedTabId,
      props: {
        viewStore: {
          billingInfo: {
            section
          }
        }
      }
    } = this;
    return (
      <div className="billing-page-layout">
        <Helmet>
          <title>Billing | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <SettingsSidebar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
              <Card>
                <div style={{display : 'inline-block', marginBottom:'15px'}}>
                  <Tabs onChange={this.handleTabChange} selectedTabId={section} large={true}>
                    <Tab id="overview" title="Overview" />
                    <Tab id="billing_history" title="Billing History" />
                    <Tab id="credit_card" title="Credit Card" />
                  </Tabs>
                </div>
                {
                  selectedTabId === 'overview' &&
                    <BillingOverview />
                }

                {
                  selectedTabId === 'billing_history' &&
                    <BillingHistory />
                }

                {
                  selectedTabId === 'credit_card' &&
                    <BillingCreditCard />
                }
              </Card>
            </PageWrapGray>
        </div>
        </div>
      </div>
    )
  }
};

export default BillingIndexPage;