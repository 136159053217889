import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { 
  Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import Toast from 'helpers/toaster';
import ServiceTypeCategory from 'components/ServiceTypeCategory/ServiceTypeCategory';


@inject('store', 'serviceTypeCategoryStore') @observer
class ServiceTypeCategoryTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable isCategoryDialogVisible = false;
  @observable category;

  handleCategoryClick(event, serviceTypeCategory) {
    switch (event.label) {
      case 'Edit':
        this.category = serviceTypeCategory;
        this.props.serviceTypeCategoryStore.serviceTypeCategory.object = serviceTypeCategory.attributes;
        this.isCategoryDialogVisible = true;
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.category = serviceTypeCategory;
        break;
      default:
    }
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.serviceTypeCategoryStore.delete(this.category)
      .then(this.onSuccessfulDeleteCategory.bind(this))
      .catch(this.onFailedCategoryDelete.bind(this))
  }

  onSuccessfulDeleteCategory(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Service type category deleted successfully!', intent: Intent.SUCCESS })
    this.category = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedCategoryDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete service type category!', intent: Intent.DANGER })
  }

  onCloseCategoryDialog() {
    this.isCategoryDialogVisible = false;
  }

  onSucessCreateCategory() {
    this.isCategoryDialogVisible = false;
  }

  render() {
    let {
      serviceTypeCategoryStore: {
        serviceTypeCategories: {
          objects
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning" style={{marginTop:'15px'}}>
          <h4 className="bp3-heading">No Results</h4>
        </div>
      )
    }
    return(
      <React.Fragment>
         <div className="card rounded-0 table-responsive">
        <table className="table table-data-presentation table-hover" style={{ width: '100%'}}>
          <thead>
            <tr>
              <th>Name</th>
              <th className="item-center" width="15%">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((serviceTypeCategory) => {
                return(
                  <tr key={serviceTypeCategory.id}>
                    <td>{serviceTypeCategory.attributes.name}</td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit" minimal={true} small={true}  onClick={() => this.handleCategoryClick({ label: 'Edit' },  serviceTypeCategory)}>
                          <img src="/edit-light-icon.svg" alt={'edit icon'}/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleCategoryClick({ label: 'Delete' }, serviceTypeCategory)}>
                          <img src="/delete-light-icon.svg" alt={'delete icon'}/>
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to do this?
          </p>
        </Alert>
        <ServiceTypeCategory 
          isOpen={this.isCategoryDialogVisible} 
          title={"Update Service Type Category"}
          edit={true}
          onClose={this.onCloseCategoryDialog.bind(this)}
          onSuccess={this.onSucessCreateCategory.bind(this)}
        />
      </React.Fragment>
    )
  }

};

export default ServiceTypeCategoryTable;