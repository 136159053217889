import React from 'react';
import Avatar from '@atlaskit/avatar';
import {
  Icon, Spinner
} from '@blueprintjs/core';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject('viewStore', 'jobStore') @observer
class WorkOrdersTable extends React.Component {

  @observable workReports = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    let { job } = this.props;
    this.props.jobStore.loadJobContents(job)
  }

  render() {
    let { 
      jobStore: {
        jobWorkReports,
        jobAssociations: {
          isLoading
        }
      } 
    } = this.props
    const {
      viewStore
    } = this.props;
    if (isLoading) {
      return <div className="spinner-center"><Spinner size='25' /></div>
    }
    if (jobWorkReports.length === 0) {
      return (
        <div className="no-data-found">
          <img src="/noWorkorder.svg" />
          <h3>No Workorder</h3>
          <p>No work orders have been created for this job yet.</p>
          <button className="add-new-item-btn" onClick={this.props.handleWorkReportClick}>Add Work order</button>
        </div>
      )
    }
    return (
      <div className="">
          {
            jobWorkReports.map((workReport) => {
              return (
                <div className="tabs-data-card" key={workReport.id} onClick={() => {viewStore.ShowWorkOrder({ uuid: workReport.attributes.id})}}>
                  <div className="card-style">
                    <div width="10px" className="customer-avatar">
                      <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=${workReport.attributes.created_by.full_name}&size=64`)} size='large' />
                    </div>
                    <div className="job-number" width="150px">
                      <p className="secondary">#{workReport.attributes.number}</p>
                      <p className="primary">By {workReport.attributes.created_by.full_name}</p>
                    </div>
                  </div>
                  <div className="job-details-status">
                    <div className="status-alignment">
                      <span className='bp3-tag bp3-minimal bp3-intent-primary'>
                        {workReport.attributes.status}
                      </span>
                    </div>
                    <div className="job-date-time">
                      {/* <p className='time-entry primary'>{this.calculateTotal(workReport)}</p> */}
                      <p className='secondary'>{workReport.attributes.formatted_created_at}</p>
                    </div>
                  </div>
                  {/* <div>
                    <Icon icon="caret-right" />
                  </div> */}
                </div>
              )
            })
          }
        </div>
    )
  }
}

export default WorkOrdersTable;