export const isFileTypeSupported = (file) => {
  switch (file.type) {
    case "image":
    case "doc":
    case "docx":
    case "pdf":
    case "xls":
    case "xlsx":
      return true;
    default:
      return false;
  }
};

export const fileExtensionFromUri = (uri) => {
  const extension = uri
    .substring(uri.lastIndexOf("/") + 1, uri.length)
    .split(".")
    .pop();
  return extension;
};

export const fullSrcFromFile = (file) => {
  if (file.thumbnail) {
    return file.src;
  }
  return thumbnailSrcFromFile(file);
};

export const thumbnailSrcFromFile = (file) => {
  if (file.thumbnail) {
    return file.thumbnail;
  }
  const extension = fileExtensionFromUri(file.src);
  switch (extension) {
    case "xls":
    case "xlsx":
      return require("../images/xls.png");
    case "doc":
    case "docx":
      return require("../images/doc.png");
    default:
      return require("../images/pdf.png");
  }
};
