import React, { Component } from 'react'
import { 
  Button,
  Popover, Position
} from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import SearchDateRangePicker from 'components/SearchDateRangePicker';
import moment from 'moment-timezone';
import TechnicianPicker from 'components/TechnicianPicker';
import NewVacationModal from 'components/User/NewVacationModal';


@inject('store', 'userStore', 'viewStore') @observer
class Vacations extends Component {

  @observable isOpenVacationModal = false;

  @action
  onDateRangeChange(range) {
    let {
      props: {
        viewStore: {
          vacationsSearch
        }
      }
    } = this;
    this.props.viewStore.vacationsSearch = {
      ...vacationsSearch,
      where: {
        ...vacationsSearch.where,
        start_date: { 
          gte: moment(range[0]).startOf('day').unix()
        },
        end_date: {
          lte: moment(range[1]).endOf('day').unix()
        }
      }
    }
    this.search()
  }

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.vacationsSearch.page = 1;
    viewStore.usersVacations();
  }

  onTechnicianChange(technicians) {
    let selectedValues = technicians.map((status) => status.value);
    let {
      props: {
        viewStore: {
          vacationsSearch
        }
      }
    } = this;
    this.props.viewStore.vacationsSearch = {
      ...vacationsSearch,
      where: {
        ...vacationsSearch.where,
        user_id: selectedValues
      }
    }
  }

  handleCloseVacationModal() {
    this.isOpenVacationModal = false;
  }

  render() {
    const {
      where
    } = this.props.viewStore.vacationsSearch;
    let startDate = moment.unix(parseInt(where.start_date.gte)).toDate();
    let endDate = moment.unix(parseInt(where.end_date.lte)).toDate();
    return(
      <nav className="bp3-navbar search bg-transparent">
        <div>
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <Popover
                  minimal={true}
                  content={
                    <SearchDateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onChange={this.onDateRangeChange.bind(this)}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                  usePortal={true} >
                  <Button rightIcon='caret-down'>
                    {(!startDate || !endDate) && `Date Range`}
                    {startDate && endDate && `${startDate.toDateString()} - ${endDate.toDateString()}`}
                  </Button>
                </Popover>

                <TechnicianPicker
                  selectedOptions={(where && where.user_id) || []}
                  onChange={this.onTechnicianChange.bind(this)}
                  showUser
                  includeAdmins
                />

                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
              </div>
            </div>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            <Button icon='plus' onClick={() => { this.isOpenVacationModal = true }}>
              Add Vacation
            </Button>
          </div>
        </div>
        <NewVacationModal
          isOpen={this.isOpenVacationModal}
          onClose={this.handleCloseVacationModal.bind(this)}
        />
      </nav>
    )
  }
};

export default Vacations;