import React, { Component }  from 'react';
import ReactDOMServer from 'react-dom/server';

const redCar = (user) => {
  const svg = (
    <svg width="55" height="52" viewBox="0 0 55 52" transfor={`rotate(${user.attributes.heading})`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0388 19.0894C17.8471 14.7314 17.8643 14.8289 17.866 14.8512C17.8578 14.7619 17.5144 11.1521 18.0839 7.69644C18.6605 4.1968 22.5849 3.20197 22.5849 3.20197L22.7872 3.19213L26.9281 3.00132L26.9282 3.02217L31.0314 3.14445L31.2332 3.15175C31.2332 3.15175 35.174 4.08261 35.8074 7.57241C36.4331 11.0183 36.1484 14.6325 36.141 14.7219C36.1423 14.7011 36.1578 14.6019 36.037 18.9617C35.9092 23.5599 36.4568 34.8922 36.4055 36.2703C36.3542 37.647 36.5431 40.5625 36.2748 41.3347C36.0057 42.1077 35.1014 45.858 35.1014 45.858C35.1014 45.858 33.6113 48.7663 27.2562 48.386L27.2561 48.3689C20.9436 48.8373 19.4125 45.9662 19.4125 45.9662C19.4125 45.9662 18.4466 42.2311 18.165 41.4619C17.8841 40.6949 18.0256 37.7759 17.9526 36.4003C17.8789 35.0247 18.2414 23.6842 18.0388 19.0894V19.0894Z" fill="#D14C32"/>
      <path d="M21.8176 15.0983C23.2922 14.3155 27.0201 14.2301 27.0201 14.2301L27.0201 14.2242C27.0201 14.2242 30.7489 14.249 32.2361 15.0076C33.7233 15.7656 34.271 16.1283 34.1732 16.6429C34.0747 17.1568 33.0105 22.2846 33.0115 22.412C33.0125 22.5416 32.9101 22.7621 32.3313 22.6022C31.7532 22.4408 27.0856 22.2777 27.0856 22.2777L27.0856 22.2844C27.0856 22.2844 22.4213 22.5242 21.8451 22.6934C21.2697 22.8642 21.1645 22.6439 21.1634 22.5158C21.1624 22.3877 20.0142 17.2779 19.9073 16.7656C19.8012 16.2519 20.3429 15.8803 21.8176 15.0983Z" fill="#121215"/>
      <path d="M19.2005 17.6151C19.2005 17.6151 19.2662 23.5038 19.2626 25.2465C19.2589 26.9891 19.3137 29.3368 19.4547 30.9323C19.5971 32.5278 19.9479 39.6988 19.9479 39.6988L19.9491 39.8447C19.9491 39.8447 20.8449 37.3419 21.0131 35.4147C21.4011 33.5215 21.1801 28.8628 20.9367 26.0208C20.8222 23.197 19.3876 18.1081 19.2005 17.6151V17.6151Z" fill="#101114"/>
      <path d="M21.6701 37.8632C21.7704 37.2026 21.8635 37.394 21.8891 37.3386C21.9154 37.2841 22.5821 37.4566 22.8521 37.5237C25.0268 37.8918 27.2109 37.7772 27.2109 37.7772L27.2108 37.7705C27.2108 37.7705 29.3965 37.8495 31.5649 37.4461C31.8338 37.3754 32.4976 37.1898 32.5248 37.2447C32.5513 37.2996 32.6413 37.1067 32.7523 37.7664C32.8634 38.426 33.4488 42.4122 33.4089 42.4535C33.3698 42.4948 32.0605 43.2896 29.7335 43.6801C29.2202 43.7081 27.26 43.8164 27.26 43.8105L27.26 43.8164C27.2601 43.8239 25.2984 43.7467 24.7847 43.7263C22.4517 43.3744 21.1296 42.601 21.0898 42.5596C21.05 42.519 21.5705 38.5253 21.6701 37.8632V37.8632Z" fill="#131417"/>
      <path d="M19.3712 19.1062L18.7905 19.4482L18.795 20.0127L19.3785 20.008L19.3712 19.1062Z" fill="#0D0E13"/>
      <path d="M23.0113 3.56117C23.0616 3.52576 23.1171 3.49842 23.1758 3.48015C23.4342 3.40209 23.3257 3.4268 23.0113 3.56117Z" fill="#C2C6C1"/>
      <path d="M18.9485 7.76313C19.3334 5.31733 22.0798 3.96196 23.0113 3.56117C22.0773 4.20997 21.4399 7.81883 20.9562 10.1128C20.4438 12.5387 20.1801 16.4439 20.1801 16.4439C20.1801 16.4439 19.0484 16.6594 18.5412 19.728C18.1535 21.657 18.4333 11.0329 18.9485 7.76313V7.76313Z" fill="#D8684A"/>
      <path d="M30.6617 3.43938C30.7205 3.45671 30.7762 3.48314 30.8268 3.51773C30.5102 3.38849 30.4021 3.36479 30.6617 3.43938Z" fill="#C2C6C1"/>
      <path d="M34.9574 7.6523C35.5265 10.9132 35.9798 21.5322 35.5608 19.6097C35.0037 16.5498 33.8679 16.352 33.8679 16.352C33.8679 16.352 33.5408 12.4516 32.9897 10.0343C32.468 7.74851 31.7719 4.14975 30.8276 3.51772C31.7647 3.90182 34.5327 5.21309 34.9574 7.6523V7.6523Z" fill="#D8684A"/>
      <path d="M21.1367 28.8416L19.3382 27.5061L19.3512 29.1034L21.1479 30.217L21.1367 28.8416ZM33.2649 41.4061C33.3472 42.0079 33.4006 42.4402 33.3873 42.4537C33.3482 42.4949 32.0381 43.2898 29.7119 43.6803C29.1986 43.7083 27.2384 43.8166 27.2384 43.8106L27.2384 43.8166C27.2385 43.824 25.2768 43.7469 24.7624 43.7265C22.4301 43.3746 21.108 42.6012 21.0675 42.5598C21.0472 42.5391 21.1803 41.4284 21.3258 40.2811L33.2649 41.4061V41.4061Z" fill="#202226"/>
      <path d="M33.3156 20.9126L21.8741 18.3001L20.1394 17.7617C20.0274 17.2592 19.9462 16.8852 19.9207 16.7648C19.8146 16.2518 20.3563 15.8795 21.831 15.0975C23.3056 14.3147 27.0335 14.2293 27.0335 14.2293L27.0335 14.2233C27.0335 14.2233 30.7615 14.2481 32.2495 15.0068C33.7366 15.7647 34.2844 16.1274 34.1866 16.6421C34.1216 16.9807 33.639 19.3186 33.3156 20.9126V20.9126Z" fill="#212428"/>
      <path d="M34.8523 17.508C34.8523 17.508 34.8831 23.3969 34.9143 25.1393C34.9471 26.8824 34.9297 29.2299 34.8147 30.8282C34.699 32.4251 34.4641 39.6001 34.4641 39.6001L34.4653 39.7468C34.4653 39.7468 33.5289 37.2589 33.3294 35.3339C32.9107 33.4472 33.0559 28.7864 33.253 25.9415C33.3216 23.1147 34.6732 18.0047 34.8523 17.508V17.508Z" fill="#101114"/>
      <path d="M18.039 19.1029C17.8472 14.7449 17.8644 14.8423 17.8661 14.8646C17.8579 14.7753 17.5145 11.1655 18.084 7.70985C18.6606 4.21022 22.585 3.21539 22.585 3.21539L22.7874 3.20555L26.9282 3.01548L26.9284 3.03559L31.0315 3.15712L31.2333 3.16442C31.2333 3.16442 35.1741 4.09529 35.8075 7.58508C36.4332 11.031 36.1485 14.6452 36.1411 14.7346C36.1424 14.7137 36.1579 14.6153 36.0371 18.9759C35.9093 23.5725 36.4569 34.9064 36.4056 36.283C36.3543 37.6597 36.5432 40.5759 36.2749 41.3474C36.0058 42.1204 35.1015 45.8706 35.1015 45.8706C35.1015 45.8706 33.6114 48.7797 27.2563 48.3987L27.2562 48.3816C20.9437 48.8499 19.4126 45.9789 19.4126 45.9789C19.4126 45.9789 18.4467 42.2438 18.1651 41.4753C17.8842 40.7068 18.0257 37.7886 17.9527 36.4137C17.879 35.0381 18.2415 23.6968 18.039 19.1029V19.1029ZM18.4961 41.5448C19.3032 46.0691 20.4276 46.7943 21.6983 47.4631C22.9681 48.1312 27.2963 48.1869 27.2963 48.1869L27.2964 48.2055C27.7384 48.1915 31.6526 48.0405 32.841 47.3919C34.0999 46.7024 35.2124 45.9598 35.9458 41.423C36.6784 36.8848 35.5911 35.0579 35.6972 31.177C35.8034 27.2962 35.7072 17.3044 35.6579 16.1773C35.6078 15.0517 35.859 7.23167 34.7633 6.11382C33.6684 4.99374 32.1102 3.46039 31.1944 3.41198C30.671 3.38496 30.0167 3.37687 29.5508 3.37619L29.5506 3.34939L26.8894 3.37103L26.8893 3.35241L24.2697 3.37371L24.2699 3.39978C23.722 3.40806 23.1742 3.4289 22.6272 3.46229C21.713 3.52483 20.1785 5.08483 19.1019 6.22183C18.0246 7.35883 18.4037 15.1715 18.3719 16.2993C18.3401 17.427 18.4072 27.4183 18.5765 31.2962C18.7472 35.1762 17.6897 37.0191 18.4961 41.5448V41.5448Z" fill="#9E4A36"/>
      <path d="M34.8725 44.7063C34.8725 44.7063 34.1697 46.2349 33.0367 46.647C31.9036 47.0606 31.08 47.2781 31.08 47.2781C31.08 47.2781 32.5466 47.2475 33.3037 46.8102C34.0607 46.3728 34.9259 45.6866 34.8725 44.7063Z" fill="#9E4A36"/>
      <path d="M31.1951 3.4127C31.1951 3.4127 32.7872 4.16979 33.2678 4.68942C33.749 5.20755 34.4611 6.10212 34.4805 6.2025C34.4992 6.30288 34.4841 6.64259 34.4841 6.64259C34.4841 6.64259 31.6267 3.59165 31.1951 3.4127Z" fill="#FEFDF8"/>
      <path d="M26.9148 3.01484L26.9081 3.0149L26.908 3.00075L26.9148 3.01484ZM23.2758 3.59478C23.2886 3.4368 24.0912 3.19569 24.2895 3.15237C24.4834 3.11132 26.7727 3.02047 26.9088 3.01489L26.9097 3.0335C27.0601 3.03749 29.3364 3.09122 29.5295 3.12986C29.7292 3.1692 30.5356 3.39648 30.5503 3.55499C30.565 3.71349 30.4145 3.78993 30.2218 3.70883C30.0292 3.62774 29.7586 3.39535 29.5521 3.34937C29.3448 3.30414 26.9032 3.23984 26.9032 3.23984L26.9038 3.22122C26.7134 3.22947 24.4688 3.32517 24.2712 3.3737C24.0655 3.42229 23.7987 3.6583 23.6074 3.74326C23.4146 3.82675 23.2629 3.75202 23.2758 3.59478V3.59478Z" fill="#1D2021"/>
      <path d="M35.3762 19.3327C35.3762 19.3327 36.9761 19.9535 37.0772 20.5849C37.1784 21.2163 37.1648 21.2857 37.0829 21.2864C37.0011 21.287 35.3015 20.4065 35.3015 20.4065L35.2928 19.3334L35.3762 19.3327V19.3327Z" fill="#D14C32"/>
      <path d="M37.0707 21.431L37.0829 21.2864L35.3016 20.4065L35.3026 20.5308L37.0707 21.431Z" fill="#0D0E13"/>
      <path d="M34.7059 19.0016L35.2928 19.3334L35.2974 19.8971L34.7132 19.9019L34.7059 19.0016Z" fill="#0D0E13"/>
      <path d="M33.0996 28.7637L34.8762 27.4006L34.8892 28.9971L33.1108 30.1391L33.0996 28.7637Z" fill="#202226"/>
      <path d="M32.7673 37.773C32.6562 37.114 32.5655 37.3069 32.539 37.252C32.5125 37.1979 31.8494 37.3812 31.5798 37.4527C29.4115 37.8561 27.2258 37.7771 27.2258 37.7771L27.2257 37.7704C27.2257 37.7704 25.0408 37.8849 22.8669 37.5169C22.5962 37.4505 21.9302 37.2757 21.9046 37.3311C21.8783 37.3864 21.7844 37.195 21.6849 37.8564C21.5846 38.5178 21.0641 42.5129 21.1046 42.5535C21.1444 42.5942 22.4665 43.3676 24.7995 43.7202C25.314 43.7399 27.2749 43.8163 27.2748 43.8103L27.2749 43.8163C27.2749 43.8237 29.2351 43.7147 29.7484 43.686C32.0754 43.2962 33.3847 42.5013 33.4246 42.4593C33.4629 42.418 32.8776 38.4334 32.7673 37.773V37.773ZM32.1706 15.0141C30.6834 14.2554 26.9547 14.2321 26.9547 14.2321L26.9546 14.2247C26.9546 14.2247 23.2267 14.3101 21.7528 15.0929C20.2781 15.8749 19.7356 16.2465 19.8425 16.7595C19.9486 17.2717 21.0969 22.3815 21.0979 22.5089C21.0989 22.6384 21.2049 22.8573 21.7803 22.688C22.3557 22.5173 27.0201 22.2782 27.0201 22.2782L27.0201 22.2849C27.0201 22.2849 31.6885 22.4488 32.2666 22.6087C32.8455 22.77 32.9471 22.548 32.9461 22.42C32.945 22.2919 34.0092 17.1641 34.1078 16.6501C34.2063 16.1347 33.6578 15.7721 32.1706 15.0141V15.0141Z" fill="#343C3E"/>
      <path d="M32.8217 15.3603C33.8361 15.9091 34.2145 16.2345 34.1317 16.6686C34.0339 17.1817 32.969 22.3103 32.97 22.4391C32.9711 22.5672 32.8687 22.7877 32.2906 22.6279C32.1593 22.5909 31.8189 22.5542 31.3706 22.5192C32.0533 20.8052 32.6044 17.0124 32.8217 15.3603V15.3603ZM21.1558 15.447C20.1505 16.0108 19.7781 16.3429 19.8672 16.7764C19.9741 17.2886 21.1223 22.3985 21.1233 22.5265C21.1244 22.6546 21.2296 22.8749 21.805 22.7042C21.9357 22.6659 22.2762 22.6229 22.7239 22.582C22.0127 20.8757 21.4007 17.0938 21.1558 15.447Z" fill="#2E3436"/>
      <path d="M20.155 16.112C20.4445 15.8334 20.9793 15.5229 21.81 15.082C23.2847 14.2992 27.0126 14.2138 27.0126 14.2138L27.0126 14.2212L27.023 14.2212L27.0231 14.2241C27.3223 14.2277 30.7946 14.2821 32.2175 15.0078C33.056 15.4344 33.5958 15.7368 33.8897 16.0107L32.5809 17.0543C32.5809 17.0543 29.9014 16.6523 27.0106 16.6282L27.0105 16.6185C24.1377 16.6933 21.4862 16.9941 21.4862 16.9941L20.155 16.112Z" fill="#202226"/>
      <path d="M22.6287 3.46226C22.6287 3.46226 21.0484 4.2444 20.5771 4.77177C20.1043 5.29914 19.4054 6.20295 19.3891 6.30511C19.3713 6.40654 19.3927 6.74521 19.3927 6.74521C19.3927 6.74521 22.1986 3.64896 22.6287 3.46226V3.46226Z" fill="#FEFDF8"/>
      <path d="M24.6636 3.94765L29.6504 3.9071C29.6504 3.9071 30.6797 4.19215 31.0399 7.93215C31.3992 11.6707 32.0096 14.5284 29.6943 13.8859C27.379 13.2457 26.1373 13.8419 25.1105 13.778C24.0829 13.7111 22.516 14.8625 23.0602 9.75681C23.6059 4.65331 23.6764 4.35932 24.6636 3.94765V3.94765Z" fill="#D8684A"/>
      <path d="M29.8833 13.5098L28.9713 5.05873L29.6193 8.04424L29.8833 13.5098ZM24.1031 13.5367L24.8774 5.07192L24.2789 8.0683L24.1031 13.5367ZM19.6226 44.8094C19.6226 44.8094 20.3502 46.3272 21.4898 46.7223C22.6294 47.1159 23.4572 47.3199 23.4572 47.3199C23.4572 47.3199 21.9895 47.314 21.2255 46.8883C20.4622 46.464 19.5852 45.792 19.6226 44.8094Z" fill="#9E4A36"/>
      <path d="M32.5392 37.2714C32.5657 37.3263 32.6564 37.1327 32.7674 37.7923C32.8785 38.452 33.4639 42.4374 33.4248 42.4794C33.4056 42.4997 33.0901 42.6944 32.5086 42.9367L31.7063 37.439C32.0145 37.3568 32.5165 37.2232 32.5392 37.2714V37.2714ZM21.6843 37.8825C21.7839 37.2211 21.8777 37.4132 21.9041 37.3579C21.926 37.3093 22.4301 37.434 22.7396 37.5119L22.0268 43.0219C21.4414 42.7899 21.1221 42.6003 21.1041 42.5796C21.0636 42.5382 21.584 38.5438 21.6843 37.8825Z" fill="#2E3436"/>
      <path d="M18.7071 19.4489C18.7071 19.4489 17.1167 20.0949 17.0259 20.7286C16.935 21.3623 16.9497 21.4307 17.0316 21.4301C17.1142 21.4294 18.7992 20.5213 18.7992 20.5213L18.7905 19.4482L18.7071 19.4489V19.4489Z" fill="#D14C32"/>
      <path d="M17.0469 21.5744L17.0316 21.4308L18.7992 20.522L18.8002 20.6457L17.0469 21.5744Z" fill="#0D0E13"/>
      <path d="M24.7719 43.5194L29.9968 43.4769C29.9968 43.4769 32.8542 43.0672 32.4786 44.2819C32.103 45.4951 32.886 46.9752 28.9255 47.0074L25.9004 47.032C21.9406 47.0642 22.698 45.5716 22.3034 44.3646C21.9081 43.1562 24.7719 43.5194 24.7719 43.5194V43.5194Z" fill="#D8684A"/>
      <path d="M27.0677 42.87C29.49 42.8503 31.6646 42.2942 33.1691 41.4262L33.2657 41.4061C33.4298 42.5501 33.4127 42.4579 33.3913 42.4797C33.3522 42.521 32.0428 43.3166 29.7158 43.7063C29.2025 43.7351 27.2424 43.8434 27.2423 43.8367L27.2423 43.83C27.2423 43.8367 25.2806 43.7595 24.767 43.7399C22.4339 43.3872 21.1111 42.6138 21.0713 42.5724C21.0585 42.5606 21.0994 42.1812 21.1635 41.6423C22.6679 42.4172 24.7585 42.8887 27.0677 42.87Z" fill="#202226"/>
      <path d="M23.5041 26.4527C22.9097 24.6613 23.8698 24.019 24.9503 23.8203C25.8041 23.6621 27.9299 23.691 28.6246 23.7323C29.874 23.8055 31.2535 24.3231 30.6043 26.3949C29.4988 29.922 30.7322 34.7138 30.6765 35.2788C30.6353 35.6954 30.0337 36.3996 28.8147 36.5838C28.3798 36.6499 25.6204 36.6663 25.1185 36.5483C24.1231 36.3136 23.6203 35.7108 23.5763 35.3358C23.5114 34.7726 24.6668 29.9612 23.5041 26.4527V26.4527Z" fill="#D8684A"/>
    </svg>

  );
  const svgString = ReactDOMServer.renderToStaticMarkup(svg);
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgString)}`;
}

const infoPopup = (userLocation) => {
  const contentString = <div>
    <table>
      <tr>
        <td><b>Name</b></td>
        <td>{ userLocation.attributes.full_name }</td>
      </tr>
      <tr>
        <td><b>Latitude</b></td>
        <td>{ userLocation.attributes.latitude }</td>
      </tr>
      <tr>
        <td><b>Longitude</b></td>
        <td>{ userLocation.attributes.longitude }</td>
      </tr>
      <tr>
        <td><b>Email</b></td>
        <td>{ userLocation.attributes.email }</td>
      </tr>
      <tr>
        <td><b>Heading</b></td>
        <td>{ userLocation.attributes.heading }</td>
      </tr>
    </table>
  </div>;
  return ReactDOMServer.renderToStaticMarkup(contentString);
}

export {
  redCar, 
  infoPopup
}