import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  ControlGroup,
  Button,
  Dialog,
  Classes,
  Intent,
  TextArea,
  Divider,
  Spinner
} from "@blueprintjs/core";
import { observable, toJS } from 'mobx';
import Toast from "helpers/toaster";
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import FormSubmissionModal from './FormSubmissionModal';

@inject("viewStore", "workReportStore","formStore","formSubmissionStore")
@observer
class AddFormModal extends Component {
  @observable showFormSubmissionModal = false;

  handleCloseImageDailog() {
    this.props.onClose();
  }

  onClose() {
    this.showFormSubmissionModal = false;
  }

  showModal() {
    this.props.formSubmissionStore.getFormSubmission(this.props.formSubmission)
    this.showFormSubmissionModal = true;
  }

  
  onPaginationChange(newPage) {
    let {
      viewStore: {
        formSearch
      }
    } = this.props;
    this.props.viewStore.formSearch = {
      ...formSearch,
      page: newPage
    };
    this.props.viewStore.forms();
  }

  createForm(form) {
    let {
      workReportStore:{
        workReport:{
          object: { attributes }
        }
      }
    } = this.props;
    form.attributes.work_report_id = attributes.id;
    attributes.forms.push(form.attributes);
    this.props.workReportStore.updateWorkReportForm(attributes)
    .then(this.onSuccessfullUpdate.bind(this))
    .catch(this.onFailUpdate.bind(this))
  }

  onSuccessfullUpdate(response) {
    this.props.onClose();
    this.props.formSubmissionStore.getFormSubmission(response.data.data)
    this.showFormSubmissionModal = true;
    Toast.show({ icon: 'thumbs-up', message: 'Form attached successfully.', intent: Intent.SUCCESS })
  }

  onFailUpdate(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong, failed to attach form!', intent: Intent.DANGER })
    }
  }

  render() {
    let {
      viewStore,
      formStore,
      formStore: {
        forms: {
          objects,
          meta,
          isLoading
        }
      },
      formSubmission
    } = this.props;
   
    return (
      <React.Fragment>
      <Dialog
        title="Select Forms"
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
        <div className="card rounded-0 table-responsive">
        <table className="table table-hover">
          <thead>
            <tr style={{height:'auto'}}>
              {/* <th>ID</th> */}
              <th>Name</th>
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((form) => {
                return (
                  <tr key={form.attributes.id}>
                    {/* <td>{form.attributes.id}</td> */}
                    <td>
                      <a onClick={() => { viewStore.formShow({uuid: form.id}) }} >
                        {form.attributes.name}
                      </a>
                    </td>
                    <td>
                      <div style={{width:100}}>
                        <Button icon="add" text="Add Form" minimal={true} small={true} onClick={() =>this.createForm(form)} />  
                      </div>
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop:15 }}>{isLoading &&
                <Spinner size={Spinner.SIZE_STANDARD} />}</div>
        </div>
        <Footer>
          <div className="bp3-navbar-group" >
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.handleCloseImageDailog.bind(this)}
              style={{ width: "100px" }}
            >
              Close
            </Button>
           
          </div>
        </div>
      </Dialog>
      <FormSubmissionModal
        isOpen={this.showFormSubmissionModal}
        formSubmission={formSubmission}
        onClose={this.onClose.bind(this)}
      />
      </React.Fragment>
    );
  }
}

export default AddFormModal;
