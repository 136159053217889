import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup, Button, Dialog, Classes, 
  Intent, TextArea, ControlGroup, Icon, Alert
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import ClientAutocomplete from 'components/ClientAutocomplete';
import LocationAutocomplete from 'components/LocationAutocomplete';
import { observable } from 'mobx';

@inject('equipmentStore','store') @observer
class AddEquipmentModal extends Component {
  @observable images = [];
  @observable imageSet = [];
  @observable isDeleteDialogVisible = false;
  @observable isSelectImage;

  handleClosEquipmentDailog() {
    this.props.onClose()
  }

  createEquipment() {
    const {
      equipmentStore: {
        persistEquipment: {
          object
        }
      }
    } = this.props;
    if(!object.client_id) {
      Toast.show({ icon: 'warning-sign', message: "Please choose customer", intent: Intent.DANGER })  
      return  
    }

    if(!object.location_id) {
      Toast.show({ icon: 'warning-sign', message: "Please choose customer location", intent: Intent.DANGER })
      return     
    }

    if(!object.kind) {
      Toast.show({ icon: 'warning-sign', message: "Please enter title", intent: Intent.DANGER })  
      return   
    }

    if(!object.make) {
      Toast.show({ icon: 'warning-sign', message: "Please enter make", intent: Intent.DANGER })  
      return   
    }

    if(!object.eq_model) {
      Toast.show({ icon: 'warning-sign', message: "Please enter model", intent: Intent.DANGER })  
      return   
    }

    if(!object.serial_number) {
      Toast.show({ icon: 'warning-sign', message: "Please enter serial number", intent: Intent.DANGER })  
      return   
    }

    if(!object.eq_location) {
      Toast.show({ icon: 'warning-sign', message: "Please enter equipment location", intent: Intent.DANGER }) 
      return    
    }

    if(!object.description) {
      Toast.show({ icon: 'warning-sign', message: "Please enter description", intent: Intent.DANGER })  
      return   
    }
    object.images = this.images;

    object.active = true;
    this.props.equipmentStore.createEquipment()
      .then(this.onSuccessAddEquipment.bind(this))
      .catch(this.onFailAddEquipment.bind(this))  
  }

  onSuccessAddEquipment(response) {
    this.props.onSuccess(response.data.data)  
    Toast.show({ icon: 'thumbs-up', message: 'Equipment added successfully.', intent: Intent.SUCCESS })
    setTimeout(() => {
      this.props.equipmentStore.loadEquipmentsForWorkReports();
    }, 500);
  }

  onFailAddEquipment(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: "Problem in adding equipment!", intent: Intent.DANGER })
    }
  }

  onLocationClear = () => {
    let { object } = this.props.equipmentStore.persistEquipment;
    object.location_id = null;
    object.location = null;
  }

  onClientClear = () => {
    let { object } = this.props.equipmentStore.persistEquipment;
    object.client_id = null;
    object.client = null;
    this.onLocationClear();
  }

  onChangeFile(event) {
    let {
      equipmentStore:{
        persistEquipment:{
          object
        }
      }
    } = this.props;
    var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 10) {
      Toast.show({ icon: 'warning-sign', message: 'File size exceeds 10 MB!', intent: Intent.DANGER })
      return
    }
    this.props.equipmentStore.uploadEquipmentAttachment(event.target.files[0])
      .then(this.onSuccessfullUploadAttachment.bind(this))
      .catch(this.onFailUploadAttachment.bind(this))
  }

  onSuccessfullUploadAttachment(response) {
    response.data.data.attributes.asset.id = response.data.data.attributes.id;
    this.images.push(response.data.data.attributes);
    this.imageSet.push({ src: response.data.data.attributes.asset.url, thumbnail: response.data.data.attributes.asset.thumb.url, id: response.data.data.attributes.id })
    Toast.show({ icon: 'thumbs-up', message: 'Image uploaded successfully.', intent: Intent.SUCCESS })
  }

  onFailUploadAttachment(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong, failed to upload file!', intent: Intent.DANGER })
    }
  }

  setImages() {
    this.imageSet = [];
    if (this.images.length > 0) {
      this.images.map((image) => {
        this.imageSet.push({ src: image.asset.url, thumbnail: image.asset.thumb.url, id: image.id })
        return this.imageSet;
      })
    }
  }

  handleSelectCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleSelectConfirm(image) {
    this.isDeleteDialogVisible = true;
    this.isSelectImage = image;
  }

  removeAttachment(attachment) {
    this.isDeleteDialogVisible = false;
    this.images = this.images.filter((image) => image.id !== this.isSelectImage.id);
    this.props.equipmentStore.removeAttachment(this.isSelectImage)
      .then((response) => {
        this.setImages();
      })
  }

  render() {
    let { object, isLoading } = this.props.equipmentStore.persistEquipment;
    return(
      <Dialog
        title="Create Equipment"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleClosEquipmentDailog.bind(this)}
      >
        <div className={Classes.DIALOG_BODY}>
          <div>
            <FormGroup
              label="Customer"
              labelFor="text-input"
              labelInfo="(*)(enter customer name, phone number or email)"
            >
              <ControlGroup fill={true} vertical={false}>
                <ClientAutocomplete
                  style={{ width: '400px' }}
                  inputProps={{ placeholder: 'Start typing Customer Name, Phone number or Email..' }}
                  value={object.client}
                  onClientSelect={(client) => { 
                    if(object && client){
                      object.client_id = client.id 
                      object.client = client
                    }else{
                      this.onClientClear();
                    }
                  }}
                />
                {/* <AnchorButton text="Add New" onClick={() => {this.isOpenAddClientDialog = true;} } /> */}
              </ControlGroup>
            </FormGroup>

            {
              object.client_id &&
                <FormGroup
                  label="Client Location"
                  labelFor="text-input"
                  labelInfo="(search for location)"
                >
                  <ControlGroup fill={true} vertical={false}>
                    <LocationAutocomplete
                      scope={{ client_id: object.client_id } }
                      style={{ width: '400px' }}
                      inputProps={{ fill: true, placeholder: 'Start typing location..' }}
                      onLocationClear={this.onLocationClear.bind(this)}
                      value={object.location}
                      onLocationSelect={(location) => { 
                        if(object && location){
                          object.location_id = location.id;
                          object.location = location
                        }else{
                          this.onLocationClear();
                        }
                      }}
                    />
                    {/* <AnchorButton text="Add New" onClick={() => {this.isOpenAddLocationDialog = true;} } /> */}
                  </ControlGroup>
                </FormGroup>
            }
          </div>

          <FormGroup
            label="Title"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Title'
              onChange={(event) => {object.kind = event.target.value;}}            
            />
          </FormGroup>

          <FormGroup
            label="Make"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Make'
              onChange={(event) => {object.make = event.target.value;}}            
            />
          </FormGroup>

          <FormGroup
            label="Model"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Model'
              onChange={(event) => {object.eq_model = event.target.value;}}        
            />
          </FormGroup>

          <FormGroup
            label="Serial Number"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Serial Number'   
              onChange={(event) => {object.serial_number = event.target.value;}}           
            />
          </FormGroup>

          <FormGroup
            label="Equipment Location"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Equipment Location'   
              onChange={(event) => {object.eq_location = event.target.value;}}           
            />
          </FormGroup>

          <FormGroup
            label="Description"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <TextArea
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Description'
              onChange={(event) => {object.description = event.target.value;}}
              
            />
          </FormGroup>
          <FormGroup  labelFor="text-input" label="Images">
            <div className="wr-img-upload text-center">
              <input id="file-upload1" type="file" multiple={false}  onChange={this.onChangeFile.bind(this)} />
              <label for="file-upload1" class="btn-1">
                <span><Icon icon="cloud-upload" style={{marginRight:5}} /> Choose Image</span>
              </label>
            </div>
          </FormGroup>
          <div style={{ paddingLeft: '0px', paddingBottom: '0px', display: 'flex', flexWrap: 'wrap' }}>
            {
              this.imageSet.length > 0 && this.imageSet.map((image, index) =>
                <div className="wr-img-wrap">
                  <Button icon="trash" className="btn-img-del" onClick={() => this.handleSelectConfirm(image)} />
                  <img
                    src={image.thumbnail}
                    alt="Attached with Work Order"
                    key={index}
                    className="wr-img"
                  />
                </div>
              )
            }
          </div>
        </div>

        <Alert
          cancelButtonText="Close"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleSelectCancel.bind(this)}
          onConfirm={this.removeAttachment.bind(this)}
        >
          Are you sure to delete the selected image?
        </Alert>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleClosEquipmentDailog.bind(this)} className="btn-notes-close">Close</Button>
            <Button onClick={this.createEquipment.bind(this)} loading={isLoading} >Save</Button>
          </div>
        </div>
      </Dialog>

    )
  }

};

export default AddEquipmentModal;