import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Lozenge from '@atlaskit/lozenge';
import Pagination from 'components/Pagination';
import { 
  Button, Divider, Position, Menu, MenuItem, ButtonGroup, Popover
} from '@blueprintjs/core';
import Footer from '../Footer';
import { Alert, Intent } from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import qs from 'qs';
import { formatTime } from '../../helpers/time';
import { formatTimeOnly, formatDateOnly } from 'helpers/time';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';

@inject('store', 'projectStore', 'viewStore') @observer
class ProjectsTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable project;

  decideAppearance = (status) => {
    switch (status) {
      case 'active':
        return 'success'
      default:
        break;
    }
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        projectSearch
      }
    } = this.props;
    this.props.viewStore.projectSearch = {
      ...projectSearch,
      page: newPage
    };
    this.props.viewStore.projects();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.projectStore.deleteProject(this.project)
      .then(this.onSuccessfulDeleteProject.bind(this))
      .catch(this.onFailedProjectDelete.bind(this))
  }

  onSuccessfulDeleteProject(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Project deleted successfully!', intent: Intent.SUCCESS })
    this.project = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedProjectDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete project!', intent: Intent.DANGER })
  }

  handleProjectClick(event, project) {
    switch (event.label) {
      case 'Edit':
        this.project = project;
        this.props.viewStore.projectShow({uuid: project.attributes.id})
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.project = project;
        break;
      default:
    }
  }

  download_bulk_excel() {
    this.props.projectStore.download_bulk_excel(this.props.viewStore.projectSearch)
  }

  render() {
    let {
      viewStore,
      projectStore: {
        projects: {
          objects,
          meta
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              pages={[1,2,3,4,5]}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            <ButtonGroup>
              <Popover
                minimal={true}
                content={
                  <Menu>
                    <MenuItem text="Download Excel/CSV" onClick={this.download_bulk_excel.bind(this)} />
                  </Menu>
                }
                position={Position.BOTTOM_LEFT}
                usePortal={true}>
                <Button rightIcon='caret-down'>
                  Options
                </Button>
              </Popover>
            </ButtonGroup>
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
        <table className="table table-data-presentation" style={{ width: '100%' }}>
          <thead>
            <tr>
              {/* <th>Crew</th> */}
              <th>Project</th>
              <th>Client</th>
              {/* <th>Name</th> */}
              <th>PO</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((project) => {
                return (
                  <tr key={project.id}>
                    {/* <td>
                      <AvatarGroup
                        appearance="stack"
                        onAvatarClick={console.log}
                        data={_.sampleSize(toJS(store.avatars), 2)}
                        size="small"
                      />
                    </td> */}
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/projects/${project.attributes.id}/show?${qs.stringify(viewStore.projectShowSearch, { encode: false })}`}
                        mainText={project.attributes.number}
                        subText={project.attributes.name}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/clients/${project.attributes.client_id}?${qs.stringify(viewStore.clientShowSearch, { encode: false })}`}
                        mainText={project.attributes.client_name}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        subText={project.attributes.po_number}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(project.attributes.start_date)}
                        subText={formatTimeOnly(project.attributes.start_date)}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(project.attributes.end_date)}
                        subText={formatTimeOnly(project.attributes.end_date)}
                      />
                    </td>
                    <td>
                      <Lozenge appearance={this.decideAppearance.bind(this)(project.status)} >
                        {project.attributes.status}
                      </Lozenge>
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.projectShow({uuid: project.attributes.id})}>
                          <img src="/edit-light-icon.svg" alt={'edit icon'}/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleProjectClick({ label: 'Delete' }, project)}>
                          <img src="/delete-light-icon.svg" alt={'delete icon'}/>
                        </Button>
                      </div>
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to delete <b>{this.project && this.project.attributes.name}</b> project?
          </p>
        </Alert>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              pages={[1,2,3,4,5]}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </React.Fragment>
    )
  }
}

export default ProjectsTable;