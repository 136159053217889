import React, { Component } from 'react'
import { 
  Button, MenuItem, Switch,
  ButtonGroup, Menu,
  Popover, Position, Tag
} from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import ListPicker from 'components/ListPicker';
import SearchDateRangePicker from 'components/SearchDateRangePicker';
import ClientAutocomplete from 'components/ClientAutocomplete';
import TechnicianPicker from 'components/TechnicianPicker';
import ServiceTypePicker from 'components/ServiceTypePicker';
import moment from 'moment-timezone';
import qs from 'qs';

const options = [
  { label: 'Assigned', value: 'assigned', selected: false },
  { label: 'Confirmed', value: 'confirmed', selected: false },
  { label: 'In Progress', value: 'in_progress', selected: false },
  { label: 'Completed', value: 'completed', selected: false },
  { label: 'Unassigned', value: 'unassigned', selected: false }
];

@inject('store', 'jobStore', 'viewStore') @observer
class Job extends Component {

  @observable selectedClient = '';

  @action
  onAssignmentStatusChange(selections) {
    let selectedValues = selections.map((status) => status.value);
    let {
      props: {
        viewStore: {
          jobSearch
        }
      }
    } = this;
    this.props.viewStore.jobSearch = {
      ...jobSearch,
      where: {
        ...jobSearch.where,
        assignment_status: selectedValues
      }
    }
  }

  @action
  onDateRangeChange(range) {
    let {
      props: {
        viewStore: {
          jobSearch
        }
      }
    } = this;
    this.props.viewStore.jobSearch = {
      ...jobSearch,
      where: {
        ...jobSearch.where,
        start_date: { 
          gte: moment(range[0]).startOf('day').unix()
        },
        end_date: {
          lte: moment(range[1]).endOf('day').unix()
        }
      }
    }
    this.startDate = range[0];
    this.endDate = range[1];
    this.search()
  }

  onTechnicianChange(technicians) {
    let selectedValues = technicians.map((status) => status.value);
    let {
      props: {
        viewStore: {
          jobSearch
        }
      }
    } = this;
    this.props.viewStore.jobSearch = {
      ...jobSearch,
      where: {
        ...jobSearch.where,
        user_id: selectedValues
      }
    }
  }

  onServiceTypeChange(selections) {
    let selectedValues = selections.map((service_type) => service_type.value);
    let {
      props: {
        viewStore: {
          jobSearch
        }
      }
    } = this;
    this.props.viewStore.jobSearch = {
      ...jobSearch,
      where: {
        ...jobSearch.where,
        service_type_id: selectedValues
      }
    }
  }

  @action
  onClientSelect(client) {
    let {
      props: {
        viewStore: {
          jobSearch,
          jobSearch: {
            where: {
              client_id,
              ...whereWithNoClientId
            }
          }
        }
      }
    } = this;
    if (client) {
      this.props.viewStore.jobSearch = {
        ...jobSearch,
        where: {
          ...jobSearch.where,
          client_id: client.id
        }
      }
    } else {
      this.props.viewStore.jobSearch = {
        ...jobSearch,
        where: {
          ...whereWithNoClientId
        }
      }
    }
  }

  @action
  onShowDeletedChange(e) {
    let {
      props: {
        viewStore: {
          jobSearch
        }
      }
    } = this;
    this.props.viewStore.jobSearch = {
      ...jobSearch,
      only_deleted: e.target.checked
    }
    this.search();
  }

  @action
  onJobNumberChange(e) {
    let {
      props: {
        viewStore: {
          jobSearch
        }
      }
    } = this;
    let eventNumber;
    if (e.target.value === "") {
      eventNumber = null;
    } else {
      eventNumber = e.target.value;
    }

    this.props.viewStore.jobSearch = {
      ...jobSearch,
      where: {
        ...jobSearch.where,
        event_number: eventNumber
      }
    }
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.search()
    }
  }

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.jobSearch.page = 1;
    viewStore.jobs();
  }

  changeView(view) {
    let { viewStore } = this.props;
    viewStore.dashboardSearch.view = view;
    viewStore.dashboard();
  }

  download_excel() {
    let {
      viewStore,
      jobStore

    } = this.props;
    jobStore.download_excel(viewStore.jobSearch)
  }

  render() {
    const {
      viewStore: {
        jobSearch,
        jobSearch:{
          where
        }
      }
    } = this.props;
    let {
      props: {
        viewStore
      }
    } = this;
    let startDate = moment.unix(parseInt(where.start_date.gte)).toDate();
    let endDate = moment.unix(parseInt(where.end_date.lte)).toDate();
    return(
      <nav className="bp3-navbar search" style={{height: 105}}>

        <div className="responsive-filter-body">
          <div className="bg-white block-1 w-100 d-inline-block pr-1">
            <div className="bp3-navbar-group bp3-align-right">
              <div style={{display: 'inline'}}>
                <div className="docs-color-bubble unassigned-color"></div>
                <span className="legents">Unassigned</span>
                <div className="docs-color-bubble assigned-color"></div>
                <span className="legents">Assigned</span>
                <div className="docs-color-bubble confirmed-color"></div>
                <span className="legents">Confirmed</span>
                <div className="docs-color-bubble in-progress-color"></div>
                <span className="legents">In Progress</span>
                <div className="docs-color-bubble complete-color"></div>
                <span className="legents" style={{marginRight: 15}}>Complete</span>
              </div>
              <ButtonGroup>
                {/* <Button rightIcon='menu' onClick={() => { this.props.store.isOpen = !this.props.store.isOpen }}>
                </Button> */}
                <a href={`/dashboard?${qs.stringify(viewStore.CalendarSearchTimeline, { encode: false })}`}  style={{textDecoration: 'none'}} icon='gantt-chart' className="bp3-button" >
                  <span icon="gantt-chart" className="bp3-icon bp3-icon-gantt-chart"></span>
                  <span className="bp3-button-text">Timeline</span>
                </a>

                <a href={`/dashboard?${qs.stringify(viewStore.CalendarSearchBasicWeek, { encode: false })}`}  style={{textDecoration: 'none'}} icon='timeline-events' className="bp3-button" >
                  <span icon="timeline-events" className="bp3-icon bp3-icon-timeline-events"></span>
                  <span className="bp3-button-text">Week</span>
                </a>

                {/* <a href={`/dashboard?${qs.stringify(viewStore.CalendarSearchListWeek, { encode: false })}`}  style={{textDecoration: 'none'}} icon='th' className="bp3-button" >
                  <span icon="th" className="bp3-icon bp3-icon-th"></span>
                  <span className="bp3-button-text">Week List</span>
                </a> */}

                <a href={`/dashboard/assignments?${qs.stringify(viewStore.jobSearch, { encode: false })}`}  style={{textDecoration: 'none'}} icon='th-list' className="bp3-button" >
                  <span icon="th-list" className="bp3-icon bp3-icon-th-list"></span>
                  <span className="bp3-button-text">Assignments</span>
                </a>

                <a href={`/dashboard/jobs?${qs.stringify(viewStore.jobSearch, { encode: false })}`}  style={{textDecoration: 'none', backgroundColor: '#ebf1f5', boxShadow: 'none'}} icon='th-list' className="bp3-button" >
                  <span icon="th-list" className="bp3-icon bp3-icon-th-list"></span>
                  <span className="bp3-button-text">Jobs List</span>
                </a>
              </ButtonGroup>
            </div>
          </div>
          
          <div className="block-2 bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <Popover
                  minimal={true}
                  content={
                    <SearchDateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onChange={this.onDateRangeChange.bind(this)}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                  usePortal={true} 
                >
                  <Button rightIcon='caret-down'>
                    {(!startDate || !endDate) && `Date Range`}
                    {startDate && endDate && `${startDate.toDateString()} - ${endDate.toDateString()}`}
                  </Button>
                </Popover>
                
                <ClientAutocomplete
                  selectedClientId={where && where.client_id}
                  onClientSelect={this.onClientSelect.bind(this)}
                />

                <TechnicianPicker
                  selectedOptions={(where && where.user_id) || []}
                  onChange={this.onTechnicianChange.bind(this)}
                />
                
                <Popover
                  minimal={true}
                  content={
                    <ListPicker
                      selectedOptions={(where && where.assignment_status) || []}
                      options={options}
                      onChange={this.onAssignmentStatusChange.bind(this)}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                  usePortal={true} >
                  <Button rightIcon='caret-down'>
                    Status
                    <span>&nbsp;</span>
                    <Tag minimal={true} round={true}>{(where && where.assignment_status && where.assignment_status.length) || 0}</Tag>
                  </Button>
                </Popover>

                <ServiceTypePicker 
                  selectedOptions={(where && where.service_type_id) || ''}
                  onChange={this.onServiceTypeChange.bind(this)}
                />

                <input 
                  type="text"
                  className="bp3-input"
                  placeholder="Job Number"
                  onChange={this.onJobNumberChange.bind(this)}
                  defaultValue={where.event_number}
                  onKeyPress={this.onEnter.bind(this)}
                /> 

                <div className="job-deleted-wrapper">
                  <Switch 
                    checked={jobSearch.only_deleted} 
                    label="Show Deleted" 
                    onChange={this.onShowDeletedChange.bind(this)} 
                  />
                </div>

                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
              </div>
            </div>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            <ButtonGroup>
              <Popover 
                minimal={true}
                content={
                  <Menu>
                    <MenuItem text="Excel/CSV" onClick={this.download_excel.bind(this)}/>
                  </Menu>
                } 
                position={Position.BOTTOM_LEFT}
                usePortal={true}>
                <Button rightIcon='caret-down'>
                  Download
                </Button>
              </Popover>
            </ButtonGroup>
          </div>
        </div>
      </nav>
    )
  }

};

export default Job;