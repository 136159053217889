import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup, Button, Dialog, Classes, Intent, TextArea
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import { observable } from 'mobx';

@inject('jobStore') @observer
class AddNotesModal extends Component {

  @observable notes = '';

  handleCloseNotesDailog() {
    this.props.onClose();
    this.notes = ''
  }

  componentDidMount() {
    let { 
      jobStore: {
        persistNote: {
          object
        }
      }
    } = this.props;
  }

  createNote() {
    if(this.notes === ''){
      Toast.show({ icon: 'warning-sign', message: "please enter notes", intent: Intent.DANGER }) 
      return;   
    }
    let { object } = this.props.jobStore.job;
    this.props.jobStore.createNotes(object,this.notes)
      .then(this.onSuccessAddNotes.bind(this))
      .catch(this.onFailAddNotes.bind(this))  
  }

  updateNote() {
    let { 
      jobStore: {
        persistNote
      }
    } = this.props;
    if(this.notes === ''){
      this.notes = persistNote.object.body;
    }
    let { object } = this.props.jobStore.job;
    this.props.jobStore.updateNotes(object,this.notes,persistNote.object)
      .then(this.onSuccessUpdateNotes.bind(this))
      .catch(this.onFailAddNotes.bind(this))  
  }

  onSuccessAddNotes(response) {
    this.props.onSuccess(response) 
    Toast.show({
      icon: "thumbs-up",
      message: "Notes created successfully!",
      intent: Intent.SUCCESS
    }); 
  }

  onSuccessUpdateNotes(response) {
    this.props.onSuccess(response) 
    Toast.show({
      icon: "thumbs-up",
      message: "Notes updated successfully!",
      intent: Intent.SUCCESS
    }); 
  }

  onFailAddNotes(error) {
    Toast.show({ icon: 'warning-sign', message: error.response.data.errors.join(", "), intent: Intent.DANGER })
  }


  render() {
    let { 
      title,
      edit,
      jobStore: {
        persistNote: {
          object,
          isLoading
        }
      }
    } = this.props;
  
    return(
      <Dialog
        title={title}
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
        
          <FormGroup
            label="Notes"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <TextArea
              defaultValue={object.body}
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Notes'
              onChange={(event) => {this.notes = event.target.value} }
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseNotesDailog.bind(this)} className="btn-notes-close">Close</Button>
            {!edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.createNote.bind(this)}>
                Save
            </Button>}
            {edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.updateNote.bind(this)}>
              Update
            </Button>}
          </div>
        </div>
      </Dialog>

    )
  }

};

export default AddNotesModal;