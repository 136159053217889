import React from 'react';
import Avatar from '@atlaskit/avatar';
import {
  Icon, Spinner, Button
} from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { formatTime } from '../../helpers/time';
import _ from 'lodash';
import qs from 'qs';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
let location_id = '';

@inject('viewStore', 'workReportStore') @observer
class WorkOrdersTable extends React.Component {

  @observable workReports = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    this.props.viewStore.clientWorkReportSearch.where.client_id = this.props.client.attributes.id;
    var params = new URLSearchParams(window.location.search);  
    location_id = params.get("location_id");
    if(location_id){
      this.props.viewStore.clientWorkReportWithLocationSearch.where.location_id = location_id;
      this.props.viewStore.clientWorkReportWithLocationSearch.where.client_id = this.props.client.attributes.id;
      this.props.workReportStore.loadClientWorkReportsWithLocation();
    }else{
      this.props.viewStore.clientWorkReportSearch.where.client_id = this.props.client.attributes.id;
      this.props.workReportStore.loadClientWorkReports();
    }  
   
   
  }

  onPaginationChange(newPage) {
    var params = new URLSearchParams(window.location.search);  
    location_id = params.get("location_id");
    let {
      viewStore: {
        clientWorkReportSearch,
        clientWorkReportWithLocationSearch
      }
    } = this.props;
    if(location_id){
      this.props.viewStore.clientWorkReportWithLocationSearch = {
        ...clientWorkReportWithLocationSearch,
        page: newPage
      };
      this.props.workReportStore.loadClientWorkReportsWithLocation();

    }else{
      this.props.viewStore.clientWorkReportSearch = {
        ...clientWorkReportSearch,
        page: newPage
      };
      this.props.workReportStore.loadClientWorkReports();
    }
    
    
  }

  render() {
    let { objects, isLoading, meta } = this.props.workReportStore.clientWorkReports;
    let {
      viewStore,
      viewStore: {
        jobTab: {
          section
        }
      },
    } = this.props;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No work orders have been created for this job yet.
        </div>
      )
    }
    return (
      <div className="card rounded-0 table-responsive">
        <table className='table table-data-presentation table-vertical-align table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th></th>
              <th width="10%">Work Order</th>
              <th width="20%">Created By</th>
              <th>Job</th>
              <th width="15%">PO</th>
              <th>Amount</th>
              <th>Date</th>
              <th width="15%">Status</th>
              <th className="item-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((workReport) => {
                return (
                  <tr key={workReport.id}>
                    <td>
                      <a href={`/dashboard/work-reports/${workReport.id}/show`}>
                        <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=${workReport.attributes.created_by.full_name}&size=64`)} size='large' />
                      </a>
                    </td>
                    <td>
                      <a href={`/dashboard/work-reports/${workReport.id}/show`}>                    
                        <p className="secondary">{workReport.attributes.number}</p>
                      </a>
                    </td>
                    <td>
                      <a href={`/dashboard/users/${workReport.attributes.created_by.id}/show?${qs.stringify(viewStore.userShowSearch, { encode: false })}`}>
                        <p className="primary">{workReport.attributes.created_by.full_name}</p>
                      </a>
                    </td>
                    <td>
                      <a href={`/dashboard/jobs/${workReport.attributes.job.id}/${section}`}>
                        <p className='primary'>{workReport.attributes.event_number}</p>  
                      </a>                  
                    </td>
                    <td>                      
                      <p className='secondary'>{workReport.attributes.po_number || "N/A"}</p>
                    </td>
                    <td>
                      {/* <p className='time-entry primary'>{this.calculateTotal(workReport)}</p>                     */}
                    </td>
                    <td>                      
                      <p className='secondary'>{ formatTime(workReport.attributes.created_at) }</p>
                    </td>
                    <td>
                      <span className='bp3-tag bp3-minimal bp3-intent-primary'>
                        {workReport.attributes.status}
                      </span>
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <a className="bp3-button bp3-minimal bp3-small" href={`/dashboard/work-reports/${workReport.id}/show`}>
                          <img src="/edit-light-icon.svg"/>
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </div>
    )
  }
}

export default WorkOrdersTable;