import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from 'mobx';
import PricingParameterPicker from "components/PricingParameterPicker";
import FrequencyPicker from "components/FrequencyPicker";
import ServiceTypeCategoryPicker from 'components/ServiceTypeCategoryPicker';
import {
  FormGroup,
  Button,
  Dialog,
  Classes,
  Intent,
  TextArea
} from "@blueprintjs/core";
import Toast from "helpers/toaster";

let title = "Add Service Type";
let button_text = "Save";

@inject("serviceTypeStore","preferenceStore","pricingParameterStore")
@observer
class AddServiceTypeModal extends Component {
  @observable selectedPricingParameter = null;
  @observable selectedFrequency = null;
  @observable selectedServiceTypeCategory = null;
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
    };
  }

  componentDidMount() {
    let {
      preferenceStore,
      service_type_object,
      service_type_id
    } = this.props;

    if(service_type_id){
      this.setState({
        isChecked: service_type_object.hourly_service,
      });

      this.selectedPricingParameter = service_type_object.service_type_pricing_parameters.map((pps) => {
        return {
          id: pps.id,
          value: pps.pricing_parameter_id,
          selected: true,
          label: pps.label
        }
      })

      this.selectedFrequency = service_type_object.service_frequencies.map((stf) => {
        return {
          id: stf.id,
          value: stf.frequency_id,
          selected: true,
          label: stf.label
        }
      })

      if(service_type_object.service_type_category != null){
        this.selectedServiceTypeCategory = [{
          id: service_type_object.service_type_category.id,
          value: service_type_object.service_type_category.id,
          selected: true,
          label: service_type_object.service_type_category.name
        }]
      }
      
    }

    preferenceStore.loadTenant(); 
  }

  createServiceType() {
    let {
      props: {
        serviceTypeStore: {
          serviceTypeDetail: { 
            object
           }
        },
        preferenceStore: {
          tenant
        }
      }
    } = this;
    if(this.selectedServiceTypeCategory !== null && this.selectedServiceTypeCategory.length > 0 && this.selectedServiceTypeCategory[0] !== null) {
      object.service_type_category_id = this.selectedServiceTypeCategory[0].value;
    }

    if(object.display_on_booking_form && (object.service_type_category_id === "" || object.service_type_category_id === null)) {
      Toast.show({ icon: 'warning-sign', message: 'Please select valid service type category!', intent: Intent.DANGER })
      return;
    }

    if(object.name === null || object.name === '') {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    if(object.description === null || object.description === '') {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid description!', intent: Intent.DANGER })
      return;
    }

    if(object.rate === null || object.rate === '') {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid price !', intent: Intent.DANGER })
      return;
    }

    object.tenant_id = tenant.object.attributes.id;
    object.hourly_service = this.state.isChecked;
    object.rate_currency = tenant.object.attributes.currency_symbol;
    
    this.props.serviceTypeStore
      .createServiceType()
      .then(response => {
        this.props.onClose();
        Toast.show({
          icon: "thumbs-up",
          message: "Service type created successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to create service type!",
          intent: Intent.DANGER
        });
      });
  }

  updateServiceType() {
    let {
      props: {
        serviceTypeStore: {
          serviceTypeDetail: { 
            object,
            isLoading
           }
        },
        service_type_object,
        service_type_id,
        preferenceStore: {
          tenant
        }
      }
    } = this;
    
    //this.props.serviceTypeStore.serviceTypeDetail.isLoading = true;
    if(service_type_id){
      object.tenant_id = service_type_object.tenant_id;
    }else{
      object.tenant_id = tenant.object.attributes.id;
    }

    object.hourly_service = this.state.isChecked;

    if(this.selectedServiceTypeCategory != null){
      object.attributes.service_type_category_id = this.selectedServiceTypeCategory[0].value;
    }

    if(object.attributes.display_on_booking_form && (object.attributes.service_type_category_id === "" || object.attributes.service_type_category_id === null)) {
      Toast.show({ icon: 'warning-sign', message: 'Please select valid service type category!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.name === "" || object.attributes.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.description === "" || object.attributes.description === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid description!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.rate === "" || object.attributes.rate === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid price!', intent: Intent.DANGER })
      return;
    }
    
    object.attributes.service_type_pricing_parameters_attributes = this.selectedPricingParameter.map((option) => {
      return {
        "pricing_parameter_id": option.value
      }
    })

    object.attributes.service_frequencies_attributes = this.selectedFrequency.map((option) => {
      return {
        "frequency_id": option.value
      }
    })

    if(service_type_id){
      object.id = service_type_id;
      this.props.serviceTypeStore.updateServiceType(object)
        .then(this.onSuccessfulUpdateServiceType.bind(this))
        .catch(this.onFailUpdateServiceType.bind(this))
    }
  }

  onSuccessfulUpdateServiceType(response) {
    this.props.onClose();
    Toast.show({ icon: 'thumbs-up', message: 'Service type updated successfully.', intent: Intent.SUCCESS })
  }

  onFailUpdateServiceType(error) {
    Toast.show({ icon: 'warning-sign', message: 'Problem in updating service type!', intent: Intent.DANGER })
  }

  handleCloseServiceTypeDailog() {
    this.props.onClose();
  }

  handleSelectCancel() {
    this.isSelectDialogVisible = false;
    this.showLoader = false;
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  onServiceTypeSelect(selectedOptions, allOptions) {
    this.selectedPricingParameter = selectedOptions;
  }

  onFrequencySelect(selectedOptions, allOptions) { 
    this.selectedFrequency = selectedOptions;
  }

  onCategorySelect(selectedOptions, allOptions) {
    this.selectedServiceTypeCategory = selectedOptions;
  }

  
  handleChangeImage = (event) => {
    let {
      serviceTypeStore: {
        serviceTypeDetail: {
          object
        }
      },
      service_type_id
    } = this.props;
  
    var file = event.target.files[0];
    var reader = new FileReader();
    let base64String = '';
    
    reader.onload = function() {
      base64String = reader.result;
      if(service_type_id){
        object.attributes.icon_file = base64String;
        object.attributes.logo = base64String;
      }else{
        object.logo = base64String;
        object.icon_file = base64String;
      }
    };
    reader.readAsDataURL(file);
  };
 
  render() {
    let {
      serviceTypeStore: {
        serviceTypeDetail: {
          object,
          isLoading
        }
      },
      service_type_object,
      service_type_id
    } = this.props;
    
    if(service_type_object){
      title = "Edit Service Type";
      button_text = "Update";
      object = service_type_object;
    }

    return (
      <Dialog
        title={title}
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseServiceTypeDailog.bind(this)}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup 
            label="Service Type Category"
            labelFor="text-input"
            labelInfo=""
            className="field-width"
          >
            {
              <ServiceTypeCategoryPicker
                selectedOptions={this.selectedServiceTypeCategory}
                onChange={(event) => {this.onCategorySelect(event)} }
                maximumSelections={1}
              />
            }
          </FormGroup>
          <FormGroup label="Name" labelFor="text-input" labelInfo="(*)">
            <input
              type="text"
              className="bp3-input bp3-fill"
              placeholder="Name"
              defaultValue={object.name}
              onChange={event => {
                object.name = event.target.value;
              }}
            />
          </FormGroup>
          <div className="row">
            <div className="col col-xs-12 col-md-6">
              <FormGroup label="Icon" labelFor="text-input" labelInfo="" style={{ display: 'flex' }}>
                <div className="service-image-main">
                  <span className="btn btn-light btn-sm btn-file">
                      Change Icon <input type="file" onChange={this.handleChangeImage.bind(this)}/>
                  </span>
                  <div className="service-image-preview">
                    <img src={object.logo || '../icon_invoice.svg'}  height='40' alt='icon'/> 
                  </div>
                </div>
              </FormGroup>
            </div>
            <div className="col col-xs-12 col-md-6">
              <FormGroup label="Display on Booking Form" labelFor="text-input">
                <label className="bp3-control bp3-checkbox">
                  <input
                    type='checkbox'
                    className="bp3-checkbox"
                    defaultChecked={object.display_on_booking_form}
                    onChange={(event) => {object.display_on_booking_form = event.target.checked}}
                  />
                  <span className="bp3-control-indicator"></span>
                </label>
              </FormGroup>
            </div>
          </div>
          <FormGroup 
            label="Pricing Parameters"
            labelFor="text-input"
            labelInfo=""
            className="field-width"
          >
            {
              //this.selectedPricingParameter &&
              <PricingParameterPicker
                selectedOptions={this.selectedPricingParameter}
                onChange={(event) => {this.onServiceTypeSelect(event)} }
              />
            }
          </FormGroup>
          <FormGroup 
            label="Frequency"
            labelFor="text-input"
            labelInfo=""
            className="field-width"
          >
            {
              //this.selectedFrequency &&
                <FrequencyPicker
                  selectedOptions={this.selectedFrequency}
                  onChange={(event) => {this.onFrequencySelect(event)} }
                />
            }
          </FormGroup>
          <div className="add-servie-type">
            <FormGroup label="Price" labelFor="text-input" labelInfo="(*)">
              <input
                type="number"
                className="bp3-input bp3-fill"
                placeholder="Price "
                defaultValue={ object.rate }
                onChange={event => {
                  object.rate = event.target.value;
                }}
              />
            </FormGroup>
            
          </div>
          <div className="add-servie-type">
            <FormGroup label="Color" labelFor="text-input" labelInfo="">
              <input
                type="color"
                className="bp3-input bp3-fill"
                placeholder="Color"
                defaultValue={`${object.color}`}
                onChange={event => {
                  object.color = event.target.value;
                }}
              />
            </FormGroup>
            <FormGroup label="Hourly Service" labelFor="text-input" labelInfo="">
              <label className="bp3-control bp3-checkbox .modifier">
                <input
                  type='checkbox'
                  className="bp3-checkbox"
                  defaultChecked={object.hourly_service || ''}
                  onChange={this.toggleChange}
                />
                <span className="bp3-control-indicator"></span>
              </label>
            </FormGroup>
          </div>
          {
            this.state.isChecked == true ?
            <div>
              <FormGroup label="Allow 30 mins Increment" labelFor="text-input" labelInfo="">
                <label className="bp3-control bp3-checkbox modifier">
                  <input
                    type='checkbox'
                    className="bp3-checkbox"
                    defaultChecked={object.allow_thirty_minutes_increments || ''}
                    onChange={event => {
                      object.allow_thirty_minutes_increments = event.target.value;
                    }}
                  />
                  <span className="bp3-control-indicator"></span>
                </label>
              </FormGroup>
              <div className="add-servie-type">
                <FormGroup label="Number of Hours Start" labelFor="text-input" labelInfo="">
                  <input
                    type='number' 
                    className="bp3-input bp3-fill"
                    placeholder="Number of Hours Start"
                    defaultValue={object.number_of_hours_start}
                    onChange={event => {
                      object.number_of_hours_start = event.target.value;
                    }}
                  />
                </FormGroup>
                <FormGroup label="Number of Hours End" labelFor="text-input" labelInfo="">
                  <input
                    type='number' 
                    className="bp3-input bp3-fill"
                    placeholder="Number of Hours End"
                    defaultValue={object.number_of_hours_end}
                    onChange={event => {
                      object.number_of_hours_end = event.target.value;
                    }}
                  />
                </FormGroup>
              </div>
              <div className="add-servie-type">
                <FormGroup label="Number of Technician Start" labelFor="text-input" labelInfo="">
                  <input
                    type='number' 
                    className="bp3-input bp3-fill"
                    placeholder="Number of Technician Start"
                    defaultValue={object.number_of_technicians_start}
                    onChange={event => {
                      object.number_of_technicians_start = event.target.value;
                    }}
                  />
                </FormGroup>
                <FormGroup label="Number of Technician End" labelFor="text-input" labelInfo="">
                  <input
                    type='number' 
                    className="bp3-input bp3-fill"
                    placeholder="Number of Technician End"
                    defaultValue={object.number_of_technicians_end}
                    onChange={event => {
                      object.number_of_technicians_end = event.target.value;
                    }}
                  />
                </FormGroup>
              </div>
            </div>
            : ''
          }
          <FormGroup label="Description" labelFor="text-input" labelInfo="(*)">
            <TextArea
              className="bp3-fill"
              placeholder="Write a short description."
              defaultValue={object.description}
              onChange={event => {
                object.description = event.target.value;
              }}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button 
              onClick={this.handleCloseServiceTypeDailog.bind(this)}
              style={{ width: "100px" }}
            >
              Cancel
            </Button>
            {
              button_text =='Update' ?
              <Button
                intent={Intent.PRIMARY}
                loading={this.props.serviceTypeStore.serviceTypeDetail.isLoading}
                onClick={this.updateServiceType.bind(this)}
                icon="add"
                style={{ width: "200px" }}
              >
                {button_text}
              </Button>
              :
              <Button
                intent={Intent.PRIMARY}
                loading={this.props.serviceTypeStore.serviceTypeDetail.isLoading}
                onClick={this.createServiceType.bind(this)}
                icon="add"
                style={{ width: "200px" }}
              >
                {button_text}
              </Button>
            }
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddServiceTypeModal;
