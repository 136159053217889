import React from "react";
import Avatar from "@atlaskit/avatar";
import { Spinner, Button, Intent } from "@blueprintjs/core";
import { observer, inject } from "mobx-react";

@inject("store", "viewStore", "activityStore")
@observer
class JobsActivityLogs extends React.Component {
  componentDidMount() {
    this.fetchActivities(false);
  }

  fetchActivities(hasMoreRecords) {
    let { job } = this.props;
    let { activityStore } = this.props;
    activityStore.loadActivityLogs(job.attributes.id, hasMoreRecords);
  }

  render() {
    let {
      activityStore: {
        jobActivityLogs: { objects, isLoading, meta, isLoadingMore},
      },
    } = this.props;
    const canLoadMore = meta.page < meta.total_pages;
    if (isLoading) {
      return (
        <div className="spinner-center">
          <Spinner size="25" />
        </div>
      );
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No activity logs have been found for this job yet.
        </div>
      );
    }
    return (
      <div>
        <div className="activityTimelineLogs">
          {objects.map((activity) => {
            return (
              <div className={`activityTimelineCard ${activity.attributes.message.color_class} rightView`}>
                <div className="timelineCard">
                  <div className="activityCard">
                    <div width="10px" className="customerAvatar">
                      <Avatar
                        src={encodeURI(
                          `https://ui-avatars.com/api/?name=${activity.attributes.owner.name}&size=64`
                        )}
                        size="large"
                      />
                    </div>
                    <div className="activityMessageWrap">
                      <div className="activtiyHeader" width="150px">
                        <p className="activityTitle">
                          #{activity.attributes.job.number}
                        </p>
                        <p className="activityDate">
                          {activity.attributes.formatted_created_at}
                        </p>
                      </div>
                      <div className="activityMessage">
                        <p className="message">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: activity.attributes.message.html,
                            }}
                          ></div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {canLoadMore && <div className="activityLoadMoreBtn">
          <Button
            intent={Intent.PRIMARY}
            loading={isLoadingMore}
            onClick={() => {
              !isLoadingMore && this.fetchActivities(true)
            }}
          >
            Load More
          </Button>
        </div>}
      </div>
    );
  }
}

export default JobsActivityLogs;
