import React, { Component } from 'react';
import {
  Button,
  Navbar,
  Popover,
  Menu, MenuItem, AnchorButton, Breadcrumbs, IBreadcrumbProps, Icon} from "@blueprintjs/core";
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import AddProjectModal from 'components/Project/AddProjectModal';
import AddClientModal from 'components/Client/AddClientModal';
import AddSupplierModal from 'components/Client/AddSupplierModal';
import AddWorkReportModal from 'components/WorkReport/AddWorkReportModal';
import AddServiceTypeModal from 'components/ServiceType/AddServiceTypeModal';
import AddEstimateModal from 'components/Quote/AddEstimateModal';
import AddInvoiceModal from 'components/Invoice/AddInvoiceModal';
import AddEquipmentModal from 'components/Equipment/AddEquipmentModal';
import GlobalSearchBar from 'components/Common/GlobalSearchBar';
import $ from 'jquery';
import styled from '@emotion/styled';
import qs from 'qs';
import Icons from '../../src/svgs';
import moment from 'moment';
import Avatar from '@atlaskit/avatar';

const BreadcrumBar = styled('div')`
  margin-top: 45px;
  max-height: 30px;
  padding-left: 10px;
  border-bottom: 1px solid #e5e8e1;
  display: flex;
  align-items: center;
  background-color: rgba(230, 232, 241, 0.10);
  justify-content: space-between;
`

@inject('store', 'viewStore', 'searchStore', 'jobStore') @observer
class NavMenuItem extends Component {
  render() {
    const {
      props: {
        text,
        icon,
        onClick,
        active,
        link,
        children
      }
    } = this;
    const MenuIcon = icon;
    let urlLink = '';
    let rootUrl =  window.location.origin;
    
    if(text == "Support"){
      urlLink = <a href="javascript:void(0)" onClick={onClick}>
                  <MenuIcon active={active} />
                  <span className="menu-text">
                    {text}
                  </span>
                </a>
    }else{
      urlLink = <a href={`${rootUrl}/${link}`} onClick={onClick}>
                  <MenuIcon active={active} />
                  <span className="menu-text">
                    {text}
                  </span>
                </a>
    }
    return (    
      <li className={`nav-item dropdown ${active ? 'active' : ''}`}>
        {urlLink}
        {
          this.props.children &&
            <ul className="sub-menu">
              {
                children
              }
            </ul>
        }
      </li>
    )
  }
}
@inject('store', 'viewStore', 'searchStore', 'jobStore') @observer
class NavMenu extends Component {
  render() {
    const {
      props: {
        viewStore,
        viewStore: {
          currentUser
        },
        store,
        children
      }
    } = this;
    return (
      <nav className="navbar">
        <ul className="navbar-nav menu-list">
          {
            children
          }
        </ul>
      </nav>
    )
  }
}

@inject('store', 'viewStore', 'searchStore', 'jobStore','notificationStore') @observer
export default class NavigationBar extends Component {

  @observable showEventDialog = false;
  @observable isOpenAddInvoiceDialog = false;
  @observable showSearchBar = false;
  @observable mobile_menu_in = false;
  @observable isOpenAddEquipmentDialog = false;
  @observable trackable;

  // componentDidMount() {
  //   let {
  //     notificationStore,
  //     notificationStore: {
  //       notifications: {
  //         objects
  //       }    
  //     }
  //   } = this.props;
  //   //notificationStore.loadActivities(); 
  // }

  closeDialog() {
    this.showEventDialog = false
  }

  handleCloseInvoiceDailog() {
    this.isOpenAddInvoiceDialog = false;
  }

  onSuccessAddInvoice(invoice) {
    this.isOpenAddInvoiceDialog = false;
  }

  handleCloseSearchBar() {
    this.props.searchStore.search.isSearched = false;
    this.props.searchStore.search.objects = {};
    this.showSearchBar = false;
  }

  handleClosEquipmentDailog() {
    this.isOpenAddEquipmentDialog = false;
  }

  onSuccessAddEquipment(equipment) {
    this.isOpenAddEquipmentDialog = false;   
  }

  changeView = (view) => {
    let {
      viewStore
    } = this.props;
    viewStore.dashboardSearch.view = view;
    viewStore.dashboard();
    //this.renderCalendar();
    $('#calendar').fullCalendar('changeView', view);
    $('#calendar').fullCalendar('render');
  }
  handletoggleSidenav(){
    if(this.mobile_menu_in == true){
      this.mobile_menu_in = false
    }else{
      this.mobile_menu_in = true
    }
  }

  handleClick(event, trackable) {
    switch (event.label) {
      case 'Project':
        this.trackable = trackable;
        this.props.viewStore.projectShow({uuid: trackable.id})
        break;
      case 'Supplier':
        this.props.viewStore.supplierShow({uuid: trackable.id})
        this.trackable = trackable;
				break;
			case 'Client':
				this.props.viewStore.clientShow({uuid: trackable.id})
				this.trackable = trackable;
				break;
			case 'Job':
				this.props.viewStore.ShowJob({uuid: trackable.id})
				this.trackable = trackable;
				break;
			case 'ServiceAgreement':
				this.props.viewStore.serviceAgreementShow({uuid: trackable.id})
				this.trackable = trackable;
				break;
			case 'WorkReport':
				if(trackable.kind === 'Invoice'){
					this.props.viewStore.ShowInvoice({uuid: trackable.id})
				}
				if(trackable.kind === 'Quote'){
					this.props.viewStore.ShowQuote({uuid: trackable.id})
				}
				if(trackable.kind === 'WorkReport'){
					this.props.viewStore.ShowWorkOrder({uuid: trackable.id})
				}
				
				this.trackable = trackable;
				break;
      default:
    }
  }

  defaultDashboardPath = () => {
    const {
      props: {
        viewStore: {
          dashboardSearch,
          currentUser
        }
      }
    } = this;
    let view = (currentUser && currentUser.attributes && currentUser.attributes.dispatch_board_default_view) || 'timelineDay';
    return qs.stringify(
      { 
        ...dashboardSearch,
        view: view
      },
      { encode: false }
    )
  }
  
  render() {
    $("body").removeClass("payment");
    const {
      props: {
        dashboard,
        viewStore,
        viewStore: {
          currentUser,
          currentView,
          dashboardSearch: {
            date, view
          }
        },
        notificationStore: {
          notifications
        },
        jobStore,
        store
      }
    } = this;

    return (
      <React.Fragment>
        <div className="header-navbar align-items-center">
          <div className="container-fluid">
            <div className="row align-items-center header-navbar-row">
              <div className="col-xs-2 col-sm-2 col-md-2">
                <a 
                  onClick={() => { viewStore.dashboard() }} 
                >
                  <img src='/brand.svg' alt='Field Nexus' style={{ height: '30px' }}/>
                </a>
              </div>
              <div className="col-xs-10 col-md-10 col-sm-10 pr-0">
                <div className="top-nav-wrap responsive-layout-navbar">
                  <ul className="mr-auto mb-0 list-inline text-right nav-mobile-navbar">
                    <li className="list-inline-item  mobile-hidden">
                      <div className="search-wrap">
                        <GlobalSearchBar
                          isOpen={this.showSearchBar}
                          onClose={this.handleCloseSearchBar.bind(this)}
                        />
                      </div>
                    </li>
                    <li className="list-inline-item" style={{ 'marginRight': '0' }}>
                      <Button minimal={true} className="add-job-button" onClick={() => {window.$crisp.push(["do", "chat:toggle"]);
                      }}>
                        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5469 0C5.48712 0 1.37036 4.11676 1.37036 9.17651V10.0276C0.572132 10.3311 0 11.0965 0 12V16.2352C0 17.1544 0.59198 17.9306 1.41174 18.2228V18.0648C1.60626 19.0332 2.46328 19.7648 3.48798 19.7648C4.65582 19.7648 5.60577 18.8148 5.60577 17.647V10.5883C5.60577 9.42059 4.65582 8.47064 3.48798 8.47064C3.25031 8.47064 3.02545 8.5188 2.81177 8.59131C3.11298 4.58295 6.46344 1.41174 10.5469 1.41174C14.6281 1.41174 17.9777 4.58002 18.2816 8.58563C18.0692 8.51294 17.8431 8.47064 17.6058 8.47064C16.4379 8.47064 15.488 9.42059 15.488 10.5883V17.647C15.488 18.8148 16.4379 19.7648 17.6058 19.7648C17.8544 19.7648 18.0897 19.7139 18.3116 19.6348V20.4706C18.3116 20.8601 17.9945 21.1765 17.6058 21.1765H12.5345C12.2421 20.3566 11.4659 19.7648 10.5469 19.7648C9.37921 19.7648 8.42926 20.7147 8.42926 21.8824C8.42926 23.05 9.37921 24 10.5469 24C11.4659 24 12.2421 23.408 12.5345 22.5883H17.6058C18.7734 22.5883 19.7234 21.6383 19.7234 20.4706V18.2097C20.5245 17.9078 21.0992 17.1409 21.0992 16.2352V12C21.0992 11.0945 20.5245 10.3276 19.7234 10.0256V9.17651C19.7234 4.11676 15.6066 0 10.5469 0Z" fill="#C8D1DC" />
                        </svg>
                      </Button>
                      
                    </li>
                    {/* <li className="list-inline-item" style={{ 'marginRight': '0' }}>
                      <Popover minimal={true} inheritDarkTheme={false} className='add-job-wrapper'>
                        <Button minimal={true} icon='notifications' className="add-job-button"><div className="nt-count" style={{ backgroundColor:"#1800ff", color: '#fff'}}>{notifications && notifications.objects ? notifications.objects.length :0}</div></Button>
                        <Menu style={{padding:0}}>
                          <div className="nd-header">
                              Notifications
                          </div>
                          <div className="nd-body">
                            {notifications.objects && notifications.objects.map((notification,key) => {
                              if(key < 10){
                                return(
                                  <div className="notification-wrap" style={{'margin-left':'10px', 'marginRight':'10px'}}>
                                    <div className="notification-header" style={{ 'backgroundColor':'#4431FF'}}>
                                      <div className="head-content" onClick={() => this.handleClick({ label: notification.attributes.trackable.type }, notification.attributes.trackable)}>
                                        <div dangerouslySetInnerHTML={{ __html: notification.attributes.message.html }}></div>
                                      </div>
                                      <div className="headr-white-text" style={{'color':'#fff'}}>
                                        <div style={{display:'flex',alignItems:'center'}} onClick={() => viewStore.userShow({uuid: notification.attributes.owner.id})}>
                                          <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=${notification.attributes.owner.name}&size=34&background=2D4EF5&color=fff`)} size='small' /> 
                                          <div style={{marginLeft:10}}>{notification.attributes.owner.name}</div>
                                        </div>
                                        <div style={{'color':'#fff'}}>{moment(notification.attributes.created_at).fromNow()}</div>
                                      </div>
                                    </div>
                                    <div className="notification-content" onClick={() => this.handleClick({ label: notification.attributes.trackable.type }, notification.attributes.trackable)}>
                                      <ul className="list-unstyled">
                                        <li>
                                          <div dangerouslySetInnerHTML={{ __html: notification.attributes.message.short_info }}></div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )
                              }
                            })}   
                          </div>  
                        
                        </Menu>
                      </Popover>
                      
                    </li> */}
                    <li className="list-inline-item" style={{ 'marginRight': '0' }}>
                      <Popover minimal={true} inheritDarkTheme={false} className='add-job-wrapper'>
                        <Button minimal={true} icon='plus' className="add-job-button" />
                        <Menu>
                          <li className="bp3-menu-header"><h6 className="bp3-heading">Add New</h6></li>
                          <MenuItem text="Add Job" icon={'add'} onClick={() => { store.isOpen = true }} />
                          <MenuItem text="Add Service Agreement" icon={'document-open'} onClick={() => { jobStore.isServiceAgreement = true; store.isOpen = true }} />
                          <MenuItem text="Add Client" icon={'new-person'} onClick={() => { store.isOpenAddClientDialog = true }} />
                          <MenuItem text="Add Supplier" icon={'people'} onClick={() => { store.isOpenAddSupplierDialog = true }} />
                          <MenuItem text="Add Project" icon={'projects'} onClick={() => { store.isOpenAddProjectDialog = true }} />
                          <MenuItem text="Add Work Order" icon={'clipboard'} onClick={() => { store.isOpenAddWorkReportDialog = true }} />
                          <MenuItem text="Add Estimate" icon={'application'} onClick={() => { store.isOpenAddEstimateDialog = true }} />
                          <MenuItem text="Add Invoice" icon={'dollar'} onClick={() => { this.isOpenAddInvoiceDialog = true }} />
                          <MenuItem text="Add Equipment" icon={'wrench'} onClick={() => { this.isOpenAddEquipmentDialog = true }} />
                          <MenuItem text="Add Booking" icon={'timeline-events'} onClick={() => viewStore.newBooking()} />
                        </Menu>
                      </Popover>                      
                    </li>
                    <li className="list-inline-item" style={{ 'marginRight': '0' }}>

                      {/* <Popover minimal={true} inheritDarkTheme={false}>
                        <Button minimal={true} icon='user' rightIcon='caret-down'>
                          Users
                      </Button>
                        <Menu>
                          <MenuItem text="List" icon={'user'} onClick={() => { viewStore.users() }} active={viewStore.currentView === 'Pages.Users.Index'} />
                          <MenuItem text="Map" icon={'map'} onClick={() => { viewStore.userMap() }} active={viewStore.currentView === 'Pages.Users.Map'} />
                        </Menu>
                      </Popover> */}

                      <Popover minimal={true} inheritDarkTheme={false}>
                        <Button minimal={true} rightIcon='caret-down' className="add-job-button">
                          <div className="avtar-wrap">
                            <img src={currentUser.attributes.avatar_url} alt='' className="user-avtar" />
                          </div>
                        </Button>
                        <Menu>
                          <li className="">
                            <a href={`/dashboard/users/${currentUser.id}/show?${qs.stringify(viewStore.userShowSearch, { encode: false })}`} text="Profile" icon={'user'}  active={viewStore.currentView === 'Pages.Users.Profile'} class="bp3-menu-item bp3-popover-dismiss">
                              <span icon="user" className="bp3-icon bp3-icon-user"></span>
                              <div className="bp3-text-overflow-ellipsis bp3-fill">Profile</div>
                            </a>
                          </li>
                          <li className="">
                            <a href={`/dashboard/users/change-password`} text="Change Password" icon={'key'}  active={viewStore.currentView === 'Pages.Users.ChangePassword'} class="bp3-menu-item bp3-popover-dismiss">
                              <span icon="key" className="bp3-icon bp3-icon-key"></span>
                              <div className="bp3-text-overflow-ellipsis bp3-fill">Change Password</div>
                            </a>
                          </li> 
                          <MenuItem text="Logout" icon={'log-out'} onClick={() => { viewStore.performLogout() }} />
                        </Menu>
                      </Popover>

                    </li>
                    <li className="list-inline-item menu-button">
                      <Button className="menu-btn" onClick={this.handletoggleSidenav.bind(this)} icon='menu'  />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
              
          </div>
        </div>
        <BreadcrumBar>
          <div>
            <Breadcrumbs
              items={viewStore.breadCrumbs}
            />
          </div>
          {currentView === "Pages.Dashboard.Index" && <div className="job-legents-on-breadcrumbs">
            <div className="docs-color-bubble unassigned-color"></div>
            <span className="legents">Unassigned</span>
            <div className="docs-color-bubble assigned-color"></div>
            <span className="legents">Assigned</span>
            <div className="docs-color-bubble confirmed-color"></div>
            <span className="legents">Confirmed</span>
            <div className="docs-color-bubble in-progress-color"></div>
            <span className="legents">In Progress</span>
            <div className="docs-color-bubble complete-color"></div>
            <span className="legents" style={{ marginRight: 15 }}>Complete</span>
          </div>}
        </BreadcrumBar>

        <div className="dashboard-panel">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div id="psidebar" className={this.mobile_menu_in ? 'panel-sidebar show-menu' : 'hidden-menu  panel-sidebar'}>
                <NavMenu>
                  <NavMenuItem 
                    text='Dispatch' 
                    link={`dashboard?${this.defaultDashboardPath()}`}
                    icon={Icons.DashboardIcon} 
                    active={viewStore.currentView === 'Pages.Dashboard.Index' || viewStore.currentView === 'Pages.Jobs.Index' || viewStore.currentView === 'Pages.Jobs.Show'} 
                  />
                  <NavMenuItem 
                    text='Clients' 
                    icon={Icons.ClientIcon} 
                    link={`dashboard/clients?${qs.stringify(viewStore.clientSearch, { encode: false })}`}
                    active={viewStore.currentView === 'Pages.Clients.Index' || viewStore.currentView === 'Pages.Clients.Show' || viewStore.currentView === 'Pages.ServiceAgreements.Index'|| viewStore.currentView === 'Pages.ServiceAgreements.Show'} 
                  />

                  <NavMenuItem 
                    text='Suppliers' 
                    icon={Icons.SupplierIcon} 
                    link={`dashboard/suppliers?${qs.stringify(viewStore.supplierSearch, { encode: false })}`}
                    active={viewStore.currentView === 'Pages.Suppliers.Index' || viewStore.currentView === 'Pages.Suppliers.Show'} 
                  />
                  
                  <NavMenuItem 
                    text='Projects' 
                    icon={Icons.ProjectsIcon} 
                    link={`dashboard/projects?${qs.stringify(viewStore.projectSearch, { encode: false })}`}
                    active={viewStore.currentView === 'Pages.Projects.Index' || viewStore.currentView === 'Pages.Projects.Show' || viewStore.currentView === 'Pages.WorkReports.MaterialOrders' || viewStore.currentView === 'Pages.WorkReports.PurchaseOrders' || viewStore.currentView === 'Pages.WorkReports.ShowPurchaseOrder'} 
                  />
                  <NavMenuItem text='Equipment' link={`dashboard/equipments?${qs.stringify(viewStore.equipmentSearch, { encode: false })}`} icon={Icons.EquipmentsIcon}  
                    active={viewStore.currentView === 'Pages.Equipments.Index' || viewStore.currentView === 'Pages.Equipments.Show'}
                  />
                  <NavMenuItem text='Jobs' link={`dashboard/jobs?${qs.stringify(viewStore.jobSearch, { encode: false })}`} icon={Icons.QuoteIcon}  
                    active={viewStore.currentView === 'Pages.Jobs.Index' || viewStore.currentView === 'Pages.Jobs.Show'}
                  />
                  <NavMenuItem text='Work Orders' link={`dashboard/work-reports?${qs.stringify(viewStore.workReportSearch, { encode: false })}`} icon={Icons.WorkIcon}  
                    active={(viewStore.currentView === 'Pages.WorkReports.Index' || viewStore.currentView === 'Pages.WorkReports.Show')} 
                  />
                  <NavMenuItem text='Estimates' link={`dashboard/quotes?${qs.stringify(viewStore.quoteSearch, { encode: false })}`} icon={Icons.QuoteIcon}  
                    active={viewStore.currentView === 'Pages.Quotes.Index' || viewStore.currentView === 'Pages.Quotes.Show'} 
                  />
                  <NavMenuItem text='Invoices' link={`dashboard/invoices?${qs.stringify(viewStore.invoiceSearch, { encode: false })}`} icon={Icons.InvoiceIcon}  
                    active={viewStore.currentView === 'Pages.Invoices.Index' || viewStore.currentView === 'Pages.Invoices.Show'} 
                  />
                  <NavMenuItem text='Timesheets' link={`dashboard/timesheets?${qs.stringify(viewStore.timesheetSearch, { encode: false })}`} icon={Icons.TimesheetIcon}  
                    active={viewStore.currentView === 'Pages.Timesheets.Index'} 
                  />
                  <NavMenuItem text='Users' link={`dashboard/users/all`} icon={Icons.UserIcon} 
                    active={['Pages.Users.Vacations', 'Pages.Users.Index', 'Pages.Users.MapShow', 'Pages.Users.Show', 'Pages.Users.Map', 'Pages.Users.ShowMap'].indexOf(viewStore.currentView) >= 0}>
                  </NavMenuItem>
                  <NavMenuItem text='Bookings' link={`dashboard/bookings?${qs.stringify(viewStore.bookingSearch, { encode: false })}`} icon={Icons.BookingRequest} 
                    active={[
                      "Pages.Bookings.Index",
                      "Pages.Bookings.Show",
                      "Pages.Bookings.New",
                      "Pages.Frequency.Index",
                      "Pages.Frequency.Add",
                      "Pages.Frequency.Show",
                      "Pages.ServiceType.Index",
                      "Pages.ServiceType.Add",
                      "Pages.ServiceType.Show",
                      "Pages.ServiceTypeExtra.Index",
                      "Pages.ServiceTypeExtra.Add",
                      "Pages.ServiceTypeExtra.Show",
                      "Pages.PricingParameter.Index",
                      "Pages.PricingParameter.Add",
                      "Pages.PricingParameter.Show",
                      "Pages.Question.Index",
                      "Pages.Question.Add",
                      "Pages.Question.Show",
                      "Pages.QuestionGroup.Index",
                      "Pages.QuestionGroup.Add",
                      "Pages.QuestionGroup.Show",
                      "Pages.Bookings.BookingConfiguration", 
                    ].indexOf(viewStore.currentView) >= 0}>
                  </NavMenuItem>
                  <NavMenuItem text='Settings' link={`billing?${qs.stringify(viewStore.billingInfo, { encode: false })}`} icon={Icons.SettingIcon}  
                    active={viewStore.currentView === 'Pages.Billing.Index' 
                    || viewStore.currentView === 'Pages.Taxes.Index' 
                    || viewStore.currentView === 'Pages.Forms.Index' 
                    || viewStore.currentView === 'Pages.Preferences.Index' 
                    || viewStore.currentView === 'Pages.Forms.Show' 
                    || viewStore.currentView === 'Pages.Preferences.Index'
                    || viewStore.currentView === 'Pages.Preferences.DefaultLineItems'
                    || viewStore.currentView === 'Pages.Preferences.CompanyDetails'
                    || viewStore.currentView === 'Pages.Preferences.Booking'
                    || viewStore.currentView === 'Pages.ProductsServices.Index'
                    || viewStore.currentView === 'Pages.Preferences.AccountingSetup'
                    }>
                    
                  </NavMenuItem>

                </NavMenu>

              </div>

              <div className="content-panel">
              </div>

            </div>
          </div>
        </div>
        <AddProjectModal
          isOpen={store.isOpenAddProjectDialog}
          onClose={store.handleCloseProjectDailog.bind(store)}
          onSuccess={store.onSuccessAddProject.bind(store)}
        />

        <AddClientModal
          isOpen={store.isOpenAddClientDialog}
          onClose={store.handleCloseClientDailog.bind(store)}
          onSuccess={store.onSuccessAddClient.bind(store)}
        />

        <AddSupplierModal
          isOpen={store.isOpenAddSupplierDialog}
          onClose={store.handleCloseSupplierDailog.bind(store)}
          onSuccess={store.onSuccessAddSupplier.bind(store)}
        />

        <AddWorkReportModal
          isOpen={store.isOpenAddWorkReportDialog}
          onClose={store.handleCloseWorkReportDailog.bind(store)}
          onSuccess={store.onSuccessAddWorkReport.bind(store)}
        />

        <AddServiceTypeModal
          isOpen={store.isOpenAddServiceTypeDialog}
          onClose={store.handleCloseServiceTypeDailog.bind(store)}
          onSuccess={store.onSuccessAddServiceType.bind(store)}
        />

        <AddEstimateModal
          isOpen={store.isOpenAddEstimateDialog}
          onClose={store.handleCloseEstimateDailog.bind(store)}
          onSuccess={store.onSuccessAddEstimate.bind(store)}
        />

        <AddInvoiceModal
          isOpen={this.isOpenAddInvoiceDialog}
          onClose={this.handleCloseInvoiceDailog.bind(this)}
          onSuccess={this.onSuccessAddInvoice.bind(this)}
        />
        <AddEquipmentModal
          isOpen={this.isOpenAddEquipmentDialog}
          onClose={this.handleClosEquipmentDailog.bind(this)}
          onSuccess={this.onSuccessAddEquipment.bind(this)}
        />
      </React.Fragment >
    )
  }
}
