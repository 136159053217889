import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Menu, MenuDivider, MenuItem, Popover, Position, Tag } from "@blueprintjs/core";
import ListPicker from 'components/ListPicker';
import { action } from 'mobx';
import moment from 'moment';
import DateTimeInput from 'components/DateTimeInput';

const options = [
  { label: 'Draft', value: 'draft', selected: false },
  { label: 'Approved', value: 'approved', selected: false },
  { label: 'Submitted', value: 'submitted', selected: false },
  { label: 'Sent', value: 'sent', selected: false }
];

@inject('store', 'invoiceStore') @observer
class InvoiceHeader extends Component {

  componentWillMount() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;

    if (!object.attributes.invoice_due_date) {
      object.attributes.invoice_due_date = moment(object.attributes.created_at)
    }
  }

  @action
  onInvoiceStatusChange(selections) {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    let selectedValues = selections.map((status) => status.value);
    object.attributes.status = selectedValues[0];
  }

  onInvoiceDueDateChange(date) {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    object.attributes.invoice_due_date = moment(date);
  }

  onInvoiceCreatedAtChange(date) {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    object.attributes.created_at = moment(date);
  }

  onInvoicePoNumberChange(event) {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    object.attributes.po_number = event.target.value;
  }
  
  render() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    return (
      <div className="invoice-header" style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', marginBottom: '1rem', marginTop: '1rem' }}>
        <div className="logo-company-name">
          <div className="header-logo">
            <img src={object.attributes.tenant.logo} height='75' alt='tenant logo'/>
          </div>
          <div className="header-address" style={{ display: 'flex', flexDirection: 'column' }}>
            <h5>{object.attributes.tenant.name}</h5>
            <span>{[object.attributes.tenant.street_address, object.attributes.tenant.street_address_other].join(", ")}</span>
            <span>{object.attributes.tenant.city}, {object.attributes.tenant.province}, {object.attributes.tenant.postal_code}</span>
            <span>
              <strong>Tel: </strong>
              {object.attributes.tenant.phone}
            </span>
            {object.attributes.tenant.tax_number_title && object.attributes.tenant.tax_number && <span>
              {object.attributes.tenant.tax_number_title}: 
              {object.attributes.tenant.tax_number}
            </span>}
          </div>
        </div>
        <div>
          <div className="d-md-flex align-items-start justify-content-between mt-2">
            <div className="side-text">Payment Status</div>
            <div className="job-detail-block p-2">
              <Tag intent={object.attributes.payment_status === 'paid' ? 'success' : 'warning'}>
                {object.attributes.formatted_payment_status || "Unpaid"}
              </Tag>
            </div>
          </div>
          <div className="d-md-flex align-items-start justify-content-between mt-2">
            <div className="side-text">Invoice date</div>
            <div className="job-detail-block">
              <DateTimeInput
                placeholder={'Invoice Date'}
                defaultValue={moment(object.attributes.created_at)}
                onChange={this.onInvoiceCreatedAtChange.bind(this)}
              />
            </div>
          </div>
          <div className="d-md-flex align-items-start justify-content-between mt-2">
            <div className="side-text">Due date</div>
            <div className="job-detail-block">
              <DateTimeInput
                placeholder={'Invoice Due Date'}
                defaultValue={moment(object.attributes.invoice_due_date)}
                onChange={this.onInvoiceDueDateChange.bind(this)}
              />
            </div>
          </div>
          <div className="d-md-flex align-items-start justify-content-between mt-2">
            <div className="side-text">PO Number</div>
            <div className="job-detail-block">
            <input
                className="bp3-input"
                style={{width: '100%'}}
                type="text"
                placeholder="PO Number"
                dir="auto"
                defaultValue={object.attributes.po_number}
                onChange={this.onInvoicePoNumberChange.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InvoiceHeader;