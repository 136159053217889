import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { 
  Button, Intent
} from "@blueprintjs/core";

import WorkReportHeader from 'components/WorkReport/HeaderPurchaseOrder';
import WorkReportCustomerDetails from 'components/WorkReport/SupplierDetails';
import WorkReportMaterialLineItems from 'components/WorkReport/PurchaseMaterialLineItems';
import WorkReportPDFViewer from 'components/WorkReport/PDFViewerPurchaseOrder';
import {
  ColumnContainer,
  Column,
  Card
} from 'components/Styles';
import Toast from 'helpers/toaster';
import _ from 'lodash';
let numeral = require('numeral');


@inject('store', 'workReportStore') @observer
class WorkReportShowPurchaseOrder extends Component {


  render() { 
    const {
      workReportStore: {
        purchaseOrderItem: {
          isLoading,
          object
        }
      }
    } = this.props;

    return (
      <div style={{ width: '1024px', margin: '0 auto' }}>
        <ColumnContainer>
          <Column>
            <Card>
              {/* Header: Logo + Tenant Details */}
              <WorkReportHeader />

              {/* Customer Details */}
              <WorkReportCustomerDetails />

              <div style={{marginTop: 40}}></div>

              {/* Material Line Items */}
              <WorkReportMaterialLineItems />

              <div style={{marginTop: 40}}></div>
              
              <WorkReportPDFViewer />
            </Card>
            
          </Column>
        </ColumnContainer>
      </div>
    )
  }
}

export default WorkReportShowPurchaseOrder;