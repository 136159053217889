import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import styled from '@emotion/styled';
import ProfileCard from 'components/ServiceAgreement/ProfileCard';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'viewStore', 'serviceAgreementStore') @observer
class ServiceAgreementShowPage extends Component {

  get canRender() {
    let {
      serviceAgreementStore: {
        serviceAgreement: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object && object.id);
  }

  render() {
    let content, title;
    let {
      serviceAgreementStore: {
        serviceAgreement: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    
    if (isLoading && !isError) {
      title = 'Loading..';
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load Service Agreement';
      content = <NonIdealState title="Failed" description="Failed to load service agreement!" />;
    }
    if (this.canRender) {
      title = `${object.attributes.title} | Field Nexus`;
      content = <ProfileCard />
    }
    return(
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <PageWrapGray>
          {content}
        </PageWrapGray>
      </div>
    )
  }

};

export default ServiceAgreementShowPage;
