import { observable, action, computed } from 'mobx';
import { Api } from 'services/Api';
import axios from 'axios';
import _ from 'lodash'; 
import moment from 'moment';
import stores from 'stores';

class DashboardStore {
  @observable jobs = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable assignments = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable users = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @computed
  get isLoading() {
    return (this.assignments.isLoading || this.users.isLoading || this.jobs.isLoading);
  }

  @action
  loadWeekViewJobs(start, end) {
    this.assignments.isLoading = true;
    const selectedUsers = stores.viewStore.dashboardSearch.technician_id;

    const orObject = [];
    if (selectedUsers && selectedUsers.length > 0) {
      orObject.push({
        user_id: selectedUsers.filter(id => id !== "0" && id !== "selectAll")
      });
      if (selectedUsers.includes("0")) {
        orObject.push({
          "assignments.user_id": null,
        });
      }
    }

    let promise = Api.post('/jobs/search', {
      search: {
        where: {
          service_type_id: stores.viewStore.dashboardSearch.service_type_id,
          '_or': [
            {
              start_date: {
                gte: moment(start).subtract(7, 'days'),
                lte: moment(end).add(7, 'days')
              },
              '_or': orObject
            },
            {
              end_date: {
                gte: moment(start).subtract(7, 'days'),
                lte: moment(end).add(7, 'days')
              },
              '_or': orObject
            }
          ]
        },
        per_page: 425
      }
    });

    promise
      .then((response) => {
        this.assignments.isLoading = false;
        this.assignments.isError = false;
        this.assignments.objects = response.data.data;
      })
      .catch((error) => {
        this.assignments.isLoading = false;
        this.assignments.isError = true;
      })
    return promise;
  }

  loadCalendarAssignments(start, end, timezone, callback) {
    let _users = this.users;
    // TODO: adding +1 day in start date and end date
    // Hack to fix the timezone issue, need to fix this 
    if(stores.viewStore.dashboardSearch.view === "basicWeek") {
      this.loadWeekViewJobs(start.set("date", start.get("date") - 1), end.set("date", end.get("date") + 1))
        .then((jobs) => {
          let events = jobs.data.data.map((job) => { return { 
            id: job.id,
            title: job.attributes.title,
            eventNumber: job.attributes.event_number,
            subtitle: `${job.attributes.short_start} - ${job.attributes.short_end}`,
            start: job.attributes.start_date,
            end: job.attributes.end_date,
            short_start: job.attributes.short_start,
            short_end: job.attributes.short_end,
            endForDisplay: job.attributes.end_date,
            resourceIds: job.attributes.technician_ids ? job.attributes.technician_ids : ["0"],
            clientName: job.attributes.client.name,
            fullAddress: job.attributes.location && job.attributes.location.full_address,
            technicianName: job.attributes.technician_names,
            displayEventTime: true,
            statusColor: "",
            jobId: job.id,
            serviceType: job.attributes.service_type,
            status: job.attributes.status,
            job_status: job.attributes.status, 
            isRecurringJob: job.attributes.service_agreement.id ? true : false,
            assignments:job.attributes.assignments
          } })
          let vacations = [];
          _users.objects.map((user) => {
            vacations = vacations.concat(_.compact(user.attributes.vacations));
            return vacations;
          })
          vacations = vacations.map(vacation => {
            return { 
              id: vacation.id,
              user: vacation.title,
              start: vacation.start,
              end: vacation.end,
              title: vacation.notes,
              editable: false,
              isVacation: true
            };
          });
          events = events.concat(vacations);
          callback(events);
        })
    } else {
      this.loadAssignments(start.set("date", start.get("date") - 1), end.set("date", end.get("date") + 1))
        .then((assignments) => {
          let events = assignments.data.data.map((assignment) => { return { 
            id: assignment.id,
            title: assignment.attributes.title,
            eventNumber: assignment.attributes.event_number,
            subtitle: `${assignment.attributes.short_start} - ${assignment.attributes.short_end}`,
            start: assignment.attributes.start_date,
            end: assignment.attributes.end_date,
            short_start: assignment.attributes.short_start,
            short_end: assignment.attributes.short_end,
            endForDisplay: assignment.attributes.end_date,
            resourceId: assignment.attributes.user_id ? assignment.attributes.user_id : 0,
            clientName: assignment.attributes.client.name,
            fullAddress: assignment.attributes.location && assignment.attributes.location.full_address,
            technicianName: assignment.attributes.technician_name,
            displayEventTime: true,
            statusColor: assignment.attributes.assignment_color,
            jobId: assignment.attributes.job_id,
            serviceType: assignment.attributes.service_type,
            status: assignment.attributes.status,
            job_status: assignment.attributes.job && assignment.attributes.job.status, 
            isRecurringJob: assignment.attributes.service_agreement.id ? true : false
          } })
          _users.objects.map((user) => {
            events = events.concat(_.compact(user.attributes.vacations));
            return events;
          })
          callback(events);
        })
    }
  }

  loadCalendarUsers(callback) {
    let promise = Api.post(
      '/users/search',
      { role: ['technician', 'admin'], include_unassigned: true }
    );

    promise
      .then((users) => {
        this.users.objects = users.data.data;
        let resources = users.data.data.map((user) => { 
          if(user.id) {
            return { 
              id: user.id, 
              title: user.attributes.full_name,
              avatar_url: user.attributes.avatar_url
            } 
          } else {
            return { 
              id: "0", 
              title: user.attributes.full_name,
              avatar_url: user.attributes.avatar_url
            } 
          }
        });
        const selectedUsers = stores.viewStore.dashboardSearch.technician_id;
        if (selectedUsers && selectedUsers.length > 0) {
          resources = resources.filter(r => selectedUsers.includes(r.id));
        }
        callback(resources);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
        } else {
        }
      })

    return promise;
  }

  // Fix: Vacation load issue on dispatch board first load. 
  fetchDashboardUsers() {
    let promise = Api.post(
      '/users/search',
      { role: ['technician', 'admin'], include_unassigned: true }
    );

    promise
      .then((users) => {
        this.users.objects = users.data.data;
      })
      .catch((error) => {
        
      })

    return promise;
  }

  @action
  loadAssignments(start, end) {
    this.assignments.isLoading = true;
    const selectedUsers = stores.viewStore.dashboardSearch.technician_id;

    const orObject = [];
    if (selectedUsers && selectedUsers.length > 0) {
      orObject.push({
        user_id: selectedUsers && selectedUsers.filter(id => id !== "0" && id !== "selectAll"),
      });
      if (selectedUsers.includes("0")) {
        orObject.push({
          status: "unassigned"
        });
      }
    }
    
    let promise = Api.post('/assignments/search', {
      search: {
        where: {
          service_type_id: stores.viewStore.dashboardSearch.service_type_id,
          '_or': [
            {
              start_date: {
                gte: moment(start).subtract(7, 'days'),
                lte: moment(end).add(7, 'days')
              },
              '_or': orObject
            },
            {
              end_date: {
                gte: moment(start).subtract(7, 'days'),
                lte: moment(end).add(7, 'days')
              },
              '_or': orObject
            }
          ]
        }
      }
    });
    
    promise
    .then((response) => {
      this.assignments.isLoading = false;
      this.assignments.isError = false;
      this.assignments.objects = response.data.data;
      })
      .catch((error) => {
        this.assignments.isLoading = false;
        this.assignments.isError = true;
        console.error(error);
      })
    return promise;
  }

  @action
  loadUsers({ section }) {
    this.users.isLoading = true;
    let promise = Api.post(
      '/users/search',
      { role: section }
    );

    promise
      .then((response) => {
        this.users.isLoading = false;
        this.users.isError = false;
        this.users.objects = response.data.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          this.users.isLoading = true;
        } else {
          this.users.isLoading = false;
          this.users.isError = true;
        }
      })

    return promise;
  }
};

export default DashboardStore;
