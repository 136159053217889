import { observable, action, toJS } from 'mobx';
import { Api, CurrentEnv } from 'services/Api';
import axios from 'axios';
import stores from './index';
import moment from 'moment';
import _ from 'lodash';

const EMPTY_BOOKING_OBJECT = {
  attributes: {
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    address: null,
    suite: null,
    city: null,
    state: '',
    zip: null,
    service_type_id: null,
    service_type_name: '',
    service_type_price:'',
    total:'',
    extra_service_types: [],
    pricing_parameters: [],
    pricing_parameter_ranges: [],
    booking_questions: [],
    booking_attachments: [],
    booking_attachment_ids: [],
    booking_pricing_parameters_attributes: [],
    booking_service_type_extras_attributes:[],
    booking_questions_attributes:[],
    start_date: moment()._d,
    selected_time: null,
    frequency_id: 0,
    frequency:[],
    payment_method: null,
    note: null,
    service_agreements:[],
    scheduled_events:[],
    start_project_expected_period:'',
    contact_way:'',
    service_type_category_id:''

  }
}

class BookingStore {

  @observable tenant_id = null;
  @observable service_id = null;
  @observable showUploadAttachmentLoader = false;
  @observable bookingStep = 1;
  @observable bookingStep1 = true;
  @observable bookingStep2 = false;
  @observable bookingStep3 = false;
  @observable bookingStep4 = false;
  @observable bookingStep5 = false;
  @observable bookingStep6 = false;
  @observable bookingStep7 = false;

  @observable tenant = {
    isLoading: false,
    isError: false,
    object: {}
  };

  @observable booking = {
    isLoading: false,
    isError: false,
    object: EMPTY_BOOKING_OBJECT
  };

  @observable bookingDetail = {
    isLoading: false,
    isError: false,
    object: {}
  };

  @observable bookings = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @action
  createBooking() {
    this.booking.isLoading = true;
    let promise = Api.post(`/bookings/`, {booking: this.booking.object.attributes})   

    promise
      .then((response) => {
        this.booking.isLoading = false;
        this.booking.isError = false; 
        this.booking.object = response.data.data      
        stores.viewStore.bookings(); 
      })
      .catch((error) => {
        this.booking.isLoading = false;
        this.booking.isError = true;       
      })

    return promise;
  }

  @action
  loadBookings = () => {
    this.bookings.isLoading = true;
    const {
      per_page,
      page,
      order,
      where,
      term
    } = stores.viewStore.bookingSearch;
    let promise = Api.post('/bookings/search', { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.bookings.isLoading = false;
        this.bookings.isError = false;
        this.bookings.objects = response.data.data;
        this.bookings.meta = response.data.meta;
      })
      .catch((error) => {
        this.bookings.isLoading = false;
        this.bookings.isError = true;
      })
    
    return promise;
  }

  @action
  loadBooking = (params) => {
    this.booking.isLoading = true;
    let promise = Api.get(`/bookings/${params.uuid}`)

    promise
      .then((response) => {
        this.booking.isLoading = false;
        this.booking.object = response.data.data;
        stores.viewStore.bookingShow(params,response.data.data.attributes.first_name);
      })
      .catch((error) => {
        this.booking.isLoading = false;
        this.booking.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  updateBooking(object) {
    let params = {
      uuid: object.attributes.id
    }
    this.bookingDetail.isLoading = true;
    let promise = Api.put(`/bookings/${object.attributes.id}`, {booking: toJS(object.attributes)})

    promise
      .then((response) => {
        this.bookingDetail.isLoading = false;
        this.bookingDetail.isError = false;
        stores.viewStore.bookingShow(params,response.data.data.attributes.first_name);
        this.loadBooking(params);
      })
      .catch((error) => {
        this.bookingDetail.isLoading = false;
        this.bookingDetail.isError = true;
      })

    return promise;
  }


  @action
  deleteBooking(booking) {
    this.bookings.isLoading = true;
    let promise = Api.delete(`/bookings/${booking.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadBookings();
      })
      .catch((error) => {
        this.bookings.isLoading = false;
        this.bookings.isError = true;
      })
    
    return promise
  }

  @action
  loadBookingForPublicLink(params) {
    this.booking.isLoading = true;
    let promise = Api.get(`/public/bookings/${params.booking_id}?x_tenant_id=${params.tenant_id}`)

    promise
      .then((response) => {
        this.booking.isLoading = false;
        this.booking.object = response.data.data;
        if(response.data.data.attributes.booking_view_style === 'page'){
          stores.bookingStore.bookingStep1 = true;
          stores.bookingStore.bookingStep2 = true;
          stores.bookingStore.bookingStep3 = true;
          stores.bookingStore.bookingStep4 = true;
          stores.bookingStore.bookingStep5 = true;
          stores.bookingStore.bookingStep6 = true;
          stores.bookingStore.bookingStep7 = true;
        }
        // Loading service type details
        stores.serviceTypeStore.loadPublicServiceTypeDetail({uuid:response.data.data.attributes.service_type_id})
        .then((response) => {
          stores.serviceTypeStore.loadPublicPricingParameter()
        });
      })
      .catch((error) => {
        this.booking.isLoading = false;
        this.booking.isError = true;
      })

    return promise;
  }

  @action
  loadTenantForPublicLink(params) {
    this.tenant.isLoading = true;
    let promise = Api.get(`/public/tenants/${params.tenant_id}?x_tenant_id=${params.tenant_id}`)

    promise
      .then((response) => {
        this.tenant.isLoading = false;
        this.tenant.isError = false;
        this.tenant.object = response.data.data;
        if(response.data.data.attributes.booking_view_style === 'page'){
          stores.bookingStore.bookingStep1 = true;
          stores.bookingStore.bookingStep2 = true;
          stores.bookingStore.bookingStep3 = true;
          stores.bookingStore.bookingStep4 = true;
          stores.bookingStore.bookingStep5 = true;
          stores.bookingStore.bookingStep6 = true;
          stores.bookingStore.bookingStep7 = true;
        }
      })
      .catch((error) => {
        this.tenant.isLoading = false;
        this.tenant.isError = true;
      })

    return promise;
  }

  @action
  loadTenantForPublicModal(params) {
    this.tenant.isLoading = true;
    let promise = Api.get(`/public/tenants/${params.tenant_id}?x_tenant_id=${params.tenant_id}`)

    promise
      .then((response) => {
        this.tenant.isLoading = false;
        this.tenant.isError = false;
        this.tenant.object = response.data.data;
        if(response.data.data.attributes.booking_view_style === 'page'){
          stores.bookingStore.bookingStep1 = true;
          stores.bookingStore.bookingStep2 = false;
          stores.bookingStore.bookingStep3 = false;
          stores.bookingStore.bookingStep4 = false;
          stores.bookingStore.bookingStep5 = false;
          stores.bookingStore.bookingStep6 = false;
          stores.bookingStore.bookingStep7 = false;
        }
      })
      .catch((error) => {
        this.tenant.isLoading = false;
        this.tenant.isError = true;
      })

    return promise;
  }

  @action
  updatePublicBooking(object) {
    let params = {
      uuid: object.attributes.id
    }
    this.booking.isLoading = true;
    let promise = Api.put(`/public/bookings/${object.attributes.id}?x_tenant_id=${this.tenant_id}`, {booking: toJS(object.attributes)})

    promise
      .then((response) => {
        this.booking.isLoading = false;
        this.booking.isError = false;
        //stores.viewStore.bookingConfirmationMessage()
      })
      .catch((error) => {
        this.booking.isLoading = false;
        this.booking.isError = true;
      })

    return promise;
  }

  @action
  createPublicBooking(object) {
    this.booking.isLoading = true;
    let promise = Api.post(`/public/bookings?x_tenant_id=${this.tenant_id}`, {booking: toJS(object.attributes)})

    promise
      .then((response) => {
        this.booking.isLoading = false;
        this.booking.isError = false;
        //stores.viewStore.bookingConfirmationMessage()
      })
      .catch((error) => {
        this.booking.isLoading = false;
        this.booking.isError = true;
      })

    return promise;
  }

  @action
  uploadBookingAttachment(file, booking_id = null) {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('booking_id', booking_id)
    this.showUploadAttachmentLoader = true;
    let promise = axios.post(`${CurrentEnv.domain()}/api/v2/bookings/upload_attachments`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Api-Token': stores.viewStore.currentUser.attributes.api_token,
          'X-Tenant':stores.viewStore.currentUser.attributes.tenant_id,
          'X-Client-Identifier': 'React-Web'
        }
      }
    )

    promise
      .then((response) => {
        this.showUploadAttachmentLoader = false;
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = true;
      })

    return promise;

  }

  @action
  removeAttachment(attachment, booking_id = null) {
    this.showUploadAttachmentLoader = true;
    let promise = Api.delete(`/bookings/${attachment.id}/remove_attachment`)

    promise
      .then((response) => {
        if(booking_id) {
          this.showUploadAttachmentLoader = false;
          this.loadBooking({uuid: booking_id}) 
        }
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = false;
      })

    return promise;
  }

  @action
  uploadPublicBookingAttachment(file, booking_id = null) {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('booking_id', booking_id)
    this.showUploadAttachmentLoader = true;
    let promise = axios.post(`${CurrentEnv.domain()}/api/v2/public/bookings/upload_attachments?x_tenant_id=${this.tenant_id}`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Tenant': this.tenant_id,
          'X-Client-Identifier': 'React-Web'
        }
      }
    )

    promise
      .then((response) => {
        this.showUploadAttachmentLoader = false;
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = true;
      })

    return promise;

  }

  @action
  removePublicAttachment(attachment, booking_id = null) {
    this.showUploadAttachmentLoader = true;
    let promise = Api.delete(`/public/bookings/${attachment.id}/remove_attachment?x_tenant_id=${this.tenant_id}`)

    promise
      .then((response) => {
        this.showUploadAttachmentLoader = false;
        if(booking_id) {
          this.loadBookingForPublicLink({booking_id: booking_id, tenant_id: this.tenant_id}) 
        } else {
          // removing from array after delete
          _.remove(this.booking.object.attributes.booking_attachments, function (obj) {
            return obj.id === attachment.id;
          });
        }
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = false;
      })

    return promise;
  }

}

export default BookingStore;