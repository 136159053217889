import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import FooterIllustration from 'components/FooterIllustration';
import MainSiteLogo from 'components/MainSiteLogo';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import { TopNavbar } from 'components/Styles';

@inject('viewStore') @observer
class InvalidResetPasswordToken extends Component {

  render() {
    const {
      props: {
        viewStore
      }
    } = this;
    return(
      <UnauthenticatedPage>
        <Helmet>
          <title>Reset Password | FieldNexus</title>
        </Helmet>
        <TopNavbar>
          <div className="tp-nav">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-8">
                  <MainSiteLogo />
                </div>
                <div className="col-4 text-right">
                  <div className="right-link-tp">
                    <a onClick={() => viewStore.login()} >
                        Login
                    </a>
                  </div>
                </div>
              </div>
            </div>      
          </div>
        </TopNavbar>
        <div></div>
        <div className="text-panel-invalid-page text-center" style={{ backgroundImage: 'url(/pnf-bg.svg)'}}>
          <h1>Password Reset Token Expired or is Invalid. </h1>
          <p>Back to <a onClick={() => viewStore.login()}>Login page</a></p>
        </div>
        <FooterIllustration />
      </UnauthenticatedPage>
    )
  }

}

export default InvalidResetPasswordToken;