import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import { Button, Dialog, Classes, Intent
} from "@blueprintjs/core";
import SignatureCanvas from 'react-signature-pad';
import Toast from 'helpers/toaster';

@inject("quoteStore", "paymentStore")
@observer
class InvoiceSignatureModal extends Component {

  handleCloseQuoteAcceptDialog() {
    this.props.onClose();
  }

  saveSignature() {
    const {
      paymentStore: {
        invoice: { object }
      }
    } = this.props;
    let invoice_signature = {
      customer_signature: this.sigCanvas.toDataURL(),
      invoice_id: object.id,
      accepted: true
    }

    if(this.sigCanvas.isEmpty()) {
      Toast.show({ message: "Please sign before accept & pay!", intent: Intent.DANGER })
      return;
    }

    this.props.paymentStore.invoiceSignature(invoice_signature)
      .then(() => {
        this.props.onSuccess()
      })
    
  }

  render() {
    const {
      paymentStore: {
        showAcceptInvoiceLoader,
        invoice: { object }
      }
    } = this.props;
    return(
      <Dialog
        title="Accept and sign"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseQuoteAcceptDialog.bind(this)}
        style={{minWidth: 500}}
        canOutsideClickClose={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="accept-agreement mb-3">
            <label><strong>Invoice Terms</strong></label>
            <div style={{ backgroundColor: '#fff', border: '1px solid #CED4DA', borderRadius: 5, height: 100, overflow: "auto", padding: 15, fontSize: 12 }}>
              <div dangerouslySetInnerHTML={{ __html: object.attributes.agreement_copy }}></div>
            </div>
          </div>
          <div className="signature-canvas mb-3" style={{ backgroundColor: '#fff', border: '2px solid #CED4DA', borderRadius: 5, width: "100%" }}>
            <SignatureCanvas 
              penColor='#1800ff' 
              clearButton="true"
              ref={(ref) => { this.sigCanvas = ref }}
              canvasProps={{ width: '100%', height: 200, className: 'sigCanvas', backgroundColor: 'white' }} />
          </div>
          <div className="text-right">
            <Button text="Accept & Pay" intent="primary" onClick={this.saveSignature.bind(this)} loading={showAcceptInvoiceLoader} />
          </div>
        </div>
      </Dialog>
    )
  }

}

export default InvoiceSignatureModal;