import React, { Component } from 'react';
import styled from '@emotion/styled';

const StyledParent = styled('div')`
  padding: ${props => `${props.theme.unit*1}px`};
`

class Page extends Component {
  render() {
    return (
      <StyledParent style={this.props.style}>
        {this.props.children}
      </StyledParent>
    )
  }
};

export default Page;