import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  Divider,
  Button,
  FormGroup, InputGroup, Intent,
  Tab, Tabs
} from '@blueprintjs/core';

import Avatar from '@atlaskit/avatar';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Inputmask from 'inputmask';
import AnalyticsCardSupplier from 'components/Common/AnalyticsCardSupplier';
import ClientSuppliersTable from 'components/Client/SuppliersTable';
import ClientPurchaseOrdersTable from 'components/Client/PurchaseOrdersTable';
import Toast from 'helpers/toaster';

const ClientGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: ${props => `${props.theme.unit*4}px`};
  align-items: flex-start;
  
`

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color: #ffffff;
  border: 1px solid #e6e8f1;
`

const AnalyticsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
 
`

@inject('supplierStore','viewStore','workReportStore') @observer
class ClientProfileCardSupplier extends Component {

  componentDidMount() {
    let { supplier } = this.props;
    this.props.supplierStore.loadAnalytics({uuid: supplier.attributes.id})
    Inputmask({ mask: '999-999-9999[999]', skipOptionalPartCharacter: " ", clearMaskOnLostFocus: true, greedy: false }).mask('#phone');
    Inputmask({ alias: 'email' }).mask('#email');
  }

  handleClientChange(event, key) {
    this.props.supplier.attributes[key] = event.target.value;
  }

  changeTableData = (resource) => {
    if(resource){
      this.props.onResourceChange(resource);
    }
  }

  updateSupplier() {
    if(this.props.supplier.attributes.name === "" || this.props.supplier.attributes.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    if(this.props.supplier.attributes.email === "" || this.props.supplier.attributes.email === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid email!', intent: Intent.DANGER })
      return;
    }

    if(this.props.supplier.attributes.phone === '' || this.props.supplier.attributes.phone === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter phone number.', intent: Intent.DANGER })
      return;
    }

    if(this.props.supplier.attributes.address === '' || this.props.supplier.attributes.address === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter address.', intent: Intent.DANGER })
      return;
    }

    this.props.supplierStore.updateSupplier(this.props.supplier)
      .then(this.onSuccessfulUpdateSupplier.bind(this))
      .catch(this.onFailUpdateSupplier.bind(this))
  }

  onSuccessfulUpdateSupplier(response) {
  }

  onFailUpdateSupplier(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: "Something went wrong!", intent: Intent.DANGER })
    }
  }

  resourceData = () => {
    let { supplier, selectedResource } = this.props;
    if(selectedResource === 'materials'){
      return(
        <React.Fragment>
          {/* <TableHeading heading="Materials" /> */}
          <ClientSuppliersTable supplier={supplier} />
        </React.Fragment>
      )
    }else if(selectedResource === 'purchase_orders'){
      return(
        <React.Fragment>
          {/* <TableHeading heading="Purchase Order" /> */}
          <ClientPurchaseOrdersTable supplier={supplier} />
        </React.Fragment>
      )
    }
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        materialLineItemSearch
      }
    } = this.props;
    this.props.viewStore.materialLineItemSearch = {
      ...materialLineItemSearch,
      page: newPage
    };
    this.props.workReportStore.loadMaterialLineItems();
  }
  
  render() {
    let { 
      supplier, 
      selectedResource,
      supplierStore:{
        supplierAnalytics
      }
    } = this.props;

  

    let nameForAvatar = supplier.attributes.name.replace(/'/, '').replace(/\(/, '').replace(/\)/, '');
    return(
      <div className="client-card-grid">
        <ClientGrid class="full-width">
          <Card>
            <CardContent>
              <div style={{ textAlign: 'center', paddingBottom: '8px' }}>
                <Avatar
                  borderColor={'#CCCED2'}
                  src={encodeURI(`https://ui-avatars.com/api/?name=${nameForAvatar}&size=256`)}
                  size='xlarge'
                />
                <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                  {supplier.attributes.name}
                </h4>
                <h6 className={'bp3-heading bp3-text-muted'}>
                  {[supplier.attributes.phone, supplier.attributes.email].join(' | ')}
                </h6>
              </div>
              <Divider style={{ margin: '16px 0px' }}></Divider>
              <FormGroup label="Name" labelInfo="*">
                <InputGroup placeholder="Supplier Name" defaultValue={supplier.attributes.name}  onChange={(event) => { this.handleClientChange(event, 'name') }} />
              </FormGroup>


              <FormGroup label="Email" labelInfo="*">
                <InputGroup placeholder="Email" defaultValue={supplier.attributes.email} id='email' onChange={(event) => { this.handleClientChange(event, 'email') }} />
              </FormGroup>

              <FormGroup label="Phone" labelInfo="*">
                <InputGroup placeholder="Phone" defaultValue={supplier.attributes.phone} id='phone' onChange={(event) => { this.handleClientChange(event, 'phone') }} />
              </FormGroup>

              <FormGroup label="Address" labelInfo="*">
                <InputGroup placeholder="Address" defaultValue={supplier.attributes.address} onChange={(event) => { this.handleClientChange(event, 'address') }} />
              </FormGroup>
              <Button icon="floppy-disk" text="Update" onClick={this.updateSupplier.bind(this)} />
            </CardContent>
          </Card>
          <div className="supplier-cards responsive-layout-analytics-card">
            <AnalyticsContainer className="client-card-warper">
              
              
              {
                supplierAnalytics.objects.map((analytics) => {
                  return (
                    <AnalyticsCardSupplier
                      key={Math.random()}
                      cardTitle={analytics.name}
                      cardId={supplier.attributes.id}
                      count={analytics.total_count}                    
                      active={selectedResource === 'materials'}
                      breakdown={analytics.breakdown || []}
                    />
                  )
                })
              }
            </AnalyticsContainer>
            <Tabs id="ClientRecords" onChange={this.changeTableData.bind(this)} selectedTabId={selectedResource}>
              {
                supplierAnalytics.objects && supplierAnalytics.objects.length > 0 && supplierAnalytics.objects.map((analytic) => {
                  return(
                    <Tab 
                      id={analytic.id} 
                      title={analytic.name} 
                      key={Math.random()}
                    />
                  )
                })
              }
            </Tabs>
            <div style={{minHeight: 20}}></div>
            { this.resourceData() }
          </div>
        </ClientGrid>
      </div>
    )
  }
}

export default ClientProfileCardSupplier;