import React, { Component } from 'react';
import posed from 'react-pose';
import { inject, observer } from 'mobx-react';
import Toast from 'helpers/toaster';
import Banner from '@atlaskit/banner';
import { Intent } from '@blueprintjs/core';

const Box = posed.div({
  visible: { height: 'auto' },
  hidden: { height: 0 },
});

@inject('store') @observer
class AppOfflineBanner extends Component {

  componentWillMount() {
    window.addEventListener('offline', () => {
      this.props.store.isOffline = true;
    })

    window.addEventListener('online', () => {
      this.props.store.isOffline = false;
      Toast.show({ icon: 'thumbs-up', message: 'You are back online!', intent: Intent.SUCCESS })
    })

    if (!navigator.onLine) {
      this.props.store.isOffline = true;
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('online');
    // window.removeEventListener('offline');
  }

  render() {
    const {
      props: {
        store: {
          isOffline
        }
      }
    } = this;
    return(
      <Box className="offline-notification" pose={isOffline ? 'visible' : 'hidden'}>
        <Banner isOpen={isOffline} appearance={'warning'}>
          It seems you are not connected to the internet. Please check your network settings.
        </Banner>
      </Box>
    )
  }
}

export default AppOfflineBanner;
