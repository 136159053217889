import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable, toJS } from "mobx";
import { 
  Button, Dialog, Classes, Intent, FormGroup, TextArea, Checkbox,
  MenuItem
} from "@blueprintjs/core";
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";
import Toast from 'helpers/toaster';
import MultipleEmailSelector from '../MultipleEmailSelector';


@inject("quoteStore", "paymentStore", "invoiceStore")
@observer
class SendQuoteModal extends Component {

  componentDidMount() {
    let { object } = this.props.quoteStore.sendQuote;
    object.emailSubject = "Estimate [#"+this.props.quoteStore.quote.object.attributes.number+"] from "+this.props.quoteStore.quote.object.attributes.tenant.name
  }

  handleCloseQuoteInvoiceDialog() {
    this.props.onClose();
  }

  SendInvoiceToEmail() {
    let {
      props: {
        quoteStore: {
          sendQuote: { object, isLoading }
        }
      }
    } = this;
    
    if(!object.emailTo) {
      Toast.show({ icon: 'warning-sign', message: "Please enter email", intent: Intent.DANGER })  
      return  
    }

    if(!object.emailSubject) {
      Toast.show({ icon: 'warning-sign', message: "Please enter email subject", intent: Intent.DANGER })
      return     
    }

    // if(!object.emailMessage) {
    //   Toast.show({ icon: 'warning-sign', message: "Please enter message", intent: Intent.DANGER })  
    //   return   
    // }
    this.props.quoteStore.sendQuote.isLoading = true;
    this.props.quoteStore.SendQuoteToEmail()
      .then(response => {
        this.props.onClose();
        Toast.show({
          icon: "thumbs-up",
          message: "Quote sent successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to send quote!",
          intent: Intent.DANGER
        });
      })
      .finally(() => {
        this.props.quoteStore.sendQuote.isLoading = false;
      })
  }

  onEmailChange = (emails) => {
    let {
      props: {
        quoteStore: {
          sendQuote: { object, isLoading }
        }
      }
    } = this;
    object.emailTo = emails.map((contact) => contact.email);
  }

  render() {
    const {
      quoteStore: {
        contacts,
        sendQuote : { object, isLoading },
        quote
      }
    } = this.props;
    return(
      <Dialog
        title="Send Estimate"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseQuoteInvoiceDialog.bind(this)}
        // style={{minWidth: 500}}
        canOutsideClickClose={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="col-12">
            <FormGroup label="To" labelFor="text-input">
              <MultipleEmailSelector 
                onEmailChange={this.onEmailChange.bind(this)}
                contacts={
                  quote.object.attributes && quote.object.attributes.client ? [
                    {
                      email: quote.object.attributes.client.email,
                      name: quote.object.attributes.client.name
                    } 
                  ] : []
                }
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup label="Subject" labelFor="text-input">
              <input
                type="text"
                className="bp3-input bp3-fill"
                placeholder="Subject"
                value={object.emailSubject}
                onChange={event => {
                  object.emailSubject = event.target.value;
                }}
              />
          </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup label="Message" labelFor="text-input">
              <TextArea
                className="bp3-fill"
                placeholder="Enter Your Message"
                onChange={event => {
                  object.emailMessage = event.target.value;
                }}
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <div style={{ marginBottom: '1rem'}} className="invoice_enable_signature">
              <Checkbox 
                label="Send a copy to myself"
                onChange={event => {
                  object.emailCopyToSelf = event.target.checked;
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <div style={{ marginBottom: '1rem'}} className="invoice_enable_signature">
              <Checkbox
                label="Attach as PDF"  
                onChange={event => {
                  object.emailAttached = event.target.checked;
                }}
              />
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              fill="false"
              onClick={this.handleCloseQuoteInvoiceDialog.bind(this)}
              style={{ width: "100px" }}
            >
              Cancel{" "}
            </Button>
            <Button
              fill="false"
              intent={Intent.PRIMARY}
              onClick={this.SendInvoiceToEmail.bind(this)}
              style={{ width: "80px" }}
              loading={this.props.quoteStore.sendQuote.isLoading}
            >
              Send
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }

}

export default SendQuoteModal;