import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from '@emotion/styled';
import qs from 'qs';
import { observable } from 'mobx';

const SecondSidebar = styled('div')`     
    width: 100%;
    height: calc(100vh - 75px);
    border-right: 1px solid #E6E8F1;
    padding: 0px 0px 15px;       
    background-color:#ffffff;   
    position: sticky;
    top: 45px;    
`

@inject('viewStore') @observer
class ProjectSidebar extends Component {

  @observable isOpenProjectSidebar = false;

  render(){
    let {
      viewStore
    } = this.props;
    return(
      <SecondSidebar className="sidebar-mobile">
        <div className="mobile-dropdown">
          <div className="setting-icon icon-top"><img src="/settings.svg" onClick={() => this.isOpenProjectSidebar = !this.isOpenProjectSidebar}/></div> 
          <div className={this.isOpenProjectSidebar ? "ssidebar-wrap " : "ssidebar-wrap ssidebar-wrap-hide" }>            
            <ul className="list-unstyled setting-list">              
              <li className={viewStore.currentView === "Pages.Projects.Index" ? 'active' : ''}>
                <a href={`/dashboard/projects?${qs.stringify(viewStore.projectSearch, { encode: false })}`} >Projects</a>
              </li>
              <li className={viewStore.currentView === "Pages.WorkReports.MaterialOrders" ? 'active' : ''}>
                <a href={`/dashboard/materialorders?${qs.stringify(viewStore.materialLineItemSearch, { encode: false })}`} >Material Ordering Queue</a>
              </li>
              <li className={viewStore.currentView === "Pages.WorkReports.PurchaseOrders" ? 'active' : ''}>
                <a href={`/dashboard/purchase-orders`} >Purchase Orders</a>
              </li>
            </ul>            
          </div>
        </div>
      </SecondSidebar>
    )
  }
}
  
export default ProjectSidebar;