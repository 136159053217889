import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from 'mobx';
import { 
  Divider, Intent,
  Button, Checkbox,
  FormGroup
} from '@blueprintjs/core';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Toast from "helpers/toaster";
import ServiceTypePicker from "components/ServiceTypePicker";
import BookingsSidebar from '../bookings/bookings-sidebar';
import { Helmet } from 'react-helmet';

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`

@inject("serviceTypeExtraStore")
@observer
class ServiceTypeExtraAddPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isQuantity: false,
      isFrequency: false,
      isDiscount: false,
      isRecurring: false,
      isBooking: false,
    };
  }

  componentDidMount() {
    let {
      serviceTypeExtraStore: {
        serviceTypeExtraDetail: {
          object
        }
      }
    } = this.props;
  }

  createServiceTypeExtra() {
    let {
      props: {
        serviceTypeExtraStore: {
          serviceTypeExtraDetail: { 
            object
           }
        }
      }
    } = this;

    if(object.name === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    if(object.service_type_extra_services_attributes.length === 0) {
      Toast.show({ icon: 'warning-sign', message: 'Please select service type!', intent: Intent.DANGER })
      return;
    }

    object.quantity_based = this.state.isQuantity;
    object.frequency_discounts = this.state.isFrequency;
    object.discount_code_discounts = this.state.isDiscount;
    object.recurring_series = this.state.isRecurring;
    object.first_booking = this.state.isBooking;

    this.props.serviceTypeExtraStore
      .createServiceTypeExtra()
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Service type extra created successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to create service type extra!",
          intent: Intent.DANGER
        });
      });
  }

  onServiceTypeSelect(selectedOptions) {
    let {
      props: {
        serviceTypeExtraStore: {
          serviceTypeExtraDetail: { 
            object
           }
        }
      }
    } = this;
    
    object.service_type_extra_services_attributes = [];

    selectedOptions && selectedOptions.map((values, index) => {
      if(values.selected === true){
        return(
          object.service_type_extra_services_attributes.push({
            service_type_id:values.value
          })
        )
      }
    })

  }

  quantityChange = () => {
    this.setState({
      isQuantity: !this.state.isQuantity,
    });
  }

  bookingChange = () => {
    this.setState({
      isBooking: !this.state.isBooking
    });
  }

  recurringChange = () => {
    this.setState({
      isRecurring: !this.state.isRecurring,
    });
  }

  discountChange = () => {
    this.setState({
      isDiscount: !this.state.isDiscount,
    });
  }

  frequencyChange = () => {
    this.setState({
      isFrequency: !this.state.isFrequency,
    });
  }

  handleChangeImage = (event) => {
    let {
      serviceTypeExtraStore: {
        serviceTypeExtraDetail: {
          object
        }
      }
    } = this.props;
  
    var file = event.target.files[0];
    var reader = new FileReader();
    let base64String = '';
    
    reader.onload = function() {
      base64String = reader.result;
      object.icon_file = base64String;
    };
    reader.readAsDataURL(file);
  };

  render() {
    let {
      serviceTypeExtraStore,
      serviceTypeExtraStore: {
        serviceTypeExtraDetail: {
          object,
          isLoading
        }
      }
    } = this.props;
    
    return (
      <div className="row no-gutters">
        <Helmet>
          <title>Service Type Extra | FieldNexus</title>
        </Helmet>
        <div className="col-lg-2 sidebar-responsive">
          <BookingsSidebar />
        </div>
        <div className="col-lg-10">
          <PageWrapGray>
            <div className="pricing-parameter-table bookings-sub-forms">
              <Card>
                <CardContent>
                  <div>
                    <div className="left-side-panel-servie-type">
                      <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                        Add Service Type Extra
                      </h4>
                    </div>
                  </div>
                  <Divider style={{ margin: '16px 0px' }}></Divider>
                  <div className="left-side-panel-servie-type pricing-parameter">
                    <FormGroup label="Name" >
                      <input 
                        type='text' 
                        className='form-control' 
                        placeholder='Name'
                        defaultValue={object.name}
                        onChange={(event) => {object.name = event.target.value;}}            
                      />
                    </FormGroup>
                    <FormGroup label="Description" >
                      <input 
                        type='text' 
                        className='form-control' 
                        placeholder='Description'
                        defaultValue={object.description}
                        onChange={(event) => {object.description = event.target.value;}}            
                      />
                    </FormGroup>
                    <FormGroup label="Icon" labelFor="text-input" labelInfo="" style={{ display: 'flex' }}>
                      <div className="service-image-main">
                        <span className="btn btn-light btn-sm btn-file">
                            Change Icon <input type="file" onChange={this.handleChangeImage.bind(this)} accept="image/x-png,image/gif,image/jpeg" />
                        </span>
                        <div className="service-image-preview">
                          {
                            object && object.icon_file ?
                            <img src={object && object.icon_file}  height='40' alt='icon'/> 
                            :''
                          }
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup 
                      label="Service Type"
                      labelFor="text-input"
                      labelInfo="(*)"
                    >
                      <ServiceTypePicker
                        onChange={(event) => {this.onServiceTypeSelect(event)} }
                      />
                    </FormGroup>
                    <FormGroup labelFor="text-input" labelInfo="">
                      <div className="bookings-radio">
                        <Checkbox 
                          inline
                          large={false}
                          defaultChecked={object.quantity_based || ''}
                          onChange={this.quantityChange} >
                            Quantity Based
                        </Checkbox>
                      </div>
                    </FormGroup>
                    <FormGroup label="Price ($)" >
                      <input 
                        type='number' 
                        className='form-control' 
                        placeholder='Price'
                        defaultValue={object.price}
                        onChange={(event) => {object.price = event.target.value;}}            
                      />
                    </FormGroup>
                  </div>
                  <div className="left-side-panel-servie-type">
                    <FormGroup label="Duration (In mins)" >
                      <input 
                        type='number' 
                        step={"1"}
                        className='form-control' 
                        placeholder='Duration'
                        defaultValue={object.duration}
                        onChange={(event) => {object.duration = event.target.value;}}            
                      />
                    </FormGroup>
                  </div>
                  <div className="left-side-panel-servie-type pricing-parameter">
                    {/* <FormGroup label="Exclude From" >
                      <input type="checkbox" defaultChecked={object.frequency_discounts || ''} 
                        name="frequency_discounts" onChange={this.frequencyChange} 
                      /> 
                        Frequency 
                      <input type="checkbox" style={{ marginLeft: '15px' }} 
                        defaultChecked={object.discount_code_discounts || ''} 
                        name="discount_code_discounts" onChange={this.discountChange} 
                      /> 
                      Discount 
                    </FormGroup> */}
                    <FormGroup label="Exclude From" labelFor="text-input" labelInfo="">
                      <div className="bookings-radio">
                        <Checkbox 
                          inline
                          large={false}
                          defaultChecked={object.frequency_discounts || ''} 
                          onChange={this.frequencyChange}>
                            Frequency
                        </Checkbox>
                        <Checkbox 
                          inline
                          large={false}
                          defaultChecked={object.discount_code_discounts || ''} 
                          onChange={this.discountChange}>
                            Discount
                        </Checkbox>
                      </div>
                    </FormGroup>
                    {/* <FormGroup label="Recurring Policy" >
                      <input type="checkbox" defaultChecked={object.recurring_series || ''} 
                        name="recurring_series" onChange={this.recurringChange} 
                      /> 
                      Recurring 
                      <input type="checkbox" style={{ marginLeft: '15px' }}
                        defaultChecked={object.first_booking || ''} 
                        name="first_booking" onChange={this.bookingChange} 
                      /> 
                      First Booking 
                    </FormGroup> */}
                    <FormGroup label="Recurring Policy" labelFor="text-input" labelInfo="">
                      <div className="bookings-radio">
                        <Checkbox 
                          inline
                          large={false}
                          defaultChecked={object.recurring_series || ''} 
                          onChange={this.recurringChange}>
                            Recurring
                        </Checkbox>
                        <Checkbox 
                          inline
                          large={false}
                          defaultChecked={object.first_booking || ''} 
                          onChange={this.bookingChange}>
                            First Booking
                        </Checkbox>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="">
                    <Button className="btn-save" loading={isLoading} onClick={this.createServiceTypeExtra.bind(this)}>Save</Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </PageWrapGray>
        </div>
      </div>
    );
  }
}

export default ServiceTypeExtraAddPage;
