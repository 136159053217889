import React, { Component } from 'react'
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Pagination from 'components/Pagination';
import { 
  Button, Dialog, Classes,
  Checkbox, ControlGroup,
  Alert, Intent, FormGroup, Divider
} from '@blueprintjs/core';
import DateTimeInput from 'components/DateTimeInput';
import Footer from '../Footer';
import moment from 'moment';
import Toast from 'helpers/toaster';
import qs from 'qs';
import { formatTimeOnly, formatDateOnly } from 'helpers/time';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';
import { StatusPill, OvertimePill } from 'components/TimelogPills';


@inject('store', 'timesheetStore', 'viewStore') @observer
class TimesheetsTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable isEditDialogVisible = false;
  @observable timelog;
  @observable status;
  @observable over_time;

  onPaginationChange(newPage) {
    let {
      viewStore: {
        timesheetSearch
      }
    } = this.props;
    this.props.viewStore.timesheetSearch = {
      ...timesheetSearch,
      page: newPage
    };
    this.props.viewStore.timesheets();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.timesheetStore.deleteTimelog(this.timelog)
      .then(this.onSuccessfulDeleteTimelog.bind(this))
      .catch(this.onFailedTimelogDelete.bind(this))
  }

  onSuccessfulDeleteTimelog(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Timelog deleted successfully!', intent: Intent.SUCCESS })
    this.timelog = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedTimelogDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete timelog!', intent: Intent.DANGER })
  }

  @action
  selectAllTimelogs = (event) => {
    let {
      timesheetStore,
      timesheetStore: {
        timelogs
      }
    } = this.props;
    timesheetStore.allEntriesSelected = event.target.checked;
    timelogs.objects.forEach((timelog) => {
      timelog.checked = event.target.checked;
    })
  }

  selectTimeEntry = (event, timelog) => {
    timelog.checked = event.target.checked;
  }

  handleTimeLogClick(event, timelog) {
    switch (event.label) {
      case 'Edit':
        this.isEditDialogVisible = true;
        this.timelog = timelog;
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.timelog = timelog;
        break;
      default:
    }
  }

  handleCloseTimelogDailog = () => {
    this.isEditDialogVisible = false;
  }

  updateTimelog() {
    if(!this.status){
      this.status = this.timelog.attributes.status;
    }else{
      this.timelog.attributes.status = this.status;
    }

    if(!this.over_time){
      this.over_time = this.timelog.attributes.is_overtime;
    }else{
      this.timelog.attributes.is_overtime = this.over_time;
    }
    
    if(moment(this.timelog.attributes.start_time) > moment(this.timelog.attributes.end_time)) {
      Toast.show({ icon: 'warning-sign', message: 'Start time cannot be greater than end time', intent: Intent.DANGER })
      return;
    }

    if(!this.timelog.attributes.status) {
      Toast.show({ icon: 'warning-sign', message: 'Please select status.', intent: Intent.DANGER })
      return;
    }

    this.props.timesheetStore.update(this.timelog)
      .then(this.onSucessfulUpdateTimelog.bind(this))
      .catch(this.FailUpdateTimelog.bind(this))
  }

  onSucessfulUpdateTimelog(response) {
    this.isEditDialogVisible = false;
    Toast.show({ icon: 'thumbs-up', message: 'Timelog updated successfully!', intent: Intent.SUCCESS })
  }

  FailUpdateTimelog(error) {
    this.isEditDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Failed to update timelog', intent: Intent.DANGER })
  }

  onStartTimeChange = (time) => {
    this.timelog.attributes.start_time = time;
  }

  onEndTimeChange = (time) => {
    this.timelog.attributes.end_time = time;
  }

  handleStatusChange = (event) => {
    //this.timelog.attributes.status = event.target.value;
    this.status = event.target.value;
  }

  handleOvertimeChange = (event) => {
    this.over_time = event.target.checked;
    this.timelog.attributes.is_overtime = event.target.checked
  }

  render() {
    let {
      viewStore,
      viewStore: {
        jobTab: {
          section
        }
      },
      timesheetStore: {
        timelog: {
          isLoading
        },
        timelogs: {
          objects,
          meta
        },
        allEntriesSelected
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
        <table className="table table-data-presentation table-hover" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th title="Select All">
                <Checkbox
                  onChange={this.selectAllTimelogs.bind(this)}
                  checked={allEntriesSelected}
                />
              </th>
              <th>Project</th>
              <th>Job</th>
              <th>Created</th>
              <th>Start & End Time</th>
              {/* <th>End Time</th> */}
              <th>Time Logged</th>
              {/* <th>Overtime</th> */}
              <th>Technician</th>
              <th>Client</th>
              {/* <th>Location</th> */}
              {/* <th>Service Type & Status</th> */}
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((timelog) => {
                
                return (
                  <tr key={timelog.id}>
                    <td>
                      <Checkbox 
                        checked={timelog.checked} 
                        onChange={(event) => { this.selectTimeEntry(event, timelog) }}
                      />
                    </td>
                    <td>
                      {
                        timelog.attributes.project &&
                          <a href={`/dashboard/projects/${timelog.attributes.project.id}/show?${qs.stringify(viewStore.projectShowSearch, { encode: false })}`}>
                            {timelog.attributes.project.number}
                          </a>
                      }
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={timelog.attributes.job && `/dashboard/jobs/${timelog.attributes.job.id}/${section}`}
                        mainText={timelog.attributes.job && timelog.attributes.job.event_number}
                        subText={timelog.attributes.service_type}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(timelog.attributes.created_at)}
                        subText={`${formatTimeOnly(timelog.attributes.created_at)}`}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(timelog.attributes.start_time)}
                        subText={`${formatTimeOnly(timelog.attributes.start_time)} - ${formatTimeOnly(timelog.attributes.end_time)}`}
                      />
                    </td>
                    <td>
                      <div style={{display: 'flex', marginBottom: 5}}>
                        <div className="field-title" style={{marginRight: 5}}>
                          {timelog.attributes.formatted_duration}
                        </div>
                      </div>
                      <StatusPill timelog={timelog} /> &nbsp;
                      <OvertimePill timelog={timelog} />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/users/${timelog.attributes.user.id}/show?${qs.stringify(viewStore.userShowSearch, { encode: false })}`}
                        mainText={timelog.attributes.technician_name}
                        subText={timelog.attributes.user.email}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={timelog.attributes.client_name}
                        subText={timelog.attributes.location_address}
                      />
                    </td>
                    <td>
                      <div className="action-buttons">
                        <Button title="Edit" minimal={true} small={true} onClick={() => this.handleTimeLogClick({ label: 'Edit' }, timelog)}>
                          <img src="/edit-light-icon.svg"/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleTimeLogClick({ label: 'Delete' }, timelog)}>
                          <img src="/delete-light-icon.svg" />
                        </Button>
                      </div>
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to delete timelog?
          </p>
        </Alert>
        
        {this.timelog && this.timelog.attributes.id && <Dialog
          title="Edit Timelog"
          icon="time"
          isOpen={this.isEditDialogVisible}
          isCloseButtonShown={false}
          canOutsideClickClose={false}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              label="When?"
              labelFor="text-input"
              labelInfo="(*)"
            >
              <ControlGroup fill={true} vertical={false}>
                <DateTimeInput 
                  placeholder={'Start Time'}
                  defaultValue={moment(this.timelog.attributes.start_time)}
                  onChange={this.onStartTimeChange.bind(this)}
                />
                <DateTimeInput 
                  placeholder={'End Time'} 
                  defaultValue={moment(this.timelog.attributes.end_time)} 
                  onChange={this.onEndTimeChange.bind(this)} 
                />
              </ControlGroup>
            </FormGroup>

            <FormGroup
              label="Status"
              labelFor="text-input"
              labelInfo="(*)"
            >
              <div className="bp3-select bp3-fill">
                <select defaultValue={this.timelog.attributes.status} onChange={this.handleStatusChange.bind(this)} >
                  <option value=''>Select Status</option>
                  <option value='draft'>Draft</option>
                  <option value='approved'>Approved</option>
                  <option value='rejected'>Rejected</option>
                </select>
              </div>
            </FormGroup>

            <FormGroup
              label="Is Overtime?"
              labelFor="checkbox"
            >
              <Checkbox defaultChecked={this.timelog.attributes.is_overtime} onChange={this.handleOvertimeChange}></Checkbox>
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.handleCloseTimelogDailog.bind(this)}>Close</Button>
              <Button icon="floppy-disk" loading={isLoading} onClick={this.updateTimelog.bind(this)}>Update</Button>
            </div>
          </div>
        </Dialog>}
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </React.Fragment>
    )
  }
}

export default TimesheetsTable;