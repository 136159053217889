import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import { 
  NonIdealState, Spinner
} from "@blueprintjs/core";
import DefaultLineItemsList from 'components/Preference/DefaultLineItems'

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`

const ThinDrawer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

@inject('store', 'viewStore', 'preferenceStore') @observer
class DefaultLineItems extends Component {

  componentDidMount() {
    let { preferenceStore } = this.props;
    preferenceStore.loadPreferences()
  }

  get canRender() {
    let {
      preferenceStore: {
        preference: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object);
  }

  render() {
    let content;
    let {
      preferenceStore: {
        preference: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      content = <DefaultLineItemsList object={object.attributes.default_line_items} />
    }
    return(
      <div className="default-page-layout">
        <Helmet>
          <title>Default Line Items | FieldNexus</title>
        </Helmet>
        {/* <TaxesBanner>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>Default Line Items</h3>
          </div>
        </TaxesBanner> */}
        
          <ThinDrawer>
            {content}
          </ThinDrawer>
       
      </div>
    )
  }

};

export default DefaultLineItems;