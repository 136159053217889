import React, { Component } from 'react'
import { 
  Button, ButtonGroup, Popover,
  Menu, MenuItem, Position
} from '@blueprintjs/core';
import { observable, action, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import ClientAutocomplete from 'components/ClientAutocomplete';
import LocationPicker from 'components/LocationPicker';

@inject('store', 'viewStore', 'equipmentStore') @observer
class Equipment extends Component {

  @observable selectedClient = '';
  @observable showLocationFilter = false;

  @action
  onClientSelect(client) {
    let {
      props: {
        viewStore: {
          equipmentSearch
        }
      }
    } = this;
    if (client) {
      this.props.viewStore.equipmentSearch = {
        ...equipmentSearch,
        where: {
          ...equipmentSearch.where,
          client_id: client.id
        }
      }
      this.showLocationFilter = true;
    } else {
      this.props.viewStore.equipmentSearch = {
        ...equipmentSearch,
        where: {
          location_id: ""
        }
      }
      this.showLocationFilter = false;
    }
  }

  onLocationChange(locations) {
    let selectedValues = locations.map((location) => location.value);
    let {
      props: {
        viewStore: {
          equipmentSearch
        }
      }
    } = this;
    this.props.viewStore.equipmentSearch = {
      ...equipmentSearch,
      where: {
        ...equipmentSearch.where,
        location_id: selectedValues
      }
    }
  }

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.equipmentSearch.page = 1;
    viewStore.equipments();
  }

  download_bulk_excel() {
    this.props.equipmentStore.download_bulk_excel(this.props.viewStore.equipmentSearch)
  }

  render() {
    let {
      props: {
        viewStore: {
          equipmentSearch: {
            where
          }
        }
      }
    } = this
    where = toJS(where);
    return (
      <nav className="bp3-navbar search bg-transparent">
        <div>
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <ClientAutocomplete
                  selectedClientId={where && where.client_id}
                  onClientSelect={this.onClientSelect.bind(this)}
                />

                {this.showLocationFilter && <LocationPicker
                      selectedOptions={(where && where.location_id) || []}
                      onChange={this.onLocationChange.bind(this)}
                    />
                }
                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
              </div>
            </div>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            <ButtonGroup>
              <Popover 
                minimal={true}
                content={
                  <Menu>
                    <MenuItem text="Download Excel/CSV" onClick={this.download_bulk_excel.bind(this)}/>
                  </Menu>
                } 
                position={Position.BOTTOM_LEFT}
                usePortal={true}>
                <Button rightIcon='caret-down'>
                  Options 
                </Button>
              </Popover>
            </ButtonGroup>
          </div>
        </div>
      </nav>
    )
  }

}

export default Equipment;