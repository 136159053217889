import React, { Component } from 'react'
import { 
  Button, ButtonGroup, Popover,
  Menu, MenuItem, Position
} from '@blueprintjs/core';
import { action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import ProductServiceModal from 'components/ProductsServices/ProductServiceModal';

@inject('store', 'viewStore','productServiceStore') @observer
class ProductService extends Component {
  @observable isProductServiceDialogVisible = false;
  @observable itemType = "Labor";

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.productServiceIndexSearch.page = 1;
    this.props.productServiceStore.loadProductsServices();
  }

  onCloseProductServiceDialog() {
    this.isProductServiceDialogVisible = false;
  }

  onSucessCreateProductService(productService) {
    this.isProductServiceDialogVisible = false;
  }

  @action
  onSearchTermChange(e) {

    let {
      props: {
        viewStore: {
          productServiceIndexSearch
        }
      }
    } = this;
    this.props.viewStore.productServiceIndexSearch = {
      ...productServiceIndexSearch,
      term: e.target.value
    }
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.search()
    }
  }

  onChangeItemTypeSelector(event) {
    this.itemType = event.target.value;
  }

  render() {
    let {
      props: {
        store,
        viewStore,
        viewStore: {
          productServiceIndexSearch: { term }
        }
      }
    } = this;
    if(term == '*'){
      term = '';
    }
    return (
      <nav className="bp3-navbar search bg-transparent">
        <div className="booking-request-search">
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <input 
                  type="text"
                  className="bp3-input"
                  placeholder="Name"
                  style={{ width: '300px' }}
                  onChange={this.onSearchTermChange.bind(this)}
                  defaultValue={term}
                  onKeyPress={this.onEnter.bind(this)}
                />
                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
              </div>
            </div>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            <Button icon={'plus'} small={true} onClick={() => this.isProductServiceDialogVisible=true}>
              Add Product Service
            </Button>
          </div>
        </div>
        <ProductServiceModal 
          isOpen={this.isProductServiceDialogVisible} 
          title={"Add Product/Service"}
          edit={false}
          onClose={this.onCloseProductServiceDialog.bind(this)}
          onSuccess={this.onSucessCreateProductService.bind(this)}
          onChangeItemTypeSelector={this.onChangeItemTypeSelector.bind(this)}
          itemType={this.itemType}
          showItemTypeSelector={true}
        />
      </nav>
    )
  }
}

export default ProductService;