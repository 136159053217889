import React, { Component } from 'react'
import { Button, ButtonGroup } from '@blueprintjs/core';
import { action } from 'mobx';
import { observer, inject } from 'mobx-react';

@inject('store', 'viewStore') @observer
class SupplierSearch extends Component {

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.supplierSearch.page = 1;
    this.props.viewStore.suppliers();
  }

  @action
  onSearchTermChange(e) {
    
    let {
      props: {
        viewStore: {
          supplierSearch
        }
      }
    } = this;
    this.props.viewStore.supplierSearch = {
      ...supplierSearch,
      term: e.target.value
    }
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.search()
    }
  }

  render() {
    let {
      props: {
        store,
        viewStore: {
          supplierSearch: { term }
        }
      }
    } = this;
    if(term == '*'){
      term = '';
    }
    return (
      <nav className="bp3-navbar search search-bar-responsive bg-transparent">
        <div>
          <div className="bp3-navbar-group search-responsive bp3-align-left">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <input 
                  type="text"
                  className="bp3-input input-control"
                  placeholder="Supplier Name, Number, Email or Address"
                  onChange={this.onSearchTermChange.bind(this)}
                  defaultValue={term}
                  onKeyPress={this.onEnter.bind(this)}
                />
                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
                {/* <Button rightIcon='refresh' text='Reset' onClick={() => { this.search = {} }}/> */}
                {/* <Button rightIcon='grouped-bar-chart' onClick={() => { this.props.store.visible = !this.props.store.visible }}/> */}
              </div>
            </div>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            <ButtonGroup>
              {/* <Button icon='plus' onClick={() => { store.isOpenAddClientDialog = true }}>
                Add New Client
              </Button> */}
              {/* <Button rightIcon='caret-down'>
                Options
              </Button> */}
            </ButtonGroup>
          </div>
        </div>
      </nav>
    )
  }
}

export default SupplierSearch;