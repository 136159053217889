import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup,Tab, Tabs, Button, Alert, Dialog, Classes, Spinner, 
  Intent,
  Menu, MenuItem, Position
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import Footer from 'components/Footer';
import ProductAndServiceTable from 'components/WorkReport/ProductAndServiceTable';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import ProductServiceModal from 'components/ProductsServices/ProductServiceModal';

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;  
  min-height: calc(100vh - 75px);
`

@inject('equipmentStore','store','viewStore','invoiceStore','productServiceStore') @observer
class AddProductForInvoiceModal extends Component {
  
  @observable selectedTabId = 'Products (Non-inventory)';
  @observable isProductServiceDialogVisible = false;
  
  handleTabChange = (tabId) => {
    this.selectedTabId = tabId; 
    if(tabId === 'Products (Non-inventory)'){
      this.props.viewStore.productServiceSearch.where.product_service_type = 'Products (Non-inventory)';
      this.props.productServiceStore.loadProductAndServicesForWorkReports(); 
    } 
    if(tabId === 'Non-inventory'){
      this.props.viewStore.productServiceSearch.where.product_service_type = 'Non-inventory';
      this.props.productServiceStore.loadProductAndServicesForWorkReports(); 
    }
    if(tabId === 'Service'){
      this.props.viewStore.productServiceSearch.where.product_service_type = 'Service';
      this.props.productServiceStore.loadProductAndServicesForWorkReports(); 
    }              
  }

  handleCloseMaterialForWorkReportDailog() {
    this.props.onClose()
  }

  handleSearchChange(event) {
    this.props.viewStore.productServiceSearch.term = event.target.value;    
  }

  handleSearch() { 
    if(this.props.viewStore.productServiceSearch.term === ''){
      this.props.viewStore.productServiceSearch.term = '*';
    }   
    this.props.productServiceStore.loadProductAndServicesForWorkReports();
  }


  onPaginationChange(newPage) {
    let {
      viewStore: {
        productServiceSearch
      }
    } = this.props;
    this.props.viewStore.productServiceSearch = {
      ...productServiceSearch,
      page: newPage
    };
    this.props.productServiceStore.loadProductAndServicesForWorkReports(); 
  }

  addBlankLineItem = () => {
    const {
      invoiceStore,
      invoiceStore: {
        invoice: {
          object
        }
      },
      type,
      store
    } = this.props;
    
    let service_type = 'material';
    if(type === 'Service'){
      service_type = 'labour';
    }
    this.props.invoiceStore.addBlankLineItemForProduct(service_type, object.attributes.id)
    .then(this.onSuccessfulCreateMaterial.bind(this))
    .catch(this.onFailCreateMaterial.bind(this));
  }

  onSuccessfulCreateMaterial(response) {
    this.props.store.isOpenAddMaterialForInvoiceDialog = false;
    this.props.store.isOpenAddLaborForInvoiceDialog = false;
    this.showLoader = false;   
    Toast.show({ icon: 'thumbs-up', message: 'Product added successfully.', intent: Intent.SUCCESS })
  }

  onFailCreateMaterial(error) {
    this.showLoader = false;
    Toast.show({ icon: 'warning-sign', message: 'Problem in adding product!', intent: Intent.DANGER })
  } 

  onCloseProductServiceDialog() {
    this.isProductServiceDialogVisible = false;
  }

  onSucessCreateProductService(productService) {
    this.isProductServiceDialogVisible = false;
  }


  render() {
    const {      
      section,
      workDetail,
      type,
      formType,
      itemType,
      button_text,
      title,
      productServiceStore:{
        productsServices:{
          objects,
          meta,
          isLoading
        }
      },
      invoiceStore: {
        isLoadingBlankLineItem
      },
      store
    } = this.props;
   let {selectedTabId} = this;
   
    return(
      <div>
        <Dialog
          className="equipments-list-dialog"
          title={title}
          isOpen={this.props.isOpen}
          isCloseButtonShown={true}
          onClose={this.handleCloseMaterialForWorkReportDailog.bind(this)}
        >         
          <div className={Classes.DIALOG_BODY}>
            <div className="">
              <FormGroup
                label=""
                labelFor="text-input"
              >
                <div className="bp3-control-group">
                  <input 
                    type='text' 
                    className='bp3-input' 
                    placeholder='Search...'                           
                    // style={{width:'90%'}} 
                    onChange={(event) => this.handleSearchChange(event) }
                  />
                  <Button rightIcon='search' text='Search' onClick={(event) => this.handleSearch(event) }/>
                </div>                   
              </FormGroup>           
            </div>

            <div className="tab-container">
              <div className="tab-block">
                <Tabs onChange={this.handleTabChange.bind(this)}  selectedTabId={section} large={true}>
                  {
                    type === 'Products (Non-inventory)' &&
                    <Tab id="Products (Non-inventory)" title="Materials" />
                  }
                  {/* {
                    type === 'Products (Non-inventory)' &&
                    <Tab id="Non-inventory" title="Non-inventory" />
                  } */}
                  
                  {
                    type === 'Service' &&
                    <Tab id="Service" title="Labor" />
                  }
                </Tabs>
              </div>
              <div className="text-right add-product-service-btns">
                <Button style={{marginRight:"10px"}} icon="add" text={button_text} onClick={() => this.isProductServiceDialogVisible = true} />
                <Button icon="add" text="Add New Blank Line Item" onClick={this.addBlankLineItem} loading={isLoadingBlankLineItem}/>
              </div>
            </div>
            {
              selectedTabId === 'Products (Non-inventory)' && type === 'Products (Non-inventory)' &&
              <ProductAndServiceTable workReportId={workDetail} objects={objects} isLoading={isLoading} meta={meta} type={type} formType={formType}/>
            }
            {
              selectedTabId === 'Non-inventory' && type === 'Products (Non-inventory)' &&
              <ProductAndServiceTable workReportId={workDetail} objects={objects} isLoading={isLoading} meta={meta} type={type} formType={formType}/>
            }
            {
              type === 'Service' && 
              <ProductAndServiceTable workReportId={workDetail} objects={objects} isLoading={isLoading} meta={meta} type={type} formType={formType}/>
            }

          </div>
        </Dialog>
        <ProductServiceModal 
          isOpen={this.isProductServiceDialogVisible} 
          title={button_text}
          edit={false}
          onClose={this.onCloseProductServiceDialog.bind(this)}
          onSuccess={this.onSucessCreateProductService.bind(this)}
          itemType={itemType}
        />
    </div>
    )
  }

};

export default AddProductForInvoiceModal;
