import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { 
  Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';

@inject('store', 'clientStore', 'frequencyStore', 'viewStore') @observer
class FrequencyTable extends Component {
  @observable isOpenAddEquipmentDialog = false;
  @observable isDeleteDialogVisible = false;
  @observable service;

  onPaginationChange(newPage) {
    let {
      viewStore: {
        frequencySearch
      }
    } = this.props;
    this.props.viewStore.frequencySearch = {
      ...frequencySearch,
      page: newPage
    };
    this.props.viewStore.frequencies();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.frequencyStore.deleteFrequency(this.service)
      .then(this.onSuccessfulDeleteFrequency.bind(this))
      .catch(this.onFailDeleteFrequency.bind(this))
  }

  onSuccessfulDeleteFrequency(response) {
    Toast.show({ icon: 'thumbs-up', message: 'frequency deleted successfully!', intent: Intent.SUCCESS })
    this.service = null;
    this.isDeleteDialogVisible = false;
  }

  onFailDeleteFrequency(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete frequency!', intent: Intent.DANGER })
  }

  handleServiceTypeClick(event, service) {
    switch (event.label) {
      case 'Edit':
        this.service = service;
        this.props.viewStore.frequencyShow({uuid: service.attributes.id, name: service.attributes.name})
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.service = service;
        break;
      default:
    }
  }

  render() {
    let {
      viewStore,
      frequencyStore: {
        frequencies: {
          objects,
          meta
        }
      }
    } = this.props;

    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
          <table className="table table-hover" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Title</th>
                <th width="12%" className="item-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                objects.map((frequency) => {
                  return (
                    <tr key={frequency.id}>
                      <td>{frequency.attributes.title}</td>
                      <td>
                        <div className="action-buttons" minimal={true}>
                          <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.frequencyShow({uuid: frequency.attributes.id})}>
                            <img src="/edit-light-icon.svg"/>
                          </Button>
                          <Divider/>
                          <Button title="Delete" minimal={true} small={true} onClick={() => this.handleServiceTypeClick({ label: 'Delete' }, frequency)}>
                            <img src="/delete-light-icon.svg" />
                          </Button>
                        </div>
                      </td>
                    </tr> 
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>Warnning! Deleting this frequency will not be recoverable.</p>
          <p>Are you sure you want to do this?</p>
        </Alert>

        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>

      </React.Fragment>
    )
  }

}

export default FrequencyTable;