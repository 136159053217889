import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import qs from 'qs';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';


@inject('viewStore','equipmentStore') @observer
class EquipmentsTable extends React.Component {

  @observable equipments = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    this.props.viewStore.clientEquipmentSearch.where.client_id = this.props.client.attributes.id;
    this.props.equipmentStore.loadClientEquipments();
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        clientEquipmentSearch
      }
    } = this.props;
    this.props.viewStore.clientEquipmentSearch = {
      ...clientEquipmentSearch,
      page: newPage
    };
    this.props.equipmentStore.loadClientEquipments();
  }

  render() {
    
    let { 
      equipmentStore: {
        clientEquipments: {
          objects, isLoading, meta 
        }
      }
    } = this.props;
    let { viewStore } = this.props;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No equipment have been added for this client yet.
        </div>
      )
    }
    return (
      <div className="card rounded-0 table-responsive">
        <table className='table table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Serial</th>            
              <th>Make</th>
              <th>Model</th>
              <th width="20%">Location</th>
              <th>Description</th>            
            </tr>
          </thead>
          <tbody>
            {
              objects.map((equipment) => {
                return (
                  <tr key={equipment.id}>
                    <td>
                      <a href={`/dashboard/equipments/${equipment.id}/show?${qs.stringify(viewStore.equipmentShowSearch, { encode: false })}`}>
                        <p className='primary'>{equipment.attributes.serial_number}</p>
                      </a>
                    </td>
                    <td>
                      <a href={`/dashboard/equipments/${equipment.id}/show?${qs.stringify(viewStore.equipmentShowSearch, { encode: false })}`}>
                        <p className="primary">{equipment.attributes.make}</p>
                      </a>                      
                    </td>
                    <td>
                      <a href={`/dashboard/equipments/${equipment.id}/show?${qs.stringify(viewStore.equipmentShowSearch, { encode: false })}`}>
                        <p className="secondary">{equipment.attributes.eq_model}</p>
                      </a>
                    </td>
                    <td>              
                      <p className='secondary'>{equipment.attributes.eq_location}</p>
                    </td>
                    <td>
                      <p className='secondary'> {equipment.attributes.description}</p>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </div>
    )
  }
}

export default EquipmentsTable;