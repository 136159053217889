
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Download from "yet-another-react-lightbox/plugins/download";
import { observable, toJS } from "mobx";
import { Intent, Button, Spinner, Alert, Icon } from "@blueprintjs/core";
import Toast from "helpers/toaster";
import $ from "jquery";
import { thumbnailSrcFromFile, fullSrcFromFile } from "helpers/strings";
import { isFileTypeSupported } from "helpers/strings";

@inject("store", "quoteStore")
@observer
export class QuoteImages extends Component {
  @observable images = [];
  @observable imageSet = [];
  @observable isOpen = false;
  @observable currentImage = 0;
  @observable isDeleteDialogVisible = false;
  @observable isSelectImage;
  @observable setOfImages = [];

  componentWillMount() {
    const {
      quoteStore: {
        quote: { object },
      },
    } = this.props;
    this.images = object.attributes.images;
    this.setImages();
  }

  handleSelectCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleSelectConfirm(image) {
    this.isDeleteDialogVisible = true;
    this.isSelectImage = image;
  }

  setImages() {
    const {
      quoteStore: {
        quote: { object },
      },
    } = this.props;

    this.images = object.attributes.images;

    this.imageSet = [];

    if (this.images.length > 0) {
      this.images.map((image) => {
        this.imageSet.push({
          src: image.asset.url,
          thumbnail: image.asset.thumb.url,
          id: image.id,
        });
        return this.imageSet;
      });
    }
  }

  closeLightbox() {
    this.isOpen = false;
  }

  openLightbox(index) {
    this.currentImage = index;
    this.isOpen = true;
  }

  gotoPrevious() {
    this.currentImage = this.currentImage - 1;
    this.forceUpdate();
  }

  gotoNext() {
    this.currentImage = this.currentImage + 1;
    this.forceUpdate();
  }

  gotoImage(index) {
    this.currentImage = index;
  }

  openAddImagesModal() {
    this.isOpenAddImagesDialog = true;
  }

  removeAttachment(attachment) {
    const {
      quoteStore: {
        quote: { object },
      },
    } = this.props;
    this.isDeleteDialogVisible = false;
    this.images = object.attributes.images;
    this.props.quoteStore
      .removeAttachment(this.isSelectImage, object.attributes.id)
      .then((response) => {
        this.isDeleteDialogVisible = false;
        this.props.quoteStore.update().then((response) => {
          this.setImages();
        });
      });
  }

  onChangeFile(e) {
    let {
      quoteStore: {
        quote: {
          object: { attributes },
        },
      },
    } = this.props;
    this.setOfImages = [];
    var numFiles = e.target.files.length;
    var _this = this;
    for (var i = 0, numFiles = e.target.files.length; i < numFiles; i++) {
      var file = e.target.files[i];
      if (!isFileTypeSupported(file)) continue;
      var reader = new FileReader();
      reader.onload = function (e) {
        _this.setOfImages.push({
          fileName: file.name,
          image: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
    this.props.quoteStore
      .uploadWorkReportAttachment(e.target.files, attributes.id)
      .then(this.onSuccessfullUploadAttachment.bind(this))
      .catch(this.onFailUploadAttachment.bind(this));
  }

  onSuccessfullUploadAttachment(response) {
    this.props.quoteStore.update().then((response) => {
      this.setImages();
      this.setOfImages = [];
    });
    Toast.show({
      icon: "thumbs-up",
      message: "File uploaded successfully.",
      intent: Intent.SUCCESS,
    });
  }

  onFailUploadAttachment(error) {
    this.props.quoteStore.showUploadAttachmentLoader = false;
    if (error.response && error.response.data && error.response.data.errors) {
      Toast.show({
        icon: "warning-sign",
        message: error.response.data.errors,
        intent: Intent.DANGER,
      });
    } else {
      Toast.show({
        icon: "warning-sign",
        message: "Something went wrong, failed to upload file!",
        intent: Intent.DANGER,
      });
    }
  }

  render() {
    const {
      quoteStore: {
        quote: { object },
        showUploadAttachmentLoader,
      },
    } = this.props;

    return (
      <React.Fragment>
        <div
          className="table-title"
          style={{ paddingLeft: "10px", marginTop: 20 }}
        >
          <h5>
            <strong>Files</strong>
          </h5>
          <div className="wr-img-upload">
            <input
              id="file-upload"
              className="add-btn"
              type="file"
              multiple={true}
              onChange={this.onChangeFile.bind(this)}
            />
            <label for="file-upload" class="btn-1">
              <span>
                <Icon icon="add" style={{ marginRight: 5 }} /> Add File(s)
              </span>
            </label>
            <span style={{ marginLeft: 3 }}>
              {showUploadAttachmentLoader && (
                <Spinner size={Spinner.SIZE_SMALL} />
              )}
            </span>
          </div>
        </div>

        <div style={{ padding: "0px 10px" }}>
          {this.imageSet.length === 0 && this.setOfImages.length === 0 && (
            <div className="bp3-callout warning-alert-message bp3-intent-warning bp3-icon-info-sign table-card">
              No files have been added to this Estimate.
            </div>
          )}
        </div>

        <div
          style={{
            paddingLeft: "10px",
            paddingBottom: "10px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {this.setOfImages &&
            this.setOfImages.map((file) => {
              return (
                <div className="wr-img-wrap">
                  <img
                    id="blah"
                    src={file.image}
                    alt="images"
                    className="wr-img opacity-03"
                    style={{ width: 100, height: 100, objectFit: "cover" }}
                  />
                </div>
              );
            })}
          {this.imageSet.length > 0 &&
            this.imageSet.map((image, index) => {
              const thumbnail = thumbnailSrcFromFile(image);
              return (
                <div className="wr-img-wrap">
                  <Button
                    icon="trash"
                    className="btn-img-del"
                    onClick={() => this.handleSelectConfirm(image)}
                  />
                  <img
                    src={thumbnail}
                    alt="Attached with Estimate"
                    key={index}
                    onClick={() => this.openLightbox(index)}
                    className="wr-img"
                  />
                </div>
              );
            })}
          <Lightbox
            open={this.isOpen}
            close={this.closeLightbox.bind(this)}
            slides={this.imageSet.map((image) => {
              return {
                src: fullSrcFromFile(image),
                download: image.src,
              };
            })}
            plugins={[Zoom, Thumbnails, Download]}
            index={this.currentImage}
            zoom={{
              maxZoomPixelRatio: 10,
              zoomInMultiplier: 1.5,
            }}
          />
        </div>
        <Alert
          cancelButtonText="Close"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleSelectCancel.bind(this)}
          onConfirm={this.removeAttachment.bind(this)}
        >
          Are you sure to delete the selected file from your current estimate?
        </Alert>
      </React.Fragment>
    );
  }
}

export default QuoteImages;
