import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState, Button } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import Page from 'components/Page';
import UserProfileCard from 'components/User/ProfileCard';
import styled from '@emotion/styled';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`

const ThinDrawer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

@inject('store', 'viewStore', 'userStore') @observer
class UsersShowPage extends Component {

  get canRender() {
    let {
      userStore: {
        user: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object);
  }

  componentWillUnmount() {
    let {
      userStore
    } = this.props;
    userStore.clearSelectedUser();
  }

  updateSelectedResource = (resource) => {
    let {
      viewStore,
      userStore: {
        user: {
          object
        }
      }
    } = this.props;
    viewStore.userShowSearch.resource = resource;
    viewStore.userViewSearch(object.attributes.id);
  }

  render() {
    let content, title;
    let {
      viewStore,
      userStore: {
        user: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      title = 'Loading..';
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load User';
      content = <NonIdealState title="Failed" description="Something went wrong!" />;
    }
    if (this.canRender) {
      title = `${object.attributes.full_name} | Field Nexus`;
      content = <UserProfileCard 
        user={object}
        selectedResource={viewStore.userShowSearch.resource}
        onResourceChange={this.updateSelectedResource.bind(this)} 
      />
    }
    return (
      <div>
        {
          <Helmet>
            <title>{title}</title>
          </Helmet>
        }
        
          <ThinDrawer>
            {content}
          </ThinDrawer>
        
      </div>
    )
  }

};

export default UsersShowPage;