import React, { Component } from 'react';
import {
  Button, Popover, Position
} from '@blueprintjs/core';
import { observable } from 'mobx';
import 'fullcalendar';
import 'fullcalendar-scheduler';
import 'fullcalendar/dist/fullcalendar.css';
import 'fullcalendar-scheduler/dist/scheduler.css';
import { inject, observer } from 'mobx-react';
import { router } from '../../App';
import SearchDateRangePicker from 'components/SearchDateRangePicker';
import 'jquery-ui/ui/widgets/draggable';
import moment from 'moment-timezone';
import $ from 'jquery';

@inject('jobStore', 'viewStore') @observer
class IncompleteJob extends Component {

  renderDateTime(startDate, endDate) {
    const startDateFormatted = moment(startDate).format("MMM D, YY h:mma");
    const endDateFormatted = moment(endDate).format("MMM D, YY h:mma");
    const combined = moment(startDate).format("MMM D, YY h:mma") + " - " + moment(endDate).format("h:mma");
    const sameStartAndEnd = moment(startDate).format("MM/DD/YYYY") === moment(endDate).format("MM/DD/YYYY");

    if (sameStartAndEnd) {
      return combined;
    } else{
      return `${startDateFormatted} - ${endDateFormatted}`;
    }
  }

  onDateRangeChange(range) {
    let {
      props: {
        jobStore,
        viewStore: {
          incompleteJobSearch
        }
      }
    } = this;
    this.props.viewStore.incompleteJobSearch = {
      ...incompleteJobSearch,
      where: {
        ...incompleteJobSearch.where,
        start_date: { 
          gte: moment(range[0]).startOf('day').unix()
        },
        end_date: { 
          lte: moment(range[1]).endOf('day').unix()
        }
      }
    }
    
    jobStore.loadIncompleteJobs();
  }

  render() {
    let {
      jobStore: {
        incompleteJobs: {
          objects,
          isLoading
        }
      },
      viewStore: {
        incompleteJobSearch: {
          where
        }
      }
    } = this.props;

    let startDate = moment.unix(parseInt(where.start_date.gte)).toDate();
    let endDate = moment.unix(parseInt(where.end_date.lte)).toDate();

    return(
      <div>
        <div className="unassigned-job-sidebar" >

          <Popover
            minimal={true}
            content={
              <SearchDateRangePicker
                startDate={startDate}
                endDate={endDate}
                onChange={this.onDateRangeChange.bind(this)}
              />
            }
            position={Position.BOTTOM_LEFT}
            usePortal={true} >
            <Button rightIcon='caret-down' icon='calendar'>
              {(!startDate || !endDate) && `Date Range`}
              {startDate && endDate && `${startDate.toDateString()} - ${endDate.toDateString()}`}
            </Button>
          </Popover>

          <div className="incomplete-job-wraper">
            {
              isLoading &&
              <div className="unassg-loading">
                <div className="block">
                  <span className="skeleton-box" style={{ width: '80%' }}></span>
                  <span className="skeleton-box" style={{ width: '90%' }}></span>
                  <span className="skeleton-box" style={{ width: '100%' }}></span>
                  <span className="skeleton-box" style={{ width: '70%' }}></span>
                </div>
                <div className="block">
                  <span className="skeleton-box" style={{ width: '80%' }}></span>
                  <span className="skeleton-box" style={{ width: '90%' }}></span>
                  <span className="skeleton-box" style={{ width: '100%' }}></span>
                  <span className="skeleton-box" style={{ width: '70%' }}></span>
                </div>
                <div className="block">
                  <span className="skeleton-box" style={{ width: '80%' }}></span>
                  <span className="skeleton-box" style={{ width: '90%' }}></span>
                  <span className="skeleton-box" style={{ width: '100%' }}></span>
                  <span className="skeleton-box" style={{ width: '70%' }}></span>
                </div>
              </div>

            }
            {
              !isLoading && objects.length === 0 && <div>
                <div className="caughtup-message">
                  <p>
                    <img src="../calender-1 1.svg" alt="" width="200px" />
                  </p>
                  Looks like there are no incomplete jobs found for the search parameter you have selected above, try different search parameters.
                </div>
              </div>
            }
            {
              !isLoading && objects.length > 0 && objects.map((job) => {
                return (
                  <div className="card mb-3 rounded-0 incomplete-events p-1 cursor-pointer" key={job.id} onClick={() => {router.navigate(`/dashboard/jobs/${job.id}`);}}>                                        
                    <div className="card-body unsg-card">
                      <div className='card-top'>
                        <div className='event-number'>#{job.attributes && job.attributes.event_number}</div>
                        <div className='event-tag' style={{ backgroundColor: `#${job.attributes.service_type.color}`, color: '#ffffff' }}>{job.attributes.service_type && job.attributes.service_type.name} {job.attributes && job.attributes.service_agreement && job.attributes.service_agreement.id && <span className='bp3-icon-standard bp3-icon-repeat unassigned-repeat-icon repeat-icon'></span>} </div>
                      </div>
                      <div className='fc-title'>{job.attributes.title}</div>                      
                      <div className='card-row'>
                        <div className="client-name">{job.attributes.client_name}</div>
                        <div className="full-address">{job.attributes.location && job.attributes.location.full_address}</div>
                      </div>  
                      <div className='card-bottom'> 
                        <div className='card-date'>
                          {this.renderDateTime(job.attributes.start_date, job.attributes.end_date)}
                        </div>
                      </div>                
                    </div>
                  </div>
                )
              })
            }
          </div>

        </div>

      </div>
    )
  }

}

export default IncompleteJob;