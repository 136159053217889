import React, { Component } from 'react';
import { 
  Divider, Intent, TextArea,
  Button, Spinner,
  FormGroup, InputGroup, Alert
} from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import Card from 'components/Card';
import styled from '@emotion/styled';
import TableHeading from 'components/TableHeading';
import WorkReportsTable from 'components/Equipment/WorkReportsTable';
import AnalyticsCard from 'components/Common/AnalyticsCard';
import Toast from 'helpers/toaster';
import EquipmentImages from 'components/Equipment/Images';
import AddEquipmentPartModal from 'components/Equipment/AddEquipmentPartModal';
import _ from 'lodash';
import { observable } from 'mobx';

const EquipmentGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: ${props => `${props.theme.unit*4}px`};
  align-items: flex-start;
`

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const AnalyticsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

@inject('equipmentStore','store') @observer
class EquipmentProfileCard extends Component {
  @observable images = [];

  @observable equipment_title = 'Create Equipment Parts';
  @observable isDeleteDialogVisible = false;
  @observable eq_part;

  componentDidMount() { 
    let { equipment } = this.props;
    this.props.equipmentStore.loadAnalytics({uuid: equipment.attributes.id})
  }

  changeTableData = (resource) => {
    if(resource){
      this.props.onResourceChange(resource);
    }
  }

  resourceData = () => {
    let { equipment, selectedResource } = this.props;
    if(selectedResource === 'workreports'){
      return(
        <React.Fragment>
          <TableHeading heading="WORK REPORTS" />
          <WorkReportsTable equipment={equipment} />
        </React.Fragment>
      )
    }
  }

  handleEquipmentChange(event, key) {
    this.props.equipment.attributes[key] = event.target.value;
  }

  updateEquipment() {

    if(this.props.equipment.attributes.kind === "" || this.props.equipment.attributes.kind === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid title!', intent: Intent.DANGER })
      return;
    }

    if(this.props.equipment.attributes.make === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid make!', intent: Intent.DANGER })
      return;
    }

    if(this.props.equipment.attributes.eq_model === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid model!', intent: Intent.DANGER })
      return;
    }

    if(this.props.equipment.attributes.serial_number === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid serial number!', intent: Intent.DANGER })
      return;
    }

    if(this.props.equipment.attributes.description === "" || this.props.equipment.attributes.description === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please write some description.', intent: Intent.DANGER })
      return;
    }
    this.props.equipment.attributes.images = this.images;
    

    this.props.equipmentStore.updateEquipment(this.props.equipment)
      .then(this.onSuccessfulUpdateEquipment.bind(this))
      .catch(this.onFailUpdateEquipment.bind(this))
  }

  onSuccessfulUpdateEquipment(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Equipment updated successfully.', intent: Intent.SUCCESS })
  }

  onFailUpdateEquipment(error) {
    Toast.show({ icon: 'warning-sign', message: 'Problem in updating equipment!', intent: Intent.DANGER })
  }

  onChangeFile(event) {
  
    var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 10) {
      Toast.show({ icon: 'warning-sign', message: 'File size exceeds 10 MB!', intent: Intent.DANGER })
      return
    }
    this.props.equipmentStore.attach_image(event.target.files,this.props.equipment.attributes.id)
      .then((response) => {
        this.props.equipmentStore.loadEquipmentForImage({uuid:this.props.equipment.attributes.id})
        .then((response) => {
          this.props.equipment.object = response.data.data;
          this.images.push(response.data.data.attributes.images)
        });
      })
      .then(this.onSuccessfullUploadAttachment.bind(this))
      .catch(this.onFailUploadAttachment.bind(this))
  }

  onSuccessfullUploadAttachment(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Image uploded successfully.', intent: Intent.SUCCESS })
  }

  onFailUploadAttachment(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong, failed to upload file!', intent: Intent.DANGER })
    }
  }
  
  handleClosEquipmentDailog() {
    this.props.store.isOpenAddEquipmentPartDialog = false;
  }

  onSuccessAddEquipment(equipment) {
    this.props.store.isOpenAddEquipmentPartDialog = false;
  }

  deleteEquipmentPart(part){
    this.isDeleteDialogVisible = true;
    this.eq_part = part;
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.equipmentStore.deleteEquipmentPart(this.eq_part)
      .then(this.onSuccessfulDeletePart.bind(this))
      .catch(this.onFailedPartDelete.bind(this))
  }

  onSuccessfulDeletePart(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Equipment part deleted successfully!', intent: Intent.SUCCESS })
    this.eq_part = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedPartDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete equipment part!', intent: Intent.DANGER })
  }

  equipmentPartDialog(){
    let { 
      equipmentStore: {
        equipmentPart:{
          object
        }
      }
    } = this.props;
    this.equipment_title = 'Create Equipment Parts';
    object.name = '';
    object.eq_size = '';
    object.eq_type = '';
    object.description = '';
    object.id = '';
    object.equipment_id = '';
    this.props.store.isOpenAddEquipmentPartDialog = true;
  }

  changeEquipmentPart(part){
    let { 
      equipmentStore: {
        equipmentPart:{
          object
        }
      }
    } = this.props;
    object.name = part.name;
    object.eq_size = part.eq_size;
    object.eq_type = part.eq_type;
    object.description = part.description;
    object.id = part.id;
    object.equipment_id = part.equipment_id;
    this.equipment_title = 'Update Equipment Parts';
    this.props.store.isOpenAddEquipmentPartDialog = true;
  }

  render() {
    let { 
      equipment, 
      selectedResource,
      equipmentStore: {
        equipmentAnalytics,
        showUploadAttachmentLoader
      },
      store
    } = this.props;
    return(
      <EquipmentGrid>
        <Card>
          <CardContent>
            <div style={{ textAlign: 'center', paddingBottom: '8px' }}>
              <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                {equipment.attributes.make}
              </h4>
              <h6 className={'bp3-heading bp3-text-muted'}>
                {[equipment.attributes.eq_model, equipment.attributes.serial_number].join(' | ')}
              </h6>
            </div>
            <Divider style={{ margin: '16px 0px' }}></Divider>

            <FormGroup label="Title" labelInfo="*">
              <InputGroup placeholder="Title" defaultValue={equipment.attributes.kind} onChange={(event) => { this.handleEquipmentChange(event, 'kind') }} />
            </FormGroup>

            <FormGroup label="Make" labelInfo="*">
              <InputGroup placeholder="Make" defaultValue={equipment.attributes.make} onChange={(event) => { this.handleEquipmentChange(event, 'make') }} />
            </FormGroup>

            <FormGroup label="Model" labelInfo="*">
              <InputGroup placeholder="Model" defaultValue={equipment.attributes.eq_model} onChange={(event) => { this.handleEquipmentChange(event, 'eq_model') }} />
            </FormGroup>

            <FormGroup label="Serial Number" labelInfo="*">
              <InputGroup placeholder="Serial Number" defaultValue={equipment.attributes.serial_number} onChange={(event) => { this.handleEquipmentChange(event, 'serial_number') }} />
            </FormGroup>

            <FormGroup label="Description" labelInfo="*">
              <TextArea
                fill={true}
                defaultValue={equipment.attributes.description}
                onChange={(event) => { this.handleEquipmentChange(event, 'description') }}
              />
            </FormGroup>

            <FormGroup label="Images" >
              {showUploadAttachmentLoader && 
              <Spinner size={Spinner.SIZE_SMALL} /> }
              <input id="file-upload" type="file" multiple={true} onChange={this.onChangeFile.bind(this)} />
            </FormGroup>

            <EquipmentImages equipment={this.props.equipment} showUploadAttachmentLoader={showUploadAttachmentLoader}/>

            {/* <Button icon="floppy-disk" text="Update" onClick={this.updateEquipment.bind(this)} /> */}
            <div style={{display:'flex',justifyContent:'space-between'}}>
              <Button icon="floppy-disk" text="Add Parts" onClick={() => this.equipmentPartDialog()} />
              <Button icon="floppy-disk" className='bp3-button bp3-intent-primary' text="Update" onClick={this.updateEquipment.bind(this)} />
            </div>
            <div className="equipment-part-card">
              <div className="card-title">Parts</div>
                {
                  equipment && equipment.attributes.equipment_parts.map((part) => {  
                    return (
                      <div className="card-data">
                        <div className="card-item">
                          <div className="card-label">Name</div>
                          <div className="card-content">{part.name}</div>
                        </div>
                        <div className="card-item">
                          <div className="card-label">Size</div>
                          <div className="card-content">{part.eq_size}</div>
                        </div>
                        <div className="card-item">
                          <div className="card-label">Type</div>
                          <div className="card-content">{part.eq_type}</div>
                        </div>
                        <div className="card-item description-card">
                          <div className="card-label">Description</div>
                          <div className="card-content">{part.description}</div>
                        </div>
                        <div className="card-action-button">
                          <button><img src="/edit-light-icon.svg" onClick={() => this.changeEquipmentPart(part)} /></button>
                          <button><img src="/delete-light-icon.svg" onClick={() => this.deleteEquipmentPart(part)}/></button>
                        </div>
                      </div>
                    )
                  })
                }
            </div>
            
          </CardContent>
        </Card>
        <div className="responsive-layout-analytics-card">
          <AnalyticsContainer>
            <div className="analytics-card-warper">
              {
                equipmentAnalytics.isLoading && 
                  <Spinner size='25' />
              }
              {
                equipmentAnalytics.objects.map((analytics) => {
                  return (
                    <AnalyticsCard
                      key={Math.random()}
                      cardTitle={analytics.name}
                      count={analytics.total_count}
                      active={selectedResource === 'jobs'}
                      breakdown={analytics.breakdown || []}
                    />
                  )
                })
              }
            </div>
          </AnalyticsContainer>
          { this.resourceData() }
        </div>
        <AddEquipmentPartModal
          isOpen={store.isOpenAddEquipmentPartDialog}
          onClose={this.handleClosEquipmentDailog.bind(this)}
          onSuccess={this.onSuccessAddEquipment.bind(this)}
          equipment={equipment}
          title={this.equipment_title}
        />
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
             Are you sure you want to do this?
          </p>
        </Alert>
      </EquipmentGrid>
    )
  }

};

export default EquipmentProfileCard;