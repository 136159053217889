import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

class TaxStore {

  @observable taxes = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable tax = {
    isLoading: false,
    isError: false,
    object: {
      name:'',
      percentage_value:''
    }
  };

  @action
  create() {
    this.tax.isLoading = true;
    let promise = Api.post(`/taxes`, {tax: this.tax.object})

    promise
      .then((response) => {
        this.tax.isLoading = false;
        this.loadTaxes();
      })
      .catch((error) => {
        this.tax.isLoading = false;
        this.tax.isError = true;
      })
    
    return promise;
  }

  @action
  createTaxOnQuickbooks() {
    this.tax.isLoading = true;
    let promise = Api.post(`/quickbooks/sync_taxes`, {tax: this.tax.object})

    promise
      .then((response) => {
        this.tax.isLoading = false;
        this.loadTaxes();
      })
      .catch((error) => {
        this.tax.isLoading = false;
        this.tax.isError = true;
      })
    
    return promise;
  }

  @action
  createTaxOnXero() {
    this.tax.isLoading = true;
    let promise = Api.post(`/xeros/sync_taxes`, {tax: this.tax.object})

    promise
      .then((response) => {
        this.tax.isLoading = false;
        this.loadTaxes();
      })
      .catch((error) => {
        this.tax.isLoading = false;
        this.tax.isError = true;
      })
    
    return promise;
  }

  @action
  update() {
    this.tax.isLoading = true;
    let promise = Api.put(`/taxes/${this.tax.object.id}`, {tax: this.tax.object})

    promise
      .then((response) => {
        this.tax.isLoading = false;
        this.loadTaxes();
      })
      .catch((error) => {
        this.tax.isLoading = false;
        this.tax.isError = true;
      })
    
    return promise;
  }

  @action
  delete(tax) {
    this.taxes.isLoading = true;
    let promise = Api.delete(`/taxes/${tax.attributes.id}`)

    promise
      .then((response) => {
        this.loadTaxes();
      })
      .catch((error) => {
        this.taxes.isLoading = false;
        this.taxes.isError = true;
      })
    
    return promise;
  }

  @action
  loadTaxes() {
    this.taxes.isLoading = true;
    let promise = Api.get(`/taxes`)

    promise
      .then((response) => {
        this.taxes.isLoading = false;
        this.taxes.objects = response.data.data;
      })
      .catch((error) => {
        this.taxes.isLoading = false;
        this.taxes.isError = true;
      })
    
    return promise
  }

  // Tax is a tenant based tax
  @action
  updateLabourTaxLineItem(taxLineItem, tax) {
    let promise = Api.put(`/tax_line_items/${taxLineItem.id}`, {tax_id: tax.attributes.id, tax_line_item: taxLineItem})

    promise
      .then((response) => {
        stores.invoiceStore.loadLabourLineItems();
      })
      .catch((error) => {
      })
    
    return promise;
  }

  // Tax is a tenant based tax
  @action
  updateMaterialTaxLineItem(taxLineItem, tax) {
    let promise = Api.put(`/tax_line_items/${taxLineItem.id}`, {tax_id: tax.attributes.id, tax_line_item: taxLineItem})

    promise
      .then((response) => {
        stores.invoiceStore.loadMaterialLineItems();
      })
      .catch((error) => {
      })
    
    return promise;
  }

  // Tax is a tenant based tax
  @action
  updateCustomTaxLineItem(taxLineItem, tax) {
    let promise = Api.put(`/tax_line_items/${taxLineItem.id}`, {tax_id: tax.attributes.id, tax_line_item: taxLineItem})

    promise
      .then((response) => {
        stores.invoiceStore.loadCustomLineItems();
      })
      .catch((error) => {
      })
    
    return promise;
  }

};

export default TaxStore;