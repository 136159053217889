import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Spinner, NonIdealState } from "@blueprintjs/core";
import SearchBars from "components/SearchBars";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import FormsTable from "components/Tables/Forms";
import Card from "components/Card";
import Sticky from "react-stickynode";
import styled from "@emotion/styled";
import SettingsSidebar from "pages/preferences/settings-sidebar";

const CardHolder = styled("div")`
  padding: ${props => `${props.theme.unit * 3}px`};
`;
const PageWrapGray = styled("div")`
  padding: 12px;
  background-color: #F8FAFB;
  height: calc(100vh - 75px);
`;

@inject("store", "formStore")
@observer
class FormsIndexPage extends Component {
  @observable visible = true;

  render() {
    let content;
    let {
      formStore: {
        forms: { isLoading, isError }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = (
        <CardHolder>
          <Spinner />
        </CardHolder>
      );
    }
    if (isError && !isLoading) {
      content = (
        <NonIdealState
          title="Failed"
          description="Sorry, something went wrong"
        />
      );
    }
    if (!isError && !isLoading) {
      content = <FormsTable />;
    }
    return (
      <div className="form-page-layout">
        <Helmet>
          <title>Forms | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <SettingsSidebar />
          </div>
          <div className="col-lg-10">

          
        
        <PageWrapGray>
          <Sticky enabled={true} top={0} innerZ={9}>
            <SearchBars.Form />
          </Sticky>

          <Card>{content}</Card>
        </PageWrapGray>
        </div>
        </div>
      </div>
    );
  }
}

export default FormsIndexPage;
