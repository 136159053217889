import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';
import moment from 'moment';

class ContactsStore {

  @observable contacts = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable selectedContacts = [];

  @action
  getContacts(query) {
    this.contacts.isLoading = true;
    let promise = Api.post(`/contacts/search`, { term: query })

     promise
      .then((response) => {
        this.contacts.isLoading = false;
        this.contacts.isError = false;
        return response;
      })
      .catch((error) => {
        this.contacts.isLoading = false;
        this.contacts.isError = true;
      })
    return promise;
  }
  

};

export default ContactsStore;