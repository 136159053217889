import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import QuoteHeader from './QuoteHeader';
import QuoteBody from './QuoteBody';
import QuoteFooter from './QuoteFooter';

@inject('quoteStore') @observer
class Quote extends Component {

  render() {
    return(
      <React.Fragment>
        <QuoteHeader />

        <QuoteBody />

        <QuoteFooter />

      </React.Fragment>
    )
  }

};

export default Quote;