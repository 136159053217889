import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button } from '@blueprintjs/core';
import LabourItem from './LabourItem';
import _ from 'lodash';
import { currencyFormattedAmount, currencySymbol } from 'helpers/currency';
import AddProductForEstimateModal from 'components/Quote/AddProductForEstimateModal';

@inject('store', 'quoteStore', 'productServiceStore', 'viewStore') @observer
class QuoteLabourLineItems extends Component {

  calculateSubTotal(labours) {
    if (labours.length > 0) {
      let total = 0;
      total = _.sumBy(labours, function (labour) {
        let taxTotal = _.sumBy(labour.tax_line_items, function (taxLineItem) {
          return (taxLineItem.percentage_value / 100) * labour.total
        });
        return labour.total + taxTotal;
      })
      return currencyFormattedAmount(total)
    } else {
      return currencyFormattedAmount(0)
    }
  }

  calculateSubTotalBeforeMarkup(labours) {
    if (labours.length > 0) {
      var sum = 0;
      _.each(labours, function (labour) {
        sum += parseFloat(labour.quantity) * parseFloat(labour.rate_per_unit)
      })
      return currencyFormattedAmount(sum)
    } else {
      return currencyFormattedAmount(0)
    }
  }

  handleCloseMaterialForWorkReportDailog() {
    this.props.store.isOpenAddLaborForQuoteDialog = false;
  }

  onSuccessAddMaterialForWorkReport(equipment) {
    const {
      productServiceStore: {
        productsServices: {
          objects
        }
      }
    } = this.props;
    this.props.viewStore.productServiceSearch.where.product_service_type = 'Service';
    this.props.productServiceStore.loadProductAndServicesForWorkReports();
    this.props.store.isOpenAddLaborForQuoteDialog = true;
    this.props.store.productServiceType = 'Service';
    this.props.store.productServiceForm = 'Quote';
  }

  refreshLineItems() {
    this.forceUpdate()
  }

  render() {
    const {
      quoteStore,
      quoteStore: {
        quote: {
          object
        },
        showLabourLineItemsLoader
      },
      store
    } = this.props;
    return (
      <React.Fragment>
        <div className="table-title" style={{ paddingLeft: '10px' }}>
          <h5>
            <strong>Labor <Button icon='add' className="add-btn bp3-minimal" onClick={() => { this.onSuccessAddMaterialForWorkReport() }} >Add Labor</Button></strong>
          </h5>

        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div style={{ padding: '0px 10px' }} >
            {
              object.attributes.labour.length === 0 && <div className="bp3-callout warning-alert-message bp3-intent-warning bp3-icon-info-sign table-card">
                No labor line items have been added to this quote.
              </div>
            }
          </div>

          {
            object.attributes.labour.length > 0 && <div className="wr-row">
              <div style={{ flex: 1 }}>
                <table className="table table-bordered border-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: 215 }}>Services</th>
                      <th scope="col" style={{ width: 200 }}>Date</th>
                      <th scope="col" style={{ width: 140 }}>Hours</th>
                      <th scope="col" style={{ width: 140 }}>Hourly Rate({currencySymbol()})</th>
                      <th scope="col" style={{ width: 134 }}>Markup</th>
                      <th scope="col">Total</th>
                      <th scope="col" style={{ width: 62 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    
                        {
                          object.attributes.labour.map((labour) => {
                            return (
                              <LabourItem labour={labour} key={labour.id} refresh={this.refreshLineItems.bind(this)} />
                            )
                          })
                        }
                      
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
        {object.attributes.labour.length > 0 && <div className="lineitem_sub_total">
          <p><span style={{ marginRight: 25 }}>Sub Total Before markup:  {this.calculateSubTotalBeforeMarkup(object.attributes.labour)}</span>Sub Total: {this.calculateSubTotal(object.attributes.labour)}</p>
        </div>}
        <AddProductForEstimateModal
          isOpen={store.isOpenAddLaborForQuoteDialog}
          onClose={this.handleCloseMaterialForWorkReportDailog.bind(this)}
          workDetail={object}
          type={this.props.store.productServiceType}
          formType={this.props.store.productServiceForm}
          onSuccess={this.onSuccessAddMaterialForWorkReport.bind(this)}
          itemType="Labor"
          button_text="Create New Labor"
          title="Select Labor"
        />
      </React.Fragment>
    )
  }
}
export default QuoteLabourLineItems;