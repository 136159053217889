import React, { Component } from 'react';
import qs from 'qs';
import { observer, inject } from 'mobx-react';

@inject('viewStore') @observer
class AnalyticsCard extends Component {

  render() {
    let props = this.props;
    let { viewStore } = this.props;
    let urlResource = '';
    if (props.cardTitle == "Jobs") {
      urlResource = 'jobs';
    }else if(props.cardTitle === "Work Orders") {
      urlResource = 'work_orders';
    }else if(props.cardTitle === "Equipment") {
      urlResource = 'equipments';
    }else if(props.cardTitle === "Estimates") {
      urlResource = 'estimates';
    }else if(props.cardTitle === "Invoices") {
      urlResource = 'invoices';
    }else if(props.cardTitle === "Locations") {
      urlResource = 'locations';
    }else if(props.cardTitle === "Projects") {
      urlResource = 'projects';    
    }else if(props.cardTitle === "Service Agreements") {
        urlResource = 'service_agreements';
    }else{
      urlResource = '';
    }
    return(
      <div className={`analytics-container ${(props.active ? 'active' : '')}`} onClick={props.onClick}>
        <a href={`/dashboard/clients/${this.props.cardId}?resource=${urlResource}`}>
          <div className="card-title-container">          
            <div className="card-title">              
              {props.cardTitle}
            </div>
          </div>
        </a>
        <a href={`/dashboard/clients/${this.props.cardId}?resource=${urlResource}`}>
          <div className="total-value-container">
            <div className="total-value">
              {props.count}            
            </div>
          </div>
        </a>
          {
            props.breakdown && props.breakdown.length && props.breakdown.length > 0 && props.breakdown.map((info) => {
              return (
                <div className="info-container" key={Math.random()}>                  
                  <div className="info-text">
                    {info.name}
                  </div>
                  <div className="info-value">
                    {info.count}
                  </div>                  
                </div>
              )
            })
          }
      </div>
    )
  }

}

export default AnalyticsCard;