import React, { Component } from 'react';
import {
  Button, Popover, Position
} from '@blueprintjs/core';
import { observable } from 'mobx';
import 'fullcalendar';
import 'fullcalendar-scheduler';
import 'fullcalendar/dist/fullcalendar.css';
import 'fullcalendar-scheduler/dist/scheduler.css';
import { inject, observer } from 'mobx-react';
import SearchDateRangePicker from 'components/SearchDateRangePicker';
import 'jquery-ui/ui/widgets/draggable';
import moment from 'moment-timezone';
import $ from 'jquery';
import Footer from '../../components/Footer';
import Pagination from 'components/Pagination';

@inject('jobStore', 'viewStore') @observer
class UnassignedJob extends Component {

  @observable startDate = null;
  @observable endDate = null;

  onTermChange(e) {
    let {
      props: {
        jobStore,
        viewStore: {
          unassignedJobSearch
        }
      }
    } = this;
    this.props.viewStore.unassignedJobSearch = {
      ...unassignedJobSearch,
      term: e.target.value,
      page: 1
    }

    jobStore.loadUnassignedJobs()
      .then(() => {
        $('#external-events .fc-events').each(function () {
          $(this).draggable({
            zIndex: 9999999,
            revert: true,
            revertDuration: 0
          });
        });
      })
  }

  onDateRangeChange(range) {
    let {
      props: {
        jobStore,
        viewStore: {
          unassignedJobSearch
        }
      }
    } = this;
    this.props.viewStore.unassignedJobSearch = {
      ...unassignedJobSearch,
      where: {
        ...unassignedJobSearch.where,
        start_date: { 
          gte: moment(range[0]).startOf('day').unix()
        },
        end_date: { 
          lte: moment(range[1]).endOf('day').unix()
        }
      },
      page: 1,
    }
    this.startDate = range[0];
    this.endDate = range[1];
    jobStore.loadUnassignedJobs()
      .then(() => {
        $('#external-events .fc-events').each(function () {
          $(this).draggable({
            zIndex: 9999999,
            revert: true,
            revertDuration: 0
          });
        });
    })
  }

  renderDateTime(startDate, endDate) {
    const startDateFormatted = moment(startDate).format("MMM D, YY h:mma");
    const endDateFormatted = moment(endDate).format("MMM D, YY h:mma");
    const combined = moment(startDate).format("MMM D, YY h:mma") + " - " + moment(endDate).format("h:mma");
    const sameStartAndEnd = moment(startDate).format("MM/DD/YYYY") === moment(endDate).format("MM/DD/YYYY");

    if (sameStartAndEnd) {
      return combined;
    } else{
      return `${startDateFormatted} - ${endDateFormatted}`;
    }
  }

  onPaginationChange(newPage) {
    let {
      props: {
        jobStore,
        viewStore: {
          unassignedJobSearch
        }
      }
    } = this;
    this.props.viewStore.unassignedJobSearch = {
      ...unassignedJobSearch,
      page: newPage
    }

    jobStore.loadUnassignedJobs()
      .then(() => {
        $('#external-events .fc-events').each(function () {
          $(this).draggable({
            zIndex: 9999999,
            revert: true,
            revertDuration: 0
          });
        });
      })
  }

  render() {
    let {
      props: {
        jobStore: {
          unassignedJobs: {
            objects,
            isLoading,
            meta
          }
        },
        viewStore: {
          unassignedJobSearch: {
            where
          }
        }
      }
    } = this;

    let startDate = moment.unix(parseInt(where.start_date.gte)).toDate();
    let endDate = moment.unix(parseInt(where.end_date.lte)).toDate();      
    
    return (
      <div>
        <div className="unassigned-job-sidebar" >
          <div className="bp3-input-group mb-2">
            <span className="bp3-icon bp3-icon-search" style={{marginTop: 9}}></span>
            <input 
              className="bp3-input" 
              type="search" 
              placeholder="Search by Job title, number, Client name" 
              onChange={this.onTermChange.bind(this)}
              defaultValue={where.term}
              dir="auto" />
          </div>

          <Popover
            minimal={true}
            content={
              <SearchDateRangePicker
                startDate={startDate}
                endDate={endDate}
                onChange={this.onDateRangeChange.bind(this)}
              />
            }
            position={Position.BOTTOM_LEFT}
            usePortal={true} >
            <Button rightIcon='caret-down' icon='calendar'>
              {(!startDate || !endDate) && `Date Range`}
              {startDate && endDate && `${startDate.toDateString()} - ${endDate.toDateString()}`}
            </Button>
          </Popover>

          {objects.length !== 0 &&
            <Footer>
              <div className="bp3-navbar-group responsive-pagination bp3-align-left scrollable-pagination">
                <Pagination
                  value={meta.page}
                  total={meta.total_pages}
                  onChange={this.onPaginationChange.bind(this)}
                />
              </div>
            </Footer>
          }

          <div className="aa-job-wraper" id="external-events">
            {
              isLoading &&
              // <Spinner />,
              <div className="unassg-loading">
                <div className="block">
                  <span className="skeleton-box" style={{ width: '80%' }}></span>
                  <span className="skeleton-box" style={{ width: '90%' }}></span>
                  <span className="skeleton-box" style={{ width: '100%' }}></span>
                  <span className="skeleton-box" style={{ width: '70%' }}></span>
                </div>
                <div className="block">
                  <span className="skeleton-box" style={{ width: '80%' }}></span>
                  <span className="skeleton-box" style={{ width: '90%' }}></span>
                  <span className="skeleton-box" style={{ width: '100%' }}></span>
                  <span className="skeleton-box" style={{ width: '70%' }}></span>
                </div>
                <div className="block">
                  <span className="skeleton-box" style={{ width: '80%' }}></span>
                  <span className="skeleton-box" style={{ width: '90%' }}></span>
                  <span className="skeleton-box" style={{ width: '100%' }}></span>
                  <span className="skeleton-box" style={{ width: '70%' }}></span>
                </div>
              </div>

            }
            {
              !isLoading && objects.length === 0 && <div>
                <div className="caughtup-message">
                  <p>
                    <img src="../calender-1 1.svg" alt="" width="200px" />
                  </p>
                  Looks like there are no unassigned jobs found for the search parameter you have selected above, try different search parameters.
                </div>
              </div>
            }
            {
              !isLoading && objects.length > 0 && objects.map((job) => {
                return (
                  <div className="card mb-3 rounded-0 fc-events p-1" data-event={JSON.stringify(job.attributes)} key={Math.random()}>                                        
                    <div className="card-body unsg-card" title="Drag and drop infront of technician name to assign job">
                      <div className='card-top'>
                        <div className='event-number'>#{job.attributes.job && job.attributes.job.event_number}</div>
                        <div className='event-tag' style={{ backgroundColor: `#${job.attributes.service_type.color}`, color: '#ffffff' }}>{job.attributes.service_type && job.attributes.service_type.name} {job.attributes && job.attributes.service_agreement && job.attributes.service_agreement.id && <span className='bp3-icon-standard bp3-icon-repeat unassigned-repeat-icon repeat-icon'></span>} </div>
                      </div>
                      <div className='fc-title'>{job.attributes.title}</div>                      
                      <div className='card-row'>
                        <div className="client-name">{job.attributes.client_name}</div>
                        <div className="full-address">{job.attributes.location && job.attributes.location.full_address}</div>
                      </div>  
                      <div className='card-bottom'> 
                        <div className='card-date'>
                          {this.renderDateTime(job.attributes.start_date, job.attributes.end_date)}
                        </div>
                      </div>                
                    </div>
                  </div>
                )
              })
            }
          </div>

        </div>

      </div>


    )
  }
}

export default UnassignedJob;