import React, { Component } from 'react'
import { Button, Popover, Position, Text, Tag,  Menu, MenuItem, Intent, Dialog } from '@blueprintjs/core';
import ListPicker from 'components/ListPicker';
import SearchDateRangePicker from 'components/SearchDateRangePicker';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import moment from 'moment-timezone';
import CopyLink from '../../pages/bookings/copy_link.js';
import BookingModal from '../../pages/bookings/booking-modal.js';
import { CurrentEnv } from '../../services/Api';
import Lockr from 'lockr';

const options = [
  { label: 'Draft', value: 'draft', selected: false },
  { label: 'Confirmed', value: 'confirmed', selected: false }
];

@inject('store', 'viewStore') @observer
class BookingSearch extends Component {
  @observable startDate = null;
  @observable endDate = null;

  @observable showCopyNewLinkDialog = false;
  @observable showCopyNewBrandLogoLinkDialog = false;
  @observable showCopyNewEmbeddedLinkDialog = false;
  @observable new_booking_link = null;
  @observable embedded_new_booking_link = null;
  @observable new_booking_link_with_brand = null;
  @observable openBookingModal = false;
  @observable showiframeModal = false;
  @observable embedded_iframe = null;

  componentDidMount() {
    const currentTenant = Lockr.get('currentTenant');
    if(currentTenant) {
      this.new_booking_link = `${CurrentEnv.appUrl}/booking_public/${currentTenant.id}/bookings/new`
      this.new_booking_link_with_brand = `${CurrentEnv.appUrl}/booking_public/${currentTenant.id}/bookings/new?is_brand=true`

      this.embedded_new_booking_link = `
        <iframe src="${CurrentEnv.appUrl}/booking_public/${currentTenant.id}/bookings/new" style="
          width: 100%;
          height: 2000px;
        "></iframe>
      `
      this.embedded_iframe = `
        <iframe src="${CurrentEnv.appUrl}/booking_public/${currentTenant.id}/bookings/booking-modal" style="
          width: 100%;
          height: 2000px;
        "></iframe>
      `
    }
  }

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.bookingSearch.page = 1;
    this.props.viewStore.bookings();
  }

  @action
  onSearchTermChange(e) {
    let {
      props: {
        viewStore: {
          bookingSearch
        }
      }
    } = this;
    this.props.viewStore.bookingSearch = {
      ...bookingSearch,
      term: e.target.value
    }
  }

  @action
  onBookingStatusChange(selections) {
    let selectedValues = selections.map((status) => status.value);
    let {
      props: {
        viewStore: {
          bookingSearch
        }
      }
    } = this;
    this.props.viewStore.bookingSearch = {
      ...bookingSearch,
      where: {
        ...bookingSearch.where,
        status: selectedValues
      }
    }
  }

  @action
  onDateRangeChange(range) {
    let {
      props: {
        viewStore: {
          bookingSearch
        }
      }
    } = this;
    this.props.viewStore.bookingSearch = {
      ...bookingSearch,
      where: {
        ...bookingSearch.where,
        start_date: { 
          gte: moment(range[0]).startOf('day').unix(),
          lte: moment(range[1]).endOf('day').unix()
        }
      }
    }
    this.startDate = range[0];
    this.endDate = range[1];
    this.search()
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.search()
    }
  }

  onCloseCopyNewBrandLinkDialog() {
    this.showCopyNewBrandLogoLinkDialog = false;
  }

  onCloseBookingModal(){
    this.openBookingModal = false;
  }

  onSuccessBookingModal() {
    this.openBookingModal = false;
  }

  onSuccessCopyBrandNewLinkDialog() {
    this.showCopyNewBrandLogoLinkDialog = false;
  }

  onCloseCopyNewLinkDialog() {
    this.showCopyNewLinkDialog = false;
  }

  onSuccessCopyNewLinkDialog() {
    this.showCopyNewLinkDialog = false;
  }

  onCloseCopyNewEmbeddedLinkDialog() {
    this.showCopyNewEmbeddedLinkDialog = false;
  }

  onSuccessCopyNewEmbeddedLinkDialog() {
    this.showCopyNewEmbeddedLinkDialog = false;
  }
  onCloseiframeModal() {
    this.showiframeModal = false;
  }

  onSuccessiframeModal() {
    this.showiframeModal = false;
  }
  
  render() {
    let {
      props: {
        store,
        viewStore,
        viewStore: {
          bookingSearch: { 
            term, 
            where 
          }
        }
      }
    } = this;

    if(term == '*'){
      term = '';
    }

    const moreAction = (
      <Menu>
        <MenuItem icon="document-share" text="Copy New Booking Link" onClick={() => { this.showCopyNewLinkDialog = true; }}/>
        <MenuItem icon="document-share" text="Copy New Booking Link With Brand" onClick={() => { this.showCopyNewBrandLogoLinkDialog = true; }}/>
        <MenuItem icon="document-share" text="Copy Embedded New Booking Link" onClick={() => { this.showCopyNewEmbeddedLinkDialog = true; }}/>
        <MenuItem icon="document-share" text="Modal Iframe" onClick={() => { this.showiframeModal = true; }}/>
      </Menu>
    );

    let startDate = moment.unix(parseInt(where.start_date.gte)).toDate();
    let endDate = moment.unix(parseInt(where.start_date.lte)).toDate();

    return (
      <nav className="bp3-navbar search bg-transparent">
        <div className="booking-request-search">
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <input 
                  type="text"
                  className="bp3-input"
                  placeholder="First Name, Last Name, Email, Phone No."
                  style={{ width: '270px' }}
                  onChange={this.onSearchTermChange.bind(this)}
                  defaultValue={term}
                  onKeyPress={this.onEnter.bind(this)}
                />
                <Popover
                  minimal={true}
                  content={
                    <ListPicker
                      selectedOptions={(where && where.status) || []}
                      options={options}
                      onChange={this.onBookingStatusChange.bind(this)}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                  usePortal={true} >
                  <Button rightIcon='caret-down'>
                    Status
                    <span>&nbsp;</span>
                    <Tag minimal={true} round={true}>{(where && where.status && where.status.length) || 0}</Tag>
                  </Button>
                </Popover>
                <Popover
                  minimal={true}
                  content={
                    <SearchDateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onChange={this.onDateRangeChange.bind(this)}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                  usePortal={true} >
                  <Button rightIcon='caret-down'>
                    {(!startDate || !endDate) && `Date Range`}
                    {startDate && endDate && `${startDate.toDateString()} - ${endDate.toDateString()}`}
                  </Button>
                </Popover>
                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
              </div>
            </div>
          </div>
          
          <div className="bp3-navbar-group bp3-align-right">
            <Button className="action-btn mr-1" icon="application" text="Booking" onClick={() => { this.openBookingModal = true }} />
            <Popover content={moreAction} position={Position.BOTTOM}>
                <Button className="action-btn" rightIcon="chevron-down" text="More Actions" />
            </Popover>
            {/* <Button icon='add' className="bp3-intent-primary primary-btn" onClick={() => viewStore.newBooking()} style={{marginLeft: 5}}>
              Create Booking
            </Button> */}
          </div>
        </div>
        <CopyLink
          isOpen={this.showCopyNewLinkDialog}
          link={this.new_booking_link}
          onClose={this.onCloseCopyNewLinkDialog.bind(this)}
          onSuccess={this.onSuccessCopyNewLinkDialog.bind(this)}
        />
        <CopyLink
          isOpen={this.showCopyNewBrandLogoLinkDialog}
          link={this.new_booking_link_with_brand}
          onClose={this.onCloseCopyNewBrandLinkDialog.bind(this)}
          onSuccess={this.onSuccessCopyBrandNewLinkDialog.bind(this)}
        />

        <CopyLink
          isOpen={this.showCopyNewEmbeddedLinkDialog}
          link={this.embedded_new_booking_link}
          onClose={this.onCloseCopyNewEmbeddedLinkDialog.bind(this)}
          onSuccess={this.onSuccessCopyNewEmbeddedLinkDialog.bind(this)}
        />
        <CopyLink
          isOpen={this.showiframeModal}
          link={this.embedded_iframe}
          onClose={this.onCloseiframeModal.bind(this)}
          onSuccess={this.onSuccessiframeModal.bind(this)}
        />
        <BookingModal
          isOpen={this.openBookingModal}
          onClose={this.onCloseBookingModal.bind(this)}
          onSuccess={this.onSuccessBookingModal.bind(this)}
        />
      </nav>
    )
  }
}

export default BookingSearch;