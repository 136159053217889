import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from 'mobx';
import { 
  Divider, Intent,
  Button, Checkbox,
  FormGroup
} from '@blueprintjs/core';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Toast from "helpers/toaster";
import ServiceTypePicker from "components/ServiceTypePicker";
import BookingsSidebar from '../bookings/bookings-sidebar';
import Range from 'components/PricingParameter/Range';
import { currencySymbol } from 'helpers/currency';
import { Helmet } from 'react-helmet';

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`


@inject("pricingParameterStore")
@observer
class PricingParameterAddPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: true,
    };
  }

  componentDidMount() {
    let {
      pricingParameterStore: {
        pricingParameterDetail: {
          object
        }
      }
    } = this.props;

  }

  createPricingParameter() {
    let {
      props: {
        pricingParameterStore: {
          pricingParameterDetail: { 
            object
           }
        }
      }
    } = this;

    if(object.name === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    if(object.pricing_parameter_services_attributes.length === 0) {
      Toast.show({ icon: 'warning-sign', message: 'Please select service type!', intent: Intent.DANGER })
      return;
    }

    this.props.pricingParameterStore
      .createPricingParameter()
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Pricing parameter created successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to create pricing parameter!",
          intent: Intent.DANGER
        });
      });
  }

  onServiceTypeSelect(selectedOptions) {
    let {
      props: {
        pricingParameterStore: {
          pricingParameterDetail: { 
            object
           }
        }
      }
    } = this;

    object.pricing_parameter_services_attributes = [];

    selectedOptions && selectedOptions.map((values, index) => {
      if(values.selected === true){
        return(
          object.pricing_parameter_services_attributes.push({
            service_type_id: values.value
          })
        )
      }
    })

  }


  changePricingType = (pricing_type) => {
    let {
      pricingParameterStore: {
        pricingParameterDetail: {
          object,
        }
      }
    } = this.props;
    object.pricing_type = pricing_type;
  }

  render() {
    let {
      pricingParameterStore,
      pricingParameterStore: {
        pricingParameterDetail: {
          object,
          isLoading
        }
      }
    } = this.props;


    return (
      <div className="row no-gutters">
        <Helmet>
          <title>Pricing Parameter | FieldNexus</title>
        </Helmet>
        <div className="col-lg-2 sidebar-responsive">
          <BookingsSidebar />
        </div>
        <div className="col-lg-10">
          <PageWrapGray>
            <div className="pricing-parameter-table bookings-sub-forms">
              <Card>
                <CardContent>
                  <div>
                    <div className="left-side-panel-servie-type">
                      <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                        Add Pricing Parameter
                      </h4>
                    </div>
                  </div>
                  <Divider style={{ margin: '16px 0px' }}></Divider>
                  <div className="left-side-panel-servie-type pricing-parameter">
                    <FormGroup label="Name" >
                      <input 
                        type='text' 
                        className='form-control' 
                        placeholder='Name'
                        defaultValue={object.name}
                        onChange={(event) => {object.name = event.target.value;}}            
                      />
                    </FormGroup>
                    <FormGroup 
                      label="Service Type"
                      labelFor="text-input"
                      labelInfo="(*)"
                    >
                      <ServiceTypePicker
                        onChange={(event) => {this.onServiceTypeSelect(event)} }
                      />
                    </FormGroup>
                    <FormGroup label="Pricing Type" labelFor="text-input" labelInfo="">
                      <div className="bookings-radio">
                        <Checkbox 
                          inline
                          large={false}
                          checked={object.pricing_type === 'flat'} 
                          onChange={this.changePricingType.bind(this, 'flat')}>
                            Flat
                        </Checkbox>
                        <Checkbox 
                          inline
                          large={false}
                          checked={object.pricing_type === 'range'} 
                          onChange={this.changePricingType.bind(this, 'range')}>
                            Pricing Ranges
                        </Checkbox>
                      </div>
                    </FormGroup>
                    { 
                      object.pricing_type === 'flat' &&
                        <FormGroup label={`Price (${currencySymbol()})`} >
                          <input 
                            type='number' 
                            className='form-control' 
                            placeholder='Price'
                            defaultValue={object.price}
                            onChange={(event) => {object.price = event.target.value;}}
                          />
                        </FormGroup>
                    }
                  </div>
                  { 
                    object.pricing_type === 'flat' &&
                      <div className="left-side-panel-servie-type inner-column">
                        <FormGroup label="Range Start" >
                          <input 
                            type='number' 
                            className='form-control' 
                            placeholder='Range Start'
                            defaultValue={object.range_start}
                            onChange={(event) => {object.range_start = event.target.value;}}            
                          />
                        </FormGroup>
                        <FormGroup label="Range End" >
                          <input 
                            type='number' 
                            className='form-control' 
                            placeholder='Range End'
                            defaultValue={object.range_end}
                            onChange={(event) => {object.range_end = event.target.value;}}            
                          />
                        </FormGroup>
                        <FormGroup label="Duration (In mins)" >
                          <input 
                            type='number' 
                            step={"1"}
                            className='form-control' 
                            placeholder='Duration'
                            defaultValue={object.duration}
                            onChange={(event) => {object.duration = event.target.value;}}            
                          />
                        </FormGroup>
                      </div>
                  }
                  { 
                    object.pricing_type === 'range' &&
                    <div className="card rounded-0 table-responsive">
                      <table className="table inner-table table-data-presentation table-hover" style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th>Label</th>
                            <th>Min Range</th>
                            <th>Max Range</th>
                            <th>Duration</th>
                            <th>Price</th>
                            <th>Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                        { 
                        object && object.pricing_parameter_ranges_attributes && object.pricing_parameter_ranges_attributes.map((range, index) => {
                          return(
                            
                              <Range error={range.error} page={'add'} range={range} key={Math.random()} rangeIndex={index} />
                            
                          )
                        })
                      }
                      
                        </tbody>
                      </table>
                      
                      <div className="row align-items-center">
                        <div className="col-sm-6 fg-pass fg-ad-member">
                          <a onClick={() => pricingParameterStore.incrementRangeCount()}>+ Add More</a>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="">
                    <Button className="btn-save" loading={isLoading} onClick={this.createPricingParameter.bind(this)}>Save</Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </PageWrapGray>
        </div>
      </div>
    );
  }
}

export default PricingParameterAddPage;
