import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { router } from 'App';

@inject('store', 'workReportStore') @observer
class WorkReportSupplierDetails extends Component {
  render() {
    const {
      workReportStore: {
        purchaseOrderItem: {
          object
        }
      }
    } = this.props;
    return (
      <div className="customer-details-nd" style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem' }}>
        <div className="details-1" style={{ display: 'flex', flexDirection: 'column'}}>
          <div className="technician-details">
            <div className="tech-label">
              <strong>Supplier</strong>
            </div>
            <div className="tech-contact">
              <div className="tech-name">
                <a href='' onClick={() => { router.navigate(`/dashboard/users/${object.attributes.created_by.id}/show`) }}>
                  {object.attributes.supplier.name}
                </a>
              </div>
              <div className="info-field">
                <div className="more-info">{object.attributes.supplier.email}</div>
                <div className="more-info">{object.attributes.supplier.phone}</div>
                <div className="more-info">{object.attributes.supplier.address}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WorkReportSupplierDetails;