import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from 'mobx';
import { 
  Divider, Intent,
  Button,
  FormGroup, Spinner, Checkbox, TextArea
} from '@blueprintjs/core';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Toast from "helpers/toaster";
import ServiceTypePicker from "components/ServiceTypePicker";
import QuestionGroupPicker from "components/QuestionGroupPicker";
import BookingsSidebar from '../bookings/bookings-sidebar';
import Select from 'react-select';
import { Helmet } from 'react-helmet';

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`
let options = [
  {label: 'Textbox', value: 'Textbox'},
  {label: 'Checkbox', value: 'Checkbox'},
  {label: 'Dropdown', value: 'Dropdown'}
];

let is_call_first_time = 0;

@inject("questionStore")
@observer
class QuestionShowPage extends Component {
  @observable selectedServiceType = [];
  @observable selectedGroup = [];

  updateQuestion() {
    let {
      props: {
        questionStore: {
          question: { 
            object
           }
        }
      }
    } = this;
   
    if(object.attributes.field_label === "" || object.attributes.field_label === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid label!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.field_type === "" || object.attributes.field_type === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please select valid field type!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.service_type_id === "" || object.attributes.service_type_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please select valid service type!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.question_group_id === "" || object.attributes.question_group_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please select valid question group!', intent: Intent.DANGER })
      return;
    }

    this.props.questionStore
      .updateQuestion(object)
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Question updated successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to update Question!",
          intent: Intent.DANGER
        });
      });
    
  }
 
  onServiceTypeSelect(selectedOptions) {
    this.selectedServiceType = [];
    let { object } = this.props.questionStore.question;
    object.selectedServiceType = selectedOptions;
    if (selectedOptions.length > 0) {
      object.attributes.service_type_id = selectedOptions[0].value;
      this.selectedServiceType = [{value: selectedOptions[0].value,selected: true,label: selectedOptions[0].label}]
    }
  }

  onGroupSelect(selectedOptions) {
    this.selectedGroup = [];
    let { object } = this.props.questionStore.question;
    object.selectedGroup = selectedOptions;
    if (selectedOptions.length > 0) {
      object.attributes.question_group_id = selectedOptions[0].value;
      this.selectedGroup = [{value: selectedOptions[0].value,selected: true,label: selectedOptions[0].label}]
    }
  }

  handleChange = selectedOption => {
    let {
      questionStore: {
        question: {
          object
        }
      }
    } = this.props;
    if (selectedOption.value) {      
      object.attributes.field_type = selectedOption.value;
    }  
  };

  updateState = () => {
    let {
      questionStore: {
        question: {
          object
        }
      }
    } = this.props;

    if(object && object.attributes){
      this.selectedServiceType = [{value: object.attributes.service_type_id,selected: true,label: object.attributes.service_type.name}]
     }
    if(object && object.attributes && object.attributes.question_group){
      this.selectedGroup = [{value: object.attributes.question_group_id,selected: true,label: object.attributes.question_group.name}]
    }
  }

  render() {
    let {
      questionStore: {
        question: {
          object,
          isLoading
        }
      }
    } = this.props;
    
    if(is_call_first_time == 0) {
      if(object && object.attributes) {
        is_call_first_time = 1;
        this.updateState();
      }
    }

    return (
      <div>
        <Helmet>
          <title>{'Questions | Fieldnexus'}</title>
        </Helmet>
       
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <BookingsSidebar />
          </div>
          {object && object.attributes &&
            <div className="col-lg-10">
              {isLoading && <div className="display-spinner-center"><Spinner /></div>}
              {!isLoading && <PageWrapGray>
                <div className="pricing-parameter-table">
                  <Card>
                    <CardContent>
                      <div>
                        <div className="left-side-panel-servie-type">
                          <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                            Update Question
                          </h4>
                        </div>
                      </div>
                      <Divider style={{ margin: '16px 0px' }}></Divider>
                      <div className="left-side-panel-servie-type pricing-parameter">
                        <FormGroup label="Name" >
                          <input 
                            type='text' 
                            className='form-control' 
                            placeholder='Name'
                            defaultValue={object.attributes.field_label}
                            onChange={(event) => {object.attributes.field_label = event.target.value;}}            
                          />
                        </FormGroup>
                        <FormGroup 
                          label="Service Type"
                          labelFor="text-input"
                          labelInfo="(*)"
                        >
                          <ServiceTypePicker
                            selectedOptions={this.selectedServiceType}
                            onChange={this.onServiceTypeSelect.bind(this)}
                          />
                        </FormGroup>
                        <FormGroup 
                          label="Question Group(s)"
                          labelFor="text-input"
                          labelInfo="(*)"
                        >
                          <QuestionGroupPicker
                            selectedOptions={this.selectedGroup}
                            onChange={this.onGroupSelect.bind(this)}
                          />
                        </FormGroup>
                        <FormGroup 
                          label="Select Field Type"
                          labelFor="text-input"
                        >
                          <Select
                            options={options} 
                            defaultValue={{label: object.attributes.field_type, value: object.attributes.field_type}}
                            onChange={this.handleChange}        
                            isSearchable={true}
                            isClearable={false}
                            placeholder={"Select Field Type"}        
                          />
                        </FormGroup>
                        <div style={{ marginBottom: '1rem'}} className="invoice_enable_signature">
                          <Checkbox 
                            label="Is required"
                            defaultChecked={object.attributes.is_required}
                            onChange={event => {
                              object.attributes.is_required = event.target.checked;
                            }}
                          />
                        </div>
                        <FormGroup label="Field Value (red:red)" >
                          <TextArea 
                            type='text' 
                            className='form-control' 
                            placeholder=''
                            defaultValue={object.attributes.field_value}
                            onChange={(event) => {object.attributes.field_value = event.target.value;}}            
                          />
                        </FormGroup>
                      </div>
                      
                      
                      <div className="">
                        <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.updateQuestion.bind(this)}>Update</Button>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </PageWrapGray>}
            </div>}
          </div>
      </div>
    );
  }
}

export default QuestionShowPage;
