import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { 
  Button, Intent
} from '@blueprintjs/core';
import Toast from 'helpers/toaster';
import PaymentMethodModal from 'components/PaymentMethod/PaymentMethodModal';


@inject('store', 'paymentMethodStore','quickbooksStore') @observer
class PaymentMethodTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable isPaymentMethodDialogVisible = false;
  @observable paymentMethod;

  handlePaymentMethodClick(event, paymentMethod) {
    switch (event.label) {
      case 'Edit':
        this.paymentMethod = paymentMethod;
        this.props.paymentMethodStore.paymentMethod.object = paymentMethod.attributes;
        this.isPaymentMethodDialogVisible = true;
        this.props.quickbooksStore.loadQuickbooksPaymentMethods()
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.paymentMethod = paymentMethod;
        break;
      default:
    }
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.paymentMethodStore.delete(this.paymentMethod)
      .then(this.onSuccessfulDeletePaymentMethod.bind(this))
      .catch(this.onFailedPaymentMethodDelete.bind(this))
  }

  onSuccessfulDeletePaymentMethod(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Payment Method deleted successfully!', intent: Intent.SUCCESS })
    this.paymentMethod = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedPaymentMethodDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete payment method!', intent: Intent.DANGER })
  }

  onClosePaymentMethodDialog() {
    this.isPaymentMethodDialogVisible = false;
  }

  onSucessUpdatePaymentMethod() {
    this.isPaymentMethodDialogVisible = false;
  }

  render() {
    let {
      paymentMethodStore: {
        paymentMethods: {
          objects
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning" style={{marginTop:'15px'}}>
          <h4 className="bp3-heading">No Results</h4>
        </div>
      )
    }
    return(
      <React.Fragment>
         <div className="card rounded-0 table-responsive">
        <table className="table table-hover" style={{ width: '100%'}}>
          <thead>
            <tr>
              <th>Name</th>
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((method) => {
                return(
                  <tr key={method.id}>
                    <td>{method.attributes.name}</td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit" minimal={true} small={true} onClick={() => this.handlePaymentMethodClick({ label: 'Edit' }, method)}>
                          <img src="/edit-light-icon.svg"/>
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        </div>
        <PaymentMethodModal 
          isOpen={this.isPaymentMethodDialogVisible} 
          title={"Update Payment Method"}
          edit={true}
          onClose={this.onClosePaymentMethodDialog.bind(this)}
          onSuccess={this.onSucessUpdatePaymentMethod.bind(this)}
        />
      </React.Fragment>
    )
  }

};

export default PaymentMethodTable;