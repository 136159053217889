import React from 'react';
import {
  Spinner
} from '@blueprintjs/core';
import Avatar from '@atlaskit/avatar';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Api } from 'services/Api';
import { formatTime } from '../../helpers/time';

@observer
class JobsTable extends React.Component {

  @observable jobs = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    let { project } = this.props;
    let promise = Api.get(`/projects/${project.attributes.id}/jobs`);

    promise
      .then((res) => {
        this.jobs = {
          isLoading: false,
          objects: res.data.data
        }
      })
  }

  render() {
    let { objects, isLoading } = this.jobs;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No jobs have been created for this project yet.
        </div>
      )
    }
    return(
      <div className="card rounded-0 table-responsive">
      <table className='table table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th></th>
            <th>Created By</th>
            <th>Job Number</th>
            <th>Name</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {
            objects.map((job) => {
              return(
                <tr key={job.id}>
                  <td width="10px">
                    <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=${job.attributes.created_by.full_name}&size=64`)} size='large' />
                  </td>
                  <td width="150px">
                    <p className="primary">{job.attributes.created_by.full_name}</p>
                  </td>
                  <td>
                    <a href={`/dashboard/jobs/${job.id}`}>
                      <p className="secondary">{job.attributes.event_number}</p>
                    </a>
                  </td>
                  <td>
                    <a href={`/dashboard/jobs/${job.id}`}>
                      <p className='time-entry primary'>{job.attributes.title}</p>
                    </a>
                  </td>
                  <td>
                    <p className='secondary'>{formatTime(job.attributes.start_date)} - {formatTime(job.attributes.end_date)}</p>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      </div>
    )
  }
};

export default JobsTable;
