import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import { observable, toJS } from 'mobx';
import { Intent, Button, Spinner, Alert} from '@blueprintjs/core';

@inject('store', 'equipmentStore') @observer
export class EquipmentImages extends Component {

  @observable images = [];
  @observable imageSet = [];
  @observable isOpen = false;
  @observable currentImage = 0;
  @observable isDeleteDialogVisible = false;
  @observable isSelectImage;

  
  componentDidMount() {
    let { 
      equipment
    } = this.props;
    this.images = equipment.attributes.images;
    this.setImages();
  }

  handleSelectCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleSelectConfirm(image) {
    this.isDeleteDialogVisible = true; 
    this.isSelectImage = image;   
  }

  setImages() {   
    let { 
      equipment
    } = this.props;
    
    this.images = equipment.attributes.images;

    this.imageSet = [];

    if(this.images.length > 0) {
      this.images.map((image) => {
        if(image.asset){
          this.imageSet.push({src: image.asset.url, thumbnail: image.asset.thumb.url,id: image.id})
          return this.imageSet;
        }    
      })
    }
  }

  closeLightbox() {
    this.isOpen = false;
  }

  openLightbox(index) {
    this.currentImage = index
    this.isOpen = true;
  }

  gotoPrevious() {
    this.currentImage = this.currentImage - 1
    this.forceUpdate()
  }

  gotoNext() {
    this.currentImage = this.currentImage + 1
    this.forceUpdate()
  }

  gotoImage(index) {
    this.currentImage = index;
  }

  removeAttachment(attachment) {
    let { 
      equipment
    } = this.props;
    let params = {
      uuid: equipment.attributes.id
    }
    this.images = equipment.attributes.images;
    this.isDeleteDialogVisible = false;
    this.props.equipmentStore.removeAttachment(this.isSelectImage, equipment.attributes.id)
      .then((response) => {
        this.isDeleteDialogVisible = false
        this.props.equipmentStore.loadEquipmentForImage(params)
          .then((response) =>{
            this.imageSet = [];
            this.setImages();
          })
      })
  }

  render() {
    let { 
      equipment,
      showUploadAttachmentLoader
    } = this.props;
    return(
      <React.Fragment>
        <div className="job-desription table-title" style={{ paddingLeft: '10px' }}>
          
        </div>

        <div style={{ padding: '0px 10px' }} >
          {
            this.imageSet.length === 0 && <div className="bp3-callout warning-alert-message bp3-intent-warning bp3-icon-info-sign table-card">
              No images have been added to this equipment.
            </div>
          }
        </div>

        <div style={{ paddingLeft: '10px', paddingBottom: '10px', display:'flex', flexWrap:'wrap' }}>
          
          {
            this.imageSet.length > 0 && this.imageSet.map((image, index) => 
              <div className="wr-img-wrap">
                <Button icon="trash" className="btn-img-del" onClick={() => this.handleSelectConfirm(image)}/>
                <img 
                  src={image.thumbnail}
                  alt="Attached with Equipment"
                  key={index}
                  onClick={() => this.openLightbox(index)}
                  className="wr-img"
                />
              </div>
            )
          }
          <Lightbox
            open={this.isOpen}
            close={this.closeLightbox.bind(this)}
            slides={toJS(this.imageSet)}
            plugins={[Zoom, Thumbnails]}
            index={this.currentImage}
            zoom={{
              maxZoomPixelRatio: 10,
              zoomInMultiplier: 1.5,
            }}
          />
        </div>
        <Alert
          cancelButtonText="Close"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleSelectCancel.bind(this)}
          onConfirm={this.removeAttachment.bind(this)}
        >
          Are you sure to delete the selected image from your current equipment?
        </Alert>
      </React.Fragment>
    )
  }
}

export default EquipmentImages;