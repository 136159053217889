import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

class PaymentMethodStore {

  @observable paymentMethods = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable paymentMethod = {
    isLoading: false,
    isError: false,
    object: {
      name: '',
      payment_method_name:''
    }
  };

  @action
  create() {
    this.paymentMethod.isLoading = true;
    let promise = Api.post(`/payment_methods`, {payment_method: this.paymentMethod.object})

    promise
      .then((response) => {
        this.paymentMethod.isLoading = false;
        this.loadPaymentMethods();
      })
      .catch((error) => {
        this.paymentMethod.isLoading = false;
        this.paymentMethod.isError = true;
      })
    
    return promise;
  }

  @action
  createOnQuickBooks() {
    this.paymentMethod.isLoading = true;
    let promise = Api.post(`/quickbooks/create_payment_method`, {payment_method: this.paymentMethod.object})

    promise
      .then((response) => {
        this.paymentMethod.isLoading = false;
        this.props.quickbooksStore.loadQuickbooksPaymentMethods();
      })
      .catch((error) => {
        this.paymentMethod.isLoading = false;
        this.paymentMethod.isError = true;
      })
    
    return promise;
  }

  @action
  update() {
    this.paymentMethod.isLoading = true;
    let promise = Api.put(`/payment_methods/${this.paymentMethod.object.id}`, {payment_method: this.paymentMethod.object})

    promise
      .then((response) => {
        this.paymentMethod.isLoading = false;
        this.loadPaymentMethods();
      })
      .catch((error) => {
        this.paymentMethod.isLoading = false;
        this.paymentMethod.isError = true;
      })
    
    return promise;
  }

  @action
  loadPaymentMethods() {
    this.paymentMethods.isLoading = true;
    let promise = Api.get(`/payment_methods`)

    promise
      .then((response) => {
        this.paymentMethods.isLoading = false;
        this.paymentMethods.objects = response.data.data;
      })
      .catch((error) => {
        this.paymentMethods.isLoading = false;
        this.paymentMethods.isError = true;
      })
    
    return promise
  }


};

export default PaymentMethodStore;