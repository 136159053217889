import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { 
  Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
import AddEquipmentModal from 'components/Equipment/AddEquipmentModal';
import qs from 'qs';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';
import TextTruncate from 'react-text-truncate';

@inject('store', 'clientStore', 'equipmentStore', 'viewStore') @observer
class EquipmentsTable extends Component {
  @observable isOpenAddEquipmentDialog = false;
  @observable isDeleteDialogVisible = false;
  @observable equipment;

  onPaginationChange(newPage) {
    let {
      viewStore: {
        equipmentSearch
      }
    } = this.props;
    this.props.viewStore.equipmentSearch = {
      ...equipmentSearch,
      page: newPage
    };
    this.props.viewStore.equipments();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.equipmentStore.deleteEquipment(this.equipment)
      .then(this.onSuccessfulDeleteEquipment.bind(this))
      .catch(this.onFailDeleteEquipment.bind(this))
  }

  onSuccessfulDeleteEquipment(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Equipment deleted successfully!', intent: Intent.SUCCESS })
    this.equipment = null;
    this.isDeleteDialogVisible = false;
  }

  onFailDeleteEquipment(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete equipment!', intent: Intent.DANGER })
  }

  handleEquipmentClick(event, equipment) {
    switch (event.label) {
      case 'Edit':
        this.equipment = equipment;
        this.props.viewStore.equipmentShow({uuid: equipment.attributes.id})
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.equipment = equipment;
        break;
      default:
    }
  }

  handleClosEquipmentDailog() {
    this.props.store.isOpenAddEquipmentDialog = false;
  }

  onSuccessAddEquipment(equipment) {
    this.props.store.isOpenAddEquipmentDialog = false;
    this.onPaginationChange(1);
  }

  render() {
    let {
      viewStore,
      clientStore,
      equipmentStore: {
        equipments: {
          objects,
          meta
        }
      },
      store
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <div className="card rounded-0 table-responsive">
          <Footer>
            <div className="bp3-navbar-group bp3-align-left">
              <Pagination
                value={meta.page}
                total={meta.total_pages}
                onChange={this.onPaginationChange.bind(this)}
              />
            </div>
          </Footer>
        <table className="table table-data-presentation" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Serial#</th>
              <th>Make</th>
              <th>Model</th>
              <th>Equipment Location</th>
              <th className="width-30">Description</th>
              <th>Client</th>
              {/* <th>Client Location</th> */}
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((equipment) => {
                return (
                  <tr key={equipment.id}>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/clients/${equipment.attributes.client.id}?${qs.stringify(viewStore.clientShowSearch, { encode: false })}`}
                        mainText={equipment.attributes.serial_number}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText= {equipment.attributes.make}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText= {equipment.attributes.eq_model}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText= {equipment.attributes.eq_location}
                      />
                    </td>
                    <td>
                      <TextTruncate
                        line={2}
                        truncateText="…"
                        text={equipment.attributes.description}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/clients/${equipment.attributes.client.id}?${qs.stringify(viewStore.clientShowSearch, { encode: false })}`}
                        mainText={equipment.attributes.client.name}
                        subText={equipment.attributes.location && equipment.attributes.location.full_address}
                      />
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.equipmentShow({uuid: equipment.attributes.id})}>
                          <img src="/edit-light-icon.svg"/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleEquipmentClick({ label: 'Delete' }, equipment)}>
                          <img src="/delete-light-icon.svg" />
                        </Button>
                      </div>
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>
          <Footer>
            <div className="bp3-navbar-group bp3-align-left">
              <Pagination
                value={meta.page}
                total={meta.total_pages}
                onChange={this.onPaginationChange.bind(this)}
              />
            </div>
          </Footer>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Warnning! Deleting this equipment will also delete this equipment from all work report etc. and will not be recoverable.
          </p>
          <p>
            Are you sure you want to do this?
          </p>
        </Alert>
        <AddEquipmentModal
          isOpen={store.isOpenAddEquipmentDialog}
          onClose={this.handleClosEquipmentDailog.bind(this)}
          onSuccess={this.onSuccessAddEquipment.bind(this)}
        />
        <AddEquipmentModal
          isOpen={store.isOpenAddEquipmentDialog}
          onClose={store.handleClosEquipmentDailog.bind(store)}
          onSuccess={store.onSuccessAddEquipment.bind(store)}
        />
      </React.Fragment>
    )
  }

}

export default EquipmentsTable;