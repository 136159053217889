import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import {
  Dialog,
  Classes,
} from "@blueprintjs/core";
import _ from 'lodash';

@inject('preferenceStore') @observer
class BookingConfirmationMessage extends Component {

  handleCloseBookingConfirmDailog() {
    this.props.onClose();
    //window.location.reload();
  }

  render() {
    let {
      message,
      messageTitle,
      total_amount
    } = this.props;
    return (
      <Dialog
        title="Booking Confirmation"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseBookingConfirmDailog.bind(this)}
      >
        <div className="row no-gutters company-page-layput booking-confirmation">
          {
            <div className={Classes.DIALOG_BODY}>
              <div className="booking-public-page">
                <div className="column-left">
                  <div className="booking-form">
                    <div className="booking-form-header">
                      {/* <div className="header-title">Your Booking submitted successfully.</div> */}
                      <img className="confirmation-icon" src="/confirmation-icon.svg" />
                      <div className="body-content">{messageTitle}</div>
                      <div className="sub-content">{message}</div>
                      <div><label className="form-control">Total (approximate) : {total_amount}</label></div>
                      {/* <div className="more-booking-btn">
                        <a href="">Book More</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </Dialog>
    );
  }

};

export default BookingConfirmationMessage;