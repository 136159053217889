import React from 'react';

class TiltYourPhonePopup extends React.Component { 
  render() {
    return(
      <div className="till-rotate">
        <div className="rotate-box">
          <div className="phone">
          </div>
          <div className="message">
            Please Rotate Your Device For Better User Experiance.
          </div>
        </div>
      </div>
    )    
  }
}

export default TiltYourPhonePopup;
