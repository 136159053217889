import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

class ProductCategoryStore {

  @observable productCategories = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable productCategory = {
    isLoading: false,
    isError: false,
    object: {
      title:''
    }
  };

  @observable productServiceLink = {
    isLoading: false,
    isError: false,
    obj: {}
  }


  @action
  loadProductCategories() {
    this.productCategories.isLoading = true;
    const {
      per_page,
      page,
      order,
      where
    } = stores.viewStore.categorySearch;

    let promise = Api.post('/product_category/search', { 
      search: { 
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.productCategories.isLoading = false;
        this.productCategories.objects = response.data.data;
      })
      .catch((error) => {
        this.productCategories.isLoading = false;
        this.productCategories.isError = true;
      })
    
    return promise
  }

  @action
  create() {
    this.productCategory.isLoading = true;
    let promise = Api.post(`/product_category`, {product_category: this.productCategory.object})

    promise
      .then((response) => {
        this.productCategory.isLoading = false;
        this.loadProductCategories();
      })
      .catch((error) => {
        this.productCategory.isLoading = false;
        this.productCategory.isError = true;
      })
    
    return promise;
  }

  @action
  update() {
    this.productCategory.isLoading = true;
    let promise = Api.put(`/product_category/${this.productCategory.object.id}`, {product_category: this.productCategory.object})

    promise
      .then((response) => {
        this.productCategory.isLoading = false;
        this.loadProductCategories();
      })
      .catch((error) => {
        this.productCategory.isLoading = false;
        this.productCategory.isError = true;
      })
    
    return promise;
  }

  @action
  delete(productCategory) {
    this.productCategories.isLoading = true;
    let promise = Api.delete(`/product_category/${productCategory.attributes.id}`)

    promise
      .then((response) => {
        this.loadProductCategories();
      })
      .catch((error) => {
        this.productCategories.isLoading = false;
        this.productCategories.isError = true;
      })
    
    return promise;
  }

}

export default ProductCategoryStore;