import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import DefaultItem from './DefaultItem';
import _ from 'lodash';
import Card from 'components/Card';
import styled from '@emotion/styled';
import SettingsSidebar from '../../pages/preferences/settings-sidebar';

const PreferenceGrid = styled('div')`
  width: 100%;  
`

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit * 2}px`};
`

@inject('store', 'preferenceStore') @observer
class InvoiceSettingDetails extends Component {

  render() {
    let {  
      preferenceStore: {
        tenant: {
          object
        }
      } 
    } = this.props;
    return(
      <PreferenceGrid>
        <SettingsSidebar />
        <Card>
          <CardContent>
            <h4>Invoice Settings coming soon</h4>

          </CardContent>
        </Card>
      </PreferenceGrid>
    )
  }
};

export default InvoiceSettingDetails;