import React from 'react';

class MainSiteLogo extends React.Component {
  render() {
    return (
      <div className="brand">
        <a href="https://fieldnexus.com">
          <img style={{ maxWidth: '200px' }} src="/brand.svg" alt='Field Nexus' />
        </a>
      </div>
    )
  }
}

export default MainSiteLogo;