import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { 
  Spinner, NonIdealState,
  Button, Position, Tag, Popover, Intent, MenuItem, Menu, MenuDivider, Dialog
} from '@blueprintjs/core';
import styled from '@emotion/styled';
import QuoteShow from 'components/Quote/Show';
import PdfViewer from 'components/Quote/PdfViewer';
import { TopBanner } from 'components/Styles';
import ListPicker from 'components/ListPicker';
import { CurrentEnv } from 'services/Api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from 'helpers/toaster';
import SendQuoteModal from 'components/Payment/SendQuoteModal';
import _ from 'lodash';
import { currencyFormattedAmount, currency } from 'helpers/currency';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`
const options = [
  { label: 'Draft', value: 'draft', selected: false },
  { label: 'Approved', value: 'approved', selected: false },
  { label: 'Submitted', value: 'submitted', selected: false },
  { label: 'Sent', value: 'sent', selected: false },
  { label: 'On Hold', value: 'on_hold', selected: false },
  { label: 'Rejected', value: 'rejected', selected: false }
];

@inject('store', 'quoteStore', 'viewStore','jobStore') @observer
class QuoteShowHeader extends Component {

  @observable showPdf = false;
  @observable isOpenSendQuoteModal = false;
  @observable client_details = {
    attributes:{
      name:''
    }
   };

  componentDidMount(){
    let {
      quoteStore: {
        quote: {
          object
        }
      },
      viewStore: {
        currentUser
      }
    } = this.props;
    this.proposalPdfLink = `${CurrentEnv.baseURL()}/work_reports/${object.attributes.id}/download_proposal?api_token=${currentUser.attributes.api_token}&x_tenant_id=${currentUser.attributes.tenant_id}`;
    this.costSheetPdfLink = `${CurrentEnv.baseURL()}/work_reports/${object.attributes.id}/download_pdf?api_token=${currentUser.attributes.api_token}&x_tenant_id=${currentUser.attributes.tenant_id}`
    this.estimatePdfLink = `${CurrentEnv.baseURL()}/work_reports/${object.attributes.id}/download_estimate?api_token=${currentUser.attributes.api_token}&x_tenant_id=${currentUser.attributes.tenant_id}`
  }

  downloadCostSheet() {
    let {
      viewStore: {
        currentUser
      },
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;
    window.open(this.costSheetPdfLink)
  }

  downloadProposal() {
    let {
      viewStore: {
        currentUser
      },
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;
    window.open(this.proposalPdfLink);
  }

  downloadEstimate(){
    let {
      viewStore: {
        currentUser
      },
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;
    window.open(this.estimatePdfLink) 
  }

  onPdfViewerClose() {
    this.showPdf = false;
  }

  onQuoteStatusChange(selections) {
    let {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;
    let {
      props: {       
        store
      }
    } = this;
    let selectedValues = selections.map((status) => status.value);
    object.attributes.status = selectedValues[0];
    
    if(object.attributes.status) {
      this.props.quoteStore.changeStatus(object, object.attributes.status);
      if(object.attributes.status === 'approved'){
        setTimeout(() => {
          store.projectDetail =  object.attributes;        
          store.isOpenAddProjectDialog = true;
        }, 3000);
      }  
    }
  }

  generateInvoice(quote) {
    this.props.quoteStore.generateInvoice(quote)
      .then((response) => {
        Toast.show({ message: "Invoice generated successfully.", intent: Intent.SUCCESS })
      })
      .catch((error) => {
        if(error.response && error.response.data) {
          Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ message: "Problem in generating invoice", intent: Intent.DANGER })
        }
      })
  }
  sendQuotePDF = () => {
    this.isOpenSendQuoteModal = true;
  }

  handleCloseQuoteSendDailog() {
    this.isOpenSendQuoteModal = false;
  }

  onSuccessQuoteSend() {
    this.isOpenSendQuoteModal = false;
  }

  handleCloseCopyDailog() {
    let {
      store
    } = this.props;
    store.isOpenAddCopyDialog = false;
  }

  openPdfModal = (url) => {
    let {
      quoteStore: {
        quote: {
          object
        }
      },
      viewStore: {
        currentUser
      }
    } = this.props;
    let kind = object.attributes.kind === 'WorkReport' ? "Work Order" : "Estimate";
    this.showPdf = true; 
    this.showPdfUrl = url;
    if(object.attributes.job && object.attributes.job.id) {
      this.showPdfTitle = `PDF: Job #: ${object.attributes.job.event_number} | ${kind} #: ${object.attributes.number.split('-')[1]}`;
    } else {
      this.showPdfTitle = `PDF: ${kind} #: ${object.attributes.number}`
    }
  }

  calculateGrandTotal() {    

    const {
      quoteStore: {
        quote: {
          object
        }
      } 
    } = this.props;
    let total = 0.0;
    
    if(object.attributes.labour.length > 0) {  
      total = total + _.sumBy(object.attributes.labour, function(labour) {
        let taxTotal = _.sumBy(labour.tax_line_items, function(taxLineItem) {
                      return (taxLineItem.percentage_value/100) * labour.total
                  });
        return labour.total + taxTotal;   
      })           
    } 
    if(object.attributes.materials.length > 0) {
      total = total + _.sumBy(object.attributes.materials, function(material) {
        let taxTotal = _.sumBy(material.tax_line_items, function(taxLineItem) {
                      return (taxLineItem.percentage_value/100) * material.total
                  });
        return material.total + taxTotal;   
      })
    } 
    if(object.attributes.custom_line_items.length > 0) {
      total = total + _.sumBy(object.attributes.custom_line_items, function(line_item) {
        let taxTotal = _.sumBy(line_item.tax_line_items, function(taxLineItem) {
                      return (taxLineItem.percentage_value/100) * line_item.total
                  });
        return line_item.total + taxTotal;   
      })
    } 
    return currencyFormattedAmount(total)
  }

  dispatchTech = () => {
    let {
      store,
      quoteStore: {
        quote
      },
      jobStore:{
        persistJob:{
          object
        }
      }
    } = this.props;

    if(quote.object.attributes.proposal_markup != null){
      object.reason_for_call = quote.object.attributes.proposal_markup.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/g, "");
      object.title = quote.object.attributes.proposal_markup.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/g, "");
    }

    if (quote.object.attributes.flat_rate) {
      object.reason_for_call = `Grand Total (${currency()}): ${this.calculateGrandTotal()}`;
    }
    
    // client pre selected
    this.client_details.attributes = quote.object.attributes.client;
    this.client_details.attributes.name = quote.object.attributes.client.name;
    object.client = this.client_details;
    object.client_id = quote.object.attributes.client.id;

    // location pre selected
    let location_details = {
      attributes: quote.object.attributes.location
    };
    object.location = location_details;
    object.location_id = quote.object.attributes.location.id;
    
    store.isOpen = true;
  }

  render() {
    let {
      quoteStore: {
        quote: {
          object
        },
        isGeneratingInvoice
      },
      store
    } = this.props;

    const moreAction = (
      <Menu>
        <MenuItem icon="new-link" text="Open In New Tab" href={object.attributes.public_link} target="_blank"/>
        <MenuItem icon="document-share" text="Copy Link" onClick={() => { store.isOpenAddCopyDialog = true }}/>
        <MenuItem icon="link" text="Generate Invoice" onClick={() => this.generateInvoice(object) }/>
        <MenuItem text="View PDF" popoverProps={{ openOnTargetFocus: false }}>
          <MenuItem icon="eye-open" text="Cost Sheet" onClick={() => { this.openPdfModal(this.costSheetPdfLink); }} />
          <MenuItem icon="eye-open" text="Proposal" onClick={() => { this.openPdfModal(this.proposalPdfLink); }}/>
          <MenuItem icon="eye-open" text="Estimate" onClick={() => { this.openPdfModal(this.estimatePdfLink); }}/>
        </MenuItem>
        <MenuDivider />
        <MenuItem text="Download" popoverProps={{ openOnTargetFocus: false }}>
          <MenuItem icon="document" text="Cost Sheet" onClick={() => { this.downloadCostSheet() }}/>
          <MenuItem icon="document" text="Proposal" onClick={() => { this.downloadProposal() }}/>
          <MenuItem icon="document" text="Estimate" onClick={() => { this.downloadEstimate() }}/>
        </MenuItem>
      </Menu>
    );

    return (
      <div>
        <TopBanner className="top-bar-styling">
          <div className="work-report-style" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="work-report-title">
              <h3>Estimate {object.attributes.number}</h3>
              <span className="bp3-tag">
                {object.attributes.status}
              </span>
            </div>
            <div className="estimate-grid-style more-actions bp3-align-right">
            <Popover 
                minimal={true}
                content={
                  <ListPicker
                    selectedOptions={[object.attributes.status]}
                    options={options}
                    maximumSelections={1}
                    onChange={this.onQuoteStatusChange.bind(this)}
                  />
                }
                position={Position.BOTTOM_LEFT}
                usePortal={true} 
                className="status-selector"
                >
                <Button rightIcon='caret-down' className="status-btn">
                  Status
                  <span>&nbsp;</span>
                  <Tag minimal={true} round={true}>{object.attributes.status}</Tag>
                </Button>
              </Popover>
              <div className="actions-dropdwon" options={false} {...this.props}>
                <Popover content={moreAction} position={Position.BOTTOM}>
                    <Button className="action-btn" icon="chevron-down" text="More Actions" />
                </Popover>
              </div>
              <Button className="bp3-intent-primary primary-btn" icon='upload' onClick={this.sendQuotePDF} style={{marginRight: 5}}>
                Send Estimate
              </Button>
              { object && object.attributes && object.attributes.status === 'approved' &&
                <Button className="bp3-intent-primary primary-btn" icon='upload' onClick={this.dispatchTech} style={{marginRight: 5}}>
                  Dispatch Technician
                </Button>
              }
              
            </div>
          </div>
          <SendQuoteModal
            isOpen={this.isOpenSendQuoteModal}
            onClose={this.handleCloseQuoteSendDailog.bind(this)}
            onSuccess={this.onSuccessQuoteSend.bind(this)}
          />
          {
            this.showPdf && 
              <PdfViewer 
                isOpen={this.showPdf}
                url={this.showPdfUrl}
                title={this.showPdfTitle}
                onClose={this.onPdfViewerClose.bind(this)} />
          }
        </TopBanner>
        <Dialog
          title="Copy Link"
          className="copy-link-dialog"
          isOpen={store.isOpenAddCopyDialog}
          isCloseButtonShown={true}
          onClose={this.handleCloseCopyDailog.bind(this)}        
          style={{width:'450px', backgroundColor:'#fff'}}
        >
          <div className="copy-link-dialog-content">
            <div className="copy-field">
              <input 
                type='text'
                className='bp3-input bp3-fill' 
                placeholder='Public Link'
                defaultValue={object.attributes.public_link}
              />
              <div className="copy-instruction">Copy the link and share it with your customer.</div>
              <CopyToClipboard text={object.attributes.public_link}
                onCopy={() => Toast.show({ message: "Copied!", intent: Intent.SUCCESS })}>
                <Button className='bp3-copy-link' onClick={this.handleCloseCopyDailog.bind(this)} >Copy Link</Button>
              </CopyToClipboard>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

@inject('store', 'quoteStore') @observer
class QuoteShowPage extends Component {

  get canRender() {
    let {
      quoteStore: {
        quote: {
          isError,
          object
        }
      }
    } = this.props;
    return (!isError && object && object.id);
  }

  render() {
    let content, title;
    let {
      quoteStore: {
        quote: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props; 
    if (isLoading && !isError && object && !object.id) {
      title = 'Loading...'
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load quote'; 
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      title = `#${object.attributes.number} Estimate | Field Nexus`;
      content = <QuoteShow />;
    }
    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        { this.canRender && <QuoteShowHeader /> }
        <PageWrapGray>
          {content}
        </PageWrapGray>
      </div>
    )
  }
};

export default QuoteShowPage;