import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from 'mobx';
import { 
  Divider, Intent,
  Button,
  FormGroup
} from '@blueprintjs/core';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Toast from "helpers/toaster";
import ServiceTypePicker from "components/ServiceTypePicker";
import BookingsSidebar from '../bookings/bookings-sidebar';

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`

@inject("frequencyStore")
@observer
class FrequencyAddPage extends Component {

  createFrequency() {
    let {
      props: {
        frequencyStore: {
          frequency: { 
            object
           }
        }
      }
    } = this;

    if(object.title === "" || object.title === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid title!', intent: Intent.DANGER })
      return;
    }

    this.props.frequencyStore
      .createFrequency()
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Frequency created successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to create frequency!",
          intent: Intent.DANGER
        });
      });
  }

  render() {
    let {
      frequencyStore,
      frequencyStore: {
        frequency: {
          object,
          isLoading
        }
      }
    } = this.props;
    
    return (
      <div className="row no-gutters">
        <div className="col-lg-2 sidebar-responsive">
          <BookingsSidebar />
        </div>
        <div className="col-lg-10">
          <PageWrapGray>
            <div className="pricing-parameter-table bookings-sub-forms">
              <Card>
                <CardContent>
                  <div>
                    <div className="left-side-panel-servie-type">
                      <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                        Add Frequency
                      </h4>
                    </div>
                  </div>
                  <Divider style={{ margin: '16px 0px' }}></Divider>
                  <div className="left-side-panel-servie-type pricing-parameter">
                    <FormGroup label="Title" >
                      <input 
                        type='text' 
                        className='form-control' 
                        placeholder='Title'
                        defaultValue={object.title}
                        onChange={(event) => {object.title = event.target.value;}}            
                      />
                    </FormGroup>
                
                  </div>
                  <div className="">
                    <Button className="btn-save" loading={isLoading} onClick={this.createFrequency.bind(this)}>Save</Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </PageWrapGray>
        </div>
      </div>
    );
  }
}

export default FrequencyAddPage;
