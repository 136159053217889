import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import { Button, Dialog, Classes, Intent, FormGroup, TextArea, Checkbox
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import MultipleEmailSelector from '../MultipleEmailSelector';

@inject("workReportStore")
@observer
class SendWorkReportModal extends Component {

  componentDidMount() {
    let { object } = this.props.workReportStore.SendWorkReport;
    object.emailSubject = "Work Order [#"+this.props.workReportStore.workReport.object.attributes.number+"] from "+this.props.workReportStore.workReport.object.attributes.tenant.name
  }

  handleCloseQuoteInvoiceDialog() {
    this.props.onClose();
  }

  SendWorkReportToEmail() {


    let {
      props: {
        workReportStore: {
          SendWorkReport: { object }
        }
      }
    } = this;
    
    if(!object.emailTo) {
      Toast.show({ icon: 'warning-sign', message: "Please enter email", intent: Intent.DANGER })  
      return  
    }

    if(!object.emailSubject) {
      Toast.show({ icon: 'warning-sign', message: "Please enter email subject", intent: Intent.DANGER })
      return     
    }

    // if(!object.emailMessage) {
    //   Toast.show({ icon: 'warning-sign', message: "Please enter message", intent: Intent.DANGER })  
    //   return   
    // }

    this.props.workReportStore.SendWorkReportToEmail()
      .then(response => {
        this.props.onClose();
        Toast.show({
          icon: "thumbs-up",
          message: "Work Order sent successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to sent Work Order!",
          intent: Intent.DANGER
        });
      });
  }

  onEmailChange = (emails) => {
    let {
      props: {
        workReportStore: {
          SendWorkReport: { object }
        }
      }
    } = this;
    object.emailTo = emails.map((contact) => contact.email);
  }

  render() {
    const {
      workReportStore: {
        SendWorkReport : {
          object
        },
        invoice,
        workReport
      }
    } = this.props;
    return(
      <Dialog
        title="Send Work Order"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseQuoteInvoiceDialog.bind(this)}
        // style={{minWidth: 500}}
        canOutsideClickClose={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="col-12">
            <FormGroup label="To" labelFor="text-input">
              <MultipleEmailSelector 
                onEmailChange={this.onEmailChange.bind(this)}
                contacts={
                  workReport.object.attributes && workReport.object.attributes.client ? [
                    {
                      email: workReport.object.attributes.client.email,
                      name: workReport.object.attributes.client.name
                    } 
                  ] : []
                }
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup label="Subject" labelFor="text-input">
              <input
                type="text"
                className="bp3-input bp3-fill"
                placeholder="Subject"
                value={object.emailSubject}
                onChange={event => {
                  object.emailSubject = event.target.value;
                }}
              />
          </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup label="Message" labelFor="text-input">
              <TextArea
                className="bp3-fill"
                placeholder="Enter Your Message"
                onChange={event => {
                  object.emailMessage = event.target.value;
                }}
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <div style={{ marginBottom: '1rem'}} className="invoice_enable_signature">
              <Checkbox 
                label="Send a copy to myself"
                onChange={event => {
                  object.emailCopyToSelf = event.target.checked;
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <div style={{ marginBottom: '1rem'}} className="invoice_enable_signature">
              <Checkbox 
                label="Attach as PDF"  
                checked={object.emailAttached}
                onChange={event => {
                  object.emailAttached = event.target.checked;
                }}
              />
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              fill="false"
              onClick={this.handleCloseQuoteInvoiceDialog.bind(this)}
              style={{ width: "100px" }}
            >
              Cancel{" "}
            </Button>
            <Button
              fill="false"
              intent={Intent.PRIMARY}
              loading={this.props.workReportStore.SendWorkReport.isLoading}
              onClick={this.SendWorkReportToEmail.bind(this)}
              style={{ width: "80px" }}
            >
              Send
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }

}

export default SendWorkReportModal;