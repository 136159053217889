import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { 
  Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
let numeral = require('numeral');

@inject('store', 'clientStore', 'questionStore', 'viewStore') @observer
class QuestionsTable extends Component {
  @observable isOpenAddEquipmentDialog = false;
  @observable isDeleteDialogVisible = false;
  @observable question;

  onPaginationChange(newPage) {
    let {
      viewStore: {
        questionSearch
      }
    } = this.props;
    this.props.viewStore.questionSearch = {
      ...questionSearch,
      page: newPage
    };
    this.props.viewStore.questions();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.questionStore.deleteQuestion(this.question)
      .then(this.onSuccessfulDeleteQuestion.bind(this))
      .catch(this.onFailDeleteQuestion.bind(this))
  }

  onSuccessfulDeleteQuestion(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Question deleted successfully!', intent: Intent.SUCCESS })
    this.service = null;
    this.isDeleteDialogVisible = false;
  }

  onFailDeleteQuestion(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete question!', intent: Intent.DANGER })
  }

  handleServiceTypeClick(event, question) {
    switch (event.label) {
      case 'Edit':
        this.question = question;
        this.props.viewStore.questionShow({uuid: question.attributes.id,name:question.attributes.field_label})
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.question = question;
        break;
      default:
    }
  }

  render() {
    let {
      viewStore,
      questionStore: {
        questions: {
          objects,
          meta
        }
      }
    } = this.props;

    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
          <table className="table table-width table-hover">
            <thead>
              <tr>
                <th>Field Label</th>
                <th>Field Type </th>
                <th>Service Type</th>
                <th>Question Group</th>
                <th className="item-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                objects.map((questions) => {
                  return (
                    <tr key={questions.id}>
                      <td>{questions.attributes.field_label}</td>
                      <td>{questions.attributes.field_type}</td>
                      <td>{questions.attributes.service_type && questions.attributes.service_type.name}</td>
                      <td>
                        {questions.attributes.question_group_id && questions.attributes.question_group.name}
                      </td>
                      <td>
                        <div className="action-buttons" minimal={true}>
                          <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.questionShow({uuid: questions.attributes.id,name: questions.attributes.field_label})}>
                            <img src="/edit-light-icon.svg"/>
                          </Button>
                          <Divider/>
                          <Button title="Delete" minimal={true} small={true} onClick={() => this.handleServiceTypeClick({ label: 'Delete' }, questions)}>
                            <img src="/delete-light-icon.svg" />
                          </Button>
                        </div>
                      </td>
                    </tr> 
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>Warnning! Deleting this question will not be recoverable.</p>
          <p>Are you sure you want to do this?</p>
        </Alert>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </React.Fragment>
    )
  }

}

export default QuestionsTable;