import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { 
  Button,FormGroup, TextArea, Intent, Spinner
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import styled from '@emotion/styled';
import BookingsSidebar from '../bookings/bookings-sidebar';
import { Helmet } from 'react-helmet';
import { observable } from 'mobx';
import _ from 'lodash';

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`
@inject('preferenceStore','viewStore','store') @observer
class BookingConfiguration extends Component {

  componentDidMount() {
    let {
      preferenceStore
    } = this.props;
    preferenceStore.loadTenant()
  }

  updateBookingConfiguration() {
    let {
      preferenceStore: {
        tenant: {
          object
        }
      }
    } = this.props;
    

    if(object.attributes.heading_text_for_service_type === "" || object.attributes.heading_text_for_service_type === null) {
      this.props.preferenceStore.tenant.object.attributes.heading_text_for_service_type = 'Choose Service Type';
    }

    if(object.attributes.another_heading_text_for_service === "" || object.attributes.another_heading_text_for_service === null) {
      this.props.preferenceStore.tenant.object.attributes.another_heading_text_for_service = 'Choose Your Service Type';
    }
   
    this.props.preferenceStore.updateTenant(object)
      .then(this.onSuccessfulUpdateConfig.bind(this))
      .catch(this.onFailUpdateConfig.bind(this))
  }

  onSuccessfulUpdateConfig(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Booking Configuration updated successfully.', intent: Intent.SUCCESS })
  }

  onFailUpdateConfig(error) {
    Toast.show({ icon: 'warning-sign', message: 'Problem in updating Booking Configuration!', intent: Intent.DANGER })
  }

  handleChange(event) {
    let {
      preferenceStore: {
        tenant,
        tenant: {
          object
        }
      }
    } = this.props;
    object.attributes.enable_booking_price_display = event.target.value;
  };

  handleChangeVisitUs(event) {
    let {
      preferenceStore: {
        tenant,
        tenant: {
          object
        }
      }
    } = this.props;
    object.attributes.enable_visit_us = event.target.value;
  };

  handleChangeOftenVisitUs(event) {
    let {
      preferenceStore: {
        tenant,
        tenant: {
          object
        }
      }
    } = this.props;
    object.attributes.enable_often_visit_us = event.target.value;
  };

  handleChangePaymentMethod(event) {
    let {
      preferenceStore: {
        tenant,
        tenant: {
          object
        }
      }
    } = this.props;
    object.attributes.payment_method = event.target.value;
  };

  handleChangeLimitAddress(event) {
    let {
      preferenceStore: {
        tenant,
        tenant: {
          object
        }
      }
    } = this.props;
    object.attributes.limit_address = event.target.value;
  };

  render() {
    let {
      preferenceStore: {
        tenant,
        tenant: {
          object
        }
      }
    } = this.props;
    
    const title = `Booking Configuration | FieldNexus`
    return (

      <div className="row no-gutters company-page-layput">
        <div className="col-lg-2 sidebar-responsive">
            <BookingsSidebar />
            <Helmet>
              <title>{title}</title>
            </Helmet>
        </div>
        
        <div className="col-lg-10">
          <PageWrapGray>
            { 
              tenant.isLoading && !object &&
                <Spinner />
            }
            <div className="row m-0">
              { object && 
                <div className="col-md-8 pr-0 pl-0">
                  <div className="card">
                    <div className="card-body">
                      <h5>Booking Configuration</h5>
                      <hr />
                      
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="Enable Booking Price Display For Customer"
                          labelFor="text-input"
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.enable_booking_price_display} 
                              onChange={(event) => {this.handleChange(event)} }
                              //onChange={(event) => { object.attributes.enable_booking_price_display = event.target.value }}
                            >
                              <option value='' disabled>Select option..</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                      { object.attributes.enable_booking_price_display === "true" || object.attributes.enable_booking_price_display === true ?
                        <div className="form-group col-md-6">
                          <FormGroup
                            label="Enable Booking Pricing Parameter"
                            labelFor="text-input"
                          >
                            <div className="bp3-select bp3-fill">
                              <select 
                                defaultValue={object.attributes.enable_booking_pricing_parameter} 
                                onChange={(event) => { object.attributes.enable_booking_pricing_parameter = event.target.value }}
                              >
                                <option value='' disabled>Select option..</option>
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                              </select>
                            </div>                          
                          </FormGroup>
                        </div>
                        :
                        ''
                      }

                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup label="Booking Form Color" labelFor="text-input" labelInfo="">
                          <input
                            type="color"
                            className="bp3-input bp3-fill"
                            placeholder="Color"
                            defaultValue={`${object.attributes.booking_form_color}`}
                            onChange={event => {
                              object.attributes.booking_form_color = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup label="Booking Form Background Color" labelFor="text-input" labelInfo="">
                          <input
                            type="color"
                            className="bp3-input bp3-fill"
                            placeholder="Color"
                            defaultValue={`${object.attributes.booking_form_bgcolor}`}
                            onChange={event => {
                              object.attributes.booking_form_bgcolor = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup label="Service Type Heading" labelFor="text-input" labelInfo="">
                          <input
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="Service Type Heading"
                            defaultValue={object.attributes.heading_text_for_service_type || `Choose Service Type`}
                            onChange={event => {
                              object.attributes.heading_text_for_service_type = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup label="Service Type Another Heading" labelFor="text-input" labelInfo="">
                          <input
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="Service Type Another Heading"
                            defaultValue={object.attributes.another_heading_text_for_service || `Choose Your Service Type`}
                            onChange={event => {
                              object.attributes.another_heading_text_for_service = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="Display Service Type"
                          labelFor="text-input"
                          labelInfo=""
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.display_service_type} 
                              onChange={(event) => { object.attributes.display_service_type = event.target.value }}
                            >
                              <option value='' disabled>Select option..</option>
                              <option value='Text'>Text</option>
                              <option value='Icon'>Icon</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="Booking Form Display Style"
                          labelFor="text-input"
                          labelInfo=""
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.booking_view_style} 
                              onChange={(event) => { object.attributes.booking_view_style = event.target.value }}
                            >
                              <option value='' disabled>Select option..</option>
                              <option value='page'>Page</option>
                              <option value='widget'>Widget</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup label="Confirmation Message Title" labelFor="text-input" labelInfo="">
                          <input
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="Confirmation Message Title"
                            defaultValue={object.attributes.booking_confirmation_message_title || `Booking Successful`}
                            onChange={event => {
                              object.attributes.booking_confirmation_message_title = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup label="Confirmation Message" labelFor="text-input" labelInfo="">
                          <input
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="Confirmation Message"
                            defaultValue={object.attributes.booking_confirmation_message || `Your booking is recorded successfully.`}
                            onChange={event => {
                              object.attributes.booking_confirmation_message = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup label="Booking Page Title" labelFor="text-input" labelInfo="">
                          <input
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="Booking Page Title"
                            defaultValue={object.attributes.booking_page_title}
                            onChange={event => {
                              object.attributes.booking_page_title = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup label="Booking Page Subtitle" labelFor="text-input" labelInfo="">
                          <input
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="Booking Page Subtitle"
                            defaultValue={object.attributes.booking_page_sub_title}
                            onChange={event => {
                              object.attributes.booking_page_sub_title = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup label="Pricing Title" labelFor="text-input" labelInfo="">
                          <input
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="Pricing Title"
                            defaultValue={object.attributes.pricing_title}
                            onChange={event => {
                              object.attributes.pricing_title = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="When would you like us to visit?"
                          labelFor="text-input"
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.enable_visit_us} 
                              onChange={(event) => {this.handleChangeVisitUs(event)} }
                            >
                              <option value='' disabled>Select option..</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="How often you want us to visit?"
                          labelFor="text-input"
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.enable_often_visit_us} 
                              onChange={(event) => {this.handleChangeOftenVisitUs(event)} }
                            >
                              <option value='' disabled>Select option..</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="Enable Payment Method"
                          labelFor="text-input"
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.payment_method} 
                              onChange={(event) => {this.handleChangePaymentMethod(event)} }
                            >
                              <option value='' disabled>Select option..</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="How soon would you like to start this project?"
                          labelFor="text-input"
                        >
                          <TextArea
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="How soon would you like to start this project?"
                            defaultValue={object.attributes.start_project_expected_period}
                            onChange={event => {
                              object.attributes.start_project_expected_period = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="What is the best way for us to contact you?"
                          labelFor="text-input"
                        >
                          <TextArea
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="What is the best way for us to contact you?"
                            defaultValue={object.attributes.contact_way}
                            onChange={event => {
                              object.attributes.contact_way = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup label="CTA Button Title" labelFor="text-input" labelInfo="">
                          <input
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="CTA Button Title"
                            defaultValue={object.attributes.cta_title}
                            onChange={event => {
                              object.attributes.cta_title = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="Limit the addresses"
                          labelFor="text-input"
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.limit_address} 
                              onChange={(event) => {this.handleChangeLimitAddress(event)} }
                            >
                              <option value='' disabled>Select option..</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                    </div>
                    { object.attributes.limit_address === "true" || object.attributes.limit_address === true ?
                      <div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <FormGroup label="North" labelFor="text-input" labelInfo="">
                              <input
                                type="text"
                                className="bp3-input bp3-fill"
                                placeholder="North"
                                defaultValue={object.attributes.north}
                                onChange={event => {
                                  object.attributes.north = event.target.value;
                                }}
                              />
                            </FormGroup>
                          </div>
                          <div className="form-group col-md-6">
                            <FormGroup label="South" labelFor="text-input" labelInfo="">
                              <input
                                type="text"
                                className="bp3-input bp3-fill"
                                placeholder="South"
                                defaultValue={object.attributes.south}
                                onChange={event => {
                                  object.attributes.south = event.target.value;
                                }}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <FormGroup label="East" labelFor="text-input" labelInfo="">
                              <input
                                type="text"
                                className="bp3-input bp3-fill"
                                placeholder="East"
                                defaultValue={object.attributes.east}
                                onChange={event => {
                                  object.attributes.east = event.target.value;
                                }}
                              />
                            </FormGroup>
                          </div>
                          <div className="form-group col-md-6">
                            <FormGroup label="West" labelFor="text-input" labelInfo="">
                              <input
                                type="text"
                                className="bp3-input bp3-fill"
                                placeholder="West"
                                defaultValue={object.attributes.west}
                                onChange={event => {
                                  object.attributes.west = event.target.value;
                                }}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>  
                      :''
                    }
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup label="Reply-to Email" labelFor="text-input" labelInfo="">
                          <input
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="Ex. john@gmail.com"
                            defaultValue={object.attributes.booking_reply_to}
                            onChange={event => {
                              object.attributes.booking_reply_to = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="Booking customer email body"
                          labelFor="text-input"
                        >
                          <TextArea
                            type="text"
                            className="bp3-input bp3-fill"
                            placeholder="Booking customer email body"
                            defaultValue={object.attributes.booking_customer_email_body}
                            onChange={event => {
                              object.attributes.booking_customer_email_body = event.target.value;
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="form-group">
                      <Button loading={tenant.isLoading} onClick={this.updateBookingConfiguration.bind(this)} icon="floppy-disk" text="Save Details" />
                    </div>

                    </div>
                  </div>
              </div>
              }
            </div>
          </PageWrapGray>
        </div>
      
      </div>
    );
  }

};
export default BookingConfiguration;