import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { DatePicker } from '@blueprintjs/datetime';
import { Popover } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

@observer
class DateInput extends React.Component {
  
  @observable value = moment();

  componentDidMount(){
    this.value = this.props.defaultValue || moment();
  }

  componentDidUpdate(){
    this.value = this.props.defaultValue || moment();
  }

  onChange = (selectedDate, isUserChange) => {
    if(!selectedDate){
      return
    }
    if(this.props.onChange){
      this.props.onChange(selectedDate, isUserChange);
    }
    this.value = moment(selectedDate);
  }

  datePicker() {
    return (
      <React.Fragment >
        <DatePicker
          value={ this.value.toDate() }
          onChange={this.onChange.bind(this)}
          minDate={this.props.minDate ? this.props.minDate.toDate() : moment().subtract(20, 'years').toDate() }
          maxDate={this.props.maxDate ? this.props.maxDate.toDate() : moment().add(365, 'days').toDate() }
        />
      </React.Fragment>
    )
  }

  render() {
    return (
      <Popover
        minimal={false}
        position={'right'}
        content={this.datePicker.bind(this)()} >
        <input 
          className="bp3-input form-control bp3-fill"
          type="text"
          dir="auto"
          value={this.value.format('ddd, MMM DD, YYYY')}
          placeholder={this.props.placeholder}
          onChange={() => {}}
        />
      </Popover>
    )
  }
}

DateInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

export default DateInput;