import React, { Component } from 'react'
import { 
  Button, Popover,
  Position, Tag,
  ButtonGroup,ControlGroup,
  Divider, Menu,
  MenuItem, Intent
} from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import ListPicker from 'components/ListPicker';
import SearchDateRangePicker from 'components/SearchDateRangePicker';
import ServiceTypePicker from 'components/ServiceTypePicker';
import TechnicianPicker from 'components/TechnicianPicker';
import ClientAutocomplete from 'components/ClientAutocomplete';
import LocationAutocomplete from 'components/LocationAutocomplete';
import moment from 'moment-timezone';
import Toast from 'helpers/toaster';
import ProjectAutocomplete from 'components/ProjectAutocomplete';

const options = [
  { label: 'Draft', value: 'draft', selected: false },
  { label: 'Approved', value: 'approved', selected: false },
  { label: 'Rejected', value: 'rejected', selected: false }
];

@inject('store', 'viewStore', 'timesheetStore','jobStore') @observer
class Timesheet extends Component {

  @observable startDate = null;
  @observable endDate = null;
  @observable selectedClient = '';

  componentDidMount() {
    let { object } = this.props.jobStore.persistJob;   
  }

  @action
  onDateRangeChange(range) {
    let {
      props: {
        viewStore: {
          timesheetSearch
        }
      }
    } = this;
    this.props.viewStore.timesheetSearch = {
      ...timesheetSearch,
      where: {
        ...timesheetSearch.where,
        start_time: { gte: moment(range[0]).startOf('day').unix() },
        end_time: { lte: moment(range[1]).endOf('day').unix() }
      }
    }
    this.startDate = range[0];
    this.endDate = range[1];
    this.search()
  }

  @action
  onClientSelect(client) {
    let { object} = this.props.jobStore.persistJob;
    let {
      props: {
        viewStore: {
          timesheetSearch,
          timesheetSearch: {
            where: {
              client_id,
              ...whereWithNoClientId
            }
          }
        }
      }
    } = this;
    if (client) {
      this.props.viewStore.timesheetSearch = {
        ...timesheetSearch,
        where: {
          ...timesheetSearch.where,
          client_id: client.id,
          location_id: object.location_id
        }
      }

      object.client_id = client.id 
    } else {
      this.props.viewStore.timesheetSearch = {
        ...timesheetSearch,
        where: {
          ...whereWithNoClientId,
          client_id: null,
          location_id: null
        }
      }
    }
  }

  @action
  onTimesheetStatusChange(selections) {
    let selectedValues = selections.map((status) => status.value);
    let {
      props: {
        viewStore: {
          timesheetSearch
        }
      }
    } = this;
    this.props.viewStore.timesheetSearch = {
      ...timesheetSearch,
      where: {
        ...timesheetSearch.where,
        status: selectedValues
      }
    }
  }

  onServiceTypeChange(selections) {
    let selectedValues = selections.map((status) => status.value);
    let {
      props: {
        viewStore: {
          timesheetSearch
        }
      }
    } = this;
    this.props.viewStore.timesheetSearch = {
      ...timesheetSearch,
      where: {
        ...timesheetSearch.where,
        service_type_id: selectedValues
      }
    }
  }

  onTechnicianChange(technicians) {
    let selectedValues = technicians.map((status) => status.value);
    let {
      props: {
        viewStore: {
          timesheetSearch
        }
      }
    } = this;
    this.props.viewStore.timesheetSearch = {
      ...timesheetSearch,
      where: {
        ...timesheetSearch.where,
        technician_id: selectedValues
      }
    }
  }

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.timesheetSearch.page = 1;
    viewStore.timesheets();
  }

  @action
  onProjectSelect(project) {
    let {
      props: {
        viewStore: {
          timesheetSearch
        }
      }
    } = this;
    if (project) {
      this.props.viewStore.timesheetSearch = {
        ...timesheetSearch,
        where: {
          ...timesheetSearch.where,
          project_id: project.id
        }
      }
    } else {
      this.props.viewStore.timesheetSearch = {
        ...timesheetSearch,
        where: {
          ...timesheetSearch.where
        }
      }
    }
  }

  download_excel() {
    this.props.timesheetStore.download_excel(this.props.viewStore.timesheetSearch)
  }


  @action
  onLocationSelect(client) {    
    let {
      props: {
        viewStore: {
          timesheetSearch,
          timesheetSearch: {
            where: {
              client_id,
              ...whereWithNoClientId
            }
          }
        }
      }
    } = this;
    if (client) {
      this.props.viewStore.timesheetSearch = {
        ...timesheetSearch,
        where: {
          ...timesheetSearch.where,
          client_id:  client.attributes.client_id,
          location_id: client.attributes.id
        }
      }
       
    } else {
      this.props.viewStore.timesheetSearch = {
        ...timesheetSearch,
        where: {
          ...whereWithNoClientId
        }
      }
    }
    
  }


  onLocationClear = () => {
    let { object } = this.props.jobStore.persistJob;
    let {
      props: {
        viewStore: {
          timesheetSearch,
        }
      }
    } = this;
    object.location_id = null;
    object.location = null;

    this.props.viewStore.timesheetSearch = {
      ...timesheetSearch,
      where: {
        ...timesheetSearch.where,
        location_id: null
      }
    }
  }

  onClientClear = () => {
    let { object } = this.props.jobStore.persistJob;
    let {
      props: {
        viewStore: {
          timesheetSearch,
        }
      }
    } = this;
    object.client_id = null;
    object.client = null;

    this.props.viewStore.timesheetSearch = {
      ...timesheetSearch,
      where: {
        ...timesheetSearch.where,
        client_id: null
      }
    }

    this.onLocationClear();
  }

  approveTimesheets() {
    const {
      timesheetStore: {
        approveTimesheets
      }
    } = this.props;
    approveTimesheets.bind(this)()
      .then((response) => {
        Toast.show({ icon: 'thumbs-up', message: 'Selected time entries were approved!', intent: Intent.SUCCESS })
      })
      .catch((error) => {
        let message = error.response.data.errors.join(' ');
        Toast.show({ icon: 'thumbs-down', message: message, intent: Intent.DANGER })
      })
  }

  rejectTimesheets() {
    const {
      timesheetStore: {
        rejectTimesheets
      }
    } = this.props;
    rejectTimesheets.bind(this)()
      .then((response) => {
        Toast.show({ icon: 'thumbs-up', message: 'Selected time entries were rejected!', intent: Intent.SUCCESS })
      })
      .catch((error) => {
        let message = error.response.data.errors.join(' ');
        Toast.show({ icon: 'thumbs-down', message: message, intent: Intent.DANGER })
      })
  }

  render() {
    const {
      jobStore: {
        persistJob: {
          object
        }
      },
      viewStore: {
        timesheetSearch: {
          where
        }
      },
      timesheetStore: {
        timelogs: {
          isLoading
        },
        timelogEntriesSelected
      }
    } = this.props;
    let startDate = moment.unix(where.start_time.gte).toDate();
    let endDate = moment.unix(parseInt(where.end_time.lte)).toDate();
    return (
      <nav className="bp3-navbar search bg-transparent navbar-overflow">
        <div>
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <Popover
                  minimal={true}
                  content={
                    <SearchDateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onChange={this.onDateRangeChange.bind(this)}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                  usePortal={true} >
                  <Button rightIcon='caret-down'>
                    {(!startDate || !endDate) && `Date Range`}
                    {startDate && endDate && `${startDate.toDateString()} - ${endDate.toDateString()}`}
                  </Button>
                </Popover>
                <ClientAutocomplete
                  selectedClientId={where && where.client_id}
                  onClientSelect={this.onClientSelect.bind(this)}
                />
                {
                  where && where.client_id &&                   
                    <ControlGroup fill={false} vertical={false}>
                      <LocationAutocomplete
                        scope={{ client_id: where.client_id } }
                        style={{ width: '400px' }}
                        inputProps={{ fill: true, placeholder: 'Start typing location..' }}
                        onLocationClear={this.onLocationClear.bind(this)}
                        value={object.location}
                        selectedLocationId={where && where.location_id}
                        onLocationSelect={(location) => { 
                          if(object && location){
                            object.location_id = location.id;
                            object.location = location
                            this.onLocationSelect(location)
                          }else{
                            this.onLocationClear();
                          }
                        }}
                      />
                    </ControlGroup>                    
                }
                <ProjectAutocomplete
                  selectedProjectId={where && where.project_id}
                  onProjectSelect={this.onProjectSelect.bind(this)}
                />
                
                <TechnicianPicker
                  includeAdmins={true}
                  selectedOptions={(where && where.technician_id) || []}
                  onChange={this.onTechnicianChange.bind(this)}
                />

                <ServiceTypePicker 
                  selectedOptions={(where && where.service_type_id) || []}
                  onChange={this.onServiceTypeChange.bind(this)}
                />
                
                <Popover
                  minimal={true}
                  content={
                    <ListPicker
                      selectedOptions={(where && where.status) || []}
                      options={options}
                      onChange={this.onTimesheetStatusChange.bind(this)}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                  usePortal={true} >
                  <Button rightIcon='caret-down'>
                    Status
                    <span>&nbsp;</span>
                    <Tag minimal={true} round={true}>{(where && where.status && where.status.length) || 0}</Tag>
                  </Button>
                </Popover>
                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
              </div>
            </div>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            {
              !isLoading && timelogEntriesSelected &&
                <React.Fragment>
                  <ButtonGroup>
                    <Button icon='tick' intent='success' onClick={this.approveTimesheets.bind(this)} className="btnapr">
                      Approve
                    </Button>
                    <Button icon='cross' intent='danger' onClick={this.rejectTimesheets.bind(this)} className="btnrjt">
                      Reject
                    </Button>
                  </ButtonGroup>
                  <Divider />
                </React.Fragment>
            }
            <div className="bp3-navbar-group bp3-align-right">
              <Popover 
                minimal={true}
                content={
                  <Menu>
                    <MenuItem text="Download Excel" onClick={this.download_excel.bind(this)}/>
                    {/* <MenuItem text="Download PDF" onClick={this.download_pdf.bind(this)}/> */}
                  </Menu>
                } 
                position={Position.BOTTOM_LEFT}
                usePortal={true}>
                <Button rightIcon='caret-down' >
                  Options
                </Button>
              </Popover>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Timesheet;
