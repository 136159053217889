import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Sticky from 'react-stickynode';
import styled from '@emotion/styled';
import { observable, action } from 'mobx';
import SearchBars from 'components/SearchBars';
import { observer, inject } from 'mobx-react';
import {
  Spinner, Button, Intent, Dialog, Classes, Tag
} from '@blueprintjs/core';
import JobsTable from 'components/Tables/Jobs';

import Toast from 'helpers/toaster';
import ProjectSidebar from '../projects/project-sidebar';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';

const options = [
  { label: 'Pending order', value: 'pending order', selected: false },
  { label: 'Ordered', value: 'ordered', selected: false },
  { label: 'In transit', value: 'in transit', selected: false },
  { label: 'Received', value: 'received', selected: false },
  { label: 'Picked up by the technician', value: 'picked up by the technician', selected: false },
  { label: 'Archived', value: 'archived', selected: false }
];

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`
const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit * 3}px`};
`
var materialLineItemIdArray = {
  materialLineItemIds: []
}

@inject('store', 'workReportStore', 'viewStore') @observer
class MaterialOrders extends Component {
  @observable isOpen = false;

  componentDidMount() {
    this.props.workReportStore.loadMaterialLineItems();
  }

  handleOverRide(event) {
    if (event.target.checked) {
      var checkboxValue = event.target.value;
      materialLineItemIdArray.materialLineItemIds.push(checkboxValue);
    } else {
      var index = materialLineItemIdArray.materialLineItemIds.indexOf(event.target.value);
      if (index !== -1) {
        materialLineItemIdArray.materialLineItemIds.splice(index, 1);
      }
    }
  }

  updateSelectedResourceForPurchaseOrder() {
    let {
      workReportStore,
      workReportStore: {
        workReport: {
          object
        },
        purchaseOrderItem
      }
    } = this.props;

    if (materialLineItemIdArray.materialLineItemIds.length > 0) {
      workReportStore.addPurchaseOrderItem(materialLineItemIdArray.materialLineItemIds)
      if (purchaseOrderItem.isError == true) {
        this.FailAddPurchaseOrder();
      } else {
        this.onSucessfullAddPurchaseOrder();
      }
    }
  }


  handleConfirm() {
    this.isOpen = false;
    this.updateSelectedResourceForPurchaseOrder();
  }

  updateSelectedResource() {
    let { objects, isLoading } = this.props.workReportStore.materialLineItem;
    let {
      workReportStore,
      workReportStore: {
        workReport: {
          object
        },
        purchaseOrderItem
      }
    } = this.props;

    var supplierData = 0;
    objects &&
      objects.map((materialItem) => {
        if (materialLineItemIdArray.materialLineItemIds.indexOf(materialItem.attributes.id) > -1) {
          if (supplierData == 0) {
            supplierData = materialItem.attributes.supplier.id;
          } else {
            if (supplierData != materialItem.attributes.supplier.id) {
              this.isOpen = true;
            }
          }
        }
      });

    if (this.isOpen == false) {
      this.updateSelectedResourceForPurchaseOrder();
    }

    if(materialLineItemIdArray.materialLineItemIds.length === 0) {
      Toast.show({ icon: 'warning-sign', message: 'Please select atleast one material item to create purchase order.', intent: Intent.DANGER })
    }
  }

  onSucessfullAddPurchaseOrder(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Purchase order added successfully!', intent: Intent.SUCCESS })
    this.props.workReportStore.loadMaterialLineItems();
  }

  FailAddPurchaseOrder(error) {
    Toast.show({ icon: 'warning-sign', message: 'Failed to add purchase order', intent: Intent.DANGER })
  }


  handleClose() {
    this.isOpen = false;
  }

  getStatusColorCode(status) {
    var colorCode = "text-capitalize";
    switch(status) {
      case 'pending order':
        return colorCode + " color-pending ";
      case 'ordered':
        return colorCode + " color-ordered";
      case 'in transit':
          return colorCode + " color-intransit";
      case 'received':
          return colorCode + " color-recieved";
      case 'picked up by the technician':
          return colorCode + " color-pubt";
      case 'archived':
          return colorCode + " color-archived";
      default:
        return colorCode + " color-default";
    }
  }

  render() {
    let { objects, isLoading } = this.props.workReportStore.materialLineItem;
    const {
      where
    } = this.props.viewStore.materialLineItemSearch;
    let { isOpen } = this.props;
    if (isLoading) {
      return <Spinner size='25' />
    }

    return (
      <div>
        <Helmet><title>Material Orders</title></Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2">
            <ProjectSidebar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
              <SearchBars.MaterialOrders />
                    <div className="bp3-navbar-group bp3-align-right" style={{marginTop: -55, zIndex: 8}}>
                      <div className="bp3-control-group">
                        {objects.length > 0 && <Button icon='plus' text='Create Purchase Order' className="crpo-btn" onClick={this.updateSelectedResource.bind(this)} /> }
                      </div>
                    </div>
                  
                  <div className="order-list-table">
                    <div className="table-responsive rounded-0">
                      {objects.length === 0 && <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
                        <h4 className="bp3-heading">No Results</h4>
                        Your search criteria returned no results. Please modify/expand your search parameters to see results.
                      </div>}
                      {objects.length > 0 && <table className="table table-vertical-align table-data-presentation">
                        <thead>
                          <tr>
                            <th>Select</th>
                            <th>Item Name</th>
                            <th>Supplier</th>
                            <th>Part Number</th>
                            <th className="text-center">Qty</th>
                            <th>Work Order</th>
                            <th>Status</th>
                            <th className="item-center">Purchase Orders</th>
                          </tr>
                        </thead>
                        <tbody>
                          {objects &&
                            objects.map((materialItem) => {
                              if (materialItem.attributes.work_report_id) {
                                return (
                                  <tr key={Math.random()}>
                                    <td>
                                      {materialItem.attributes.purchase_order.number

                                        ?
                                        <label className="bp3-control bp3-checkbox .modifier">
                                          <input
                                            type='checkbox'
                                            disabled='disabled'
                                            className="bp3-checkbox"
                                            value={materialItem.attributes.id}
                                            onChange={(event) => this.handleOverRide(event)}
                                          />
                                          <span className="bp3-control-indicator"></span>
                                        </label>
                                        :
                                        <label className="bp3-control bp3-checkbox .modifier">
                                          <input
                                            type='checkbox'
                                            className="bp3-checkbox"
                                            value={materialItem.attributes.id}
                                            onChange={(event) => this.handleOverRide(event)}
                                          />
                                          <span className="bp3-control-indicator"></span>
                                        </label>
                                      }
                                    </td>
                                    <td>
                                      <TableCellWithSubtext
                                        mainText={materialItem.attributes.description}
                                      />
                                    </td>
                                    <td>
                                      <TableCellWithSubtext
                                        mainLink={`/dashboard/suppliers/${materialItem.attributes.supplier.id}/show`}
                                        mainText={materialItem.attributes.supplier.name}
                                      />
                                    </td>
                                    <td>
                                      <TableCellWithSubtext
                                        subText={materialItem.attributes.part_number}
                                      />
                                    </td>
                                    <td align="center">
                                      <TableCellWithSubtext
                                        subText={materialItem.attributes.quantity}
                                      />
                                    </td>
                                    <td>
                                      <TableCellWithSubtext
                                        mainLink={`/dashboard/work-reports/${materialItem.attributes.work_report.id}/show`}
                                        mainText={materialItem.attributes.work_report.number}
                                      />
                                    </td>
                                    <td>
                                      <Tag interactive={false} className={this.getStatusColorCode(materialItem.attributes.status)}>
                                        {materialItem.attributes.status}
                                      </Tag>
                                    </td>
                                    <td className="item-center">
                                      {materialItem.attributes.purchase_order.number
                                          ?
                                        <TableCellWithSubtext
                                          mainLink={`/dashboard/purchase-orders/${materialItem.attributes.purchase_order.id}/show-purchase-order`}
                                          mainText={materialItem.attributes.purchase_order.number}
                                        />
                                      : ''}
                                      {/* {materialItem.attributes.purchase_order.number
                                        ?
                                        <a href={`/dashboard/purchase-orders/${materialItem.attributes.purchase_order.id}/show-purchase-order`}>
                                          {materialItem.attributes.purchase_order.number}
                                        </a>
                                        : ''} */}
                                    </td>
                                  </tr>
                                )
                              }
                            })
                          }
                        </tbody>
                      </table>}
                    </div>
                  </div> 
            </PageWrapGray>
          </div>
        </div>

        <Dialog
          title="Purchase Order"
          onClose={this.handleClose.bind(this)}
          isOpen={this.isOpen}
        >
          <div className={Classes.DIALOG_BODY}>
            You have selected multiple suppliers,this will genrate multiple purchase orders(1 per supplier).
              Are you sure you want to do this?
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.handleClose.bind(this)}>Cancel</Button>
              <Button intent={Intent.PRIMARY} onClick={this.handleConfirm.bind(this)}>Yes,I understand, please proceed</Button>
            </div>
          </div>

        </Dialog>
      </div>
    )
  }

};

export default MaterialOrders;