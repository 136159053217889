import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import TextTruncate from 'react-text-truncate';
import Pagination from 'components/Pagination';
import Footer from '../Footer';
import { 
  Button, Tag, Alert, Intent, Divider
} from '@blueprintjs/core';
import Toast from 'helpers/toaster';
import qs from 'qs';
import { formatTimeOnly, formatDateOnly } from 'helpers/time';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';
import { currencyFormattedAmount } from 'helpers/currency';

@inject('store', 'quoteStore', 'viewStore', 'clientStore', 'userStore') @observer
class QuotesTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable quote;

  decideAppearance = (status) => {
    switch (status) {
      case 'submitted':
        return 'success'
      default:
        break;
    }
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        quoteSearch
      }
    } = this.props;
    this.props.viewStore.quoteSearch = {
      ...quoteSearch,
      page: newPage
    };
    this.props.viewStore.quotes();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.quoteStore.deleteQuote(this.quote)
      .then(this.onSuccessfulDeleteQuote.bind(this))
      .catch(this.onFailedQuoteDelete.bind(this))
  }

  onSuccessfulDeleteQuote(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Quote deleted successfully!', intent: Intent.SUCCESS })
    this.quote = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedQuoteDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete quote!', intent: Intent.DANGER })
  }

  handleQuoteClick(event, quote) {
    switch (event.label) {
      case 'Edit':
        this.props.viewStore.ShowQuote({ uuid: quote.attributes.id })
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.quote = quote;
        break;
      default:
    }
  }

  render() {
    let {
      viewStore,
      viewStore: {
        jobTab: {
          section
        }
      },
      quoteStore: {
        quotes: {
          objects,
          meta
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
        <table className="table table-hover table-data-presentation" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Job #</th>
              <th>Estimate #</th>
              <th>Customer</th>
              <th>Created By</th>
              <th>Description</th>
              <th>PO#</th>
              <th>Grand Total</th>
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((quote) => {
                return (
                  <tr key={quote.id}>
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(quote.attributes.created_at)}
                        subText={formatTimeOnly(quote.attributes.created_at)}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/jobs/${quote.attributes.job.id}/${section}`}
                        mainText={quote.attributes.job.event_number}
                        subText={quote.attributes.job && quote.attributes.job.service_type_name}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/quotes/${quote.id}/show`}
                        mainText={quote.attributes.number}
                        subText={
                          <Tag intent={'warning'}>
                            {quote.attributes.status}
                          </Tag>
                        }
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/clients/${quote.attributes.client.id}?${qs.stringify(viewStore.clientShowSearch, { encode: false })}`}
                        mainText={quote.attributes.client.name}
                        subText={quote.attributes.location && quote.attributes.location.full_address}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/users/${quote.attributes.created_by.id}/show?${qs.stringify(viewStore.userShowSearch, { encode: false })}`}
                        mainText={quote.attributes.created_by.full_name}
                        subText={quote.attributes.created_by.email}
                      />
                    </td>
                    <td width='200'>
                      <div className="text-clamp" dangerouslySetInnerHTML={{ __html: quote.attributes.description }}></div>
                    </td>
                    <td>
                      <TableCellWithSubtext
                        subText={quote.attributes.po_number}
                      />
                    </td>
                    <td>
                      <Tag>
                        {currencyFormattedAmount(quote.attributes.grand_total)}
                      </Tag>
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.ShowQuote({uuid: quote.attributes.id})}>
                          <img src="/edit-light-icon.svg" alt={'edit icon'}/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleQuoteClick({ label: 'Delete' }, quote)}>
                          <img src="/delete-light-icon.svg" alt={'delete icon'}/>
                        </Button>
                      </div>
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            All associated data of this quote will also be deleted. Are you sure you want to do this?
          </p>
        </Alert>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </React.Fragment>
    )
  }
}

export default QuotesTable;