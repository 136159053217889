import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { 
  Spinner, NonIdealState,
  Button,
  ButtonGroup
} from '@blueprintjs/core';
import styled from '@emotion/styled';
import WorkReportShow from 'components/WorkReport/ShowPurchaseOrder';
import { TopBanner } from 'components/Styles';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'workReportStore') @observer
class WorkOrderShowHeader extends Component {

  @observable downloadingPdf = false;

  downloadPurchaseOrderPDF = () => {
    this.downloadingPdf = true;
    this.props.workReportStore.downloadPurchaseOrderPDF()
      .then((response) => {
        this.downloadingPdf = false;
      })
      .catch((error) => {
        this.downloadingPdf = false;
      })
  }

  render() {  
    let {
      workReportStore,
      workReportStore: {
        purchaseOrderItem: {
          object
        }
      }
    } = this.props;
    return (
      <TopBanner>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <h3>Purchase Order {object.attributes.purchase_order_number}</h3>
            
          </div>
          <div>
            <ButtonGroup> 
              <Button icon='eye-open' onClick={() => workReportStore.showPdfViewer = true }>
                View PDF
              </Button>
              <Button icon='download' onClick={this.downloadPurchaseOrderPDF} loading={this.downloadingPdf}>
                Download PDF
              </Button>
              {/* <Button icon='envelope'></Button>
              <Button icon='more'></Button> */}
            </ButtonGroup>
          </div>
        </div>
      </TopBanner>
    )
  }
}

@inject('store', 'workReportStore') @observer
class WorkReportShowPurchaseOrderPage extends Component { 

  get canRender() {
    let {
      workReportStore: {
        purchaseOrderItem: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object);
  }

  render() {
    let content, title;
    let {
      workReportStore: {
        purchaseOrderItem: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      title = 'Loading...'
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load Purchase Order'; 
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      title = `#${object.attributes.purchase_order_number} Purchase Order | Field Nexus`;
      content = <WorkReportShow />;
    }
    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        { this.canRender && <WorkOrderShowHeader /> }
        <PageWrapGray>
          {content}
        </PageWrapGray>
      </div>
    )
  }
};

export default WorkReportShowPurchaseOrderPage;