import { observable, action, toJS } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

class SupplierStore {  

  @observable suppliers = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable supplierAnalytics = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable supplier = {
    isLoading: false,
    isError: false,
    object: {
      name:'',
      email:'',
      phone:'',
      address:''
    }
  };

  @action
  loadSuppliers() {
    this.suppliers.isLoading = true;
    const {
      per_page,
      page,
      order,
      term
    } = stores.viewStore.supplierSearch;

    let promise = Api.post('/suppliers/search', {
      search: {
        term: term,
        per_page: per_page,
        page: page,
        order: { created_at: 'desc' }
      } 
    });
    promise
      .then((response) => {
        this.suppliers.isLoading = false;
        this.suppliers.isError = false;
        this.suppliers.objects = response.data.data;
        this.suppliers.meta = response.data.meta;
      })
      .catch((error) => {
        this.suppliers.isLoading = false;
        this.suppliers.isError = true;
      })
    
  }

  @action
  createSupplier() {
    this.supplier.isLoading = true;
    let promise = Api.post(`/suppliers`, { supplier: this.supplier.object })

    promise
      .then((response) => {
        this.supplier.isLoading = false;
        this.supplier.isError = false;
      })
      .catch((error) => {
        this.supplier.isLoading = false;
        this.supplier.isError = true;
      })
    
    return promise
  }

  @action
  updateSupplier(supplier) {
    this.supplier.isLoading = true;
    let promise = Api.put(`/suppliers/${supplier.attributes.id}`, {supplier: toJS(supplier.attributes)})

    promise
      .then((response) => {
        this.supplier.isLoading = false;
        this.supplier.isError = false;
        stores.viewStore.suppliers();
      })
      .catch((error) => {
        this.supplier.isLoading = false;
        // this.supplier.isError = true;
      })

    return promise;
  }


  @action
  loadAnalytics = (params) => {
    this.supplierAnalytics.isLoading = true;
    let promise = Api.get(`/suppliers/${params.uuid}/analytics`);

    promise
      .then((response) => {
        this.supplierAnalytics.isLoading = false;
        this.supplierAnalytics.isError = false;
        this.supplierAnalytics.objects = response.data;
      })
      .catch((error) => {
        this.suppliers.isLoading = false;
        this.suppliers.isError = true;
      })
    
    return promise;
  }

  @action
  loadSupplier(params) {
    this.supplier.isLoading = true;
    let promise = Api.get(`/suppliers/${params.uuid}`)
    
    promise
      .then((response) => {
        this.supplier.isLoading = false;
        this.supplier.isError = false;
        this.supplier.object = response.data.data;
        stores.viewStore.supplierShow(params,response.data.data.attributes.name);
      })
      .catch((error) => {
        this.supplier.isLoading = false;
        this.supplier.isError = true;
        console.error(error)
      })

    return promise;
  }

  
};

export default SupplierStore;