import { observable, action, toJS } from 'mobx';
import { Api, CurrentEnv } from 'services/Api';
import stores from './index';
import axios from 'axios';

const EMPTY_PRICING_PARAMETER_OBJECT = {
  name: null,
  pricing_type: 'flat',
  price: null,
  range_start: null,
  range_end: null,
  duration: null,
  service_type_id: null,
  service_types: [],
  pricing_parameter_services_attributes: [],
  pricing_parameter_ranges_attributes: [
    {
      label: '',
      min_range: '',
      max_range: '',
      duration: '',
      price:'',
      image_id:'',
      error: null
    }
  ]
}

class PricingParameterStore {

  @observable showUploadAttachmentLoader = false;

  @observable pricingParameter = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable pricingParameterDetail = {
    isLoading: false,
    isError: false,
    object: EMPTY_PRICING_PARAMETER_OBJECT
  };

  @observable pricingParameterDetailUpdate = {
    isLoading: false,
    isError: false,
    object: {
      attributes: {
        name: '',
        service_types: []
      }
    }
  };

  @action
  deletePricingParameter(pricingParameter) {
    this.pricingParameter.isLoading = true;
    let promise = Api.delete(`/pricing_parameters/${pricingParameter.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadPricingParameters();
      })
      .catch((error) => {
        this.pricingParameter.isLoading = false;
        this.pricingParameter.isError = true;
      })
    
    return promise
  }

  @action
  deletePricingParameterRange(pricingParameter) {
    let params = {
      uuid: pricingParameter.pricing_parameter_id,
      range_id: pricingParameter.id
    }
    this.pricingParameter.isLoading = true;
    let promise = Api.delete(`/pricing_parameters/destroy_range/`,{params})
  
    promise
      .then((response) => {
        this.loadPricingParameter(params);
      })
      .catch((error) => {
        this.pricingParameter.isLoading = false;
        this.pricingParameter.isError = true;
      })
    
    return promise
  }

  @action
  incrementRangeCount() {
    this.pricingParameterDetail.object.pricing_parameter_ranges_attributes.push({
      label:'',
      min_range: '',
      max_range: '',
      duration: '',
      price:''
    })
  }

  incrementRangeCountUpdate() {
    this.pricingParameterDetailUpdate.object.attributes.pricing_parameter_ranges.push({
      label:'',
      min_range: '',
      max_range: '',
      duration: '',
      price:''
    })
  }

  @action
  updatePricingParameter(object) {
    // let params = {
    //   uuid: object.attributes.id
    // }
    this.pricingParameterDetail.isLoading = true;
    let promise = Api.put(`/pricing_parameters/${object.attributes.id}`, {pricing: toJS(object.attributes)})

    promise
      .then((response) => {
        this.pricingParameterDetail.isLoading = false;
        this.pricingParameterDetail.isError = false;
        stores.viewStore.pricingParameterSearch.term = '*';
        stores.viewStore.pricingParameters();
        stores.pricingParameterStore.loadPricingParameters();  
      })
      .catch((error) => {
        this.pricingParameterDetail.isLoading = false;
        this.pricingParameterDetail.isError = true;
      })

    return promise;
  }

  @action
  createPricingParameter() {
    this.pricingParameterDetail.isLoading = true;
    let promise = Api.post(`/pricing_parameters/`, {pricing: this.pricingParameterDetail.object})   

    promise
      .then((response) => {
        this.pricingParameterDetail.isLoading = false;
        this.pricingParameterDetail.isError = false;       
        this.pricingParameterDetail.object = response.data.data;  
        stores.viewStore.pricingParameters();
        stores.pricingParameterStore.loadPricingParameters();    
      })
      .catch((error) => {
        this.pricingParameterDetail.isLoading = false;
        this.pricingParameterDetail.isError = true;       
      })

    return promise;
  }
  

  @action
  loadPricingParameters = () => {
    this.pricingParameter.isLoading = true;
    const {
      per_page,
      page,
      order,
      where,
      term
    } = stores.viewStore.pricingParameterSearch;
    let promise = Api.post('/pricing_parameters/search', { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.pricingParameter.isLoading = false;
        this.pricingParameter.isError = false;
        this.pricingParameter.objects = response.data.data;
        this.pricingParameter.meta = response.data.meta;
      })
      .catch((error) => {
        this.pricingParameter.isLoading = false;
        this.pricingParameter.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadPricingParameter = (params) => {
    this.pricingParameterDetailUpdate.isLoading = true;
    let promise = Api.get(`/pricing_parameters/${params.uuid}`)

    promise
      .then((response) => {
        this.pricingParameterDetailUpdate.isLoading = false;
        this.pricingParameterDetailUpdate.object = response.data.data;
        stores.viewStore.pricingParameterShow(params, response.data.data.attributes.name);
      })
      .catch((error) => {
        this.pricingParameterDetailUpdate.isLoading = false;
        this.pricingParameterDetailUpdate.isError = true;
        console.error(error)
      })

    return promise;
  }

  loadPricingParameterDetail = (params) => {
    this.pricingParameterDetailUpdate.isLoading = true;
    let promise = Api.get(`/pricing_parameters/${params.uuid}`)

    promise
      .then((response) => {
        this.pricingParameterDetailUpdate.isLoading = false;
        this.pricingParameterDetailUpdate.object = response.data.data;
      })
      .catch((error) => {
        this.pricingParameterDetailUpdate.isLoading = false;
        this.pricingParameterDetailUpdate.isError = true;
        console.error(error)
      })

    return promise;
  }


  // For Public link Actions starts

  loadPublicPricingParameterDetail = (params) => {
    this.pricingParameterDetailUpdate.isLoading = true;
    let promise = Api.get(`/public/pricing_parameters/${params.uuid}?x_tenant_id=${stores.bookingStore.tenant_id}`)

    promise
      .then((response) => {
        this.pricingParameterDetailUpdate.isLoading = false;
        this.pricingParameterDetailUpdate.object = response.data.data;
      })
      .catch((error) => {
        this.pricingParameterDetailUpdate.isLoading = false;
        this.pricingParameterDetailUpdate.isError = true;
        console.error(error)
      })

    return promise;
  }


  @action
  uploadAttachment(file, pricing_parameter_id = null) {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('pricing_parameter_id', pricing_parameter_id)
    this.showUploadAttachmentLoader = true;
    let promise = axios.post(`${CurrentEnv.domain()}/api/v2/images`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Api-Token': stores.viewStore.currentUser.attributes.api_token,
          'X-Tenant':stores.viewStore.currentUser.attributes.tenant_id,
          'X-Client-Identifier': 'React-Web'
        }
      }
    )

    promise
      .then((response) => {
        this.showUploadAttachmentLoader = false;
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = true;
      })

    return promise;

  }


  // For Public link Actions ends

};

export default PricingParameterStore;