import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Toast from 'helpers/toaster';
import {Intent } from '@blueprintjs/core';
import MaterialItem from './PurchaseMaterialItem';

const options = [
  { label: 'Pending order', value: 'pending order', selected: false },
  { label: 'Ordered', value: 'ordered', selected: false },
  { label: 'In transit', value: 'in transit', selected: false },
  { label: 'Received', value: 'received', selected: false },
  { label: 'Picked up by the technician', value: 'picked up by the technician', selected: false },
  { label: 'Archived', value: 'archived', selected: false }
];

@inject('store', 'workReportStore') @observer
class WorkReportPurchaseMaterialLineItems extends Component {

  @observable selectedStatus = '';
  @observable selectedMaterial = '';

  componentDidMount() {
    let {
      workReportStore: {
        purchaseOrderItem: {
          object
        }
      }
    } = this.props;
    if(object){
      this.selectedStatus = object.attributes.status;      
    }
  }

  updatePurchaseOrderItem(event,purchaseOrder) { 
    var materialLineItemIdArray = {
      materialLineItemIds: []
    }
    let {
      workReportStore: {
        purchaseOrderItem: {
          object
        }
      }
    } = this.props;
    this.selectedStatus = event.target.value;   
    this.props.workReportStore.updateMaterialLineItemsWithPurchaseOrderId(object.id,event.target.value);
    this.props.workReportStore.updatePurchaseOrderItems(purchaseOrder,event.target.value);
    if(object){
      this.onSuccessfullUpdateLineItem();      
    }else{
      this.onFailUpdateLineItem();      
    }    
  }

  onSuccessfullUpdateLineItem(response) {    
    Toast.show({ icon: 'thumbs-up', message: 'Updated successfully!', intent: Intent.SUCCESS })
  }

  onFailUpdateLineItem(error) {
    Toast.show({ icon: 'warning-sign', message: 'Unable to update!', intent: Intent.DANGER })
  }


  render() {
    const {
      workReportStore,
      workReportStore: {
        purchaseOrderItem: {
          object
        }
      }
    } = this.props;
    
    return (
      <React.Fragment>
        <div style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <div style={{ paddingLeft: '10px' }}>
            <h6>
              <strong>Materials </strong>
            </h6>
          </div>
        
          <div style={{ display: 'flex', flexDirection: 'column' }}>          
            <div className="bp3-select bp3-fill drop-down-material-status" >
              <select onChange={(event) => {this.updatePurchaseOrderItem(event,object.id)} } value={this.selectedStatus}>
                <option value='' disabled>Select Status</option>
                {
                  options.length > 0 && options.map((items) => {
                    return(
                      <option value={items.value} key={items.value}>{items.label}</option>
                    )
                  })
                }
              </select>
            </div>          
          </div>
        </div>

        <div className="table-responsive" style={{ marginBottom: '1rem' }}>
          <div style={{ padding: '0px 10px' }} >
            {
              object.attributes.material_line_items.length === 0 && <div className="bp3-callout bp3-intent-warning bp3-icon-info-sign table-card">
                No material line items have been added to this Work Order.
              </div>
            }
          </div>
          {
            object.attributes.material_line_items.length > 0 && <table className='table report-data-table table-hover' style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Description</th>                 
                  <th>Part Number</th>
                  <th>Qty</th>                  
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  object.attributes.material_line_items.map((material) => {
                    return (
                      <MaterialItem material={material} key={material.id} />
                    )
                  })
                }
              </tbody>
            </table>
          }
        </div>
       
        
      </React.Fragment>
    )
  }
}
export default WorkReportPurchaseMaterialLineItems;