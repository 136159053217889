import React from 'react'
import { Button, Popover, Position, Tag, Spinner } from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import ListPicker from 'components/ListPicker';
import PropTypes from 'prop-types';
import { Api } from 'services/Api';

@observer
class PricingParameterPicker extends React.Component {

  @observable loading = true;
  @observable failed = false;
  @observable options = [];
  @observable selectedOptions = [];

  componentDidMount() {
    if(this.props.selectedOptions) {
      this.selectedOptions = this.props.selectedOptions;
    }
  }

  @action
  onPricingParametersLoad(response) {
    this.options = response.data.data.map((pricingParameter) => 
      ({
        label: pricingParameter.attributes.name,
        value: pricingParameter.attributes.id,
        selected: this.selectedOptions.filter((selectedOption) => pricingParameter.attributes.id === selectedOption.value).length > 0 ? true : false
      })
    );
    this.failed = false;
    this.loading = false;
  }

  @action
  onPricingParametersLoadFail(error) {
    this.loading = false;
    this.failed = true;
  }

  loadPricingParameters() {
    let promise = Api.post('/pricing_parameters/search', { 
      search: { 
        term: '*',
      }
    });
    promise
      .then(this.onPricingParametersLoad.bind(this))
      .catch(this.onPricingParametersLoadFail.bind(this))
    return promise;
  }

  onChange = (selectedOptions) => {
    this.selectedOptions = selectedOptions;
    if(this.props.onChange){
      this.props.onChange(selectedOptions);
    }
  }

  render() {
    let {
      onChange,
      props: {
        customindex,
        maximumSelections
      }
    } = this;
    let content = '';
   
    // this.options.map(function(value,key){
    //   if(value.value == customindex) {
    //     value.selected = true;
    //   } else {
    //     value.selected = false;
    //   }
    // });
    
    if (this.failed) {
      content = (<div className="bp3-callout bp3-intent-danger bp3-icon-info-sign">
        Had some problem loading the Pricing Parameters. Please close and try again!
      </div>)
    }
    if (!this.failed) {
      if (this.loading) {
        content = <div><Spinner /></div>;
      }
      if (!this.loading && this.options.length > 0) {
        console.log(this.selectedOptions)
        content = <ListPicker
                    selectedOptions={this.selectedOptions}
                    options={this.options}
                    onChange={onChange}
                    maximumSelections={maximumSelections}
                  />
      }
    }
    return(
      <Popover
        minimal={true}
        onOpening={this.loadPricingParameters.bind(this)}
        content={content}
        position={Position.BOTTOM_LEFT}
        usePortal={true} 
      >
        <Button rightIcon='caret-down'>
          Pricing Parameter
          <span>&nbsp;</span>
          {
            this.selectedOptions.length === 1 &&
              <Tag minimal={true} round={true}>{this.selectedOptions[0].label}</Tag>
          }
          {
            (this.selectedOptions.length > 1 || this.selectedOptions.length === 0)  &&
              <Tag minimal={true} round={true}>{this.selectedOptions.length || 0}</Tag>
          }
          
        </Button>
      </Popover>
    )
  }
}

PricingParameterPicker.propTypes = {
  selectedOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func,
  maximumSelections: PropTypes.number
}

export default PricingParameterPicker;