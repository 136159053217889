import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { 
  Button, Intent
} from "@blueprintjs/core";
import WorkReportImages from 'components/WorkReport/Images';
import WorkFormSubmissions from 'components/WorkReport/FormSubmissions';
import WorkReportHeader from 'components/WorkReport/Header';
import WorkReportCustomerDetails from 'components/WorkReport/CustomerDetails';
import WorkReportDescription from 'components/WorkReport/Description';
import WorkReportLabourLineItems from 'components/WorkReport/LabourLineItems';
import WorkReportMaterialLineItems from 'components/WorkReport/MaterialLineItems';
import WorkReportCustomLineItems from 'components/WorkReport/CustomLineItems';
import WorkReportEquipments from 'components/WorkReport/Equipments';
import WorkReportPDFViewer from 'components/WorkReport/PDFViewer';
import WorkReportSignatureSection from 'components/WorkReport/SignatureSection';
import {
  ColumnContainer,
  Column,
  Card
} from 'components/Styles';
import Toast from 'helpers/toaster';
import _ from 'lodash';
import { currencyFormattedAmount } from 'helpers/currency';
import { observable } from 'mobx';
let job_detals = [];

@inject('store', 'workReportStore') @observer
class WorkReportShow extends Component {
  
  calculateGrandTotal() {    

    const {
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
    let total = 0.0;

    if(object.attributes.labour.length > 0) {       
      total = total + _.sumBy(object.attributes.labour, 'total')           
    } 
    if(object.attributes.materials.length > 0) {
      total = total + _.sumBy(object.attributes.materials, 'total')
    } 
    if(object.attributes.custom_line_items.length > 0) {
      total = total + _.sumBy(object.attributes.custom_line_items, 'total')
    } 
    return currencyFormattedAmount(total)
  }

  updateWorkReport() {
    this.props.workReportStore.update()
      .then(this.onUpdateSuccessfully.bind(this))
      .catch(this.onFailUpdate.bind(this))

  }

  onUpdateSuccessfully(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Updated successfully!', intent: Intent.SUCCESS })
  }

  onFailUpdate(error) {
    Toast.show({ icon: 'warning-sign', message: 'Unable to update!', intent: Intent.DANGER })
  }

  render() {
    const {
      workReportStore: {
        workReport: {
          object,
          isLoading
        }
      }
    } = this.props;

    return (
      <div className="pdf-view-layout">
        <ColumnContainer>
          <Column>
            <Card>
              {/* Header: Logo + Tenant Details */}
              <WorkReportHeader />

              {/* Customer Details */}
              <WorkReportCustomerDetails selectedTechnician={[{id:object.attributes.created_by.id,label:object.attributes.created_by.full_name,value:object.attributes.created_by.id,selected: true}]} selectedJob={[{id: object.attributes.job.id, label: object.attributes.job.number, value: object.attributes.job.id,selected: true}]}/>

              {/* Reason For Call/Description */}
              <WorkReportDescription />

              {/* Work Report Images */}
              <WorkReportImages />

              {/* Work Report Images */}
              <WorkFormSubmissions />

              {/* Equipments */}
              <WorkReportEquipments />

              {/* Labour Line Items */}
              <WorkReportLabourLineItems  />

              <div style={{marginTop: 40}}></div>

              {/* Material Line Items */}
              <WorkReportMaterialLineItems />

              <div style={{marginTop: 40}}></div>

              {/* Custom Line Items */}

              <WorkReportCustomLineItems />


              <div style={{marginTop: 45}}></div>

              {/* Signatures */}
              <WorkReportSignatureSection />

              {/* Show Work Report PDF Dialog */} 
              <WorkReportPDFViewer />

              <div style={{marginTop: 0, clear: 'both'}}></div>
              <br/>
            </Card>

            <div className="invoice-save">
              <div className="lineitem_grand_total" style={{display: 'inline-block'}}>
                <h5 style={{marginBottom: 10}}>Grand Total: {this.calculateGrandTotal()}</h5>
              </div>
              <Button 
                large={true}                
                loading={isLoading} 
                onClick={this.updateWorkReport.bind(this)}
                icon='floppy-disk'
              >
                Save
              </Button>
            </div>
          </Column>
        </ColumnContainer>
      </div>
    )
  }
}

export default WorkReportShow;