import moment from 'moment-timezone';
import Lockr from 'lockr';

const formatTime = (date) => {
  if(date && currentTimezone()){
    return currentTime(date).format('MMM D, YYYY hh:mmA z');
  }else{
    return currentTime().format('MMM D, YYYY hh:mmA z');
  }
}

const formatDateOnly = (date) => {
  if (date && currentTimezone()) {
    return currentTime(date).format('MMM Do, YYYY');
  } else {
    return currentTime().format('MMM Do, YYYY');
  }
}

const formatTimeOnly = (date) => {
  if (date && currentTimezone()) {
    return currentTime(date).format('hh:mmA');
  } else {
    return currentTime().format('hh:mmA');
  }
}

const formatTimeOnlyWithZone = (date) => {
  if (date && currentTimezone()) {
    return currentTime(date).format('hh:mmA z');
  } else {
    return currentTime().format('hh:mmA z');
  }
}

const currentTime = (time) => {
  if(time && currentTimezone()){
    return moment(time).tz(currentTimezone());
  }else{
    return moment().tz(currentTimezone());
  }
}

const dateObjectInCurrentTimezone = (moment_time) => {
  return moment_time.toDate();
}

const currentTimezone = () => {
  const currentUser = Lockr.get('currentUser');
  if(currentUser && currentUser.attributes.timezone){
    return currentUser.attributes.timezone
  }else{
    return systemTimezone();
  }
}

const systemTimezone = () => {
  return moment.tz.guess();
}

const systemTimezoneAbbreviated = () => {
  return new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]
}

const calculateDifferenceInDays = (date1, date2) => {
  if(date1 && date2 && currentTimezone()){
    return Math.abs(currentTime(date1).diff(currentTime(date2), 'days'));
  }else{
    return 0;
  }
}

const getDaysDifferenceFrom = (date1, date2) => {
  const diff = calculateDifferenceInDays(date1, date2);
  console.log('Diff: ', diff);
  if (diff > 0) {
    return ` +${diff}d`;
  } else {
    return '';
  }
}

export {
  formatTime,
  currentTimezone,
  currentTime,
  dateObjectInCurrentTimezone,
  systemTimezone,
  systemTimezoneAbbreviated,
  formatTimeOnly,
  formatTimeOnlyWithZone,
  formatDateOnly,
  calculateDifferenceInDays,
  getDaysDifferenceFrom
};