import React, { Component } from 'react';
import styled from '@emotion/styled';

const StyledParent = styled('div')`
  margin-left: ${props => `${props.theme.unit*1}px`};
  margin-right: ${props => `${props.theme.unit*1}px`};
`

class SearchBarWrapper extends Component {
  render() {
    return (
      <StyledParent>
        {this.props.children}
      </StyledParent>
    )
  }
};

export default SearchBarWrapper;