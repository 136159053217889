import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SearchBars from 'components/SearchBars';
import SearchBarWrapper from 'components/SearchBarWrapper';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Sticky from 'react-stickynode';
import _ from 'lodash';
import { redCar, infoPopup } from './map_components';
import UserSidebar from './user-sidebar';

@inject('store', 'dashboard', 'viewStore', 'userStore', 'geolocationStore') @observer
class UsersMapShowPage extends Component {

  @observable selectedTabId = 'all';
  @observable isOpenInviteUserDialog = false;
  @observable isOpenResponseDialog = false;
  map = null;
  bounds = null;

  componentDidMount(){
    let {
      props: {
        dashboard: {
          users: {
            objects
          }
        }
      }
    } = this;
    let google = window.google;
    
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 4,
      center: {lat: 0, lng: 0}
    });
    this.bounds = new google.maps.LatLngBounds();
    this.loadData();
  }

  loadData() {
    this.props.geolocationStore.loadLocations();
  }

  render() {
    let google = window.google;
    const user = this.props.userStore.user.object;
    const objects = this.props.geolocationStore.objects;
    let driveCoordinates = [];
    if(user && user.attributes && user.attributes.latitude && user.attributes.longitude){
      const userLocation = {
        lat: user.attributes.latitude, 
        lng: user.attributes.longitude
      };
      var infowindow = new google.maps.InfoWindow({
        content: infoPopup(user)
      });
      const marker = new window.MarkerWithLabel({
        position: userLocation,
        rotation: user.attributes.heading,
        labelContent: user.attributes.full_name,
        labelAnchor: new google.maps.Point(25, 8),
        labelClass: "mapLabels",
        labelStyle: {
          opacity: 0.8,
          color: "white",
          background: '#2D4EF5',
          padding: '2px',
          borderRadius: "10px",
          textAlign: 'center',
          maxWidth: "50px"
        },
        map: this.map,
        title: `${user.attributes.full_name} - location last received ${user.attributes.location_last_updated_time_ago}`,
        icon: {
          url: redCar(user),
          rotation: user.attributes.heading,
          scale: 0.5
        }
      });
      marker.addListener('mouseover', function() {
        infowindow.open(this.map, marker);
      });

      marker.addListener('mouseout', function() {
        infowindow.close();
      });
      window.markers.push(marker);

      if(this.bounds){
        this.bounds.extend(userLocation);
      }
      _.each(objects, (geolocation) => {
        if(geolocation.attributes.coords.latitude && geolocation.attributes.coords.longitude){
          const location = {
            lat: geolocation.attributes.coords.latitude, 
            lng: geolocation.attributes.coords.longitude
          };
          const marker = new google.maps.Marker({
            position: location,
            map: this.map,
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 3,
              rotation: geolocation.attributes.coords.heading
            },
          });
          if(this.bounds){
            this.bounds.extend(location);
          }
          window.markers.push(marker);
          driveCoordinates.push(location);
        }
      })
      const drivePath = new google.maps.Polyline({
        path: driveCoordinates,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: this.map
      });
      window.markers.push(drivePath);
      if(this.bounds && this.map){
        this.map.fitBounds(this.bounds);
      }
    }
    if(this.bounds && this.map){
      this.map.fitBounds(this.bounds);
    }
    return (
      <div>
        <Helmet>
          <title>Users Map Show | FieldNexus</title>
        </Helmet>
        <Sticky enabled={true} top={0} innerZ={9}>
          <SearchBarWrapper>
            <SearchBars.Map />
          </SearchBarWrapper>
        </Sticky>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <UserSidebar />
          </div>
          <div className="col-lg-10">
            <div style={{ 'width': "calc(100vw - 235px)", height: "calc(100vh - 75px)", float: 'left', 'marginLeft':'0px', position:'relative', overflow:'hidden' }} id="map"></div>
          </div>
        </div>
        
        
      </div>
    )
  }
};

export default UsersMapShowPage;