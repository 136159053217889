import { withOrientationChange, isMobileOnly, isMobile } from 'react-device-detect';

const styles = {
  sidebar: {
    position: isMobile ? 'initial' : 'relative',
    right: '-15px',
    top: '0px',
    marginTop: isMobile ? '50px' : '0px',
    background: isMobile ? '#fff' : '#fbfcfe',
    height: isMobile ? '' : 'calc(100vh - 80px)',
    padding: isMobile ? '5px' : '15px 30px',
    borderLeft: isMobile ? '' : '1px solid #F0F0F2',    
    minWidth: isMobile ? '100%' : '0px',
    overflow: 'auto'
  },
  sidebarAttributes: {
    listStyle: 'none',
    padding: 0
  },
  label: {
    fontWeight: 'bold',
    color: '#999',
  },
  sidebarList: {
    borderBottom: '1px solid #F0F0F2',
    marginBottom: '1rem'
  },
  sidebarButton:{
    width: '35px',
    height: '30px',
    background: '#fff'
  }
}

export default styles;