import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { 
  Button,FormGroup,Checkbox, Radio,
  Intent, Spinner, RadioGroup
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import DateTimeInput from 'components/DateTimeInput';
import moment from 'moment';
import GeoComplete from '../../helpers/GeoComplete';
import { Select } from "@blueprintjs/select";
import { currencySymbol, currencyFormattedAmount } from 'helpers/currency';
import QuestionRadio from 'components/Booking/QuestionRadio';
import QuestionSelect from 'components/Booking/QuestionSelect';
import QuestionTextbox from 'components/Booking/QuestionTextbox';

var startCount = 0;
var startCountRange = 0
var selectedAnswer = '';
var princingRangeCombineArray = [];

const items=[ 
  { label: 'Cash', value: 'Cash' }, 
  { label: 'Interac', value: 'Interac' },
  { label: 'Cheque', value: 'Cheque' },
  { label: 'Debit/Credit', value: 'dr/cr' }
];



const PageWrapGray = styled('div')`
  min-height: calc(100vh - 75px);
  width: 100%;
`
@inject('store','viewStore','bookingStore','preferenceStore','serviceTypeStore','pricingParameterStore','frequencyStore','questionGroupStore','serviceAgreementStore','jobStore','serviceTypeCategoryStore') @observer
class NewBooking extends Component {
  @observable pricingParameterArray = [];

  constructor(props) {
    super(props);
    this.state = {
      isServiceTypeChecked: 0,
      isPaymentSelect:0,
      isFrequencySelected: null
    };
  }

  @observable startCountRange = 0;
  @observable extra_service_total = 0;
  @observable pricing_parameter_total = 0;
  @observable pricing_range_total = 0;
  @observable isServiceExtraChecked = 0;
  @observable grand_total = 0.0;
  @observable showAsIconOrText = false;

  componentDidMount() {
    let {
      serviceTypeStore,
      questionGroupStore,
      bookingStore,
      serviceTypeCategoryStore,
      bookingStore:{
        booking:{
          object
        }
      }
    } = this.props;
    serviceTypeCategoryStore.loadServiceTypeCategories();
    serviceTypeStore.loadServiceTypesBooking();
    questionGroupStore.loadQuestionGroups();
  }

  onAddressSelect(suggestion) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    if(suggestion) {
      object.attributes.address = suggestion.label;
      suggestion.gmaps && suggestion.gmaps.address_components && suggestion.gmaps.address_components.length > 0 && suggestion.gmaps.address_components.map((address_component) => {
        if(_.includes(address_component.types, "locality")) {
          object.attributes.city = address_component.long_name;
        }

        if(_.includes(address_component.types, "administrative_area_level_1")) {
          object.attributes.state = address_component.long_name;
        }
        
        if(_.includes(address_component.types, "postal_code")) {
          object.attributes.zip = address_component.long_name;
        }
      })
    } else {
      object.attributes.address = '';
      object.attributes.zip = "";
      object.attributes.state = "";
      object.attributes.city = "";
    }
  }

  onAddressClear(value) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    if(!value) {
      object.attributes.address = '';
    }
  }

  onExtraServiceSelect(event,selectedOptions,key) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
   var removeIndex = object.attributes.extra_service_types.map(function(item) { return item.name; }).indexOf(selectedOptions.name);
    if(event.target.checked === true){
      object.attributes.extra_service_types.push({service_type_extra_id:selectedOptions.id,name:selectedOptions.name,price:parseFloat(selectedOptions.price_cents/100)});
    }else{
      object.attributes.extra_service_types.splice(removeIndex, 1);
    }
    this.isServiceExtraChecked = key;
    this.calculateGrandtotal()
  }

  onFrequencySelect(event,selectedOptions,key) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    object.attributes.frequency_id = selectedOptions.id;
    this.setState({
      isFrequencySelected: key,
    });
  }

  onPricingRangeSelectImage(event,selectedOptions,key) { 
    let {
      props: {
        bookingStore: {
          booking: {
            object
          }
        }
      }
    } = this;

    if(event.target.checked){
      let index = object.attributes.pricing_parameter_ranges.map(function(item) { return item.pricing_parameter_id; }).indexOf(selectedOptions.pricing_parameter_id);
      if(index > -1){
        object.attributes.pricing_parameter_ranges[index].pricing_parameter_id = selectedOptions.pricing_parameter_id;
        object.attributes.pricing_parameter_ranges[index].name = selectedOptions.label;
        object.attributes.pricing_parameter_ranges[index].price = selectedOptions.price;
        object.attributes.pricing_parameter_ranges[index].total_amount =  selectedOptions.price;
      }else{
        object.attributes.pricing_parameter_ranges.push({pricing_parameter_id:selectedOptions.pricing_parameter_id,name:selectedOptions.label,price:selectedOptions.price,total_amount:selectedOptions.price});
      }
      this.calculateGrandtotal()
    }
  }

  onPricingRangeSelect(event,selectedOptions) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    // if(event.target.value != ''){
    //   object.attributes.pricing_parameter_ranges = [];
    //   object.attributes.pricing_parameter_ranges.push({pricing_parameter_id:selectedOptions.id,name:event.target.options[event.target.selectedIndex].text,price:parseFloat(event.target.value)});
    // } else {
    //   object.attributes.pricing_parameter_ranges = [];
    // }
    // this.calculateGrandtotal()

    if(event.target.value != ''){
      let index = object.attributes.pricing_parameter_ranges.map(function(item) { return item.pricing_parameter_id; }).indexOf(selectedOptions.id);
      if(index > -1){
        object.attributes.pricing_parameter_ranges[index].pricing_parameter_id = selectedOptions.id;
        object.attributes.pricing_parameter_ranges[index].name = event.target.options[event.target.selectedIndex].text;
        object.attributes.pricing_parameter_ranges[index].price = parseFloat(event.target.value);
        object.attributes.pricing_parameter_ranges[index].total_amount = parseFloat(event.target.selectedIndex) * parseFloat(event.target.value);

      }else{
        object.attributes.pricing_parameter_ranges.push({pricing_parameter_id:selectedOptions.id,name:event.target.options[event.target.selectedIndex].text,price:parseFloat(event.target.value),total_amount:parseFloat(event.target.value)});
      
      }
      this.calculateGrandtotal()
    }
  }

  onPricingSelect(event,selectedOptions) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;

    if(event.target.value != ''){
      object.attributes.pricing_parameters = [];
      object.attributes.pricing_parameters.push({pricing_parameter_id:selectedOptions.id,name:event.target.options[event.target.selectedIndex].text,price:parseFloat((selectedOptions.price_cents/100))});
      // NOTE: here price came from backend in cents
      object.attributes.pricing_parameters[0].quantity = event.target.selectedIndex;
      object.attributes.pricing_parameters[0].total_amount = parseFloat(event.target.selectedIndex) * parseFloat((selectedOptions.price_cents/100));
    } else {
      object.attributes.pricing_parameters = [];
    }
    this.calculateGrandtotal()
  }

  pricingList = (pricing) => {
    let pricingToReturn = [];
    this.pricingParameterArray = [];
    for (let i = pricing.range_start; i <= pricing.range_end; i++) {
      pricingToReturn.push(<option id={pricing.id} key={i}>{pricing.name}{` ${i}`}</option>);
    }
    return pricingToReturn;
  };

  pricingRangeList = (pricing) => {
    let {
      props: {
        pricingParameterStore,
        pricingParameterStore: {
          pricingParameterDetailUpdate: { 
            object
           }
        }
      }
    } = this;
    let { 
      serviceTypeStore: {
        serviceTypeDetail
      }
    } = this.props;
    let pricingRangeToReturn = [];

    if(pricing.id){
      let index = pricing.pricing_parameter_ranges.map(function(item) { return item.pricing_parameter_id; }).indexOf(pricing.id);
      if(index > -1){
        pricing.pricing_parameter_ranges.map((range) => {
          pricingRangeToReturn.push(<option innerText={range.label} value={range.price}>{range.label}</option>);
        });
      }
    }
    return pricingRangeToReturn;
  };

  handleStartDateChange(date) {
    let { object } = this.props.bookingStore.booking;
    if(date) {
      object.attributes.start_date = moment(date);
    }
  }

  onPaymentSelect = (event,payment,key) => {
    let { object } = this.props.bookingStore.booking;
    this.setState({
      isPaymentSelect: key,
    });
    object.attributes.payment_method = payment.value;
  }

  onContactWayChange = (event) =>{
    let {
      bookingStore:{
        booking:{
          object: {
            attributes
          }
        }
      }
    } = this.props;
    attributes.start_project_expected_period = event.target.value;
  }

  onTimePeriodChange = (event) =>{
    let {
      bookingStore:{
        booking:{
          object: {
            attributes
          }
        }
      }
    } = this.props;
    attributes.contact_way = event.target.value;
  }

  onServiceTypeSelect = (event,service,key) => {
    let { object } = this.props.bookingStore.booking;
    let { serviceTypeStore, pricingParameterStore} = this.props;
    let { 
      serviceTypeStore: {
        serviceTypeDetail
      }
    } = this.props;
    if(service.attributes.id !== "") {
      this.setState({
        isServiceTypeChecked: service.attributes.id
      });
      princingRangeCombineArray = [];
      object.attributes.extra_service_types = [];
      object.attributes.pricing_parameters = [];
      object.attributes.pricing_parameter_ranges = [];
      object.attributes.service_type_id = service.attributes.id;
      serviceTypeStore.loadServiceTypeDetail({uuid:service.attributes.id})
        .then((response) => {
          serviceTypeDetail.object.attributes && serviceTypeDetail.object.attributes.pricing_parameters.length > 0 && serviceTypeDetail.object.attributes.pricing_parameters.map((pricing_attr) => {
            // pricingParameterStore.loadPricingParameterDetail({uuid:pricing_attr.id})
            // .then(response => {
            //   princingRangeCombineArray.push(response.data.data)
            // })
          });
          this.calculateGrandtotal()
        })
    }else{
      this.props.serviceTypeStore.clearServiceTypeDetail()
      this.calculateGrandtotal()
    }
  }

  onServiceTypeCategoryChange = (event) => {
    let { object } = this.props.bookingStore.booking;
    this.props.viewStore.serviceTypeSearch.where.service_type_category_id = event.target.value;
    this.props.serviceTypeStore.loadServiceTypes() 
    object.attributes.service_type_category_id = event.target.value;
  }

  onServiceTypeChange = (event) => {
    let { object } = this.props.bookingStore.booking;
    let { serviceTypeStore, pricingParameterStore} = this.props;
    let { 
      serviceTypeStore: {
        serviceTypeDetail
      }
    } = this.props;
    if(event.target.value !== "") {
      this.setState({
        isServiceTypeChecked: event.target.value
      });
      princingRangeCombineArray = [];
      object.attributes.extra_service_types = [];
      object.attributes.pricing_parameters = [];
      object.attributes.pricing_parameter_ranges = [];
      object.attributes.service_type_id = event.target.value;
      serviceTypeStore.loadServiceTypeDetail({uuid:event.target.value})
        .then((response) => {
          serviceTypeDetail.object.attributes && serviceTypeDetail.object.attributes.pricing_parameters.length > 0 && serviceTypeDetail.object.attributes.pricing_parameters.map((pricing_attr) => {
          });
          this.calculateGrandtotal()
        })
    } else {
      this.props.serviceTypeStore.clearServiceTypeDetail()
      this.calculateGrandtotal()
    }
  }

  calculateGrandtotal() {
    let { 
      bookingStore: {
        booking: {
          object
        }
      },
      serviceTypeStore: {
        serviceTypeDetail
      }
    } = this.props;

    this.grand_total = 0.0;

    // Adding Service type price in grand total
    if(serviceTypeDetail && serviceTypeDetail.object && serviceTypeDetail.object.attributes) {
      this.grand_total += serviceTypeDetail.object.attributes.rate;
    }

    // Adding pricing parameters flat to grand total
    if(object && object.attributes.pricing_parameters && object.attributes.pricing_parameters.length > 0) {
      this.grand_total += _.sumBy(object.attributes.pricing_parameters, 'total_amount')
    }

    // Adding pricing parameters range to grand total
    if(object && object.attributes.pricing_parameter_ranges && object.attributes.pricing_parameter_ranges.length > 0) {
      this.grand_total += _.sumBy(object.attributes.pricing_parameter_ranges, 'price')
    }

    // Adding extra service types to grand total
    if(object && object.attributes.extra_service_types && object.attributes.extra_service_types.length > 0) {
      this.grand_total += _.sumBy(object.extra_service_types, 'price')
    }
  }

  createBooking(status) {
    let {
      props: {
        store,
        frequencyStore:{
          frequencies
        },
        serviceTypeStore: {
          serviceType:{
            objects,
          }
        },
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
   
    if(object.attributes.service_type_category_id === "" || object.attributes.service_type_category_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please select service type category!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.first_name === "" || object.attributes.first_name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid first name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.last_name === "" || object.attributes.last_name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid last name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.email === "" || object.attributes.email === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid email!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.phone === "" || object.attributes.phone === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid phone!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.address === "" || object.attributes.address === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid address!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.city === "" || object.attributes.city === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid city name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.state === "" || object.attributes.state === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid state name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.zip === "" || object.attributes.zip === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid zip code!', intent: Intent.DANGER })
      return;
    }
    
    if(object.attributes.service_type_id === "" || object.attributes.service_type_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please select service type!', intent: Intent.DANGER })
      return;
    }
    
   

    if(object.attributes.payment_method === "" || object.attributes.payment_method === null) {
      object.attributes.payment_method = 'Cash';
    }

    object.attributes.total = this.grand_total;
    object.attributes.booking_pricing_parameters_attributes = object.attributes.pricing_parameters.concat(object.attributes.pricing_parameter_ranges);
    object.attributes.booking_service_type_extras_attributes = object.attributes.extra_service_types;
    object.attributes.status = status;
    object.attributes.booking_questions_attributes = object.attributes.booking_questions;

    this.props.bookingStore
      .createBooking()
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Booking created successfully!",
          intent: Intent.SUCCESS
        });
        
        if(response.data.data.attributes.status === 'confirmed'){
          if(response.data.data.attributes.frequency.title === 'One Time'){
            let { object } = this.props.jobStore.persistJob;
            object.reason_for_call = response.data.data.attributes.service_type.name;
            object.start_date = moment(response.data.data.attributes.start_date);
            object.end_date = moment(response.data.data.attributes.start_date);
            object.service_type_id = response.data.data.attributes.service_type_id;
            object.selectedServiceType = [{value: response.data.data.attributes.service_type_id,selected: true,label: response.data.data.attributes.service_type.name}];
            store.isOpen = true;
            object.booking_id = response.data.data.attributes.id;
          }else{
            let {object } = this.props.serviceAgreementStore.persistServiceAgreement;
            let frequencyType = response.data.data.attributes.frequency.title;
            let frequencyTypeString = response.data.data.attributes.frequency.title.toUpperCase();
            let startDate = moment(response.data.data.attributes.start_date).format('YYYYMMDDTHHmmssz');
            object.reason_for_call = response.data.data.attributes.service_type.name;
            object.selectedServiceType = [{value: response.data.data.attributes.service_type_id,selected: true,label: response.data.data.attributes.service_type.name}];
            object.service_type_id = response.data.data.attributes.service_type_id;
            var string = '';
            if(frequencyType === 'Weekly'){
              string = `DTSTART:${startDate} RRULE:FREQ=${frequencyTypeString};INTERVAL=1;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Bi-weekly'){
              string = `DTSTART:${startDate} RRULE:FREQ=${'WEEKLY'};INTERVAL=2;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Monthly'){
              string = `DTSTART:${startDate} RRULE:FREQ=${frequencyTypeString};INTERVAL=1;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Quarterly'){
              string = `DTSTART:${startDate} RRULE:FREQ=${'MONTHLY'};INTERVAL=3;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Semi-annually'){
              string = `DTSTART:${startDate} RRULE:FREQ=${frequencyTypeString};INTERVAL=6;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Annually'){
              string = `DTSTART:${startDate} RRULE:FREQ=${'YEARLY'};INTERVAL=1;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Yearly'){
              string = `DTSTART:${startDate} RRULE:FREQ=${'YEARLY'};INTERVAL=1;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Every other year'){
              string = `DTSTART:${startDate} RRULE:FREQ=${frequencyTypeString};INTERVAL=1;BYMONTHDAY=1`;
            }
            object.booking_id = response.data.data.attributes.id;
            object.rrule = string;
            this.props.jobStore.isServiceAgreement = true;
            this.props.store.isOpen = true;
          }
        }


      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to create Booking!",
          intent: Intent.DANGER
        });
      });

  }

  onQuestionChange = (event,question) => {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    let index = object.attributes.booking_questions_attributes.map(function(item) { return item.question_id; }).indexOf(question.id);
    if(index > -1){
      object.attributes.booking_questions_attributes[index].answer = event.target.value;
    }else{
      object.attributes.booking_questions_attributes.push({answer:event.target.value,question_id:question.id});
    }
  }

  onChangeFile(event) {
    let {
      bookingStore:{
        booking:{
          object: { attributes }
        }
      }
    } = this.props;
    var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 10) {
      Toast.show({ icon: 'warning-sign', message: 'File size exceeds 10 MB!', intent: Intent.DANGER })
      return
    }
    this.props.bookingStore.uploadBookingAttachment(event.target.files[0])
      .then(this.onSuccessfullUploadAttachment.bind(this))
      .catch(this.onFailUploadAttachment.bind(this))
  }

  onSuccessfullUploadAttachment(response) {
    let {
      bookingStore:{
        booking:{
          object
        }
      }
    } = this.props;
    object.attributes.booking_attachment_ids.push(response.data.data.attributes.id);
    object.attributes.booking_attachments.push(response.data.data.attributes);
  }

  onFailUploadAttachment(error) {
    this.props.bookingStore.showUploadAttachmentLoader = false;
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong, failed to upload file!', intent: Intent.DANGER })
    }
  }

  removeAttachment(attachment) {
    let {
      bookingStore:{
        booking:{
          object
        }
      }
    } = this.props;
    var removeIndex = object.attributes.booking_attachments.map(function(item) { return item.id; }).indexOf(attachment.id);
    if(removeIndex > -1){
      object.attributes.booking_attachments.splice(removeIndex, 1);
      object.attributes.booking_attachment_ids.splice(removeIndex, 1);
    }
  }

  render() {
    let {
      serviceTypeStore: {
        serviceType:{
          objects
        },
        serviceTypeDetail,
      },
      preferenceStore:{
        tenant,
        tenant: {
          object
        }
      },
      bookingStore:{
        showUploadAttachmentLoader,
        booking:{
          object:{
            attributes
          },
          isLoading
        }
      },
      questionGroupStore:{
        groups
      },
      serviceTypeCategoryStore:{
        serviceTypeCategories
      }
    } = this.props;

    let service_type_extra = [];
    let pricing_parameters = [];
    let frequency = [];
    let questions = [];

    if(serviceTypeDetail && serviceTypeDetail.object && serviceTypeDetail.object.attributes) {
      service_type_extra = serviceTypeDetail.object.attributes.service_type_extra
      pricing_parameters = serviceTypeDetail.object.attributes.pricing_parameters
      frequency = serviceTypeDetail.object.attributes.frequency
      questions = serviceTypeDetail.object.attributes.questions
    }
    
    return (
      <div className="company-page-layput">
        <Helmet>
          <title>{`Booking | FieldNexus`}</title>
        </Helmet>
        {(isLoading || tenant.isLoading) && <div className="spinner-center">
          <Spinner />
        </div>}
        <style dangerouslySetInnerHTML={{__html: `
            .bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator:before,
            .bp3-control input:checked ~ .bp3-control-indicator,
            .bp3-control.bp3-radio input:checked ~ .bp3-control-indicator:before {
              background-color: ${object && object.attributes && object.attributes.booking_form_color} !important;
              border-color: ${object && object.attributes && object.attributes.booking_form_color} !important;
            }
            .save-draft-btn {
              border: 1px solid ${object && object.attributes && object.attributes.booking_form_color || '#3b6afe'} !important;
              color: ${object && object.attributes && object.attributes.booking_form_color} !important;
            }

            .save-draft-btn:hover {
              border: 1px solid ${object && object.attributes && object.attributes.booking_form_color};
            }
            .bp3-input:focus, .bp3-input:hover, .bp3-input.bp3-active {
              border: 1px solid ${object && object.attributes && object.attributes.booking_form_color} !important;
            }
            .form-control:focus {
              box-shadow: none !important;
              border: 1px solid ${object && object.attributes && object.attributes.booking_form_color};
            }
            .service-card-active-inline {
              border: 1px solid ${object && object.attributes && object.attributes.booking_form_color} !important;
              -webkit-box-shadow: 0px 0px 6px 0px rgba(31, 144, 255, 0.55) !important;
              box-shadow: 0px 0px 6px 2px rgba(234, 234, 234, 0.73) !important;
            }
            .service-card:hover {
              border: 1px solid ${object && object.attributes && object.attributes.booking_form_color} !important;
              -webkit-box-shadow: 0px 0px 6px 0px rgba(31, 144, 255, 0.55) !important;
              box-shadow: 0px 0px 6px 2px rgba(234, 234, 234, 0.73) !important;
            }
            .DayPicker-Day--selected {
              background-color: ${object && object.attributes && object.attributes.booking_form_color} !important;
            }

            .next-visit-active {
              background-color: ${object && object.attributes && object.attributes.booking_form_color} !important;
              border: 1px solid ${object && object.attributes && object.attributes.booking_form_color} !important;
            }

            .next-visit:hover {
              background-color: ${object && object.attributes && object.attributes.booking_form_color} !important;
              border: 1px solid ${object && object.attributes && object.attributes.booking_form_color} !important;
              -webkit-box-shadow: 0px 0px 6px 0px rgba(31, 144, 255, 0.55) !important;
              box-shadow: 0px 0px 6px 2px rgba(234, 234, 234, 0.73) !important;
            }

            .booking-page {
              background-color: ${object && object.attributes && object.attributes.booking_form_bgcolor} !important;
            }
          `}} />
        {!isLoading && !tenant.isLoading &&
          <PageWrapGray>
            <div className="booking-page">
              <div className="booking-page-container">
                <div className="column-left">
                <div className="booking-form">
                  <div className="booking-form-header">
                    <div className="header-title">Complete Your Booking</div>
                    <div className="header-content">Please fill us the form to complete your booking.</div>
                  </div>
                  <div className="form-block">
                    <div className="form-block-title">Step 1: Who Are You?</div>
                    <div className="block-instruction">Please give us your contact information.</div>
                    
                    <div className="form-fields">
                      <div className="field-type1">
                        <FormGroup
                          label={"First Name"}
                          labelFor="text-input"
                          labelInfo={"*"}
                        >
                          <input 
                            type='text' 
                            className='form-control' 
                            placeholder='First name'
                            defaultValue={attributes.first_name}
                            onChange={(event) => {attributes.first_name = event.target.value;}} 
                          />
                        </FormGroup>
                      </div>
                      <div className="field-type1">
                        <FormGroup
                          label={"Last Name"}
                          labelFor="text-input"
                          labelInfo={"*"}
                        >
                          <input 
                            type='text' 
                            className='form-control' 
                            placeholder='Last name'
                            defaultValue={attributes.last_name}
                            onChange={(event) => {attributes.last_name = event.target.value;}} 
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="form-fields">
                      <div className="field-type1">
                        <FormGroup
                          label={"Email Address"}
                          labelFor="text-input"
                          labelInfo={"*"}
                        >
                          <input 
                            type='email' 
                            className='form-control' 
                            placeholder='mail@domain.com'
                            defaultValue={attributes.email}
                            onChange={(event) => {attributes.email = event.target.value;}} 
                          />
                        </FormGroup>
                      </div>
                      <div className="field-type1">
                        <FormGroup
                          label={"Phone Number"}
                          labelFor="text-input"
                          labelInfo={"*"}
                        >
                          <input 
                            type='text' 
                            className='form-control' 
                            placeholder='Ex. 9876543210'
                            defaultValue={attributes.phone}
                            onChange={(event) => {attributes.phone = event.target.value;}} 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="form-block">
                    <div className="form-block-title">Step 2: Your Address</div>
                    <div className="block-instruction">Please give us your contact information.</div>
                    
                    <div className="form-fields">
                      <div className="field-type2">
                        <FormGroup
                          label={"Address"}
                          labelFor="text-input"
                          labelInfo={"*"}
                        >
                          <GeoComplete
                            className='form-control geo-control' 
                            placeholder="Search Address"
                            initialValue={attributes.address}
                            onSuggestSelect={this.onAddressSelect.bind(this)}
                            onChange={this.onAddressClear.bind(this)}
                          />
                        </FormGroup>
                      </div>
                      <div className="field-type3">
                        <FormGroup

                          label={"Address 2"}
                          labelFor="text-input"
                        >
                          <input 
                            type='text' 
                            className='form-control' 
                            placeholder='Address 2'
                            defaultValue={attributes.suite}
                            onChange={(event) => {attributes.suite = event.target.value;}} 
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="form-fields">
                      <div className="field-type4">
                        <FormGroup
                          label={"City"}
                          labelFor="text-input"
                          labelInfo={"*"}
                        >
                          <input 
                            type='text' 
                            className='form-control' 
                            placeholder='City'
                            defaultValue={attributes.city}
                            onChange={(event) => {attributes.city = event.target.value;}} 
                          />
                        </FormGroup>
                      </div>
                      <div className="field-type3">
                        <FormGroup
                          label={"State/Province"}
                          labelFor="text-input"
                          labelInfo={"*"}
                        >
                          <input 
                            type='text' 
                            className='form-control' 
                            placeholder='State/Province'
                            defaultValue={attributes.state}
                            onChange={(event) => {attributes.state = event.target.value;}} 
                          />
                          
                        </FormGroup>
                      </div>
                      <div className="field-type3">
                        <FormGroup
                          label={"Zip code/Postal Code"}
                          labelFor="text-input"
                          labelInfo={"*"}
                        >
                          <input 
                            type='text' 
                            className='form-control' 
                            placeholder='Ex. 0377'
                            defaultValue={attributes.zip}
                            onChange={(event) => {attributes.zip = event.target.value;}} 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div>
                    {
                      serviceTypeCategories.objects &&
                      <div className="form-block">
                        { serviceTypeCategories.objects.length === 0 ?
                            <Spinner size='25' />
                            :
                            ""
                          }
                        <div className="row">
                          <div className="col col-xs-12 col-md-6" >
                            <FormGroup label={'Service Type Category'} labelFor="text-input" labelInfo={"*"}>
                              <select 
                                className='form-control'
                                onChange={this.onServiceTypeCategoryChange.bind(this)}
                              >
                                <option value="">Select category</option>
                                { serviceTypeCategories.objects.map((category) => {
                                    return(
                                      <option value={category.attributes.id} key={category.attributes.id}>{category.attributes.name}</option>
                                    )
                                  })
                                }
                              </select>
                            </FormGroup>
                          </div>
                        </div>
                      </div>

                    }
                    { object && object.attributes && object.attributes.display_service_type === 'Icon' && attributes && attributes.service_type_category_id != '' ?

                      <div className="form-block">
                        <div className="form-block-title">Step 3: {object && object.attributes && object.attributes.heading_text_for_service_type}</div>
                          <div className="block-instruction"></div>
                          {/* { objects.length === 0 ?
                            <Spinner size='25' />
                            :
                            ""
                          } */}
                          <div className="form-fields fields-style">
                            { objects && objects.map((service,key) => {
                              if(service.attributes.display_on_booking_form) {
                                return (
                                  <div className="field-type5">
                                    <div 
                                      className={this.state.isServiceTypeChecked == service.attributes.id ? 'services-category service-card-active': 'services-category'}
                                    >
                                      <div className="service-card">
                                        <div className="service-icon">
                                          <img style={{ width: '40px' }} src={service.attributes.logo} />
                                        </div>
                                        <div className="service-checkbox">
                                          <Radio label="" type="radio" name="service_type_id" 
                                            onChange={(event) => {this.onServiceTypeSelect(event,service,key)} }
                                          />
                                        </div>
                                      </div>
                                      <div className="service-label">{service.attributes.name}</div>
                                    </div>
                                  </div>
                                  )
                                }
                              })
                            }
                          </div>
                          <div className="form-fields">
                            <div className="col col-xs-12 col-md-12">
                              {pricing_parameters && pricing_parameters.length > 0 && <div className="">
                              
                                <div className="row">
                                {
                                  pricing_parameters && pricing_parameters.length > 0 && pricing_parameters.map((pricing,pricingKey) => {
                                    if(pricing.pricing_type === 'flat'){
                                      return (
                                        <div className="col-md-6">
                                          <FormGroup label={"Pricing"} labelFor="text-input">
                                            <select 
                                              className='form-control' 
                                              placeholder={`Select ${pricing.name}`}
                                              onChange={(event) => {this.onPricingSelect(event,pricing)} }
                                            >
                                              <option value="">Select any one</option>
                                            { this.pricingList(pricing)}
                                            </select>
                                          </FormGroup>
                                        </div>
                                      )
                                    }
                                  })
                                }
                                </div>
                                <div className="row">
                                {
                                  pricing_parameters && pricing_parameters.length > 0 && pricing_parameters.map((pricing) => {
                                    if(pricing.pricing_type === 'range'){
                                      var index = pricing.pricing_parameter_ranges.length === _.filter(pricing.pricing_parameter_ranges, function(obj) { return obj.image_url; }).length
                                      return (
                                        <div className="col-md-6">
                                          <FormGroup
                                            label={pricing.name}
                                            labelFor="text-input"
                                            labelInfo={"*"}
                                          >
                                            {
                                              index === true ?
                                              <div className="d-flex flex-wrap mt-3">
                                                {pricing.pricing_parameter_ranges.map((pricing_range,key) => {
                                                  let indexChecked = attributes && attributes.pricing_parameter_ranges.map(function(item) { return item.name; }).indexOf(pricing_range.label);
                                                  var checked_id ='';
                                                  if(indexChecked > -1){
                                                    checked_id = pricing_range.id;
                                                  }
                                                  return (
                                                    <div className="imgwrap">
                                                      <label className="font-weight-bold">{pricing_range.label}</label>
                                                      <div className="imgbox">
                                                      <Radio type="radio" name="checkboxgroup" 
                                                        checked={checked_id === pricing_range.id ? true: false}
                                                        onChange={(event) => {this.onPricingRangeSelectImage(event,pricing_range,key)} } 
                                                      />
                                                        <img width="150" height="150" src={pricing_range.image_url}  />
                                                      </div>
                                                    </div>
                                                  )
                                                })}    
                                              </div>:
                                              <select 
                                                className='form-control' 
                                                placeholder={`Select ${pricing.name}`}
                                                onChange={(event) => {this.onPricingRangeSelect(event,pricing)} }
                                                >
                                                <option value="">Select any one</option>
                                                { this.pricingRangeList(pricing)}
                                              </select>
                                            }
                                            
                                          </FormGroup>
                                        </div>
                                      )
                                    }
                                  })
                                }
                                </div>
                              </div>}
                            </div>
                          </div>
                      </div>
                      :
                      <div className="form-block">
                        {attributes && attributes.service_type_category_id != '' ?
                        <div>
                          <div className="form-block-title" style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{marginRight: 10}}>Step 3: {object && object.attributes && object.attributes.heading_text_for_service_type}</div>
                            {/* { objects.length === 0 ?
                              <Spinner size='25' />
                              :
                              ""
                            } */}
                          </div>
                    
                          <div className="row">
                            <div className="col col-xs-12 col-md-6" >
                              <FormGroup label={object && object.attributes && object.attributes.another_heading_text_for_service} labelFor="text-input" labelInfo={"*"}>
                                <select 
                                  className='form-control'
                                  onChange={this.onServiceTypeChange.bind(this)}
                                >
                                  <option value="">Select service</option>
                                  { objects && objects.map((service) => {
                                      if(service.attributes.display_on_booking_form) {
                                        return(
                                          <option value={service.attributes.id} key={service.attributes.id}>{service.attributes.name}</option>
                                        )
                                      }
                                    })
                                  }
                                </select>
                              </FormGroup>
                            </div>
                            <div className="col col-xs-12 col-md-12">
                              {pricing_parameters && pricing_parameters.length > 0 && <div className="">
                              
                                <div className="row">
                                {
                                  pricing_parameters && pricing_parameters.length > 0 && pricing_parameters.map((pricing,pricingKey) => {
                                    if(pricing.pricing_type === 'flat'){
                                      return (
                                        <div className="col-md-6">
                                          <FormGroup label={"Pricing"} labelFor="text-input">
                                            <select 
                                              className='form-control' 
                                              placeholder={`Select ${pricing.name}`}
                                              onChange={(event) => {this.onPricingSelect(event,pricing)} }
                                            >
                                              <option value="">Select any one</option>
                                            { this.pricingList(pricing)}
                                            </select>
                                          </FormGroup>
                                        </div>
                                      )
                                    }
                                  })
                                }
                                </div>
                                <div className="row">
                                {
                                  pricing_parameters && pricing_parameters.length > 0 && pricing_parameters.map((pricing) => {
                                    if(pricing.pricing_type === 'range'){
                                      var index = pricing.pricing_parameter_ranges.length === _.filter(pricing.pricing_parameter_ranges, function(obj) { return obj.image_url; }).length
                                      return (
                                        <div className="col-md-6">
                                          <FormGroup
                                            label={pricing.name}
                                            labelFor="text-input"
                                            labelInfo={"*"}
                                          >
                                            {
                                              index === true ?
                                              <div className="d-flex flex-wrap mt-3">
                                                {pricing.pricing_parameter_ranges.map((pricing_range,key) => {
                                                  let indexChecked = attributes && attributes.pricing_parameter_ranges.map(function(item) { return item.name; }).indexOf(pricing_range.label);
                                                  var checked_id ='';
                                                  if(indexChecked > -1){
                                                    checked_id = pricing_range.id;
                                                  }
                                                  return (
                                                    <div className="imgwrap">
                                                      <label className="font-weight-bold">{pricing_range.label}</label>
                                                      <div className="imgbox">
                                                      <Radio type="radio" name="checkboxgroup" 
                                                        checked={checked_id === pricing_range.id ? true: false}
                                                        onChange={(event) => {this.onPricingRangeSelectImage(event,pricing_range,key)} } 
                                                      />
                                                        <img width="150" height="150" src={pricing_range.image_url}  />
                                                      </div>
                                                    </div>
                                                  )
                                                })}    
                                              </div>:

                                              <select
                                                className='form-control'
                                                placeholder={`Select ${pricing.name}`}
                                                onChange={(event) => { this.onPricingRangeSelect(event, pricing) }}
                                                >
                                                <option value="">Select any one</option>
                                                {this.pricingRangeList(pricing)}
                                              </select>

                                            }
                                          </FormGroup>
                                        </div>
                                      )
                                    }
                                  })
                                }
                                </div>
                              </div>}
                            </div>
                          </div>
                        </div>
                        :''
                        }
                      </div>
                    }

                    {service_type_extra && service_type_extra.length > 0 && <div className="form-block">
                      <div className="form-block-title">Add ons</div>
                      <div className="block-instruction"></div>
                      <div className="form-fields fields-style">
                        {
                          service_type_extra && service_type_extra.length > 0 && service_type_extra.map((extra,key) => {
                            
                            return (
                              <div className="field-type5">
                                <div className={`services-category ${this.isServiceExtraChecked === key ? 'service-card-active' :''}`}>
                                  <div className="service-card">
                                    <div className="service-icon"><img src={extra.icon_file.thumb.url} width={50} /></div>
                                    <div className="service-checkbox">
                                      <Checkbox label="" type="checkbox"
                                        defaultChecked={this.isServiceExtraChecked}
                                        onChange={(event) => {this.onExtraServiceSelect(event,extra,key)} }
                                      />
                                    </div>
                                  </div>
                                  <div className="service-label">{extra.name}</div>
                                </div>
                              </div>
                          )
                        })
                      }
                    </div>
                    </div>}
                    {groups && questions && questions.length > 0 && <div className="form-block">
                      <div className="form-block-title">Additional information</div>
                        {
                          groups.objects.map(function(group) { 
                            var isInGroup = questions.map(function(item) { return item.question_group_id; }).indexOf(group.id);
                            if(isInGroup > -1) {
                              return(
                                <div className="form-fields">
                                    <div className="question-layout">
                                      <div className="questions">
                                        {group.attributes.question}
                                        
                                      </div>
                                      {questions && questions.length > 0 && questions.map((question, index) => {
                                          if(group.attributes.id === question.question_group_id){
                                            return(
                                              <div className="form-fields">
                                                <div className="question-layout">
                                                  <div className="questions inner-question">
                                                    {question.field_label}
                                                  </div>
                                                  <div className="answer">
                                                    {question.field_type === "Checkbox" && <div>
                                                      <RadioGroup inline={true} className="bookings-radio field-type0" name="question_radio">
                                                        {question.field_value.split(/\r?\n/).map((answer) => {
                                                          return(
                                                            <QuestionRadio 
                                                              inline= {true}
                                                              bookingStatus={''}
                                                              question={question}
                                                              answer={answer}
                                                            /> 
                                                          )
                                                        })}
                                                      </RadioGroup>
                                                      </div>}
                                                      {question.field_type === "Dropdown"  && <div className="field-type1">
                                                        <QuestionSelect 
                                                          inline= {true}
                                                          question={question}
                                                          bookingStatus={''}
                                                          selectedAnswer={selectedAnswer}
                                                        /> 
                                                      </div>}
                                                      {question.field_type === "Textbox"  && <div className="field-type1">
                                                        <QuestionTextbox 
                                                          inline= {true}
                                                          question={question}
                                                          bookingStatus={''}
                                                          selectedAnswer={selectedAnswer}
                                                        />
                                                      </div>}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          }})
                                        }
                                    </div>
                                  </div>
                                )
                            }
                            })
                          }
                        </div>
                      }
                  </div>
                  
                  <div>
                    <div className="form-block">
                      <div className="row">
                        <div className="col col-xs-12 col-md-6">
                          <div className="form-block-title">
                            How soon would you like to start this project?
                          </div>
                        <FormGroup  labelFor="text-input">
                          <select 
                            className='form-control' 
                            placeholder={`Select `}
                            onChange={this.onTimePeriodChange.bind(this)} 
                            defaultValue={attributes && attributes.start_project_expected_period}
                          >
                            <option value="">Select any one</option>
                            {object && object.attributes && object.attributes.start_project_expected_period.split(/\r?\n/).map((period) => { 
                              return(
                                <option value={period.split(":")[1]}>{period.split(":")[0]}</option>
                              )})}
                          </select>
                        </FormGroup>
                        </div>
                        <div className="col col-xs-12 col-md-6">
                          <div className="form-block-title">
                            What is the best way for us to contact you?
                          </div>
                        <FormGroup  labelFor="text-input">
                          <select 
                            className='form-control' 
                            placeholder={`Select `}
                            onChange={this.onContactWayChange.bind(this)} 
                            defaultValue={attributes && attributes.contact_way}
                          >
                            <option value="">Select any one</option>
                            {object && object.attributes && object.attributes.contact_way.split(/\r?\n/).map((way) => {                              
                              return(
                                <option value={way.split(":")[1]}>{way.split(":")[0]}</option>
                              )})}
                          </select>
                        </FormGroup>
                        </div>
                      </div>
                    </div>
                    { object && object.attributes && object.attributes.enable_visit_us === true &&
                      <div className="form-block">
                        <div className="form-block-title">Step 4: When would you like us to visit?</div>
                        <div className="block-instruction"></div>
                        <div className="row">
                          <div className="col col-xs-12 col-md-6 booking_form_date">
                            <FormGroup
                              label={"Choose a date"}
                              labelFor="text-input"
                              labelInfo={"*"}
                            >
                              <DateTimeInput 
                                placeholder={'Date'} 
                                onChange={this.handleStartDateChange.bind(this)}
                              />
                            </FormGroup>
                          </div>
                          <div className="col col-xs-12 col-md-6">
                            
                          </div>
                        </div>
                      </div>
                    }
                    { object && object.attributes && object.attributes.enable_often_visit_us === true &&
                      <div>
                        {frequency && frequency.length > 0 && 
                          <div className="form-block">
                            <div className="form-block-title">- How often you want us to visit?</div>
                            <div className="block-instruction"></div>
                            <div className="form-fields fields-style">
                              { 
                                frequency.map((freqncy,frequencyKey) => {
                                  return (
                                    <div className="field-type6">
                                      <div onClick={(event) => {this.onFrequencySelect(event,freqncy,frequencyKey)} } className={`next-visit ${frequencyKey == this.state.isFrequencySelected ? 'next-visit-active' : ''}`}>{freqncy.title}</div>
                                    </div>
                                  )

                                })
                              }
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                  

                  <div>
                  { object && object.attributes && object.attributes.payment_method === true &&
                    <div className="form-block">
                      <div className="form-block-title">Step 5: Select Payment Method</div>
                      <div className="block-instruction"></div>
                      <div className="form-fields fields-style">
                        {
                          items.map((payment,paymentKey) => {
                            return (
                              <div className="field-type6" key={Math.random()}>
                                <div onClick={(event) => {this.onPaymentSelect(event,payment,paymentKey)} } className={`next-visit ${paymentKey === this.state.isPaymentSelect ? 'next-visit-active' : ''}`}>{payment.label}</div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  }
                    <div className="form-block">
                      <div className="upload-btn-wrapper">
                        <label htmlFor="file-upload" className="custom-file-upload">
                          <img src="/upload-file-icon.svg" />
                          <span style={{marginRight: 10}}>Upload a file </span>(Optional) (PNG, JPG, GIF, PDF, DOC, DOCX, XLS, XLSX)
                          {showUploadAttachmentLoader && 
                            <Spinner size={Spinner.SIZE_SMALL} /> }
                        </label>
                        <input id="file-upload" type="file" multiple={true} onChange={this.onChangeFile.bind(this)} />
                      </div>
                      <div className="card rounded-0 table-responsive">
                        {attributes && attributes.booking_attachments.length > 0 && <table className="table table-vertical-align table-data-presentation table-hover" style={{ width: '100%' }}>
                          <thead>
                            <tr>
                              <th>File Name</th>
                              <th>Content Type</th>
                              <th width="10%">File Size</th>
                              <th className="item-center" width="10%">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {attributes.booking_attachments.map((attachment) => {
                              return(
                                <tr>
                                  <td><a href={attachment.asset.url} target="_blank" download>{attachment.asset.url.split("/").pop()}</a></td>
                                  <td>{attachment.asset_content_type}</td>
                                  <td>{attachment.asset_human_file_size}</td>
                                  <td>
                                  <div className="action-buttons" minimal={'true'}>
                                    <Button minimal={true} small={true} onClick={() => this.removeAttachment(attachment)}>
                                      <img src="/delete-light-icon.svg" />
                                    </Button>
                                  </div>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>}
                      </div>
                    </div>
                  </div>
                  <div className="form-block">
                    <div className="form-block-title">Step 6: Add Your Note (If necessary)</div>
                    <div className="block-instruction"></div>
                    <div className="form-fields">
                      <div className="field-type0">
                        <FormGroup>
                          <textarea 
                            type='text' 
                            className='form-control' 
                            placeholder='Type here...'
                            defaultValue={attributes.note}
                            onChange={(event) => {attributes.note = event.target.value;}}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div className="column-right">
                <div className="summary-column">
                  <div className="summary-header" style={{backgroundColor: object && object.attributes && object.attributes.booking_form_color}}>Booking Summary</div>
                  <div className="summary-data">
                    <div className="scrollbar-right-side">
                      <div className="summary-field">
                        <div className="summary-icon"><img src="/date-time.svg" /></div>
                        <div className="summary-description">
                          <div className="headline"></div>
                        <div className="description">{moment(attributes.start_date).format('MMMM Do YYYY, h:mm:ss a')}</div>
                        </div>
                      </div>
                      {attributes && attributes.address && <div className="summary-field">
                        <div className="summary-icon"><img src="/map-pin-icon.svg" /></div>
                        <div className="summary-description">
                          <div className="headline">{attributes && attributes.first_name} {attributes && attributes.last_name}</div>
                          <div className="description">
                            <div className="content">{attributes && attributes.address}{attributes && attributes.suite ? ',' :''} {attributes && attributes.suite} {attributes && attributes.state}{attributes && attributes.zip ? ',' :''} {attributes && attributes.zip}</div>
                          </div>
                        </div>
                      </div>}
                      {serviceTypeDetail && serviceTypeDetail.object && serviceTypeDetail.object.attributes && serviceTypeDetail.object.attributes && <div className="summary-field">
                        <div className="summary-icon"><img src="/note-pad-icon.svg" /></div>
                        <div className="summary-description">
                        <div className="headline">Services</div>
                          <div className="description">
                            <div className="content">
                              {serviceTypeDetail.object.attributes.name}
                              <div>
                                {`1 x ${currencyFormattedAmount(parseFloat(serviceTypeDetail.object.attributes.rate))}`}
                              </div>
                            </div>
                            <div className="price">
                              {currencyFormattedAmount(serviceTypeDetail.object.attributes.rate)}
                            </div>
                          </div>
                        </div>
                      </div>}
                      {/* {attributes && attributes.pricing_parameters && attributes.pricing_parameters.length > 0 && */}
                        <div className="summary-field">
                          <div className="summary-icon"><img src="/note-pad-icon.svg" /></div>
                          <div className="summary-description">
                            <div className="headline">{object && object.attributes && object.attributes.pricing_title != '' ? object.attributes.pricing_title:'Pricing'}</div>
                              { attributes && attributes.pricing_parameters && attributes.pricing_parameters.map((parameter) => {
                                  return (
                                    <div className="description">
                                      <div className="content">
                                        {parameter.name} x {parameter.quantity}
                                        <div></div>
                                      </div>
                                      <div className="price">{currencyFormattedAmount((parameter.total_amount))}</div>
                                    </div>
                                  )
                                })
                              }
                              {
                                attributes && attributes.pricing_parameter_ranges && attributes.pricing_parameter_ranges.map((parameter_range) => {
                                  return (
                                    <div className="description">
                                      <div className="content">
                                        {parameter_range.name}
                                        <div></div>
                                      </div>
                                      <div className="price">{currencyFormattedAmount(parameter_range.price)}</div>
                                    </div>
                                  )
                                })
                              }
                          </div>
                        </div>
                      {/* } */}
                      { service_type_extra && service_type_extra.length > 0 &&
                        <div className="summary-field">
                          <div className="summary-icon"><img src="/note-pad-icon.svg" /></div>
                          <div className="summary-description">
                          <div className="headline">Add ons</div>
                              {
                                attributes && attributes.extra_service_types && attributes.extra_service_types.map((ext_service) => {
                                  return (
                                    <div className="description">
                                      <div className="content">
                                        {ext_service.name}
                                        <div></div>
                                      </div>
                                      <div className="price">{currencyFormattedAmount(ext_service.price)}</div>
                                    </div>
                                  )
                                })
                              }
                          </div>
                        </div>
                      }
                    </div>
                    <div className="summary-total">
                      <div className="total-label">Total (approximate)</div>
                      <div className="price">
                        {
                          currencyFormattedAmount(this.grand_total)
                        }
                      </div>
                    </div>
                    <Button 
                      onClick={() => this.createBooking('draft')} 
                      className={attributes && attributes.status === 'confirmed' ? "save-draft-btn disable-save-draft-btn" : "save-draft-btn"}
                      loading={isLoading}>
                        Save
                    </Button>
                    <br></br>
                    <Button 
                      onClick={() => this.createBooking('confirmed')} 
                      className="confirm-btn" 
                      loading={isLoading}
                      style={{backgroundColor: object && object.attributes && object.attributes.booking_form_color, borderColor: object && object.attributes && object.attributes.booking_form_color}}>
                        {object && object.attributes && object.attributes.cta_title != '' ? object.attributes.cta_title:'Confirm Booking'}
                    </Button>
                  </div>
                </div>
              </div> 
              </div>            
            </div>
          </PageWrapGray>
        }
      </div>
    );
  }

};
export default NewBooking;