import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Toast from 'helpers/toaster';
import { Intent } from "@blueprintjs/core";

@inject('store', 'preferenceStore') @observer
class DefaultItem extends Component {

  updateDefaultLineItem(line_item) {
    const { preferenceStore } = this.props;
    preferenceStore.updateDefaultLineItem(line_item)
      .then(this.onSucessfulUpdateLineItem.bind(this))
      .catch(this.onFailUpdateLineItem.bind(this))
  }

  onSucessfulUpdateLineItem(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Updated successfully!', intent: Intent.SUCCESS })
  }

  onFailUpdateLineItem(error) {
    if(error && error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Unable to update!', intent: Intent.DANGER })
    }
  }

  removeDefaultLineItem(line_item) {
    const { preferenceStore } = this.props;
    preferenceStore.deleteDefaultLineItem(line_item)
      .then(this.onSucessfulDeleteLineItem.bind(this))
      .catch(this.onFailDeleteLineItem.bind(this))
  }

  onSucessfulDeleteLineItem(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Deleted successfully!', intent: Intent.SUCCESS })
  }

  onFailDeleteLineItem(error) {
    if(error && error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Unable to delete!', intent: Intent.DANGER })
    }
  }

  changeLineItemType(event) {
    this.props.line_item.line_item_type = event.target.value;
  }

  render() {
    let { line_item } = this.props;
    return(
      <tr key={line_item.id}>
        <td>
          <input 
            type='text' 
            className='bp3-input bp3-fill' 
            placeholder='Description'
            defaultValue={line_item.description}
            onChange={(event) => {line_item.description = event.target.value;}} 
          />
        </td>
        <td>
          <div className="bp3-select bp3-fill">
            <select
              className="bp3-fill"
              defaultValue={line_item.line_item_type}
              onChange={this.changeLineItemType.bind(this)}
            >
              <option value="">Select type</option>
              <option value="percentage_of_material">Percentage of Materials</option>
              <option value="percentage_of_labour">Percentage of Labor</option>
              <option value="percentage_of_materials_labour">Percentage of Materials + Labor</option> 
              <option value="dollar_value">Dollar value</option>
            </select>
          </div>
        </td>
        <td>
          <input 
            type='number' 
            className='bp3-input bp3-fill' 
            placeholder='value'
            defaultValue={line_item.value}
            onChange={(event) => {line_item.value = event.target.value;}} 
          />
        </td>
        <td style={{ width: '8%' }}>
          <button 
            className='bp3-button bp3-minimal bp3-icon-floppy-disk bp3-small'
            type='button'
            onClick={() => {this.updateDefaultLineItem(line_item)} }
          />
          <button 
            className='bp3-button bp3-minimal bp3-icon-trash bp3-small'
            type='button'
            onClick={() => {this.removeDefaultLineItem(line_item)}} 
          />
        </td>
      </tr>
    )
  }

};

export default DefaultItem;