import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { currencyFormattedAmount } from 'helpers/currency';

@inject("paymentStore")
@observer
class InvoiceHeader extends Component {
  render() {
    const {
      paymentStore: {
        due_amount,
        invoice: { object }
      }
    } = this.props;
    return (
      <div className="payment-padding">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <img
              src={object.attributes.tenant.logo}
              alt="tenant logo"
              style={{maxWidth:"100%", maxHeight:80}}
            />
          </div>
          <div>
            <div className="due-payment-block"
              style={{
                
              }}
            >
              Amount Due
              <h2>{currencyFormattedAmount(due_amount)}</h2>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "2.5rem" }}>
          <h2 style={{ fontWeight: 400, marginBottom: 20 }}>INVOICE</h2>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", marginBottom:30}}>
            <div style={{ marginRight: 50 }}><p><span style={{ color: "#91A4B9", marginRight: 7 }}>Date</span> {moment().format("ll")}</p></div>
            <div style={{ marginRight: 50 }}><p><span style={{ color: "#91A4B9", marginRight: 7 }}>Due Date</span> {moment(object.attributes.invoice_due_date).format(
              "ll"
            )}</p></div>
            <div style={{ marginRight: 50 }}><p><span style={{ color: "#91A4B9", marginRight: 7 }}>Invoice #</span> {object.attributes.number}</p></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <div className="bill-from">
              <p style={{ color:"#1800FF"}}>Bill From:</p>
              <h6>{object.attributes.tenant.name}</h6>
              <p style={{ color:"#91A4B9"}}>
                {object.attributes.tenant.street_address} 
                {object.attributes.tenant.city},{" "}
                {object.attributes.tenant.province},{" "}
                {object.attributes.tenant.postal_code}
              </p>
              <p style={{ color: "#91A4B9" }}><strong>Phone:</strong> {object.attributes.tenant.phone}</p>
              {object.attributes.tenant.tax_number_title && object.attributes.tenant.tax_number && <p style={{ color: "#91A4B9" }}>
                  <strong>{object.attributes.tenant.tax_number_title}: </strong>{object.attributes.tenant.tax_number}
                </p>}
            </div>
            <div className="bill-to">
              <p style={{ color: "#228B22" }}>Bill To:</p>
              <h6>{object.attributes.client.name}</h6>
              <p style={{ color: "#91A4B9" }}>
                {object.attributes.location.full_address}
                <br />
                {object.attributes.po_number && (
                  <span>{object.attributes.po_number}</span>
                )}
              </p>
            </div>
          </div>
        </div>
        <br />
        <hr />               
      </div>
    );
  }
}

export default InvoiceHeader;
