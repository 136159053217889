import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Dialog, Classes, Button, Spinner } from '@blueprintjs/core';
import PDF from 'react-pdf-js';
import { CurrentEnv } from 'services/Api';

@inject('store', 'quoteStore', 'viewStore') @observer
class PdfViewer extends Component {

  @observable isOpen  = false;
  @observable url = undefined;

  componentWillMount() {
    let {
      workReport,
      viewStore: {
        currentUser
      }
    } = this.props;
    if(this.props.url){
      this.url = this.props.url;
    }
    else if(workReport) {
      this.url = `${CurrentEnv.baseURL()}/work_reports/${workReport.attributes.id}/download_pdf?api_token=${currentUser.attributes.api_token}&x_tenant_id=${currentUser.attributes.tenant_id}`
    }
  }

  renderTitle() {
    let { workReport, title } = this.props;
    if(workReport){
      let kind = workReport.attributes.kind === 'WorkReport' ? "Work Order" : "Estimate";
      if(workReport.attributes.job && workReport.attributes.job.id) {
        title = `PDF: Job #: ${workReport.attributes.job.event_number} | ${kind} #: ${workReport.attributes.number.split('-')[1]}`;
      } else {
        title = `PDF: ${kind} #: ${workReport.attributes.number}`
      }
    }
    return title;
  }

  handleClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    let { isOpen } = this.props;
    return(
      <Dialog
        iconName="eye-open"
        title={this.renderTitle.bind(this)()}
        canOutsideClickClose={false}
        canEscapeKeyClose={true}
        onClose={this.handleClose.bind(this)}
        isOpen={isOpen}
        style={{width: 635}}
      >
        <div className={Classes.DIALOG_BODY}>
          {this.url && <PDF file={this.url} loading={<Spinner />} />}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleClose.bind(this)}>Close</Button>
          </div>
        </div>
      </Dialog>
    )
  }

};

export default PdfViewer;