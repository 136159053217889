import { observable, action } from "mobx";
import { Api } from "services/Api";

class ActivityStore {
  @observable jobActivityLogs = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {
      page: 1,
      total_pages: 1
    },
    isLoadingMore: false
  };

  @action
  loadActivityLogs(job_id, hasMoreRecords) {
    const nextPage = hasMoreRecords ? this.jobActivityLogs.meta.page + 1 : 1;
    this.jobActivityLogs.isLoading = hasMoreRecords ? false : true;
    this.jobActivityLogs.isLoadingMore = hasMoreRecords;
    let promise = Api.post("/activity/job_activities", { job_id: job_id, page: nextPage });

    promise
      .then((response) => {
        this.jobActivityLogs.isLoading = false;
        this.jobActivityLogs.isLoadingMore = false;
        this.jobActivityLogs.objects = hasMoreRecords ? this.jobActivityLogs.objects.concat(response.data.data) : response.data.data;
        this.jobActivityLogs.meta = response.data.meta;
      })
      .catch((error) => {
        this.jobActivityLogs.isLoading = false;
        this.jobActivityLogs.isError = false;
      });

    return promise;
  }
}

export default ActivityStore;
