import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { 
  Spinner, NonIdealState
} from "@blueprintjs/core";
import { observer, inject } from 'mobx-react';
import Sticky from 'react-stickynode';
import styled from '@emotion/styled';
import Card from 'components/Card';
import VacationsTable from 'components/Tables/Vacations';
import SearchBars from 'components/SearchBars';
import UserSidebar from './user-sidebar';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`

const PageWrapGray = styled('div')`
  padding: 8px 12px;
  background-color: #F8FAFB;
  height: calc(100vh - 75px);
`

@inject('store', 'viewStore', 'userStore') @observer
class UsersVacations extends Component {

  render() {
    let content;
    let {
      userStore: {
        vacations: {
          isLoading,
          isError
        }
      }
    } = this.props;

    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (!isError && !isLoading) {
      content = <VacationsTable />
    }
    return(
      <div className="user-page-layout">
        <Helmet>
          <title>Vacations | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <UserSidebar />
          </div>
          <div className="col-lg-10">

          
       <PageWrapGray>
        <Sticky enabled={true} top={0} innerZ={9}>         
            <SearchBars.Vacations />          
        </Sticky>
          
          <Card>
            {content}
          </Card>
          </PageWrapGray>
        </div>
        </div>
      </div>
    )
  }

};

export default UsersVacations;