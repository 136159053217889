import React, { Component } from 'react';
import { inject } from 'mobx-react';
import moment from 'moment';

@inject('store', 'quoteStore')
class QuoteSignatureSection extends Component {
  render() {
    let {
      quoteStore: {
        quote: {
          object: {
            attributes,
            attributes: {
              signatures: {
                client_signature,
                tech_signature
              }
            }
          }
        }
      }
    } = this.props;
    if (!client_signature) {
      return (
        <div style={{ padding: '0px 10px' }}>
          <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
            No signatures were captured on this estimate.
          </div>
        </div>
      )
    }
    return (
      <div style={{display:'flex',justifyContent:"space-between"}}>
        <div style={{ paddingLeft: '10px',display:'flex',flexDirection:'column'  }}>
          <div style={{ paddingLeft: '10px' }}>
            <h6>
              <strong>Customer Signature:</strong>
            </h6>
          </div>
          <div className="row">
            <div className="col col-md-6">
              <img
                style={{ width: 200 }}
                alt="Signature"
                src={client_signature.asset.thumb.url} />
            </div>
            <div className="col col-md-6" style={{fontWeight: 'bold'}}>
              {attributes && attributes.is_accepted && <div>
                  <p>Accepted from IP: {attributes && attributes.accepted_ip} </p>
                  <p>Accepted at: {attributes && attributes.accepted_at && moment(attributes.accepted_at).format('llll')} </p>
                </div>}
            </div>
          </div>
        </div>
        <div style={{ paddingLeft: '10px',display:'flex',flexDirection:'column'  }}>
          <div style={{ paddingLeft: '10px' }}>
            <h6  style={{marginRight:10}}>
              <strong>Technician Signature:</strong>
            </h6>
          </div>
          <div className="row">
            <div className="col col-md-6">
              {tech_signature && 
                <img
                  style={{ width: 200,marginRight:10 }}
                  alt="Signature"
                  src={tech_signature.asset.thumb.url} />
              }
              
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default QuoteSignatureSection;