import React, { Component } from "react";
import { Helmet } from "react-helmet";
import VacationsTable from 'components/User/VacationsTable';
import { Spinner, NonIdealState, Button , Intent, Toast } from "@blueprintjs/core";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import * as EmailValidator from 'email-validator';
import _ from 'lodash';
import { Api } from 'services/Api';

@inject("store", "viewStore", "userStore","jobStore",'invoiceStore')
@observer
class UserProfile extends Component {
  @observable leftSideBarCount = {
    Objects: []
  }

  componentDidMount() {   
    let {
      props: {
        viewStore,
        viewStore: { 
          currentUser 
        },        
        store
      }
    } = this; 
    let promise = Api.post('/work_reports/search', { 
      search: { 
        where: {
          created_by_id: currentUser.attributes.id,         
        },
        order: { created_at: 'desc' },
        page: 1,
        per_page: 10
      }
    });
    promise
    .then((response) => {   
      this.leftSideBarCount = {
        Objects: response.data.data
      }
    })     
  }

  handleUserChange(event, key) {
    this.props.viewStore.currentUser.attributes[key] = event.target.value;
  }

  updateUser() {
    if (EmailValidator.validate(this.props.viewStore.currentUser.attributes.email)) {
      this.props.userStore.updateUser(this.props.viewStore.currentUser)
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid email!', intent: Intent.DANGER })
    }
  }

  render() {
    let { user } = this.props;
    const {
      props: {
        viewStore,
        jobStore,
        viewStore: { 
          currentUser 
        },
        jobStore:          {
          job :{
            object
          },
        },
        store
      }
    } = this;   
    const jobLength = _.size(object);
    let { Objects } = this.leftSideBarCount;
    var jobWorkReportsCount = [];
    var jobEstimatesCount = [];
    var jobInvoiceCount = [];
    Objects && Objects.map((attribute, index) => {      
      if(attribute.attributes.kind === 'WorkReport'){
        jobWorkReportsCount.push(attribute.attributes);
      }
      if(attribute.attributes.kind === 'Quote'){
        jobEstimatesCount.push(attribute.attributes);
      }
      if(attribute.attributes.kind === 'Invoice'){
        jobInvoiceCount.push(attribute.attributes);
      }
    })

    console.log(Objects);

    return (
      <div>        
        <div className="page-wrap">         
          <div className="row">
            <div className="col-sm-4">
              <div className="card position-sticky" style={{top:'0'}}>
                <div className="card-body">
                  <div className="profile-avtar">
                    <img
                      src={currentUser.attributes.avatar_url}
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="text-center mt-1">
                    <button className="btn btn-light change-photo btn-sm">
                      Change Photo <input type="file" />
                    </button>
                  </div>

                  <div className="details-wrap text-center">
                    <h4>{currentUser.attributes.full_name}</h4>
                    <p>{currentUser.attributes.role}</p>
                  </div>

                  <ul className="list-group profile-sidebar">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Jobs
                      <span className="badge badge-primary badge-pill">{jobLength}</span>
                    </li>
                    <li 
                      onClick={() => { viewStore.workReportsForCustomer(currentUser.attributes.id) }}                     
                      active={(viewStore.currentView === 'Pages.WorkReports.Index' || viewStore.currentView === 'Pages.WorkReports.Show')} 
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Work Orders
                      <span className="badge badge-primary badge-pill">{jobWorkReportsCount.length}</span>
                    </li> 
                    <li onClick={() => { viewStore.invoicesForCustomer(currentUser.attributes.id) }} active={viewStore.currentView === 'Pages.Invoices.Index'} className="list-group-item d-flex justify-content-between align-items-center">
                      Invoices
                      <span className="badge badge-primary badge-pill">{jobInvoiceCount.length}</span>
                    </li> 
                    <li onClick={() => { viewStore.quotesForCustomer(currentUser.attributes.id) }} active={viewStore.currentView === 'Pages.Quotes.Index'} className="list-group-item d-flex justify-content-between align-items-center">
                      Estimates
                      <span className="badge badge-primary badge-pill">{jobEstimatesCount.length}</span>
                    </li> 
                  
                                        
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="First Name"
                        className="form-control"
                        defaultValue={currentUser.attributes.first_name}
                        onChange={(event) => { this.handleUserChange(event, 'first_name') }}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="form-control"
                        defaultValue={currentUser.attributes.last_name}
                        onChange={(event) => { this.handleUserChange(event, 'last_name') }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <input
                        type="tel"
                        placeholder="Phone Number"
                        className="form-control"
                        defaultValue={currentUser.attributes.phone}
                        onChange={(event) => { this.handleUserChange(event, 'phone') }}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="form-control"
                        defaultValue={currentUser.attributes.email}
                        onChange={(event) => { this.handleUserChange(event, 'email') }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                        <Button icon="automatic-updates" text="Update Details" className="" onClick={this.updateUser.bind(this)} />
                      
                    </div>
                  </div>
                </div>
              </div>
              <VacationsTable user={currentUser} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfile;
