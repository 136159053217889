import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Button, Intent } from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import Invite from 'components/User/Invite';
import _ from 'lodash';

const InviteGrid = styled('div')`
  display: flex;
  margin-top: 80px;
  justify-content: center;
`

@inject('viewStore', 'userStore') @observer
class InviteUsers extends Component {

  inviteTeamMembers() {
    let { users } = this.props.userStore;
    var error = false;
    if (users.some(user => user.email === "")) {
      error = true;
    }
    if (error) {
      Toast.show({ icon: 'warning-sign', message: 'Email is required!', intent: Intent.DANGER })
    } else {
      this.props.userStore.inviteUser()
        .then(this.onSuccessInviteUser.bind(this))
        .catch(this.onFailureInviteUser.bind(this));
    }
  }

  onFailureInviteUser(response){
    let { failed } = response.response.data;
    let { users } = this.props.userStore;
    users.map((user) => {
      user.error = null;
      let failure = _.find(failed, (failure) => failure.email === user.email );
      user.error = failure.errors.join(", ");
    })
    Toast.show({ icon: 'warning-sign', message: 'Error occured while inviting users!', intent: Intent.DANGER })
  }

  onSuccessInviteUser(response) {
    this.props.viewStore.dashboard();
    Toast.show({ icon: 'thumbs-up', message: 'Invitation(s) sent successfully!', intent: Intent.SUCCESS })
  }

  render() {
    const {
      viewStore,
      userStore,
      userStore: {
        users,
        showInviteUserLoader
      }
    } = this.props;
    return(
      <InviteGrid>
        <div className="form-wrap">
          <h3 className="login-heading">Let's invite your team.</h3>
          { 
            users.map((user, index) => {
              return(
                <div>
                  <Invite error={user.error} user={user} key={Math.random()} userIndex={index} />
                </div>
              )
            })
          }

          <div className="row align-items-center">
            <div className="col-sm-6 fg-pass fg-ad-member">
              <a onClick={() => userStore.incrementTeamCount()}>+ Add another member</a>
            </div>
            <div className="col-sm-6 fg-next fg-invite">
              <a className="skip-link" href="javscript:;" onClick={() => {viewStore.users()}}>Skip</a>
              <Button
                className="btn-gray"
                loading={showInviteUserLoader}
                onClick={this.inviteTeamMembers.bind(this)} >
                Invite
              </Button>
            </div>
          </div>
        </div>
      </InviteGrid>
    )
  }

};

export default InviteUsers;