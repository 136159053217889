import React from 'react'
import { Button, Popover, Position, Tag, Spinner } from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import ListPicker from 'components/ListPicker';
import PropTypes from 'prop-types';
import { Api } from 'services/Api';
import axios from 'axios';

const selectAllId = "selectAll";
@observer
class TechnicianPicker extends React.Component {

  @observable loading = true;
  @observable failed = false;
  @observable options = [];

  @action
  onTechnicianLoad(response) {
    let {
      props: {
        onChange,
        selectedOptions,
        maximumSelections,
        includeSelectAll
      }
    } = this;
    this.options = [];
    if (includeSelectAll) {
      this.options = [{
        label: "Select All",
        value: selectAllId,
        selected: selectedOptions.includes(selectAllId) ? true : false
      }];
    }
    const options = response.data.data.map((technician) => {
      let label = `${technician.attributes.full_name}`;
      if (technician.attributes.role && technician.attributes.role !== null) {
        label = `${technician.attributes.full_name} (${technician.attributes.role})`;
      }
      return {
        label: label,
        value: (technician.id || "0"),
        selected: selectedOptions.includes(technician.id) ? true : false
      }
    });
    this.options = [...this.options, ...options];
    this.failed = false;
    this.loading = false;
  }

  @action
  onTechnicianLoadFail(error) {
    if (!axios.isCancel(error)) {
      this.loading = false;
      this.failed = true;
    }
  }

  loadTechnicians() { 
    const roles = this.props.includeAdmins ? ['admin', 'technician'] : ["technician"]
    let promise = Api.post('/users/search', { role: roles, include_unassigned: this.props.includeUnassigned })
    promise
      .then(this.onTechnicianLoad.bind(this))
      .catch(this.onTechnicianLoadFail.bind(this))
    return promise;
  }

  onChange = (_selectedOptions) => {
    let selectedOptions = [];
    if (this.props.includeSelectAll) {
      selectedOptions = this.props.selectedOptions;
      if (_selectedOptions.length > selectedOptions.length) {
        if (
          _selectedOptions.filter((option) => option.value === selectAllId)
            .length > 0 ||
          _selectedOptions.length === this.options.length - 1
        ) {
          selectedOptions = this.options.map((option) => ({
            ...option,
            selected: true,
          }));
        } else {
          selectedOptions = _selectedOptions;
        }
      } else {
        if (
          _selectedOptions.filter((option) => option.value === selectAllId)
            .length === 0 &&
          selectedOptions.filter((option) => option.value === selectAllId || option === selectAllId)
            .length > 0
        ) {
          selectedOptions = [];
        } else {
          selectedOptions = _selectedOptions.filter(
            (option) => option.value !== selectAllId
          );
        }
      }
    } else {
      selectedOptions = _selectedOptions;
    }
    if (this.props.onChange) {
      this.props.onChange(selectedOptions, this.options);
    }
  }

  render() {
    let {
      props: {
        selectedOptions,
        maximumSelections,
        shouldDismissPopover
      },
      onChange
    } = this;
    let content = '';
    if (this.failed) {
      content = (<div className="bp3-callout bp3-intent-danger bp3-icon-info-sign">
        Had some problem loading Technicians. Please close and try again!
      </div>)
    }
    if (!this.failed) {
      if (this.loading) {
        content = <div><Spinner /></div>;
      }
      if (!this.loading && this.options.length > 0) {
        content = <ListPicker
          selectedOptions={selectedOptions}
          options={this.options}
          onChange={onChange}
          maximumSelections={maximumSelections}
          shouldDismissPopover={shouldDismissPopover}
        />
      }
    }
    let tagText = "0";
    if (selectedOptions) {
      if (selectedOptions.length > 0 && selectedOptions.length === this.options.length) {
        tagText = "All";
      } else if (this.props.hideHeading && selectedOptions.length === 1) {
        tagText = selectedOptions[0].label;
      } else {
        tagText = selectedOptions.length;
      }
    }
    let heading = "Technicians";
    if (this.props.showCreatedBy) {
      heading = "Created By";
    } else if (this.props.showUser) {
      heading = "User";
    }
    return (
      <Popover
        minimal={true}
        onOpening={this.loadTechnicians.bind(this)}
        content={content}
        position={Position.BOTTOM_LEFT}
        usePortal={true}
        className="actionItem"
      >
        <Button rightIcon="caret-down">
          {!this.props.hideHeading && heading}
          <span>&nbsp;</span>
          <Tag minimal={true} round={true}>
            {tagText}
          </Tag>
        </Button>
      </Popover>
    );
  }
}

TechnicianPicker.propTypes = {
  selectedOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func,
  includeUnassigned: PropTypes.bool,
  includeSelectAll: PropTypes.bool,
  shouldDismissPopover: PropTypes.bool
}

export default TechnicianPicker;