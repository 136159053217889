import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

@inject('userStore') @observer
class Invite extends Component {

  removeUser(userIndex){
    let { users } = this.props.userStore;
    users.splice(userIndex, 1);
  }

  render() {
    let { user } = this.props;
    return (
      <div className={`row input-wrap userInvitation ${this.props.error ? 'has-error' : ''}`}>
        <div className="col-sm-3">
          <input 
            type='text' 
            className='form-control-outline' 
            placeholder='First Name'
            defaultValue={user.first_name}
            onChange={(event) => {user.first_name = event.target.value}} 
          />
        </div>
        <div className="col-sm-3">
          <input 
            type='text' 
            className='form-control-outline' 
            placeholder='Last Name'
            defaultValue={user.last_name}
            onChange={(event) => {user.last_name = event.target.value}} 
          />
        </div>
        <div className="col-sm-3">
          <input 
            type='email' 
            className='form-control-outline' 
            placeholder='Email'
            defaultValue={user.email}
            onChange={(event) => {user.email = event.target.value}} 
          />
        </div>
        <div className="col-sm-3">
          <select className='form-control-outline' defaultValue={user.role} onChange={(event) => {user.role = event.target.value} }>
            <option value="" disabled>Select role</option>
            <option value="admin">Admin</option> 
            <option value="technician">Technician</option>
          </select>
        </div>
        {
          this.props.error &&
            <div className="col-sm-6 invitation-error-message">
              { this.props.error }
            </div>
        }
        <div className={`${this.props.error ? 'col-sm-6' : 'col-sm-12'} text-right`}>
          <a className="removeUser" onClick={this.removeUser.bind(this, this.props.userIndex)}>x Remove</a>
        </div>
      </div>
    )
  }

}

export default Invite;