import React, { Component } from 'react';
import { inject } from 'mobx-react';

@inject('store', 'workReportStore')
class WorkReportSignatureSection extends Component {
  render() {
    let {
      workReportStore: {
        workReport: {
          object: {
            attributes: {
              signatures: {
                client_signature,
                tech_signature
              }
            }
          }
        }
      }
    } = this.props;
    if (!client_signature) {
      return (
        <div style={{ padding: '0px 10px' }}>
          <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
            No signatures were captured on this work order.
          </div>
        </div>
      )
    }
    return (
      <div style={{display:'inline-flex',justifyContent:"space-between", width:'100%'}}>
        <div style={{ paddingLeft: '10px',display:'flex',flexDirection:'column'  }}>
          <span>
            <strong>Customer Signature:</strong>
          </span>
          <img
            style={{ width: 200 }}
            alt="Signature"
            src={client_signature.asset.thumb.url} />
        </div>
        
        <div style={{ paddingLeft: '10px',display:'flex',flexDirection:'column' }}>
          <span  style={{ marginRight:10}}>
            <strong >Technician Signature:</strong>
          </span>
          {tech_signature && 
            <img
              style={{ width: 200, marginRight:10}}
              alt="Signature"
              src={tech_signature.asset.thumb.url} />
          }
        </div>
      </div>

    )
  }
}
export default WorkReportSignatureSection;