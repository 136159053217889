import React from 'react';
import { Spinner, Alert, Button, Intent } from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import qs from 'qs';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';

@inject('viewStore','equipmentStore') @observer
class RetiredEquipmentsTable extends React.Component {
  @observable isSelectDialogVisible = false;
  @observable isSelectId;
 
  handleSelectCancel() {
    this.isSelectDialogVisible = false;
  }

  handleSelectConfirm(id) {
    this.isSelectDialogVisible = true; 
    this.isSelectId = id;   
  }

  handleSaveConfirm() {
    let id = this.isSelectId;
    this.isSelectDialogVisible = false; 
  }  

  onPaginationChange(newPage) {
    let {
      viewStore: {
        equipmentSearchWorkReport
      }
    } = this.props;
    this.props.viewStore.equipmentSearchWorkReport = {
      ...equipmentSearchWorkReport,
      page: newPage
    };
    this.props.equipmentStore.loadEquipmentsForWorkReports(); 
  }

  render() {
    let {objects, meta, isLoading} = this.props;

    if (isLoading) {
      return(
        <div className="">
          <Spinner size='25' />
        </div>
      ) 
    }
    
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No equipment have been added for this client yet.
        </div>
      )
    }
    return (
      <div className="table-responsive">
        <table className='table table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>             
              <th>Serial</th>            
              <th>Make</th>
              <th>Model</th>
              <th width="20%">Equipment Location</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {
              objects && objects.map((equipment) => {
                if(equipment.attributes.active == false){
                  return (
                    <tr key={equipment.id}>                      
                      <td>
                        <p className='primary mb-0'>{equipment.attributes.serial_number}</p>
                      </td>
                      <td>
                        <p className="primary mb-0">{equipment.attributes.make}</p>
                      </td>
                      <td>
                        <p className="secondary mb-0">{equipment.attributes.eq_model}</p>
                      </td>
                      <td>
                        <p className='secondary mb-0'>{equipment.attributes.eq_location}</p>
                      </td>                      
                      <td>
                        <p className='secondary mb-0'> {equipment.attributes.description}</p>
                      </td>
                    </tr>
                  )
                }
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>        
      </div>
    )
  }
}

export default RetiredEquipmentsTable;