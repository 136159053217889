import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PaymentGrid, Card } from 'components/Styles';
import InvoiceHeader from './InvoiceHeader';
import InvoiceLineItems from './InvoiceLineItems';

@inject('paymentStore') @observer
class PaymentInvoiceBody extends Component {

  render() {
    return(
      <PaymentGrid>
        <div className="payment-wraper">        
          <Card>
            <InvoiceHeader />
            <br/>
            <InvoiceLineItems />
          </Card>        
        </div>
      </PaymentGrid>
    )
  }

}

export default PaymentInvoiceBody;