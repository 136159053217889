import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  FormGroup, InputGroup,
  Button,
  Dialog,
  Classes,
  Intent,
  TextArea
} from "@blueprintjs/core";
import Toast from "helpers/toaster";
import { observable } from "mobx";
import moment from 'moment';
import DateTimeInput from 'components/DateTimeInput';
import { currency, currencySymbol } from 'helpers/currency';

@inject("viewStore", "invoiceStore")
@observer
class ManualTransactionModal extends Component {

  @observable transaction = {
    amount_paid: "0",
    transaction_date: moment()._d,
    payment_method: "Cash",
    notes: "",
    description: ""
  }

  componentDidMount() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    if(object && object.attributes && object.attributes) {
      this.transaction.amount_paid = object.attributes.due_amount ? object.attributes.due_amount.toString() : "0";
      this.transaction.invoice_id = object.attributes.id;
    }
  }

  handleManualTransactionDailog() {
    this.props.onClose();
  }

  onTransactionDateChange(date) {
    let {
      transaction
    } = this;
    transaction.transaction_date = date
  }

  processTransaction() {
    let {
      transaction,
      props: {
        invoiceStore,
        invoiceStore: {
          invoice: {
            object: {
              attributes
            }
          }
        }
      }
    } = this;

    if(!transaction.amount_paid) {
      Toast.show({
        icon: "warning-sign",
        message: "Please enter valid amount.",
        intent: Intent.DANGER
      });
      return;
    }

    if(parseFloat(transaction.amount_paid) < 0) {
      Toast.show({
        icon: "warning-sign",
        message: "Amount must be positive value.",
        intent: Intent.DANGER
      });
      return;
    }

    if(parseFloat(transaction.amount_paid) > parseFloat(attributes.due_amount)) {
      Toast.show({
        icon: "warning-sign",
        message: "Amount cannot be paid more than amount due.",
        intent: Intent.DANGER
      });
      return;
    }

    // if(!transaction.notes) {
    //   Toast.show({
    //     icon: "warning-sign",
    //     message: "Please enter some notes.",
    //     intent: Intent.DANGER
    //   });
    //   return;
    // }

    // if(!transaction.description) {
    //   Toast.show({
    //     icon: "warning-sign",
    //     message: "Please enter some description.",
    //     intent: Intent.DANGER
    //   });
    //   return;
    // }
    transaction.invoice_id = attributes.id;
    invoiceStore.makeManualTransaction(transaction)
      .then(this.onSuccessfulTransaction.bind(this))
      .catch(this.onFailTransaction.bind(this))
  }

  onSuccessfulTransaction(response) {
    this.props.onSuccess();
    Toast.show({
      icon: "thumbs-up",
      message: "Manual transaction added successfully!",
      intent: Intent.SUCCESS
    });
  }

  onFailTransaction(error) {
    Toast.show({
      icon: "warning-sign",
      message: "Unable to add manual transaction!",
      intent: Intent.DANGER
    });
  }

  handleOpenedEvent() {
    const {
      transaction,
      props: {
        invoiceStore: {
          invoice: {
            object
          }
        }
      }
    } = this;
    if(object && object.attributes && object.attributes.due_amount) {
      transaction.amount_paid = object.attributes.due_amount;
    }
  }

  render() {
    const {
      transaction,
      props: {
        invoiceStore: {
          showManualTransactionLoader,
          invoice: {
            object
          }
        }
      }
    } = this;
    return(
      <Dialog
        title="Add Manual Transaction"
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
        onOpened={this.handleOpenedEvent.bind(this)}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="transaction-type">
            <div className="interac">
              <img src="/interac-eTransfer.svg" width="50" />
              <span>Cash/Interac</span>
            </div>
            <div className="interac-details">
              <FormGroup
                label={`Amount(${currency()}${currencySymbol()})`}
              >
                <InputGroup 
                  type="text-input"
                  placeholder={`${object && object.attributes && object.attributes.currency_symbol}${transaction.amount_paid}`}
                  defaultValue={transaction.amount_paid}
                  value={transaction.amount_paid}
                  onChange={(event) => {transaction.amount_paid = event.target.value}}
                />
              </FormGroup>

              <FormGroup>
                <div className="fullWidth">
                  <DateTimeInput 
                    placeholder={'Transaction Date'}
                    defaultValue={moment(transaction.transaction_date)}
                    onChange={this.onTransactionDateChange.bind(this)}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <TextArea 
                  className="notes" 
                  type="text-input" 
                  placeholder="Notes" 
                  onChange={(event) => {transaction.notes = event.target.value}}
                />
              </FormGroup>

              <FormGroup>
                <TextArea 
                  className="notes" 
                  type="text-input" 
                  placeholder="Description" 
                  onChange={(event) => {transaction.description = event.target.value}}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.handleManualTransactionDailog.bind(this)}
              style={{ width: "100px" }}
            >
              Close
            </Button>
            <Button 
              className="bp3-large bp3-intent-primary"
              onClick={this.processTransaction.bind(this)}
              loading={showManualTransactionLoader}
              disabled={!transaction.amount_paid ? true : false}
            >
              Pay - {object && object.attributes && object.attributes.currency_symbol}{transaction.amount_paid}
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default ManualTransactionModal;