import React from 'react'
import { Button, Popover, Position, Tag, Spinner } from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer, inject } from "mobx-react";
import ListPicker from 'components/ListPicker';
import PropTypes from 'prop-types';
import { Api } from 'services/Api';
import _ from 'lodash';

const selectAllId = "selectAll";
@observer
class ServiceTypePicker extends React.Component {

  @observable loading = true;
  @observable failed = false;
  @observable options = [];
  @observable selectedOptions = [];

  componentDidMount() {
    if(this.props.selectedOptions) {
      this.selectedOptions = this.props.selectedOptions;
    }

    if(this.props.selectedServiceTypeId) {
      let promise = Api.get(`/service_types/${this.props.selectedServiceTypeId}`)
      promise.then(response => {
        this.selectedOptions = [{
          label: response.data.data.attributes.name,
          selected: true,
          value: response.data.data.attributes.id
        }]
      })
    }
  } 

  // To check if array of objects has property
  checkArrayObjectsHasProperty(array, property) {
    let found_property = false
    _.map(array, function(obj) {
      if(_.has(obj, property)) {
        found_property = true;
      }
    })
    return found_property;
  }

  @action
  onServiceTypesLoad(response) {
    this.options = [];
    if (this.checkArrayObjectsHasProperty(this.selectedOptions, 'label')) {
      if (this.props.includeSelectAll) {
        this.options = [{
          label: 'Select All',
          value: selectAllId,
          selected: this.selectedOptions.filter((selectedOption) => selectAllId === selectedOption.value).length > 0 ? true : false
        }];
      }
      const options = response.data.map((serviceType) =>
      ({
        label: serviceType.name,
        value: serviceType.id,
        selected: this.selectedOptions.filter((selectedOption) => serviceType.id === selectedOption.value).length > 0 ? true : false
      })
      );
      this.options = [...this.options, ...options];
    } else {
      if (this.props.includeSelectAll) {
        this.options = [{
          label: 'Select All',
          value: selectAllId,
          selected: this.selectedOptions.filter((selectedOption) => selectAllId === selectedOption).length > 0 ? true : false
        }];
      }
      const options = response.data.map((serviceType) =>
      ({
        label: serviceType.name,
        value: serviceType.id,
        selected: this.selectedOptions.filter((selectedOption) => serviceType.id === selectedOption).length > 0 ? true : false
      })
      );
      this.options = [...this.options, ...options];
    }
    this.failed = false;
    this.loading = false;
  }

  @action
  onServiceTypesLoadFail(error) {
    this.loading = false;
    this.failed = true;
  }

  loadServiceTypes() {
    let promise = Api.get('/service_types')
    promise
      .then(this.onServiceTypesLoad.bind(this))
      .catch(this.onServiceTypesLoadFail.bind(this))
    return promise;
  }

  onChange = (selectedOptions) => {
    if (this.props.includeSelectAll) {
      if (selectedOptions.length > this.selectedOptions.length) {
        if (
          selectedOptions.filter((option) => option.value === selectAllId)
            .length > 0 ||
          selectedOptions.length === this.options.length - 1
        ) {
          this.selectedOptions = this.options.map((option) => ({
            ...option,
            selected: true,
          }));
        } else {
          this.selectedOptions = selectedOptions;
        }
      } else {
        if (
          selectedOptions.filter((option) => option.value === selectAllId)
            .length === 0 &&
          this.selectedOptions.filter((option) => option.value === selectAllId)
            .length > 0
        ) {
          this.selectedOptions = [];
        } else {
          this.selectedOptions = selectedOptions.filter(
            (option) => option.value !== selectAllId
          );
        }
      }
    } else {
      this.selectedOptions = selectedOptions;
    }
    if (this.props.onChange) {
      this.props.onChange(this.selectedOptions, this.options);
    }
  }

  render() {
    let {
      onChange,
      props: {
        customindex,
        maximumSelections
      }
    } = this;
    let content = '';
   
    // this.options.map(function(value,key){
    //   if(value.value === customindex) {
    //     value.selected = true;
    //   } else {
    //     value.selected = false;
    //   }
    //   return value.selected;
    // });
    
    if (this.failed) {
      content = (<div className="bp3-callout bp3-intent-danger bp3-icon-info-sign">
        Had some problem loading the Service Types. Please close and try again!
      </div>)
    }
    if (!this.failed) {
      if (this.loading) {
        content = <div><Spinner /></div>;
      }
      if (!this.loading && this.options.length > 0) {
        content = <ListPicker
                    selectedOptions={this.selectedOptions}
                    options={this.options}
                    onChange={onChange}
                    maximumSelections={maximumSelections}
                  />
      }
    }
    let label = this.selectedOptions.length || 0;
    if (this.selectedOptions.length === 1 && this.selectedOptions[0].label) {
      label = this.selectedOptions[0].label;
    } else if (this.selectedOptions.length > 0 && this.selectedOptions.length === this.options.length) {
      label = "All";
    }

    return(
      <Popover
        minimal={true}
        onOpening={this.loadServiceTypes.bind(this)}
        content={content}
        position={Position.BOTTOM_LEFT}
        usePortal={true} 
        className="actionItem"
      >
        <Button rightIcon='caret-down'>
          Service Type
          <span>&nbsp;</span>
          <Tag minimal={true} round={true}>{label}</Tag>
        </Button>
      </Popover>
    )
  }
}

ServiceTypePicker.propTypes = {
  selectedOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func,
  maximumSelections: PropTypes.number,
  includeSelectAll: PropTypes.bool
}

export default ServiceTypePicker;