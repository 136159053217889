import React, { Component } from 'react';
import { 
  Divider,
  Button,
  FormGroup,
  InputGroup,
  Intent
} from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import Avatar from '@atlaskit/avatar';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Inputmask from 'inputmask';
import TableHeading from 'components/TableHeading';
import VacationsTable from 'components/User/VacationsTable';
import * as EmailValidator from 'email-validator';
import { Tab, Tabs } from "@blueprintjs/core";
import { Api } from 'services/Api';
import { observable, action } from 'mobx';
import Toast from 'helpers/toaster';
import _ from 'lodash'
import { router } from '../../App';

const UserGrid = styled('div')`
  width: 768px;
`

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*0}px`};
`

class UserVacationsTab extends Component {
  render() {
    let { user } = this.props;
    return(
      <React.Fragment>
        <VacationsTable user={user} />
      </React.Fragment>
    )
  }
}

@inject('userStore','jobStore','viewStore','invoiceStore') @observer
class UserProfileCard extends Component {
  @observable leftSideBarCount = {
    Objects: []
  }

  componentDidMount() {
    Inputmask({ mask: '999-999-9999[999]', skipOptionalPartCharacter: " ", clearMaskOnLostFocus: true, greedy: false }).mask('#phone');
    Inputmask({ alias: 'email' }).mask('#email');
    let { user } = this.props;
    let promise = Api.post('/work_reports/search', { 
      search: { 
        where: {
          created_by_id: user.attributes.id,         
        },
        order: { created_at: 'desc' },
        page: 1,
        per_page: 10
      }
    });
    promise
    .then((response) => {   
      this.leftSideBarCount = {
        Objects: response.data.data
      }
    })     
  }

  
  changeTableData = (resource) => {
    if (resource) {
      this.props.onResourceChange(resource);
    }
  }

  resourceData = () => {
    let { user, selectedResource } = this.props;
    if(selectedResource === 'vacations'){
      return(
        <React.Fragment>
          <TableHeading heading="VACATIONS" />
          <VacationsTable user={user} />
        </React.Fragment>
      )
    }
  }

  handleUserChange(event, key) {
    this.props.user.attributes[key] = event.target.value;
  }

  updateUser() {
    if(this.props.user.attributes.first_name === '') {
      Toast.show({ icon: 'warning-sign', message: 'Please enter first name!', intent: Intent.DANGER })
      return;
    }
    if(this.props.user.attributes.last_name === '') {
      Toast.show({ icon: 'warning-sign', message: 'Please enter last name!', intent: Intent.DANGER })
      return;
    }
    if(this.props.user.attributes.phone === '') {
      Toast.show({ icon: 'warning-sign', message: 'Please enter phone!', intent: Intent.DANGER })
      return;
    }
    if (EmailValidator.validate(this.props.user.attributes.email)) {
      this.props.userStore.updateUser(this.props.user)
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid email!', intent: Intent.DANGER })
    }
  }

  deactivateUser(user) {
    this.props.userStore.deactivateUser(user)
      .then((response) => {
        Toast.show({ icon: 'thumbs-up', message: 'User deactivated successfully', intent: Intent.SUCCESS })
      })
      .catch((error) => {
        if(error.response && error.response.data && error.response.data.errors) {
          Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ icon: 'warning-sign', message: 'Something went wrong, unable to make this user account inactive', intent: Intent.DANGER })
        }
      })
  }

  activateUser(user) {
    this.props.userStore.activateUser(user)
      .then((response) => {
        Toast.show({ icon: 'thumbs-up', message: 'User activated successfully', intent: Intent.SUCCESS })
      })
      .catch((error) => {
        if(error.response && error.response.data && error.response.data.errors) {
          Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ icon: 'warning-sign', message: 'Something went wrong, failed to activate user', intent: Intent.DANGER })
        }
      })
  }

  render() {
    let {
      props: {  
        user,  
        jobStore, 
        viewStore,
        viewStore: {
          currentUser
        },     
        jobStore:{         
          job :{
            object
          },
        },
        userStore: {
          isDeactivateLoading
        }
      }
    } = this; 
    const jobLength = _.size(object);

    let { Objects } = this.leftSideBarCount;

    var jobWorkReportsCount = [];
    var jobEstimatesCount = [];
    var jobInvoiceCount = [];
    Objects && Objects.map((attribute, index) => {      
      if(attribute.attributes.kind === 'WorkReport'){
        jobWorkReportsCount.push(attribute.attributes);
      }
      if(attribute.attributes.kind === 'Quote'){
        jobEstimatesCount.push(attribute.attributes);
      }
      if(attribute.attributes.kind === 'Invoice'){
        jobInvoiceCount.push(attribute.attributes);
      }
    })
   
    return(
      <div style={{width: '100%', height:'calc(100vh - 100px)', overflow:'hidden'}}>        
        <div className="page-wrap">       
          <div className="row">
            <div className="col-sm-4">
              <div className="card position-sticky" style={{top:'0'}}>
                <div className="card-body">
                  <div className="profile-avtar">
                    <img
                      src={user.attributes.avatar_url}
                      alt=""
                      width="100%"
                    />
                  </div> 
                  <div className="details-wrap text-center">
                    <h4>{user.attributes.full_name}</h4>
                    <p>{user.attributes.role}</p>
                  </div>
                  <ul className="list-group profile-sidebar">
                    <li  onClick={() => { viewStore.jobsForCustomer(user.attributes.id) }} className="list-group-item d-flex justify-content-between align-items-center">
                      Jobs
                      <span className="badge badge-primary badge-pill">{jobLength}</span>
                    </li>
                    <li 
                      onClick={() => { viewStore.workReportsForCustomer(user.attributes.id) }}                     
                      active={(viewStore.currentView === 'Pages.WorkReports.Index' || viewStore.currentView === 'Pages.WorkReports.Show')} 
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Work Orders
                      <span className="badge badge-primary badge-pill">{jobWorkReportsCount.length}</span>
                    </li> 
                    <li onClick={() => { viewStore.invoicesForCustomer(user.attributes.id) }} active={viewStore.currentView === 'Pages.Invoices.Index'} className="list-group-item d-flex justify-content-between align-items-center">
                      Invoices
                      <span className="badge badge-primary badge-pill">{jobInvoiceCount.length}</span>
                    </li> 
                    <li onClick={() => { viewStore.quotesForCustomer(user.attributes.id) }} active={viewStore.currentView === 'Pages.Quotes.Index'} className="list-group-item d-flex justify-content-between align-items-center">
                      Estimates
                      <span className="badge badge-primary badge-pill">{jobEstimatesCount.length}</span>
                    </li>                 
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="card">
                <div className="card-body">
                  {/* <hr /> */}
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <FormGroup label="First Name (*)">
                        <InputGroup placeholder="First Name" defaultValue={user.attributes.first_name} onChange={(event) => { this.handleUserChange(event, 'first_name') }} />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup label="Last Name (*)">
                        <InputGroup placeholder="Last Name" defaultValue={user.attributes.last_name} onChange={(event) => { this.handleUserChange(event, 'last_name') }} />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <FormGroup label="Phone (*)">
                        <InputGroup placeholder="Phone" defaultValue={user.attributes.phone} id='phone' onChange={(event) => { this.handleUserChange(event, 'phone') }} />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup label="Email (*)">
                        <InputGroup placeholder="Email" defaultValue={user.attributes.email} id='email' onChange={(event) => { this.handleUserChange(event, 'email') }} />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <FormGroup label="Enable Invoices on Mobile">
                        <div className="bp3-select bp3-fill">
                          <select 
                            defaultValue={user.attributes.enable_invoices_on_mobile} 
                            onChange={(event) => { user.attributes.enable_invoices_on_mobile = event.target.value }}
                            style={{minHeight: 38}}>
                            <option value='' disabled='true'>Select option..</option>
                            <option value='true'>Yes</option>
                            <option value='false'>No</option>
                          </select>
                        </div>  
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup label="Enable QuickBooks Sync on Mobile">
                        <div className="bp3-select bp3-fill">
                          <select 
                            defaultValue={user.attributes.enable_quickbooks_sync} 
                            onChange={(event) => { user.attributes.enable_quickbooks_sync = event.target.value }}
                            style={{minHeight: 38}}>
                            <option value='' disabled='true'>Select option..</option>
                            <option value='true'>Yes</option>
                            <option value='false'>No</option>
                          </select>
                        </div>  
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <FormGroup label={`Enable "Send invoice link" on Mobile`}>
                        <div className="bp3-select bp3-fill">
                          <select 
                            defaultValue={user.attributes.enable_invoice_sent_link} 
                            onChange={(event) => { user.attributes.enable_invoice_sent_link = event.target.value }}
                            style={{minHeight: 38}}>
                            <option value='' disabled='true'>Select option..</option>
                            <option value='true'>Yes</option>
                            <option value='false'>No</option>
                          </select>
                        </div>  
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup label="Enable display unit prices on Mobile">
                        <div className="bp3-select bp3-fill">
                          <select 
                            defaultValue={user.attributes.enable_display_unit_prices} 
                            onChange={(event) => { user.attributes.enable_display_unit_prices = event.target.value }}
                            style={{minHeight: 38}}>
                            <option value='' disabled='true'>Select option..</option>
                            <option value='true'>Yes</option>
                            <option value='false'>No</option>
                          </select>
                        </div>  
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <FormGroup label={`Enable "Send Work Order link" on Mobile`}>
                        <div className="bp3-select bp3-fill">
                          <select 
                            defaultValue={user.attributes.enable_work_report_sent_link} 
                            onChange={(event) => { user.attributes.enable_work_report_sent_link = event.target.value }}
                            style={{minHeight: 38}}>
                            <option value='' disabled='true'>Select option..</option>
                            <option value='true'>Yes</option>
                            <option value='false'>No</option>
                          </select>
                        </div>  
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup label={`Enable "Send Estimate link" on Mobile`}>
                        <div className="bp3-select bp3-fill">
                          <select 
                            defaultValue={user.attributes.enable_estimate_sent_link} 
                            onChange={(event) => { user.attributes.enable_estimate_sent_link = event.target.value }}
                            style={{minHeight: 38}}>
                            <option value='' disabled='true'>Select option..</option>
                            <option value='true'>Yes</option>
                            <option value='false'>No</option>
                          </select>
                        </div>  
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <Button icon="floppy-disk" text="Update Details" onClick={this.updateUser.bind(this)} />
                    </div>
                    <div className="col-md-6">
                      { currentUser.attributes.id !== user.attributes.id && user.attributes.is_active && <Button icon="disable" text="Deactivate" intent={Intent.DANGER} onClick={() => {this.deactivateUser(user)}} loading={isDeactivateLoading} /> }
                      { currentUser.attributes.id !== user.attributes.id && !user.attributes.is_active && <Button icon="tick-circle" text="Activate" intent={Intent.SUCCESS} onClick={() => {this.activateUser(user)}} loading={isDeactivateLoading} /> }
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <VacationsTable user={user} />
              </div>
            </div>
          </div>
        </div>
      </div>  
    )
  }
}

export default UserProfileCard;
