import React, { Component } from 'react';
import {
  Button, Intent
} from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Toast from 'helpers/toaster';
import { Helmet } from 'react-helmet';
import { router } from '../App';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import { TopNavbar } from 'components/Styles';
import * as EmailValidator from 'email-validator';
import FooterIllustration from "components/FooterIllustration";
import MainSiteLogo from "../components/MainSiteLogo";

@inject("viewStore")
@observer
class ForgotPassword extends Component {


  @observable isDisabled = true;
  @observable showLoader = false;
  @observable user = {
    email: ""
  };

  handleEmailChange(event) {
    this.user.email = event.target.value
    this.validateEmail();
  }

  validateEmail() {
    if (EmailValidator.validate(this.user.email)) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  _handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.forgotPassword();
    }
  }

  forgotPassword() {
    const {
      props: { viewStore }
    } = this;
    this.showLoader = true;
    if(this.user.email === '' || this.user.email === null){
      this.showLoader = false;
      Toast.show({ icon: 'warning-sign', message: 'Please enter email.', intent: Intent.DANGER });
      return;
    }
    viewStore
      .performForgetPassword(this.user)
      .then(response => {
        this.showLoader = false;
        Toast.show({ icon: 'thumbs-up', message: "Email sent with instructions to reset your password", intent: Intent.SUCCESS });
      })
      .catch(error => {
        this.showLoader = false;
        if (error.response.data) {
          Toast.show({
            icon: "warning-sign",
            message: error.response.data.error,
            intent: Intent.DANGER
          });
        } else {
          Toast.show({
            icon: "warning-sign",
            message: "Unable to perfom forget password",
            intent: Intent.DANGER
          });
        }
      });
  }

  render() {
    const {
      props: { viewStore }
    } = this;
    return (
      <UnauthenticatedPage>
        <Helmet>
          <title>Forgot Password | FieldNexus</title>
        </Helmet>
        <TopNavbar>
          <div className="tp-nav">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-8">
                  <MainSiteLogo />
                </div>
                <div className="col-4 text-right">
                  <div className="right-link-tp">
                    <a onClick={() => viewStore.signup()}>Signup</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TopNavbar>


        <div className="form-wrap" style={{ marginTop: '100px' }}>
          <p>Forget Password?</p>
          <h3 className="login-heading">Lets recover your account.</h3>
          <div className="row input-wrap">
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                value={this.user.email}
                onChange={this.handleEmailChange.bind(this)}
                required
                onKeyPress={this._handleKeyPress.bind(this)}
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-6 fg-pass">
              <a
                onClick={() => {
                  router.navigate("/login");
                }}
              >
                Go Back
              </a>
            </div>
            <div className="col-sm-6 fg-next">
              <Button
                className="btn"
                loading={this.showLoader}
                onClick={this.forgotPassword.bind(this)}
              >
                Reset Password
              </Button>
            </div>
          </div>
        </div>

        <FooterIllustration />
      </UnauthenticatedPage>
    );
  }
}

export default ForgotPassword;
