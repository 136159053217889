import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Button, Dialog, Classes, Spinner, Intent, FormGroup
} from "@blueprintjs/core";

@inject('invoiceStore', 'quickbooksStore', 'productServiceStore') @observer
class ProductService extends Component {

  render() {
    let {
      productServiceStore: {
        productServiceLink: {
          obj,
          isLoading
        }
      },
      quickbooksStore: {
        quickbooksItems: {
          objects
        }
      },
      object
    } = this.props;
    return(
      <div className="qb-products-services">
        <FormGroup
          label={object}
          labelFor="text-input"
          labelInfo="  - Sync with QuickBooks*"
        >
          <div className="bp3-select bp3-fill">
            <select
              defaultValue={""}
              onChange={event => {
                obj[object] = event.target.value;
              }}
            >
              <option value="">Select QuickBooks Product/Service</option>
              {objects && objects.length > 0 && objects.map((item) => {
                return(
                  <option value={item.id} key={Math.random()}>{item.name}</option>
                )
              })}
            </select>
          </div>
        </FormGroup>
      </div>
    )
  }
}

export default ProductService;