import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';
import _ from 'lodash';

class XeroStore {

  @observable isLoadingCreateXeroCustomer = false;
  @observable showXeroConnectionLoader = false;

  @observable paymentMethods = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable xeroCustomers = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable xeroTaxCodes = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable xeroAccounts = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable xeroServiceStatus = {
    isLoading: false,
    isError: false,
    object: {},
    xero_services_link_required: false
  };

  @observable productServiceStatus = {
    isLoading: false,
    isError: false,
    object: {},
    product_services_link_required: false
  };

  @observable xeroItems = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable xeroItem = {
    isLoading: false,
    isError: false,
    object: {}
  };

  @action
  disconnectFromXero() {
    this.showXeroConnectionLoader = true;
    let promise = Api.get('xeros/disconnect_from_xero');

    promise
      .then((response) => {
        this.showXeroConnectionLoader = false;
      })
      .catch((error) => {
        this.showXeroConnectionLoader = false;
      })

    return promise;
  }

  @action
  createProductService() {
    this.xeroItem.isLoading = true;
    let promise = Api.post(`/xeros/create_items`, {items: this.xeroItem.object})

    promise
      .then((response) => {
        this.xeroItem.isLoading = false;
        this.loadXeroItems();
      })
      .catch((error) => {
        this.xeroItem.isLoading = false;
        this.xeroItem.isError = true;
      })
    
    return promise;
  }

  @action
  loadXeroTaxCodes() {
    this.xeroTaxCodes.isLoading = true;
    let promise = Api.get('xeros/tax_codes');

    promise
      .then((response) => {
        this.xeroTaxCodes.objects = response.data;
        this.xeroTaxCodes.isLoading = false;
        this.xeroTaxCodes.isError = false;
      })
      .catch((error) => {
        this.xeroTaxCodes.isLoading = false;
        this.xeroTaxCodes.isError = true;
      })

    return promise;

  }

  @action
  loadAccountsXero() {
    this.xeroAccounts.isLoading = true;
    let promise = Api.get(`/xeros/get_accounts`)
    
    promise
      .then((response) => {
        this.xeroAccounts.isLoading = false;
        this.xeroAccounts.isError = false;
        this.xeroAccounts.objects = response.data;
      })
      .catch((error) => {
        this.xeroAccounts.isLoading = false;
        this.xeroAccounts.isError = true;
      })

    return promise;
  }

  @action
  loadXeroItems() {
    this.xeroItems.isLoading = true;
    let promise = Api.get('xeros/products_services');

    promise
      .then((response) => {
        this.xeroItems.objects = response.data;
        this.xeroItems.isLoading = false;
        this.xeroItems.isError = false;
      })
      .catch((error) => {
        this.xeroItems.isLoading = false;
        this.xeroItems.isError = true;
      })

    return promise;

  }

  @action
  linkClientWithXeroCustomer(xeroCustomer) {
    let promise = Api.post('xeros/link_xero_customer', {invoice_id: stores.invoiceStore.invoice.object.attributes.id, xero_customer_id: xeroCustomer.ContactID});

    promise
      .then((response) => {
        // for reloading client via invoice
        stores.invoiceStore.update();
      })
      .catch((error) => {
      })
    
    return promise;
  }

  @action
  createXeroCustomer() {
    this.isLoadingCreateXeroCustomer = true;
    let promise = Api.post('xeros/create_xero_customer', {invoice_id: stores.invoiceStore.invoice.object.attributes.id});

    promise
      .then((response) => {
        this.isLoadingCreateXeroCustomer = false;
        stores.invoiceStore.update();
      })
      .catch((error) => {
        this.isLoadingCreateXeroCustomer = false;
      })
    
    return promise;
  }

  @action
  loadXeroCustomers() {
    this.xeroCustomers.isLoading = true;
    let promise = Api.get('xeros/customers');

    promise
      .then((response) => {
        this.xeroCustomers.objects = response.data;
        this.xeroCustomers.isLoading = false;
        this.xeroCustomers.isError = false;
      })
      .catch((error) => {
        this.xeroCustomers.isLoading = false;
        this.xeroCustomers.isError = true;
      })

    return promise;
    
  }

  // @action
  // loadXeroItems() {
  //   this.xeroItems.isLoading = true;
  //   let promise = Api.get('xeros/products_services');

  //   promise
  //     .then((response) => {
  //       this.xeroItems.objects = response.data;
  //       this.xeroItems.isLoading = false;
  //       this.xeroItems.isError = false;
  //     })
  //     .catch((error) => {
  //       this.xeroItems.isLoading = false;
  //       this.xeroItems.isError = true;
  //     })

  //   return promise;

  // }

  @action
  checkXeroServicesStatus(invoice) {
    this.xeroServiceStatus.isLoading = true;

    let promise = Api.post('xeros/check_xero_service_status', {invoice_id: invoice.attributes.id});

    promise
      .then((response) => {
        this.xeroServiceStatus.object = {}
        this.xeroServiceStatus.xero_services_link_required = response.data.xero_services_link_required;
        this.xeroServiceStatus.isLoading = false;
        this.xeroServiceStatus.isError = false;
      })
      .catch((error) => { 
        this.xeroServiceStatus.isLoading = false;
        this.xeroServiceStatus.isError = true;
      })

    return promise;
  }

  @action
  syncInvoiceWithXero(invoice) {
    this.productServiceStatus.isLoading = true;
    let promise = Api.post('xeros/sync_invoice', {invoice_id: invoice.attributes.id,invoice:invoice.attributes});

    promise
      .then((response) => {
        this.productServiceStatus.isLoading = false;
        stores.invoiceStore.getInvoice({uuid: invoice.attributes.id})
      })
      .catch((error) => { 
        this.productServiceStatus.isLoading = false;
      })

    return promise;
  }


  @action
  updateInvoiceWithXero(invoice) {
    this.productServiceStatus.isLoading = true;
    let promise = Api.post('xeros/sync_update_invoice', {invoice_id: invoice.attributes.id,invoice:invoice.attributes});

    promise
      .then((response) => {
        this.productServiceStatus.isLoading = false;
        stores.invoiceStore.getInvoice({uuid: invoice.attributes.id})
      })
      .catch((error) => { 
        this.productServiceStatus.isLoading = false;
      })

    return promise;
  }

  @action
  loadXeroPaymentMethods() {
    this.paymentMethods.isLoading = true;
    let promise = Api.get(`xeros/payment_methods`)

    promise
      .then((response) => {
        this.paymentMethods.isLoading = false;
        this.paymentMethods.objects = response.data.data;
      })
      .catch((error) => {
        this.paymentMethods.isLoading = false;
        this.paymentMethods.isError = true;
      })
    
    return promise
  }

};

export default XeroStore;