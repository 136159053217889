import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import SearchBars from 'components/SearchBars';
import { observer, inject } from 'mobx-react';
import { Button} from "@blueprintjs/core";
import ProjectSidebar from '../projects/project-sidebar';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'workReportStore','viewStore') @observer
class PurchaseOrders extends Component {

  componentDidMount() {
    this.props.workReportStore.loadPurchaseOrders();
  }

  render() {
    let {
      viewStore
    } = this.props;
    let { objects } = this.props.workReportStore.purchaseOrderItem;
    
    return(
      <div>
        <Helmet><title>Purchase Orders</title></Helmet>
          <div className="row no-gutters">
            <div className="col-lg-2">
              <ProjectSidebar />
            </div>
            <div className="col-lg-10"> 
              <PageWrapGray>
                <SearchBars.PurchaseOrders />
                <div className="workorder">
              <div className="work-order-dashboard">
                <div className="order-list-table">
                  <div className="table-responsive">
                    {objects.length === 0 && <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
                      <h4 className="bp3-heading">No Results</h4>
                      Your search criteria returned no results. Please modify/expand your search parameters to see results.
                    </div>}
                    {objects.length > 0 && <table className="table table-vertical-align table-data-presentation">
                      <thead>
                        <tr>
                          {/* <th>Select</th>                     */}
                          <th>Supplier</th>                    
                          <th>PO Number</th>
                          <th className="item-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      { objects && 
                        objects.map((purchaseOrder) => {
                          if(purchaseOrder.attributes.purchase_order_number){
                            return (
                              <tr key={Math.random()}>
                                <td>
                                  <TableCellWithSubtext
                                    mainLink={`/dashboard/suppliers/${purchaseOrder.attributes.supplier.id}/show`}
                                    mainText={purchaseOrder.attributes.supplier.name}
                                  />
                                </td>                          
                                <td>
                                  <TableCellWithSubtext
                                    mainLink={`/dashboard/purchase-orders/${purchaseOrder.attributes.id}/show-purchase-order`}
                                    mainText={purchaseOrder.attributes.purchase_order_number}
                                  />
                                </td>
                                <td className="item-center">
                                  <div className="action-buttons" minimal={true}>
                                    <Button minimal={true} small={true} onClick={() => viewStore.ShowPurchaseOrder({uuid: purchaseOrder.id})}>
                                      <img src="/edit-light-icon.svg" alt={'edit icon'}/>
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            )
                          }
                        })
                      }
                      </tbody>
                    </table>}
                  </div>
                </div>
              </div>
            <div>
          </div>
        </div> 
      </PageWrapGray>       
            </div>
          </div>
          
      </div>
    )
  }

};

export default PurchaseOrders;