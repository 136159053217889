import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <nav className="bp3-navbar search">
        <div>
          {this.props.children}
        </div>
      </nav>
    )
  }
};

export default Footer;