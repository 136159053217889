import React from 'react';
import { Spinner, Alert, Button, Intent } from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import qs from 'qs';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
import _ from 'lodash';

@inject('store','viewStore','invoiceStore','workReportStore','quoteStore','productServiceStore') @observer
class ProductAndServiceDetailsTable extends React.Component {
  
  @observable isSelectDialogVisible = false;
  @observable isDeleteDialogVisible = false;
  @observable isSelectId;
  @observable isSelectMaterial;
  @observable showLoader = false;  
 
  handleSelectCancel() {
    this.isSelectDialogVisible = false;
    this.showLoader = false;
  }

  handleSelectConfirm(product) {
    this.isSelectDialogVisible = true; 
    this.isSelectId = product.id;  
    this.isSelectMaterial = product;      
  }

  handleSaveConfirm() { 
    let {type, formType} = this.props; 
    let id = this.isSelectId;
    let material = this.isSelectMaterial;
    this.isSelectDialogVisible = false;
    let {workReportId} = this.props;     
    this.showLoader = true;

    let service_type = 'material';
    if(type === 'Service'){
      service_type = 'labour';
    }
    if(formType && formType === 'Invoice'){
      this.props.invoiceStore.addLineItem(material,workReportId.id,service_type)
      .then(this.onSuccessfulCreateMaterial.bind(this))
      .catch(this.onFailCreateMaterial.bind(this));
    }else if(formType && formType === 'Quote'){
      this.props.quoteStore.addLineItem(material,workReportId.id,service_type)
      .then(this.onSuccessfulCreateMaterial.bind(this))
      .catch(this.onFailCreateMaterial.bind(this));
    }else{
      this.props.workReportStore.addLineItem(material,workReportId.id,service_type)
      .then(this.onSuccessfulCreateMaterial.bind(this))
      .catch(this.onFailCreateMaterial.bind(this));
    }
    
      
  }

  onSuccessfulCreateMaterial(response) {
    this.props.store.isOpenAddMaterialForWorkReportDialog = false;
    this.props.store.isOpenAddLaborForWorkReportDialog = false;
    this.props.store.isOpenAddMaterialForQuoteDialog = false;
    this.props.store.isOpenAddLaborForQuoteDialog = false;
    this.props.store.isOpenAddMaterialForInvoiceDialog = false;
    this.props.store.isOpenAddLaborForInvoiceDialog = false;
    this.showLoader = false;
    Toast.show({ icon: 'thumbs-up', message: 'Product/services added successfully.', intent: Intent.SUCCESS })
  }

  onFailCreateMaterial(error) {
    this.showLoader = false;
    Toast.show({ icon: 'warning-sign', message: 'Problem in adding product/services!', intent: Intent.DANGER })
  } 

  
  render() {
    let {product, valueInWorkReportMaterial, servicedMaterial, type, formType} = this.props;   
    return (
      <React.Fragment>
        <tr key={product.id} >          
          <td>
            <p className='primary mb-0'>{product.attributes.name}</p>
          </td>
          <td>
            <p className="primary mb-0">{product.attributes.description}</p>
          </td>
          {
            type != 'Service' ?
            <td>
              <p className="secondary mb-0">{product.attributes.part_number}</p>
            </td>:''
          }
          
          <td>
            <p className='secondary mb-0'>{product.attributes.unit_price}</p>
          </td>
          
          <td className="item-center" style={{width:200}}>
            <Button icon={'plus'} onClick={this.handleSelectConfirm.bind(this,product)} loading={this.showLoader}>Add to Work Order</Button>
          </td>          
        </tr> 
        <Alert
          cancelButtonText="No"
          confirmButtonText="Yes"
          icon="info-sign"
          intent={Intent.PRIMARY}
          isOpen={this.isSelectDialogVisible}
          onCancel={this.handleSelectCancel.bind(this)}
          onConfirm={this.handleSaveConfirm.bind(this)}          
        >
            Are you sure to add this product/service to your current work order?         
        </Alert> 
      </React.Fragment>    
    )
  }
}

export default ProductAndServiceDetailsTable;