import React, { Component } from 'react';
import { 
  Divider, Intent,
  Button, Spinner,
  FormGroup, InputGroup,
  ControlGroup
} from '@blueprintjs/core';
import DateTimeInput from 'components/DateTimeInput';
import { observer, inject } from 'mobx-react';
import Card from 'components/Card';
import styled from '@emotion/styled';
import TableHeading from 'components/TableHeading';
import JobsTable from 'components/Project/JobsTable';
import Toast from 'helpers/toaster';
import moment from 'moment-timezone';

const ProjectGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: ${props => `${props.theme.unit*4}px`};
  align-items: flex-start;
`

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const AnalyticsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
@inject('projectStore') @observer
class ProjectProfileCard extends Component {

  componentDidMount() {
    let { project } = this.props;
    this.props.projectStore.loadAnalytics({uuid: project.attributes.id})
  }

  changeTableData = (resource) => {
    if(resource){
      this.props.onResourceChange(resource);
    }
  }

  resourceData = () => {
    let { project, selectedResource } = this.props;
    if(selectedResource === 'jobs'){
      return(
        <React.Fragment>
          <TableHeading heading="JOBS" />
          <JobsTable project={project} />
        </React.Fragment>
      )
    }
  }

  handleStartDateChange(date) {
    let { project } = this.props;
    project.attributes.start_date = moment(date);
  }

  handleEndDateChange(date) {
    let { project } = this.props;
    project.attributes.end_date = moment(date);
  }

  handleProjectChange(event, key) {
    this.props.project.attributes[key] = event.target.value;
  }

  updateProject() {
    if(this.props.project.attributes.name === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }
    this.props.projectStore.updateProject(this.props.project)
  }

  render() {
    let { 
      project,
      projectStore: {
        projectAnalytics
      }
    } = this.props;     
    return(
      <ProjectGrid>
        <Card>
          <CardContent>
            <div style={{ textAlign: 'center', paddingBottom: '8px' }}>
              <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                {project.attributes.name}
              </h4>
              <h6 className={'bp3-heading bp3-text-muted'}>
                {[project.attributes.number, project.attributes.status].join(' | ')}
              </h6>
            </div>
            <Divider style={{ margin: '16px 0px' }}></Divider>
            <FormGroup label="Name" labelInfo="*">
              <InputGroup placeholder="Name" defaultValue={project.attributes.name} onChange={(event) => { this.handleProjectChange(event, 'name') }} />
            </FormGroup>

            <FormGroup label="PO Number">
              <InputGroup placeholder="PO Number" defaultValue={project.attributes.po_number} onChange={(event) => { this.handleProjectChange(event, 'po_number') }} />
            </FormGroup>

            <FormGroup
              label="When?"
              labelFor="text-input"
              labelInfo="(*)"
            >
              {
                project.attributes.start_date && project.attributes.end_date &&
                  <ControlGroup fill={true} vertical={false}> 
                    <DateTimeInput 
                      placeholder={'Start Date'}
                      defaultValue={moment(project.attributes.start_date)} 
                      onChange={this.handleStartDateChange.bind(this)}
                    />
                    <DateTimeInput 
                      placeholder={'End Date'} 
                      defaultValue={moment(project.attributes.end_date)}
                      onChange={this.handleEndDateChange.bind(this)}
                    />
                  </ControlGroup>
              }
            </FormGroup>

            <FormGroup label="Status">
              <div className="bp3-control-group">
                <div className="bp3-select full-width">
                  <select defaultValue={project.attributes.status} onChange={(event) => { this.handleProjectChange(event, 'status') }}>
                    <option value="in_progress">In Progress</option>
                    <option value="active">Active</option>
                    <option value="completed">Completed</option>
                    <option value="invoiced">Invoiced</option>
                  </select>
                </div>
              </div>
            </FormGroup>

            <Button icon="floppy-disk" text="Update" onClick={this.updateProject.bind(this)} />
          </CardContent>
        </Card>
        <div>
          <AnalyticsContainer>
            {
              projectAnalytics.isLoading && 
                <Spinner size='25' />
            }
            {/* {
              projectAnalytics && projectAnalytics.objects && projectAnalytics.objects && projectAnalytics.objects.map((analytics) => {
                return (
                  <AnalyticsCard
                    key={Math.random()}
                    cardTitle={analytics.name}
                    count={analytics.total_count}
                    active={selectedResource === 'jobs'}
                    breakdown={analytics.breakdown || []}
                  />
                )
              })
            } */}
          </AnalyticsContainer>
          { this.resourceData() }
        </div>
      </ProjectGrid>
    )
  }
};

export default ProjectProfileCard;