import React, { Component } from 'react';
import styled from '@emotion/styled';

const StyledParent = styled('div')`
  display: flex;
  margin-top: 80px;
  justify-content: center;
`

class UnauthenticatedPage extends Component {
  render() {
    return (
      <StyledParent>
        {this.props.children}
      </StyledParent>
    )
  }
};

export default UnauthenticatedPage;