import React from 'react';
import Avatar from '@atlaskit/avatar';
import {
  Icon, Spinner
} from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { formatTime } from '../../helpers/time';

@inject('viewStore', 'jobStore') @observer
class EstimatesTable extends React.Component {

  render() {
    let { 
      jobStore: {
        jobEstimates,
        jobAssociations: {
          isLoading
        }
      } 
    } = this.props
    const {
      viewStore
    } = this.props;
    if (isLoading) {
      return <div className="spinner-center"><Spinner size='25' /></div>
    }
    if (jobEstimates.length === 0) {
      return (
        <div className="no-data-found">
          <img src="/onEstimates.svg" />
          <h3>No Estimates</h3>
          <p>No estimates have been created for this job yet.</p>
          <button className="add-new-item-btn" onClick={this.props.handleEstimateClick}>Add Estimate</button>
        </div>
      )
    }
    return (
      <div>
          {
            jobEstimates.map((estimate) => {
              return (
                <div className="tabs-data-card" key={estimate.id} onClick={() => {viewStore.ShowQuote({ uuid: estimate.attributes.id })}}>
                  <div className="card-style">
                    <div width="10px" className="customer-avatar">
                      <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=${estimate.attributes.created_by.full_name}&size=64`)} size='large' />
                    </div>
                    <div className="job-number" width="150px">
                      <p className="secondary">#{estimate.attributes.number}</p>
                      <p className="primary">By {estimate.attributes.created_by.full_name}</p>
                    </div>
		              </div>
                  <div className="job-details-status">
                    <div className="status-alignment">
                      <span className='bp3-tag bp3-minimal bp3-intent-primary'>
                        {estimate.attributes.status}
                      </span>
                    </div>
                    <div className="job-date-time">
                      {/* <p className='time-entry primary'>{this.calculateTotal(estimate)}</p> */}
                      <p className='secondary'>{ formatTime(estimate.attributes.created_at) }</p>
                    </div>
                  </div>
                  {/* <div>
                    <Icon icon="caret-right" />
                  </div> */}
                </div>
              )
            })
          }
        </div>
    )
  }
}

export default EstimatesTable;