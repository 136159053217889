import React from 'react';
import {
  Spinner, Icon
} from '@blueprintjs/core';
import Avatar from '@atlaskit/avatar';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import qs from 'qs';
import { formatTime } from '../../helpers/time'

@inject('viewStore', 'workReportStore','supplierStore') @observer
class ClientPurchaseOrdersTable extends React.Component {

  @observable jobs = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    this.props.viewStore.purchaseOrderItemSearch.where.supplier_id = this.props.supplier.attributes.id;
    this.props.workReportStore.loadPurchaseOrders();
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        purchaseOrderItemSearch
      }
    } = this.props;
    this.props.viewStore.purchaseOrderItemSearch = {
      ...purchaseOrderItemSearch,
      page: newPage
    };
    this.props.workReportStore.loadPurchaseOrders();
  }

  render() {
    let {
      workReportStore,
      workReportStore: {
        purchaseOrderItem:{
          isLoading,
          objects,
          meta
        }
      }
    } = this.props;
    let { viewStore } = this.props;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects && objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning bp3-icon-info-sign table-card">
          No purchase order have been created for this supplier yet.
        </div>
      )
    }
    return(
      <div className="card rounded-0 table-responsive">

        <table className="table hover">
          <thead>
            <tr>
              <th>Sno.</th>
              <th>Purchase Order Number</th>
              <th>Total Material</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              objects && objects.length > 0 &&
              objects.map((items,key) => {
                return (
                  <tr key={Math.random()}>
                    <td>{key+1}</td>
                    
                    <td>
                      { items.attributes.purchase_order_number
                        ?
                        <a href={`/dashboard/purchase-orders/${items.attributes.id}/show-purchase-order`} className="bp3-menu-item bp3-intent-default bp3-popover-dismiss">
                          {items.attributes.purchase_order_number}
                        </a>
                        :
                        '' 
                      }
                    </td>
                    <td>{items.attributes.material_line_items.length}</td>
                    <td>{items.attributes.status}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            { meta &&
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
            }
          </div>
        </Footer>
      </div>
    )
  }
};

export default ClientPurchaseOrdersTable;