import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Switch, Intent, Popover, Position, Spinner, Alert} from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import moment from 'moment-timezone';
import DeleteJobModal from '../Job/DeleteJobModal';
import Toast from 'helpers/toaster';
import $ from 'jquery';
import { formatTime } from 'helpers/time';

@inject('store', 'viewStore', 'jobStore', 'serviceAgreementStore') @observer
class ProfileCard extends Component {

  @observable job = {attributes:{}};
  @observable isDeleteDialogVisible = false;
  @observable untilDate = moment().toDate();
  @observable isCompleteScheduleDialogVisible = false;
  @observable isActivateScheduleDialogVisible = false;
  @observable status = "";

  componentDidMount() {
    this.untilDate = moment().toDate();
  }

  startEditServiceAgreement() {
    let {
      serviceAgreementStore: {
        serviceAgreement: {
          object
        }
      }
    } = this.props;

    this.props.serviceAgreementStore.persistServiceAgreement = {
      object: {
        ...object.attributes,
        start_time: moment(object.attributes.start_time).toDate(),
        end_time: moment(object.attributes.end_time).toDate(),
        locations: [{
          attributes: {
            id: object.attributes.location.id,
            full_address: object.attributes.location.full_address,
            name: object.attributes.location.full_address,
            client_id: object.attributes.client_id
          }
        }],
        selectedServiceType: [
          {
            label: object.attributes.service_type.name,
            value: object.attributes.service_type.id,
            selected: true
          }
        ],
        client: {
          attributes: object.attributes.client
        }
      }
    }

    this.props.jobStore.isServiceAgreement = true;
    this.props.store.isOpen = true;
  }

  startEditJob(job) {
    let {
      serviceAgreementStore: {
        serviceAgreement
      }
    } = this.props;
    this.props.jobStore.persistJob = {
      object: {
        ...job,
        start_date: moment(job.start_date),
        end_date: moment(job.end_date),
        isServiceAgreement: true,
        service_agreement_id: serviceAgreement.object.id,
        location: {
          attributes: {
            ...job.location,
            name: job.location.full_address
          }
        },
        client: {
          attributes: job.client
        },
        selectedServiceType: [
          {
            value: job.service_type_id, 
            selected: true, 
            label: job.service_type_name
          }
        ]
      }
    }
    this.props.store.isOpen = true;
  }

  startDeletingJob(job, service_agreement_id) {
    job.isServiceAgreement = true;
    job.service_agreement_id = service_agreement_id;
    this.job = {attributes: job}; 
    this.isDeleteDialogVisible = true;
  }

  handleOnClose() {
    this.isDeleteDialogVisible = false;
  }

  generateFutureJobs() {
    var no_of_jobs = $('#future_jobs').val();
    if(!no_of_jobs || parseInt(no_of_jobs) < 1) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter how many future jobs you want to generate.', intent: Intent.DANGER })
      return;
    }

    let {
      serviceAgreementStore: {
        serviceAgreement: {
          object
        }
      }
    } = this.props;

    this.props.jobStore.generateFutureJobs(no_of_jobs, object.attributes.id)
      .then(this.onSuccessfulGenerateFutureJobs.bind(this))
      .catch(this.onFailGenerateFutureJobs.bind(this))
  }

  onSuccessfulGenerateFutureJobs(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Future jobs generated successfully!', intent: Intent.SUCCESS })
  }

  onFailGenerateFutureJobs(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong. Failed to generate future jobs.', intent: Intent.DANGER })
    }
  }

  datePicker(date) {
    return (
      <DatePicker
        minDate={moment().toDate()}
        maxDate={moment().add(5, 'years').toDate()}
        defaultValue={moment(date).toDate()}
        onChange={(date) => { this.untilDate = date }}
        showActionsBar={true}
      />
    )
  }

  generateUntilJobs(date) {
    if(date) {
      this.untilDate = moment(date).toDate();
      if(this.untilDate < moment().toDate()) {
        Toast.show({ icon: 'warning-sign', message: 'Please select future date.', intent: Intent.DANGER })
        return;
      }
      let {
        serviceAgreementStore: {
          serviceAgreement: {
            object
          }
        }
      } = this.props;

      this.props.jobStore.generateUntilJobs(this.untilDate, object.attributes.id)
        .then(this.onSuccessfulGenerateFutureJobs.bind(this))
        .catch(this.onFailGenerateFutureJobs.bind(this))
    }
  }

  handleCompleteScheduleCancel() {
    this.isCompleteScheduleDialogVisible = false;
    this.status = "";
  }

  handleActivateScheduleCancel() {
    this.isActivateScheduleDialogVisible = false;
    this.status = "";
  }

  handleScheduleStatusChange() {
    this.props.serviceAgreementStore.changeStatus(this.status)
      .then(this.onSuccessfulChangeStatus.bind(this))
      .catch(this.onFailChangeStatus.bind(this))
  }

  changeStatus(status) {
    this.status = status
    if(status === "completed") {
      this.isCompleteScheduleDialogVisible = true;
    } else if(status === "active") {
      this.isActivateScheduleDialogVisible = true;
    }
  }

  onSuccessfulChangeStatus(response) {
    var msg;
    if(this.status === 'completed') {
      msg = "Recurring schedule completed successfully."
      this.isCompleteScheduleDialogVisible = false;
    } else if(this.status === 'active') {
      msg = "Recurring schedule activated successfully."
      this.isActivateScheduleDialogVisible = false;
    }
    this.status = "";
    Toast.show({ icon: 'thumbs-up', message: msg, intent: Intent.SUCCESS })
  }

  onFailChangeStatus(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong.', intent: Intent.DANGER })
    }
  }

  render() {
    let {
      jobStore: {
        showFutureJobsLoader,
        showUntilJobsLoader
      },
      serviceAgreementStore: {
        serviceAgreement: {
          object
        }
      }
    } = this.props;
    return(
      <div className="container-fluid position-relative">
        <div className="row">
          <div className="col-md-6 pl-0">
            <div className="card position-sticky" style={{top: 60}}>
              <div className="card-body pr-0">
                  <div className="row">
                    <div className="col-8">
                      <h5><strong>Service Agreement Details</strong></h5>
                    </div>
                    <div className="col-4 text-right">
                      <Button onClick={() => {this.startEditServiceAgreement()}} style={{marginRight:'1.5rem'}}>
                        Edit
                      </Button>
                    </div>
                  </div>
                  <br/>
                <div style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="pl-0">
                          <strong>Start Date:</strong>
                        </td>
                        <td className="pl-0">
                          {
                            moment(object.attributes.schedule.start_time).format("dddd, MMMM Do YYYY")
                          }
                          
                        </td>
                      </tr>
                      <tr>
                        <td className="pl-0">
                          <strong>End Date:</strong>
                        </td>
                        <td className="pl-0">
                          {
                            object.attributes.schedule.end_time ? moment(object.attributes.schedule.end_time).format("dddd, MMMM Do YYYY") : "Never"
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="pl-0" width="300px">
                          <strong>Start and end time:</strong>
                        </td>
                        <td className="pl-0">
                          { object.attributes.formatted_start_time } - { object.attributes.formatted_end_time }
                        </td>
                      </tr>
                      <tr>
                        <td width="35%" valign="top" className="pl-0">
                          <strong>Title:</strong>
                        </td>
                        <td className="pl-0">
                          {object.attributes.title}
                        </td>
                      </tr>
                      <tr>
                        <td width="35%" valign="top" className="pl-0">
                          <strong>Reason for call:</strong>
                        </td>
                        <td className="pl-0">
                          {object.attributes.reason_for_call}
                        </td>
                      </tr>
                      <tr>
                        <td width="35%" valign="top" className="pl-0">
                          <strong>Client:</strong>
                        </td>
                        <td className="pl-0">
                          {object.attributes.client.legal_name}
                        </td>
                      </tr>
                      <tr>
                        <td width="35%" valign="top" className="pl-0">
                          <strong>Location:</strong>
                        </td>
                        <td className="pl-0">
                          {object.attributes.location.full_address}
                        </td>
                      </tr>
                      <tr>
                        <td width="35%" valign="top" className="pl-0">
                          <strong>Status:</strong>
                        </td>
                        <td className="pl-0">
                          {object.attributes.status}
                        </td>
                      </tr>
                      {
                        object.attributes.status === 'draft' &&
                        <tr>
                          <td colSpan="2" className="pl-0">
                            <p style={{textAlign: 'justify', backgroundColor: '#ffc4c4', borderRadius: 10, padding: 8}}>
                              The service agreement is in draft mode which means no future jobs will be generated, please activate this service agreement if you want to start generating new jobs based on the recurring schedule selected.
                            </p>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                </div>
            </div>
          </div>
          {/* <div className="col-md-3">
            <div className="card" style={{height: 'calc(100vh - 100px)'}}>
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <h5><strong>Invoicing</strong></h5>
                  </div>
                  <div className="col-4 text-right">
                    <Switch 
                      checked={object.attributes.invoicing} 
                      onChange={() => {}} />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-md-6 pr-0">
            <div className="card">
              <div className="card-body pr-0">
                <h5 className="mb-4"><strong>Upcoming Jobs</strong></h5>
                <div className="card rounded-0 border-0">
                  <table className="table mb-0" style={{ width: '100%' }}>
                    <tbody>
                      {object.attributes.jobs && object.attributes.jobs.map((job) => {
                        return (
                          <tr key={Math.random()}>
                            <td style={{ padding: '5px 0px 5px 0px', width:'325px' }}>
                              <div className="upcoming-job-card">
                                <div className='card-top'>
                                  <div className='event-number'>#{job.event_number}</div>
                                  <div className='event-tag' style={{ backgroundColor: `#000000`, color: '#ffffff' }}>{job.service_type_name} </div>
                                </div>
                              <div className='fc-title'>{job.title}</div>
                              <div className='card-row'>
                                <div className="client-name">{job.client_name}</div>
                                <div className="full-address">{job.location && job.location.full_address}</div>
                              </div>
                              <div className='card-bottom'>
                                <div className='card-date'>
                                  {formatTime(job.start_date)} - {formatTime(job.end_date)}
                                </div>
                              </div>
                              </div>
                            </td>
                            <td align="left" valign="top" style={{verticalAlign:'top'}}>
                              <Button intent={"danger"} onClick={() => {this.startDeletingJob(job, object.attributes.id)}}>Remove</Button> <Button onClick={() => {this.startEditJob(job)}}>Edit</Button>
                            </td>
                          </tr>)
                        })
                      }
                    </tbody>
                  </table>

                  {
                    object.attributes.status === 'active' &&                    
                    <React.Fragment>
                      <div>
                        <table className="table mb-0" style={{ width: '100%' }}>
                          <tbody>
                            <tr>
                              <td>Generate</td>
                              <td>
                                <label className="mb-0" style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                  <input type="number" id="future_jobs" className="bp3-input" placeholder="0" style={{maxWidth: '75%'}} /> future job(s)
                                </label>
                              </td>
                              <td align="right">
                                <Button loading={showFutureJobsLoader} onClick={this.generateFutureJobs.bind(this)}>Generate Jobs</Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div>
                        <table className="table mb-0" style={{ width: '100%' }}>
                          <tbody>
                            <tr>
                              <td>Generate jobs</td>
                              <td>
                                <label className="mb-0">
                                <span style={{marginRight: 20}}>Until</span>
                                <Popover 
                                  content={this.datePicker.bind(this)(this.untilDate)}
                                  position={Position.BOTTOM_LEFT}
                                  style={{ padding: '10px', borderRadius: 5 }}
                                  usePortal={true} >
                                  <Button rightIcon='caret-down' icon='calendar'>
                                    {moment(this.untilDate).format("dddd, MMMM Do YYYY")}
                                  </Button>
                                </Popover>
                                </label>
                              </td>
                              <td align="right">
                                <Button 
                                  loading={showUntilJobsLoader} 
                                  onClick={() => { 
                                    this.generateUntilJobs.bind(this)(this.untilDate)
                                  }}
                                >
                                  Generate Jobs
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </React.Fragment>
                  }

                  <div>
                    <table className="table mb-0" style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td valign="center  ">
                            {object.attributes.status === 'active' && 
                              <p>Completing recurring schedule will stop generating new jobs.</p>}

                            {object.attributes.status === 'draft' && 
                              <p>The service agreement is in draft mode, this will not generate any jobs until its activated.</p>}
                          </td>
                          <td align="right">
                            {object.attributes.status === 'active' &&
                              <Button intent={"danger"} onClick={() => { this.changeStatus('completed') }}>Mark as complete</Button>}
                            
                            {object.attributes.status === 'draft' &&
                              <Button intent={"primary"} onClick={() => {this.changeStatus('active')}}>Activate</Button>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteJobModal
          isOpen={this.isDeleteDialogVisible}
          job={this.job}
          isServiceAgreement={true}
          onClose={this.handleOnClose.bind(this)}
        />
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Complete recurring schedule"
          icon="updated"
          intent={Intent.DANGER}
          isOpen={this.isCompleteScheduleDialogVisible}
          onCancel={this.handleCompleteScheduleCancel.bind(this)}
          onConfirm={this.handleScheduleStatusChange.bind(this)}
        >
          <p>
            Completing recurring schedule will stop generating new jobs. Are you sure you want to do this?
          </p>
        </Alert>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Activate service agreement"
          icon="automatic-updates"
          intent={Intent.SUCCESS}
          isOpen={this.isActivateScheduleDialogVisible}
          onCancel={this.handleActivateScheduleCancel.bind(this)}
          onConfirm={this.handleScheduleStatusChange.bind(this)}
        >
          <p>
            Activating a service agreement will start generating new jobs. Are you sure you want to do this?
          </p>
        </Alert>
      </div>
    )
  }

};

export default ProfileCard;