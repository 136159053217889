import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import TextTruncate from 'react-text-truncate';
import Pagination from 'components/Pagination';
import Footer from '../Footer';
import { 
  Button, Tag, Alert, Intent, Divider
} from '@blueprintjs/core';
import Toast from 'helpers/toaster';
import qs from 'qs';
import { formatTime, formatTimeOnly, formatDateOnly } from 'helpers/time';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';
import { currencyFormattedAmount } from 'helpers/currency';
import ManualTransactionModal from 'components/Invoice/ManualTransactionModal';

@inject('store', 'invoiceStore', 'viewStore') @observer
class InvoicesTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable invoice;
  @observable showManualTransactionDialog = false;

  decideAppearance = (status) => {
    switch (status) {
      case 'submitted':
        return 'success'
      default:
        break;
    }
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        invoiceSearch
      }
    } = this.props;
    this.props.viewStore.invoiceSearch = {
      ...invoiceSearch,
      page: newPage
    };
    this.props.viewStore.invoices();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.invoiceStore.deleteInvoice(this.invoice)
      .then(this.onSuccessfulDeleteInvoice.bind(this))
      .catch(this.onFailedInvoiceDelete.bind(this))
  }

  onSuccessfulDeleteInvoice(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Invoice deleted successfully!', intent: Intent.SUCCESS })
    this.invoice = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedInvoiceDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete invoice!', intent: Intent.DANGER })
  }

  handleInvoiceClick(event, invoice) {
    switch (event.label) {
      case 'Edit':
        this.props.viewStore.ShowInvoice({ uuid: invoice.attributes.id })
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.invoice = invoice;
        break;
      case 'MarkAsPaid':
        this.props.invoiceStore.invoice.object = invoice;
        this.showManualTransactionDialog = true;
        break;
      default:
    }
  }

  onCloseManualTransactionDialog() {
    this.showManualTransactionDialog = false;
  }

  onSuccessManualTransaction() {
    this.showManualTransactionDialog = false;
    this.props.invoiceStore.loadInvoices();
  }

  showInvoice = (event, invoice) => {
    event.preventDefault();
    const {
      viewStore
    } = this.props;
    viewStore.ShowInvoice({ uuid: invoice.attributes.id });
  }

  invoiceStatusSubTextHtml(invoice) {
    let subText = [];
    let status = "";
    let payment_status = `<span class="bp3-tag ${invoice.attributes.payment_status === 'paid' ? 'bp3-intent-success' : 'bp3-intent-warning'}">${invoice.attributes.formatted_payment_status || "Unpaid"}</span>`
    if(invoice.attributes.payment_status != 'paid' && invoice.attributes.status === 'sent') {
      status = `<span class="bp3-tag bp3-intent-success">${invoice.attributes.formatted_status}</span>`
    }

    if(status === "") {
      return payment_status
    } else {
      subText.push(payment_status);
      subText.push(status);
      return subText;
    }
  }

  render() {
    let {
      viewStore,
      viewStore: {
        jobTab: {
          section
        }
      },
      invoiceStore: {
        invoices: {
          objects,
          meta
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
        <table className="table table-hover table-data-presentation" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Due Date</th>
              <th>Job</th>
              <th>Invoice #</th>
              <th>Customer</th>
              <th>Created By</th>
              <th>Grand Total</th>
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((invoice) => {
                return (
                  <tr key={invoice.id}>
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(invoice.attributes.created_at)}
                        subText={formatTimeOnly(invoice.attributes.created_at)}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(invoice.attributes.invoice_due_date)}
                        subText={formatTimeOnly(invoice.attributes.invoice_due_date)}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/jobs/${invoice.attributes.job.id}/${section}`}
                        mainText={invoice.attributes.event_number}
                        subText={invoice.attributes.job && invoice.attributes.job.service_type_name}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        onClick={(event) => { this.showInvoice(event, invoice) }}
                        mainLink={`/dashboard/invoices/${invoice.attributes.id}/show`}
                        mainText={invoice.attributes.number}
                        subTextHtml={this.invoiceStatusSubTextHtml(invoice)}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/clients/${invoice.attributes.client.id}?${qs.stringify(viewStore.clientShowSearch, { encode: false })}`}
                        mainText={invoice.attributes.client.name}
                        subText={invoice.attributes.location && invoice.attributes.location.full_address}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/users/${invoice.attributes.created_by.id}/show?resource=vacations`}
                        mainText={invoice.attributes.created_by.full_name}
                        subText={invoice.attributes.created_by.email}
                      />
                    </td>
                    <td>
                      <Tag intent={invoice.attributes.payment_status === 'paid' ? 'success' : 'warning'}>
                        {currencyFormattedAmount(invoice.attributes.grand_total)}
                      </Tag>
                    </td>
                    <td>
                      <div className="action-buttons">
                        <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.ShowInvoice({uuid: invoice.attributes.id})}>
                          <img src="/edit-light-icon.svg"/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleInvoiceClick({ label: 'Delete' }, invoice)}>
                          <img src="/delete-light-icon.svg" />
                        </Button>
                        {invoice.attributes.payment_status != 'paid' && invoice.attributes.due_amount > 0 && <Divider/>}
                        {invoice.attributes.payment_status != 'paid' && invoice.attributes.due_amount > 0 && <Button title="Mark as Paid" minimal={true} small={true} onClick={() => this.handleInvoiceClick({ label: 'MarkAsPaid' }, invoice)}>
                          <img src="/dollar_icon.svg" />
                        </Button>}
                      </div>
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to do this?
          </p>
        </Alert>
        <ManualTransactionModal
          isOpen={this.showManualTransactionDialog}
          onClose={this.onCloseManualTransactionDialog.bind(this)}
          onSuccess={this.onSuccessManualTransaction.bind(this)}
        />
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </React.Fragment>
    )
  }
}

export default InvoicesTable;