import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { 
  ControlGroup, Spinner
} from "@blueprintjs/core";
import DateTimeInput from 'components/DateTimeInput';
import { 
  Button
} from '@blueprintjs/core';
import moment from 'moment-timezone';

@inject('store', 'viewStore', 'geolocationStore') @observer
class Map extends Component {

  onStartDateChange(date) {
    this.props.viewStore.geolocationSearch.where.timestamp.gte = moment(date).unix();
  }

  onEndDateChange(date) {
    this.props.viewStore.geolocationSearch.where.timestamp.lte = moment(date).unix();
  }

  search() {
    this.props.viewStore.userMapShow();
    this.props.geolocationStore.loadLocations();
  }

  render() {
    let {
      geolocationStore: {
        search: {
          isLoading
        }
      },
      viewStore: {
        geolocationSearch: {
          where: {
            timestamp: {
              gte,
              lte
            }
          }
        }
      }
    } = this.props;
    return(
      <nav className="bp3-navbar search">
        <div>
          <div className="bp3-navbar-group bp3-align-left">
            <ControlGroup fill={true} vertical={false}>
              <DateTimeInput 
                placeholder={'Start Date'}
                defaultValue={moment.unix(parseInt(gte))}
                onChange={this.onStartDateChange.bind(this)}
              />
              <DateTimeInput 
                placeholder={'End Date'} 
                defaultValue={moment.unix(parseInt(lte))} 
                onChange={this.onEndDateChange.bind(this)} 
              />
            </ControlGroup>
            <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
            {
              isLoading && <Button minimal={true}>
                <Spinner size={24}></Spinner>
              </Button>
            }
          </div>
        </div>
      </nav>
    )
  }
};

export default Map;