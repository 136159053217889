import React, { Component } from "react";
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import {
  Button, Intent, Spinner,
  ButtonGroup, Icon, Popover, Menu, MenuItem
} from '@blueprintjs/core';
import styled from '@emotion/styled';
import DeleteJobModal from './DeleteJobModal';
import qs from 'qs';
import InvoiceJobModal from 'components/Job/InvoiceJobModal';
import AddTimeLogModal from 'components/Job/AddTimeLogModal';
import Toast from "helpers/toaster";
import _ from 'lodash';

const TopRow = styled('div')`
  display: flex;
  flex-direction: row;
`

const BottomRow = styled('div')`
`
@inject('store', 'jobStore', 'viewStore', 'invoiceStore','taxStore', 'activityStore') @observer
class JobHeader extends Component {

  @observable isOpenAddJobInvoiceDialog = false;
  @observable isDeleteDialogVisible = false;
  @observable isOpenAddTimeLogDialog = false;
  @observable selectedAssignment;
  @observable client_details = {
    attributes: {
      name: ''
    }
  };

  startEditJob() {
    this.props.jobStore.persistJob = {
      object: {
        ...this.props.job.attributes,
        isServiceAgreement: false,
        location: {
          attributes: this.props.job.attributes.location
        },
        client: {
          attributes: this.props.job.attributes.client
        },
        selectedServiceType: [
          {
            value: this.props.job.attributes.service_type_id,
            selected: true,
            label: this.props.job.attributes.service_type_name
          }
        ]
      }
    }
    this.props.store.isOpen = true;
  }

  handleOnClose() {
    this.isDeleteDialogVisible = false;
  }

  remindTechs() {
    this.props.jobStore.remindTechs(this.props.job);
  }

  handleTimeLogClick() {
    let {
      jobStore,
      jobStore: {
        job: {
          object
        },
        timelog
      }
    } = this.props;
    jobStore.loadJobContents(object);
    timelog.object.job_id = object.id;
    this.isOpenAddTimeLogDialog = true;
  }

  handleCloseTimeLogDialog() {
    this.isOpenAddTimeLogDialog = false;
  }

  onSuccessAddTimeLog() {
    let {
      jobStore,
      jobStore: {
        job: {
          object
        }
      }
    } = this.props;
    this.isOpenAddTimeLogDialog = false;
    // reloading timesheets
    jobStore.loadJobTimesheets(object);
  }

  handleInvoiceJobClick() {
    let { 
      job, 
      taxStore,
      viewStore: {
        currentUser
      },
      invoiceStore: {
        jobInvoice: { 
          object
        }
      }
    } = this.props;
    let defaultTax = undefined;
    taxStore.loadTaxes()
      .then(response => { 
        defaultTax = _.find(response.data.data, {attributes: {is_default: true}})
      });
    
    //this.props.jobStore.loadJobInvoice(job.attributes.id);

    // client pre selected for work order
    this.client_details.attributes = job.attributes.client;
    object.selectedClient = this.client_details;
    object.client_id = job.attributes.client.id;
    
    // job pre selected for work order
    let job_detals = [];
    job_detals = [{
      id: job.attributes.id,
      label: job.attributes.title,
      value: job.attributes.id,
      selected: true
    }]
    object.selectedJob = job_detals;
    object.scheduled_event_id = job.attributes.id;

    // location pre selected for work order
    let location_details = {
      attributes: job.attributes.location
    };
    object.location = location_details;
    object.location_id = job.attributes.location.id;

    object.technician_id = [currentUser.id]

    // job search using client id for work order
    this.props.viewStore.jobSearch.where.client_id = job.attributes.client.id;
    this.props.jobStore.loadWorkReportLineItems(job.attributes.id)
      .then(response => {
        if (defaultTax !== undefined) {
          var lineItems = response.data;
          lineItems.line_items.labours.map((labor) => {
            labor.attributes.tax_line_item_id = defaultTax.attributes.id;
            labor.attributes.tax_amount = (defaultTax.attributes.percentage_value / 100) * labor.attributes.total;
          });
          lineItems.line_items.materials.map((material) => {
            material.attributes.tax_line_item_id = defaultTax.attributes.id;
            material.attributes.tax_amount = (defaultTax.attributes.percentage_value / 100) * material.attributes.total;
          });
          lineItems.line_items.customs.map((custom) => {
            custom.attributes.tax_line_item_id = defaultTax.attributes.id;
            custom.attributes.tax_amount = (defaultTax.attributes.percentage_value / 100) * custom.attributes.total;
          });
          this.props.jobStore.materialLineItems.object = lineItems;
        }
      });
    this.isOpenAddJobInvoiceDialog = true;
  }

  onSuccessAddJobInvoice() {
    this.isOpenAddJobInvoiceDialog = false;
  }
  
  handleCloseJobInvoiceDailog() {
    this.isOpenAddJobInvoiceDialog = false;
  }

  changeJobStatus(status) {
    let {
      job,
      jobStore,
      activityStore
    } = this.props;
    jobStore.changeJobStatus(job, status)
      .then((response) => {
        Toast.show({
          icon: "thumbs-up",
          message: status === "invoiced" ? "Job successfully marked as Invoiced!" : "Job successfully marked as Not Invoiced!",
          intent: Intent.SUCCESS
        });
        activityStore.loadActivityLogs(job.attributes.id);
      })
      .catch((error) => {
        if(error.response && error.response.data && error.response.data.errors) {
          Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ icon: 'warning-sign', message: 'Something went wrong. Failed to change job status.', intent: Intent.DANGER })
        }
      })
  }

  render() {
    let { 
      job, 
      viewStore,
      jobStore: {
        showMarkAsInvoicedLoader
      }
    } = this.props;
    return (
      <React.Fragment>
        <div className="job-details-page-header">
          <TopRow className="title-field">
            <h2 className="bp3-heading bp3-text-disabled">
              <div className={'bp3-heading'}>Job #{job.attributes.event_number}</div>
              <div className="service-field"><span>{job.attributes.service_type_name}</span></div>
              <div className="service-field">
                <span style={{backgroundColor: job.attributes.status === 'invoiced' ? '#449a61' : '#868f95'}}>
                  {job.attributes.status === "invoiced" ? "Invoiced" : "Not Invoiced"}
                </span>
              </div>
              {showMarkAsInvoicedLoader && <div className="service-field">
                <Spinner size={20} />
              </div>}
            </h2>
            <div className="field-style">Created by&nbsp;-&nbsp;
          <a href={`/dashboard/users/${job.attributes.created_by_id}/show?${qs.stringify(viewStore.userShowSearch,
              { encode: false })}`} >
                {job.attributes.created_by_fullname}
              </a> |
            <a style={{ marginLeft: 5 }} href={`/dashboard/clients/${job.attributes.client_id}/`} >
                {job.attributes.client_name}
              </a>
            </div>
            {job.attributes.service_agreement_id && <Icon icon="repeat" iconSize={Icon.SIZE_LARGE} style={{ marginLeft: 0, marginTop: 5 }} />}
            {/* <div style={{ marginLeft: 'auto' }}>
            <ButtonGroup large={true}>
              <Button>
                <strong>DISPATCH</strong>
              </Button>
              <Button icon='print'></Button>
              <Button icon='envelope'></Button>
              <Button icon='more'></Button>
            </ButtonGroup>
          </div> */}
          </TopRow>
          <BottomRow>
            <div className='job-details-action job-tags job-tags-overflow'>
              <ButtonGroup>
                {/* <Button minimal={true} icon='bookmark'>
                Google Adwords
              </Button> */}
                {/* <Button minimal={true} icon='build'>
                {job.attributes.service_type_name}
              </Button> */}
                {
                  // <Button minimal={true} icon='document'>
                  //   PO: {job.attributes.po_number || "N/A"}
                  // </Button>
                }
                { job.attributes.counts.work_order > 0 &&
                  <Button minimal={false} rightIcon={'dollar'} onClick={() => { this.handleInvoiceJobClick(this) }}>
                    Invoice Job
                  </Button>
                }
                
                {/* <Button minimal={false} onClick={() => { this.isDeleteDialogVisible = true; }}>
                  Delete Job <img src="/Delete-Icon.svg" />
                </Button>
                <Button minimal={false} onClick={this.startEditJob.bind(this)}>
                  Edit Job <img src="/edit-icon.svg" />
                </Button> */}
                <Popover minimal={true} inheritDarkTheme={false} className='add-job-wrapper'>
                  <Button minimal={true} rightIcon='chevron-down' className="more-actions-btn" >More Actions</Button>
                  <Menu>
                    {job.attributes.status === "invoiced" && <MenuItem text="Mark as Not Invoiced" icon={'dollar'} onClick={() => {this.changeJobStatus('not-invoiced')}} />}
                    {job.attributes.status != "invoiced" && <MenuItem text="Mark as Invoiced" icon={'dollar'} onClick={() => {this.changeJobStatus('invoiced')}} />}
                    <Menu.Divider />
                    <MenuItem text="Edit Job" icon={'edit'} onClick={this.startEditJob.bind(this)} />
                    <MenuItem text="Delete Job" icon={'trash'} onClick={() => { this.isDeleteDialogVisible = true; }} />
                    <Menu.Divider />
                    <MenuItem text="Add Work Order" icon={'add'} onClick={this.props.handleWorkReportClick} />
                    <MenuItem text="Add Estimate" icon={'add'} onClick={this.props.handleEstimateClick} />
                    <MenuItem text="Add Invoice" icon={'add'} onClick={this.props.handleInvoiceClick} />
                    <Menu.Divider />
                    {job.attributes.counts && job.attributes.counts.work_order > 0 && <MenuItem text="Add TimeLog" icon={'add'} onClick={this.handleTimeLogClick.bind(this)} />}
                    {job.attributes.counts && job.attributes.counts.work_order === 0 && <MenuItem text="Add TimeLog" icon={'add'} onClick={() => { }} disabled={true} />}
                  </Menu>
                </Popover>
                <Button className="blue-btn" minimal={false} onClick={this.remindTechs.bind(this)}>
                  Remind Technicians <img src="/bell-icon.svg" />
                </Button>
                {/* <Button minimal={true} icon='tag' />
              <span className='bp3-tag bp3-minimal bp3-tag-with-margin'>building</span>
              <span className='bp3-tag bp3-minimal bp3-tag-with-margin'>recurring</span>
              <span className='bp3-tag bp3-minimal bp3-tag-with-margin'>hvac</span> */}
              </ButtonGroup>
            </div>
          </BottomRow>
        </div>
        <DeleteJobModal
          isOpen={this.isDeleteDialogVisible}
          job={job}
          onClose={this.handleOnClose.bind(this)}
        />

        <InvoiceJobModal
          isOpen={this.isOpenAddJobInvoiceDialog}
          onClose={this.handleCloseJobInvoiceDailog.bind(this)}
          onSuccess={this.onSuccessAddJobInvoice.bind(this)}
          edit={true}
          job={job}
        />

        <AddTimeLogModal
          isOpen={this.isOpenAddTimeLogDialog}
          onClose={this.handleCloseTimeLogDialog.bind(this)}
          onSuccess={this.onSuccessAddTimeLog.bind(this)}
        />
      </React.Fragment>
    )
  }
}

export default JobHeader;