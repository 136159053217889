import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Intent, Alert, Button, ButtonGroup } from '@blueprintjs/core';
import { observable } from 'mobx';
import Toast from 'helpers/toaster';
import CustomTaxItem from './CustomTaxItem';
import { currencyFormattedAmount } from 'helpers/currency';

@inject('store', 'invoiceStore', 'viewStore') @observer
class CustomItem extends Component {
  @observable isDeleteDialogVisible = false;
  @observable lineitem;

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.invoiceStore.deleteLineItem(this.lineitem)
      .then(this.onSuccessfulDeleteCustom.bind(this))
      .catch(this.onFailDeleteCustom.bind(this))
  }

  onSuccessfulDeleteCustom(response) {
    Toast.show({ icon: 'thumbs-up', message: 'successfully deleted default line item!', intent: Intent.SUCCESS })
    this.lineitem = null;
    this.isDeleteDialogVisible = false;
  }

  onFailDeleteCustom(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete default line item!', intent: Intent.DANGER })
  }

  handlemCustomClick(event, lineitem) {
    switch (event.label) {
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.lineitem = lineitem;
        break;
      default:
    }
  }

  handleQuantityChange(event) {
    let { lineItem } = this.props;
    lineItem.quantity_display_value = event.target.value === "" ? 0 : event.target.value;
    lineItem.quantity = parseFloat(lineItem.quantity_display_value)
    this.calculatelineItem();
  }

  handleRateChange(event) {
    let { lineItem } = this.props;
    var value = event.target.value === "" ? 0 : event.target.value.replace('$', '').replace(',', '')
    lineItem.rate_per_unit = value;
    lineItem.rate_per_unit_display_value = currencyFormattedAmount(lineItem.rate_per_unit)
    this.calculatelineItem();
  }

  calculatelineItem() {
    let {
      lineItem
    } = this.props;
    if (lineItem.description === "Truck Charge") {
      let { currentUser } = this.props.viewStore;
      if (currentUser.attributes.truck_charge.enabled) {
        lineItem.total = (parseFloat(lineItem.quantity) / 4) * parseFloat(currentUser.attributes.truck_charge.rate)
      } else {
        if (lineItem.markup) {
          lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit) * parseFloat(lineItem.markup)
        } else {
          lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit)
        }
      }
    } else {
      if (lineItem.markup) {
        lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit) * parseFloat(lineItem.markup)
      } else {
        lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit)
      }
    }

    lineItem.total_display_value = currencyFormattedAmount(lineItem.total)
  }

  refreshLineitem() {
    this.props.refresh()
  }

  render() {
    const {
      lineItem
    } = this.props;
    return (
      <React.Fragment>

        <tr key={lineItem.id}>
          <td className="p-0 border-left-0">
            <input
              type="text"
              className="bp3-input bp3-fill"
              placeholder="Description"
              defaultValue={lineItem.description}
              onChange={(event) => { lineItem.description = event.target.value; }}
            />
          </td>
          <td className="p-0">
            <input
              type='number'
              className='bp3-input bp3-fill'
              placeholder='Hours'
              defaultValue={parseFloat(lineItem.quantity) || 0}
              onChange={(event) => this.handleQuantityChange(event)}
            />
          </td>
          <td className="p-0">
            <input
              type='number'
              className='bp3-input bp3-fill'
              placeholder='Rate'
              defaultValue={lineItem.rate_per_unit || ''}
              onChange={(event) => this.handleRateChange(event)}
            />
          </td>

          <td className="py-0">
            <strong> {currencyFormattedAmount(lineItem.total)}</strong>
          </td>
          <td rowSpan="2" className="border-0" align="center">
            <Button
              minimal
              type='button'
              icon={"cross"}
              onClick={() => this.handlemCustomClick({ label: 'Delete' }, lineItem)}
              className="row-delete-btn"
            />
          </td>
        </tr>

        { lineItem.tax_line_items && lineItem.tax_line_items.length > 0 && lineItem.tax_line_items.map((tax) => {
          return (
            <CustomTaxItem taxItem={tax} key={tax.id} lineItem={lineItem} refresh={this.refreshLineitem.bind(this)} />
          )
        })
        }
        <div style={{ height: 10 }}></div>
        
        {/* <tr key={lineItem.id}>
          <td width="42%">
            <label className="only-mobile">Description</label>
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Description'
              defaultValue={lineItem.description}
              onChange={(event) => {lineItem.description = event.target.value;}} 
            />
          </td>
          <td>
            <label className="only-mobile">Quantity</label>
            <input 
              type='number' 
              className='bp3-input bp3-fill' 
              placeholder='Hours'
              defaultValue={parseFloat(lineItem.quantity) || 0}
              onChange={(event) => this.handleQuantityChange(event) }
            />  
          </td>
          <td>
            <label className="only-mobile">Rate($)</label>
            <input 
              type='number' 
              className='bp3-input bp3-fill' 
              placeholder='Rate'
              defaultValue={lineItem.rate_per_unit || ''}
              onChange={(event) => this.handleRateChange(event) }
            />    
          </td>
          <td>
            <label className="only-mobile">Total</label>
            {currencyFormattedAmount(lineItem.total)}
          </td>
          <td style={{ width: '5%' }}>
            <button 
              className='bp3-button bp3-minimal bp3-icon-trash bp3-small'
              type='button'
              onClick={() => this.handlemCustomClick({ label: 'Delete' }, lineItem)}
            />
          </td>
          
        </tr> */}
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>Warnning! Deleting this default line item will not be recoverable.</p>
          <p>Are you sure you want to do this?</p>
        </Alert>

      </React.Fragment>
    )
  }

};

export default CustomItem;