import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import Page from 'components/Page';
import styled from '@emotion/styled';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`

@inject('store', 'formStore', 'viewStore') @observer
class FormsShowPage extends Component {

  state = {
    SurveyCreator: null
  };

  componentWillMount() {
    import('./SurveyCreator').then(LoadableComponent => {
      this.setState({ LoadableComponent: LoadableComponent.default });
    });
  }

  get canRender() {
    let {
      formStore: {
        form: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object && object.id);
  }

  componentWillUnmount() {
    let {
      formStore
    } = this.props;
    formStore.clearSelectedForm();
  }

  updateSelectedResource = (resource) => {
    let {
      viewStore,
      formStore: {
        form: {
          object
        }
      }
    } = this.props;
    viewStore.formShowSearch.resource = resource;
    viewStore.formViewSearch(object.attributes.id);
  }

  onFormSave = (schema) => {
    let {
      formStore,
      formStore: {
        form: {
          object
        }
      }
    } = this.props;

    formStore.updateForm({uuid: object.id, schema_version: schema});
  }

  render() {
    let { LoadableComponent } = this.state;
    let content, title;
    let {
      formStore: {
        form: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      title = 'Loading..';
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load form';
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      title = `${object.attributes.name} | Field Nexus`;
      content = <React.Fragment>
        { LoadableComponent && <LoadableComponent formId={object.id} onSave={this.onFormSave.bind(this)} schema={object.attributes.schema_version} /> }
      </React.Fragment>
    }
    return (
      <div className="forms-pagae-layout">
        {
          <Helmet>
            <title>{title}</title>
          </Helmet>
        }
        <Page>
          {content}
        </Page>
      </div>
    )
  }

};

export default FormsShowPage;