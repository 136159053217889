import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import _ from 'lodash';
import { Intent,Alert } from '@blueprintjs/core';
import Toast from 'helpers/toaster';
import { currencyFormattedAmount } from 'helpers/currency';

@inject('store', 'quoteStore', 'taxStore') @observer
class CustomTaxItem extends Component {

  @observable totalTax = undefined;
  @observable selectedTaxId = '';
  @observable isDeleteDialogVisible = false;
  @observable lineitem;

  componentDidMount() {
    this.calculateTax();
  }

  handlemTaxLineItemClick(event, lineitem) {
    switch (event.label) {
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.lineitem = lineitem;
        break;
      default:
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      taxStore: {
        taxes: {
          objects
        }
      }
    } = this.props;
    if(nextProps.lineItem) {
      let taxLineItem = _.find(objects, {attributes: {id: this.selectedTaxId}})
      if(taxLineItem && taxLineItem.attributes){
        this.totalTax = currencyFormattedAmount((taxLineItem.attributes.percentage_value/100) * nextProps.lineItem.total)
      }
      
    }
  }

  calculateTax() {
    const {
      lineItem,
      taxItem
    } = this.props;
    this.selectedTaxId = taxItem.tax_id
    this.totalTax = currencyFormattedAmount((taxItem.percentage_value/100) * lineItem.total)
  }

  calculateTotalTax(event) {
    const {
      lineItem,
      taxItem,
      taxStore,
      taxStore: {
        taxes: {
          objects
        }
      }
    } = this.props;

    let taxLineItem = _.find(objects, {attributes: {id: event.target.value}})

    taxStore.updateCustomTaxLineItem(taxItem, taxLineItem)
      .then((res) => {
        this.selectedTaxId = taxLineItem.attributes.id
        this.totalTax = currencyFormattedAmount((taxLineItem.attributes.percentage_value/100) * lineItem.total)
        this.props.refresh()
      })

  }

  deleteTaxLineItem() {
    this.isDeleteDialogVisible = false;
    const {
      quoteStore,
      taxItem
    } = this.props;
    quoteStore.deleteCustomTaxLineItem(taxItem)
      .then(this.onSuccessfulDeleteTaxLineItem.bind(this))
      .catch(this.onFailDeleteTaxLineItem.bind(this))
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }


  onSuccessfulDeleteTaxLineItem(response) {
    this.lineitem = null;
    Toast.show({ icon: 'thumbs-up', message: 'successfully deleted tax line item!', intent: Intent.SUCCESS })
  }

  onFailDeleteTaxLineItem(error) {
    Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
  }

  render() {
    const {
      taxItem,
      taxStore: {
        taxes: {
          objects
        },
      },
    } = this.props;
    
    return(
      <React.Fragment>
         
            <tr key={taxItem.id}>
              <td colSpan="1" className="py-0"></td>
              <td className="p-0">
                <div className="bp3-select bp3-fill bp3-large">
                  <select onChange={(event) => {this.calculateTotalTax(event)} } value={this.selectedTaxId}>
                    {/* <option value=''>Select Tax</option> */}
                    {
                      objects.length > 0 && objects.map((tanent_tax) => {
                        return(
                          <option value={tanent_tax.attributes.id} key={tanent_tax.attributes.id}>{tanent_tax.attributes.name} {tanent_tax.attributes.percentage_value}%</option>
                        )
                      })
                    }
                  </select>
                </div>
              </td>
              <td className="py-0">{this.totalTax}</td>
              <td className="py-0" align="center">
                <button 
                  className='bp3-button bp3-minimal bp3-icon-trash bp3-small'
                  type='button'
                  //onClick={this.deleteTaxLineItem.bind(this)} 
                  onClick={() => this.handlemTaxLineItemClick({ label: 'Delete' }, taxItem)}
                />
              </td>
            </tr>
          
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.deleteTaxLineItem.bind(this)}
        >
          <p>Are you sure you want to delete this tax line item?</p>
        </Alert>
      </React.Fragment>
    )
  }

};

export default CustomTaxItem;