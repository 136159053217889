import { observable, action } from 'mobx';

class RootStore {
  @observable isOffline = false;
  @observable currentUser = null;
  @observable visible = true;
  @observable avatars = [];
  @observable jobs = [];
  @observable showPanel = false;
  @observable isOpen = false;
  // @observable isOpenUnassignedJob = false;
  
  @observable isOpenAddProjectDialog = false;
  @observable isOpenAddClientDialog = false;
  @observable isOpenAddSupplierDialog = false;
  @observable isOpenAddNotesDialog = false;
  @observable isOpenAddEquipmentDialog = false;
  @observable isOpenAddEquipmentForWorkReportDialog = false;
  @observable isOpenAddMaterialForWorkReportDialog = false;
  @observable isOpenAddLaborForWorkReportDialog = false;
  @observable isOpenAddMaterialForQuoteDialog = false;
  @observable isOpenAddLaborForQuoteDialog = false;
  @observable isOpenAddMaterialForInvoiceDialog = false;
  @observable isOpenAddLaborForInvoiceDialog = false;
  @observable isOpenAddWorkReportDialog = false;
  @observable isOpenAddServiceTypeDialog = false;
  @observable isOpenUpdateServiceTypeDialog = false;
  @observable isOpenAddEstimateDialog = false;
  @observable isOpenAddCopyDialog = false;
  @observable projectDetail = [];
  @observable productServiceType = '';
  @observable productServiceForm = '';
  @observable isBookingConfirmDialog = false;
  @observable isOpenAddEquipmentPartDialog = false;
  @observable isOpenUpdateClientDialog = false;
  

  @action
  onSuccessAddProject(project) {
    this.isOpenAddProjectDialog = false;
  }

  @action
  toggleAddJob(){ 
    if(this.isOpen){
      this.isOpen = false; 
      window.stores.jobStore.clearJob(); 
      setTimeout(() => {
        this.isOpen = true;
      }, 500);
    }else{
      this.isOpen = true;
    }
  }

  @action
  handleCloseProjectDailog() {
    this.isOpenAddProjectDialog = false;
  }

  @action
  handleCloseClientDailog() {
    this.isOpenAddClientDialog = false;
  }

  @action
  onSuccessAddClient(client) {
    this.isOpenAddClientDialog = false;
  }

  @action
  handleCloseSupplierDailog() {
    this.isOpenAddSupplierDialog = false;
  }

  @action
  onSuccessAddSupplier(client) {
    this.isOpenAddSupplierDialog = false;
  }

  @action
  handleCloseCopyDailog() {
    this.isOpenAddCopyDialog = false;
  }

  @action
  onSuccessAddCopy(client) {
    this.isOpenAddCopyDialog = false;
  }

  @action
  handleCloseNotesDailog() {
    this.isOpenAddNotesDialog = false;
  }

  @action
  onSuccessAddNotes(note) {
    this.isOpenAddNotesDialog = false;
  }

  @action
  handleClosEquipmentForWorkReportDailog() {
    this.isOpenAddEquipmentForWorkReportDialog = false;
  }

  @action
  onSuccessAddEquipmentForWorkReport(note) {
    this.isOpenAddEquipmentForWorkReportDialog = false;
  }

  @action
  handleCloseMaterialForWorkReportDailog() {
    this.isOpenAddMaterialForWorkReportDialog = false;
  }

  @action
  onSuccessAddMaterialForWorkReport(note) {
    this.isOpenAddMaterialForWorkReportDialog = false;
  }

  @action
  handleClosEquipmentDailog() {
    this.isOpenAddEquipmentDialog = false;
  }

  @action
  onSuccessAddEquipment(note) {
    this.isOpenAddEquipmentDialog = false;
  }

  @action
  handleCloseWorkReportDailog() {
    this.isOpenAddWorkReportDialog = false;
  }

  @action
  handleCloseServiceTypeDailog() {
    this.isOpenAddServiceTypeDialog = false;
  }

  @action
  onSuccessAddServiceType(ServiceType) {
    this.isOpenAddServiceTypeDialog = false;
  }

  @action
  handleCloseUpdateServiceTypeDailog() {
    this.isOpenUpdateServiceTypeDialog = false;
  }

  @action
  onSuccessUpdateServiceType(ServiceType) {
    this.isOpenUpdateServiceTypeDialog = false;
  }

  @action
  onSuccessAddWorkReport(workReport) {
    this.isOpenAddWorkReportDialog = false;
  }

  @action
  handleCloseEstimateDailog() {
    this.isOpenAddEstimateDialog = false;
  }

  @action
  onSuccessAddEstimate(estimate) {
    this.isOpenAddEstimateDialog = false;
  }

  @action
  handleCloseBookingConfirmDailog() {
    this.isBookingConfirmDialog = false;
  }

  @action
  onSuccessBookingConfirm(booking) {
    this.isBookingConfirmDialog = false;
  }

  @action
  handleCloseUpdateClientDailog() {
    this.isOpenUpdateClientDialog = false;
  }

}

export default RootStore;