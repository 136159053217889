import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from 'mobx';
import { 
  Divider, Intent,
  Button,
  FormGroup, Spinner
} from '@blueprintjs/core';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Toast from "helpers/toaster";
import BookingsSidebar from '../bookings/bookings-sidebar';

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`


@inject("frequencyStore")
@observer
class FrequencyShowPage extends Component {

  updateFrequency() {
    let {
      props: {
        frequencyStore,
        frequencyStore: {
          frequencyUpdate,
          frequencyUpdate: { 
            object,
            isLoading
           }
        }
      }
    } = this;
    frequencyUpdate.isLoading = true;
   
    if(object.attributes.title === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid title!', intent: Intent.DANGER })
      return;
    }

    this.props.frequencyStore
      .updateFrequency(object)
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Frequency updated successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to update frequency!",
          intent: Intent.DANGER
        });
      })
      .finally(() => {
        frequencyUpdate.isLoading = false;
      })
    
  }

 
  render() {
    let {
      frequencyStore,
      frequencyStore: {
        frequencyUpdate: {
          object,
          isLoading
        }
      }
    } = this.props;

    return (
      <div>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <BookingsSidebar />
          </div>
          {
            !object &&
              <Spinner />
          }
          {
            object && object.attributes &&
              <div className="col-lg-10">
                <PageWrapGray>
                  <div className="pricing-parameter-table">
                    <Card>
                      <CardContent>
                        <div>
                          <div className="left-side-panel-servie-type">
                            <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                              Update Frequency
                            </h4>
                          </div>
                        </div>
                        <Divider style={{ margin: '16px 0px' }}></Divider>
                        <div className="left-side-panel-servie-type pricing-parameter">
                          <FormGroup label="Title" >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='Title'
                              defaultValue={object.attributes.title}
                              onChange={(event) => {object.attributes.title = event.target.value;}}            
                            />
                          </FormGroup>
                        </div>
                        <div className="">
                          <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.updateFrequency.bind(this)}>Update</Button>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </PageWrapGray>
              </div>
          } 
        </div>
      </div>
    );
  }
}

export default FrequencyShowPage;
