import React from 'react'
import { MenuItem, Menu, Intent } from '@blueprintjs/core';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import Select from 'react-select';

let option = [
  {label: 'Appliance Repair',value: 'Appliance Repair'},
  {label: 'Carpet Cleaning',value: 'Carpet Cleaning'},
  {label: 'Commercial Cleaning',value: 'Commercial Cleaning'},
  {label: 'Electrical Cleaning',value: 'Electrical Cleaning'},
  {label: 'General Contracting',value: 'General Contracting'},
  {label: 'HVAC',value: 'HVAC'},
  {label: 'Landscaping',value: 'Landscaping'},
  {label: 'Presure Washing',value: 'Presure Washing'},
  {label: 'Residential Cleaning',value: 'Residential Cleaning'},
  {label: 'Pest Control',value: 'Pest Control'},
  {label: 'Plumbing',value: 'Plumbing'},
  {label: 'Snow Removing',value: 'Snow Removing'},
  {label: 'Painting',value: 'Painting'},
  {label: 'Others',value: 'Others'},
];
@inject('preferenceStore','viewStore','store') @observer
class CompanyType extends React.Component {

  state = {
    selectedOption: null
  };

  componentDidMount() {
    let {
      preferenceStore,
      preferenceStore: {
        tenant: {
          object
        }    
      }
    } = this.props;
    preferenceStore.loadTenant(); 
  }

  handleChange = selectedOption => {
    this.setState(
      { selectedOption }
    ); 
    let {
      preferenceStore: {
        tenant: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props; 
    if (selectedOption.value) {      
      object.attributes.company_type = selectedOption.value;
    }  
  };

  render() {  
    let {
      preferenceStore: {
        tenant: {
          isLoading,
          isError,
          object
        }     
      }
    } = this.props;  
    let { selectedOption } = this.state;
   
    return (
      <div>
        { object &&        
          <Select
            options={option} 
            value={{label: object.attributes.company_type, value: object.attributes.company_type}}                                                                   
            onChange={this.handleChange}        
            isSearchable={true}
            defaultValue={{label: object.attributes.company_type, value: object.attributes.company_type}}
            isClearable={false}
            placeholder={"Select Company Type"}        
          />
        }
      </div>
    )
  }
}



export default CompanyType;