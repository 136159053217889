import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  ControlGroup,
  Button,
  Dialog,
  Classes,
  Intent,
} from "@blueprintjs/core";
import ClientAutocomplete from "components/ClientAutocomplete";
import LocationAutocomplete from "components/LocationAutocomplete";
import Toast from "helpers/toaster";
import { observable } from 'mobx';

@inject("viewStore", "quoteStore")
@observer
class UpdateClientModal extends Component {
  @observable selectedClient = null;
  @observable selectedLocation = null;

  componentDidMount() {
    const {
      quoteStore: {
        quote: { 
          object 
        }
      }
    } = this.props;
    this.selectedClient = object.attributes.client && {attributes: object.attributes.client};
    this.selectedLocation = object.attributes.current_location && {attributes: { ...object.attributes.current_location, name: object.attributes.current_location.full_address}};
  }

  updateClient() {
    let {
      props: {
        quoteStore: {
          quote: { 
            object 
          }
        }
      }
    } = this;

    if(this.selectedClient === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please choose customer name.', intent: Intent.DANGER })
      return;
    }
    if(this.selectedLocation === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please choose location.', intent: Intent.DANGER })
      return;
    }

    object.attributes.client = this.selectedClient;
    object.attributes.client_id = this.selectedClient.id;
    object.attributes.current_location = this.selectedLocation;
    object.attributes.location_id = this.selectedLocation.id;

    this.props.quoteStore
      .update()
      .then(response => {
        this.props.onClose();
        Toast.show({
          icon: "thumbs-up",
          message: "Client updated successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to update client!",
          intent: Intent.DANGER
        });
      });
  }

  handleCloseDailog() {
    this.props.onClose();
  }

  onClientClear = () => {
    this.selectedClient = null;
    this.selectedLocation = null;
  };

  onLocationClear = () => {
    this.selectedLocation = null;
  };

  render() {
    let { object } = this.props.quoteStore.quote;
    return (
      <Dialog
        title="Update Client"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseDailog.bind(this)}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Enter Customer Name, Phone Number or Email"
            labelFor="text-input"
            labelInfo="*"
          >
            <ClientAutocomplete
              style={{ width: "600px" }}
              inputProps={{
                placeholder:
                  "Start typing Customer Name, Phone number or Email.."
              }}
              value={this.selectedClient}
              onClientSelect={client => {
                if (client) {
                  this.selectedClient = client;
                } else {
                  this.onClientClear();
                }
              }}
            />
            {this.selectedClient && this.selectedClient.attributes.do_not_service &&
            <div className="alert mt-2 alert-danger do-not-service" role="alert">
              Do not service this client: {this.selectedClient.attributes.do_not_service_reason}
            </div>}
            {this.selectedClient && this.selectedClient.attributes.is_on_alert &&
            <div className="alert mt-2 alert-danger do-not-service" role="alert">
              Alert on this client: {this.selectedClient.attributes.on_alert_reason}
            </div>}
          </FormGroup>
          {this.selectedClient && (
            <FormGroup
              label="Location"
              labelFor="text-input"
              labelInfo="(search for location)"
            >
              <ControlGroup fill={true} vertical={false}>
                <LocationAutocomplete
                  scope={{ client_id: object.attributes.client.id }}
                  style={{ width: "400px" }}
                  inputProps={{
                    fill: true,
                    placeholder: "Start typing location.."
                  }}
                  onLocationClear={this.onLocationClear}
                  value={this.selectedLocation}
                  onLocationSelect={location => {
                    if (location) {
                      this.selectedLocation = location;
                    } else {
                      this.onLocationClear();
                    }
                  }}
                />
              </ControlGroup>
            </FormGroup>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.handleCloseDailog.bind(this)}
              style={{ width: "100px" }}
            >
              Cancel
            </Button>
            <Button
              intent={Intent.PRIMARY}
              loading={this.props.quoteStore.quote.isLoading}
              onClick={this.updateClient.bind(this)}
              style={{ width: "100px" }}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default UpdateClientModal;
