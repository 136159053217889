import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import ServiceTypeCard from 'components/ServiceType/Card';
import styled from '@emotion/styled';
import BookingsSidebar from '../bookings/bookings-sidebar';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`

@inject('store', 'viewStore', 'serviceTypeStore') @observer
class ServiceTypeShowPage extends Component {

  get canRender() {
    let {
      serviceTypeStore: {
        serviceTypeDetail: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object && object.id);
  }

  render() {
    let content, title;
    let {
      viewStore,
      serviceTypeStore: {
        serviceTypeDetail: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      title = 'Loading..';
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load Project';
      content = <NonIdealState title="Failed" description="Super Failed!" />;
    }
    if (this.canRender) {
      title = `${object.attributes.name} | Field Nexus`;
      content = <ServiceTypeCard 
        serviceTypeDetail={object}
        selectedResource={viewStore.serviceTypeSearch.resource} 
      />
    }
   
    return(
      <div className="profile-card-grid">
        {
          <Helmet>
            <title>{title}</title>
          </Helmet>
        }
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
                <BookingsSidebar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
                {content}
            </PageWrapGray>
          </div>
        </div>
      </div>
    )
  }

};

export default ServiceTypeShowPage;