import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import EquipmentProfileCard from 'components/Equipment/ProfileCard';
import styled from '@emotion/styled';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);  
`

@inject('store', 'viewStore', 'equipmentStore') @observer
class EquipmentsShowPage extends Component {

  get canRender() {
    let {
      equipmentStore: {
        equipment: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object && object.id);
  }

  componentWillUnmount() {
    let {
      equipmentStore
    } = this.props;
    equipmentStore.clearSelectedEquipment();
  }

  updateSelectedResource = (resource) => {
    let {
      viewStore,
      equipmentStore: {
        equipment: {
          object
        }
      }
    } = this.props;
    viewStore.equipmentShowSearch.resource = resource;
    viewStore.equipmentViewSearch(object.attributes.id);
  }

  render() {
    let content, title;
    let {
      viewStore,
      equipmentStore: {
        equipment: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      title = 'Loading..';
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load Project';
      content = <NonIdealState title="Failed" description="Super Failed!" />;
    }
    if (this.canRender) {
      title = `${object.attributes.make} | Field Nexus`;
      content = <EquipmentProfileCard 
        equipment={object}
        selectedResource={viewStore.equipmentShowSearch.resource}
        onResourceChange={this.updateSelectedResource.bind(this)} 
      />
    }
    return(
      <div className="profile-card-grid">
        {
          <Helmet>
            <title>{title}</title>
          </Helmet>
        }
       <PageWrapGray>
          {content}
       </PageWrapGray>
      </div>
    )
  }

};

export default EquipmentsShowPage;