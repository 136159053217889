import React, { Component } from 'react';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import Helmet from 'react-helmet';

class NotFound extends Component {
  render() {
    return (
      <UnauthenticatedPage>
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        <div></div>
        <div>
          <h1 style={{ textAlign: 'center' }}>Oops, Looks like you lost your way?</h1>
          <img src='/images/pluto-page-not-found.png' style={{ width: '100%' }} alt='Not Found' />
        </div>
        <div></div>
      </UnauthenticatedPage>
    )
  }
}

export default NotFound;