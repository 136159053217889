import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import RichTextEditor from 'react-rte'; 

@inject('store', 'quoteStore') @observer
class ProposalCoverEditor extends Component {

  @observable value = RichTextEditor.createValueFromString('Jasdeep Singh Narang', 'html');

  componentDidMount() {
    let {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;
    if (!object.attributes.proposal_markup) {
      this.value = RichTextEditor.createValueFromString(`
          <p><strong>Re: </strong></p>
        `, 'html');
    } else {
      this.value = RichTextEditor.createValueFromString(object.attributes.proposal_markup,'html');
    }
  }

  onChange(val) {
    let {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;

    this.value = val;
    object.attributes.proposal_markup = val.toString('html');
  }

  render() {
    return(
      <div className="editor-nd" style={{marginLeft: 10, marginRight: 10, marginBottom: 12}}>
        <h5>
          <strong>Scope of Work</strong>
        </h5>
        <RichTextEditor
          value={this.value}
          onChange={this.onChange.bind(this)}
          className='text-editor'
          placeholder="Scope of Work"
        />
      </div>
    )
  }

};

export default ProposalCoverEditor;