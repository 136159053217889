import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Intent } from '@blueprintjs/core';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import FooterIllustration from 'components/FooterIllustration';
import { Button } from "@blueprintjs/core";
import { Helmet } from 'react-helmet';
import { TopNavbar } from 'components/Styles';
import Toast from 'helpers/toaster';
import MainSiteLogo from 'components/MainSiteLogo';
import NewUserSidebar from './new_user_sidebar';
import Inputmask from 'inputmask';
import moment from 'moment-timezone';
import { Api } from 'services/Api';

@inject('viewStore', 'userStore') @observer
class SignupDetails extends Component {

  @observable isDisabled = true;
  @observable browserIpdata = {
    isLoading: true,
    data: {}
  }

  componentDidMount(){ 
    Inputmask({ mask: '999-999-9999[999]', skipOptionalPartCharacter: " ", clearMaskOnLostFocus: true, greedy: false }).mask('#phone');
    this.checkIsDisbaled();
    this.props.userStore.signup.isLoading = false;
    this.props.userStore.signup.isError = true;

    let promise = Api.get(`https://api.ipgeolocation.io/ipgeo?apiKey=8f8e5aa7c93d4ab687d86a2d099bfccc`);
    promise
      .then((res) => {
        this.browserIpdata = {
          isLoading: false,
          data: res.data
        }
      });
  }

  checkIsDisbaled(){
    let {
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;
    if(object.first_name === '' || !object.email || !object.tenant || !object.password || object.password === '' || !object.password_confirmation || object.password_confirmation === ''){
      this.isDisabled = true;
    }else{
      this.isDisabled = false;
    }
    if(object.password !== object.password_confirmation || (!object.password || object.password === '')){
      this.isDisabled = true;
    }else{
      this.isDisabled = false
    }
  }

  createTenant() {
    const {
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;

    let {
      browserIpdata: {
        data
      }
    } = this;

    

    if(data){
      if(data.currency.code === 'CAD'){
        object.currency_symbol = '$';
      }
      object.currency = data.currency.code;
      object.currency_symbol = data.currency.symbol;
      object.country = data.country_name;
    }
   
    object.timezone = moment.tz.guess(true);

    if(object.first_name === '') {
      Toast.show({ message: "First name is required! Please enter first name", intent: Intent.DANGER })
      return;
    }
    if(object.password === '') {
      Toast.show({ message: "Password is required! Please enter password", intent: Intent.DANGER })
      return;
    }
    if(!object.email) {
      Toast.show({ message: "Email is required! Please enter email", intent: Intent.DANGER })
      return;
    }
    if(!object.tenant) {
      Toast.show({ message: "Company name is required! Please enter company name", intent: Intent.DANGER })
      return;
    }
    if(!object.password || object.password === '') {
      Toast.show({ message: "Password is required! Please enter password", intent: Intent.DANGER })
      return;
    }

    if(object.password.length < 6 || object.password_confirmation.length < 6){
      Toast.show({ message: "Password and password confirmation should be at least 6 characters long!", intent: Intent.DANGER })
      return;
    }

    if(object.password !== object.password_confirmation) {
      Toast.show({ message: "Password and password confirmation must match!", intent: Intent.DANGER })
      return;
    }

    this.props.userStore.createTenant()
      .then((response) => {
        Toast.show({ message: "Account created successfully. Let's invite your team mates", intent: Intent.SUCCESS });
        this.props.userStore.resetSignupUser();
      })
      .catch((error) => {
        if(error.response.data.errors) {
          Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ message: "Unable to create account!", intent: Intent.DANGER })
        }
      })
  }

  _handleKeyPress(event) {
    if (event.key === 'Enter' && !this.isDisabled) {
      this.createTenant();
    }
    this.checkIsDisbaled();
    this.props.userStore.saveUserSignupToLocalStorage();
  }


  render() {
    let {
      viewStore,
      userStore: {
        signup: {
          isLoading,
          object
        }
      }
    } = this.props;
    const { isDisabled } = this;
    return(
      <UnauthenticatedPage>
        <Helmet>
          <title>Signup | FieldNexus</title>
        </Helmet>
        <TopNavbar>
          <div className="tp-nav">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-7">
                  <MainSiteLogo />
                </div>
                <div className="col-5 text-right">
                  <div className="right-link-tp">
                    <div className="top-btn-back"><a onClick={() => viewStore.signupCompanyTeamSize() }><img src="/back.svg" title="Go Back" alt='Back' /> Go Back</a> 5/5</div>  
                  </div> 
                </div>
              </div>
            </div>      
          </div>
        </TopNavbar>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-9 d-flex align-items-center sign-up">
              <div className="form-wrap">
                <p>We are almost done</p>
                <h3 className="login-heading">Just create an account and start saving time.</h3>
                <div className="row input-wrap">
                  <div className="col-sm-6 checked-icon">
                    <input 
                      autoFocus
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      autoComplete={"false"}
                      defaultValue={object.first_name}
                      onChange={e => {
                        object.first_name = e.target.value
                        this.checkIsDisbaled();
                      }} 
                      onKeyPress={this._handleKeyPress.bind(this)}
                      required 
                    />
                    {
                      object.first_name.length > 2 &&
                      <div className="checed-active"><img src="/checked.svg" /></div>
                    }
                  </div>
                  <div className="col-sm-6 checked-icon">
                    <input 
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      defaultValue={object.last_name}
                      autoComplete={"false"}
                      onChange={e => {
                        object.last_name = e.target.value
                        this.checkIsDisbaled();
                      }}
                      
                      onKeyPress={this._handleKeyPress.bind(this)}
                      required
                    />
                    {
                      object.last_name.length > 2 &&
                      <div className="checed-active"><img src="/checked.svg" /></div>
                    }
                  </div>
                </div>
                <div className="row input-wrap">
                  <div className="col-sm-12 checked-icon">
                    <input 
                      id="phone"
                      type="tel"
                      className="form-control"
                      placeholder="Phone Number"
                      defaultValue={object.phone}
                      autoComplete="new-tel"
                      // onChange={e => object.phone = e.target.value} 
                      onChange={e => {
                        object.phone = e.target.value
                        this.checkIsDisbaled();
                      }}
                      onKeyPress={this._handleKeyPress.bind(this)}
                      required 
                    />
                    {/* {
                      object.phone.length > 9  &&
                      <div className="checed-active"><img src="/checked.svg" /></div>
                    } */}
                  </div>
                </div>
                <div className="row input-wrap">
                  <div className="col-sm-6 checked-icon">
                    <input 
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      onChange={e => {
                        let _object = object
                        _object.password = e.target.value;
                        object = _object;
                        this.checkIsDisbaled();
                      }}
                      onKeyPress={this._handleKeyPress.bind(this)}
                      required
                    />
                    {
                      object.password.length > 5 && object.password === object.password_confirmation  &&
                      <div className="checed-active"><img src="/checked.svg" /></div>
                    }
                  </div>
                  <div className="col-sm-6 checked-icon">
                    <input 
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                      onChange={e => {
                        let _object = object
                        _object.password_confirmation = e.target.value;
                        object = _object;
                        this.checkIsDisbaled();
                      }}
                      onKeyPress={this._handleKeyPress.bind(this)}
                      required
                    />
                    {
                      object.password_confirmation.length > 5 && object.password_confirmation === object.password &&
                      <div className="checed-active"><img src="/checked.svg" /></div>
                    }
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-sm-6 col-5 fg-pass">
                    <a onClick={() => viewStore.signupCompanyTeamSize()}>Previous Step</a>
                  </div>
                  <div className="col-sm-6 col-7 fg-next">
                    <Button
                      className="btn-gray"
                      loading={isLoading}
                      disabled={isDisabled}
                      onClick={this.createTenant.bind(this)} >
                      Create Account
                    </Button>
                  </div>
                </div>
                <h6 className="d-none d-sm-none d-md-block text-right">Or Press ENTER</h6>
              </div>
            </div>
            <div className="col-lg-3">
              <NewUserSidebar />
            </div>
          </div>
        </div>
        <FooterIllustration />
      </UnauthenticatedPage>
    )
  }

};

export default SignupDetails;