import { observable, action, toJS } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

class GeolocationStore {

  @observable search = {
    isLoading: false,
    isError: false,
    isSearched: false,
  };

  @observable clientLocations = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable objects = [];

  @action
  loadLocations() {
    this.search.isLoading = true;
    let promise = Api.post(`/geolocations/search`, { search: toJS(stores.viewStore.geolocationSearch) } );

    promise
      .then((response) => {
        window.markers.map((marker) => marker.setMap(null) );
        window.markers = [];
        this.search.isLoading = false;
        this.search.isSearched = true;
        this.objects = response.data.data;
      })
      .catch((error) => {
        this.search.isLoading = false;
        this.search.isError = true;
      })
  }

  @action
  loadClientLocations() {
    this.clientLocations.isLoading = true;
    let promise = Api.post('/locations/search', { 
      search: window.stores.viewStore.clientLocationSearch
    });

    promise
      .then((response) => {
        this.clientLocations.isLoading = false;
        this.clientLocations.isError = false;
        this.clientLocations.objects = response.data.data;
        this.clientLocations.meta = response.data.meta;
      })
      .catch((error) => {
        this.clientLocations.isLoading = false;
        this.clientLocations.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  deleteLocation(location) {
    this.clientLocations.isLoading = true;
    let promise = Api.delete(`/locations/${location.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadClientLocations();
      })
      .catch((error) => {
        this.clientLocations.isLoading = false;
        this.clientLocations.isError = true;
      })
    
    return promise;
  }

};

export default GeolocationStore;