import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { 
  Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import { currencyFormattedAmount } from 'helpers/currency';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
let numeral = require('numeral');

@inject('store', 'clientStore', 'pricingParameterStore', 'viewStore') @observer
class PricingParametersTable extends Component {
  @observable isOpenAddEquipmentDialog = false;
  @observable isDeleteDialogVisible = false;
  @observable pricing;

  onPaginationChange(newPage) {
    let {
      viewStore: {
        pricingParameterSearch
      }
    } = this.props;
    this.props.viewStore.pricingParameterSearch = {
      ...pricingParameterSearch,
      page: newPage
    };
    this.props.viewStore.pricingParameters();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.pricingParameterStore.deletePricingParameter(this.pricing)
      .then(this.onSuccessfulDeletePricingParameter.bind(this))
      .catch(this.onFailDeletePricingParameter.bind(this))
  }

  onSuccessfulDeletePricingParameter(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Pricing parameter deleted successfully!', intent: Intent.SUCCESS })
    this.service = null;
    this.isDeleteDialogVisible = false;
  }

  onFailDeletePricingParameter(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete pricing parameter!', intent: Intent.DANGER })
  }

  handleServiceTypeClick(event, pricing) {
    switch (event.label) {
      case 'Edit':
        this.pricing = pricing;
        this.props.viewStore.pricingParameterShow({uuid: pricing.attributes.id})
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.pricing = pricing;
        break;
      default:
    }
  }

  copyPricingParameter(pricing) {
    let {
      props: {
        pricingParameterStore: {
          pricingParameterDetail,
          pricingParameterDetail: { 
            object
           }
        }
      }
    } = this;

    pricingParameterDetail.object = pricing;
    pricingParameterDetail.object.id = '';
    pricingParameterDetail.object.pricing_parameter_services_attributes = [];
    pricingParameterDetail.object.pricing_parameter_ranges_attributes = [];
    if(pricingParameterDetail.object.pricing_parameter_services){
      pricingParameterDetail.object.pricing_parameter_services && pricingParameterDetail.object.pricing_parameter_services.map((values) => {
        return(
          pricingParameterDetail.object.pricing_parameter_services_attributes.push({
            service_type_id:values.service_type_id,
            label:values.label
          })
        )
      })
    }
    pricingParameterDetail.object.pricing_parameter_ranges_attributes = [];
    if(pricingParameterDetail.object.pricing_parameter_ranges){
      pricingParameterDetail.object.pricing_parameter_ranges && pricingParameterDetail.object.pricing_parameter_ranges.map((values) => {
        return(
          pricingParameterDetail.object.pricing_parameter_ranges_attributes.push({
            label:values.label,
            min_range:values.min_range,
            max_range:values.max_range,
            price:values.price,
          })
        )
      })
    }

    this.props.pricingParameterStore
      .createPricingParameter()
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Pricing parameter copied successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to copied pricing parameter!",
          intent: Intent.DANGER
        });
      });
  }

  render() {
    let {
      viewStore,
      pricingParameterStore: {
        pricingParameter: {
          objects,
          meta
        }
      }
    } = this.props;

    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
          <table className="table table-width table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th width="30%">Price</th>
                <th width="30%">Details</th>
                <th className="item-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                objects.map((pricings) => {
                  return (
                    <tr key={pricings.id}>
                      <td>{pricings.attributes.name}</td>
                      <td>
                        <div className="data-overflow">
                          { 
                            pricings.attributes.pricing_type === 'range' ?
                            pricings && pricings.attributes.pricing_parameter_ranges && pricings.attributes.pricing_parameter_ranges.map((range, index) => {
                            return(
                                <div>
                                  <b>{range.label}</b> - {currencyFormattedAmount(range.price)}
                                </div>
                              )
                            })
                            :
                            <div>{currencyFormattedAmount(pricings.attributes.price)} </div>
                          }
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="pricing-parameter-range">
                            <div className="pricing-parameter-heading"><b>Duration:</b></div>
                            <div>
                              {
                                pricings.attributes.pricing_parameter_ranges.length > 0 && pricings.attributes.pricing_parameter_ranges[0].min_range != null ?
                                "Varies"
                                :
                                pricings.attributes.duration
                              }
                            </div>
                          </div>
                          <div className="pricing-parameter-range data-overflow">
                            <div className="pricing-parameter-heading"><b>Range:</b></div> 
                            <div>
                              { 
                                pricings.attributes.range_start === null ?
                                pricings && pricings.attributes.pricing_parameter_ranges && pricings.attributes.pricing_parameter_ranges.map((range, index) => {
                                  return(
                                    <div>{ range.min_range } {'-'} {range.max_range}</div>
                                  )
                                })
                                :
                                <div>{pricings.attributes.range_start} {'-'} {pricings.attributes.range_end}</div>
                              }
                            </div>
                          </div>
                          <div className="pricing-parameter-range">
                            <div className="pricing-parameter-heading"><b>Services:</b></div>
                            <div>
                              {
                                pricings && pricings.attributes.service_types.length > 0 && pricings.attributes.service_types.map((service) => {
                                  return(
                                    <div>{ service.name }</div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="action-buttons" minimal={true}>
                          <Button title="Copy" minimal={true} small={true} onClick={() => this.copyPricingParameter(pricings.attributes)}>
                            <img src="/icon_projects.svg"/>
                          </Button>
                          <Divider/>
                          <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.pricingParameterShow({uuid: pricings.attributes.id})}>
                            <img src="/edit-light-icon.svg"/>
                          </Button>
                          <Divider/>
                          <Button title="Delete" minimal={true} small={true} onClick={() => this.handleServiceTypeClick({ label: 'Delete' }, pricings)}>
                            <img src="/delete-light-icon.svg" />
                          </Button>
                        </div>
                      </td>
                    </tr> 
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>Warnning! Deleting this service type will not be recoverable.</p>
          <p>Are you sure you want to do this?</p>
        </Alert>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </React.Fragment>
    )
  }

}

export default PricingParametersTable;