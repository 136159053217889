import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Intent, Button, Alert } from '@blueprintjs/core';
@inject('store', 'quoteStore') @observer
class QuoteEquipments extends Component {
  render() {
    const {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;
    return (
      <React.Fragment>

        <div className="table-title" style={{ paddingLeft: '10px' }}>
        <h5><strong>Equipment</strong></h5>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div style={{ padding: '0px 10px' }} >
            {
              object.attributes.equipments.length === 0 && <div className="bp3-callout add-btn bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
                No equipment have been added to this Estimate.
              </div>
            }
          </div>
          {
            object.attributes.equipments.length > 0 && <div className="wr-row"> <table className='responsive-table-layout table table-bordered' style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ width: 120 }}>Serial #</th>
                  <th style={{ width: 120 }}>Make</th>
                  <th style={{ width: 120 }}>Model</th>
                  <th style={{ width: 350 }}>Description</th>
                  <th>Location</th>
                  <th style={{ width: 62 }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  object.attributes.equipments.map((equipment) => {
                    return (
                      <tr key={equipment.id}>
                        <td colSpan="6" style={{ paddingRight: 0, border: 0 }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className="wr-row" style={{ flex: 1 }}>
                              <table className="table table-bordered">
                                <tr>
                                  <td style={{ width: 120 }}>
                                    <label className="only-mobile">Serial #</label>
                                    {equipment.serial_number}
                                  </td>
                                  <td style={{ width: 120 }}>
                                    <label className="only-mobile">Make</label>
                                    {equipment.make}
                                  </td>
                                  <td style={{ width: 120 }}>
                                    <label className="only-mobile">Model</label>
                                    {equipment.model}
                                  </td>
                                  <td style={{ width: 350 }}>
                                    <label className="only-mobile">Description</label>
                                    {equipment.description}
                                  </td>
                                  <td>
                                    <label className="only-mobile">Equipment Location</label>
                                    {equipment.physical_location}
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div className="fn-icons" style={{ flexDirection: 'column' }}>
                              <Button
                                minimal
                                type='button'
                                icon={"cross"}
                                //onClick={this.handleSelectConfirm.bind(this, equipment.serviced_equipment_id)}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </div>
          }
        </div>
      </React.Fragment>
    )
  }
}

export default QuoteEquipments;