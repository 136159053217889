import React, { Component } from 'react'

const StatusMap = {
  draft: '',
  approved: 'bp3-intent-success',
  rejected: 'bp3-intent-danger'
}

const StatusPill = ({ timelog }) => (
  <span className={["bp3-tag bp3-minimal text-capitalize", StatusMap[timelog.attributes.status]].join(' ')}>{timelog.attributes.status}</span>
)

const OvertimePill = ({ timelog }) => {
  if (timelog.attributes.is_overtime) { 
    return (<span className='bp3-tag bp3-intent-warning'>Overtime</span>)
  }
  return null;
}


export { StatusPill, OvertimePill };