import React, { Component } from 'react'
import { 
  Button,FormGroup,ControlGroup,
  ButtonGroup,
  Popover, Position,
  Text, Tag, Menu,
  MenuItem, Intent
} from '@blueprintjs/core';
import { DateRangePicker } from "@blueprintjs/datetime";
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import ListPicker from 'components/ListPicker';
import SearchDateRangePicker from 'components/SearchDateRangePicker';
import ServiceTypePicker from 'components/ServiceTypePicker';
import SupplierAutocomplete from 'components/SupplierAutocomplete';
import WorkReportAutocomplete from 'components/WorkReportAutocomplete';
import Toast from 'helpers/toaster';
import moment from 'moment-timezone';

const options = [
  { label: 'Pending order', value: 'pending order', selected: false },
  { label: 'Ordered', value: 'ordered', selected: false },
  { label: 'In transit', value: 'in transit', selected: false },
  { label: 'Received', value: 'received', selected: false },
  { label: 'Picked up by the technician', value: 'picked up by the technician', selected: false },
  { label: 'Archived', value: 'archived', selected: false }
];

@inject('store', 'workReportStore', 'viewStore','jobStore') @observer
class MaterailOrders extends Component {

  @observable startDate = null;
  @observable endDate = null;
  @observable selectedClient = '';
  @observable selectedWork = '';


  componentDidMount() {
    let {
      workReportStore: {
        persistMaterial: {
          object
        }
      }
    } = this.props;
  }

  
  @action
  onWorkReportStatusChange(selections) {
    let selectedValues = selections.map((status) => status.value);
    let {
      props: {
        viewStore: {
          materialLineItemSearch
        }
      }
    } = this;
    this.props.viewStore.materialLineItemSearch = {
      ...materialLineItemSearch,
      where: {
        ...materialLineItemSearch.where,
        status: selectedValues
      }
    }
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.search()
    }
  }

  @action
  onSupplierSelect(supplier) {
    let {
      workReportStore: {
        persistMaterial: {
          object
        }
      }
    } = this.props;
    let {
      props: {
        viewStore: {
          materialLineItemSearch,
          materialLineItemSearch: {
            where: {
              supplier_id,
              ...whereWithNoClientId
            }
          }
        }
      }
    } = this;
    if (supplier) { 
      this.props.viewStore.materialLineItemSearch = {
        ...materialLineItemSearch,
        where: {
          ...materialLineItemSearch.where,
          supplier_id: supplier.id
        }
      }
      object.supplier_id = supplier.id
    } else {
      this.props.viewStore.materialLineItemSearch = {
        ...materialLineItemSearch,
        where: {
          ...whereWithNoClientId,
          supplier_id: null
        }
      }
    }
  }

  @action
  onWorkReportSelect(workReportData) {
    let {
      workReportStore: {
        persistMaterial: {
          object
        }
      }
    } = this.props;
   
    let {
      props: {
        viewStore: {
          materialLineItemSearch,
          materialLineItemSearch: {
            where: {
              work_report_id,
              ...whereWithNoClientId
            }
          }
        }
      }
    } = this;
    if (workReportData) { 
      this.props.viewStore.materialLineItemSearch = {
        ...materialLineItemSearch,
        where: {
          ...materialLineItemSearch.where,
          work_report_id: workReportData.id
        }
      }
     object.work_report_id = workReportData.attributes.number
    } else {
      this.props.viewStore.materialLineItemSearch = {
        ...materialLineItemSearch,
        where: {
          ...whereWithNoClientId,
          work_report_id: null
        }
      }
    }
  }

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.materialLineItemSearch.page = 1;
    viewStore.MaterialOrders();
    this.props.workReportStore.loadMaterialLineItems();
    
  }

  

  onSupplierClear = () => {
    let {
      workReportStore: {
        persistMaterial: {
          object
        }
      }
    } = this.props;
    let {
      props: {
        viewStore: {
          materialLineItemSearch,
        }
      }
    } = this;
    object.supplier_id = null;
    this.props.viewStore.materialLineItemSearch = {
      ...materialLineItemSearch,
      where: {
        ...materialLineItemSearch.where,
        supplier_id: null
      }
    }

  }

  onWorkClear = () => {
    let {
      workReportStore: {
        persistMaterial: {
          object
        }
      }
    } = this.props;
    let {
      props: {
        viewStore: {
          materialLineItemSearch,
        }
      }
    } = this;
    object.work_report_id = null;
    this.props.viewStore.materialLineItemSearch = {
      ...materialLineItemSearch,
      where: {
        ...materialLineItemSearch.where,
        work_report_id: null
      }
    }
  }

  render() {
    const {
      workReportStore: {
        persistMaterial: {
          object
        }
      }
    } = this.props;
    const {
      store
    } = this.props;
    const {
      where
    } = this.props.viewStore.materialLineItemSearch;
    
    return (
      <nav className="bp3-navbar sticky-mobile search bg-transparent navbar-overflow">
        <div>
          <div className="">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <SupplierAutocomplete
                  selectedSupplierId={where && where.supplier_id}
                  onSupplierSelect={this.onSupplierSelect.bind(this)}
                />
                <WorkReportAutocomplete
                  style={{ width: '400px' }}
                  selectedWorkId={where && where.work_report_id}
                  
                  inputProps={{ placeholder: 'Work Order...' }}
                  onWorkReportSelect={(work) => { 
                    if(object && work){
                      object.work_report_id = work.number;
                      object.work = work
                      this.onWorkReportSelect(work)
                    }else{
                      this.onWorkClear();
                    }
                  }}
                />
                <Popover
                  minimal={true}                  
                  content={
                    <ListPicker
                      selectedOptions={(where && where.status) || []}
                      options={options}
                      onChange={this.onWorkReportStatusChange.bind(this)}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                  usePortal={true} >
                  <Button rightIcon='caret-down' style={{ height: 40 }}>
                    Select Status
                    <span>&nbsp;</span>
                    <Tag minimal={true} round={true}>{(where && where.status && where.status.length) || 0}</Tag>
                  </Button>
                </Popover> 
                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} style={{ height: 40 }} />
              </div>
            </div>
          </div>
          {/* <div className="bp3-navbar-group bp3-align-right">
            <div className="bp3-control-group">
              <Button icon='plus' text='Create Purchase Order' onClick={this.updateSelectedResource.bind(this)} />
            </div>
          </div> */}
        </div>
      </nav>
    )
  }
}

export default MaterailOrders;