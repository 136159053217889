import React, { Component } from 'react';
import {
  Button, Intent
} from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Toast from 'helpers/toaster';
import { Helmet } from 'react-helmet';
import { router } from '../App';
import FooterIllustration from 'components/FooterIllustration';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import { TopNavbar } from 'components/Styles';


@inject('viewStore') @observer
class ResetPassword extends Component {

  @observable user = {
    password: '',
    password_confirmation: '',
    token: ''
  }

  handlePasswordChange(event) {
    this.user.password = event.target.value
  }

  handlePasswordConfirmationChange(event) {
    this.user.password_confirmation = event.target.value
  }

  resetPassword() {
    const {
      props: {
        viewStore
      }
    } = this;
    this.user.token = viewStore.resetToken;
    viewStore.performPasswordReset(this.user)
      .then((response) => {
        Toast.show({ icon: 'thumbs-up', message: 'Your password has been changed. Please log in with your new password to continue.', intent: Intent.SUCCESS })
      })
      .catch((error) => {
        if (error.response.data) {
          Toast.show({ icon: 'warning-sign', message: error.response.data.error, intent: Intent.DANGER })
        } else {
          Toast.show({ icon: 'warning-sign', message: 'Unable to reset password, please ensure your internet is working fine.', intent: Intent.DANGER })
        }
      })
  }

  render() {
    return (
      <UnauthenticatedPage>
        <Helmet>
          <title>Reset Password | FieldNexus</title>
        </Helmet>
        <TopNavbar>
          <div className="tp-nav">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-7">
                  <div className="brand">
                    <a href="https://fieldnexus.com"><img style={{ maxWidth: '200px' }} src="/brand.svg" alt='Field Nexus' /></a>
                  </div>
                </div>
                <div className="col-5 text-right">
                  <div className="right-link-tp"></div>
                </div>
              </div>
            </div>      
          </div>
        </TopNavbar>

        <div className="form-wrap" style={{ marginTop: '100px' }}>
          <p>Hi there,</p>
          <h3 className="login-heading">Let's reset your password.</h3>
          <div className="row input-wrap">
            <div className="col-sm-12">
              <input 
              type="password" 
              className="form-control" 
              placeholder="Type New Password (8-12 Characters)"
              value={this.user.password}
              onChange={this.handlePasswordChange.bind(this)}
              required  
            />
            </div>
            <div className="col-sm-12">
              <input 
                type="password" 
                className="form-control" 
                placeholder="Retype New Password"
                value={this.user.password_confirmation}
                onChange={this.handlePasswordConfirmationChange.bind(this)}
                required  
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-6 col-5 fg-pass">
              <a onClick={() => { router.navigate('/login') }}>Back to Login</a>
            </div>
            <div className="col-sm-6 col-7 fg-next">
              <Button
                className="btn-gray"
                onClick={this.resetPassword.bind(this)} >
                Reset Password
              </Button>
            </div>
          </div>
        </div>
        <div></div>
        <FooterIllustration />
      </UnauthenticatedPage>
    )
  }

}

export default ResetPassword;