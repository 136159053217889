import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import FooterIllustration from 'components/FooterIllustration';
import { Button } from "@blueprintjs/core";
import { Helmet } from 'react-helmet';
import { TopNavbar } from 'components/Styles';
import MainSiteLogo from 'components/MainSiteLogo';
import NewUserSidebar from './new_user_sidebar';
@inject('viewStore', 'userStore') @observer
class SignupCompany extends Component {

  @observable isDisabled = true;

  componentDidMount() {
    this.checkNextButton();
  }

  navigateToNextScreen() {
    const {
      viewStore
    } = this.props;
    viewStore.signupCompanyType();
    this.props.userStore.saveUserSignupToLocalStorage();
  }

  checkNextButton() {
    const {
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;
    if(object.tenant && object.tenant.length > 3) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }
  
  handleTenantChange(event) {
    const {
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;
    object.tenant = event.target.value;
    this.checkNextButton();
  }

  _handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.navigateToNextScreen()
    }
  }

  render() {
    const {
      viewStore,
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;
    return(
      <UnauthenticatedPage>
        <Helmet>
          <title>Signup | FieldNexus</title>
        </Helmet>
        <TopNavbar>
          <div className="tp-nav">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-7">
                  <MainSiteLogo />
                </div>
                <div className="col-5 text-right">
                  <div className="right-link-tp">
                    <div className="top-btn-back"><a onClick={() => viewStore.signup()}><img src="/back.svg" title="Go Back" alt='Back' /> Go Back</a> 2/5</div>
                  </div>
                </div>
              </div>
            </div>      
          </div>
        </TopNavbar>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-9 d-flex align-items-center sign-up">
          <div className="form-wrap">
            <p>Introduce your business</p>
            <h3 className="login-heading">Tell us your company name.</h3>
            <div className="row input-wrap">
              <div className="col-sm-12 checked-icon">
                <input 
                  autoFocus
                  type="text"
                  className="form-control"
                  placeholder="Your company name"
                  defaultValue={object.tenant}
                  onChange={this.handleTenantChange.bind(this)} 
                  onKeyPress={this._handleKeyPress.bind(this)}
                  required 
                />
                {
                  this.isDisabled === false &&
                  <div className="checed-active"><img src="/checked.svg" alt="" /></div>
                }
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-6 col-5 fg-pass">
                <a onClick={() => viewStore.signup()}>Previous Step</a>
              </div>
              <div className="col-sm-6 col-7 fg-next">
                <Button
                  className="btn-gray"
                  disabled={this.isDisabled}
                  onClick={this.navigateToNextScreen.bind(this)} >
                  Next
                </Button>
              </div>
            </div>
            <h6 className="d-none d-sm-none d-md-block text-right">Or Press ENTER</h6>
          </div>
            </div>
            <div className="col-lg-3">
              <NewUserSidebar />
            </div>
          </div>
        </div>

        <FooterIllustration />
      </UnauthenticatedPage>
    )
  }

};

export default SignupCompany;