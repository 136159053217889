import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { 
  Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
import { currencyFormattedAmount } from 'helpers/currency';
let numeral = require('numeral');

@inject('store', 'clientStore', 'serviceTypeExtraStore', 'viewStore') @observer
class ServiceTypeExtraTable extends Component {
  @observable isOpenAddEquipmentDialog = false;
  @observable isDeleteDialogVisible = false;
  @observable service;

  onPaginationChange(newPage) {
    let {
      viewStore: {
        serviceTypeExtraSearch
      }
    } = this.props;
    this.props.viewStore.serviceTypeExtraSearch = {
      ...serviceTypeExtraSearch,
      page: newPage
    };
    this.props.viewStore.serviceTypeExtras();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.serviceTypeExtraStore.deleteServiceTypeExtra(this.service)
      .then(this.onSuccessfulDeleteServiceTypeExtra.bind(this))
      .catch(this.onFailDeleteServiceTypeExtra.bind(this))
  }

  onSuccessfulDeleteServiceTypeExtra(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Service type extra deleted successfully!', intent: Intent.SUCCESS })
    this.service = null;
    this.isDeleteDialogVisible = false;
  }

  onFailDeleteServiceTypeExtra(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete service type extra!', intent: Intent.DANGER })
  }

  handleServiceTypeClick(event, service) {
    switch (event.label) {
      case 'Edit':
        this.service = service;
        this.props.viewStore.serviceTypeExtraShow({uuid: service.attributes.id})
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.service = service;
        break;
      default:
    }
  }

  copyServiceTypeExtra(service) {
    let {
      props: {
        serviceTypeExtraStore: {
          serviceTypeExtraDetail,
          serviceTypeExtraDetail: { 
            object
           }
        }
      }
    } = this;
    
    serviceTypeExtraDetail.object = service;
    serviceTypeExtraDetail.object.id = '';
    serviceTypeExtraDetail.object.service_type_extra_services_attributes = [];
    if(serviceTypeExtraDetail.object.service_type_extra_services){
      serviceTypeExtraDetail.object.service_type_extra_services && serviceTypeExtraDetail.object.service_type_extra_services.map((values) => {
        return(
          serviceTypeExtraDetail.object.service_type_extra_services_attributes.push({
            service_type_id:values.service_type_id,
            label:values.label
          })
        )
      })
    }
    

    this.props.serviceTypeExtraStore
      .createServiceTypeExtra()
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Service type copied successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to copy service type extra!",
          intent: Intent.DANGER
        });
      });
  }

  render() {
    let {
      viewStore,
      serviceTypeExtraStore: {
        serviceTypeExtra: {
          objects,
          meta
        }
      }
    } = this.props;

    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
          <table className="table table-width-ste table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Price </th>
                <th>Details</th>
                <th className="item-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                objects.map((services) => {
                  return (
                    <tr key={services.id}>
                      <td>{services.attributes.name}</td>
                      <td>{services.attributes.description}</td>
                      <td>{currencyFormattedAmount(services.attributes.price)}</td>
                      <td>
                        <div>
                          <div className="pricing-parameter-range">
                            <div className="pricing-parameter-heading">Duration: </div>
                            <div>{services.attributes.duration}</div>
                          </div>
                          <div className="pricing-parameter-range">
                            <div className="pricing-parameter-heading">Services:</div>
                            <div>
                              { 
                                services && services.attributes.service_types.length > 0 && services.attributes.service_types.map((service) => {
                                  return(
                                    <div>{ service.name }</div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="action-buttons" minimal={true}>
                          <Button title="Copy" minimal={true} small={true} onClick={() => this.copyServiceTypeExtra(services.attributes)}>
                            <img src="/icon_projects.svg"/>
                          </Button>
                          <Divider/>
                          <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.serviceTypeExtraShow({uuid: services.attributes.id})}>
                            <img src="/edit-light-icon.svg"/>
                          </Button>
                          <Divider/>
                          <Button title="Delete" minimal={true} small={true} onClick={() => this.handleServiceTypeClick({ label: 'Delete' }, services)}>
                            <img src="/delete-light-icon.svg" />
                          </Button>
                        </div>
                      </td>
                    </tr> 
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>Warnning! Deleting this service type will not be recoverable.</p>
          <p>Are you sure you want to do this?</p>
        </Alert>

        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>

      </React.Fragment>
    )
  }

}

export default ServiceTypeExtraTable;