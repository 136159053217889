import { observable, action, toJS } from 'mobx';
import { Api, CurrentEnv } from 'services/Api';
import stores from './index';
import axios from 'axios';

class ClientStore {
  @observable clients = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable importClients = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable showUploadAttachmentLoader = false;

  @observable clientAnalytics = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable jobs = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable client = {
    isLoading: false,
    isError: false,
    object: {
      name: null, 
      legal_name: null, 
      email: null, 
      phone: null, 
      fax: null, 
      terms: null,
      invoice_to: null, 
      ship_to: null, 
      description: null
    }
  };

  @observable location = {
    isLoading: false,
    isError: false,
    object: {
      full_address: null,
      latitude: null,
      longitude: null,
      place_id: null,
      notes: null
    }
  };

  @action
  clearLocationObject = () => {
    this.location.object = {
      full_address: null,
      latitude: null,
      longitude: null,
      place_id: null,
      notes: null
    }
  }

  @action
  clearClientObject = () => {
    this.client.object = {
      name: null, 
      legal_name: null, 
      email: null, 
      phone: null, 
      fax: null, 
      terms: null,
      invoice_to: null, 
      ship_to: null, 
      description: null
    }
  }

  @action
  createLocation() {
    this.location.isLoading = true;
    let promise = Api.post(`/locations`, {location: this.location.object})

    promise
      .then((response) => {
        this.clearLocationObject();
        this.location.isLoading = false;
        this.location.isError = false;
      })
      .catch((error) => {
        this.location.isLoading = false;
        this.location.isError = true;
      })
    
    return promise
  }

  @action
  createClient() {
    this.client.isLoading = true;
    let promise = Api.post(`/clients`, { client: this.client.object })

    promise
      .then((response) => {
        this.clearLocationObject();
        this.client.isLoading = false;
        this.client.isError = false;
      })
      .catch((error) => {
        this.client.isLoading = false;
        this.client.isError = true;
      })
    
    return promise
  }
 
  @action
  deleteClient(client) {
    this.clients.isLoading = true;
    let promise = Api.delete(`/clients/${client.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadClients();
      })
      .catch((error) => {
        this.clients.isLoading = false;
        this.clients.isError = true;
      })
    
    return promise
  }

  @action
  loadClients = () => {
    this.clients.isLoading = true;
    const {
      per_page,
      page,
      order,
      term
    } = stores.viewStore.clientSearch;
    let promise = Api.post('/clients/search', {
      search: {
        term: term,
        per_page: per_page,
        page: page,
        order: order
      }  
    });

    promise
      .then((response) => {
        this.clients.isLoading = false;
        this.clients.isError = false;
        this.clients.objects = response.data.data;
        this.clients.meta = response.data.meta;
      })
      .catch((error) => {
        this.clients.isLoading = false;
        this.clients.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadAnalytics = (params) => {
    this.clientAnalytics.isLoading = true;
    let promise = Api.get(`/clients/${params.uuid}/analytics`);

    promise
      .then((response) => {
        this.clientAnalytics.isLoading = false;
        this.clientAnalytics.isError = false;
        this.clientAnalytics.objects = response.data;
      })
      .catch((error) => {
        this.clients.isLoading = false;
        this.clients.isError = true;
      })
    
    return promise;
  }

  @action
  clearSelectedClient() {
    this.client = {
      isLoading: false,
      isError: false,
      client: null
    }
  }

  @action
  loadClient(params) {
    this.client.isLoading = true;
    let promise = Api.get(`/clients/${params.uuid}`)
    
    promise
      .then((response) => {
        this.client.isLoading = false;
        this.client.isError = false;
        this.client.object = response.data.data;
        stores.viewStore.clientShow(params,response.data.data.attributes.name);
      })
      .catch((error) => {
        this.client.isLoading = false;
        this.client.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  updateClient(client) {
    this.client.isLoading = true;
    let promise = Api.put(`/clients/${client.attributes.id}`, {client: toJS(client.attributes)})

    promise
      .then((response) => {
        this.client.isLoading = false;
        this.client.isError = false;
        stores.viewStore.clients();
      })
      .catch((error) => {
        this.client.isLoading = false;
        this.client.isError = true;
      })

    return promise;
  }

  @action
  uploadCsv(file, client_id = null) {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('client_id', client_id)
    this.showUploadAttachmentLoader = true;
    let promise = axios.post(`${CurrentEnv.domain()}/api/v2/clients/import_clients_csv`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Api-Token': stores.viewStore.currentUser.attributes.api_token,
          'X-Tenant':stores.viewStore.currentUser.attributes.tenant_id,
          'X-Client-Identifier': 'React-Web'
        }
      }
    )

    promise
      .then((response) => {
        this.importClients.objects = response.data;
        this.showUploadAttachmentLoader = false;
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = false;
      })

    return promise;

  }

  // @action
  // loadClientJobs() {
  //   this.jobs.isLoading = true;
  //   let promise = Api.get(`/clients/${params.uuid}/jobs`)
    
  //   promise
  //     .then((response) => {
  //       this.jobs.isLoading = false;
  //       this.jobs.isError = false;
  //       this.jobs.object = response.data.data
  //     })
  //     .catch((error) => {
  //       this.jobs.isLoading = false;
  //       this.jobs.isError = true;
  //     })

  //   return promise;
  // }

};

export default ClientStore;