import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { 
  Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
import ProductServiceModal from 'components/ProductsServices/ProductServiceModal';
import { currency, currencySymbol } from 'helpers/currency';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';

@inject('store', 'productServiceStore', 'viewStore') @observer
class ProductsServicesTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable isProductServiceDialogVisible = false;
  @observable product_service;
  @observable itemType = 'Labor';

  handleProductServiceClick(event, productService) {
    switch (event.label) {
      case 'Edit':
        this.product_service = productService;
        this.props.productServiceStore.productService.object = productService.attributes;
        this.isProductServiceDialogVisible = true;
        this.itemType = productService.attributes.product_service_type === 'Service' ? 'Labor' : 'Materials';
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.product_service = productService;
        break;
      default:
    }
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.productServiceStore.delete(this.product_service)
      .then(this.onSuccessfulDeleteProductService.bind(this))
      .catch(this.onFailedProductServiceDelete.bind(this))
  }

  onSuccessfulDeleteProductService(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Product/Service deleted successfully!', intent: Intent.SUCCESS })
    this.product_service = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedProductServiceDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete product/service!', intent: Intent.DANGER })
  }

  onCloseProductServiceDialog() {
    this.isProductServiceDialogVisible = false;
  }

  onSucessCreateProductService() {
    this.isProductServiceDialogVisible = false;
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        productServiceIndexSearch
      }
    } = this.props;
    this.props.viewStore.productServiceIndexSearch = {
      ...productServiceIndexSearch,
      page: newPage
    };
    this.props.productServiceStore.loadProductsServices();
  }

  render() {
    let {
      productServiceStore: {
        productsServices: {
          objects,
          meta
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning" style={{marginTop:'15px'}}>
          <h4 className="bp3-heading">No Results</h4>
        </div>
      )
    }
    return(
      <React.Fragment>
        <div className="card rounded-0 table-responsive">
          <table className="table table-data-presentation table-hover" style={{ width: '100%'}}>  
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Product Category</th>
              <th>Part Number</th>
              <th>Price ({`${currency()} ${currencySymbol()}`})</th>
              <th>Markup</th>
              {/* <th>Description</th> */}
              <th>Is Connected to QuickBooks?</th>
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((productService) => {
                return(
                  <tr key={productService.id}>
                    <td>
                      <TableCellWithSubtext
                        mainText={productService.attributes.name}
                        subText={productService.attributes.description}
                      />
                    </td>
                    <td>
                      { productService.attributes.product_service_type === 'Service' || productService.attributes.product_service_type === 'Services' ?
                        <TableCellWithSubtext
                          mainText={'Labor'}
                        />:
                        <TableCellWithSubtext
                          mainText={'Material'}
                        />
                      }
                      
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={productService.attributes.product_category && productService.attributes.product_category.title}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        subText={productService.attributes.part_number}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={productService.attributes.unit_price}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        subText={productService.attributes.mark_up}
                      />
                    </td>
                    <td>
                      {productService.attributes.external_mappings && productService.attributes.external_mappings.length > 0 ? 
                      <span className="qb-status-yes qb-status-style">{"Connected"}</span>
                      : 
                      <span className="qb-status-no qb-status-style">{"Not Connected"}</span>
                      }
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit" minimal={true} small={true} disabled={productService.attributes.is_readonly} onClick={() => this.handleProductServiceClick({ label: 'Edit' },  productService)}>
                          <img src="/edit-light-icon.svg" alt={'edit icon'}/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} disabled={productService.attributes.is_readonly} onClick={() => this.handleProductServiceClick({ label: 'Delete' }, productService)}>
                          <img src="/delete-light-icon.svg" alt={'delete icon'}/>
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
          <Footer>
            <div className="bp3-navbar-group bp3-align-left">
              <Pagination
                value={meta.page}
                total={meta.total_pages}
                onChange={this.onPaginationChange.bind(this)}
              />
            </div>
          </Footer>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to do this?
          </p>
        </Alert>
        <ProductServiceModal 
          isOpen={this.isProductServiceDialogVisible} 
          title={"Update Pricebook"}
          edit={true}
          onClose={this.onCloseProductServiceDialog.bind(this)}
          onSuccess={this.onSucessCreateProductService.bind(this)}
          itemType={this.itemType}
        />
      </React.Fragment>
    )
  }

};

export default ProductsServicesTable;