import React, { Component } from 'react';
import {
  Divider,
  Button,
  FormGroup,
  InputGroup,
  Checkbox,
  Radio,
  Tag
} from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import Card from 'components/Card';
import styled from '@emotion/styled';
import SettingsSidebar from '../../pages/preferences/settings-sidebar';
import { CurrentEnv } from '../../services/Api';

const PreferenceGrid = styled('div')`
  width: 100%;  
`
const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit * 0}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`


@inject('preferenceStore', 'viewStore', 'userStore') @observer
class PreferencesProfileCard extends Component {

  componentWillMount() {
    let { preference } = this.props;
    if (preference.custom_bambora_env === '') {
      preference.custom_bambora_env = "development"
    }
  }

  handlePreferenceChange(event, key) {
    if (key === "payment_gateway") {
      if (event.target.value == "bambora") {
        event.target.checked ? this.props.preference[key] = "bambora" : this.props.preference[key] = event.target.checked
      } else if (event.target.value == "stripe_connect") {
        event.target.checked ? this.props.preference[key] = "stripe_connect" : this.props.preference[key] = event.target.checked
      }
    } else {
      this.props.preference[key] = event.target.value;
    }
  }

  stripeConnectLink = () => {
    let {
      props: {
        viewStore: {
          currentUser: {
            attributes: {
              tenant_id,
              api_token
            }
          }
        }
      }
    } = this;
    return `${CurrentEnv.domain()}/auth/stripe_connect_redirect/?tenant_id=${tenant_id}&api_token=${api_token}`;
  }

  updatePreference() {
    this.props.preferenceStore.updatePreference()
      .then(this.onSuccessfulUpdatePreference.bind(this))
      .catch(this.onFailUpdatePreference.bind(this))
  }

  onSuccessfulUpdatePreference(response) {

  }

  onFailUpdatePreference(error) {

  }

  render() {
    let { preference } = this.props;
    return (
      <PreferenceGrid>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <SettingsSidebar />
          </div>
          <div className="col-lg-10">

            <PageWrapGray>
              <div className="pmt-gateway-wrap">
                <ul className="list-unstyled gateway-list row">
                  
                  <li className="col-sm-3">
                    <div className="pmg-box bg-white">
                      <img src="../stripe-logo.svg" />
                      <div className="pmg-details">
                        <p>Stripe</p>
                        <p><small>2.9% + $0.3 / transaction</small></p>
                        <Radio name="payment_gateway" value="stripe_connect" defaultChecked={preference.payment_gateway == "stripe_connect"} onChange={(event) => { this.handlePreferenceChange(event, 'payment_gateway') }} />
                        {/* <Checkbox defaultChecked={preference.payment_gateway == "stripe_connect"} onChange={(event) => { this.handlePreferenceChange(event, 'payment_gateway') }} /> */}
                      </div>
                    </div>
                  </li>
                  <li className="col-sm-3">
                    <div className="pmg-box bg-white">
                      <img src="../bambora-logo-mark.svg" />
                      <div className="pmg-details">
                        <p>Bambora</p>
                        <p><small>2.9% + $0.3 / transaction</small></p>
                        <Radio name="payment_gateway" value="bambora" defaultChecked={preference.payment_gateway == "bambora"} onChange={(event) => { this.handlePreferenceChange(event, 'payment_gateway') }} />
                        {/* <Checkbox defaultChecked={preference.payment_gateway == "bambora"} onChange={(event) => { this.handlePreferenceChange(event, 'payment_gateway') }} /> */}
                      </div>
                    </div>
                  </li>
                  {/* <li className="col-sm-3">
                      <div className="pmg-box">
                          <img src="../paypal-logo.svg" />
                          <div className="pmg-details">
                              <p>Paypal</p>
                              <p><small>0.5% (Per Transection)</small></p>
                              <Checkbox  />
                          </div>
                      </div>
                    </li>
                    <li className="col-sm-3">
                      <div className="pmg-box">
                        

                      </div>
                    </li> */}
                </ul>
              </div>




              <div>

                <div className="card">
                  <div className="card-body">
                    <Card>
                      <CardContent>
                        {/* <div style={{ textAlign: 'center', paddingBottom: '8px' }}>
              <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                Preferences
              </h4>
            </div>
            <Divider style={{ margin: '16px 0px' }}></Divider> */}
                        <FormGroup label="Invoice Due within Days">
                          <InputGroup placeholder="Invoice Due within Days" defaultValue={preference.invoice_due_within_days} onChange={(event) => { this.handlePreferenceChange(event, 'invoice_due_within_days') }} />
                        </FormGroup>

                        {/* <FormGroup
              label="Payment Gateway"
              labelFor="text-input"
            >
              <div className="bp3-select bp3-fill">
                <select defaultValue={preference.payment_gateway} onChange={(event) => { this.handlePreferenceChange(event, 'payment_gateway') }}>
                  <option value=''>Select Terms</option>
                  <option value='Bambora'>Bambora</option>
                </select>
              </div>
            </FormGroup> */}

                        {
                          preference.payment_gateway === 'bambora' &&



                          <fieldset>
                            <legend>Bambora Checkout Settings:</legend>
                            <FormGroup
                              label="Bambora Environment"
                              labelFor="text-input"
                            >
                              <div className="bp3-select bp3-fill">
                                <select defaultValue={preference.custom_bambora_env} onChange={(event) => { this.handlePreferenceChange(event, 'custom_bambora_env') }}>
                                  <option value=''>Select bambora payment enviornment</option>
                                  <option value='development'>Development</option>
                                  <option value='production'>Production</option>
                                </select>
                              </div>
                            </FormGroup>
                            <FormGroup label="Bambora Development Merchant Id">
                              <InputGroup placeholder="Bambora Merchant Id" defaultValue={preference.bambora_dev_merchant_id} onChange={(event) => { this.handlePreferenceChange(event, 'bambora_dev_merchant_id') }} />
                            </FormGroup>
                            <FormGroup label="Bambora Development Api Key">
                              <InputGroup type="password" placeholder="Bambora API Key" defaultValue={preference.bambora_dev_api_key} onChange={(event) => { this.handlePreferenceChange(event, 'bambora_dev_api_key') }} />
                            </FormGroup>
                            <FormGroup label="Bambora Production Merchant Id">
                              <InputGroup placeholder="Bambora Merchant Id" defaultValue={preference.bambora_pro_merchant_id} onChange={(event) => { this.handlePreferenceChange(event, 'bambora_pro_merchant_id') }} />
                            </FormGroup>
                            <FormGroup label="Bambora Production Api Key">
                              <InputGroup type="password" placeholder="Bambora API Key" defaultValue={preference.bambora_pro_api_key} onChange={(event) => { this.handlePreferenceChange(event, 'bambora_pro_api_key') }} />
                            </FormGroup>
                          </fieldset>

                        }

                        {
                          preference.payment_gateway === 'stripe_connect' && !preference.stripe_checkout_info &&
                          <fieldset>
                            <a href={`${this.stripeConnectLink()}`} class="connect-button">
                              <span>Connect with Stripe</span>
                            </a>
                          </fieldset>
                        }

                        {
                          preference.payment_gateway === 'stripe_connect' && preference.stripe_checkout_info &&
                          <fieldset>
                            <p><b>Name:</b> {preference.stripe_checkout_info.name}</p>
                            <p><b>Email:</b> {preference.stripe_checkout_info.email}</p>
                            <p><b>Nickname:</b> {preference.stripe_checkout_info.nickname}</p>
                            <p><b>Scope:</b> {preference.stripe_checkout_info.scope}</p>
                            <p><b>Livemode:</b> {preference.stripe_checkout_info.livemode ? "Yes" : "No"}</p>
                            <p><b>Connected:</b> <Tag intent="primary">Yes</Tag></p>
                            <a href={`${this.stripeConnectLink()}`} class="connect-button">
                              <span>Re-connect with Stripe</span>
                            </a>
                          </fieldset>
                        }
                        <Button icon="floppy-disk" text="Save" onClick={this.updatePreference.bind(this)} />

                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            </PageWrapGray>
          </div>
        </div>
      </PreferenceGrid>
    )
  }

};

export default PreferencesProfileCard;