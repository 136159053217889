import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from '@emotion/styled';
import qs from 'qs';
import _ from 'lodash';
import { observable } from 'mobx';

const SecondSidebar = styled('div')`     
    width: 100%;
    height: calc(100vh - 75px);
    border-right: 1px solid #E6E8F1;
    padding: 0px 0px 15px;       
    background-color:#ffffff;   
    position: sticky;
    top: 45px;    
`

@inject('viewStore') @observer
class BookingsSidebar extends Component {

  @observable isOpenBillingSetting = false;

  render(){
    let {
      viewStore
    } = this.props;
    return(
      <SecondSidebar className="sidebar-mobile"> 
          <div className="mobile-dropdown">
            <div className="setting-icon"><img src="/settings.svg" onClick={() => this.isOpenBillingSetting = !this.isOpenBillingSetting}/></div>
            <div className={this.isOpenBillingSetting ? "ssidebar-wrap " : "ssidebar-wrap ssidebar-wrap-hide" }>
  
              <ul className="list-unstyled setting-list list-max-height">                           
                <li className={viewStore.currentView === "Pages.Bookings.Index" ? 'active' : ''}>
                  <a href={`/dashboard/bookings?${qs.stringify(viewStore.bookingSearch, { encode: false })}`}>
                  Booking Requests
                  </a>
                </li>
                <li className={
                  viewStore.currentView === "Pages.Frequency.Index" ? 'active' : ''
                  || viewStore.currentView === "Pages.Frequency.Add" ? 'active' : ''
                  || viewStore.currentView === "Pages.Frequency.Show" ? 'active' : ''
                  }
                >
                  <a href={'/dashboard/frequency'} >Frequencies </a>
                </li>
                
                <li className={
                    viewStore.currentView === "Pages.ServiceTypeCategory.Index" ? 'active' : ''
                    || viewStore.currentView === "Pages.ServiceTypeCategory.Add" ? 'active' : ''
                    || viewStore.currentView === "Pages.ServiceTypeCategory.Show" ? 'active' : ''
                  }
                >
                  <a href={'/service_type_category'} >Service Type Category</a>
                </li>
                <li className={
                    viewStore.currentView === "Pages.ServiceType.Index" ? 'active' : ''
                    || viewStore.currentView === "Pages.ServiceType.Add" ? 'active' : ''
                    || viewStore.currentView === "Pages.ServiceType.Show" ? 'active' : ''
                  }
                >
                  <a href={'/dashboard/service-type'} >Service Types</a>
                </li>
                <li className={
                    viewStore.currentView === "Pages.ServiceTypeExtra.Index" ? 'active' : ''
                    || viewStore.currentView === "Pages.ServiceTypeExtra.Add" ? 'active' : '' 
                    || viewStore.currentView === "Pages.ServiceTypeExtra.Show" ? 'active' : '' 
                  }
                >
                  <a href={'/dashboard/service-type-extra'} >Service Type Extras</a>
                </li>
                <li className={
                    viewStore.currentView === "Pages.PricingParameter.Index" ? 'active' : ''
                    || viewStore.currentView === "Pages.PricingParameter.Add" ? 'active' : '' 
                    || viewStore.currentView === "Pages.PricingParameter.Show" ? 'active' : ''
                  }
                >
                  <a href={'/dashboard/pricing-parameter'} >Pricing Parameters</a>
                </li>
                <li className={
                    viewStore.currentView === "Pages.QuestionGroup.Index" ? 'active' : ''
                    || viewStore.currentView === "Pages.QuestionGroup.Add" ? 'active' : '' 
                    || viewStore.currentView === "Pages.QuestionGroup.Show" ? 'active' : ''
                  }
                >
                  <a href={'/dashboard/question-group'} >Question Group</a>
                </li>
                <li className={
                    viewStore.currentView === "Pages.Question.Index" ? 'active' : ''
                    || viewStore.currentView === "Pages.Question.Add" ? 'active' : '' 
                    || viewStore.currentView === "Pages.Question.Show" ? 'active' : ''
                  }
                >
                  <a href={'/dashboard/question'} >Questions</a>
                </li>
                <li className={viewStore.currentView === "Pages.Bookings.BookingConfiguration" ? 'active' : ''}>
                  <a href={`/dashboard/bookings/booking-configuration`}>
                    Booking Configurations
                  </a>
                </li>
              </ul>            
            </div>
          </div>
        </SecondSidebar>
    )
  }
}
  
export default BookingsSidebar;