import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { 
  Button,FormGroup,Checkbox, Radio,
  Intent, Spinner, RadioGroup
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import styled from '@emotion/styled';

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);
  width: 100%;
`

@inject('bookingStore') @observer
class QuestionRadio extends React.Component {
	@observable isChecked = false;

  onQuestionRadioChange = (answer,question) => {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    if(object && object.attributes){
      let index = object.attributes.booking_questions.map(function(item) { return item.question_id; }).indexOf(question.id);
      if(index > -1){
        object.attributes.booking_questions[index].answer = answer.split(":")[0];
      }else{
        object.attributes.booking_questions.push({answer:answer.split(":")[0],question_id:question.id});
      }

    }else{
      let index = object.booking_questions_attributes.map(function(item) { return item.question_id; }).indexOf(question.id);
      if(index > -1){
        object.booking_questions_attributes[index].answer = answer.split(":")[0];
      }else{
        object.booking_questions_attributes.push({answer:answer.split(":")[0],question_id:question.id});
      }
    }
    
  }

  render() {
    let {
			question, answer, checkedValue, selectedAnswer, bookingStatus
		} = this.props;
    return(
			<Radio 
				inline= {true}
        name={`radio_question${question.id}`}
        disabled={bookingStatus && bookingStatus === 'confirmed' ? true : false}
				label={answer.split(":")[0]}
				defaultChecked={checkedValue === question.id && (answer.split(":")[0]).toLowerCase() === selectedAnswer.toLowerCase()}
				onChange={(event) => {this.onQuestionRadioChange(answer.split(":")[0],question)}} 
			/> 
    )
  }
}



export default QuestionRadio;