import React, { Component } from 'react';
import Avatar from '@atlaskit/avatar';
import {
  Button, ButtonGroup, Tag,
  Divider, Alert, Intent
} from "@blueprintjs/core";
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import styled from '@emotion/styled';
import Toast from 'helpers/toaster';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import qs from 'qs';

const TableHeader = styled('th')`
  padding-left: ${props => `${props.theme.unit*3}px !important`};
`
const TableData = styled('td')`
  padding-left: ${props => `${props.theme.unit*3}px !important`};
`

@inject('dashboard', 'viewStore', 'userStore') @observer
class UsersTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable user;

  handleUserDeleteClick(user) {
    this.isDeleteDialogVisible = true;
    this.user = user;
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
    this.user = null;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.viewStore.deleteUser(this.user)
      .then(this.onSuccessfulDeleteUser.bind(this))
      .catch(this.onFailedUserDelete.bind(this))
  }

  onSuccessfulDeleteUser(response) {
    Toast.show({ icon: 'thumbs-up', message: 'User deleted successfully!', intent: Intent.SUCCESS })
    this.user = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedUserDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete user!', intent: Intent.DANGER })
  }

  resendInvite(invitation){
    this.props.userStore.resendUserInvite(invitation.attributes.id)
      .then(() => {
        Toast.show({ message: "Invite resent", intent: Intent.SUCCESS })
      })
  }

  copyInvitationLink(invitation){
    return(`${window.location.origin}/users/invitation/${invitation.attributes.token}`);
  }

  renderNameWithAvatar(user) {
    let {
      viewStore,
      viewStore: {
        userSearch: {
          section
        }
      }
    } = this.props;
    let common = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar src={user.attributes.avatar_url} size='large' />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div className="name">{user.attributes.full_name}</div>
          <div style={{marginLeft: 15}}>
            <Tag intent={user.attributes.is_active ? 'success' : 'danger'} minimal={true}>
              {user.attributes.is_active ? 'Active' : "Inactive"}
            </Tag>
          </div>
        </div>
      </div>
    )

    if (section === 'invitations') {
      return (
        <a className="name-avtar">
          {common}
        </a>
      )
    }

    return (
      <a className="name-avtar" href={`/dashboard/users/${user.attributes.id}/show?${qs.stringify(viewStore.userShowSearch, { encode: false })}`} >
        {common}
      </a>
    )
  }

  render() {
    let {
      viewStore,
      viewStore: {
        userSearch: {
          section
        }
      },
      dashboard: {
        users: {
          objects
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="invite-illustration">
          <img src="/invite-Illustration.svg" alt="Invite-Illustration" />
        </div>
      )
    }
    return (
      <div style={{'width' : '100%'}}>
        <div className="card rounded-0 table-responsive">
        <table className="table table-hover" width='100%'>
          <thead>
            <tr>
              <TableHeader>Name</TableHeader>
              <TableHeader>Email</TableHeader>
              <TableHeader>Phone</TableHeader>
              <TableHeader>Role</TableHeader>
              <TableHeader className="item-center">Actions</TableHeader>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((user) => {
                return (
                  <tr key={user.id}>
                    <TableData>
                      {this.renderNameWithAvatar(user)}
                    </TableData>
                    <TableData>{user.attributes.email}</TableData>
                    <TableData>{user.attributes.formatted_phone_number || user.attributes.formatted_phone_number}</TableData>
                    <TableData>{user.attributes.role}</TableData>
                    <TableData>
                    {
                        !user.attributes.is_invitation &&
                          <ButtonGroup className="action-buttons" minimal={true}>
                            <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.userShow({uuid: user.attributes.id})}>
                              <img src="/edit-light-icon.svg" alt={'edit icon'}/>
                            </Button>
                            <Divider/>
                            <Button title="Delete" minimal={true} small={true} disabled={user.id === viewStore.currentUser.id ? true : false} onClick={() => this.handleUserDeleteClick(user)}>
                              <img src="/delete-light-icon.svg" alt={'delete icon'}/>
                            </Button>
                          </ButtonGroup>
                      }
                      {
                        user.attributes.is_invitation &&
                          <ButtonGroup minimal={true}>
                            <Button 
                              minimal={true} 
                              small={true} 
                              onClick={this.resendInvite.bind(this, user)}
                            >
                              Re-send invite
                            </Button>
                            <Divider/>
                            <CopyToClipboard 
                              text={this.copyInvitationLink(user)}
                              onCopy={() => Toast.show({ message: "Copied!", intent: Intent.SUCCESS })}>
                              <Button
                                minimal={true} 
                                small={true} 
                              >
                                Copy invitation link
                              </Button>
                            </CopyToClipboard>
                          </ButtonGroup>
                      }
                    </TableData>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to delete <b>{this.user && this.user.attributes.full_name}</b> user?
          </p>
        </Alert>
      </div>
    )
  }
}

export default UsersTable;
