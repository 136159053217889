import React, { Component } from 'react';
import { inject } from 'mobx-react';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import { Helmet } from 'react-helmet';

@inject('viewStore')
class LoggedOut extends Component {
  render() {
    return (
      <UnauthenticatedPage>
        <Helmet>
          <title>Logged Out | FieldNexus</title>
        </Helmet>
        <div></div>
        <div>
          <h1>Logged Out!</h1>
          <a onClick={() => { this.props.viewStore.forgetPassword() }} >forgot password</a>
        </div>
        <div></div>
      </UnauthenticatedPage>
    )
  }
}

export default LoggedOut;