import React from 'react';
import Avatar from '@atlaskit/avatar';
import { Spinner } from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer,  inject} from 'mobx-react';
import { Api } from 'services/Api';
import { formatTime } from '../../helpers/time';
import moment from 'moment';

@inject('store', 'viewStore','notificationStore') @observer
class Logs extends React.Component {

  @observable timeLogs = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    let { job } = this.props;
    this.props.viewStore.notificationSearch.where.trackable_id = job.attributes.id;
    let {
      notificationStore,
      notificationStore: {
        notifications: {
          objects
        }    
      }
    } = this.props;
    notificationStore.loadNotifications(); 
  }

  render() {
    let {
			viewStore,
			notificationStore: {
				notifications: {
          isLoading,
					objects,
					meta
				}
			}
		} = this.props;
    if (isLoading) {
      return (<div className="spinner-center"><Spinner size='25' /></div>)
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No logs have been found for this job yet.
        </div>
      )
    }
    return (
      <div>
          {
            objects.map((notification) => {
              return (
                <div className="notification-wrap" style={{marginLeft: 10, marginRight: 10}} key={Math.random()}>
                  <div className="notification-header-title">
                    <div dangerouslySetInnerHTML={{ __html: notification.attributes.message.html }}></div>
                  </div>
                  <div className="notification-header">
                    <div className="notification-user-details">
                      <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=${notification.attributes.owner.name}&size=34&background=2D4EF5&color=fff`)} size='small' /> 
                      <div className="user-name-timestamp" style={{marginLeft:10}}>{notification.attributes.owner.name}</div>
                    </div>
                    <div className="user-name-timestamp">{moment(notification.attributes.created_at).fromNow()}</div>
                  </div>
                  <div className="notification-content">
                    <span className="notification-activity-log-name">Reason For Call:</span>
                    <span class="notification-activity-log-changes"><del>Lipsum test always rock</del> <span className="new-update">new text</span></span>
                  </div>
                  <div className="notification-content">
                    <span className="notification-activity-log-name">Job Discription:</span>
                    <span className="notification-activity-log-changes">Lipsum test always rock Testing job and create job from App Lipsum test always rock Testing job and create job from App Lipsum test always rock Testing job and create job from App</span>
                  </div>
                  <div className="notification-content">
                    <span className="notification-activity-log-name">Send Remind to Technician:</span>
                    <span className="notification-activity-log-changes">Send reminder to technicain</span>
                  </div>
                  <div className="notification-content">
                    <span className="notification-activity-log-name">Update Start and Time:</span>
                    <span className="notification-activity-log-changes"><del>11, jan 2020</del> <span className="new-update">15, Aug 2020</span></span>
                  </div>
                  <div className="notification-content">
                    <span className="notification-activity-log-name">Update Assigned To:</span>
                    <span className="notification-activity-log-changes"><del>Jack smith</del> <span className="new-update">Ethan Hunt</span></span>
                  </div>
                {/* <div className="notification-header" style={{ 'backgroundColor':'#4431FF'}}>
                  <div className="head-content" onClick={() => this.handleClick({ label: notification.attributes.trackable.type }, notification.attributes.trackable)}>
                    <div>
                      <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=${notification.attributes.owner.name}&size=34&background=2D4EF5&color=fff`)} size='small' /> 
                      <div style={{marginLeft:10}}>{notification.attributes.owner.name}</div>
                    </div>
                    <div>{moment(notification.attributes.created_at).fromNow()}</div>
                  </div>
                  <div className="headr-white-text" style={{'color':'#fff'}}>
                    <div style={{display:'flex',alignItems:'center'}} onClick={() => viewStore.userShow({uuid: notification.attributes.owner.id})}>
                      <div dangerouslySetInnerHTML={{ __html: notification.attributes.message.html }}></div>
                    </div>
                    
                  </div>
                </div>
                <div className="notification-content" onClick={() => this.handleClick({ label: notification.attributes.trackable.type }, notification.attributes.trackable)}>
                  <ul className="list-unstyled mb-0" >
                    <li>
                      <div dangerouslySetInnerHTML={{ __html: notification.attributes.message.short_info }}></div>
                    </li>
                  </ul>
                </div> */}
              </div>
              )
            })
          }
        </div>
    )
  }
}

export default Logs;