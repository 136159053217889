import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import FooterIllustration from 'components/FooterIllustration';
import { Button } from "@blueprintjs/core";
import { Helmet } from 'react-helmet';
import { TopNavbar } from 'components/Styles';
import MainSiteLogo from 'components/MainSiteLogo';
import NewUserSidebar from './new_user_sidebar';
@inject('viewStore', 'userStore') @observer
class SignupCompanyTeamSize extends Component {

  @observable isDisabled = true;

  componentDidMount() {
    this.checkNextButton();
  }

  componentDidUpdate() {
    this.checkNextButton();
  }

  handleCompanyTeamChange(event) {
    const {
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;
    object.team_size = event.target.value;
    this.checkNextButton();
  }

  checkNextButton() {
    const {
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;
    if(object.team_size !== '') {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.navigateToNextScreen()
    }
  }

  navigateToNextScreen() {
    const {
      viewStore
    } = this.props;
    viewStore.signupDetails();
    this.props.userStore.saveUserSignupToLocalStorage();
  }

  render() {
    const {
      viewStore,
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;
    return(
      <UnauthenticatedPage>
        <Helmet>
          <title>Signup | FieldNexus</title>
        </Helmet>
        <TopNavbar>
          <div className="tp-nav">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-7">
                  <MainSiteLogo />
                </div>
                <div className="col-5 text-right">
                  <div className="right-link-tp">
                    <div className="top-btn-back"><a onClick={() => viewStore.signupCompanyType()}><img src="/back.svg" title="Go Back" alt='Back' /> Go Back</a> 4/5</div>  
                  </div>
                </div>
              </div>
            </div>      
          </div>
        </TopNavbar>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-9 d-flex align-items-center sign-up">
            <div className="form-wrap">
              <p>Introduce your business</p>
              <h3 className="login-heading">Tell us the size of your team.</h3>
              <div className="row input-wrap">
                <div className="col-sm-12">
                  <select 
                    autoFocus 
                    className="form-control" 
                    value={object.team_size} 
                    onChange={this.handleCompanyTeamChange.bind(this)} 
                    defaultValue={object.team_size}
                    onKeyPress={this.onEnter.bind(this)}
                  >
                    <option value="">Select your team size</option>
                    <option value="1-5">1-5 Members</option>
                    <option value="5-25">5-25 Members</option>
                    <option value="25-50">25-50 Members</option>
                    <option value="50+">50+ Members</option>
                  </select>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-sm-6 col-5 fg-pass">
                  <a onClick={() => viewStore.signupCompanyType()}>Previous Step</a>
                </div>
                <div className="col-sm-6 col-7 fg-next">
                  <Button
                    className="btn-gray"
                    disabled={this.isDisabled}
                    onClick={this.navigateToNextScreen.bind(this)} >
                    Next
                  </Button>
                </div>
              </div>
              <h6 className="d-none d-sm-none d-md-block text-right">Or Press ENTER</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <NewUserSidebar />
          </div>
        </div>
        </div>
        <FooterIllustration />
      </UnauthenticatedPage>
    )
  }
};

export default SignupCompanyTeamSize;