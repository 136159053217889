import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  Button,
  Dialog,
  Classes,
  Intent,
  TextArea
} from "@blueprintjs/core";
import Toast from "helpers/toaster";

@inject("clientStore")
@observer
class AddClientModal extends Component {
  handleCloseClientDailog() {
    this.props.onClose();
  }

  createClient() {
    let { object } = this.props.clientStore.client;

    if(object.name === '' || object.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter name.', intent: Intent.DANGER })
      return;
    }
    if(object.legal_name === '' || object.legal_name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter legal name.', intent: Intent.DANGER })
      return;
    }
    if(object.email === '' || object.email === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid email', intent: Intent.DANGER })
      return;
    }
    if(object.phone === '' || object.phone === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid phone.', intent: Intent.DANGER })
      return;
    }
    

    this.props.clientStore
      .createClient()
      .then(this.onSuccessfulAddClient.bind(this))
      .catch(this.onFailAddClient.bind(this));
  }

  onSuccessfulAddClient(response) {
    this.props.onSuccess(response.data.data);
  }

  onFailAddClient(error) {
    Toast.show({
      icon: "warning-sign",
      message: error.response.data.errors.join(", "),
      intent: Intent.DANGER
    });
    console.log(error);
  }

  render() {
    let { object } = this.props.clientStore.client;
    return (
      <Dialog
        title="Add Client"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseClientDailog.bind(this)}        
        style={{width:'700px'}}
      >
        <div className={Classes.DIALOG_BODY}>
          <p><small>All the fields, marked with star (*) are mandatory.</small></p>
          <div className="row">
            <div className="col-6">
              <p>
                <strong>Personal Details</strong>
              </p>
              <hr />
              <FormGroup label="Name" labelFor="text-input" labelInfo="*">
                <input
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Name"
                  onChange={event => {
                    object.name = event.target.value;
                  }}
                />
              </FormGroup>
              <FormGroup
                label="Legal Name"
                labelFor="text-input"
                labelInfo="*"
              >
                <input
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Legal Name"
                  onChange={event => {
                    object.legal_name = event.target.value;
                  }}
                />
              </FormGroup>
              <FormGroup label="Email" labelFor="text-input" labelInfo="*">
                <input
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Email"
                  onChange={event => {
                    object.email = event.target.value;
                  }}
                />
              </FormGroup>
              <FormGroup label="Phone" labelFor="text-input" labelInfo="*">
                <input
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Phone"
                  onChange={event => {
                    object.phone = event.target.value;
                  }}
                />
              </FormGroup>
              <FormGroup label="Fax" labelFor="text-input" >
                <input
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Fax"
                  onChange={event => {
                    object.fax = event.target.value;
                  }}
                />
              </FormGroup>
            </div>
            <div className="col-6">
              <p>
                <strong>Business Details</strong>
              </p>
              <hr />

              <FormGroup label="Terms" labelFor="text-input" labelInfo="">
                {/* <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Terms'
              onChange={(event) => { object.terms = event.target.value } } 
            />
             */}
                <div className="bp3-select bp3-fill">
                  <select
                    onChange={event => {
                      object.terms = event.target.value;
                    }}
                  >
                    <option value="" disabled selected>Select Terms</option>
                    <option value="Net 15">Net 15</option>
                    <option value="Net 30">Net 30</option>
                    <option value="Net 45">Net 45</option>
                    <option value="Net 60">Net 60</option>
                    <option value="Upon Completion">Upon Completion</option>
                  </select>
                </div>
              </FormGroup>
              <FormGroup
                label="Invoice To"
                labelFor="text-input"
                
              >
                <TextArea
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Invoice To"
                  onChange={event => {
                    object.invoice_to = event.target.value;
                  }}
                />
              </FormGroup>
              <FormGroup label="Ship To" labelFor="text-input" >
                <TextArea
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Ship To"
                  onChange={event => {
                    object.ship_to = event.target.value;
                  }}
                />
              </FormGroup>
              <FormGroup
                label="Description"
                labelFor="text-input"
                labelInfo=""
              >
                <TextArea
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Description"
                  style={{height:'80px'}}
                  onChange={event => {
                    object.description = event.target.value;
                  }}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.handleCloseClientDailog.bind(this)}
              style={{ width: "100px" }}
            >
              Cancel
            </Button>
            <Button
              intent={Intent.PRIMARY}
              icon="add"
              loading={this.props.clientStore.client.isLoading}
              onClick={this.createClient.bind(this)}
              style={{ width: "200px" }}
            >
              Add Client
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddClientModal;
