import React, { Component } from 'react';
import qs from 'qs';
import { observer, inject } from 'mobx-react';

@inject('viewStore') @observer
class AnalyticsCardSupplier extends Component {

  render() {
    let props = this.props;
    let { viewStore } = this.props;
    let urlResource = '';
    
    if(props.cardTitle == "Materials"){
      urlResource = 'materials';
    }else if(props.cardTitle == "Purchase Order"){
      urlResource = 'purchase_orders';
    }else{
      urlResource = '';
    }
    
    return(
      <div className={`analytics-container ${(props.active ? 'active' : '')}`} onClick={props.onClick}>
        <a href={`/dashboard/suppliers/${this.props.cardId}/show`}>
          <div className="card-title-container">          
            <div className="card-title">              
              {props.cardTitle}
            </div>
          </div>
        </a>
        <a href={`/dashboard/suppliers/${this.props.cardId}/show`}>
          <div className="total-value-container">
            <div className="total-value">
              {props.count}            
            </div>
          </div>
        </a>
          {
            props.breakdown && props.breakdown.length && props.breakdown.length > 0 && props.breakdown.map((info) => {
              return (
                
                <div className="info-container" key={Math.random()}>                  
                  <div className="info-text">
                    {info.name}
                  </div>
                  <div className="info-value">
                    {info.count}
                  </div>                  
                </div>
                
              )
            })
          }
      </div>
    )
  }

}

export default AnalyticsCardSupplier;