import React, { Component } from 'react'
import { 
  Button
} from '@blueprintjs/core';
import { action } from 'mobx';
import { observer, inject } from 'mobx-react';

@inject('store', 'viewStore') @observer
class QuestionGroupSearch extends Component {

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.questionGroupSearch.page = 1;
    this.props.viewStore.questionGroups();
  }

  @action
  onSearchTermChange(e) {
    let {
      props: {
        viewStore: {
          questionGroupSearch
        }
      }
    } = this;
    this.props.viewStore.questionGroupSearch = {
      ...questionGroupSearch,
      term: e.target.value
    }
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.search()
    }
  }

  render() {
    let {
      props: {
        viewStore,
        store,
        viewStore: {
          questionGroupSearch: { term }
        }
      }
    } = this;
    if(term == '*'){
      term = '';
    }
    return (
      <nav className="bp3-navbar search bg-transparent">
        <div className="booking-request-search">
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <input 
                  type="text"
                  className="bp3-input"
                  placeholder="Label"
                  style={{ width: '300px' }}
                  onChange={this.onSearchTermChange.bind(this)}
                  defaultValue={term}
                  onKeyPress={this.onEnter.bind(this)}
                />
                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
              </div>
            </div>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            <Button icon={'plus'} small={true} onClick={() => viewStore.questionGroupAdd()}>
              Add Question Group
            </Button>
          </div>
        </div>
      </nav>
    )
  }
}

export default QuestionGroupSearch;