import { observable, action, toJS } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

const EMPTY_QUESTION_OBJECT = {
  field_label: null,
  field_type: null,
  field_value: null,
  is_required: null,
  service_type_id: null,
}

class QuestionStore {

  @observable questions = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable question = {
    isLoading: false,
    isError: false,
    object: EMPTY_QUESTION_OBJECT
  };

  @observable EMPTY_QUESTION_OBJECT = {
    isLoading: false,
    isError: false
  };

  @action
  deleteQuestion(question) {
    this.question.isLoading = true;
    let promise = Api.delete(`/questions/${question.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadQuestions();
      })
      .catch((error) => {
        this.question.isLoading = false;
        this.question.isError = true;
      })
    
    return promise
  }

  @action
  updateQuestion(object) {
    let params = {
      uuid: object.attributes.id
    }
    this.question.isLoading = true;
    let promise = Api.put(`/questions/${object.attributes.id}`, {question: toJS(object.attributes)})

    promise
      .then((response) => {
        this.question.isLoading = false;
        this.question.isError = false;
        stores.viewStore.questionSearch.term = '*';
        stores.viewStore.questions();
        stores.question.loadQuestions();  
      })
      .catch((error) => {
        this.question.isLoading = false;
        this.question.isError = true;
      })

    return promise;
  }

  @action
  createQuestion() {
    this.question.isLoading = true;
    let promise = Api.post(`/questions/`, {question: this.question.object})   

    promise
      .then((response) => {
        this.question.isLoading = false;
        this.question.isError = false;       
        this.question.object = response.data.data;  
        stores.viewStore.questions();
        stores.question.loadQuestions();    
      })
      .catch((error) => {
        this.question.isLoading = false;
        this.question.isError = true;       
      })

    return promise;
  }
  

  @action
  loadQuestions = () => {
    this.questions.isLoading = true;
    const {
      per_page,
      page,
      order,
      where,
      term
    } = stores.viewStore.questionSearch;
    let promise = Api.post('/questions/search', { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.questions.isLoading = false;
        this.questions.isError = false;
        this.questions.objects = response.data.data;
        this.questions.meta = response.data.meta;
      })
      .catch((error) => {
        this.questions.isLoading = false;
        this.questions.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadQuestion = (params) => {
    this.question.isLoading = true;
    let promise = Api.get(`/questions/${params.uuid}`)

    promise
      .then((response) => {
        this.question.isLoading = false;
        this.question.object = response.data.data;

        stores.viewStore.questionShow(params,response.data.data.attributes.field_label);
      })
      .catch((error) => {
        this.question.isLoading = false;
        this.question.isError = true;
        console.error(error)
      })

    return promise;
  }

  

};

export default QuestionStore;