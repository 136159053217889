import React from 'react'
import { Button, Spinner, Menu, MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Api } from 'services/Api';
import axios from 'axios';
import debounce from 'services/debounce';

const ClientRenderer = (client, { handleClick, modifiers }) => {
  return (
    <MenuItem
      active={modifiers.active}
      key={client.id}
      label={client.attributes.email}
      onClick={handleClick}
      text={client.attributes.name}
    />
  );
};

const ListRenderer = ({ items, itemsParentRef, query, renderItem, loading, failed }) => {
  const renderedItems = items.map(renderItem).filter(item => item != null);
  return (
    <Menu ulRef={itemsParentRef}>
      {
        failed && !loading && <MenuItem disabled={true} text={'Failed to load results :('} />
      }
      {
        loading && !failed && <MenuItem disabled={true} text={'Loading..'} label={<Spinner size={20}/>} />
      }
      {
        !loading && !failed && <MenuItem
                      disabled={true}
                      text={`Found ${renderedItems.length} clients matching "${query}"`}
                    />
      }
      {renderedItems}
    </Menu>
  );
}

@observer
class ClientAutocomplete extends React.Component {

  @observable loading = true;
  @observable failed = false;
  @observable results = [];
  @observable selectedClient = null;

  componentDidUpdate(){
    if(this.props.value){
      this.selectedClient = this.props.value;
    }
  }

  componentDidMount() {
    let {
      selectedClientId
    } = this.props;
    if (selectedClientId) {
      let promise = Api.get(`/clients/${selectedClientId}`)
      promise.then(response => {
        this.selectedClient = response.data.data;
      })
    }
    if(this.props.value){
      this.selectedClient = this.props.value;
    }
  }

  @action
  onQueryChange(query) {
    this.failed = false;
    this.loading = true;
    if (query === '') {
      return;
    }
    let promise = Api.post('/clients/search', { search: { term: query, per_page: 50, page: 1, order: { name: 'asc' } } })
    promise
      .then(this.onClientsLoad.bind(this))
      .catch(this.onClientsLoadFail.bind(this))
    return promise;
  }

  @action
  onClientsLoadFail(error) {
    if (axios.isCancel(error)) {
      this.loading = true;
    } else {
      this.loading = false;
      this.failed = true;
    }
  }

  @action
  onClientsLoad(response) {
    this.loading = false;
    this.failed = false;
    this.results = response.data.data;
  }

  @action
  onClientSelect(client) {
    this.selectedClient = client;
    this.props.onClientSelect(client);
  }

  @action
  clearSelectedClient() {
    this.selectedClient = null;
    this.props.onClientSelect(null);
  }

  render() {
    let {
      failed,
      results,
      onQueryChange,
      loading,
      onClientSelect,
      selectedClient,
      clearSelectedClient,
      props: {
        inputProps
      }
    } = this;
    return(
      <div className="client-name-field">
        {
          selectedClient ? <Button 
              text={this.selectedClient.attributes.name} 
              rightIcon='cross'
              onClick={clearSelectedClient.bind(this)}
            /> : <Suggest
              inputProps={{
                icon: 'cross',
                placeholder: 'Search client...',
                ...inputProps
              }}
              closeOnSelect={true}
              openOnKeyDown={true}
              resetOnQuery={true}
              resetOnSelect={true}
              popoverProps={{ minimal: true }}
              onQueryChange={debounce(onQueryChange.bind(this)).bind(this)}
              items={toJS(results)}
              itemRenderer={ClientRenderer}
              itemListRenderer={(props) => <ListRenderer {...props} loading={loading} failed={failed} />}
              inputValueRenderer={(client) => client.attributes.name }
              onItemSelect={onClientSelect.bind(this)}
            />
        }
      </div>
    )
  }
}

ClientAutocomplete.propTypes = {
  inputProps: PropTypes.object,
  onClientSelect: PropTypes.func.isRequired
}

export default ClientAutocomplete;