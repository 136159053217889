import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { Button, Divider, MenuItem, Alert, Intent } from '@blueprintjs/core';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
import qs from 'qs';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';

@inject('store', 'supplierStore', 'viewStore') @observer
class SuppliersTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable client;

  

  onPaginationChange(newPage) {
    let {
      viewStore: {
        supplierSearch
      }
    } = this.props;
    this.props.viewStore.supplierSearch = {
      ...supplierSearch,
      page: newPage
    };
    this.props.viewStore.suppliers();
  }

  

  handleSupplierClick(event, supplier) {
    switch (event.label) {
      case 'Edit':
        this.supplier = supplier;
        this.props.viewStore.supplierShow({uuid: supplier.attributes.id})
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.supplier = supplier;
        break;
      default:
    }
  }

  render() {
    let {
      viewStore,
      supplierStore,
      supplierStore: {
        suppliers: {
          objects,
          meta
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
        <table className="table table-vertical-align table-data-presentation" style={{ width: '100%' }}>
          <thead>
            <tr>           
              <th>Name</th>
              <th>Contact Details</th>
              <th>Address</th>
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((supplier,key) => {
                return (
                  <tr key={supplier.id}>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/suppliers/${supplier.attributes.id}/show`}
                        mainText={supplier.attributes.name}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={supplier.attributes.email}
                        subText={supplier.attributes.phone}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        subText={supplier.attributes.address}
                      />
                    </td>
                    <td className="item-center">
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit Details" minimal={true} small={true} onClick={() => viewStore.supplierShow({uuid: supplier.attributes.id})}>
                          <img src="/edit-light-icon.svg"/>
                        </Button>
                      </div>
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>

        </div>
       
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </React.Fragment>
    )
  }
}

export default SuppliersTable;