import { observable, action, toJS } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';
import moment from 'moment';

const EMPTY_SPOT_OBJECT = {
  booking_slots:[]
}


class SpotStore {
  
  @observable spots = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable spot = {
    isLoading: false,
    isError: false,
    object: EMPTY_SPOT_OBJECT
  };

};

export default SpotStore;