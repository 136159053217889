import React, { Component } from 'react';
import styled from '@emotion/styled';

const StyledParent = styled('div')`
  //background-color: ${props => props.theme.global.white};
  // -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  // box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
`

class Card extends Component {
  render() {
    let { style } = this.props;
    return (
      <StyledParent style={style}>
        {this.props.children}
      </StyledParent>
    )
  }
};

export default Card;