import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup, Button, Dialog, Classes, Intent, TextArea
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';

@inject('serviceTypeCategoryStore') @observer
class ServiceTypeCategory extends Component {

  handleCloseDailog() {
    // clearing object
    this.props.serviceTypeCategoryStore.serviceTypeCategory.object = {}
    this.props.onClose()
  }

  createCategory() {
    let { 
      serviceTypeCategoryStore,
      serviceTypeCategoryStore:{
        serviceTypeCategory: {
          object
        }
      }
     } = this.props;

    if(object.name === "" || object.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter name!', intent: Intent.DANGER })
      return;
    }
    serviceTypeCategoryStore.create()
      .then(this.onSuccessfullyCreateCategory.bind(this))
      .catch(this.onFailCreateCategory.bind(this))
  }

  onSuccessfullyCreateCategory(response) {
    this.props.serviceTypeCategoryStore.loadServiceTypeCategories();
    this.props.onSuccess()
    Toast.show({ message: "Service type category created successfully.", intent: Intent.SUCCESS })
  }

  onFailCreateCategory(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ message: "Something went wrong. Failed to create/update service type category.", intent: Intent.DANGER })
    }
  }

  updateCategory() {
    let { 
      serviceTypeCategoryStore,
      serviceTypeCategoryStore:{
        serviceTypeCategory: {
          object
        }
      }
     } = this.props;
     
    if(object.name === "" || object.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter name!', intent: Intent.DANGER })
      return;
    }
    serviceTypeCategoryStore.update()
      .then(this.onSuccessfullyUpdateCategory.bind(this))
      .catch(this.onFailCreateCategory.bind(this))
  }

  onSuccessfullyUpdateCategory(response) {
    this.props.onSuccess()
    Toast.show({ message: "Service type category updated successfully.", intent: Intent.SUCCESS })
  }

  render() {
    let {
      title,
      edit,
      serviceTypeCategoryStore: {
        serviceTypeCategory: {
          isLoading,
          object
        }
      }
    } = this.props;
    return(
      <Dialog
        title={title}
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Name"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Name'
              defaultValue={object.name}
              onChange={(event) => { object.name = event.target.value } } 
            />
          </FormGroup>
          <FormGroup
            label="Description"
            labelFor="text-input"
            labelInfo=""
          >
            <TextArea
              type="text"
              className="bp3-input bp3-fill"
              placeholder="Description"
              defaultValue={object.description}
              onChange={(event) => { object.description = event.target.value } } 
            />
            
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseDailog.bind(this)}>Close</Button>
            {!edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.createCategory.bind(this)}>
              Save
            </Button>}
            {edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.updateCategory.bind(this)}>
              Update
            </Button>}
          </div>
        </div>
      </Dialog>
    )
  }

}

export default ServiceTypeCategory;