import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import FooterIllustration from 'components/FooterIllustration';
import { Button } from "@blueprintjs/core";
import { Helmet } from 'react-helmet';
import { TopNavbar } from 'components/Styles';
import * as EmailValidator from 'email-validator';
import NewUserSidebar from './new_user_sidebar';
import { router } from '../../App';

@inject('viewStore', 'userStore') @observer
class Signup extends Component {

  @observable isDisabled = true;

  componentDidUpdate() {
    this.validateEmail();
  }

  componentDidMount() {
    this.validateEmail();
  }

  validateEmail() {
    const {
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;
    if(EmailValidator.validate(object.email)) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  handleEmailChange(event) {
    const {
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;
    object.email = event.target.value;
    this.validateEmail();
  }

  _handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.navigateToNextScreen()
    }
  }

  navigateToNextScreen() {
    const {
      viewStore
    } = this.props;
    viewStore.signupCompany();
    this.props.userStore.saveUserSignupToLocalStorage();
  }

  render() {
    const {
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;
    return(
      <UnauthenticatedPage>
        <Helmet>
          <title>Signup | FieldNexus</title>
        </Helmet>
        <TopNavbar>
          <div className="tp-nav">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-7">
                  <div className="brand">
                    <a href="https://fieldnexus.com"><img style={{ maxWidth: '200px' }} src="/brand.svg" alt='Field Nexus' /></a>
                  </div>
                </div>
                <div className="col-5 text-right">
                  <div className="right-link-tp">
                    <div className="top-btn-back">1/5</div>
                  </div>
                </div>
              </div>
            </div>      
          </div>
        </TopNavbar>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9 d-flex align-items-center sign-up">
            <div className="form-wrap">
              <p>Let's begin your journey</p>
              <h3 className="login-heading">Start with your email</h3>
              <div className="row input-wrap">
                <div className="col-sm-12 checked-icon">
                  <input
                    autoFocus
                    type="text"
                    className="form-control"
                    placeholder="email@website.com"
                    defaultValue={object.email}
                    onChange={this.handleEmailChange.bind(this)}
                    onKeyPress={this._handleKeyPress.bind(this)}
                    required
                  />
                  {
                    this.isDisabled === false &&
                    <div className="checed-active"><img src="/checked.svg" alt="" /></div>
                  }
                  
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-sm-6 col-5 fg-pass">
                  <a onClick={() => { router.navigate('/login') }}>Login</a>
                </div>
                <div className="col-sm-6 col-7 fg-next">
                  <Button
                    className="btn-gray"
                    disabled={this.isDisabled}
                    onClick={this.navigateToNextScreen.bind(this)}
                  >
                    Next
                  </Button>
                </div>
              </div>
              <h6 className="d-none d-sm-none d-md-block text-right">Or Press ENTER</h6>
              </div>
            </div>
            <div className="col-lg-3">
              <NewUserSidebar />
            </div>
          </div>
        </div>
        <FooterIllustration />
      </UnauthenticatedPage>
    )
  }

};

export default Signup;