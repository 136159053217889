import React from 'react';
import {
  Card, Elevation, NonIdealState, Spinner
} from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import styled from '@emotion/styled';
import PaymentTransactions from 'components/Tables/PaymentTransactions';

const BillingGrid = styled('div')`
  display: grid;
  grid-template-columns: 12fr;
  grid-gap: ${props => `${props.theme.unit*3}px`};
  align-items: flex-start;
  font-size: 18px;
  color: rgba(0,0,0,.65);
  padding: 20px;
`

@inject('billingStore') @observer
class BillingHistory extends React.Component {

  componentWillMount() {
    // this.props.billingStore.loadTransactions()
  }

  render() {
    const {
      billingStore: {
        payment_transactions: {
          isLoading,
          isError,
          objects
        }
      }
    } = this.props;

    if(isLoading) {
      return <Spinner />
    }

    if(!isLoading && !isError && objects.length === 0) {
      return <NonIdealState title={'Your billing history will appear here once invoices are generated.'} icon="history" />
    }
    return(
      <BillingGrid>
        <Card interactive={true} elevation={Elevation.TWO}>
          { objects.length > 0 && <PaymentTransactions objects={objects} /> }
        </Card>
      </BillingGrid>
    )
  }

};

export default BillingHistory;