import React, { Component } from 'react';
import styled from '@emotion/styled';
import NavigationBar from './NavigationBar';
const StyledParent = styled('div')`
  margin-left: 90px;
`

class InternalPage extends Component {
  render() {
    return (
      <StyledParent>
        <NavigationBar />          
        {this.props.children}
      </StyledParent>
    )
  }
};

export default InternalPage;