import React from 'react';
import Avatar from '@atlaskit/avatar';
import {
  Icon, Spinner, Button, Divider, Tag
} from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import qs from 'qs';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';

@inject('viewStore','invoiceStore') @observer
class InvoicesTable extends React.Component {

  @observable invoices = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    this.props.viewStore.clientInvoicSearch.where.client_id = this.props.client.attributes.id;
    this.props.invoiceStore.loadClientInvoices();
  }

  getStatusColorCode(status) {
    var colorCode = "text-capitalize";
    switch(status) {
      case 'paid':
        return colorCode + " color-ordered";
      case 'unpaid':
        return colorCode + " color-intransit";
      default:
        return colorCode + " color-default";
    }
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        clientInvoicSearch
      }
    } = this.props;
    this.props.viewStore.clientInvoicSearch = {
      ...clientInvoicSearch,
      page: newPage
    };
    this.props.invoiceStore.loadClientInvoices();
  }

  render() {
    let { 
      invoiceStore: {
        clientInvoices: {
          objects, isLoading, meta 
        }
      },
      viewStore: {
        jobTab: {
          section
        }
      },
    } = this.props;
    let { viewStore } = this.props;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No invoices have been created yet.
        </div>
      )
    }
    return (
      <div className="card rounded-0 table-responsive">
        <table className='table table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th></th>
              <th>Created By</th>            
              <th>Invoice No.</th>
              <th>Job</th>
              <th width="20%">PO</th>                      
              <th>Status</th>            
              <th className="item-center">Action</th>            
            </tr>
          </thead>
          <tbody>
            {
              objects.map((invoice) => {
                return (
                  <tr key={invoice.id} >
                    <td width="10px">
                      <a href={`/dashboard/invoices/${invoice.id}/show`}>
                        <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=${invoice.attributes.created_by.full_name}&size=64`)} size='large' />
                      </a>
                    </td>
                    <td width="150px">
                      <a href={`/dashboard/users/${invoice.attributes.created_by.id}/show?${qs.stringify(viewStore.userShowSearch, { encode: false })}`}>
                        <p className="primary">{invoice.attributes.created_by.full_name}</p>
                      </a>                      
                    </td>
                    <td>
                      <a href={`/dashboard/invoices/${invoice.id}/show`}>
                        <p className="secondary">{invoice.attributes.number}</p>
                      </a>
                    </td>
                    <td>
                      <a href={`/dashboard/jobs/${invoice.attributes.job.id}/${section}`}>
                        <p className='primary'>{invoice.attributes.event_number || invoice.attributes.number}</p>                    
                      </a>
                    </td>
                    <td>
                      <p className='secondary'>{invoice.attributes.po_number || "N/A"}</p>
                    </td>
                    <td>
                      <Tag className={this.getStatusColorCode(invoice.attributes.payment_status)}>{invoice.attributes.payment_status}</Tag> 
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <a className="bp3-button bp3-minimal bp3-small" href={`/dashboard/jobs/${invoice.attributes.job.id}/${section}`}>
                          <img src="/view-icon.svg"/>
                        </a>
                        <Divider/>
                        <a className="bp3-button bp3-minimal bp3-small" href={`/dashboard/invoices/${invoice.id}/show`}>
                          <img src="/edit-light-icon.svg" />
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </div>
    )
  }
}

export default InvoicesTable;
