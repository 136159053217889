import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SearchBars from 'components/SearchBars';
import Card from 'components/Card';
import { observer, inject } from 'mobx-react';
import { 
  Spinner, NonIdealState
} from "@blueprintjs/core";
import TimesheetsTable from 'components/Tables/Timesheets';
import Sticky from 'react-stickynode';
import styled from '@emotion/styled';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 8px 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'timesheetStore') @observer
class TimesheetsIndexPage extends Component {

  render() {
    let content;
    const {
      props: {
        timesheetStore: {
          timelogs: {
            isLoading,
            isError
          }
        }
      }
    } = this;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (!isError && !isLoading) {
      content = <TimesheetsTable />
    }
    return (
      <div>
        <PageWrapGray>
        <Sticky enabled={true} top={0} innerZ={9}>          
            <SearchBars.Timesheet />          
        </Sticky>
        <Helmet>
          <title>Timesheets | FieldNexus</title>
        </Helmet>
        
          <Card>
            {content}
          </Card>
        
        </PageWrapGray>
      </div>
    )
  }

};

export default TimesheetsIndexPage;