import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';
import { toJS } from 'mobx';
import moment from 'moment';

var FileDownload = require('js-file-download');

const EMPTY_PROJECT_OBJECT = {
  name: null,
  po_number: null,
  start_date: moment(),
  end_date: moment(),
  status: null,
}

class ProjectStore {
  @observable projects = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable clientProjects = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable project = {
    isLoading: false,
    isError: false,
    object: EMPTY_PROJECT_OBJECT
  };

  @observable projectAnalytics = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @action
  loadAnalytics = (params) => {
    this.projectAnalytics.isLoading = true;
    let promise = Api.get(`/projects/${params.uuid}/analytics`);

    promise
      .then((response) => {
        this.projectAnalytics.isLoading = false;
        this.projectAnalytics.isError = false;
        this.projectAnalytics.objects = response.data;
      })
      .catch((error) => {
        this.projectAnalytics.isLoading = false;
        this.projectAnalytics.isError = true;
      })
    
    return promise;
  }

  @action
  createProject() {
    this.project.isLoading = true;
    let promise = Api.post('/projects', {project: this.project.object})

    promise
      .then((response) => {
        this.project.isLoading = false;
        this.project.isError = false;
      })
      .catch((error) => {
        this.project.isLoading = false;
        this.project.isError = true;
      })
    
    return promise
  }

  @action
  deleteProject(project) {
    this.projects.isLoading = true;
    let promise = Api.delete(`/projects/${project.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadProjects();
      })
      .catch((error) => {
        this.projects.isLoading = false;
        this.projects.isError = true;
      })
    
    return promise
  }

  @action
  loadProjects() {
    this.projects.isLoading = true;
    const {
      per_page,
      page,
      order,
      where
    } = stores.viewStore.projectSearch;
    let promise = Api.post('/projects/search', 
      {
        search: {
          where: where,
          order: order,
          per_page: per_page,
          page: page
        }
      }
    );

    promise
      .then((response) => {
        this.projects.isError = false;
        this.projects.isLoading = false;
        this.projects.objects = response.data.data;
        this.projects.meta = response.data.meta;
      })
      .catch((error) => {
        this.projects.isLoading = false;
        this.projects.isError = true;
        this.projects.objects = [];
      })
    
    return promise;
  }

  @action
  loadClientProjects() {
    this.clientProjects.isLoading = true;
    let promise = Api.post('/projects/search', { 
      search: window.stores.viewStore.clientProjectSearch
    });

    promise
      .then((response) => {
        this.clientProjects.isLoading = false;
        this.clientProjects.isError = false;
        this.clientProjects.objects = response.data.data;
        this.clientProjects.meta = response.data.meta;
      })
      .catch((error) => {
        this.clientProjects.isLoading = false;
        this.clientProjects.isError = true;
        console.error(error)
      })
    
    return promise;
  }



  @action
  clearSelectedProject() {
    this.project = {
      isLoading: false,
      isError: false,
      object: EMPTY_PROJECT_OBJECT
    }
  }

  @action
  loadProject(params) {
    this.project.isLoading = true;
    let promise = Api.get(`/projects/${params.uuid}`)

    promise
      .then((response) => {
        this.project.isLoading = false;
        this.project.isError = false;
        this.project.object = response.data.data;
        stores.viewStore.projectShow(params,response.data.data.attributes.name);
      })
      .catch((error) => {
        this.project.isLoading = false;
        this.project.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  updateProject(project) {
    this.project.isLoading = true;
    let promise = Api.put(`/projects/${project.attributes.id}`, {project: toJS(project.attributes)})

    promise
      .then((response) => {
        this.project.isLoading = false;
        this.project.isError = false;
        this.project.object = EMPTY_PROJECT_OBJECT;
        stores.viewStore.projects();
      })
      .catch((error) => {
        this.project.isLoading = false;
        this.project.isError = true;
        console.error(error)
      })
  }

  download_bulk_excel(searchObj) {
    let promise = Api.post('/projects/excel_bulk_download', { search: searchObj}, { responseType: 'arraybuffer'})

    promise
      .then((response) => {
        FileDownload(response.data, 'Bulk_Projects.xls');
      })
      .catch((error) => {
      })
  }


};

export default ProjectStore