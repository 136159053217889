import { observable, action, toJS } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

const EMPTY_SERVICE_EXTRA_OBJECT = {
  name: null,
  description: null,
  quantity_based: false,
  price: null,
  frequency_discounts: false,
  discount_code_discounts: false,
  recurring_series: false,
  first_booking: false,
  duration: null,
  icon_file: null,
  service_type_extra_services_attributes: [],
}


class ServiceTypeExtraStore {
  
  @observable serviceTypeExtra = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable serviceTypeExtraDetail = {
    isLoading: false,
    isError: false,
    object: EMPTY_SERVICE_EXTRA_OBJECT
  };

  @observable serviceTypeExtraDetailUpdate = {
    isLoading: false,
    isError: false
  };

  @action
  deleteServiceTypeExtra(serviceTypeExtra) {
    this.serviceTypeExtra.isLoading = true;
    let promise = Api.delete(`/service_type_extras/${serviceTypeExtra.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadPricingParameters();
      })
      .catch((error) => {
        this.serviceTypeExtra.isLoading = false;
        this.serviceTypeExtra.isError = true;
      })
    
    return promise
  }


  @action
  updateServiceTypeExtra(object) {
    let params = {
      uuid: object.attributes.id
    }
    this.serviceTypeExtraDetail.isLoading = true;
    let promise = Api.put(`/service_type_extras/${object.attributes.id}`, {service: toJS(object.attributes)})

    promise
      .then((response) => {
        this.serviceTypeExtraDetail.isLoading = false;
        this.serviceTypeExtraDetail.isError = false;
        stores.viewStore.serviceTypeExtraSearch.term = '*';
        stores.viewStore.serviceTypeExtras();
        stores.serviceTypeExtraStore.loadServiceTypeExtras(); 
      })
      .catch((error) => {
        this.serviceTypeExtraDetail.isLoading = false;
        this.serviceTypeExtraDetail.isError = true;
      })

    return promise;
  }

  @action
  createServiceTypeExtra() {
    this.serviceTypeExtraDetail.isLoading = true;
    let promise = Api.post(`/service_type_extras/`, {service: this.serviceTypeExtraDetail.object})   

    promise
      .then((response) => {
        this.serviceTypeExtraDetail.isLoading = false;
        this.serviceTypeExtraDetail.isError = false;       
        this.serviceTypeExtraDetail.object = response.data.data;  
        stores.viewStore.serviceTypeExtras();
        stores.serviceTypeExtraStore.loadServiceTypeExtras();    
      })
      .catch((error) => {
        this.serviceTypeExtraDetail.isLoading = false;
        this.serviceTypeExtraDetail.isError = true;       
      })

    return promise;
  }


  @action
  loadServiceTypeExtras = () => {
    this.serviceTypeExtra.isLoading = true;
    const {
      per_page,
      page,
      order,
      where,
      term
    } = stores.viewStore.serviceTypeExtraSearch;
    let promise = Api.post('/service_type_extras/search', { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.serviceTypeExtra.isLoading = false;
        this.serviceTypeExtra.isError = false;
        this.serviceTypeExtra.objects = response.data.data;
        this.serviceTypeExtra.meta = response.data.meta;
      })
      .catch((error) => {
        this.serviceTypeExtra.isLoading = false;
        this.serviceTypeExtra.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadServiceTypeExtra = (params) => {
    this.serviceTypeExtraDetailUpdate.isLoading = true;
    let promise = Api.get(`/service_type_extras/${params.uuid}`)

    promise
      .then((response) => {
        this.serviceTypeExtraDetailUpdate.isLoading = false;
        this.serviceTypeExtraDetailUpdate.object = response.data.data;
        stores.viewStore.serviceTypeExtraShow(params,response.data.data.attributes.name);
      })
      .catch((error) => {
        this.serviceTypeExtraDetailUpdate.isLoading = false;
        this.serviceTypeExtraDetailUpdate.isError = true;
        console.error(error)
      })

    return promise;
  }

  

};

export default ServiceTypeExtraStore;