import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import { redCar, infoPopup } from './map_components';
import UserSidebar from './user-sidebar';

@inject('store', 'dashboard', 'viewStore', 'userStore') @observer
class UsersMapPage extends Component {

  map = null;
  bounds = null;

  componentDidMount() {
    let {
      props: {
        dashboard: {
          users
        }
      }
    } = this;
    let google = window.google;

    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 8,
      center: { lat: 0, lng: 0 }
    });
    this.bounds = new google.maps.LatLngBounds();
  }

  render() {
    const {
      props: {
        viewStore,
        dashboard: {
          users: {
            objects
          }
        }
      }
    } = this;
    let google = window.google;
    _.each(objects, (user) => {
      if (user.attributes.latitude && user.attributes.longitude) {
        const userLocation = {
          lat: user.attributes.latitude,
          lng: user.attributes.longitude
        };

        var infowindow = new google.maps.InfoWindow({
          content: infoPopup(user)
        });
        const marker = new window.MarkerWithLabel({
          position: userLocation,
          rotation: user.attributes.heading,
          labelContent: user.attributes.full_name,
          labelAnchor: new google.maps.Point(25, 8),
          labelClass: "mapLabels",
          labelStyle: {
            opacity: 0.8,
            color: "white",
            background: '#2D4EF5',
            padding: '2px',
            borderRadius: "10px",
            textAlign: 'center',
            maxWidth: "50px"
          },
          map: this.map,
          title: `${user.attributes.full_name} - location last received ${user.attributes.location_last_updated_time_ago}`,
          icon: {
            url: redCar(user),
            rotation: user.attributes.heading,
            scale: 0.5
          }
        });
        marker.addListener('mouseover', function () {
          infowindow.open(this.map, marker);
        });

        marker.addListener('mouseout', function () {
          infowindow.close();
        });
        marker.addListener('click', function () {
          viewStore.geolocationSearch.where.user_id = user.id;
          viewStore.userMapShow();
        });
        if (this.bounds) {
          this.bounds.extend(userLocation);
        }
      }
    })
    if (this.bounds && this.map) {
      this.map.fitBounds(this.bounds);
    }
    return (
      <div className="user-page-layout">
        <Helmet>
          <title>Users Map | FieldNexus</title>
        </Helmet>

        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <UserSidebar />
          </div>
          <div className="col-lg-10">
            <div style={{ 'width': '100%', height: "calc(100vh - 75px)" }} id="map"></div>
          </div>
        </div>
      </div>
    )
  }
};

export default UsersMapPage;