import { observable, action } from 'mobx';
import { Api, CurrentEnv } from 'services/Api';
import stores from './index';
import moment from 'moment';
import qs from 'qs';
var FileDownload = require('js-file-download');

class InvoiceStore {

  @observable showLabourLineItemsLoader = false
  @observable showMaterialLineItemsLoader = false
  @observable showCustomLineItemsLoader = false
  @observable showManualTransactionLoader = false
  @observable showTransactionLoader = false
  @observable isLoadingBlankLineItem = false;
  @observable isaddedMaterialLineItem = false;
  @observable isLoadingBlankMaterialLineItem = false;
  @observable isLoadingBlankLabourLineItem = false;
  @observable isLoadingBlankCustomLineItem = false;

  @observable invoices = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable clientInvoices = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable invoice = {
    isLoading: false,
    isError: false,
    object: null
  };

  @observable labourLineItems = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable materialLineItems = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable customLineItems = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable newInvoice = {
    isLoading: false,
    isError: false,
    object: {
      client_id:'',
      technician_id:'',
      scheduled_event_id:'',
      location_id:'',
      materials:'',
      labours:'',
      customs:''
    }
  };

  @observable jobInvoice = {
    isLoading: false,
    isError: false,
    object: {
      client_id:'',
      technician_id:'',
      scheduled_event_id:'',
      location_id:'',
      line_items:[]
    }
  };

  @observable transactions = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable sendPaymentInvoice = {
    isLoading: false,
    isError: false,
    object: {
      emailTo: "",
      emailSubject: "",
      emailMessage: "",
      emailAttached: ""
    }
  };

  @observable allUsers = {
    isLoading: false,
    isError: false,
    objects: []
  };


  get invoicePDFDownloadUrl() {
    const {
      viewStore: {
        currentUser: {
          attributes: {
            api_token,
            tenant_id
          }
        }
      }
    } = stores;
    const {
      invoice: {
        object: {
          id
        }
      }
    } = this;
    if (id && api_token) {
      let path = `work_reports/${id}/download_invoice_pdf?api_token=${api_token}&x_tenant_id=${tenant_id}`
      return {
        fullUrl: `${CurrentEnv.baseURL()}/${path}`,
        baseUrl: CurrentEnv.baseURL(),
        path: path
      }
    }
  }

  @action
  loadAllUsersEmail() {
     this.allUsers.isLoading = true;
    const {
      viewStore: {
        currentUser: {
          attributes: {
            tenant_id
          }
        }
      }
    } = stores;
  let promise = Api.get(`/work_reports/${tenant_id}/all_users_email`)
    promise
      .then((response) => {
        this.allUsers.isLoading = false;
        this.allUsers.isError = false;
        this.allUsers.objects = response.data.data
      })
      .catch((error) => {
        this.allUsers.isLoading = false;
        this.allUsers.isError = true;
      })
  }

  @action
  SendInvoiceToEmail() {
    const {
      viewStore: {
        currentUser: {
          attributes: {
            tenant_id
          }
        }
      }
    } = stores;
    const {
      invoice: {
        object: {
          id
        }
      }
    } = this;
    this.sendPaymentInvoice.isLoading = true;
    let promise = Api.post(`/work_reports/${tenant_id}/send_email/${id}/send_invoice_email`, { email_details: this.sendPaymentInvoice.object })

     promise
      .then((response) => {
        this.sendPaymentInvoice.isLoading = false;
        this.sendPaymentInvoice.isError = false;
        stores.store.isOpen = false;
      })
      .catch((error) => {
        //console.error(error.response.data.errors.join(", "))
        this.sendPaymentInvoice.isLoading = false;
        this.sendPaymentInvoice.isError = true;
      })
    return promise;
  }

  @action
  SendInvoiceToEmailFromQuickBooks() {
    const {
      viewStore: {
        currentUser: {
          attributes: {
            tenant_id
          }
        }
      }
    } = stores;
    const {
      invoice: {
        object: {
          id
        }
      }
    } = this;
    this.sendPaymentInvoice.isLoading = true;
    let promise = Api.post(`/quickbooks/${tenant_id}/send_email/${id}/send_invoice_email`, { email_details: this.sendPaymentInvoice.object })

     promise
      .then((response) => {
        this.sendPaymentInvoice.isLoading = false;
        this.sendPaymentInvoice.isError = false;
        stores.store.isOpen = false;
      })
      .catch((error) => {
        this.sendPaymentInvoice.isLoading = false;
        this.sendPaymentInvoice.isError = true;
      })
    return promise;
  }

  @action
  loadTransactions() {
    this.transactions.isLoading = true
    let params = {
      invoice_id: this.invoice.object.id
    }
    let promise = Api.get(`/billing/invoice_payment_transactions?` + qs.stringify(params))

    promise
      .then((response) => {
        this.transactions.objects = response.data.data;
        this.transactions.isLoading = false
        this.transactions.isError = false
      })
      .catch((error) => {
        this.transactions.isLoading = false
        this.transactions.isError = false
      })

    return promise;
  }

  // update full invoice with line_items
  // this.invoice used as payload in this action
  @action
  update() {
    
    this.invoice.isLoading = true;
    if(this.invoice.object.attributes.grand_total === this.invoice.object.attributes.due_amount){
      this.invoice.object.attributes.payment_status = 'unpaid';
    }
    let promise = Api.put(`/work_reports/${this.invoice.object.attributes.id}`, {work_report: this.invoice.object.attributes})
    
    promise
      .then((response) => {
        this.invoice.object = response.data.data;
        this.invoice.isLoading = false;
        this.invoice.isError = false;
      })
      .catch((error) => {
        this.invoice.isLoading = false;
        this.invoice.isError = true;
      })

    return promise;
  }

  @action
  deleteLineItem(lineItem) {

    if(lineItem.kind === 'material') {
      this.showMaterialLineItemsLoader = true;
    } else if(lineItem.kind === 'labour') {
      this.showLabourLineItemsLoader = true;
    } else if(lineItem.kind === 'custom') {
      this.showCustomLineItemsLoader = true;
    }
    let promise = Api.delete(`/line_items/${lineItem.id }` );
    
    promise
      .then((response) => {
        this.update();
        this.showMaterialLineItemsLoader = false;
        this.showLabourLineItemsLoader = false;
        this.showCustomLineItemsLoader = false;
        
      })
      .catch((error) => {
        this.showMaterialLineItemsLoader = false;
        this.showLabourLineItemsLoader = false;
        this.showCustomLineItemsLoader = false;
      })
    
    return promise
  }

  @action
  addBlankLineItemForProduct(kind, invoiceId) {
    
    let lineItem = {
      work_report_id: invoiceId,
      kind: kind,
      work_date: moment()
    }
    let invoice = {
      id: invoiceId
    }

    if(kind === 'labour') {
      this.showLabourLineItemsLoader = true;
    } else if(kind === 'material') {
      this.showMaterialLineItemsLoader = true;
      this.isLoadingBlankLineItem = true;
    } else if(kind === 'custom') {
      this.showCustomLineItemsLoader = true;
    }

    let promise = Api.post(`/line_items`, {line_item: lineItem, work_report: invoice})

    promise
      .then((response) => {
        this.update()
        if(kind === 'labour') {
          this.showLabourLineItemsLoader = false;
        } else if(kind === 'material') {
          this.showMaterialLineItemsLoader = false;
          this.isLoadingBlankLineItem = false;
        } else if(kind === 'custom') {
          this.showCustomLineItemsLoader = false;
        }
      })
      .catch((error) => {
      })
      return promise;
  }

  @action
  addLineItem(material, workReportId, kind) {
    let lineItem = {
      work_report_id: workReportId,
      is_overtime:false,
      markup: 1,
      override:false,
      part_number:material.attributes.part_number,
      product_service_id:material.attributes.id,
      quantity:1,
      quantity_display_value:1,
      rate_per_unit: material.attributes.mark_up ? (material.attributes.unit_price * parseFloat(material.attributes.mark_up)).toFixed(2) : material.attributes.unit_price,
      supplier_id:material.attributes.supplier_id,
      description:material.attributes.description,
      kind: kind,
      work_date: moment()
    }
    let work_report = {
      id: workReportId
    }

    let promise = Api.post(`/line_items`, {line_item: lineItem, work_report: work_report})

    promise
      .then((response) => {
        this.isaddedMaterialLineItem = true;
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
        this.update()
      })
      .catch((error) => {
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
      })
      return promise;

  }

  @action
  addBlankLineItem(kind, invoiceId) {
    let lineItem = {
      work_report_id: invoiceId,
      kind: kind,
      work_date: moment()
    }
    let invoice = {
      id: invoiceId
    }

    if(kind === 'labour') {
      this.showLabourLineItemsLoader = true;
    } else if(kind === 'material') {
      this.showMaterialLineItemsLoader = true;
    } else if(kind === 'custom') {
      this.showCustomLineItemsLoader = true;
    }

    let promise = Api.post(`/line_items`, {line_item: lineItem, work_report: invoice})

    promise
      .then((response) => {
        this.update()
        if(kind === 'labour') {
          this.showLabourLineItemsLoader = false;
        } else if(kind === 'material') {
          this.showMaterialLineItemsLoader = false;
        } else if(kind === 'custom') {
          this.showCustomLineItemsLoader = false;
        }
      })
      .catch((error) => {
      })
  }

  @action
  loadInvoices() {
    this.invoices.isLoading = true;
    const {
      per_page,
      page,
      order,
      where
    } = stores.viewStore.invoiceSearch;
    let promise = Api.post('/work_reports/search', { 
      search: { 
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.invoices.isLoading = false;
        this.invoices.isError = false;
        this.invoices.objects = response.data.data;
        this.invoices.meta = response.data.meta;
      })
      .catch((error) => {
        this.invoices.isLoading = false;
        this.invoices.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadClientInvoices() {
    this.clientInvoices.isLoading = true;
    let promise = Api.post('/work_reports/search', { 
      search: window.stores.viewStore.clientInvoicSearch
    });

    promise
      .then((response) => {
        this.clientInvoices.isLoading = false;
        this.clientInvoices.isError = false;
        this.clientInvoices.objects = response.data.data;
        this.clientInvoices.meta = response.data.meta;
      })
      .catch((error) => {
        this.clientInvoices.isLoading = false;
        this.clientInvoices.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  deleteInvoice(invoice) {
    this.invoice.isLoading = true;
    let promise = Api.delete(`/work_reports/${invoice.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadInvoices();
      })
      .catch((error) => {
        this.invoice.isLoading = false;
        this.invoice.isError = true;
      })
    
    return promise;
  }

  @action
  loadMaterialLineItems() {
    this.materialLineItems.isLoading = true;
    let promise = Api.get(`/work_reports/${this.invoice.object.attributes.id}/material_line_items`)
    
    promise
      .then((response) => {
        this.materialLineItems.isLoading = false;
        this.materialLineItems.isError = false;
        this.materialLineItems.objects = response.data.data;
      })
      .catch((error) => {
        this.materialLineItems.isLoading = false;
        this.materialLineItems.isError = true;
      })
  }

  @action
  loadLabourLineItems() {
    this.labourLineItems.isLoading = true;
    let promise = Api.get(`/work_reports/${this.invoice.object.attributes.id}/labour_line_items`)
    
    promise
      .then((response) => {
        this.labourLineItems.isLoading = false;
        this.labourLineItems.isError = false;
        this.labourLineItems.objects = response.data.data;
      })
      .catch((error) => {
        this.labourLineItems.isLoading = false;
        this.labourLineItems.isError = true;
      })
  }

  @action
  loadCustomLineItems() {
    this.customLineItems.isLoading = true;
    let promise = Api.get(`/work_reports/${this.invoice.object.attributes.id}/custom_line_items`)
    
    promise
      .then((response) => {
        this.customLineItems.isLoading = false;
        this.customLineItems.isError = false;
        this.customLineItems.objects = response.data.data;
      })
      .catch((error) => {
        this.customLineItems.isLoading = false;
        this.customLineItems.isError = true;
      })

  }

  @action
  getInvoice(params) {
    this.invoice.isLoading = true;
    let promise = Api.get(`/work_reports/${params.uuid}`)

    promise
      .then((response) => {
        this.invoice.isLoading = false;
        this.invoice.object = response.data.data;
        if(!params.manual_transcation) {
          stores.viewStore.ShowInvoice(params,response.data.data.attributes.number);
        }
      })
      .catch((error) => {
        this.invoice.isLoading = false;
        this.invoice.isError = true;
      })

    return promise;
  }

  @action
  deleteLabourTaxLineItem(taxItem) {
    //var deleteTaxLineItem = window.confirm("Are you sure you want to delete this tax line item?");
    if (taxItem) {

      let promise = Api.delete(`/line_items/labour_tax_line_item/${taxItem.id }` );
      
      const onSuccess = (response) => {
        this.labourLineItems.objects = response.data.data;
      }

      const onError = (error) => {
      }

      promise
        .then(onSuccess)
        .catch(onError)

      return promise;
    } else {
      return new Promise(function(resolve, reject) {
        resolve();
    });
    }
  }

  @action
  deleteMaterialTaxLineItem(taxItem) {
    //var deleteTaxLineItem = window.confirm("Are you sure you want to delete this tax line item?");
    if (taxItem) {

      let promise = Api.delete(`/line_items/material_tax_line_item/${taxItem.id }` );
      
      const onSuccess = (response) => {
        this.materialLineItems.objects = response.data.data;
      }

      const onError = (error) => {
      }

      promise
        .then(onSuccess)
        .catch(onError)

      return promise;
    } else {
      return new Promise(function(resolve, reject) {
        resolve();
    });
    }
  }

  @action
  deleteCustomTaxLineItem(taxItem) {
    //var deleteTaxLineItem = window.confirm("Are you sure you want to delete this tax line item?");
    if (taxItem) {

      let promise = Api.delete(`/line_items/custom_tax_line_item/${taxItem.id }` );
      
      const onSuccess = (response) => {
        this.customLineItems.objects = response.data.data;
      }

      const onError = (error) => {
      }

      promise
        .then(onSuccess)
        .catch(onError)

      return promise;
    } else {
      return new Promise(function(resolve, reject) {
        resolve();
    });
    }
  }

  @action
  createEmpty() {
    this.newInvoice.isLoading = true
    let promise = Api.post(`/work_reports/create_empty_invoice`, {invoice: this.newInvoice.object})

    promise
      .then((response) => {
        this.newInvoice.isLoading = false
        this.newInvoice.object = response.data.data
        this.newInvoice.technician_id = ''
        this.newInvoice.client_id = ''
        stores.viewStore.ShowInvoice({ uuid: this.newInvoice.object.attributes.id })
      })
      .catch((error) => {
        this.newInvoice.isLoading = false
      })

    return promise;
  }

  @action
  createJobInvoice() {
    this.jobInvoice.isLoading = true
    let promise = Api.post(`/work_reports/create_job_invoice`, {invoice: this.jobInvoice.object})

    promise
      .then((response) => {
        this.jobInvoice.isLoading = false
        this.jobInvoice.object = response.data.data
        this.jobInvoice.technician_id = ''
        this.jobInvoice.client_id = ''
        stores.viewStore.ShowInvoice({ uuid: this.jobInvoice.object.attributes.id })
      })
      .catch((error) => {
        this.jobInvoice.isLoading = false
      })

    return promise;
  }

  @action
  downloadInvoicePDF() {
    let downloadUrl = this.invoicePDFDownloadUrl;
    let promise = Api.get(downloadUrl.path, { responseType: 'arraybuffer' });
    
    const onSuccess = (response) => {
      FileDownload(response.data, 'Invoice.pdf');
    }

    const onError = (error) => {

    }

    promise
      .then(onSuccess)
      .catch(onError)
    return promise;
  }

  @action
  makeManualTransaction(transaction) {
    this.showManualTransactionLoader = true;
    let promise = Api.post(`/payments/${stores.viewStore.currentUser.attributes.tenant_id}/invoices/${transaction.invoice_id}/manual_payment`, {payment: transaction})
    
    promise
      .then((response) => {
        this.showManualTransactionLoader = false;
        this.getInvoice({uuid: transaction.invoice_id, manual_transcation: true})
      })
      .catch((error) => {
        this.showManualTransactionLoader = false;
      })
    
    return promise;
  }

  deleteTransaction(transaction) {
    this.invoice.isLoading = true;
    let promise = Api.delete(`/payments/${transaction.id}`)
    
    promise
      .then((response) => {
        this.showManualTransactionLoader = false;
        this.update();
        //this.getInvoice({uuid: transaction.invoice_id})
      })
      .catch((error) => {
        this.invoice.isLoading = false;
      })
    
    return promise;
  }

  @action
  changeTechnician(work_report, event) {
    this.invoice.isLoading = true;
    let promise = Api.post(`/work_reports/update_created_by`, {id: work_report.id, event: event})

    const onSuccess = (response) => {
      this.invoice.object = response.data.data;
      this.invoice.isLoading = false;
      this.invoice.isError = false;
    }

    const onError = (error) => {
      this.invoice.isLoading = false;
      this.invoice.isError = true;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  changeStatus(event) {
    this.invoice.isLoading = true;
    let promise = Api.post(`/work_reports/change_status`, {work_report: this.invoice.object.attributes, event: event})

    const onSuccess = (response) => {
      this.invoice.object = response.data.data;
      this.invoice.isLoading = false;
      this.invoice.isError = false;
    }

    const onError = (error) => {
      this.invoice.isLoading = false;
      this.invoice.isError = true;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

};

export default InvoiceStore;