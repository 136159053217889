import React from 'react';
import {
  Spinner, Icon, Button
} from '@blueprintjs/core';
import Avatar from '@atlaskit/avatar';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import qs from 'qs';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Lozenge from '@atlaskit/lozenge';

@inject('viewStore','projectStore') @observer
class ProjectsTable extends React.Component {

  @observable projects = {
    isLoading: false,
    objects: []
  }

  componentDidMount() {
    this.props.viewStore.clientProjectSearch.where.client_id = this.props.client.attributes.id;
    this.props.projectStore.loadClientProjects();
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        clientProjectSearch
      }
    } = this.props;
    this.props.viewStore.clientProjectSearch = {
      ...clientProjectSearch,
      page: newPage
    };
    this.props.projectStore.loadClientProjects();
  }

  decideAppearance = (status) => {
    switch (status) {
      case 'active':
        return 'success'
      default:
        break;
    }
  }

  render() {
    let { 
      projectStore: {
        clientProjects: {
          objects, isLoading, meta 
        }
      }
    } = this.props;
    let { viewStore } = this.props;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No projects have been created for this client yet.
        </div>
      )
    }
    return(
      <div className="card rounded-0 table-responsive">
        <table className='table table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th width="7%">#</th>                       
              <th>Client Name</th>
              <th>PO</th>            
              <th>Start Date</th>            
              <th>End Date</th>            
              <th>Status</th>            
              <th className="item-center">Action</th>            
            </tr>
          </thead>
          <tbody>
            {
              objects.map((project) => {
                return(
                  <tr key={project.id} >
                    <td width="150px">
                      <a href={`/dashboard/projects/${project.id}/show?${qs.stringify(viewStore.projectShowSearch, { encode: false })}`}>
                        <p className="primary">{project.attributes.number}</p>
                      </a>
                    </td>
                    <td>
                      <a href={`/dashboard/clients/${project.attributes.client_id}?${qs.stringify(viewStore.clientShowSearch, { encode: false })}`}>
                        <p className='primary'>{project.attributes.client_name}</p>
                      </a>
                    </td>
                    <td> 
                      <p className='secondary'>PO #: {project.attributes.po_number || "N/A"}</p>
                    </td>
                    <td>
                      <p className='primary'>Start date: {project.attributes && project.attributes.start_date_formatted}</p>
                      
                    </td>
                    <td> 
                      <p className='secondary'>End date: {project.attributes && project.attributes.end_date_formatted}</p>
                      
                    </td>
                    <td>
                      <Lozenge appearance={this.decideAppearance.bind(this)(project.attributes.status)} >
                        {project.attributes.status}
                      </Lozenge>
                      </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <a className="bp3-button bp3-minimal bp3-small" href={`/dashboard/projects/${project.id}/show?${qs.stringify(viewStore.projectShowSearch, { encode: false })}`}>
                          <img src="/view-icon.svg"/>
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </div>
    )
  }

}

export default ProjectsTable;