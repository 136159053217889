import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable, toJS } from "mobx";
import { 
  Button, Dialog, Classes, Intent, FormGroup, TextArea, Checkbox,
  MenuItem
} from "@blueprintjs/core";
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";
import Toast from 'helpers/toaster';

@inject("contactsStore")
@observer
class MultipleEmailSelector extends Component {

  @observable query = "";

  componentDidMount() {
    const {
      onContactSelect,
      queryContacts,
      props: {
        contacts,
        contactsStore
      }
    } = this;
    contactsStore.selectedContacts = [];
    queryContacts("", null);
    if(contacts && contacts.length > 0 && Array.isArray(contacts)){
      contacts.map((contact) => {
        onContactSelect(contact);
      })
    }
  }

  onTagKeyDown = (event, index) => {
    // 9 = TAB
    if (event.keyCode === 9) {
      if(event.target.value){
        this.addNewEmail({
          name: event.target.value,
          email: event.target.value
        });
      }
      this.query = "";
    } else if(event.keyCode === 188){ // 188 = , (comma)
      if(event.target.value){
        event.preventDefault();
        event.target.value.split(",").map((email) => {
          this.addNewEmail({
            name: email,
            email: email
          });
        }) 
      }
      this.query = "";
    }
  }

  onTagChange = (value) => {
    this.contact = value;
  }
  
  onContactSelect = (contact) => {
    this.addNewEmail(contact);
    this.query = "";
  }

  queryContacts = (query, event) => {
    let {
      props: {
        contactsStore,
        contactsStore: {
          contacts
        }
      }
    } = this;
    this.query = query;
    contacts.isLoading = true;
    contactsStore.getContacts(query || "*")
      .then(response => {
        contacts.objects = response.data.data;
      })
      .catch(error => {
        
      })
      .finally(() => {
        contacts.isLoading = false;
      })
  }

  escapeRegExpChars = (text) => {
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  }

  highlightText = (text, query) => {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(this.escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
  }

  handleTagRemove = (_tag, index) => {
    this.deselectContact(index);
  }

  deselectContact = (index) => {
    let {
      props: {
        contactsStore,
        contactsStore: {
          selectedContacts
        },
        onEmailChange
      }
    } = this;
    contactsStore.selectedContacts = contactsStore.selectedContacts.filter((_contact, i) => i !== index);
    if(onEmailChange){
      onEmailChange(contactsStore.selectedContacts);
    }
  }

  addNewEmail = (contact) => {
    let {
      props: {
        contactsStore: {
          selectedContacts
        },
        onEmailChange
      }
    } = this;
    selectedContacts.push(contact);
    if(onEmailChange){
      onEmailChange(selectedContacts);
    }
  }

  render() {
    const {
      props: {
        contactsStore: {
          contacts,
          selectedContacts
        }
      },
      onContactSelect,
      handleTagRemove
    } = this;
    return(
      <React.Fragment>

        <MultiSelect
          onQueryChange={this.queryContacts}
          itemRenderer={(contact, { handleClick, modifiers, query }) => {
            if (selectedContacts.filter((_selectedContact) => _selectedContact.email === contact.email ).length > 0) {
              return null;
            }
            return (
                <MenuItem
                  active={modifiers.active}
                  disabled={modifiers.disabled}
                  key={contact.email + contact.name}
                  label={contact.email}
                  onClick={handleClick}
                  text={this.highlightText(contact.name, query)}
                />
            );
          }}
          createNewItemFromQuery={(query) => { 
            return { email: query, name: query } 
          } }
          createNewItemRenderer={(query, active, handleClick) => {
            if(query){
              return (
                  <MenuItem
                    active={active}
                    key={query}
                    onClick={handleClick}
                    text={`Add '${query}'`}
                  />
              );
            }else{
              return null;
            }
          }}
          onItemsPaste={(contacts) => {
            contacts.map((contact) => {
              if(!selectedContacts.filter((_selectedContact) => _selectedContact.email === contact.email ).length > 0) {
                onContactSelect(contact);
              }
            });
          }}
          query={this.query}
          tagRenderer={(contact) => { return `${contact.email} <${contact.name}>` }}
          items={toJS(contacts.objects)}
          selectedItems={toJS(selectedContacts)}
          onItemSelect={onContactSelect}
          fill={true}
          openOnKeyDown={true}
          tagInputProps={{ 
            onRemove: handleTagRemove.bind(this), placeholder: 'Enter emails',
            addOnBlur: true,
            onKeyDown: this.onTagKeyDown
          }}
        />
      </React.Fragment>
    )
  }

}

export default MultipleEmailSelector;
