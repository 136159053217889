import React from 'react';
import Avatar from '@atlaskit/avatar';
import {
  Spinner, Button, Divider, Alert, Intent 
} from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject} from 'mobx-react';
import qs from 'qs';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import moment from 'moment-timezone';
import Toast from 'helpers/toaster';

@inject('viewStore','geolocationStore') @observer
class LocationsTable extends React.Component {

  @observable locations = {
    isLoading: true,
    objects: []
  }
  @observable isDeleteDialogVisible = false;
  @observable location;

  componentDidMount() {
    this.props.viewStore.clientLocationSearch.where.client_id = this.props.client.attributes.id;
    this.props.viewStore.locationWorkReportSearch.where.client_id = this.props.client.attributes.id;
    this.props.geolocationStore.loadClientLocations();    
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        clientLocationSearch
      }
    } = this.props;
    this.props.viewStore.clientLocationSearch = {
      ...clientLocationSearch,
      page: newPage
    };
    this.props.geolocationStore.loadClientLocations();
  }

  workOrderSearchQuery = (location) => {
    return qs.stringify({
      where: {
        grand_total: {
          gte: null,
          lte: null
        },
        created_at: {
          gte: moment().subtract(1, 'month').unix(),
          lte: moment().unix()},
          kind: 'WorkReport',
          location_id: location.id,
          client_id: location.attributes.client_id
        }
      }, 
      { encode: false }
    );
  }

  handleDeleteClick(event, location) {
    this.isDeleteDialogVisible = true;
    this.location = location;
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
    this.location = null;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.geolocationStore.deleteLocation(this.location)
      .then(this.onSuccessfulDeleteLocation.bind(this))
      .catch(this.onFailedLocationDelete.bind(this))
  }

  onSuccessfulDeleteLocation(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Location deleted successfully!', intent: Intent.SUCCESS })
    this.location = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedLocationDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete location!', intent: Intent.DANGER })
  }

  render() {
    let { 
      geolocationStore: {
        clientLocations: {
          objects, isLoading, meta 
        }
      }
    } = this.props;
    let { 
      viewStore
    } = this.props;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No locations have been created for this client yet.
        </div>
      )
    }
    return (
      <div className="card rounded-0 table-responsive">
        <table className='table table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Client Name</th>                       
              <th>Client Address</th>            
              <th>Notes</th>            
              <th className="item-center">Action</th>            
            </tr>
          </thead>
          <tbody>
            {
              objects.map((location) => {
                return (
                  <tr key={location.id}>
                    <td>
                      <p className='primary'>{location.attributes.name}</p>
                    </td>
                    <td>
                      <p className='primary'>{location.attributes.full_address}</p>
                    </td>
                    <td>
                      {location.attributes.notes && <span className='bp3-tag bp3-minimal bp3-intent-primary'>
                        {location.attributes.notes}
                      </span>}
                    </td>
                    <td>                    
                      <div className="action-buttons" minimal={true}>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleDeleteClick({ label: 'Delete' }, location)}>
                          <img src="/delete-light-icon.svg" />
                        </Button>
                        <Divider/>
                        <a className="bp3-button bp3-minimal bp3-small" href={`/dashboard/work-reports?${this.workOrderSearchQuery(location)}`}>
                          <img src="/view-icon.svg"/>
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to delete <b>{this.location && this.location.attributes.name}</b> location?
          </p>
        </Alert>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </div>
    )
  }

};

export default LocationsTable;
