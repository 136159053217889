import React from 'react';
import {
  Spinner, NonIdealState, Intent
} from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import Toast from 'helpers/toaster';
import { formatTime } from 'helpers/time';

@inject('store', 'billingStore') @observer
class CardList extends React.Component {

  componentWillMount() {
    this.props.billingStore.loadCards();
  }

  deleteCard(card) {
    this.props.billingStore.deleteCard(card)
      .then(this.onSussessfullDelete.bind(this))
      .catch(this.onFailDeleteCard.bind(this))
  }

  onSussessfullDelete(response) {
    Toast.show({ message: "Card deleted successfully.", intent: Intent.SUCCESS })
  }

  onFailDeleteCard(error) {
    Toast.show({ message: "Unable to delete card!", intent: Intent.DANGER })
  }

  render() {
    const {
      cards: {
        isLoading,
        objects
      }
    } = this.props.billingStore;
    if(isLoading) {
      return <Spinner />
    }
    return(
      <div className="table-responsive">
        {objects.length === 0 && <NonIdealState title={'No Credit Card added yet.'} icon="help" description="Please add credit card."/>
          }
        {objects.length > 0 && <table className="table table-hover" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Brand</th>
              <th>Card Number</th>
              <th>Expiry</th>
              <th>Added By</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {objects.map((card) => {
              return(
                <tr key={card.attributes.id}>
                  <td>
                    {card.attributes.stripe_card_brand} 
                    {card.attributes.is_default && <div className="bp3-tag bp3-intent-primary" style={{marginLeft: 5}}>
                      Primary
                    </div>}
                  </td>
                  <td>
                    **** **** **** {card.attributes.last4}
                  </td>
                  <td>
                    {card.attributes.card_expiry}
                  </td>
                  <td>
                    {card.attributes.added_by}
                  </td>
                  <td>
                    { formatTime(card.attributes.created_at) }
                  </td>
                  <td>
                    <div className="bp3-button-group bp3-minimal">
                      <button type="button" className="bp3-button" onClick={() => {if (window.confirm('Are you sure you want to delete this card?')) this.deleteCard(card) }}>Delete</button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>}
      </div>
    )
  }

};

export default CardList;