import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { router } from 'App';
import { Button, Checkbox, Intent } from '@blueprintjs/core';
import TechnicianPicker from "components/TechnicianPicker";
import { observable, toJS } from 'mobx';
import Toast from 'helpers/toaster';
import _ from 'lodash';

@inject('store', 'quoteStore', 'viewStore') @observer
class QuoteCustomerDetails extends Component {

  @observable selectedOptions = null;

  onTechnicianChange(technicians) {
    let {
      quoteStore: {
        quote: {
          object
        }
      },
      selectedTechnician
    } = this.props;
    let selectedValues = technicians.map(status => status.value);
    selectedTechnician = selectedValues.filter((v) => v !== object.attributes.created_by.id);

    if (object.attributes.created_by) {
      this.props.quoteStore.changeTechnician(object, selectedTechnician[0])
        .then((response) => {
          Toast.show({ message: "Estimate technician changed successfully.", intent: Intent.SUCCESS })
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
          } else {
            Toast.show({ message: "Something went wrong. Problem in changing estimate technician.", intent: Intent.DANGER })
          }
        })
    }
  }

  render() {
    const {
      quoteStore: {
        quote: {
          object
        }
      },
      viewStore: {
        jobTab: {
          section
        }
      },
      store
    } = this.props;

    let {
      selectedJob, selectedTechnician
    } = this.props;

    return (
      <div>
        <div className="invoice-customer-details" style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', marginBottom: '1rem' }}>

          <div className="details-1" style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="technician-details tech-contact-block">
              <div className="tech-contact">
                <div className="tech-name" style={{ display: 'flex' }}>
                  <h5> <a href={`/dashboard/clients/${object.attributes.client.id}/`} >
                    {object.attributes.client.name}
                  </a></h5> <span style={{ marginLeft: 15, color: '#91A4B9', marginTop: 5 }}>Client</span>
                </div>
                <div className="info-field">
                  <div className="more-info long-email-decorator">{object.attributes.client.email}</div>
                  <div className="more-info">{object.attributes.client.phone}</div>
                  {
                    object.attributes && object.attributes.current_location ?
                      <div className="more-info">{object.attributes.current_location.full_address}</div>
                      :
                      <div className="more-info">{object && object.attributes && object.attributes.location && object.attributes.location.full_address}</div>
                  }
                </div>
              </div>
              {_.isEmpty(object.attributes.job) && <Button className="bp3-intent-primary primary-btn" icon='edit' onClick={() => store.isOpenUpdateClientDialog = true } style={{marginTop: 10}}>
                Change Client
              </Button>}
            </span>
          </div>
          <div>
            <div className="in-right-part">
              {
                object.attributes.job.number &&
              <div className="d-md-flex align-items-start justify-content-between">
                <div className="side-text">Job</div>
                  <div className="details-1" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="job-detail-block">
                      <a className="job-number-link" href={`/dashboard/jobs/${object.attributes.job.id}/${section}`} >
                        {object.attributes.job.number}
                      </a>
                      <div className="job-meta-details">
                        <div className="d-flex justify-content-between">
                          <div>
                            <div><span className="font-weight-bold text-muted">Reason for call:</span> {object.attributes.job.title}</div>
                            <div className="job-type-name">{object.attributes.job.service_type_name}</div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>              
              </div>
              }

              <div className="d-md-flex align-items-start justify-content-between mt-4">
                <div className="side-text">Prepared by</div>
                <div className="job-detail-block">
                  <TechnicianPicker
                    fill="true"
                    selectedOptions={toJS(selectedTechnician)}
                    onChange={this.onTechnicianChange.bind(this)}
                    includeAdmins={true}
                    hideHeading={true}
                  />
                  <div className="p-2">
                    <div className="more-info mb-1">{object.attributes.created_by.email}</div>
                    <div className="more-info">{object.attributes.created_by.phone}</div>
                  </div>
                </div>
              </div>

            </div>
            
          </div>
        </div>
        <div style={{ padding: '10px', marginBottom: '1rem'}} className="invoice_enable_signature">
          <Checkbox 
            checked={object.attributes.flat_rate} 
            large={false}
            label="Flat Rate" 
            onChange={(event) => {object.attributes.flat_rate = event.target.checked}} />
        </div>
      </div>
    )
  }
}

export default QuoteCustomerDetails;
