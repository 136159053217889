import { observable, action, toJS } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

const EMPTY_SERVICE_TYPE_OBJECT = {
  service_type_category_id: null,
  name: null,
  description: null,
  rate_cents: null,
  rate_currency: null,
  tenant_id: null,
  color: null,
  icon_file: null,
  logo: '',
  rate_cents: null,
  rate_currency: null,
  allow_thirty_minutes_increments: null,
  number_of_hours_start: null,
  number_of_hours_end: null,
  number_of_technicians_start: null,
  number_of_technicians_end: null,
  hourly_service: null,
  tenant_id: null,
  service_type_pricing_parameters: [],
  service_type_pricing_parameters_attributes:[],
  display_on_booking_form: false
}

class ServiceTypeStore {
  @observable serviceType = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable serviceTypeDetail = {
    isLoading: false,
    isError: false,
    object: EMPTY_SERVICE_TYPE_OBJECT
  };

  @observable princingRangeCombineArray = [];

  @action
  clearServiceTypeDetail() {
    this.serviceTypeDetail = {
      isLoading: false,
      isError: false,
      object: EMPTY_SERVICE_TYPE_OBJECT
    };
  }

  @action
  deleteServiceType(serviceType) {
    this.serviceType.isLoading = true;
    let promise = Api.delete(`/service_types/${serviceType.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadServiceTypes();
      })
      .catch((error) => {
        this.serviceType.isLoading = false;
        this.serviceType.isError = true;
      })
    
    return promise
  }

  @action
  updateServiceType(object) {
    let params = {
      uuid: object.attributes.id
    }
    this.serviceTypeDetail.isLoading = true;
    let promise = Api.put(`/service_types/${object.attributes.id}`, {service: toJS(object.attributes)})

    promise
      .then((response) => {
        this.serviceTypeDetail.isLoading = false;
        this.serviceTypeDetail.isError = false;
        stores.viewStore.serviceTypeShow(params,response.data.data.attributes.name);
        this.loadServiceType(params);
      })
      .catch((error) => {
        this.serviceTypeDetail.isLoading = false;
        this.serviceTypeDetail.isError = true;
      })

    return promise;
  }

  @action
  createServiceType() {
    this.serviceTypeDetail.isLoading = true;
    let promise = Api.post(`/service_types/`, {service: this.serviceTypeDetail.object})   

    promise
      .then((response) => {
        this.serviceTypeDetail.isLoading = false;
        this.serviceTypeDetail.isError = false;       
        this.serviceTypeDetail.object = response.data.data;  
        this.loadServiceTypes();      
      })
      .catch((error) => {
        this.serviceTypeDetail.isLoading = false;
        this.serviceTypeDetail.isError = true;       
      })

    return promise;
  }

  @action
  loadServiceTypes = () => {
    this.serviceType.isLoading = true;
    const {
      per_page,
      page,
      order,
      where,
      term
    } = stores.viewStore.serviceTypeSearch;
    let promise = Api.post('/service_types/search', { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.serviceType.isLoading = false;
        this.serviceType.isError = false;
        this.serviceType.objects = response.data.data;
        this.serviceType.meta = response.data.meta;
      })
      .catch((error) => {
        this.serviceType.isLoading = false;
        this.serviceType.isError = true;
        console.error(error)
      })
    
    return promise;
  }


  @action
  loadServiceTypesBooking = () => {
    this.serviceType.isLoading = true;
    const {
      per_page,
      page,
      order,
      where,
      term
    } = stores.viewStore.serviceTypeSearch;
    let promise = Api.post('/service_types/search', { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.serviceType.isLoading = false;
        this.serviceType.isError = false;
        this.serviceType.objects = response.data.data;
        this.serviceType.meta = response.data.meta;
        //this.loadServiceTypeDetail({uuid:response.data.data[0].attributes.id});
      })
      .catch((error) => {
        this.serviceType.isLoading = false;
        this.serviceType.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadServiceType(params) {
    this.serviceTypeDetail.isLoading = true;
    let promise = Api.get(`/service_types/${params.uuid}`)

    promise
      .then((response) => {
        this.serviceTypeDetail.isLoading = false;
        this.serviceTypeDetail.isError = false;
        this.serviceTypeDetail.object = response.data.data;
        stores.viewStore.serviceTypeShow(params,response.data.data.attributes.name);
      })
      .catch((error) => {
        this.serviceTypeDetail.isLoading = false;
        this.serviceTypeDetail.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  loadServiceTypeDetail(params) {
    this.serviceTypeDetail.isLoading = true;
    let promise = Api.get(`/service_types/${params.uuid}`)

    promise
      .then((response) => {
        this.serviceTypeDetail.isLoading = false;
        this.serviceTypeDetail.isError = false;
        this.serviceTypeDetail.object = response.data.data;
      })
      .catch((error) => {
        this.serviceTypeDetail.isLoading = false;
        this.serviceTypeDetail.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  loadPricingParameter(params) {
    this.serviceTypeDetail.object.attributes && this.serviceTypeDetail.object.attributes.pricing_parameters.length > 0 && this.serviceTypeDetail.object.attributes.pricing_parameters.map((pricing_attr) => {
      stores.pricingParameterStore.loadPricingParameterDetail({uuid:pricing_attr.id})
      .then(response => {
        this.princingRangeCombineArray.push(response.data.data)
      })
    });
  }

  @action
  loadPublicPricingParameter(params) {
    this.serviceTypeDetail.object.attributes && this.serviceTypeDetail.object.attributes.pricing_parameters.length > 0 && this.serviceTypeDetail.object.attributes.pricing_parameters.map((pricing_attr) => {
      stores.pricingParameterStore.loadPublicPricingParameterDetail({uuid:pricing_attr.id})
      .then(response => {
        this.princingRangeCombineArray.push(response.data.data)
      })
    });
  }


  // For Public link Actions starts

  @action
  loadPublicServiceTypesBooking = () => {
    this.serviceType.isLoading = true;
    const {
      per_page,
      page,
      order,
      where,
      term
    } = stores.viewStore.serviceTypeSearch;
    let promise = Api.post(`/public/service_types/search?x_tenant_id=${stores.preferenceStore.tenant_id}`, { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.serviceType.isLoading = false;
        this.serviceType.isError = false;
        this.serviceType.objects = response.data.data;
        this.serviceType.meta = response.data.meta;
        //this.loadServiceTypeDetail({uuid:response.data.data[0].attributes.id});
      })
      .catch((error) => {
        this.serviceType.isLoading = false;
        this.serviceType.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadPublicServiceTypeDetail(params) {
    this.serviceTypeDetail.isLoading = true;
    let promise = Api.get(`/public/service_types/${params.uuid}?x_tenant_id=${stores.bookingStore.tenant_id}`)

    promise
      .then((response) => {
        this.serviceTypeDetail.isLoading = false;
        this.serviceTypeDetail.isError = false;
        this.serviceTypeDetail.object = response.data.data;
      })
      .catch((error) => {
        this.serviceTypeDetail.isLoading = false;
        this.serviceTypeDetail.isError = true;
        console.error(error)
      })

    return promise;
  }

  // For Public link Actions ends
  
};

export default ServiceTypeStore;