import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Button, Intent, Alert, Icon
} from "@blueprintjs/core";
import { observable } from 'mobx';
import Toast from 'helpers/toaster';
import Icons from '../../svgs';

@inject('invoiceStore', 'xeroStore') @observer
class XeroCustomer extends Component {

  @observable showLoader = false;
  @observable isLinkCustomerWithXero = false;

  linkCustomer() {
    this.isLinkCustomerWithXero = true
  }

  handleCustomerLinkCancel() {
    this.isLinkCustomerWithXero = false
  }

  handleSaveConfirm() {
    this.isLinkCustomerWithXero = false
    this.showLoader = true;
    this.props.xeroStore.linkClientWithXeroCustomer(this.props.customer)
      .then((response) => {
        this.showLoader = false;
        this.props.onSuccess();
        Toast.show({ icon: 'thumbs-up', message: "Client Linked with Xero customer successfully.", intent: Intent.SUCCESS })
      })

  }

  render() {
    let {customer} = this.props;
    return(
      <React.Fragment>
        <div className="quickboos-client-info" key={Math.random()}>
          <div className="qb-name-field">
            <div className="qb-field-lable">
              <div><span>Client name:</span> {customer.Name} </div>
              <div><span>Contact Status:</span> {customer.ContactStatus}</div>
            </div>
            <div className="qb-link">
              <Button
                onClick={this.linkCustomer.bind(this)}
                loading={this.showLoader}
                className=""
              >
                <Icons.LinkIcon /> Link
              </Button>
            </div>
          </div>
        </div>
        <Alert
          cancelButtonText="No"
          confirmButtonText="Yes"
          icon="help"
          intent={Intent.PRIMARY}
          isOpen={this.isLinkCustomerWithXero}
          onCancel={this.handleCustomerLinkCancel.bind(this)}
          onConfirm={this.handleSaveConfirm.bind(this)}          
        >
            Are you sure you want to link client with this Xero customer?
            <div className="dark-text" style={{paddingTop: 5}}><b>{customer.Name}</b></div>
        </Alert> 
      </React.Fragment>
    )
  }

}

export default XeroCustomer;