import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Sticky from 'react-stickynode';
import styled from '@emotion/styled';
import SearchBars from 'components/SearchBars';
import Card from 'components/Card';
import Page from 'components/Page';
import SearchBarWrapper from 'components/SearchBarWrapper';
import { observer, inject } from 'mobx-react';
import { Spinner, NonIdealState} from "@blueprintjs/core";
import AssignmentsTable from 'components/Tables/Assignments';

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`
const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`

@inject('store', 'jobStore') @observer
class AssignmentsIndexPage extends Component {

  render() {
    let content;
    const {
      props: {
        jobStore: {
          jobs: {
            isLoading,
            isError
          }
        }
      }
    } = this;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (!isError && !isLoading) {
      content = <AssignmentsTable />
    }
    return(
      <div>
        <Helmet>
          <title>Assignments | FieldNexus</title>
        </Helmet>
        <PageWrapGray>
          <SearchBars.Assignment />
          <Card>
            {content}
          </Card>
        </PageWrapGray>
      </div>
    )
  }

};

export default AssignmentsIndexPage;