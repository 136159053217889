import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PaymentGrid } from 'components/Styles';

@inject('quoteStore') @observer
class QuoteFooter extends Component {

  render() {
    return(
      <PaymentGrid>
        <div className="payment-wrapper">
          <div><h5><span style={{ color: 'rgba(2, 32, 71, 0.5)' }}>Powered by</span> <a target="_blank" href="https://fieldnexus.com"><img src="\fieldnexus-logo.png" /></a></h5></div>
          <div><p><a target="_blank" href="https://fieldnexus.com/terms_and_conditions" style={{ color: "#91A4B9" }}> Terms & Conditions</a> | <a target="_blank" href="https://fieldnexus.com/privacy_policy  " style={{ color: "#91A4B9" }}>Privacy Policy</a></p></div>
        </div>
      </PaymentGrid>
    )
  }

};

export default QuoteFooter;