import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { 
  Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import Toast from 'helpers/toaster';
import ProductCategory from 'components/ProductCategory/ProductCategory';


@inject('store', 'productCategoryStore') @observer
class ProductCategoryTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable isProductCategoryDialogVisible = false;
  @observable product_service;

  handleProductCategoryClick(event, productCategory) {
    switch (event.label) {
      case 'Edit':
        this.product_service = productCategory;
        this.props.productCategoryStore.productCategory.object = productCategory.attributes;
        this.isProductCategoryDialogVisible = true;
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.product_service = productCategory;
        break;
      default:
    }
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.productCategoryStore.delete(this.product_service)
      .then(this.onSuccessfulDeleteProductCategory.bind(this))
      .catch(this.onFailedProductCategoryDelete.bind(this))
  }

  onSuccessfulDeleteProductCategory(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Product category deleted successfully!', intent: Intent.SUCCESS })
    this.product_service = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedProductCategoryDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete product category!', intent: Intent.DANGER })
  }

  onCloseProductCategoryDialog() {
    this.isProductCategoryDialogVisible = false;
  }

  onSucessCreateProductCategory() {
    this.isProductCategoryDialogVisible = false;
  }

  render() {
    let {
      productCategoryStore: {
        productCategories: {
          objects
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning" style={{marginTop:'15px'}}>
          <h4 className="bp3-heading">No Results</h4>
        </div>
      )
    }
    return(
      <React.Fragment>
         <div className="card rounded-0 table-responsive">
        <table className="table table-data-presentation table-hover" style={{ width: '100%'}}>
          <thead>
            <tr>
              <th>Title</th>
              <th className="item-center" width="15%">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((productCategory) => {
                return(
                  <tr key={productCategory.id}>
                    <td>{productCategory.attributes.title}</td>
                    <td>
                      {productCategory.attributes.title === 'Materials' || productCategory.attributes.title === 'Others' || productCategory.attributes.title === 'Labor' ?
                        '' :
                        <div className="action-buttons" minimal={true}>
                          <Button title="Edit" minimal={true} small={true} disabled={productCategory.attributes.is_readonly} onClick={() => this.handleProductCategoryClick({ label: 'Edit' },  productCategory)}>
                            <img src="/edit-light-icon.svg" alt={'edit icon'}/>
                          </Button>
                          <Divider/>
                          <Button title="Delete" minimal={true} small={true} disabled={productCategory.attributes.is_readonly} onClick={() => this.handleProductCategoryClick({ label: 'Delete' }, productCategory)}>
                            <img src="/delete-light-icon.svg" alt={'delete icon'}/>
                          </Button>
                        </div>
                      }
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to do this?
          </p>
        </Alert>
        <ProductCategory 
          isOpen={this.isProductCategoryDialogVisible} 
          title={"Update Product category"}
          edit={true}
          onClose={this.onCloseProductCategoryDialog.bind(this)}
          onSuccess={this.onSucessCreateProductCategory.bind(this)}
        />
      </React.Fragment>
    )
  }

};

export default ProductCategoryTable;