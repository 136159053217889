import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable, toJS } from 'mobx';
import { 
  Divider, Intent,
  Button,Checkbox,
  FormGroup, Spinner
} from '@blueprintjs/core';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Toast from "helpers/toaster";
import ServiceTypePicker from "components/ServiceTypePicker";
import BookingsSidebar from '../bookings/bookings-sidebar';
import Range from 'components/PricingParameter/Range';
import { currencySymbol } from 'helpers/currency';
import { Helmet } from 'react-helmet';

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`

@inject("pricingParameterStore", 'viewStore')
@observer
class PricingParameterShowPage extends Component {
  @observable selectedOptions = null;

  componentDidMount() {
    let {
      props: {
        pricingParameterStore,
        pricingParameterStore: {
          pricingParameterDetailUpdate
        }
      }
    } = this;
    pricingParameterStore.loadPricingParameter(this.props.viewStore.routingParams)
    .then(() => {
      this.selectedOptions = pricingParameterDetailUpdate.object.attributes.pricing_parameter_services.map((pps) => {
        return {
          id: pps.id,
          value: pps.service_type_id,
          selected: true,
          label: pps.label
        }
      })
    })
  }

  updatePricingParameter() {
    let {
      props: {
        pricingParameterStore: {
          pricingParameterDetailUpdate,
          pricingParameterDetailUpdate: { 
            object,
            isLoading
           }
        }
      }
    } = this;

    pricingParameterDetailUpdate.isLoading = true;
    
    if(object.attributes.name === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    object.attributes.pricing_parameter_ranges_attributes = object.attributes.pricing_parameter_ranges;
    object.attributes.pricing_parameter_services_attributes = this.selectedOptions.map((option) => {
      return {
        "service_type_id": option.value
      }
    })

    this.props.pricingParameterStore
      .updatePricingParameter(object)
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Pricing parameter updated successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to update pricing parameter!",
          intent: Intent.DANGER
        });
      })
      .finally(() => {
        pricingParameterDetailUpdate.isLoading = false;
      })
  }

  onServiceTypeSelect(selectedOptions, allOptions) {
    this.selectedOptions = selectedOptions;
  }

  changePricingType = (pricing_type) => {
    let {
      pricingParameterStore: {
        pricingParameterDetailUpdate: {
          object,
        }
      }
    } = this.props;
    object.attributes.pricing_type = pricing_type;
  }

  render() {
    let {
      pricingParameterStore,
      pricingParameterStore: {
        pricingParameterDetailUpdate,
        pricingParameterDetailUpdate: {
          object,
          isLoading
        }
      }
    } = this.props;
    {
      isLoading && 
        <Spinner size='25' />
    } 
    return (
      <div>
        <Helmet>
          <title>{'Pricing Parameter | Fieldnexus'}</title>
        </Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <BookingsSidebar />
          </div>
          {
            object && object.attributes &&
            <div className="col-lg-10">
              <PageWrapGray>
                <div className="pricing-parameter-table">
                  <Card>
                    <CardContent>
                      <div>
                        <div className="left-side-panel-servie-type">
                          <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                            Update Pricing Parameter - {pricingParameterDetailUpdate.object.attributes.name}
                          </h4>
                        </div>
                      </div>
                      <Divider style={{ margin: '16px 0px' }}></Divider>
                      <div className="left-side-panel-servie-type pricing-parameter">
                        <FormGroup label="Name" >
                          <input 
                            type='text' 
                            className='form-control' 
                            placeholder='Name'
                            defaultValue={object.attributes.name}
                            onChange={(event) => { object.attributes.name = event.target.value; }}
                          />
                        </FormGroup>
                        <FormGroup 
                          label="Service Type(s)"
                          labelFor="text-input"
                          labelInfo=""
                        >
                          {
                            this.selectedOptions && 
                              <ServiceTypePicker
                                selectedOptions={toJS(this.selectedOptions)}
                                onChange={ this.onServiceTypeSelect.bind(this) }
                              />
                          }
                        </FormGroup>
                        <FormGroup label="Pricing Type" labelFor="text-input" labelInfo="">
                          <div className="bookings-radio">
                            <Checkbox 
                              inline
                              large={false}
                              checked={object.attributes.pricing_type === 'flat'} 
                              onChange={this.changePricingType.bind(this, 'flat')}>
                                Flat
                            </Checkbox>
                            <Checkbox 
                              inline
                              large={false}
                              checked={object.attributes.pricing_type === 'range'} 
                              onChange={this.changePricingType.bind(this, 'range')}>
                                Pricing Ranges
                            </Checkbox>
                          </div>
                        </FormGroup>
                        { 
                          object.attributes.pricing_type === 'flat' &&
                          <FormGroup label={`Price (${currencySymbol()})`} >
                            <input 
                              type='number' 
                              className='form-control' 
                              placeholder='Price'
                              defaultValue={object.attributes.price}
                              onChange={(event) => {object.attributes.price = event.target.value;}}            
                            />
                          </FormGroup>
                        }
                      </div>
                      { 
                        object.attributes.pricing_type === 'flat' &&
                        <div className="left-side-panel-servie-type">
                          <FormGroup label="Range Start" >
                            <input 
                              type='number' 
                              className='form-control' 
                              placeholder='Range Start'
                              defaultValue={object.attributes.range_start}
                              onChange={(event) => {object.attributes.range_start = event.target.value;}}            
                            />
                          </FormGroup>
                          <FormGroup label="Range End" >
                            <input 
                              type='number' 
                              className='form-control' 
                              placeholder='Range End'
                              defaultValue={object.attributes.range_end}
                              onChange={(event) => {object.attributes.range_end = event.target.value;}}            
                            />
                          </FormGroup>
                          <FormGroup label="Duration (In mins)" >
                            <input 
                              type='number' 
                              step={"1"}
                              className='form-control' 
                              placeholder='Duration'
                              defaultValue={object.attributes.duration}
                              onChange={(event) => {object.attributes.duration = event.target.value;}}            
                            />
                          </FormGroup>
                        </div>
                      }
                      { 
                        object.attributes.pricing_type === 'range' &&
                        <div className="card rounded-0 table-responsive">
                          <table className="table inner-table table-data-presentation table-hover" style={{ width: '100%' }}>
                            <thead>
                              <tr>
                                <th>Label</th>
                                <th>Min Range</th>
                                <th>Max Range</th>
                                <th>Duration</th>
                                <th>Price</th>
                                <th>Image</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              { 
                                object && object.attributes.pricing_parameter_ranges && object.attributes.pricing_parameter_ranges.map((range, index) => {
                                  return(
                                    <Range error={range.error} page={'edit'} range={range} key={Math.random()} rangeIndex={index} />
                                  )
                                })
                              }
                            </tbody>
                          </table>
                          <div className="row align-items-center">
                            <div className="col-sm-6 fg-pass fg-ad-member">
                              <a onClick={() => pricingParameterStore.incrementRangeCountUpdate()}>+ Add More</a>
                            </div>
                          </div>
                        </div>
                      }
                      <div className="">
                        <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.updatePricingParameter.bind(this)}>Update</Button>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </PageWrapGray>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default PricingParameterShowPage;
