import { observable, action } from 'mobx';
import { Api } from 'services/Api';

class BillingStore {

  @observable billing = {
    isLoading: false,
    isError: false,
    overview: null
  };

  @observable cards = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable payment_transactions = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @action
  loadTransactions() {
    this.payment_transactions.isLoading = true
    let promise = Api.get(`/billing/payment_transactions`)

    promise
      .then((response) => {
        this.payment_transactions.isLoading = false
        this.payment_transactions.isError = false
        this.payment_transactions.objects = response.data.data
      })
      .catch((error) => {
        this.payment_transactions.isLoading = false
        this.payment_transactions.isError = true
      })
  }

  @action
  loadCards() {
    this.cards.isLoading = true;
    let promise = Api.get(`/cards`)
    
    promise
      .then((response) => {
        this.cards.isLoading = false;
        this.cards.isError = false;
        this.cards.objects = response.data.data
      })
      .catch((error) => {
        this.cards.isLoading = false;
        this.cards.isError = true;
      })
  }

  @action
  addCard(token) {
    let promise = Api.post(`/cards`, {token: token.id})

    promise
      .then((response) => {
        this.loadCards();
      })
      .catch((error) => {
      })

    return promise;
  }

  @action
  deleteCard(card) {
    let promise = Api.delete(`/cards/${card.attributes.id}`)

    promise
      .then((response) => {
        this.loadCards();
      })
      .catch((error) => {
      })

    return promise;
  }

};

export default BillingStore;