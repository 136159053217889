import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import { observable, toJS } from 'mobx';
import { Spinner, Alert, Intent, Button } from '@blueprintjs/core';

@inject('viewStore', 'jobStore') @observer
class ImagesTable extends React.Component {

  @observable isLoading = true;
  @observable images = [];
  @observable imageSet = [];
  @observable isOpen = false;
  @observable currentImage = 0;
  @observable isDeleteDialogVisible = false;

  componentWillMount() {
    let { jobStore } = this.props;
    jobStore.loadImages()
      .then((response) => {
        this.images = response.data.data;
        this.setImages();
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
      })
  }

  setImages() {
    if(this.images.length > 0) {
      this.imageSet = this.images.map((image) => {
        return {
          src: image.attributes.asset.url,
          thumbnail: image.attributes.asset.thumb.url
        }
      })
    } else {
      this.imageSet = [];
    }
  }

  closeLightbox() {
    this.isOpen = false;
  }

  openLightbox(index) {
    this.currentImage = index
    this.isOpen = true;
  }

  gotoPrevious() {
    this.currentImage = this.currentImage - 1
    this.forceUpdate()
  }

  gotoNext() {
    this.currentImage = this.currentImage + 1
    this.forceUpdate()
  }

  gotoImage(index) {
    this.currentImage = index;
  }

  startDeleteImage(index) {
    this.indexToDelete = index;
    this.isDeleteDialogVisible = true;
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    let {
      jobStore,
      jobStore: {
        job: {
          object
        }
      }
    } = this.props;
    this.isDeleteDialogVisible = false;
    this.isLoading = true;
    const image = this.images[this.indexToDelete];
    jobStore.deleteImage(image.attributes.work_report_id, image.id)
      .then(() => {
        jobStore.refreshJob({ uuid: object.attributes.id })
          .then(() => {
            this.images.splice(this.indexToDelete, 1);
            this.setImages();
            this.isLoading = false;
          })
      })
      .catch((error) => {
        this.isLoading = false;
      })
  }

  render() {
    if (this.isLoading) {
      return <Spinner size='25' />
    }

    if (this.imageSet.length === 0 ) {
      return (
        <div className="no-data-found">
          <img src="/noImages.svg" />
          <h3>No Images</h3>
          <p>No images have been uploaded for this job yet.</p>
          {/* <button className="add-new-item-btn">Add Images</button> */}
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="job-desription" style={{ paddingLeft: '10px' }}>
          <h6>
            <strong className="field-label">
            </strong>
          </h6>
        </div>

        <div style={{ padding: '0px 10px' }} >
          {
            this.imageSet.length === 0 && <div className="bp3-callout warning-alert-message bp3-intent-warning bp3-icon-info-sign table-card">
              No images have been uploded for this job yet.
            </div>
          }
        </div>

        <div className='job-detail-images' style={{ paddingLeft: '20px', paddingBottom: '10px' }}>
          {
            this.imageSet.length > 0 && this.imageSet.map((image, index) => 
              <div className="job-images-action" key={index}>
                <img
                  src={image.thumbnail}
                  alt="Attached with Quotes"
                  onClick={() => this.openLightbox(index)}
                />
                <Button icon="trash" className="delete-image-icon" onClick={() => this.startDeleteImage(index)}/>
              </div>
            )
          }
          <Lightbox
            open={this.isOpen}
            close={this.closeLightbox.bind(this)}
            slides={toJS(this.imageSet)}
            plugins={[Zoom, Thumbnails]}
            index={this.currentImage}
            zoom={{
              maxZoomPixelRatio: 10,
              zoomInMultiplier: 1.5,
            }}
          />
          <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to delete this?
          </p>
        </Alert>
        </div>
      </React.Fragment>
    )
  }
}

export default ImagesTable;