import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

class FormStore {
  @observable forms = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable form = {
    isLoading: false,
    isError: false,
    object: {}
  };

  @action
  createForm() {
    this.form.isLoading = true
    let promise = Api.post(`/forms`, {form: this.form.object})

    promise
      .then((response) => {
        this.form.isLoading = false;
        this.form.isError = false;
        this.form.object = response.data.data
        stores.viewStore.formShow({uuid: this.form.object.attributes.id})
      })
      .catch((error) => {
        this.form.isLoading = false;
        this.form.isError = true;
      })
    
    return promise;
  }

  @action
  deleteForm(form) {
    this.form.isLoading = true
    let promise = Api.delete(`/forms/${form.id}`)

    promise
      .then((response) => {
        this.form.isLoading = false;
        this.form.isError = false;
        this.loadForms()
      })
      .catch((error) => {
        this.form.isLoading = false;
        this.form.isError = true;
      })
    
    return promise;
  }

  @action
  loadForms = () => {
    this.forms.isLoading = true;
    const {
      per_page,
      page,
      order,
      term
    } = stores.viewStore.formSearch;
    let promise = Api.post('/forms/search', {
      search: {
        term: term,
        per_page: per_page,
        page: page,
        order: order
      }
    });

    promise
      .then((response) => {
        this.forms.isLoading = false;
        this.forms.isError = false;
        this.forms.objects = response.data.data;
        this.forms.meta = response.data.meta;
      })
      .catch((error) => {
        this.forms.isLoading = false;
        this.forms.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  clearSelectedForm() {
    this.form = {
      isLoading: false,
      isError: false,
      form: null
    }
  }

  @action
  loadForm(params) {
    this.form.isLoading = true;
    let promise = Api.get(`/forms/${params.uuid}`)
    
    promise
      .then((response) => {
        this.form.isLoading = false;
        this.form.isError = false;
        this.form.object = response.data.data
      })
      .catch((error) => {
        this.form.isLoading = false;
        this.form.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  updateForm(params) {
    this.form.isLoading = true;
    let promise = Api.put(`/forms/${params.uuid}`, params)
    
    promise
      .then((response) => {
        window.location.reload()
        this.form.isLoading = false;
        this.form.isError = false;
        this.form.object = response.data.data;
      })
      .catch((error) => {
        this.form.isLoading = false;
        alert('Error occured' + error);
        console.error(error)
      })

    return promise;
  }

};

export default FormStore;