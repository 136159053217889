import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('store', 'workReportStore') @observer
class WorkReportHeaderPurchaseOrder extends Component {
  render() {
    const {
      workReportStore: {
        purchaseOrderItem: {
          object
        }
      }
    } = this.props;
    return (
      <div style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <div>
          <img src={object.attributes.tenant.logo} height='75' alt='tenant logo'/>
        </div>
        <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column' }}>
          <h5>{object.attributes.tenant.name}</h5>
          <span>{object.attributes.tenant.street_address}</span>
          <span>{object.attributes.tenant.city}, {object.attributes.tenant.province}, {object.attributes.tenant.postal_code}</span>
          <span>
            <strong>Tel: </strong>
            {object.attributes.tenant.phone}
          </span>
        </div>
      </div>
    )
  }
}

export default WorkReportHeaderPurchaseOrder;