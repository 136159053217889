import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

class ServiceTypeCategoryStore {

  @observable serviceTypeCategories = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable serviceTypeCategory = {
    isLoading: false,
    isError: false,
    object: {
      name:''
    }
  };

  @observable serviceTypeCategorySearch = {
    term: '',
    order: {
      name: 'asc'
    },
    per_page: 50,
    page: 1
  }

  @action
  loadServiceTypeCategories() {
    this.serviceTypeCategories.isLoading = true;
    const {
      per_page,
      page,
      order,
      where
    } = this.serviceTypeCategorySearch;

    let promise = Api.post('/service_type_category/search', { 
      search: { 
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.serviceTypeCategories.isLoading = false;
        this.serviceTypeCategories.objects = response.data.data;
      })
      .catch((error) => {
        this.serviceTypeCategories.isLoading = false;
        this.serviceTypeCategories.isError = true;
      })
    
    return promise
  }


  loadServiceTypePublicCategories() {
    this.serviceTypeCategories.isLoading = true;
    const {
      per_page,
      page,
      order,
      where
    } = this.serviceTypeCategorySearch;

    let promise = Api.post(`/public/service_type_category/search?x_tenant_id=${stores.preferenceStore.tenant_id}`, { 
      search: { 
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.serviceTypeCategories.isLoading = false;
        this.serviceTypeCategories.objects = response.data.data;
      })
      .catch((error) => {
        this.serviceTypeCategories.isLoading = false;
        this.serviceTypeCategories.isError = true;
      })
    
    return promise
  }

  @action
  create() {
    this.serviceTypeCategory.isLoading = true;
    let promise = Api.post(`/service_type_category`, {service_type_category: this.serviceTypeCategory.object})

    promise
      .then((response) => {
        this.serviceTypeCategory.isLoading = false;
        //this.loadServiceTypeCategories();
      })
      .catch((error) => {
        this.serviceTypeCategory.isLoading = false;
        this.serviceTypeCategory.isError = true;
      })
    
    return promise;
  }

  @action
  update() {
    this.serviceTypeCategory.isLoading = true;
    let promise = Api.put(`/service_type_category/${this.serviceTypeCategory.object.id}`, {service_type_category: this.serviceTypeCategory.object})

    promise
      .then((response) => {
        this.serviceTypeCategory.isLoading = false;
        this.loadServiceTypeCategories();
      })
      .catch((error) => {
        this.serviceTypeCategory.isLoading = false;
        this.serviceTypeCategory.isError = true;
      })
    
    return promise;
  }

  @action
  delete(serviceTypeCategory) {
    this.serviceTypeCategories.isLoading = true;
    let promise = Api.delete(`/service_type_category/${serviceTypeCategory.attributes.id}`)

    promise
      .then((response) => {
        this.loadServiceTypeCategories();
      })
      .catch((error) => {
        this.serviceTypeCategories.isLoading = false;
        this.serviceTypeCategories.isError = true;
      })
    
    return promise;
  }

}

export default ServiceTypeCategoryStore;