import React, { Component } from 'react'
import AtlasPagination from '@atlaskit/pagination';

class Pagination extends Component {

  onChange = (e, page) => {
    const { value, onChange } = this.props;
    if (page !== value) {
      onChange(page);
    }
  };

  render() {
    const { value, total } = this.props;
    return (
      <AtlasPagination
        selectedIndex={value - 1}
        pages={Array.from({ length: total }, (_, i) => i + 1)}
        onChange={this.onChange}
      />
    );
  }
}

export default Pagination;