import React from 'react';
import Avatar from '@atlaskit/avatar';
import {
  Icon, Spinner, Button, Alert, Intent
} from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import _ from 'lodash';
import { formatTime } from '../../helpers/time';
import Toast from 'helpers/toaster';
import AddNotesModal from 'components/Job/AddNotesModal';
let numeral = require('numeral');

@inject('store','viewStore', 'jobStore', 'activityStore') @observer
class NotesTable extends React.Component {

  @observable isDeleteDialogVisible = false;
  @observable note;
  @observable isOpenAddNotesDialog = false;

  componentDidMount() {
    let { job } = this.props;
    this.props.jobStore.loadNotes(job)  
  }

  handleNotesClick(event, notes) {
    switch (event.label) {
      case 'Edit':
        this.note = notes;
        this.props.jobStore.persistNote.object = notes.attributes;
        this.isOpenAddNotesDialog = true;
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.note = notes;
        break;
      default:
    }
  }

  onCloseNotesDialog() {
    this.isOpenAddNotesDialog = false;
  }

  onSucessNotes() {
    let {
      jobStore,
      jobStore: {
        job: {
          object
        }
      },
      activityStore
    } = this.props;

    this.isOpenAddNotesDialog = false;

    jobStore.refreshJob({ uuid: object.attributes.id })
      .then(() => {
        activityStore.loadActivityLogs(object.attributes.id);
      })
  }

  startDeleteNote(note) {
    this.note = note;
    this.isDeleteDialogVisible = true;
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    const { job } = this.props;
    this.props.jobStore.deleteNote(this.note, job)
      .then(this.onSuccessfulDeleteNote.bind(this))
      .catch(this.onFailDeleteNote.bind(this))
  }

  onSuccessfulDeleteNote(response) {
    let {
      jobStore,
      jobStore: {
        job: {
          object
        }
      },
      activityStore
    } = this.props;

    Toast.show({ icon: 'thumbs-up', message: 'Note deleted successfully!', intent: Intent.SUCCESS })
    this.note = null;
    this.isDeleteDialogVisible = false;

    jobStore.refreshJob({ uuid: object.attributes.id })
      .then(() => {
        activityStore.loadActivityLogs(object.attributes.id);
      })
  }

  onFailDeleteNote(error) {
    this.isDeleteDialogVisible = false;
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong. Unable to delete note!', intent: Intent.DANGER })
    }
  }

  render() {
    let { 
      jobStore: {
        notes,
        notes: {
          isLoading,
          objects
        }
      } 
    } = this.props
    const {
      viewStore: {
        currentUser
      }
    } = this.props;
    if (isLoading) {
      return <div className="spinner-center"><Spinner size='25' /></div>
    }
    if (objects.length === 0) {
      return (
        <div className="no-data-found notes-style">
          <img src="/onNote.svg" />
          <h3>No Notes</h3>
          <p>No notes have been created for this job yet.</p>
        </div>
      )
    }
  
    return (
      <div className="job-card-notes">
        <div className="card-scrollview">
          {
            objects.map((note) => {
              return (
                <div className="notes-card" key={note.id}>
                  <div className="job-card-header">
                    <div className="header-title">
                      <div className="name">{note.attributes.user_name}</div>
                      <div className="date">
                        {
                          formatTime(note.attributes.created_at)
                        }
                      </div>
                    </div>
                    <div className="card-action">
                      { note.attributes.user_id === currentUser.id ?
                        <button className="icon-style" onClick={() => this.handleNotesClick({ label: 'Edit' },  note)}><img src="/edit-icon.svg"/> </button>
                        :''
                      }
                      { note.attributes.user_id === currentUser.id ?
                        <button className="icon-style icon-width"  onClick={() => this.startDeleteNote(note)}><img src="/Delete-Icon.svg"/> </button>
                        :''
                      }
                    </div>
                  </div>
                  <div className="notes-content">
                    {note.attributes.body}
                  </div>
                </div>
              )
            })
          }
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to delete this?
          </p>
        </Alert>
        <AddNotesModal
          isOpen={this.isOpenAddNotesDialog}
          onClose={this.onCloseNotesDialog.bind(this)}
          onSuccess={this.onSucessNotes.bind(this)}
          title={"Update Notes"}
          edit={true}
        />
      </div>
    )
  }
}

export default NotesTable;