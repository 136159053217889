import React, { Component } from 'react';
import { Button, Popover, Position, MenuItem, Card } from '@blueprintjs/core';
import { DateRangePicker } from "@blueprintjs/datetime";
import { Select } from "@blueprintjs/select";
import { observable } from 'mobx';

class Dashboard extends Component {

  @observable search = {
    startDate: null,
    endDate: null,
    client: null,
    status: null
  };

  renderTechnicianSelector = () => {
    return (
      <Card>
        <label className="bp3-control bp3-checkbox">
          <input type="checkbox" />
          <span className="bp3-control-indicator"></span>
          Ali Muzaffar
        </label>
        <label className="bp3-control bp3-checkbox">
          <input type="checkbox" />
          <span className="bp3-control-indicator"></span>
          Bil Roberts
        </label>
        <label className="bp3-control bp3-checkbox">
          <input type="checkbox" />
          <span className="bp3-control-indicator"></span>
          Rashid Durant
        </label>
        <label className="bp3-control bp3-checkbox">
          <input type="checkbox" />
          <span className="bp3-control-indicator"></span>
          Kevin Spacey
        </label>
        <label className="bp3-control bp3-checkbox">
          <input type="checkbox" />
          <span className="bp3-control-indicator"></span>
          Milton Murray
        </label>
        <label className="bp3-control bp3-checkbox">
          <input type="checkbox" />
          <span className="bp3-control-indicator"></span>
          Jasdeep Singh
        </label>
        <label className="bp3-control bp3-checkbox">
          <input type="checkbox" />
          <span className="bp3-control-indicator"></span>
          Bill Murray
        </label>
        <label className="bp3-control bp3-checkbox">
          <input type="checkbox" />
          <span className="bp3-control-indicator"></span>
          Micahel Doe
        </label>
      </Card>
    )
  }

  dateRangePicker = () => {
    return (
      <DateRangePicker 
        value={[this.search.startDate, this.search.endDate]}
        formatDate={(d) => { return d.toDateString() }} 
        parseDate={(d) => { console.log(d); return new Date(d) }}
        allowSingleDayRange={true}
        onChange={this.onDateRangeChange.bind(this)}
      />
    )
  }

  onDateRangeChange = (range) => {
    this.search.startDate = range[0];
    this.search.endDate = range[1];
    this.forceUpdate();
  }

  render() {
    return (
      <nav className="bp3-navbar">
        <div>
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <Button rightIcon='caret-down'>
                <Popover 
                  content={this.dateRangePicker.bind(this)()}
                  position={Position.BOTTOM_LEFT}
                  style={{ padding: '10px' }}
                  usePortal={true} >
                  {(!this.search.startDate || !this.search.endDate) && `Date Range`}
                  {this.search.startDate && this.search.endDate && `${this.search.startDate.toDateString()} - ${this.search.endDate.toDateString()}`}
                </Popover>
              </Button>
              <Select
                items={['Client 1', 'Client 2', 'Client 3']}
                itemRenderer={(item, { handleClick }) => { return (<MenuItem text={item} key={item} onClick={handleClick} />) }}
                onItemSelect={(item) => { this.search.client = item; this.forceUpdate() }}
              >
                <Button text={this.search.client ? this.search.client : 'Client'} rightIcon='caret-down' />
              </Select>
              <Button rightIcon='caret-down'>
                <Popover 
                  content={this.renderTechnicianSelector.bind(this)()}
                  position={Position.BOTTOM_LEFT}
                  style={{ padding: '10px' }}
                  usePortal={true} >
                  Technician
                </Popover>
              </Button>
              <div className="bp3-control-group">
                <div className="bp3-select">
                  <select defaultValue={'Status'}>
                    <option>Status</option>
                    <option value="1">In Progress</option>
                    <option value="2">Active</option>
                    <option value="3">Completed</option>
                    <option value="3">Paused</option>
                  </select>
                </div>
                <div className="bp3-select">
                  <select defaultValue={'Status'}>
                    <option>Service Type</option>
                    <option value="1">PMA</option>
                    <option value="2">Quoted</option>
                    <option value="2">Service Work</option>
                    <option value="2">Project</option>
                    <option value="2">On Call</option>
                    <option value="2">Shop Time</option>
                    <option value="2">Warranty</option>
                  </select>
                </div>
                <input type="text" className="bp3-input" placeholder="Job Number" />
                <Button rightIcon='refresh' text='Reset' onClick={() => { this.search = {} }}/>
                <Button rightIcon='search' text='Search' />
                {/* <Button rightIcon='grouped-bar-chart' onClick={() => { this.props.store.visible = !this.props.store.visible }}/> */}
              </div>
            </div>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            <Button rightIcon='caret-down' intent='primary'>
              Options
            </Button>
          </div>
        </div>
      </nav>
    )
  }  
};

export default Dashboard;