import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);
  width: 100%;
`
var label= '';
var indexChecked = '';

@inject('bookingStore') @observer
class QuestionSelect extends React.Component {
  @observable isChecked = false;

	onQuestionDropDownChange = (event,question) => {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    if(object && object.attributes){
      let index = object.attributes.booking_questions.map(function(item) { return item.question_id; }).indexOf(question.id);
      if(index > -1){
        object.attributes.booking_questions[index].answer = event.target.value;
      }else{
        object.attributes.booking_questions.push({answer:event.target.value,question_id:question.id});
      }

    }else{
      let index = object.booking_questions_attributes.map(function(item) { return item.question_id; }).indexOf(question.id);
      if(index > -1){
        object.booking_questions_attributes[index].answer = event.target.value;
      }else{
        object.booking_questions_attributes.push({answer:event.target.value,question_id:question.id});
      }

    }
    
  }


  render() {
    let {
			question, answer, checkedValue, selectedAnswer, bookingStatus
    } = this.props;
    
    return(
			<select
        className = "form-control"
        disabled={bookingStatus && bookingStatus === 'confirmed' ? true : false}
        onChange={(event) => {this.onQuestionDropDownChange(event,question)}} 
      >
        <option> Select any one </option>
        {question.field_value.split(/\r?\n/).map((answer) => {
         
          return(
            <option 
              label={answer.split(":")[0]}
              selected={checkedValue === question.id && answer.split(":")[0] === selectedAnswer.trim() ? 'selected' : ''}
            > {answer.split(":")[1]} </option>
          )
        })}
      </select>
    )
  }
}



export default QuestionSelect;