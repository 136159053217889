import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { 
  Button,FormGroup, TextArea,
  MenuItem, Intent, Spinner
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import styled from '@emotion/styled';
import SettingsSidebar from '../../pages/preferences/settings-sidebar';
import { TimezonePicker } from "@blueprintjs/timezone";
import { Helmet } from 'react-helmet';
import CompanyType from 'components/CompanyType';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import TeamSize from 'components/TeamSize';
import _ from 'lodash';
import RichTextEditor from 'react-rte'; 

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`
const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  ]
};

@inject('preferenceStore','viewStore','store', 'quickbooksStore') @observer
class CompanyDetails extends Component {

  @observable countryOptions = [];
  @observable stateOptions = [];
  @observable defaultCurrency = "";
  @observable invoice_agreement_values = RichTextEditor.createValueFromString(`<p><strong>  </strong></p>`, 'html');
  @observable estimate_agreement_values = RichTextEditor.createValueFromString(`<p><strong>  </strong></p>`, 'html');

  handleChangeState = (selectedOptionState) => {
    let {
      preferenceStore: {
        tenant: {
          object
        }
      }
    } = this.props; 
    object.attributes.province = selectedOptionState.attributes.name;
  };

  handleChangeImage = (event) => {
    let {
      preferenceStore: {
        tenant: {
          object
        }
      }
    } = this.props; 
    var file = event.target.files[0];
    //object.attributes.logo= event.target.files[0];
    var reader = new FileReader();
    let base64String = '';
    
    reader.onload = function() {
       base64String = reader.result;
       object.attributes.logo = base64String;
       object.attributes.logo_file = base64String;
    };
    reader.readAsDataURL(file);
    
    
  };

  handleChangeCountry = (selectedOptionCountry) => {
    let {
      preferenceStore: {
        tenant: {
          object
        }    
      }
    } = this.props;
    object.attributes.country = selectedOptionCountry.attributes.name;
    object.attributes.country_id = `${selectedOptionCountry.attributes.country_id}`;
    object.attributes.currency = selectedOptionCountry.attributes.currency_code;
    object.attributes.currency_symbol = selectedOptionCountry.attributes.currency_symbol;
    object.attributes.province = "";
    this.defaultCurrency = `${object.attributes.currency} ${object.attributes.currency_symbol}`
    this.props.viewStore.stateSearch.where.country_id = selectedOptionCountry.attributes.country_id;

    this.props.preferenceStore.loadStates()
      .then((options) => {
        this.stateOptions = options;
      });
  };
  
  componentDidMount() {
    let {
      preferenceStore,
      preferenceStore: {
        tenant: {
          object
        }
      },
      quickbooksStore,
      quickbooksStore: {
        quickbooksItems: {
          objects
        }
      }
    } = this.props;

    if(objects && objects.length === 0) {
      quickbooksStore.loadQuickbooksItems();
    }

    preferenceStore.loadTenant()
      .then((tenant) => {
        if(tenant && tenant.data && tenant.data.data && tenant.data.data.attributes && tenant.data.data.attributes.country_id){
          const tenantObject = tenant.data.data;
          this.props.viewStore.stateSearch.where.country_id = tenantObject.attributes.country_id;
          this.defaultCurrency = `${tenantObject.attributes.currency} ${tenantObject.attributes.currency_symbol}`
          if (!tenantObject.attributes.invoice_agreement) {
            this.invoice_agreement_values = RichTextEditor.createValueFromString(`
                <p><strong>  </strong></p>
              `, 'html');
          } else {
            this.invoice_agreement_values = RichTextEditor.createValueFromString(tenantObject.attributes.invoice_agreement,'html');
          }
          if (!tenantObject.attributes.estimate_agreement) {
            this.estimate_agreement_values = RichTextEditor.createValueFromString(`
                <p><strong>  </strong></p>
              `, 'html');
          } else {
            this.estimate_agreement_values = RichTextEditor.createValueFromString(tenantObject.attributes.estimate_agreement,'html');
          }
        }else{
          const tenantObject = tenant.data.data;
          this.props.viewStore.stateSearch.where.country_id = 233;
          this.defaultCurrency = `${tenantObject.attributes.currency} ${tenantObject.attributes.currency_symbol}`          
        }
        preferenceStore.loadStates()
          .then((options) => {
            if(options){
              this.stateOptions = options;
            }
          });
      })
    preferenceStore.loadCountries()
      .then((options) => {
        this.countryOptions = options;
      });
    
  }

  onChangeInvoiceAgreement(val) {
    let {
      preferenceStore: {
        tenant: {
          object
        }
      }
    } = this.props;

    this.invoice_agreement_values = val;
    object.attributes.invoice_agreement = val.toString('html');
  }

  onChangeEstimateAgreement(val) {
    let {
      preferenceStore: {
        tenant: {
          object
        }
      }
    } = this.props;

    this.estimate_agreement_values = val;
    object.attributes.estimate_agreement = val.toString('html');
  }

  handleTimezoneChange = (timezone) => {
    this.props.preferenceStore.tenant.object.attributes.timezone = timezone;
  }

  updateTenant() {
    let {
      preferenceStore: {
        tenant: {
          object
        }
      }
    } = this.props;
    if(object.attributes.name === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.phone === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid phone number!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.street_address === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter address!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.city === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter city!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.team_size === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter team size!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.company_type === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter company type!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.province === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please select state/province!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.country === "") {
      this.props.preferenceStore.tenant.object.attributes.country = 'United States';
    }

    if(object.attributes.timezone === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please select valid timezone!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.enable_invoices_on_mobile === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please select invoice on mobile!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.currency === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please select country for default currency!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.country_id === "") {
      this.props.preferenceStore.tenant.object.attributes.country_id = '233';
    }

    if(object.attributes.heading_text_for_service_type === "" || object.attributes.heading_text_for_service_type === null) {
      this.props.preferenceStore.tenant.object.attributes.heading_text_for_service_type = 'Choose Service Type';
    }

    if(object.attributes.another_heading_text_for_service === "" || object.attributes.another_heading_text_for_service === null) {
      this.props.preferenceStore.tenant.object.attributes.another_heading_text_for_service = 'Choose Your Service Type';
    }
   
    this.props.preferenceStore.updateTenant(object)
      .then(this.onSuccessfulUpdateTenant.bind(this))
      .catch(this.onFailUpdateTenant.bind(this))
  }

  onSuccessfulUpdateTenant(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Company details updated successfully.', intent: Intent.SUCCESS })
  }

  onFailUpdateTenant(error) {
    Toast.show({ icon: 'warning-sign', message: 'Problem in updating Tenant!', intent: Intent.DANGER })
  }

  filterCountry = (query) => {
    this.countryOptions = _.filter(this.props.preferenceStore.countries.objects, function(option) { 
      return option.attributes.name.toLowerCase().includes(query.toLowerCase());
    });
  }

  filterState = (query) => {
    this.stateOptions = _.filter(this.props.preferenceStore.states.objects, function(option) { 
      return option.attributes.name.toLowerCase().includes(query.toLowerCase());
    });
  }

  render() {
    let {
      preferenceStore: {
        tenant,
        tenant: {
          object
        },
        countries,
        states
      },
      quickbooksStore: {
        quickbooksItems: {
          objects
        }
      }
    } = this.props;

    
    const title = `Company Details | FieldNexus`
    return (

      <div className="row no-gutters company-page-layput">
        <div className="col-lg-2 sidebar-responsive">
            <SettingsSidebar />
            <Helmet>
              <title>{title}</title>
            </Helmet>
        </div>
        
        <div className="col-lg-10">
          <PageWrapGray>
            { 
              tenant.isLoading && !object &&
                <Spinner />
            }
            <div className="row m-0">
              
              { object && 
                <div className="col-md-4 pl-0">
                    <div className="card text-center position-sticky" style={{ top: '57px' }}>                                    
                      <div className="card-body">
                          <div className="company-logo d-flex align-items-center justify-content-center">
                            <img src={object && object.attributes.logo} className="tenant_logo"  alt='company logo'/>
                          </div>
                          <span className="btn btn-light btn-sm btn-file">
                              Change Logo <input type="file" onChange={this.handleChangeImage.bind(this)}/>
                          </span>
                          <hr />
                          <h6>{object && object.attributes.name}</h6>
                          <p className="text-black-50"><span>+{object && object.attributes.phone}</span></p>
                      </div>
                    </div>
                </div>
              }
              { object && 
                <div className="col-md-8 pr-0 pl-0">
                  <div className="card">
                    <div className="card-body">
                      <h5>Company Details</h5>
                      <hr />
                      <FormGroup
                        label="Name"
                        labelFor="text-input"
                        labelInfo="*"
                      >
                        <input 
                          type='text' 
                          className='form-control' 
                          placeholder='Company Name'
                          defaultValue={object.attributes.name}
                          onChange={(event) => {object.attributes.name = event.target.value;}}            
                        />
                      </FormGroup>

                      <FormGroup
                        label="Phone"
                        labelFor="text-input"
                        labelInfo="*"
                      >
                        <input 
                          type='text' 
                          className='form-control' 
                          placeholder='Phone Number'
                          defaultValue={object.attributes.phone}
                          onChange={(event) => {object.attributes.phone = event.target.value;}}            
                        />
                      </FormGroup>

                      <FormGroup
                        label="Website"
                        labelFor="text-input"
                        labelInfo="*"
                      >
                        <input 
                          type='text' 
                          className='form-control' 
                          placeholder='Website'
                          defaultValue={object.attributes.website}
                          onChange={(event) => {object.attributes.website = event.target.value;}}            
                        />
                      </FormGroup>

                      <FormGroup
                        label="Address Line 1"
                        labelFor="text-input"
                        labelInfo="*"
                      >
                        <input 
                          type='text' 
                          className='form-control' 
                          placeholder='Address Line 1'
                          defaultValue={object.attributes.street_address}
                          onChange={(event) => {object.attributes.street_address = event.target.value;}}            
                        />
                      </FormGroup>

                      <FormGroup
                        label="Address Line 2"
                        labelFor="text-input"
                        labelInfo=""
                      >
                        <input 
                          type='text' 
                          className='form-control' 
                          placeholder='Address Line 2'
                          defaultValue={object.attributes.street_address_other}
                          onChange={(event) => {object.attributes.street_address_other = event.target.value;}}            
                        />
                      </FormGroup>
                      
                      <div className="form-row select-overflow">
                        <div className="form-group col-md-6">
                          <FormGroup
                            label="City"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='City'
                              defaultValue={object.attributes.city}
                              onChange={(event) => {object.attributes.city = event.target.value;}}            
                            />
                          </FormGroup>                        
                        </div>
                        <div className="form-group col-md-6 blp-select-box"> 
                          <FormGroup
                            label="State/Province"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <Select
                              filterable={true}
                              items={this.stateOptions}
                              onQueryChange={this.filterState}
                              itemRenderer={(item) => { 
                                  return (
                                    <MenuItem
                                      className="overflow-height"
                                      text={item.attributes.name} 
                                      key={item.attributes.name}
                                      onClick={() => { this.handleChangeState(item) } }
                                    />
                                  ) 
                                }
                              }
                            >
                              
                              <Button 
                                icon="globe-network" 
                                rightIcon="caret-down"
                                text={
                                  object.attributes.province ? object.attributes.province : "Select State/Province"
                                }
                                fill={true}
                                loading={states.isLoading} 
                              />
                            </Select>
                          </FormGroup> 
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6 blp-select-box">
                          <FormGroup
                            label="Country"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <Select
                              filterable={true}
                              items={this.countryOptions}
                              onQueryChange={this.filterCountry}
                              itemRenderer={(item) => { 
                                  return (
                                    <MenuItem 
                                      text={item.attributes.name} 
                                      key={item.attributes.name}
                                      onClick={() => { this.handleChangeCountry(item) } }
                                    />
                                  ) 
                                }
                              }
                              onItemSelect={this.handleChangeCountry}
                            >
                              
                              <Button 
                                icon="globe" 
                                rightIcon="caret-down"
                                text={
                                  object.attributes.country ? object.attributes.country : "United States"
                                }
                                fill={true}
                                loading={countries.isLoading} 
                              />
                            </Select>
                          </FormGroup>

                        </div>
                        <div className="form-group col-md-6">
                        <FormGroup
                            label="Default Currency"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <input 
                              type='text' 
                              id= 'currency'
                              className='form-control' 
                              placeholder='Default Currency'
                              value={this.defaultCurrency}
                              onChange={() => {}}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <FormGroup
                            label="Team Size"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <TeamSize />
                          </FormGroup>
                        </div>
                        <div className="form-group col-md-6">
                          <FormGroup
                            label="Company Type"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <CompanyType />                            
                          </FormGroup>
                        </div>
                      </div>

                    <div className="form-row">
                      <div className="form-group blp-select-box col-md-6">
                        <FormGroup
                          label="Choose Your Timezone"
                          labelFor="text-input"
                          labelInfo="*"
                        >
                          <TimezonePicker 
                            value={object.attributes.timezone} 
                            onChange={this.handleTimezoneChange} 
                            valueDisplayFormat={'composite'}
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="Enable Invoices on Mobile"
                          labelFor="text-input"
                          labelInfo="*"
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.enable_invoices_on_mobile} 
                              onChange={(event) => { object.attributes.enable_invoices_on_mobile = event.target.value }}
                              style={{minHeight: 38}}>
                              <option value='' disabled='true'>Select option..</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-12">
                        <div className="editor-nd" >
                          <FormGroup
                            label="Estimate Agreement"
                            labelFor="text-input"
                          >
                            <RichTextEditor
                              value={this.estimate_agreement_values}
                              onChange={this.onChangeEstimateAgreement.bind(this)}
                              className='text-editor'
                              toolbarConfig={toolbarConfig}
                              placeholder="Estimate Agreement"
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <div className="editor-nd" >
                          <FormGroup
                            label="Invoice Agreement"
                            labelFor="text-input"
                          >
                            <RichTextEditor
                              value={this.invoice_agreement_values}
                              onChange={this.onChangeInvoiceAgreement.bind(this)}
                              className='text-editor'
                              toolbarConfig={toolbarConfig}
                              placeholder="Invoice Agreement"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="Enable QuickBooks Sync on Mobile"
                          labelFor="text-input"
                          labelInfo="*"
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.enable_quickbooks_sync} 
                              onChange={(event) => { object.attributes.enable_quickbooks_sync = event.target.value }}
                              style={{minHeight: 38}}>
                              <option value='' disabled='true'>Select option..</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup
                          label={`Enable "Send invoice link" on Mobile`}
                          labelFor="text-input"
                          labelInfo="*"
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.enable_invoice_sent_link} 
                              onChange={(event) => { object.attributes.enable_invoice_sent_link = event.target.value }}
                              style={{minHeight: 38}}>
                              <option value='' disabled='true'>Select option..</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup
                          label={`Enable "Send Work Order link" on Mobile`}
                          labelFor="text-input"
                          labelInfo="*"
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.enable_work_report_sent_link} 
                              onChange={(event) => { object.attributes.enable_work_report_sent_link = event.target.value }}
                              style={{minHeight: 38}}>
                              <option value='' disabled='true'>Select option..</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        <FormGroup
                          label={`Enable "Send Estimate link" on Mobile`}
                          labelFor="text-input"
                          labelInfo="*"
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.enable_estimate_sent_link} 
                              onChange={(event) => { object.attributes.enable_estimate_sent_link = event.target.value }}
                              style={{minHeight: 38}}>
                              <option value='' disabled='true'>Select option..</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <FormGroup
                          label="Enable display unit prices on Mobile"
                          labelFor="text-input"
                          labelInfo="*"
                        >
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.enable_display_unit_prices} 
                              onChange={(event) => { object.attributes.enable_display_unit_prices = event.target.value }}
                              style={{minHeight: 38}}>
                              <option value='' disabled='true'>Select option..</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                            </select>
                          </div>                          
                        </FormGroup>
                      </div>
                      <div className="form-group col-md-6">
                        {objects && objects.length > 0 ?
                          <FormGroup
                            label="QuickBooks Default Product and Service"
                            labelInfo=""
                          >
                            <div className="bp3-select bp3-fill">
                              <select
                                id={"quickbooks-product-service"}
                                value={object.attributes.default_quickbooks_product_sercives_id}
                                onChange={event => {
                                  object.attributes.default_quickbooks_product_sercives_id = event.target.value;
                                }}
                              >
                                <option value="">Select Product/Service</option>
                                {objects && objects.length > 0 && objects.map((item) => {
                                  return(
                                    <option value={item.id} key={Math.random()}>{item.name}</option>
                                  )
                                })}
                              </select>
                            </div>
                          </FormGroup>
                          :''
                          }
                      </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                          <FormGroup
                            label="Don't show PMA hours on work orders"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <div className="bp3-select bp3-fill">
                              <select 
                                defaultValue={object.attributes.dont_show_pma_hours_on_work_reports} 
                                onChange={(event) => { object.attributes.dont_show_pma_hours_on_work_reports = event.target.value }}
                                style={{minHeight: 38}}>
                                <option value='' disabled='true'>Select option..</option>
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                              </select>
                            </div>                          
                          </FormGroup>
                        </div>
                        <div className="form-group col-md-6">
                          <FormGroup
                            label="Tax Number Title"
                            labelFor="text-input"
                          >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='Ex. HST#'
                              defaultValue={object.attributes.tax_number_title}
                              onChange={(event) => {object.attributes.tax_number_title = event.target.value;}}            
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <FormGroup
                            label="Tax Number"
                            labelFor="text-input"
                          >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='Ex. 123-212-212-RT01'
                              defaultValue={object.attributes.tax_number}
                              onChange={(event) => {object.attributes.tax_number = event.target.value;}}            
                            />
                          </FormGroup>
                        </div>
                        <div className="form-group col-md-6">
                          <FormGroup
                            label="Show Invoice Date on PDF/Public Link"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <div className="bp3-select bp3-fill">
                              <select 
                                defaultValue={object.attributes.show_invoice_date_on_pdf} 
                                onChange={(event) => { object.attributes.show_invoice_date_on_pdf = event.target.value }}
                                style={{minHeight: 38}}>
                                <option value='' disabled='true'>Select option..</option>
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                              </select>
                            </div>                          
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <FormGroup
                            label="Show Invoice Due Date on PDF/Public Link"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <div className="bp3-select bp3-fill">
                              <select 
                                defaultValue={object.attributes.show_invoice_due_date_on_pdf} 
                                onChange={(event) => { object.attributes.show_invoice_due_date_on_pdf = event.target.value }}
                                style={{minHeight: 38}}>
                                <option value='' disabled='true'>Select option..</option>
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                              </select>
                            </div>                          
                          </FormGroup>
                        </div>
                        <div className="form-group col-md-6">
                          <FormGroup
                            label="Show Invoice Agreement on PDF/Public Link"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <div className="bp3-select bp3-fill">
                              <select 
                                defaultValue={object.attributes.show_invoice_agreement_on_pdf} 
                                onChange={(event) => { object.attributes.show_invoice_agreement_on_pdf = event.target.value }}
                                style={{minHeight: 38}}>
                                <option value='' disabled='true'>Select option..</option>
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                              </select>
                            </div>                          
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <FormGroup
                            label="Reply-to Email"
                            labelFor="text-input"
                          >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='Ex. john@gmail.com'
                              defaultValue={object.attributes.reply_to}
                              onChange={(event) => {object.attributes.reply_to = event.target.value;}}            
                            />
                          </FormGroup>
                        </div>
                        <div className="form-group col-md-6">
                        <FormGroup
                          label="Default view for Dispatch Board"
                          labelFor="text-input">
                          <div className="bp3-select bp3-fill">
                            <select 
                              defaultValue={object.attributes.dispatch_board_default_view} 
                              onChange={(event) => { object.attributes.dispatch_board_default_view = event.target.value }}
                              style={{minHeight: 38}}>
                              <option value='' disabled='true'>Select option..</option>
                                <option value='timelineDay'>Timeline</option>
                                <option value='basicWeek'>Week</option>
                              </select>
                            </div>                          
                          </FormGroup>
                        </div>
                      </div>
                    
                    <div className="form-group">
                      <Button loading={tenant.isLoading} onClick={this.updateTenant.bind(this)} icon="floppy-disk" text="Save Details" />
                    </div>

                    </div>
                  </div>
              </div>
              }
            </div>
          </PageWrapGray>
        </div>
      
      </div>
    );
  }

};
export default CompanyDetails;