import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import CustomItem from './CustomItem';
import _ from 'lodash';
import { currencyFormattedAmount, currencySymbol } from 'helpers/currency'; 

@inject('store', 'invoiceStore') @observer
class InvoiceCustomLineItems extends Component {

  calculateSubTotal() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;

    let objects = [];
    if(object && object.attributes && object.attributes.custom_line_items) {
      objects = object.attributes.custom_line_items;
    }

    if(objects.length > 0) {
      let total = 0;
      total = _.sumBy(objects, function(custom) {
                let taxTotal = _.sumBy(custom.tax_line_items, function(taxLineItem) {
                              return (taxLineItem.percentage_value/100) * custom.total
                          });
                return custom.total + taxTotal;
              })
      return currencyFormattedAmount(total)
    } else {
      return currencyFormattedAmount(0)
    }
  }

  refreshLineItems() {
    this.forceUpdate()
  }

  render() {
    const {
      invoiceStore,
      invoiceStore: {
        showCustomLineItemsLoader,
        invoice: {
          object,
          object: {
            attributes: {
              custom_line_items
            }
          }
        }
      }
    } = this.props;

    let objects = [];
    if(custom_line_items && custom_line_items.length > 0) {
      objects = custom_line_items;
    }

    return (
      <React.Fragment>
        <div className="table-title" style={{ paddingLeft: '10px' }}>
          <h5>
            <strong>Others <Button icon='add' className="bp3-minimal add-btn" onClick={() => {invoiceStore.addBlankLineItem('custom', object.attributes.id)} } loading={showCustomLineItemsLoader}>Add Other</Button></strong>
          </h5>
        </div>

        <div style={{ marginBottom: '1rem' }}>

          {
            objects && objects.length > 0 && <div className="wr-row">
              <div style={{ flex: 1 }}>
                <table className="table table-bordered border-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: 495}}>Description</th>
                      <th scope="col" style={{ width: 100}}>Quantity</th>
                      <th scope="col" style={{ width: 100 }}>Rate({currencySymbol()})</th>
                      <th scope="col" style={{ width: 100}}>Total</th>
                      <th scope="col" style={{ width: 52 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    
                        {
                          objects && objects.map((lineItem) => {
                            return (
                              <CustomItem lineItem={lineItem} key={lineItem.id} refresh={this.refreshLineItems.bind(this)} />
                            )
                          })
                        }
                      
                  </tbody>
                </table>
              </div>
            </div>
          }
          <br/>
          {objects && objects.length > 0 && <div className="lineitem_sub_total">
            <h6>Sub Total: {this.calculateSubTotal()}</h6>
          </div>}
        </div>
        
      </React.Fragment>
    )
  }
}

export default InvoiceCustomLineItems;