import React from 'react'
import { Button, Spinner, Menu, MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Api } from 'services/Api';
import axios from 'axios';
import debounce from 'services/debounce';

const ProjectRenderer = (project, { handleClick, modifiers }) => {
  return (
    <MenuItem
      active={modifiers.active}
      key={project.id}
      onClick={handleClick}
      text={project.attributes.name}
    />
  );
};

const ListRenderer = ({ items, itemsParentRef, query, renderItem, loading, failed }) => {
  const renderedItems = items.map(renderItem).filter(item => item != null);
  return (
    <Menu ulRef={itemsParentRef}>
      {
        failed && !loading && <MenuItem disabled={true} text={'Failed to load results :('} />
      }
      {
        loading && !failed && <MenuItem disabled={true} text={'Loading..'} label={<Spinner size={20}/>} />
      }
      {
        !loading && !failed && <MenuItem
                      disabled={true}
                      text={`Found ${renderedItems.length} projects matching "${query}"`}
                    />
      }
      {renderedItems}
    </Menu>
  );
}

@observer
class ProjectAutocomplete extends React.Component {

  @observable loading = true;
  @observable failed = false;
  @observable results = [];
  @observable selectedProject = null;
  @observable scope = null;

  componentDidMount() {
    let {
      selectedProjectId
    } = this.props;
    if (selectedProjectId) {
      let promise = Api.get(`/projects/${selectedProjectId}`)
      promise.then(response => {
        this.selectedProject = response.data.data;
      })
    }
  }

  @action
  onQueryChange(query) {
    let { scope } = this.props;
    this.failed = false;
    this.loading = true;
    let search = { term: query, per_page: 50, page: 1, order: { name: 'asc' } };
    if(scope){
      search['where'] = scope;
    }
    let promise = Api.post('/projects/search', { search: search })
    promise
      .then(this.onProjectsLoad.bind(this))
      .catch(this.onProjectsLoadFail.bind(this))

    return promise;
  }

  @action
  onProjectsLoadFail(error) {
    if (axios.isCancel(error)) {
      this.loading = true;
    } else {
      this.loading = false;
      this.failed = true;
    }
  }

  @action
  onProjectsLoad(response) {
    this.loading = false;
    this.failed = false;
    this.results = response.data.data;
  }

  @action
  onProjectSelect(project) {
    this.selectedProject = project;
    this.props.onProjectSelect(project);
  }

  @action
  clearSelectedProject() {
    this.selectedProject = null;
    this.props.onProjectSelect(null);
  }

  render() {
    let {
      failed,
      results,
      onQueryChange,
      loading,
      onProjectSelect,
      selectedProject,
      clearSelectedProject,
      props: {
        inputProps
      }
    } = this;
    return(
      <div className="client-name-field">
        {
          selectedProject ? <Button 
              text={this.selectedProject.attributes.name} 
              rightIcon='cross'
              onClick={clearSelectedProject.bind(this)}
            /> : <Suggest
              inputProps={{
                icon: 'cross',
                placeholder: 'Search project...',
                onClick: onQueryChange.bind(this, ""),
                ...inputProps
              }}
              closeOnSelect={true}
              resetOnQuery={true}
              resetOnSelect={true}
              popoverProps={{ minimal: true }}
              onQueryChange={debounce(onQueryChange.bind(this)).bind(this)}
              items={toJS(results)}
              itemRenderer={ProjectRenderer}
              itemListRenderer={(props) => <ListRenderer {...props} loading={loading} failed={failed} />}
              inputValueRenderer={(project) => project.attributes.name }
              onItemSelect={onProjectSelect.bind(this)}
            />
        }
      </div>
    )
  }
}

ProjectAutocomplete.propTypes = {
  inputProps: PropTypes.object,
  onProjectSelect: PropTypes.func.isRequired
}

export default ProjectAutocomplete;