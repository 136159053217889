import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import {
  FormGroup, InputGroup,
  Button,
  Dialog,
  Classes,
  Intent,
  TextArea
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import { currency, currencySymbol } from 'helpers/currency';
import LocationAutocomplete from "components/LocationAutocomplete";
import {StripeProvider, Elements, injectStripe, CardElement } from 'react-stripe-elements';

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Helvetica, sans-serif',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

@inject('invoiceStore', 'paymentStore') @observer
class _StripeForm extends Component {

  @observable transaction = {
    amount: this.props.due_amount,
    payment_method: "Card",
    notes: "",
    token: "",
    description: "",
    invoice_id: this.props.invoice_id,
    location_id: null
  }

  @observable location = null

  componentWillMount() {
    let {
      props: {
        invoiceStore: {
          invoice: {
            object: {
              attributes
            }
          }
        }
      }
    } = this;

    if(attributes && attributes.location && attributes.location.id) {
      this.location = {attributes: attributes.location}
      this.transaction.location_id = attributes.location.id
    }
  }

  handleChange = (change) => {
    // console.log('[change]', change);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if(this.props.stripe) {
      this.props.paymentStore.showLoaderCardPayment = true;
      this.props.stripe.createToken()
        .then(this.processStripePayment.bind(this));
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  }

  processStripePayment(payload) {
    this.transaction.token = payload.token.id
    this.props.paymentStore.stripeCharge(this.transaction)
      .then(this.onSuccessfulStripeCharge.bind(this))
      .catch(this.onFailStripeCharge.bind(this))
  }

  onSuccessfulStripeCharge(response) {
    Toast.show({ icon: 'thumbs-up', message: "Successfully charge!", intent: Intent.SUCCESS })
    this.props.onClose()
  }

  onFailStripeCharge(error) {
    if(error && error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Unable to charge!', intent: Intent.DANGER })
    }
    //this.props.onClose()
  }

  handleCardTransactionDailog() {
    this.props.onClose()
  }

  onLocationClear = () => {
    this.location = null
    this.transaction.location_id = ""
  }

  render() {
    let {
      transaction,
      props: {
        paymentStore: {
          showLoaderCardPayment
        },
        invoiceStore: {
          invoice: {
            object: {
              attributes
            }
          }
        }
      }
    } = this;
    return(
      <div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <FormGroup
            label="Card Details"
            labelFor="text-input"
          >
            <CardElement
              onChange={this.handleChange.bind(this)}
              hidePostalCode={true}
              {...createOptions(this.props.fontSize)}
            />
          </FormGroup>

          <FormGroup
            label={`Amount(${currency()}${currencySymbol()})`}
            labelFor="text-input"
          >
            <InputGroup 
              type="text-input" 
              placeholder={`${currencySymbol()}${transaction.amount_paid}`}
              defaultValue={transaction.amount}
              onChange={(event) => {transaction.amount = event.target.value}}
            />
          </FormGroup>

          {transaction && !transaction.location_id && <FormGroup
            label={`Location for '${attributes && attributes.client && attributes.client.name}'`}
            labelFor="text-input"
          >
            <LocationAutocomplete
              scope={{ client_id: attributes && attributes.client && attributes.client.id }}
              style={{ width: "400px" }}
              inputProps={{
                fill: true,
                placeholder: "Start typing location.."
              }}
              onLocationClear={this.onLocationClear.bind(this)}
              value={this.location}
              onLocationSelect={location => {
                if (location) {
                  transaction.location_id = location.id;
                  this.location = location;
                } else {
                  this.onLocationClear();
                }
              }}
            />
          </FormGroup>}

          <FormGroup
            label="Description"
            labelFor="text-input"
          >
            <TextArea 
              style={{width: '100%'}} 
              type="text-input" 
              placeholder="Description" 
              onChange={(event) => {transaction.description = event.target.value}}
            />
          </FormGroup>

          <FormGroup
            label="Notes"
            labelFor="text-input"
          >
            <TextArea 
              style={{width: '100%'}} 
              type="text-input" 
              placeholder="Notes" 
              onChange={(event) => {transaction.notes = event.target.value}}
            />
          </FormGroup>

          <div className={Classes.DIALOG_FOOTER} style={{margin: 0}}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                onClick={this.handleCardTransactionDailog.bind(this)}
                style={{ width: "100px" }}
              >
                Close
              </Button>
              <Button text={"Pay"} intent="primary" style={{alignItems: 'flex-end'}} onClick={this.handleSubmit.bind(this)} loading={showLoaderCardPayment}></Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const StripeForm = injectStripe(_StripeForm);

@inject('store', 'invoiceStore', 'paymentStore', 'viewStore') @observer
class CardTransactionModal extends Component {

  handleCardTransactionDailog() {
    this.props.onClose();
  }

  render() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    return(
      <Dialog
        title="Add Card Transaction"
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
        <StripeProvider apiKey={window.stripe_api_key}>
          <Elements>
            <StripeForm 
              fontSize={18} 
              due_amount={object.attributes.due_amount}
              invoice_id={object.attributes.id}
              onClose={this.handleCardTransactionDailog.bind(this)}
            />
          </Elements>
        </StripeProvider>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          </div>
        </div>
      </Dialog>
    )
  }

}

export default CardTransactionModal;