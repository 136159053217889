import React from 'react';
import {
  Spinner
} from '@blueprintjs/core';
import { observable } from 'mobx';
import { inject,observer } from 'mobx-react';
import { Api } from 'services/Api';
import TextTruncate from 'react-text-truncate';
import { formatTime } from '../../helpers/time';
import { 
  Button, Tag, Divider
} from '@blueprintjs/core';
import qs from 'qs';

@inject('store', 'workReportStore', 'viewStore', 'userStore', 'clientStore') @observer
class WorkReportsTable extends React.Component {

  @observable workReports = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    let { equipment } = this.props;
    let promise = Api.get(`/equipments/${equipment.attributes.id}/work_reports`);

    promise
      .then((response) => {
        this.workReports = {
          isLoading: false,
          objects: response.data.data
        }
      })
  }

  render() {
    let {
      viewStore,
      viewStore: {
        jobTab: {
          section
        }
      }
    } = this.props;
    let { objects, isLoading } = this.workReports;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No work report having this equipment yet.
        </div>
      )
    }
    return(
      <div className="card rounded-0 table-responsive">
        <table className="table table-hover" style={{ width: '100%' }}>
          <thead>
            <tr>
              {/* <th>Crew</th> */}
              <th>Date</th>
              <th>Job #</th>
              <th>Work Report #</th>
              <th>Customer</th>
              {/* <th>Requested By</th> */}
              <th>Created By</th>
              <th>Description</th>
              <th>PO#</th>
              <th>Status</th>
              <th>Grand Total</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((workReport) => {
                return (
                  <tr key={workReport.id}>
                    {/* <td>
                      <AvatarGroup
                        appearance="stack"
                        onAvatarClick={console.log}
                        data={_.sampleSize(toJS(store.avatars), 2)}
                        size="small"
                      />
                    </td> */}
                    <td>
                      {formatTime(workReport.attributes.created_at)}
                    </td>
                    <td>
                      <a href={`/dashboard/jobs/${workReport.attributes.job.id}/${section}`} >
                        {workReport.attributes.job.event_number}
                      </a>
                    </td>
                    <td>
                      <a href={`/dashboard/work-reports/${workReport.id}/show`} >
                        {workReport.attributes.number}
                      </a>
                    </td>
                    <td>
                      <a href={`/dashboard/clients/${workReport.attributes.client.id}?${qs.stringify(viewStore.clientShowSearch, { encode: false })}`}                        
                      >
                        {workReport.attributes.client.name}
                      </a>
                    </td>
                    {/* <td>{workReport.attributes.requested_by}</td> */}
                    <td>
                      {/* <AvatarGroup
                        appearance="stack"
                        onAvatarClick={console.log}
                        data={_.sampleSize(toJS(store.avatars), 1)}
                        size="small"
                      /> */}
                      <a 
                        href={`/dashboard/users/${workReport.attributes.created_by.id}/show?${qs.stringify(viewStore.userShowSearch, { encode: false })}`} 
                      >
                        {workReport.attributes.created_by.full_name}
                      </a>
                    </td>
                    <td width='200'>
                      <TextTruncate
                        line={2}
                        truncateText="…"
                        text={workReport.attributes.description}
                      />
                    </td>
                    <td>{workReport.attributes.po_number}</td>
                    <td>
                      <Tag>
                        {workReport.attributes.status}
                      </Tag>
                    </td>
                    <td>
                      <Tag>
                        { 
                          workReport.attributes.grand_total
                        }
                      </Tag>
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button minimal={true} small={true} onClick={() => viewStore.ShowWorkOrder({uuid: workReport.attributes.id})}>
                          <img src="/edit-light-icon.svg" alt={'edit icon'}/>
                        </Button>
                        <Divider/>
                        <Button minimal={true} small={true} onClick={() => this.handleWorkReportClick({ label: 'Delete' }, workReport)}>
                          <img src="/delete-light-icon.svg" alt={'delete icon'}/>
                        </Button>
                      </div>
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }

};

export default WorkReportsTable;