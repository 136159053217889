import React from 'react';
import { inject, observer } from 'mobx-react';
import { Select } from "@blueprintjs/select";
import {
  Spinner, Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import { observable } from 'mobx';
import { Api } from 'services/Api';
import VacationModal from './VacationModal';
import Toast from 'helpers/toaster';
import moment from 'moment';

@inject('userStore') @observer
class VacationsTable extends React.Component {

  @observable vacations = {
    isLoading: true,
    objects: []
  }

  @observable isOpenVacationModal = false;
  @observable isDeleteDialogVisible = false;
  @observable vacation;
  @observable edit = false;

  componentDidMount() {
    let { user } = this.props;
    let promise = Api.get(`/vacations/${user.attributes.id}`);

    promise
      .then((res) => {
        this.vacations = {
          isLoading: false,
          objects: res.data.data
        }
      })
  }

  handleCloseVacationModal() {
    this.isOpenVacationModal = false
    this.edit = false;
    this.vacation = null;
    this.props.userStore.vacation.object = {
      start_date: moment(),
      end_date: moment()
    }
  }

  handleVacationClick(event, vacation) {
    switch (event.label) {
      case 'Edit':
        this.vacation = vacation;
        this.props.userStore.vacation.object = this.vacation.attributes;
        this.edit = true
        this.isOpenVacationModal = true
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.vacation = vacation;
        break;
      default:
    }
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.userStore.deleteVacation(this.vacation)
      .then(this.onSuccessfulDeleteVacation.bind(this))
      .catch(this.onFailedVacationDelete.bind(this))
  }

  onSuccessfulDeleteVacation(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Vacation deleted successfully!', intent: Intent.SUCCESS })
    this.vacation = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedVacationDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete vacation!', intent: Intent.DANGER })
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  render() {
    let { user } = this.props; 
    let { objects, isLoading } = this.vacations;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout warning-alert-message bp3-intent-warning bp3-icon-info-sign table-card" style={{marginTop:'15px'}}>
          No vacations have been created for this user yet.
        </div>
      )
    }
    return (
      <React.Fragment>
        <div className="bp3-right" style={{marginTop: -30}}>
          <Button icon='add' className="bp3-minimal" onClick={() => {this.isOpenVacationModal = true} }>Add</Button>
        </div>
        <div className="card rounded-0 table-responsive">
          <table className='table table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Approved By</th>
                <th>Notes</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                objects.map((vacation) => {
                  return (
                    <tr key={vacation.id}>
                      <td>
                        <p className='primary'>{vacation.attributes.formatted_start_date}</p>
                      </td>
                      <td>
                        <p className='secondary'>{vacation.attributes.formatted_end_date}</p>
                      </td>
                      <td width="150px">
                        {vacation.attributes.status === "approved" && <span className='bp3-tag bp3-minimal bp3-intent-success'>{vacation.attributes.status}</span>}
                        {vacation.attributes.status === "rejected" && <span className='bp3-tag bp3-minimal bp3-intent-danger'>{vacation.attributes.status}</span>}
                      </td>
                      <td>
                        <p className="primary">{vacation.attributes.created_by_fullname}</p>
                      </td>
                      <td>
                        {vacation.attributes.notes &&<span className='bp3-tag bp3-minimal bp3-intent-primary'>
                          {vacation.attributes.notes}
                        </span>}
                      </td>
                      <td>
                        {/* <Select
                          filterable={false}
                          items={[ { label: 'Edit', icon: 'edit', intent: 'default' }, { label: 'Delete', icon: 'trash', intent: 'danger' }]}
                          itemRenderer={(item) => { return (<MenuItem text={item.label} key={item.label} onClick={() => this.handleVacationClick(item, vacation)} icon={item.icon} intent={item.intent} />) }}
                          onItemSelect={(item) => {  }}
                        >
                          <Button text={'Actions'} rightIcon='caret-down' className='bp3-minimal' small={true} />
                        </Select> */}
                        <div className="action-buttons" minimal={true}>
                          <Button minimal={true} small={true} onClick={() => this.handleVacationClick({ label: 'Edit' }, vacation)}>
                            <img src="/edit-light-icon.svg"/>
                          </Button>
                          <Divider/>
                          <Button minimal={true} small={true} onClick={() => this.handleVacationClick({ label: 'Delete' }, vacation)}>
                            <img src="/delete-light-icon.svg" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <VacationModal
          isOpen={this.isOpenVacationModal}
          onClose={this.handleCloseVacationModal.bind(this)}
          user={user}
          vacation={this.edit ? this.vacation.attributes : null}
        />

        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to do this?
          </p>
        </Alert>

      </React.Fragment>
    )
  }

}

export default VacationsTable;