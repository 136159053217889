import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PaymentGrid, Card } from 'components/Styles';
import QuoteBodyHeader from './QuoteBodyHeader';
import QuoteLineItems from './QuoteLineItems';

@inject('quoteStore') @observer
class QuoteBody extends Component {

  render() {
    return(
      <PaymentGrid>
        <div className="payment-wraper">        
          <Card>
            <QuoteBodyHeader />
            <QuoteLineItems />
          </Card>        
        </div>
      </PaymentGrid>
    )
  }

}

export default QuoteBody;