import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button } from '@blueprintjs/core';
import LabourItem from './LabourItem';
import _ from 'lodash';
import { currencyFormattedAmount, currencySymbol } from 'helpers/currency';
import AddProductForInvoiceModal from 'components/Invoice/AddProductForInvoiceModal';

@inject('store', 'invoiceStore', 'productServiceStore', 'viewStore') @observer
class InvoiceLabourLineItems extends Component {

  componentWillReceiveProps(nextProps) {
    if(nextProps.labour) {
      this.calculateSubTotal()
    }
  }

  calculateSubTotal() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;

    let objects = [];
    if(object && object.attributes && object.attributes.labour) {
      objects = object.attributes.labour;
    }

    if(objects.length > 0) {
      let total = 0;
      total = _.sumBy(objects, function(labour) {
                let taxTotal = _.sumBy(labour.tax_line_items, function(taxLineItem) {
                              return (taxLineItem.percentage_value/100) * labour.total
                          });
                return labour.total + taxTotal;
              })
      return currencyFormattedAmount(total)
    } else {
      return currencyFormattedAmount(0)
    }
  }

  refreshLineItems() {
    this.forceUpdate()
  }

  handleCloseMaterialForWorkReportDailog() {
    this.props.store.isOpenAddLaborForInvoiceDialog = false;
  }

  onSuccessAddMaterialForWorkReport(equipment) {
    const {
      productServiceStore: {
        productsServices: {
          objects
        }
      }
    } = this.props;
    this.props.viewStore.productServiceSearch.where.product_service_type = 'Service';
    this.props.productServiceStore.loadProductAndServicesForWorkReports(); 
    this.props.store.isOpenAddLaborForInvoiceDialog = true; 
    this.props.store.productServiceType = 'Service';   
    this.props.store.productServiceForm = 'Invoice'; 
  }

  render() {
    const {
      invoiceStore,
      invoiceStore: {
        showLabourLineItemsLoader,
        invoice: {
          object,
          object: {
            attributes: {
              labour
            }
          }
        }
      },
      store
    } = this.props;
    let objects = [];
    if(labour && labour.length > 0) {
      objects = labour;
    }
    return (
      <React.Fragment>
        <div className="table-title" style={{ paddingLeft: '10px' }}>
          <h5>
            <strong>Labor <Button icon='add' className="bp3-minimal add-btn" onClick={() => {this.onSuccessAddMaterialForWorkReport()} } >Add Labor</Button></strong>
          </h5>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div style={{ padding: '0px 10px' }} >
            {
              objects && objects.length === 0 && <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
                No labour line items have been added to this Invoice.
              </div>
            }
          </div>
          {/* {
            objects && objects.length > 0 && <table className='responsive-table-layout report-data-table bp3-html-table bp3-html-table-striped bp3-interactive table-product-service' style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th colSpan="2">Services</th>
                  <th>Date</th>
                  <th>Hours</th>
                  <th>Hourly Rate({currencySymbol()})</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  objects && objects.map((labour) => {
                    return (
                      <LabourItem labour={labour} key={labour.id} refresh={this.refreshLineItems.bind(this)} />
                    )
                  })
                }
              </tbody>
            </table>
          } */}
          {
            objects && objects.length > 0 && <div className="wr-row">
              <div style={{ flex: 1 }}>
                <table className="table table-bordered border-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: 240}}>Services</th>
                      <th scope="col" style={{ width: 238 }}>Date</th>
                      <th scope="col" style={{ width: 165 }}>Hours</th>
                      <th scope="col" style={{ width: 165 }}>Hourly Rate({currencySymbol()})</th>
                      <th scope="col">Total</th>
                      <th scope="col" style={{ width: 62 }}></th>
                    </tr>
                  </thead>
                  <tbody>                    
                      {
                          objects && objects.map((labour) => {
                            return (
                              <LabourItem labour={labour} key={labour.id} refresh={this.refreshLineItems.bind(this)} />
                            )
                          })
                        }
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
        {object.attributes.labour.length > 0 && <div className="lineitem_sub_total">
          <p><span style={{ marginRight: 25 }}></span>Sub Total: {this.calculateSubTotal(object.attributes.labour)}</p>
        </div>}
        <AddProductForInvoiceModal
          isOpen={store.isOpenAddLaborForInvoiceDialog}
          onClose={this.handleCloseMaterialForWorkReportDailog.bind(this)}
          workDetail = {object}
          type={this.props.store.productServiceType}
          formType={this.props.store.productServiceForm}
          onSuccess={this.onSuccessAddMaterialForWorkReport.bind(this)}
          itemType="Labor"
          button_text="Create New Labor"
          title="Select Labor"
        />
      </React.Fragment>
    )
  }
}
export default InvoiceLabourLineItems;