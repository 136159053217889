import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  ControlGroup,
  Button,
  Dialog,
  Classes,
  Intent
} from "@blueprintjs/core";
import LocationAutocomplete from "components/LocationAutocomplete";
import Toast from "helpers/toaster";

@inject("viewStore", "invoiceStore")
@observer
class AddLocationModal extends Component {

  handleCloseLocationDailog() {
    let {
      props: {
        invoiceStore: {
          invoice: { object }
        }
      }
    } = this;
    object.location = null;
    this.props.onClose();
  }

  addLocation() {
    let {
      props: {
        invoiceStore: {
          invoice: { object }
        }
      }
    } = this;
    if(object.attributes.location_id) {
      this.props.invoiceStore.update()
        .then(this.onUpdateSuccessfully.bind(this))
        .catch(this.onFailUpdate.bind(this))
    } else {
      Toast.show({ message: "Please select location!", intent: Intent.DANGER })
    }
  }

  onUpdateSuccessfully(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Updated successfully!', intent: Intent.SUCCESS })
    this.props.onSuccess()
  }

  onFailUpdate(error) {
    Toast.show({ icon: 'warning-sign', message: 'Unable to update!', intent: Intent.DANGER })
  }

  onLocationClear = () => {
    let {
      props: {
        invoiceStore: {
          invoice: { object }
        }
      }
    } = this;
    object.attributes.location_id = null;
    object.location = null;
  };

  render() {
    let {
      invoiceStore: {
        invoice: {
          object,
          isLoading
        }
      }
    } = this.props
    return(
      <Dialog
        title="Add Location"
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <h6><strong>Client: {object.attributes.client && object.attributes.client.name}</strong></h6>

          <FormGroup
              label="Location"
              labelFor="text-input"
              labelInfo="(search for location)"
            >
              <ControlGroup fill={true} vertical={false}>
                <LocationAutocomplete
                  scope={{ client_id: object.client && object.client.id }}
                  style={{ width: "400px" }}
                  inputProps={{
                    fill: true,
                    placeholder: "Start typing location.."
                  }}
                  onLocationClear={this.onLocationClear.bind(this)}
                  value={object.location}
                  onLocationSelect={location => {
                    if (object && location) {
                      object.attributes.location_id = location.id;
                      object.location = location;
                    } else {
                      this.onLocationClear();
                    }
                  }}
                />
              </ControlGroup>
            </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.handleCloseLocationDailog.bind(this)}
              style={{ width: "100px" }}
            >
              Close
            </Button>
            <Button
              intent={Intent.PRIMARY}
              loading={isLoading}
              onClick={this.addLocation.bind(this)}
              icon="add"
              style={{ width: "200px" }}
            >
              Add Location
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }

}

export default AddLocationModal;