import React from 'react';
import Avatar from '@atlaskit/avatar';
import { Spinner } from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Api } from 'services/Api';
import { formatTime } from '../../helpers/time';
import { StatusPill, OvertimePill } from 'components/TimelogPills';

@inject('jobStore') @observer
class TimeLogs extends React.Component {

  componentDidMount() {
    let { 
      job,
      jobStore
    } = this.props;
    jobStore.loadJobTimesheets(job);
  }

  render() {
    let { 
      jobStore: {
        timeLogs: {
          objects, 
          isLoading
        }
      }
    } = this.props;
    if (isLoading) {
      return <div className="spinner-center"><Spinner size='25' /></div>
    }
    if (objects.length === 0) {
      return (
        <div className="no-data-found">
          <img src="/noTimeLog.svg" />
          <h3>No TimeLog</h3>
          <p>No time logs have been entered for this job yet.</p>
          {/* <a href="" className="add-new-item-btn">Add TimeLog</a> */}
        </div>
      )
    }
    return (
      <div>
          {
            objects.map((timelog) => {
              return (
                <div className="tabs-data-card" key={timelog.id}>
                  <div className="card-style">
                    <div width="10px" className="customer-avatar">
                      <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=${timelog.attributes.technician_name}&size=64`)} size='large' />
                    </div>
                    <div className="job-number" width="150px">
                      <p className="primary">{timelog.attributes.technician_name}</p>
                      <p className='time-entry primary'>{timelog.attributes.formatted_duration}</p>
                    </div>
                  </div>
                  <div className="job-details-status">
                    <div className="status-alignment">
                      <StatusPill timelog={timelog} /> &nbsp;
                      <OvertimePill timelog={timelog} />
                    </div>
                    <div className="job-date-time">
                      <p className='secondary'>{ formatTime(timelog.attributes.start_time) } - { formatTime(timelog.attributes.end_time) }</p>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
    )
  }
}

export default TimeLogs;