import React from 'react'
import { Button, Spinner, Menu, MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Api } from 'services/Api';
import axios from 'axios';
import debounce from 'services/debounce';

const ProductServiceRenderer = (product, { handleClick, modifiers }) => {
  return (
    <MenuItem
      active={modifiers.active}
      key={product.id}
      label={product.attributes.name}
      onClick={handleClick}
      text={product.attributes.name}
    />
  );
};

const ListRenderer = ({ items, itemsParentRef, query, renderItem, loading, failed }) => {
  const renderedItems = items.map(renderItem).filter(item => item != null);
  return (
    <Menu ulRef={itemsParentRef}>
      {
        failed && !loading && <MenuItem disabled={true} text={'Failed to load results :('} />
      }
      {
        loading && !failed && <MenuItem disabled={true} text={'Loading..'} label={<Spinner size={20}/>} />
      }
      {
        !loading && !failed && <MenuItem
                      disabled={true}
                      text={`Found ${renderedItems.length} clients matching "${query}"`}
                    />
      }
      {renderedItems}
    </Menu>
  );
}

@observer
class ProductServiceAutocomplete extends React.Component {

  @observable loading = true;
  @observable failed = false;
  @observable results = [];
  @observable selectedProduct = null;

  componentDidUpdate(){
    if(this.props.value){
      this.selectedProduct = this.props.value;
    }
  }

  

  componentDidMount() {
    let {
      selectedProductId
    } = this.props;

    
    if(selectedProductId) {     
      let promise = Api.post('/product_services/search', { search: { term: '*', where: {id: selectedProductId},per_page: 50, page: 1, order: { name: 'asc' } } })
      promise.then(response => {       
        this.selectedProduct = response.data.data[0];
      })
    }
    if(this.props.value){
      this.selectedProduct = this.props.value;
    }
  }

  @action
  onQueryChange(query) {
    this.failed = false;
    this.loading = true;
    if (query === '') {
      return;
    }
    let promise = Api.post('/product_services/search', { search: { term: query, per_page: 50, page: 1, order: { name: 'asc' } } })
    promise
      .then(this.onProductLoad.bind(this))
      .catch(this.onProductLoadFail.bind(this))
    return promise;
  }

  @action
  onProductLoadFail(error) {
    if (axios.isCancel(error)) {
      this.loading = true;
    } else {
      this.loading = false;
      this.failed = true;
    }
  }

  @action
  onProductLoad(response) {
    this.loading = false;
    this.failed = false;
    this.results = response.data.data;
  }

  @action
  onProductSelect(product) {
    this.selectedProduct = product;
    this.props.onProductSelect(product);
  }

  @action
  clearSelectedProduct() {
    this.selectedProduct = null;
    this.props.onProductSelect(null);
  }

  render() {
    let {
      failed,
      results,
      onQueryChange,
      loading,
      onProductSelect,
      selectedProduct,
      clearSelectedProduct,
      props: {
        inputProps
      }
    } = this;
    
    return(
      <div>
        {
          selectedProduct ? <Button 
              text={(this.selectedProduct.attributes && this.selectedProduct.attributes.name) || (this.selectedProduct && this.selectedProduct.name)} 
              rightIcon='cross'
              className="bp3-text-overflow"
              style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}
              onClick={clearSelectedProduct.bind(this)}
            /> : <Suggest
              inputProps={{
                icon: 'cross',
                placeholder: 'Search Materials & Labor...',
                ...inputProps
              }}
              closeOnSelect={true}
              openOnKeyDown={true}
              resetOnQuery={true}
              resetOnSelect={true}
              popoverProps={{ minimal: true }}
              onQueryChange={debounce(onQueryChange.bind(this)).bind(this)}
              items={toJS(results)}
              itemRenderer={ProductServiceRenderer}
              itemListRenderer={(props) => <ListRenderer {...props} loading={loading} failed={failed} />}
              inputValueRenderer={(product) => product.attributes.name }
              onItemSelect={onProductSelect.bind(this)}
            />
        }
      </div>
    )
  }
}

ProductServiceAutocomplete.propTypes = {
  inputProps: PropTypes.object,
  onProductSelect: PropTypes.func.isRequired
}

export default ProductServiceAutocomplete;