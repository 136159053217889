import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import './styles/animate.css';
import { App as ServiceHQApp }  from './App';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'

if (process.env.NODE_ENV === 'production') {
  window.stripe = window.Stripe('pk_live_DbeGl8MP9fIfzCweDBlz6q1l');
  window.stripe_api_key = 'pk_live_DbeGl8MP9fIfzCweDBlz6q1l';
} else {
  window.stripe = window.Stripe('pk_test_54JHZmQvfWX2dXnMx679P7JL');
  window.stripe_api_key = 'pk_test_54JHZmQvfWX2dXnMx679P7JL'
}

ReactDOM.render(<ServiceHQApp />, document.getElementById('root'));
unregisterServiceWorker();
