import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { 
  Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import Toast from 'helpers/toaster';
import TaxModal from 'components/Taxes/TaxModal';


@inject('store', 'taxStore', 'quickbooksStore','xeroStore') @observer
class TaxesTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable isTaxDialogVisible = false;
  @observable tax;

  handleTaxClick(event, tax) {
    switch (event.label) {
      case 'Edit':
        this.tax = tax;
        this.props.taxStore.tax.object = tax.attributes;
        this.isTaxDialogVisible = true;
        this.props.quickbooksStore.loadQuickbooksTaxCodes()
        this.props.xeroStore.loadXeroTaxCodes()
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.tax = tax;
        break;
      default:
    }
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.taxStore.delete(this.tax)
      .then(this.onSuccessfulDeleteTax.bind(this))
      .catch(this.onFailedTaxDelete.bind(this))
  }

  onSuccessfulDeleteTax(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Tax deleted successfully!', intent: Intent.SUCCESS })
    this.tax = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedTaxDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete tax!', intent: Intent.DANGER })
  }

  onCloseTaxDialog() {
    this.isTaxDialogVisible = false;
  }

  onSucessUpdateTax() {
    this.isTaxDialogVisible = false;
  }

  render() {
    let {
      taxStore: {
        taxes: {
          objects
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning" style={{marginTop:'15px'}}>
          <h4 className="bp3-heading">No Results</h4>
        </div>
      )
    }
    return(
      <React.Fragment>
         <div className="card rounded-0 table-responsive">
        <table className="table table-hover" style={{ width: '100%'}}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Percentage (%)</th>
              <th>Is Default?</th>
              <th>Is Connected to QuickBooks ?</th>
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((tax) => {
                return(
                  <tr key={tax.id}>
                    <td>{tax.attributes.name}</td>
                    <td>{tax.attributes.percentage_value}</td>
                    <td>{tax.attributes.is_default ? "Yes" : "No"}</td>
                    <td>
                      {tax.attributes.external_mappings ? 
                      "Yes"
                      : 
                      "No"
                      }
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit" minimal={true} small={true} onClick={() => this.handleTaxClick({ label: 'Edit' }, tax)}>
                          <img src="/edit-light-icon.svg"/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleTaxClick({ label: 'Delete' }, tax)}>
                          <img src="/delete-light-icon.svg" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to do this?
          </p>
        </Alert>
        <TaxModal 
          isOpen={this.isTaxDialogVisible} 
          title={"Update Tax"}
          edit={true}
          onClose={this.onCloseTaxDialog.bind(this)}
          onSuccess={this.onSucessUpdateTax.bind(this)}
        />
      </React.Fragment>
    )
  }

};

export default TaxesTable;