import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable, toJS } from 'mobx';
import { 
  Divider, Intent,
  Button, Checkbox,
  FormGroup, Spinner
} from '@blueprintjs/core';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Toast from "helpers/toaster";
import ServiceTypePicker from "components/ServiceTypePicker";
import BookingsSidebar from '../bookings/bookings-sidebar';
import { currencySymbol } from 'helpers/currency';
import { Helmet } from 'react-helmet';

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`

@inject("serviceTypeExtraStore","viewStore")
@observer
class ServiceTypeExtraShowPage extends Component {
  @observable selectedOptions = null;
  constructor(props) {
    super(props);
    this.state = {
      isQuantity: false,
      isFrequency: false,
      isDiscount: false,
      isRecurring: false,
      isBooking: false,
      isChanged: false
    };
  }

  componentDidMount() {
    let {
      props: {
        serviceTypeExtraStore,
        serviceTypeExtraStore: {
          serviceTypeExtraDetailUpdate
        }
      }
    } = this;
    serviceTypeExtraStore.loadServiceTypeExtra(this.props.viewStore.routingParams)
    .then(() => {
      this.selectedOptions = serviceTypeExtraDetailUpdate.object.attributes.service_type_extra_services.map((serviceTypeExtra) => {
        return {
          id: serviceTypeExtra.id,
          value: serviceTypeExtra.service_type_id,
          selected: true,
          label: serviceTypeExtra.label
        }
      })
    })
    
  }

  updateServiceTypeExtra() {
    let {
      props: {
        serviceTypeExtraStore: {
          serviceTypeExtraDetailUpdate,
          serviceTypeExtraDetailUpdate: { 
            object
           }
        }
      }
    } = this;

    serviceTypeExtraDetailUpdate.isLoading = true;
   
    if(object.attributes.name === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    object.attributes.service_type_extra_services_attributes = this.selectedOptions.map((option) => {
      return {
        "service_type_id": option.value
      }
    })
   
    this.props.serviceTypeExtraStore
      .updateServiceTypeExtra(object)
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Service type extra updated successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to update service type extra!",
          intent: Intent.DANGER
        });
      });
    
  }

  onServiceTypeSelect(selectedOptions, allOptions) {
    this.selectedOptions = selectedOptions;
  }

  quantityChange = () => {
    let {
      props: {
        serviceTypeExtraStore: {
          serviceTypeExtraDetailUpdate: { 
            object
           }
        }
      }
    } = this;
    this.setState({
      isQuantity: !this.state.isQuantity,
    });
    object.attributes.quantity_based = this.state.isQuantity;
  }

  bookingChange = () => {
    let {
      props: {
        serviceTypeExtraStore: {
          serviceTypeExtraDetailUpdate: { 
            object
           }
        }
      }
    } = this;
    this.setState({
      isBooking: !this.state.isBooking
    });
    object.attributes.first_booking = this.state.isBooking;
  }

  recurringChange = () => {
    let {
      props: {
        serviceTypeExtraStore: {
          serviceTypeExtraDetailUpdate: { 
            object
           }
        }
      }
    } = this;
    this.setState({
      isRecurring: !this.state.isRecurring,
    });
    object.attributes.recurring_series = this.state.isRecurring;
  }

  discountChange = () => {
    let {
      props: {
        serviceTypeExtraStore: {
          serviceTypeExtraDetailUpdate: { 
            object
           }
        }
      }
    } = this;
    this.setState({
      isDiscount: !this.state.isDiscount,
    });
    object.attributes.discount_code_discounts = this.state.isDiscount;
  }

  frequencyChange = () => {
    let {
      props: {
        serviceTypeExtraStore: {
          serviceTypeExtraDetailUpdate: { 
            object
           }
        }
      }
    } = this;
    this.setState({
      isFrequency: !this.state.isFrequency,
    });
    object.attributes.frequency_discounts = this.state.isFrequency;
  }

  handleChangeImage = (event) => {
    let {
      serviceTypeExtraStore: {
        serviceTypeExtraDetailUpdate: {
          object
        }
      }
    } = this.props;
    var file = event.target.files[0];
    var reader = new FileReader();
    let base64String = '';
      reader.onload = function() {
        base64String = reader.result;
        object.attributes.icon_file = base64String;
        object.attributes.logo = base64String;
      };
    reader.readAsDataURL(file);
  };

  render() {
    let {
      serviceTypeExtraStore: {
        serviceTypeExtraDetailUpdate,
        serviceTypeExtraDetailUpdate: {
          object,
          isLoading
        }
      }
    } = this.props;
    
    return (
      <div>
        <Helmet>
          <title>{'Service Type Extra | Fieldnexus'}</title>
        </Helmet>
       {
        object && object.attributes &&
          <div className="row no-gutters">
              <div className="col-lg-2 sidebar-responsive">
                <BookingsSidebar />
              </div>

              <div className="col-lg-10">
                <PageWrapGray>
                  <div className="pricing-parameter-table">
                    <Card>
                      <CardContent>
                        <div>
                          <div className="left-side-panel-servie-type">
                            <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                              Update Service Type Extra
                            </h4>
                          </div>
                        </div>
                        <Divider style={{ margin: '16px 0px' }}></Divider>
                        <div className="left-side-panel-servie-type pricing-parameter">
                          <FormGroup label="Name" >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='Name'
                              defaultValue={object.attributes.name}
                              onChange={(event) => {object.attributes.name = event.target.value;}}            
                            />
                          </FormGroup>
                          <FormGroup label="Description" >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='Description'
                              defaultValue={object.attributes.description}
                              onChange={(event) => {object.attributes.description = event.target.value;}}            
                            />
                          </FormGroup>
                          <FormGroup label="Icon" labelFor="text-input" labelInfo="" style={{ display: 'flex' }}>
                            <div className="service-image-main">
                              <span className="btn btn-light btn-sm btn-file">
                                  Change Icon <input type="file" onChange={this.handleChangeImage.bind(this)} accept="image/x-png,image/gif,image/jpeg" />
                              </span>
                              <div className="service-image-preview">
                                  <img src={object.attributes.logo}  height='40' alt='icon'/>
                              </div>
                            </div>
                          </FormGroup>
                          <FormGroup 
                            label="Service Type"
                            labelFor="text-input"
                            labelInfo=""
                          >
                          {
                            this.selectedOptions && 
                              <ServiceTypePicker
                                selectedOptions={toJS(this.selectedOptions)}
                                onChange={ this.onServiceTypeSelect.bind(this) }
                              />
                          }
                          </FormGroup>
                          <FormGroup labelFor="text-input" labelInfo="">
                            <div className="bookings-radio">
                              <Checkbox 
                                inline
                                large={false}
                                defaultChecked={object.attributes.quantity_based || ''}
                                onChange={this.quantityChange} >
                                  Quantity Based
                              </Checkbox>
                            </div>
                          </FormGroup>
                          {/* <FormGroup label="" labelFor="text-input" labelInfo="">
                            <input type="checkbox" 
                              defaultChecked={object.attributes.quantity_based || ''} 
                              name="quantity_based" onChange={this.quantityChange} 
                            /> 
                              Quantity Based
                          </FormGroup> */}
                          <FormGroup label={`Price (${currencySymbol()})`} >
                            <input 
                              type='number' 
                              className='form-control' 
                              placeholder='Price'
                              defaultValue={object.attributes.price}
                              onChange={(event) => {object.attributes.price = event.target.value;}}
                            />
                          </FormGroup>
                        </div>
                        <div className="left-side-panel-servie-type">
                          <FormGroup label="Duration(In mins)" >
                            <input 
                              type='number' 
                              step={"1"}
                              className='form-control' 
                              placeholder='Duration'
                              defaultValue={object.attributes.duration}
                              onChange={(event) => {object.attributes.duration = event.target.value;}}            
                            />
                          </FormGroup>
                        </div>
                        <div className="left-side-panel-servie-type pricing-parameter">
                          <FormGroup label="Exclude From" labelFor="text-input" labelInfo="">
                            <div className="bookings-radio">
                              <Checkbox 
                                inline
                                large={false}
                                defaultChecked={object.attributes.frequency_discounts || ''}
                                onChange={this.frequencyChange}>
                                  Frequency
                              </Checkbox>
                              <Checkbox 
                                inline
                                large={false}
                                defaultChecked={object.attributes.discount_code_discounts || ''} 
                                onChange={this.discountChange}>
                                  Discount
                              </Checkbox>
                            </div>
                          </FormGroup>
                          {/* <FormGroup label="Exclude From" >
                            <input type="checkbox" 
                              defaultChecked={object.attributes.frequency_discounts || ''} 
                              name="frequency_discounts" onChange={this.frequencyChange} 
                            /> Frequency 
                            <input type="checkbox" style={{ marginLeft: '15px' }} 
                              defaultChecked={object.attributes.discount_code_discounts || ''} 
                              name="discount_code_discounts" onChange={this.discountChange} 
                            /> Discount 
                          </FormGroup> */}
                          <FormGroup label="Recurring Policy" labelFor="text-input" labelInfo="">
                            <div className="bookings-radio">
                              <Checkbox 
                                inline
                                large={false}
                                defaultChecked={object.attributes.recurring_series || ''} 
                                onChange={this.recurringChange}>
                                  Recurring
                              </Checkbox>
                              <Checkbox 
                                inline
                                large={false}
                                defaultChecked={object.attributes.first_booking || ''} 
                                onChange={this.bookingChange}>
                                  First Booking
                              </Checkbox>
                            </div>
                          </FormGroup>
                          {/* <FormGroup label="Recurring Policy" >
                            <input type="checkbox" 
                              defaultChecked={object.attributes.recurring_series || ''} 
                              name="recurring_series" onChange={this.recurringChange} 
                            /> Recurring 
                            <input type="checkbox" style={{ marginLeft: '15px' }} 
                              defaultChecked={object.attributes.first_booking || ''} 
                              name="first_booking" onChange={this.bookingChange} 
                            /> First Booking 
                          </FormGroup> */}
                        </div>
                        <div className="">
                          <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.updateServiceTypeExtra.bind(this)}>Update</Button>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </PageWrapGray>
              </div>
            </div>
          }
      </div>
    );
  }
}

export default ServiceTypeExtraShowPage;
