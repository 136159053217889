import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';
import Lockr from 'lockr';

class PaymentStore {

  @observable tenant_id = null;
  @observable invoice_id = null;
  @observable nameOnCard = '';

  @observable subTotal = 0.0;
  @observable total = 0.0;
  @observable due_amount = 0.0;
  @observable taxes = [];
  @observable stripeCheckout = {};

  @observable showLoaderCardPayment = false;

  @observable showAcceptInvoiceLoader = false;

  @observable invoice = {
    isLoading: false,
    isError: false,
    object: null
  };

  @observable preference = {
    isLoading: false,
    isError: false,
    object: null
  };

  @observable pay = {
    isLoading: false,
    isError: false,
    object: null
  };

  @action
  invoiceSignature(invoice_signature) {
    this.showAcceptInvoiceLoader = true;
    let promise = Api.post(`/work_reports/${this.tenant_id}/quotes/${invoice_signature.invoice_id}/invoice_signature`, { invoice_signature: invoice_signature })

    promise
      .then((response) => {
        this.showAcceptInvoiceLoader = false;
      })
      .catch((error) => {
        this.showAcceptInvoiceLoader = false;
      })

    return promise;
  }

  @action
  stripeCharge(transaction) {
    this.showLoaderCardPayment = true;
    let promise = Api.post(`/payments/${transaction.invoice_id}/stripe_charge`, { transaction: transaction })

    promise
      .then((response) => {
        this.showLoaderCardPayment = false;
        stores.invoiceStore.getInvoice({uuid: transaction.invoice_id});
      })
      .catch((error) => {
        this.showLoaderCardPayment = false;
      })

    return promise;
  }

  @action
  makePayment(token) {
    this.pay.isLoading = true
    let promise = Api.post(`/payments/bambora_payment`, 
      { token: token, 
        name: this.nameOnCard,
        tenant_id: this.tenant_id,
        invoice_id: this.invoice_id
      }
    )

    promise
      .then((response) => {
        this.pay.isLoading = false;
        this.pay.isError = false;
        this.loadInvoiceAmount()
      })
      .catch((error) => {
        this.pay.isLoading = false;
        this.pay.isError = true;
      })

    return promise;
  }

  @action
  generateStripeCheckoutSession() {
    let promise = Api.post(`/payments/${this.invoice_id}/create_stripe_checkout_session?tenant_id=${this.tenant_id}`);

    return promise;
  }

  @action
  loadInvoiceToPay() {
    this.invoice.isLoading = true
    let promise = Api.get(`/payments/${this.tenant_id}/invoices/${this.invoice_id}`)

    promise
      .then((response) => {
        this.invoice.isLoading = false;
        this.invoice.isError = false;
        this.invoice.object = response.data.data
        if(!Lockr.get('currentTenant')){
          Lockr.set('currentTenant', response.data.data.attributes.tenant);
        }
        this.loadInvoiceAmount();
        this.loadPreference();
      })
      .catch((error) => {
        this.invoice.isLoading = false;
        this.invoice.isError = true;
      })

  }

  @action
  loadInvoiceAmount() {
    let promise = Api.get(`/payments/${this.tenant_id}/invoices/${this.invoice_id}/amount`)

    promise
      .then((response) => {
        this.subTotal = response.data.subtotal
        this.total = response.data.total
        this.due_amount = response.data.due_amount
        this.taxes = response.data.taxes
      })
      .catch((error) => {
      })
  }

  @action
  loadPreference() {
    let promise = Api.get(`/payments/${this.tenant_id}/preferences`)

      promise
        .then((response) => {
          this.preference.isLoading = false;
          this.preference.isError = false;
          this.preference.object = response.data
        })
        .catch((error) => {
          this.preference.isLoading = false;
          this.preference.isError = true;
        })

  }

};

export default PaymentStore;