import React from 'react';
import Geosuggest from 'react-geosuggest';

class GeoComplete extends React.Component {

  render() {
    return (
      <Geosuggest
        className="bp3-input-group"
        inputClassName="bp3-input form-control bp3-fill"
        suggestsClassName="bp3-menu"
        suggestItemActiveClassName="bp3-active"
        suggestItemClassName="bp3-menu-item"
        {...this.props}
      />
    )
  }
};

export default GeoComplete;