import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import Pagination from 'components/Pagination';
import Footer from '../Footer';
import { Tag } from '@blueprintjs/core';
import qs from 'qs';
import { formatTimeOnly, formatDateOnly } from 'helpers/time';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';

@inject('store', 'jobStore', 'viewStore') @observer
class AssignmentsTable extends Component {

  onPaginationChange(newPage) {
    let {
      viewStore: {
        jobSearch
      }
    } = this.props;
    this.props.viewStore.jobSearch = {
      ...jobSearch,
      page: newPage
    };
    this.props.viewStore.assignments();
  }

  render() {
    let {
      viewStore,
      viewStore: {
        jobTab: {
          section
        }
      },
      jobStore: {
        jobs: {
          objects,
          meta
        }
      }
    } = this.props;
    

    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }

    return(
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
        <table className="table table-hover table-data-presentation" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Job #</th>
              <th width="15%">Job Title</th>
              <th>Start - End Time</th>
              <th>Status</th>
              <th>Technician</th>
              <th>Client</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((assignment) => {
                return(
                  <tr key={assignment.id}> 
                    <td>
                      <TableCellWithSubtext
                        mainText={assignment.attributes.event_number}
                        mainLink={`/dashboard/jobs/${assignment.attributes.job.id}/${section}`}
                      />
                      <Tag style={{ backgroundColor: `#${assignment.attributes.service_type.color}` }}>
                        {assignment.attributes.service_type && assignment.attributes.service_type.name}
                      </Tag>
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={assignment.attributes.job.title}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(assignment.attributes.start_date)}
                        subText={`${formatTimeOnly(assignment.attributes.start_date)} - ${formatTimeOnly(assignment.attributes.end_date)}`}
                      />
                    </td>
                    <td>
                      <span className="docs-color-bubble" style={{backgroundColor: assignment.attributes.assignment_color}}></span>
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={assignment.attributes.technician_name}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={assignment.attributes.client_name}
                        mainLink={`/dashboard/clients/${assignment.attributes.client.id}?${qs.stringify(viewStore.clientShowSearch, { encode: false })}`}
                        subText={assignment.attributes.location && assignment.attributes.location.full_address}
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        </div>
      </React.Fragment>
    )
  }

};

export default AssignmentsTable;