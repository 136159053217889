import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from '@blueprintjs/core';
import { observable } from 'mobx';
import FormSubmissionModal from './FormSubmissionModal';
import { formatTime } from '../../helpers/time';

@inject('store', 'formSubmissionStore') @observer
export default class FormSubmissionRow extends Component {

  @observable showFormSubmissionModal = false;

  onClose() {
    this.showFormSubmissionModal = false;
  }

  showModal() {
    this.props.formSubmissionStore.getFormSubmission(this.props.formSubmission)
    this.showFormSubmissionModal = true;
  }

  render() {
    let { formSubmission } = this.props;
    return(
      <React.Fragment>
        
        <tr onClick={this.showModal.bind(this)} >
          <td className="border-left-0">{formSubmission.name}</td>
          <td>{formSubmission.created_by_name}</td>
          <td>{formatTime(formSubmission.created_at)}</td>
          <td className="border-0 py-0" style={{ width: '5%' }}>
            <Icon icon="caret-right" className="row-delete-btn" />
          </td>
        </tr>
         <div style={{height:10}}></div>
        <FormSubmissionModal
          isOpen={this.showFormSubmissionModal}
          formSubmission={formSubmission}
          onClose={this.onClose.bind(this)}
        />
      </React.Fragment>
    )
  }

}