import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button } from "@blueprintjs/core";

@inject('searchStore') @observer
class GlobalSearchBar extends Component {

  @observable term = "";

  componentDidMount(){
    document.addEventListener('keyup', this.searchListener.bind(this), false);
  }

  doSearch() {
    this.props.searchStore.doTermSearch(this.term)
  }

  handleTermChange(event) {
    this.term = event.target.value
    if(this.term === '') { 
      this.props.searchStore.search.isSearched = false
      this.props.searchStore.search.objects = {}
    }
  }

  searchListener(e) {
    if (e.ctrlKey && e.key == 's') {
      document.getElementById("search-field").focus();
    }
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.doSearch()
    }else if(event.key == "Escape"){
      this.term = "";
      this.props.searchStore.search.isSearched = false;
      this.props.searchStore.search.objects = {};
    }
  }

  render() {
    const {
      onClose,
      searchStore: {
        search: {
          isLoading,
          isError,
          isSearched,
          objects
        }
      }
    } = this.props;
    return(
      <div className="bp3-input-group bp3-large bp3-location" style={{top: 0}}>
        <div className="bp3-input-group">
          <span className="bp3-icon bp3-icon-search"></span>
          <input 
            type="text" 
            className="bp3-input" 
            placeholder="Search by client, technician, job..." 
            onChange={this.handleTermChange.bind(this)} 
            onKeyDown={this.onEnter.bind(this)} 
            value={this.term}
            id="search-field"
          />
          <Button className="bp3-minimal bp3-icon-arrow-right" loading={isLoading} onClick={this.doSearch.bind(this)}></Button>
        </div>
        { objects && 
            <div className="search-dropdown">
              { 
                Object.keys(objects).map(function(key, index) {
                  return <SearchList key={Math.random()} heading={key} items={objects[key].splice(0,10)} onClose={onClose.bind(this)} />
                })
              }
            </div>
        }

        {!isLoading && !isError && isSearched && Object.keys(objects).length === 0 && <div>
          <div className="search-dropdown-no-result">
            <small className="bp3-text-muted">No result! please try some other search term</small>
          </div>
          </div>}
      </div>
  
    )
  }

};

export default GlobalSearchBar;

@inject('searchStore', 'viewStore') @observer
class SearchList extends Component {

  navigateTo(item, type) {
    const {
      viewStore: {
        jobTab: {
          section
        }
      },
    } = this.props;
    switch(type) {
      case 'ClientDecorator':
        window.location.href = `/dashboard/clients/${item.id}?resource=jobs`;
        break;
      case 'UserDecorator':
        window.location.href = `/dashboard/users/${item.id}/show`;
        break;
      case 'JobDecorator':
        window.location.href = `/dashboard/jobs/${item.id}/${section}`;
        break;
      default:
    }
  }


  render() {
    let { heading, items } = this.props;
    return(
      <React.Fragment>
        <figure>
          <figcaption>{heading.replace("Decorator", "s")}</figcaption>
            <ul>
              { items.map((item) => {
                  return(<li key={item.id}>
                    <a onClick={() => this.navigateTo(item, heading)}>
                      <div className="bp3-fill">
                        <div>
                          {heading === "ClientDecorator" && item.name}
                          {heading === "UserDecorator" && `${item.first_name} ${item.last_name}` }
                          {heading === "JobDecorator" && item.title}
                        </div>
                        <small className="bp3-text-muted">
                          <span className='gs-client-email'>
                            {heading !== "JobDecorator" && item.email} 
                            {heading === "ClientDecorator" && item.do_not_service && <div className="global-do-not-serve-pill">{"Do not service"}</div>}
                          </span>
                          {heading === "JobDecorator" && item.event_number}
                        </small>
                      </div>
                    </a>
                  </li>)
                })
              }
            </ul>
        </figure>
      </React.Fragment>
    )
  }

};