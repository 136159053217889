import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from 'mobx';
import { 
  Divider, Intent,
  Button,
  FormGroup, Checkbox, TextArea
} from '@blueprintjs/core';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Toast from "helpers/toaster";
import ServiceTypePicker from "components/ServiceTypePicker";
import QuestionGroupPicker from "components/QuestionGroupPicker";
import BookingsSidebar from '../bookings/bookings-sidebar';
import Select from 'react-select';
import { Helmet } from 'react-helmet';

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`
let options = [
  {label: 'Textbox', value: 'Textbox'},
  {label: 'Checkbox', value: 'Checkbox'},
  {label: 'Dropdown', value: 'Dropdown'}
];

@inject("questionStore")
@observer
class QuestionAddPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: true,
    };
  }

  componentDidMount() {
    let {
      questionStore: {
        question: {
          object
        }
      }
    } = this.props;

  }

  createQuestion() {
    let {
      props: {
        questionStore: {
          question: { 
            object
           }
        }
      }
    } = this;

    if(object.field_label === "" || object.field_label === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid label!', intent: Intent.DANGER })
      return;
    }

    if(object.field_type === "" || object.field_type === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please select valid field type!', intent: Intent.DANGER })
      return;
    }

    if(object.service_type_id === "" || object.service_type_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please select valid service type!', intent: Intent.DANGER })
      return;
    }

    if(object.question_group_id === "" || object.question_group_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please select valid question group!', intent: Intent.DANGER })
      return;
    }

    this.props.questionStore
      .createQuestion()
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Question created successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to create Question!",
          intent: Intent.DANGER
        });
      });
  }

  onGroupSelect(selectedOptions) {
    let { object } = this.props.questionStore.question;
    object.selectedGroup = selectedOptions;
    if (selectedOptions.length > 0) {
      object.question_group_id = selectedOptions[0].value;
    }
  }

  onServiceTypeSelect(selectedOptions) {
    let { object } = this.props.questionStore.question;
    object.selectedServiceType = selectedOptions;
    if (selectedOptions.length > 0) {
      object.service_type_id = selectedOptions[0].value;
    }
  }

  handleChange = selectedOption => {
    let {
      questionStore,
      questionStore: {
        question: {
          object,
          isLoading
        }
      }
    } = this.props;
    if (selectedOption.value) {      
      object.field_type = selectedOption.value;
    }  
  };

  
  render() {
    let {
      questionStore,
      questionStore: {
        question: {
          object,
          isLoading
        }
      }
    } = this.props;


    return (
      <div className="row no-gutters">
        <Helmet>
          <title>{'Questions | Fieldnexus'}</title>
        </Helmet>
        <div className="col-lg-2 sidebar-responsive">
          <BookingsSidebar />
        </div>
        <div className="col-lg-10">
          <PageWrapGray>
            <div className="pricing-parameter-table bookings-sub-forms">
              <Card>
                <CardContent>
                  <div>
                    <div className="left-side-panel-servie-type">
                      <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                        Add Question
                      </h4>
                    </div>
                  </div>
                  <Divider style={{ margin: '16px 0px' }}></Divider>
                  <div className="left-side-panel-servie-type pricing-parameter">
                    <FormGroup label="Name" >
                      <input 
                        type='text' 
                        className='form-control' 
                        placeholder=''
                        defaultValue={object.field_label}
                        onChange={(event) => {object.field_label = event.target.value;}}            
                      />
                    </FormGroup>
                    <FormGroup 
                      label="Service Type(s)"
                      labelFor="text-input"
                      labelInfo="(*)"
                    >
                      <ServiceTypePicker
                        selectedOptions={object.selectedServiceType}
                        onChange={this.onServiceTypeSelect.bind(this)}
                      />
                    </FormGroup>
                    <FormGroup 
                      label="Question Group(s)"
                      labelFor="text-input"
                      labelInfo="(*)"
                    >
                      <QuestionGroupPicker
                        selectedOptions={object.selectedGroup}
                        onChange={this.onGroupSelect.bind(this)}
                      />
                    </FormGroup>
                    <FormGroup 
                      label="Select Field Type"
                      labelFor="text-input"
                    >
                      <Select
                        options={options} 
                        onChange={this.handleChange}        
                        isSearchable={true}
                        isClearable={false}
                        placeholder={"Select Field Type"}        
                      />
                    </FormGroup>
                    <div style={{ marginBottom: '1rem'}} className="invoice_enable_signature">
                      <Checkbox 
                        label="Is required"
                        onChange={event => {
                          object.is_required = event.target.checked;
                        }}
                      />
                    </div>
                    <FormGroup label="Field Values" labelInfo="one per line. You can also use key:value format if key and value is different">
                      <TextArea 
                        type='text' 
                        className='form-control' 
                        placeholder=''
                        defaultValue={object.field_value}
                        onChange={(event) => {object.field_value = event.target.value;}}            
                      />
                    </FormGroup>
                  </div>
                  <div className="">
                    <Button className="btn-save" loading={isLoading} onClick={this.createQuestion.bind(this)}>Save</Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </PageWrapGray>
        </div>
      </div>
    );
  }
}

export default QuestionAddPage;
