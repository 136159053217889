import React from 'react';
import {
  Spinner, Icon, Tag
} from '@blueprintjs/core';
import Avatar from '@atlaskit/avatar';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import qs from 'qs';
import { formatTime } from '../../helpers/time'

@inject('viewStore', 'workReportStore','supplierStore') @observer
class ClientSuppliersTable extends React.Component {

  @observable jobs = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    this.props.viewStore.materialLineItemSearch.where.supplier_id = this.props.supplier.attributes.id;
    this.props.workReportStore.loadMaterialLineItems();
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        materialLineItemSearch
      }
    } = this.props;
    this.props.viewStore.materialLineItemSearch = {
      ...materialLineItemSearch,
      page: newPage
    };
    this.props.workReportStore.loadMaterialLineItems();
  }

  getStatusColorCode(status) {
    var colorCode = "text-capitalize";
    switch(status) {
      case 'pending order':
        return colorCode + " color-pending";
      case 'ordered':
        return colorCode + " color-ordered";
      case 'in transit':
          return colorCode + " color-intransit";
      case 'received':
          return colorCode + " color-recieved";
      case 'picked up by the technician':
          return colorCode + " color-pubt";
      case 'archived':
          return colorCode + " color-archived";
      default:
        return colorCode + " color-default";
    }
  }

  render() {
    let {
      workReportStore,
      workReportStore: {
        materialLineItem:{
          isLoading,
          objects,
          meta
        }
      }
    } = this.props;
    let { viewStore } = this.props;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning bp3-icon-info-sign table-card">
          No material ordering have been created for this supplier yet.
        </div>
      )
    }
    return(
      <div className="card rounded-0 table-responsive">

        <table className="table hover">
          <thead>
            <tr>
              <th>Sno.</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Part Number</th>
              <th>Purchase Order Number</th>
              <th>Work Order Number</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              objects && objects.length > 0 &&
              objects.map((material,key) => {
                return (
                  <tr key={Math.random()}>
                    <td>{key+1}</td>
                    <td>{material.attributes.description}</td>
                    <td>{material.attributes.quantity}</td>
                    <td>{material.attributes.part_number}</td>
                    <td>
                      { material.attributes.purchase_order.number
                        ?
                        <a href={`/dashboard/purchase-orders/${material.attributes.purchase_order.id}/show-purchase-order`} className="bp3-menu-item bp3-intent-default bp3-popover-dismiss">
                          {material.attributes.purchase_order.number}
                        </a>
                        :
                        '' 
                      }
                    </td>
                    <td>
                      { material.attributes.work_report.number
                        ?
                        <a href={`/dashboard/work-reports/${material.attributes.work_report.id}/show`} className="bp3-menu-item bp3-intent-default bp3-popover-dismiss">
                          {material.attributes.work_report.number}
                        </a>
                        :
                        '' 
                      }
                    </td>
                    <td><Tag className={this.getStatusColorCode(material.attributes.status)}>{material.attributes.status}</Tag></td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </div>
    )
  }
};

export default ClientSuppliersTable;