import React from 'react';
import { Button, Popover, Position, Tag, Spinner } from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import ListPicker from 'components/ListPicker';
import PropTypes from 'prop-types';
import { Api } from 'services/Api';
import axios from 'axios';

@inject('viewStore') @observer
class LocationPicker extends React.Component {

  @observable loading = true;
  @observable failed = false;
  @observable options = [];

  @action
  onLocationLoad(response) {
    this.options = response.data.data.map((location) =>
      ({
        label: location.attributes.full_address,
        value: location.id,
        selected: false
      })
    );
    this.failed = false;
    this.loading = false;
  }

  @action
  onLocationLoadFail(error) {
    if (!axios.isCancel(error)) {
      this.loading = false;
      this.failed = true;
    }
  }

  loadLocations() {
    const {
      where
    } = this.props.viewStore.equipmentSearch;
    let promise = Api.post('/clients/locations', { id: where.client_id})
    promise
      .then(this.onLocationLoad.bind(this))
      .catch(this.onLocationLoadFail.bind(this))
    return promise;
  }

  render() {
    let {
      props: {
        onChange,
        selectedOptions
      }
    } = this;
    let content = '';
    if (this.failed) {
      content = (<div className="bp3-callout bp3-intent-danger bp3-icon-info-sign">
        Had some problem loading Locations. Please close and try again!
      </div>)
    }
    if (!this.failed) {
      if (this.loading) {
        content = <div><Spinner /></div>;
      }
      if (!this.loading && this.options.length > 0) {
        content = <ListPicker
                    selectedOptions={selectedOptions}
                    options={this.options}
                    onChange={onChange}
                  />
      }
    }
    return(
      <Popover
        minimal={true}
        onOpening={this.loadLocations.bind(this)}
        content={content}
        position={Position.BOTTOM_LEFT}
        usePortal={true} >
        <Button rightIcon='caret-down'>
          Locations
          <span>&nbsp;</span>
          <Tag minimal={true} round={true}>{selectedOptions.length || 0}</Tag>
        </Button>
      </Popover>
    )
  }

}

LocationPicker.propTypes = {
  selectedOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func,
}

export default LocationPicker;