import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import Card from 'components/Card';
import { 
  ButtonGroup, Button, NonIdealState, Spinner
} from "@blueprintjs/core";
import ProductsServicesTable from 'components/Tables/ProductsServices';
import SettingsSidebar from '../preferences/settings-sidebar';
import ProductServiceModal from 'components/ProductsServices/ProductServiceModal';
import SearchBarWrapper from 'components/SearchBarWrapper';
import Sticky from 'react-stickynode';
import SearchBars from 'components/SearchBars';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'productServiceStore',) @observer
class ProductsServicesIndexPage extends Component {

  @observable isProductServiceDialogVisible = false;

  componentDidMount() {
    let { productServiceStore } = this.props;
    productServiceStore.loadProductsServices()
  }

  get canRender() {
    let {
      productServiceStore: {
        productsServices: {
          isLoading,
          isError,
          objects
        }
      }
    } = this.props;
    return (!isLoading && !isError && objects);
  }

  onCloseProductServiceDialog() {
    this.isProductServiceDialogVisible = false;
  }

  onSucessCreateProductService(productService) {
    this.isProductServiceDialogVisible = false;
  }

  render() {
    let content;
    let {
      productServiceStore: {
        productsServices: {
          isLoading,
          isError
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      content = <ProductsServicesTable />;
    }
    return(
      <div className="taxed-page-layout">
        <Helmet>
          <title>Pricebook | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <SettingsSidebar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
              <Sticky enabled={true} top={0} innerZ={9} className="sticky-mobile">
                <SearchBarWrapper>
                  <SearchBars.ProductService />
                </SearchBarWrapper>
              </Sticky>
              <Card>
                {/* <div>            
                  <div className="text-right mb-2">
                    <ButtonGroup>
                      <Button icon='add' onClick={() => this.isProductServiceDialogVisible = true }>
                        Add
                      </Button>
                    </ButtonGroup>
                  </div>
                </div> */}
                {content}
              </Card>
            </PageWrapGray>
          </div>
        </div>
        <ProductServiceModal 
          isOpen={this.isProductServiceDialogVisible} 
          title={"Add to Pricebook"}
          edit={false}
          onClose={this.onCloseProductServiceDialog.bind(this)}
          onSuccess={this.onSucessCreateProductService.bind(this)}
        />
      </div>
    )
  }

}

export default ProductsServicesIndexPage;