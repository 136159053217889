import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  Button,
  Dialog,
  Classes,
  Intent,
  ControlGroup
} from "@blueprintjs/core";
import Toast from "helpers/toaster";
import { observable } from "mobx";

@inject("workReportStore", "quoteStore", "store")
@observer
class AddInvoiceNumberModal extends Component {


  createInvoiceNumber() {
    let {
      workReportStore,
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
    
    if (object.attributes.invoice_number === '' || object.attributes.invoice_number === null) {
      Toast.show({
        icon: "warning-sign",
        message: "Please enter invoice number.",
        intent: Intent.DANGER
      });
      return;
    }
    this.props.workReportStore
      .createInvoiceNumber()
      .then(this.onSuccessfullyCreateInvoiceNumber.bind(this))
      .catch(this.onFailCreateInvoiceNumber.bind(this));
  }

  onSuccessfullyCreateInvoiceNumber(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Invoice number updated successfully.', intent: Intent.SUCCESS })
    this.props.onSuccess(response);
  }

  onFailCreateInvoiceNumber(error) {
    console.log(error);
  }

  handleCloseInvoiceNumberDailog() {
    this.props.onClose();
  }

  render() {
    let {
      workReportStore,
      workReportStore: {
        workReport: {
          object,
          isLoading
        }
      }
    } = this.props;
    return (
      <Dialog
        title={object && object.attributes && object.attributes.invoice_number ? 'Update Invoice Number' : 'Add Invoice Number'}
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseInvoiceNumberDailog.bind(this)}
        hasBackdrop="true"
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="Invoice Number" labelFor="text-input">
            <input
              type="text"
              className="bp3-input bp3-fill"
              placeholder="Invoice Number"
              defaultValue={object && object.attributes && object.attributes.invoice_number}
              onChange={event => {
                object.attributes.invoice_number = event.target.value;
              }}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              fill="false"
              onClick={this.handleCloseInvoiceNumberDailog.bind(this)}
              style={{ width: "100px" }}
            >
              Cancel{" "}
            </Button>
            <Button
              fill="false"
              icon={object && object.attributes && object.attributes.invoice_number ? "floppy-disk" : "add"}
              intent={Intent.PRIMARY}
              onClick={this.createInvoiceNumber.bind(this)}
              loading={isLoading}
              style={{ width: "200px" }}
            >
              {object && object.attributes && object.attributes.invoice_number ? 'Update Invoice Number' : 'Add Invoice Number'}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddInvoiceNumberModal;
