import React from 'react';
import { Spinner, Alert, Button, Intent } from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import qs from 'qs';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
import ProductAndServiceDetailsTable from 'components/WorkReport/ProductAndServiceDetailsTable';
import _ from 'lodash';
import { currencySymbol } from 'helpers/currency';

var materialItemIds = [];

@inject('viewStore','equipmentStore','workReportStore','productServiceStore') @observer
class ProductAndServiceTable extends React.Component {
  
  @observable isSelectDialogVisible = false;
  @observable isSelectId;
  @observable showLoader = false;

  componentDidMount() {
    let {workReportId} = this.props; 
    workReportId && workReportId.attributes.materials.map((material) => {
      if(material.product_service_id){
        materialItemIds.push(material.product_service_id);
      }
    })    
  }  

  onPaginationChange(newPage) {
    let {
      viewStore: {
        productServiceSearch
      }
    } = this.props;
    this.props.viewStore.productServiceSearch = {
      ...productServiceSearch,
      page: newPage
    };
    this.props.productServiceStore.loadProductAndServicesForWorkReports(); 
  }
  
  render() {
    let {objects, meta, isLoading, workReportId, type, formType} = this.props; 
    if (isLoading) {
      return(
        <div className="">
          <Spinner size='25' />
        </div>
      ) 
    }

    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No product/service have been added yet.
        </div>
      )
    }
    
    return (
      <div className="table-responsive">
        <table className='table table-data-presentation table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>             
              <th>Name</th>            
              <th>Description</th>
              {
                type != 'Service' ?
                <th>Part Number</th>
                :''
              }
              {
                type != 'Service' ?
                <th width="20%">Unit Price ({currencySymbol()})</th>
                :
                <th width="20%">Hourly Rate ({currencySymbol()})</th>
              }
              <th className="item-center">Action</th>           
            </tr>
          </thead>
          <tbody>
            {
              objects && objects.map((product) => {               
                let valueInWorkReportMaterial = _.includes(materialItemIds,product.attributes.id);                  
                let value = _.find(workReportId.attributes.materials, { 'product_service_id': product.attributes.id });
                return (
                  <ProductAndServiceDetailsTable product={product} servicedMaterial={value} valueInWorkReportMaterial={valueInWorkReportMaterial}  workReportId={workReportId} type={type} formType={formType}/>
                )            
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>       
      </div>
    )
  }
}

export default ProductAndServiceTable;