import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup, Button, Dialog, Classes, Intent, TextArea, Spinner, Checkbox
} from "@blueprintjs/core";
import DateTimeInput from 'components/DateTimeInput';
import Toast from 'helpers/toaster';
import { observable } from 'mobx';
import _ from 'lodash';
import moment from 'moment';

@inject('jobStore') @observer
class AddTimeLogModal extends Component {

  @observable work_orders = [];

  createTimeLog() {
    let {
      jobStore,
      jobStore: {
        timelog: {
          object
        }
      }
    } = this.props;
    jobStore.createTimeLog(object)
      .then(this.onSuccessfullySaveTimeLog.bind(this))
      .catch(this.onFailCreateTimeLog.bind(this))
  }

  onSuccessfullySaveTimeLog(response) {
    this.props.onSuccess();
  }

  onFailCreateTimeLog(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: "Something went wrong, failed to create TimeLog.", intent: Intent.DANGER })
    }
  }

  handleCloseTimeLogDailog() {
    this.props.onClose();
  }

  handleTechnicianChange(event) {
    let {
      jobStore: {
        jobWorkReports,
        timelog: {
          object
        },
        job: {
          object: {
            attributes
          }
        }
      }
    } = this.props;
    object.user_id = event.target.value;
    if(event.target.value === "") {
      object.description = "";
    } else {
      object.description = event.target.selectedOptions[0].text;
    }
    this.work_orders = _.filter(jobWorkReports, function(obj) { return obj.attributes.user_id === object.user_id})
    let assignment = _.find(attributes.assignments, function(obj) { return obj.user_id === object.user_id})
    if(assignment && assignment.id) {
      object.schedule_assignment_id = assignment.id;
    }
  }

  onStartDateChange(date) {
    let {
      jobStore: {
        timelog: {
          object
        }
      }
    } = this.props;
    object.start_time = date;
    if(object.hours && object.hours > 0 && object.start_time) {
      object.end_time = moment(object.start_time).add(parseFloat(object.hours), 'hours')._d;
    }
  }

  onHoursChange(event) {
    let {
      jobStore: {
        timelog: {
          object
        }
      }
    } = this.props;
    if(event.target.value === 0 || event.target.value < 0) {
      Toast.show({ icon: 'warning-sign', message: "Hours must be greater than zero.", intent: Intent.DANGER })
      return;
    }
    object.hours = event.target.value;
    object.end_time = moment(object.start_time).add(parseFloat(object.hours), 'hours')._d;
    console.log("End time: ", object.end_time)
  }

  

  render() {
    let {
      work_orders,
      props: {
        jobStore: {
          job: {
            object: {
              attributes
            }
          },
          jobAssociations: {
            isLoading
          },
          timelog,
          timelog: {
            object
          }
        }
      }
    } = this;
    return(
      <Dialog
        title={'Add TimeLog'}
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Technician"
            labelFor="text-input"
            labelInfo="*"
          >
            <div className="bp3-select bp3-fill">
              <select
                value={object.user_id}
                onChange={this.handleTechnicianChange.bind(this)}
              >
                <option value="">Select Technician</option>
                {attributes && attributes.assignments && attributes.assignments.length > 0 && attributes.assignments.map((assignment) => {
                  return(
                    <option value={assignment.user_id} key={assignment.user_id}>{assignment.name}</option>
                  )
                })}
              </select>
            </div>
          </FormGroup>

          <div className="bp3-form-group">
            <label className="bp3-label" htmlFor="form-group-input">
              Work Order
              <span className="bp3-text-muted">{isLoading && <Spinner size={15} />} </span>
            </label>
            <div className="bp3-form-content">
              <div className="bp3-select bp3-fill">
                <select
                  value={object.work_report_id}
                  onChange={event => {
                    object.work_report_id = event.target.value;
                  }}
                >
                  <option value="">Select Work Order</option>
                  {work_orders && work_orders.length > 0 && work_orders.map((work_order) => {
                    return(
                      <option value={work_order.id} key={work_order.id}>{work_order.attributes && work_order.attributes.number}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>

          <FormGroup
            label="Start Date"
            labelFor="text-input"
            labelInfo="*"
          >
            <div className="fullWidth">
              <DateTimeInput 
                placeholder={'Start Date'}
                defaultValue={moment(object.start_time)}
                onChange={this.onStartDateChange.bind(this)}
              />
            </div>
          </FormGroup>

          <FormGroup
            label="Hours"
            labelFor="text-input"
            labelInfo="*"
          >
            <input
              type="number"
              step="0.01"
              className="bp3-input bp3-fill"
              placeholder="Hours"
              defaultValue={object.hours}
              onChange={this.onHoursChange.bind(this)}
            />
          </FormGroup>

          {/* <FormGroup 
            label="Description" 
            labelFor="text-input" 
            labelInfo="">
            <TextArea
              type="text"
              className="bp3-input bp3-fill"
              placeholder="Write some description"
              defaultValue={object.description}
              onChange={event => {
                object.description = event.target.value;
              }}
            />
          </FormGroup> */}
          
          <FormGroup 
            label="Is Overtime?"
            style={{marginBottom: 0}}
          >
            <Checkbox 
              checked={object.is_overtime} 
              large={false}
              label="" 
              onChange={(event) => {object.is_overtime = event.target.checked}} />
          </FormGroup>
          
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseTimeLogDailog.bind(this)} className="btn-notes-close">Close</Button>
            <Button intent={Intent.PRIMARY} loading={timelog.isLoading} onClick={this.createTimeLog.bind(this)}>
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }

};

export default AddTimeLogModal;