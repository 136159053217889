import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Position, Button, ButtonGroup, Popover, Menu, MenuItem, Icon } from '@blueprintjs/core';
import MaterialItem from './MaterialItem';
import _ from 'lodash';
import { currencyFormattedAmount } from 'helpers/currency';
import Toast from 'helpers/toaster';
import AddProductForWorkReportModal from 'components/WorkReport/AddProductForWorkReportModal';

@inject('store', 'workReportStore','viewStore','productServiceStore') @observer
class WorkReportMaterialLineItems extends Component {
  @observable isOpenAddMaterialForWorkReportDialog = false;
  
  calculateSubTotal(materials) {
    if(materials.length > 0) {
      return currencyFormattedAmount(_.sumBy(materials, 'total'))
    } else {
      return currencyFormattedAmount(0)
    }
  }

  calculateSubTotalBeforeMarkup(materials) {
    if(materials.length > 0) {
      var sum = 0;
      _.each(materials, function(material) {
        sum += parseFloat(material.quantity) * parseFloat(material.rate_per_unit)
      })
      return currencyFormattedAmount(sum)
    } else {
      return currencyFormattedAmount(0)
    }
  }

  handleCloseMaterialForWorkReportDailog() {
    this.props.store.isOpenAddMaterialForWorkReportDialog = false;
  }

  onSuccessAddMaterialForWorkReport(equipment) {
    const {
      productServiceStore: {
        productsServices: {
          objects
        }
      }
    } = this.props;
    this.props.viewStore.productServiceSearch.where.product_service_type = 'Products (Non-inventory)';
    this.props.productServiceStore.loadProductAndServicesForWorkReports(); 
    this.props.store.isOpenAddMaterialForWorkReportDialog = true;
    this.props.store.productServiceType = 'Products (Non-inventory)'; 
  }

  render() {
    this.state = {
      show: false
    }
    const {
      workReportStore,
      workReportStore: {
        workReport: {
          object
        }
      },
      store
    } = this.props;
    
    return (
      <React.Fragment>
        <div className="table-title" style={{ paddingLeft: '10px' }}>
          <h5><strong>Materials</strong></h5>
          <Button icon='add' className="bp3-minimal add-btn" onClick={() => { this.onSuccessAddMaterialForWorkReport() }}>Add Material</Button>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div style={{ padding: '0px 10px' }} >
            {
              object.attributes.materials.length === 0 && <div className="bp3-callout warning-alert-message bp3-intent-warning bp3-icon-info-sign table-card">
                No material line items have been added to this Work Order.
              </div>
            }
          </div>

          {/* {
            object.attributes.materials.length > 0 && <table className='responsive-table-layout table report-data-table table-product-service' style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th colSpan="2">Products</th>
                  <th>Supplier</th>
                  <th>Part Number</th>
                  <th>Qty</th>
                  <th>Unit Price({object.attributes.currency_symbol})</th>
                  <th>Markup</th>
                  <th>Total</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  object.attributes.materials.map((material,key) => {
                    return (
                      <MaterialItem material={material} key={key} />
                    )
                  })
                }
              </tbody>
            </table>
            
          } */}

          {
            object.attributes.materials.length > 0 && <div className="wr-row">
              <div style={{ flex: 1 }}>
                <table className="table table-bordered border-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: 150}}>Products</th>
                      <th scope="col" style={{ width: 250 }}>Supplier</th>
                      <th scope="col" style={{ width: 120 }}>Part No.</th>
                      <th scope="col" style={{ width: 75 }}>Qty</th>
                      <th scope="col" style={{ width: 115 }}>Unit Price({object.attributes.currency_symbol})</th>
                      <th scope="col" style={{ width: 109 }}>Markup</th>
                      <th scope="col" style={{textAlign:'right'}}>Total</th>
                      <th scope="col" style={{ width: 62 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    
                      {
                        object.attributes.materials.map((material, key) => {
                          return (
                            <MaterialItem material={material} key={key} />
                          )
                        })
                      }
                     
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
        
        {object.attributes.materials.length > 0 && <div className="lineitem_sub_total material_sub_total">
          <p><span style={{ marginRight: 25 }}>Sub Total Before markup: {this.calculateSubTotalBeforeMarkup(object.attributes.materials)}</span>Sub Total: {this.calculateSubTotal(object.attributes.materials)}</p>
        </div>}
        <AddProductForWorkReportModal
          isOpen={store.isOpenAddMaterialForWorkReportDialog}
          onClose={this.handleCloseMaterialForWorkReportDailog.bind(this)}
          workDetail = {object}
          type={this.props.store.productServiceType}
          onSuccess={this.onSuccessAddMaterialForWorkReport.bind(this)}
          title="Select Material"
          button_text="Create New Material"
          itemType="Materials"
        />
      </React.Fragment>
    )
  }
}
export default WorkReportMaterialLineItems;