import React, { Component } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Helmet } from 'react-helmet';
import Toast from 'helpers/toaster';

@inject("store", "viewStore", "userStore")
@observer
class ChangePassword extends Component {
  @observable user = {
    current_password: '',
    new_password: '',
    new_password_confirmation: ''
  }

  handlePasswordChange(event) {
    this.user.new_password = event.target.value
  }

  handlePasswordConfirmationChange(event) {
    this.user.new_password_confirmation = event.target.value
  }

  handleCurrentPasswordChange(event) {
    this.user.current_password = event.target.value
  }

  changePassword() {
    const {
      props: {
        viewStore,
        viewStore: { currentUser },
      }
    } = this;
    var user_id = currentUser.id;

    if(!this.user.current_password) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter your current password.', intent: Intent.DANGER });
      return;
    }

    if(!this.user.new_password) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter your new password.', intent: Intent.DANGER });
      return;
    }

    if(this.user.new_password.length < 8) {
      Toast.show({ icon: 'warning-sign', message: 'Your new password must be atleast 8 character.', intent: Intent.DANGER });
      return;
    }

    if(this.user.new_password !== this.user.new_password_confirmation) {
      Toast.show({ icon: 'warning-sign', message: "Your new password & new password confirmation must match", intent: Intent.DANGER });
      return;
    }

    viewStore.performPasswordChange(this.user, user_id)
      .then((response) => {
        Toast.show({ icon: 'thumbs-up', message: 'Your password has been changed. Please log in with new password to continue.', intent: Intent.SUCCESS })
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
          Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ icon: 'warning-sign', message: 'Something went wrong, unable to change password.', intent: Intent.DANGER })
        }
      })
  }

  render() {
    const {
      props: {
        viewStore,
        viewStore: { 
          currentUser,
          performingChangePassword
         },
        store
      }
    } = this;
    return (
      <div>
        <Helmet>
          <title>Change Password | FieldNexus</title>
        </Helmet>
        <div className="page-wrap responsive-card-password-change">
          <div className="cp-wrap">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <label for="current_password" class="form-label">Current Password *</label>
                    <input
                      type="password"
                      placeholder="Current Password"
                      name="current_password"
                      className="form-control mb-2"
                      value={this.user.current_password}
                      onChange={this.handleCurrentPasswordChange.bind(this)}
                    />
                  </div>
                  <div className="col-md-4">
                    <label for="new_password" class="form-label">New Password *</label>
                    <input
                      type="password"
                      placeholder="Your New Password"
                      name="new_password"
                      className="form-control mb-2"
                      value={this.user.new_password}
                      onChange={this.handlePasswordChange.bind(this)}
                    />
                  </div>
                  <div className="col-md-4">
                    <label for="new_password_confirmation" class="form-label">New Password Confirmation *</label>
                    <input
                      type="password"
                      placeholder="Re-type Your New Password"
                      name="new_password_confirmation"
                      className="form-control mb-2"
                      value={this.user.new_password_confirmation}
                      onChange={this.handlePasswordConfirmationChange.bind(this)}
                    />
                  </div>
                  <div className="col-md-4 mt-2">
                    <Button icon="key" text="Change Password" className="bp3-intent-primary" large={true} onClick={this.changePassword.bind(this)} loading={performingChangePassword} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
