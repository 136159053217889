import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Button, Dialog, Classes, Spinner, Icon, Alert, Intent
} from "@blueprintjs/core";
import styled from '@emotion/styled';
import XeroCustomer from './XeroCustomer';
import { observable } from "mobx";
import Toast from "helpers/toaster";
// import { DebounceInput } from 'react-debounce-input';

const XeroCustomersWrapper = styled('div')`
  padding: 5px;
  background-color: #FFFFFF;
  height: 350px;
  overflow: auto;
  box-shadow: 0 0 0 1px rgba(16,22,26,0.1), 0 0 0 rgba(16,22,26,0), 0 1px 1px rgba(16,22,26,0.2);
`

@inject('invoiceStore','xeroStore') @observer
class SyncWithXeroModal extends Component {
  @observable isCreateNewCustomer = false;

  handleCloseDailog() {
    this.props.onClose();
  }

  handleCreateNeCustomerCancel() {
    this.isCreateNewCustomer = false
  }

  createXeroCustomer() {
    this.isCreateNewCustomer = true
  }

  handleSaveConfirm() {
    this.isCreateNewCustomer = false
    this.props.xeroStore.createXeroCustomer()
      .then((response) => {
        this.props.onSuccess(response.data);
      })
      .catch((error) => {
        if(error.response && error.response.data && error.response.data.auth) {
          Toast.show({
            icon: "warning-sign",
            message: error.response.data.auth,
            intent: Intent.DANGER
          });
        } else {
          Toast.show({
            icon: "warning-sign",
            message: "Something went wrong. Try to reconnect with Xero from account setup",
            intent: Intent.DANGER
          });
        }
      })
  }

  onChangeSearchText(event) {
    let value = event.target.value;
    this.props.quickbooksStore.searchQuickbooksCustomers(value)
  }

  onSuccessfulLinkCustomer() {
    this.props.onSuccess(null)
  }

  render() {
    let {
      invoiceStore: {
        invoice: {
          object
        }
      },
      xeroStore: {
        isLoadingCreateXeroCustomer,
        xeroCustomers: {
          isLoading,
          objects
        }
      }
    } = this.props
    let client = object.attributes.client;
    return(
      <React.Fragment>
        <Dialog
          title="Sync Client With Xero"
          isOpen={this.props.isOpen}
          isCloseButtonShown={true}
          canOutsideClickClose={false}
          onClose={this.handleCloseDailog.bind(this)}
          style={{width: '62%'}}
          className="quickbooks-dialog"
        >
          <div className={Classes.DIALOG_BODY}>
            {client && !client.synced_with_xero && 
              <div className="row">
                <div className="col col-md-5 dialog-left-side">
                  <div className="qb-red-color">No customer found for &quot;{client && client.name}&quot;</div>
                  <div className="dark-text" style={{paddingTop: 5, marginBottom: 10}}>Please create a new customer on QuickBooks or Select from the list to link with existing customer.</div>
                  
                  <div className="dark-text"><b>{client && client.name}</b></div>
                  {client && client.phone && <div className="dark-text"><Icon icon="phone" iconSize={12} /> {client.phone}</div>}
                  {client && client.email && <div className="dark-text"><Icon icon="envelope" iconSize={12} /> {client.email}</div>}
                  {object.attributes && object.attributes.location && object.attributes.location.full_address && <div className="dark-text"><Icon icon="map-marker" iconSize={12} /> {object.attributes.location.full_address}</div>}
                  <br/>
                  <Button
                    onClick={this.createXeroCustomer.bind(this)}
                    loading={isLoadingCreateXeroCustomer}
                    className="connect-qb"
                  >
                    <img className="connect-btn" width="9%" src="/xero_icon.svg" alt="" />
                    Create New Customer in Xero
                  </Button>
                </div>
                <div className="col col-md-7 dialog-right-side">
                  {/* <div className="qb-search">
                    <div className="bp3-input-group">
                      <span className="qb-search-icon">
                        <img src="/qb-search-icon.svg" />
                      </span>
                      <DebounceInput
                        minLength={2}
                        debounceTimeout={700}
                        className="bp3-input" 
                        type="search" 
                        placeholder="Search by company name" 
                        onChange={(event) => this.onChangeSearchText(event)}
                      />
                    </div>
                  </div> */}
                  <XeroCustomersWrapper>
                    <div style={{margin: 'auto', textAlign: 'center'}}>
                      {(isLoading) && <Spinner size={Spinner.SIZE_STANDARD} />}
                    </div>
                    {objects && objects.length > 0 && objects.map((customer) => {
                      return(  
                        <XeroCustomer customer={customer} onSuccess={this.onSuccessfulLinkCustomer.bind(this)} key={Math.random()} />
                      )
                    })}
                    {!isLoading && objects && objects.length === 0 && 
                      <div className="qb-red-color" style={{padding: 15}}>
                        No Customer found. Try to search with another keyword or Create new one or Try to reconnect with Xero from account setup.
                      </div>}
                  </XeroCustomersWrapper>
                </div>
              </div>
            }
          </div>
        </Dialog>
        <Alert
          cancelButtonText="No"
          confirmButtonText="Yes"
          icon="help"
          intent={Intent.PRIMARY}
          isOpen={this.isCreateNewCustomer}
          onCancel={this.handleCreateNeCustomerCancel.bind(this)}
          onConfirm={this.handleSaveConfirm.bind(this)}          
        >
            Are you sure you want to create new customer in Xero?
            <div className="dark-text" style={{paddingTop: 5}}><b>{client && client.name}</b></div>
            {client && client.phone && <div><Icon icon="phone" iconSize={12} style={{marginRight: 5}} />{client.phone}</div>}
            {client && client.email && <div><Icon icon="envelope" iconSize={12} style={{marginRight: 5}} />{client.email}</div>}
            {object.attributes && object.attributes.location && object.attributes.location.full_address && <div className="dark-text"><Icon icon="map-marker" iconSize={12} style={{marginRight: 5}} />{object.attributes.location.full_address}</div>}        
        </Alert> 
      </React.Fragment>
    )
  }

}

export default SyncWithXeroModal;