import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import Card from 'components/Card';
import { 
  ButtonGroup, Button, NonIdealState, Spinner
} from "@blueprintjs/core";
import ServiceTypeCategoryTable from 'components/Tables/ServiceTypeCategory';
import BookingsSidebar from '../bookings/bookings-sidebar';
import ServiceTypeCategory from 'components/ServiceTypeCategory/ServiceTypeCategory';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'serviceTypeCategoryStore',) @observer
class ServiceTypeCategoryIndexPage extends Component {

  @observable isServiceTypeCategoryDialogVisible = false;

  componentDidMount() {
    let { serviceTypeCategoryStore } = this.props;
    serviceTypeCategoryStore.loadServiceTypeCategories()
  }

  get canRender() {
    let {
      serviceTypeCategoryStore: {
        serviceTypeCategories: {
          isLoading,
          isError,
          objects
        }
      }
    } = this.props;
    return (!isLoading && !isError && objects);
  }

  onCloseCategoryDialog() {
    this.isServiceTypeCategoryDialogVisible = false;
  }

  onSucessCreateCategory(productService) {
    this.isServiceTypeCategoryDialogVisible = false;
  }

  render() {
    let content;
    let {
      serviceTypeCategoryStore: {
        serviceTypeCategories: {
          isLoading,
          isError
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      content = <ServiceTypeCategoryTable />;
    }
    return(
      <div className="taxed-page-layout">
        <Helmet>
          <title>Service Type Category | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <BookingsSidebar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
              <Card>
                <div>            
                  <div className="text-right mb-2">
                    <ButtonGroup>
                      <Button icon='add' onClick={() => this.isServiceTypeCategoryDialogVisible = true }>
                        Add
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
                {content}
              </Card>
            </PageWrapGray>
          </div>
        </div>
        <ServiceTypeCategory 
          isOpen={this.isServiceTypeCategoryDialogVisible} 
          title={"Add Service Type Category"}
          edit={false}
          onClose={this.onCloseCategoryDialog.bind(this)}
          onSuccess={this.onSucessCreateCategory.bind(this)}
        />
      </div>
    )
  }

}

export default ServiceTypeCategoryIndexPage;