import React from 'react';
import { Spinner, Tag } from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';

@inject('viewStore','serviceAgreementStore') @observer
class ServiceAgreementsTable extends React.Component {

  @observable service_agreements = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    this.props.viewStore.clientServiceAgreementSearch.where.client_id = this.props.client.attributes.id;
    this.props.serviceAgreementStore.loadClientServiceAgreements();
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        clientServiceAgreementSearch
      }
    } = this.props;
    this.props.viewStore.clientServiceAgreementSearch = {
      ...clientServiceAgreementSearch,
      page: newPage
    };
    this.props.serviceAgreementStore.loadClientServiceAgreements();
  }

  render() {
    
    let { 
      serviceAgreementStore: {
        clientServiceAgreements: {
          objects, isLoading, meta 
        }
      }
    } = this.props;
    let { viewStore } = this.props;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning bp3-icon-info-sign table-card">
          No service agreements have been added for this client yet.
        </div>
      )
    }
    return (
      <div className="card rounded-0 table-responsive">
        <table className='table' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Number</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Title</th>
              <th>Reason for Call</th>
              {/* <th>Client</th> */}
              <th>Client Location</th>
              <th>Service Type</th>
              <th>Status</th>            
            </tr>
          </thead>
          <tbody>
            {
              objects.map((service_agreement) => {
                return (
                  <tr key={service_agreement.id}>
                    <td>
                      <a 
                        href='' 
                        onClick={() => { viewStore.serviceAgreementShow({ uuid: service_agreement.attributes.id }, service_agreement.attributes.number) }} 
                      >
                        {service_agreement.attributes.number}
                      </a>
                    </td>
                    <td>
                      {service_agreement.attributes.formatted_start_time}
                    </td>
                    <td>
                      {service_agreement.attributes.formatted_end_time}                      
                    </td>
                    <td>
                      {service_agreement.attributes.title}
                    </td>
                    <td>              
                      {service_agreement.attributes.reason_for_call}
                    </td>
                    {/* <td>
                      <a 
                        href='' 
                        onClick={() => { viewStore.clientShow({ uuid: service_agreement.attributes.client_id }) }} 
                      >
                        {service_agreement.attributes.client && service_agreement.attributes.client.legal_name}
                      </a>
                    </td> */}
                    <td>
                      {service_agreement.attributes.location && service_agreement.attributes.location.full_address}
                    </td>
                    <td>
                      <Tag>
                        {service_agreement.attributes.service_type && service_agreement.attributes.service_type.name}
                      </Tag>
                    </td>
                    <td>
                      <Tag>
                        {service_agreement.attributes.status}
                      </Tag>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </div>
    )
  }
}

export default ServiceAgreementsTable;