import React from 'react'
import { Button, Popover, Position, Tag, Spinner } from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer, inject } from "mobx-react";
import ListPicker from 'components/ListPicker';
import PropTypes from 'prop-types';
import { Api } from 'services/Api';

@observer
class ServiceTypeCategoryPicker extends React.Component {

  @observable loading = true;
  @observable failed = false;
  @observable options = [];
  @observable selectedOptions = [];

  componentDidMount() {
    if(this.props.selectedOptions) {
      this.selectedOptions = this.props.selectedOptions;
    }
  }

  @action
  onServiceTypeCategoryLoad(response) {
    this.options = response.data.data.map((category) => 
      ({
        label: category.attributes.name,
        value: category.attributes.id,
        selected: this.selectedOptions.filter((selectedOption) => category.attributes.id === selectedOption.value).length > 0 ? true : false
      })
    );
    this.failed = false;
    this.loading = false;
  }

  @action
  onServiceTypeCategoryLoadFail(error) {
    this.loading = false;
    this.failed = true;
  }

  loadServiceTypeCategories() {
    let promise = Api.post('/service_type_category/search', { 
      search: { 
        term: '*',
      }
    });
    promise
      .then(this.onServiceTypeCategoryLoad.bind(this))
      .catch(this.onServiceTypeCategoryLoadFail.bind(this))
    return promise;
  }

  onChange = (selectedOptions) => {
    this.selectedOptions = selectedOptions;
    if(this.props.onChange){
      this.props.onChange(selectedOptions, this.options);
    }
  }

  render() {
    let {
      onChange,
      props: {
        customindex,
        maximumSelections
      }
    } = this;
    let content = '';
   
    
    if (this.failed) {
      content = (<div className="bp3-callout bp3-intent-danger bp3-icon-info-sign">
        Had some problem loading the Service Types Category. Please close and try again!
      </div>)
    }
    if (!this.failed) {
      if (this.loading) {
        content = <div><Spinner /></div>;
      }
      if (!this.loading && this.options.length > 0) {
        content = <ListPicker
                    selectedOptions={this.selectedOptions}
                    options={this.options}
                    onChange={onChange}
                    maximumSelections={maximumSelections}
                  />
      }
    }
    return(
      <Popover
        minimal={true}
        onOpening={this.loadServiceTypeCategories.bind(this)}
        content={content}
        position={Position.BOTTOM_LEFT}
        usePortal={true} 
      >
        <Button rightIcon='caret-down'>
          Service Type Category
          <span>&nbsp;</span>
          {
            this.selectedOptions.length === 1 &&
              <Tag minimal={true} round={true}>{this.selectedOptions[0].label}</Tag>
          }
          {
            (this.selectedOptions.length > 1 || this.selectedOptions.length === 0)  &&
              <Tag minimal={true} round={true}>{this.selectedOptions.length || 0}</Tag>
          }
          
        </Button>
      </Popover>
    )
  }
}

ServiceTypeCategoryPicker.propTypes = {
  selectedOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func,
  maximumSelections: PropTypes.number
}

export default ServiceTypeCategoryPicker;