import { observable, action , toJS} from 'mobx';
import { Api } from 'services/Api';
import qs from 'qs';
import stores from './index';
import moment from 'moment';
import Lockr from 'lockr';


const EMPTY_BOOKING_OBJECT = {
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  address: null,
  suite: null,
  city: null,
  state: '',
  zip: null,
  service_type_id: null,
  service_type_name: '',
  service_type_price:'',
  total:'',
  extra_service_types: [],
  pricing_parameters: [],
  pricing_parameter_ranges: [],
  booking_pricing_parameters_attributes: [],
  booking_service_type_extras_attributes:[],
  start_date: '',
  selected_time: null,
  frequency_id: 0,
  payment_method: null,
  note: null
}

class PreferenceStore {

  @observable showLoader = false;

  @observable booking = {
    isLoading: false,
    isError: false,
    object: {}
  };

  @observable bookingDetail = {
    isLoading: false,
    isError: false,
    object: {}
  };

  @observable preference = {
    isLoading: false,
    isError: false,
    object: null
  };

  @observable tenant = {
    isLoading: false,
    isError: false,
    object: null
  };

  @observable countries = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable states = {
    isLoading: false,
    isError: false,
    objects: null
  };

  @action
  loadTenant() {
    this.tenant.isLoading = true;
    let promise = Api.get(`/preferences/tenant`);

    promise
      .then((response) => {
        this.tenant.isLoading = false;
        this.tenant.isError = false;
        this.tenant.object = response.data.data;
        return this.tenant.object;
      })
      .catch((error) => {
        this.tenant.isLoading = false;
        this.tenant.isError = true;
      })

    return promise;
  }


  @action
  loadCountries() {
    this.countries.isLoading = true;
    let promise = Api.get('/countries');
    return promise
      .then((response) => {        
        this.countries.isLoading = false;
        this.countries.isError = false;
        this.countries.objects = response.data.data;
        this.countries.meta = response.data.meta;
        return this.countries.objects;
      })
      .catch((error) => {
        this.countries.isLoading = false;
        this.countries.isError = true;
      })    
  }

  @action
  loadStates() {
    this.states.isLoading = true;
    
    let promise = Api.post('/states/search', { 
      search: window.stores.viewStore.stateSearch
    });
    return promise
      .then((response) => {        
        this.states.isLoading = false;
        this.states.isError = false;
        this.states.objects = response.data.data;
        this.states.meta = response.data.meta;
        return this.states.objects;
      })
      .catch((error) => {
        this.states.isLoading = false;
        this.states.isError = true;
      })    
  }


  @action
  loadState(country_id) {
    if(country_id){
      country_id = country_id;
    }else{
      country_id = 233;
    }
    this.states.isLoading = true;
    
    let promise = Api.get(`/states/show?country_id=${country_id}`);
    promise
      .then((response) => {        
        this.states.isLoading = false;
        this.states.isError = false;
        this.states.objects = response.data.data;
        this.states.meta = response.data.meta;
      })
      .catch((error) => {
        this.states.isLoading = false;
        this.states.isError = true;
      })    
  }

  @action
  updateTenant(tenant) {
    this.tenant.isLoading = true;
    let promise = Api.put(`/tenants/${tenant.attributes.id}`, {tenant: toJS(tenant.attributes)})

    promise
      .then((response) => {
        this.tenant.isLoading = false;
        this.tenant.isError = false;
        Lockr.set('currentTenant', response.data.data.attributes);
        window.stores.viewStore.reloadUser();
        stores.viewStore.preferences();
      })
      .catch((error) => {
        this.tenant.isLoading = false;
        this.tenant.isError = true;
      })

    return promise;
  }

  @action
  deleteDefaultLineItem(line_item) {
    this.showLoader = true;
    let promise = Api.delete(`/preferences/${line_item.id}/delete_line_item`)

    promise
      .then((response) => {
        this.showLoader = false;
        this.preference.object = response.data.data
      })
      .catch((error) => {
        this.showLoader = false;
      })

    return promise;
  }

  @action
  updateDefaultLineItem(line_item) {
    this.showLoader = true;
    let promise = Api.put(`/preferences/update_line_item`, {line_item: line_item})

    promise
      .then((response) => {
        this.showLoader = false;
        this.preference.object = response.data.data
      })
      .catch((error) => {
        this.showLoader = false;
      })

    return promise;
  }

  @action
  addBlankLineItem() {
    this.showLoader = true;
    let lineItem = {
      preference_id: this.preference.object.id,
      kind: 'custom'
    }

    let promise = Api.post(`/preferences/blank_line_item`, {line_item: lineItem})

    promise
      .then((response) => {
        this.showLoader = false;
        this.preference.object = response.data.data;
      })
      .catch((error) => {
        this.showLoader = false;
      })
  }

  @action
  addDefaultBlankLineItem() {
    this.showLoader = true;
    let lineItem = {
      preference_id: this.preference.object.id,
      kind: 'custom'
    }

    let promise = Api.post(`/preferences/blank_line_item`, {line_item: lineItem})

    promise
      .then((response) => {
        this.showLoader = false;
        this.preference.object = response.data.data;
      })
      .catch((error) => {
        this.showLoader = false;
      })
    return promise;
  }

  @action
  loadPreferences() {
    this.preference.isLoading = true;
    let promise = Api.get(`/preferences`)

    promise
      .then((response) => {
        this.preference.isLoading = false;
        this.preference.isError = false;
        this.preference.object = response.data.data
      })
      .catch((error) => {
        this.preference.isLoading = false;
        this.preference.isError = true;
      })
  }

  @action 
  updatePreference() {
    this.preference.isLoading = true;
    let promise = Api.put(`/preferences/${this.preference.object.id}`, {preference: this.preference.object.attributes})

    promise
      .then((response) => {
        this.preference.isLoading = false;
        this.preference.isError = false;
        this.preference.object = response.data.data
      })
      .catch((error) => {
        this.preference.isLoading = false;
        this.preference.isError = true;
      })

    return promise;
  }

  

  
  // For public link Actions starts
  @action
  createPublicBooking() {
    this.booking.isLoading = true;
    let promise = Api.post(`/public/bookings/?x_tenant_id=${stores.preferenceStore.tenant_id}`, {booking: this.booking.object})   

    promise
      .then((response) => {
        this.booking.isLoading = false;
        this.booking.isError = false; 
        this.booking.object = response.data.data      
        stores.viewStore.bookingConfirmationMessage(); 
      })
      .catch((error) => {
        this.booking.isLoading = false;
        this.booking.isError = true;       
      })

    return promise;
  }
  // For public link Actions ends

};

export default PreferenceStore;