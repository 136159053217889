import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  TextArea, Label
} from '@blueprintjs/core';

@inject('store', 'workReportStore') @observer
class WorkReportDescription extends Component {
  render() {
    const {
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
    return (
      <React.Fragment>
        <div className="reason-description" style={{ padding: '10px'}}>
          {/* <div className="table-title"><h5><strong>Details</strong></h5></div> */}
            <div className="d-flex flex-wrap">
                {/* <div className="pr-md-2" style={{minWidth:300}}>
                  <Label className="font-weight-bold">
                    PO No.
                    <input
                      type='text'
                      className='bp3-input bp3-fill mb-2'
                      placeholder='PO No.'
                      defaultValue={object.attributes.po_number}
                      onChange={(event) => { object.attributes.po_number = event.target.value }}
                    />
                  </Label>
                </div> */}
                <div className="job-reason-block">
                  {/* <Label className="font-weight-bold"> Reason for call
                    <input
                      type='text'
                      className='bp3-input bp3-fill mb-2'
                      placeholder='Reason for call'
                      defaultValue={object.attributes.job.title}
                      onChange={(event) => { object.attributes.job.title = event.target.value }}
                      readOnly={true}
                    />
                  </Label> */}
                  <Label className="font-weight-bold"> Description
                    <TextArea
                      style={{minHeight:100}}
                      className="description-textarea bp3-fill"
                      growVertically={false}
                      large={true}
                      defaultValue={object.attributes.description}
                      onChange={(event) => { object.attributes.description = event.target.value }}
                    />
                </Label>
                </div>
            </div>
          {/* <div className="reason-for-call">
            <div className="details-1">
              <div className="po-number"><strong>PO# :</strong> <span>{object.attributes.po_number}</span></div>
            </div>
          </div>
          <div className="reason-for-call">
            <div className="details-1">
              <strong className="label">Reason for Call</strong>
              <div>{object.attributes.job.title}</div>
            </div>
          </div> */}
        </div>

        {/* Job Description: */}
        {/* <div className="job-desription" style={{ padding: '10px', marginBottom: '1rem' }}>
          <h6>
            <strong className="field-label">Work Description</strong>
          </h6>
          <TextArea
            style={{ width: '100%', minHeight: '130px' }}
            className="description-textarea"
            growVertically={false}
            large={true}
            defaultValue={object.attributes.description}
            onChange={(event) => {object.attributes.description = event.target.value}}
          />
        </div> */}
      </React.Fragment>
    )
  }
}
export default WorkReportDescription;