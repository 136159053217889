import React from 'react'
import { Button, Popover, Position, Tag, Spinner } from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import ListPicker from 'components/ListPicker';
import PropTypes from 'prop-types';
import { Api } from 'services/Api';


@inject('store','viewStore') @observer
class JobPicker extends React.Component {

  @observable loading = true;
  @observable failed = false;
  @observable options = [];
  @observable selectedOptions = [];


  componentDidMount() {
    if(this.props.selectedOptions) {
      this.selectedOptions = this.props.selectedOptions;
    }
  }

  @action
  onJobLoad(response) {
    this.options = response.data.data.map((job) => 
      ({
        label: `(${job.attributes.event_number}) ${job.attributes.title}`,
        value: job.attributes.job.id,
        selected: this.selectedOptions.filter((selectedOption) => job.attributes.id === selectedOption.value).length > 0 ? true : false
      })
    );
    this.failed = false;
    this.loading = false;
  }

  @action
  onJobLoadFail(error) {
    this.loading = false;
    this.failed = true;
  }

  loadJobs() {
    let promise = Api.post('/assignments/search', { search: this.props.viewStore.jobSearch
    });
    promise
      .then(this.onJobLoad.bind(this))
      .catch(this.onJobLoadFail.bind(this))
    return promise;
  }

  onChange = (selectedOptions) => {
    this.selectedOptions = selectedOptions;
    if(this.props.onChange){
      this.props.onChange(selectedOptions);
    }
  }

  render() {
    let {
      onChange,
      props: {
        maximumSelections
      }
    } = this;
    let content = '';
    
   
    if (this.failed) {
      content = (<div className="bp3-callout bp3-intent-danger bp3-icon-info-sign">
        Had some problem loading the jobs. Please close and try again!
      </div>)
    }
    if (!this.failed) {
      if (this.loading) {
        content = <div><Spinner /></div>;
      }
      if (!this.loading && this.options.length > 0) {
        console.log(this.selectedOptions)
        content = <ListPicker
                    selectedOptions={this.selectedOptions}
                    options={this.options}
                    onChange={onChange}
                    maximumSelections={maximumSelections}
                  />
      }
    }
    return(
      <Popover
        minimal={true}
        onOpening={this.loadJobs.bind(this)}
        content={content}
        position={Position.BOTTOM_LEFT}
        usePortal={true} 
      >
        <Button rightIcon='caret-down'>
          <span className="job-heading">Jobs</span>
          <span className="empty-span">&nbsp;</span>
          {
            this.selectedOptions.length === 1 &&
              <Tag minimal={true} round={true}>{this.selectedOptions[0].label}</Tag>
          }
          {
            (this.selectedOptions.length > 1 || this.selectedOptions.length === 0)  &&
              <Tag minimal={true} round={true}>{this.selectedOptions.length || 0}</Tag>
          }
          
        </Button>
      </Popover>
    )
  }
}

JobPicker.propTypes = {
  selectedOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func,
  maximumSelections: PropTypes.number
}

export default JobPicker;