import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { action } from 'mobx';
import SupplierAutocomplete from 'components/SupplierAutocomplete';
import { 
  Button
} from '@blueprintjs/core';

@inject('store', 'workReportStore', 'viewStore') @observer
class PurchaseOrders extends Component {

  @action
  onSupplierSelect(supplier) {
    let {
      props: {
        viewStore: {
          purchaseOrderItemSearch,
          purchaseOrderItemSearch: {
            where: {
              supplier_id,
              ...whereWithNoClientId
            }
          }
        }
      }
    } = this;
    if (supplier) { 
      this.props.viewStore.purchaseOrderItemSearch = {
        ...purchaseOrderItemSearch,
        where: {
          ...purchaseOrderItemSearch.where,
          supplier_id: supplier.id
        }
      }
    } else {
      this.props.viewStore.purchaseOrderItemSearch = {
        ...purchaseOrderItemSearch,
        where: {
          ...whereWithNoClientId,
          supplier_id: null
        }
      }
    }
  }

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.purchaseOrderItemSearch.page = 1;
    viewStore.PurchaseOrder();
    this.props.workReportStore.loadPurchaseOrders();
  }

  render() {
    let {
      viewStore: {
        purchaseOrderItemSearch: {
          where
        }
      },
      workReportStore: {
        purchaseOrderItem: {
          isLoading
        }
      }
    } = this.props;

    return(
      <nav className="bp3-navbar search bg-transparent navbar-overflow">
        <div>
          <div className="bp3-button-group">
            <div className="bp3-control-group">
              <SupplierAutocomplete
                selectedSupplierId={where && where.supplier_id}
                onSupplierSelect={this.onSupplierSelect.bind(this)}
              />
              <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} style={{height: 40}} loading={isLoading} />
            </div>
          </div>
        </div>
      </nav>
    )
  }

}

export default PurchaseOrders;