import { observable, action, toJS } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

class NotificationStore {
  @observable notifications = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };
  
  @action
  loadActivities() {
    this.notifications.isLoading = true;
    this.notifications.isError = false;
    let promise = Api.get(`/activity`);

    promise
      .then((response) => {
        this.notifications.isLoading = false;
        this.notifications.isError = false;
        this.notifications.objects = response.data.data;
      })
      .catch((error) => {
        this.notifications.isLoading = false;
        this.notifications.isError = true;
      })

    return promise;
  }

  @action
  loadNotifications() {
    this.notifications.isLoading = true;
    const {
      term,
      per_page,
      page,
      order,
      where
    } = stores.viewStore.notificationSearch;
    let promise = Api.post('/activity/search', 
      {
        search: {
          term: term,
          where: where,
          order: order,
          per_page: per_page,
          page: page
        }
      }
    );

    promise
      .then((response) => {
        this.notifications.isError = false;
        this.notifications.isLoading = false;
        this.notifications.objects = response.data.data;
        this.notifications.meta = response.data.meta;
      })
      .catch((error) => {
        this.notifications.isLoading = false;
        this.notifications.isError = true;
        this.notifications.objects = [];
      })
    
    return promise;
  }

}

export default NotificationStore;