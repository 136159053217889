import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  Divider,
  TextArea, Button,
  FormGroup, InputGroup, Intent,
  Spinner, Tab, Tabs, Icon, Checkbox
} from '@blueprintjs/core';

import Avatar from '@atlaskit/avatar';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Inputmask from 'inputmask';
import WorkOrdersTable from 'components/Client/WorkOrdersTable';
import QuotesTable from 'components/Client/QuotesTable';
import InvoicesTable from 'components/Client/InvoicesTable';
import ProjectsTable from 'components/Client/ProjectsTable';
import LocationsTable from 'components/Client/LocationsTable';
import ServiceAgreementsTable from 'components/Client/ServiceAgreementsTable';
import EquipmentsTable from 'components/Client/EquipmentsTable';
import ClientJobsTable from 'components/Client/JobsTable';
import AnalyticsCard from 'components/Common/AnalyticsCard';
import Toast from 'helpers/toaster';

const ClientGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: ${props => `${props.theme.unit*4}px`};
  align-items: flex-start;
`

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color: #ffffff;
  border: 1px solid #e6e8f1;
`

const AnalyticsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
@inject('clientStore') @observer
class ClientProfileCard extends Component {

  componentDidMount() {
    let { client } = this.props;
    Inputmask({ mask: '999-999-9999[999]', skipOptionalPartCharacter: " ", clearMaskOnLostFocus: true, greedy: false }).mask('#phone');
    Inputmask({ alias: 'email' }).mask('#email');

    this.props.clientStore.loadAnalytics({uuid: client.attributes.id})
  }

  changeTableData = (resource) => {
    if(resource){
      this.props.onResourceChange(resource);
    }
  }

  resourceData = () => {
    let { client, selectedResource } = this.props;
    if(selectedResource === 'jobs'){
      return(
        <React.Fragment>
          {/* <TableHeading heading="JOBS" /> */}
          <ClientJobsTable client={client} />
        </React.Fragment>
      )
    }else if(selectedResource === 'work_orders'){
      return(
        <React.Fragment>
          {/* <TableHeading heading="WORK ORDERS" /> */}
          <WorkOrdersTable client={client} />
        </React.Fragment>
      )
    }else if(selectedResource === 'equipments'){
      return(
        <React.Fragment>
          {/* <TableHeading heading="EQUIPMENTS" /> */}
          <EquipmentsTable client={client} />
        </React.Fragment>
      )
    }else if(selectedResource === 'estimates'){
      return(
        <React.Fragment>
          {/* <TableHeading heading="ESTIMATES" /> */}
          <QuotesTable client={client} />
        </React.Fragment>
      )
    }else if(selectedResource === 'invoices'){
      return(
        <React.Fragment>
          <InvoicesTable client={client} />
        </React.Fragment>
      )
    }else if(selectedResource === 'projects'){
      return(
        <React.Fragment>
          <ProjectsTable client={client} />
        </React.Fragment>
      )
    }else if(selectedResource === 'locations'){
      return(
        <React.Fragment>
          <LocationsTable client={client} />
        </React.Fragment>
      )
    }else if(selectedResource === 'service_agreements'){
      return(
        <React.Fragment>
          <ServiceAgreementsTable client={client} />
        </React.Fragment>
      )
    }
  }

  handleClientChange(event, key) {
    this.props.client.attributes[key] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
  }

  updateClient() {
    if(this.props.client.attributes.name === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    if(this.props.client.attributes.legal_name === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid legal name!', intent: Intent.DANGER })
      return;
    }
    
    if(this.props.client.attributes.phone === '') {
      Toast.show({ icon: 'warning-sign', message: 'Please enter phone!', intent: Intent.DANGER })
      return;
    }

    if(this.props.client.attributes.email === "") {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid email!', intent: Intent.DANGER })
      return;
    }

    this.props.clientStore.updateClient(this.props.client)
      .then(this.onSuccessfullUpdateClient.bind(this))
      .catch(this.onFailClientUpdate.bind(this))
  }

  onSuccessfullUpdateClient(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Client updated successfully.', intent: Intent.SUCCESS })
  }

  onFailClientUpdate(error) {
    if(error.response && error.response.data && error.response.data.error) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.error, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong. Failed to update client.', intent: Intent.DANGER })
    }
  }

  render() {
    let { 
      client, 
      selectedResource,
      clientStore: {
        client: {
          isLoading
        },
        clientAnalytics
      }
    } = this.props;
    let nameForAvatar = client.attributes.name.replace(/'/, '').replace(/\(/, '').replace(/\)/, '');
    return(
      <div className="profile-card-grid">
      <ClientGrid>
        <Card>
          <CardContent>
            <div style={{ textAlign: 'center', paddingBottom: '8px' }}>
              <Avatar
                borderColor={'#CCCED2'}
                src={encodeURI(`https://ui-avatars.com/api/?name=${nameForAvatar}&size=256`)}
                size='xlarge'
              />
              <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                {client.attributes.name}
              </h4>
              <h6 className={'bp3-heading bp3-text-muted'} style={{display:'inline-flex', alignItems:'center'}}>
                  <Icon icon="phone" iconSize={10} style={{width:'20px', height:'20px', backgroundColor:'#eee', borderRadius:'100%', display:'inline-flex', alignItems:'center', justifyContent:'center', marginRight:'5px'}} /> {client.attributes.phone}
              </h6>
                <h6 className={'bp3-heading bp3-text-muted'} style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Icon icon="envelope" iconSize={10} style={{ width: '20px', height: '20px', backgroundColor: '#eee', borderRadius: '100%', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px' }} /> {client.attributes.email}
                </h6>
            </div>
            <Divider style={{ margin: '16px 0px' }}></Divider>
            <FormGroup label="Name (*)">
              <InputGroup placeholder="Customer Name" defaultValue={client.attributes.name}  onChange={(event) => { this.handleClientChange(event, 'name') }} />
            </FormGroup>

            <FormGroup label="Legal Name (*)">
              <InputGroup placeholder="Legal Name" defaultValue={client.attributes.legal_name} onChange={(event) => { this.handleClientChange(event, 'legal_name') }} />
            </FormGroup>

            <FormGroup label="Email (*)">
              <InputGroup placeholder="Email" defaultValue={client.attributes.email} id='email' onChange={(event) => { this.handleClientChange(event, 'email') }} />
            </FormGroup>

            <FormGroup label="Phone (*)">
              <InputGroup placeholder="Phone" defaultValue={client.attributes.phone} id='phone' onChange={(event) => { this.handleClientChange(event, 'phone') }} />
            </FormGroup>

            <FormGroup label="Fax">
              <InputGroup placeholder="Fax" defaultValue={client.attributes.fax} onChange={(event) => { this.handleClientChange(event, 'fax') }} />
            </FormGroup>

            <FormGroup
              label="Terms"
              labelFor="text-input"
            >
              <div className="bp3-select bp3-fill">
                <select defaultValue={client.attributes.terms} onChange={(event) => { this.handleClientChange(event, 'terms') }}>
                  <option value=''>Select Terms</option>
                  <option value='Net 15'>Net 15</option>
                  <option value='Net 30'>Net 30</option>
                  <option value='Net 45'>Net 45</option>
                  <option value='Net 60'>Net 60</option>
                  <option value='Upon Completion'>Upon Completion</option>
                </select>
              </div>
            </FormGroup>

            <FormGroup
              label="Invoice To"
              labelFor="text-input"
            >
              <TextArea 
                type='text' 
                className='bp3-input bp3-fill' 
                placeholder='Invoice To'
                defaultValue={client.attributes.invoice_to}
                onChange={(event) => { this.handleClientChange(event, 'invoice_to') }} 
              />
            </FormGroup>

            <FormGroup
              label="Ship To"
              labelFor="text-input"
            >
              <TextArea 
                type='text' 
                className='bp3-input bp3-fill' 
                placeholder='Ship To'
                defaultValue={client.attributes.ship_to}
                onChange={(event) => { this.handleClientChange(event, 'ship_to') }} 
              />
            </FormGroup>

            <FormGroup label="Description">
              <TextArea
                fill={true}
                defaultValue={client.attributes.description}
                onChange={(event) => { this.handleClientChange(event, 'description') }}
              />
            </FormGroup>

            <FormGroup label="Invoice due within days">
              <div className="bp3-select bp3-fill">
                <select defaultValue={client.attributes.invoice_due_within_days} onChange={(event) => { this.handleClientChange(event, 'invoice_due_within_days') }}>
                  <option value=''>Select Days</option>
                  <option value='7'>7 Days</option>
                  <option value='15'>15 Days</option>
                  <option value='30'>30 Days</option>
                  <option value='45'>45 Days</option>
                  <option value='60'>60 Days</option>
                  <option value='75'>75 Days</option>
                  <option value='90'>90 Days</option>
                  <option value='105'>105 Days</option>
                  <option value='120'>120 Days</option>
                </select>
              </div>
            </FormGroup>
            <div className="client-service-wrapper">
              <FormGroup label="Do not service this client" className="service-label-header" inline>
                <Checkbox 
                  checked={client.attributes.do_not_service} 
                  onChange={(event) => { this.handleClientChange(event, 'do_not_service') }} 
                />
              </FormGroup>
              {client.attributes.do_not_service && (
                <FormGroup className="clients-message">
                  <TextArea
                    placeholder='Enter reason'
                    fill={true}
                    defaultValue={client.attributes.do_not_service_reason}
                    onChange={(event) => { this.handleClientChange(event, 'do_not_service_reason') }} />
                </FormGroup>
              )}
            </div>
            <div className="client-service-wrapper">
              <FormGroup label="Alert when creating a Job" className="service-label-header" inline>
                <Checkbox 
                  checked={client.attributes.is_on_alert} 
                  onChange={(event) => { this.handleClientChange(event, 'is_on_alert') }} 
                />
              </FormGroup>
              {client.attributes.is_on_alert && (
                <FormGroup className="clients-message">
                  <TextArea
                    placeholder='Enter reason'
                    fill={true}
                    defaultValue={client.attributes.on_alert_reason}
                    onChange={(event) => { this.handleClientChange(event, 'on_alert_reason') }} />
                </FormGroup>
              )}
            </div> 
            <Button icon="floppy-disk" text="Update" onClick={this.updateClient.bind(this)} loading={isLoading} />
          </CardContent>
        </Card>
        
        <div className="responsive-layout-analytics-card">
          <AnalyticsContainer>
            <div className="analytics-card-warper">
            {
              clientAnalytics.isLoading && 
                <Spinner size='25' />
            }
            {
              clientAnalytics.objects.map((analytics) => {
                return (
                  <AnalyticsCard
                    key={Math.random()}
                    cardTitle={analytics.name}
                    cardId={client.attributes.id}
                    count={analytics.total_count}
                    active={selectedResource === 'jobs'}
                    breakdown={analytics.breakdown || []}
                  />
                )
              })
            }
            </div>
          </AnalyticsContainer>
          <div className="table-responsive">
              <Tabs id="ClientRecords" onChange={this.changeTableData.bind(this)} selectedTabId={selectedResource}>
                {
                  clientAnalytics.objects && clientAnalytics.objects.length > 0 && clientAnalytics.objects.map((analytic) => {
                    return(
                      <Tab 
                        id={analytic.id} 
                        title={analytic.name} 
                        key={Math.random()}
                      />
                    )
                  })
                }
              </Tabs>
              <div style={{minHeight: 20}}></div>
              { this.resourceData() }
            </div>
        </div>
        </ClientGrid>
      </div>
    )
  }
}

export default ClientProfileCard;