import React from 'react';
import Avatar from '@atlaskit/avatar';
import {
  Icon, Spinner, Button, Divider
} from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import qs from 'qs';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import { formatTime } from '../../helpers/time';

@inject('viewStore', 'quoteStore')  @observer
class QuotesTable extends React.Component {

  @observable quotes = {
    isLoading: true,
    objects: []
  }

  componentDidMount() {
    this.props.viewStore.clientQuoteSearch.where.client_id = this.props.client.attributes.id;
    this.props.quoteStore.loadClientQuotes();
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        clientQuoteSearch
      }
    } = this.props;
    this.props.viewStore.clientQuoteSearch = {
      ...clientQuoteSearch,
      page: newPage
    };
    this.props.quoteStore.loadClientQuotes();
  }

  render() {
    let { 
      quoteStore: {
        clientQuotes: {
          objects, isLoading, meta 
        }
      },
      viewStore: {
        jobTab: {
          section
        }
      },
    } = this.props;
    let { viewStore } = this.props;
    if (isLoading) {
      return <Spinner size='25' />
    }
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No estimates have been created for this client yet.
        </div>
      )
    }
    return (
      <div className="card rounded-0 table-responsive">
        <table className='table table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th></th>
              <th>Created By</th>            
              <th>Estimate No.</th>
              <th>Job</th>
              <th width="15%">PO</th>
              <th>Description</th>
              <th>Amount</th>            
              <th>Date</th>            
              <th>Status</th>            
              <th className="item-center">Action</th>            
            </tr>
          </thead>
          <tbody>
            {
              objects.map((quote) => {
                return (
                  <tr key={quote.id} >
                    <td>
                      <a href={`/dashboard/quotes/${quote.id}/show`}>
                        <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=${quote.attributes.created_by.full_name}&size=64`)} size='large' />
                      </a> 
                    </td>
                    <td>
                      <a href={`/dashboard/users/${quote.attributes.created_by.id}/show?${qs.stringify(viewStore.userShowSearch, { encode: false })}`}>
                        <p className="primary">{quote.attributes.created_by.full_name}</p>
                      </a>
                    </td>
                    <td>
                      <a href={`/dashboard/quotes/${quote.id}/show`}>                    
                        <p className="secondary">{quote.attributes.number}</p>
                      </a>
                    </td>
                    <td>
                      <a href={`/dashboard/jobs/${quote.attributes.job.id}/${section}`}>
                        <p className='primary'>{quote.attributes.event_number}</p>
                      </a>
                    </td>
                    <td>
                      <p className='secondary'>{quote.attributes.po_number || "N/A"}</p>
                    </td>
                    <td>
                      <p className='secondary'>{quote.attributes.description || ""}</p>
                    </td>
                    <td>
                      <p className='time-entry primary'>{quote.attributes.grand_total}</p>
                    </td>
                    <td>
                      <p className='secondary'>{ formatTime(quote.attributes.created_at) }</p>
                    </td>
                    <td>
                      <span className='bp3-tag bp3-minimal bp3-intent-primary'>
                        {quote.attributes.status}
                      </span>
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <a className="bp3-button bp3-minimal bp3-small" href={`/dashboard/jobs/${quote.attributes.job.id}/${section}`}>
                          <img src="/view-icon.svg"/>
                        </a>
                        <Divider/>
                        <a className="bp3-button bp3-minimal bp3-small" href={`/dashboard/quotes/${quote.id}/show`}>
                          <img src="/edit-light-icon.svg" />
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </div>
    )
  }
}

export default QuotesTable;