import React from 'react';

class FooterIllustration extends React.Component {
  render() {
    return (
      <div className="footer-illustration">
        <img src="/footer_illustration.svg" alt="footer illustration" />
      </div>
    )
  }
}

export default FooterIllustration;