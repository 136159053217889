import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import Lockr from 'lockr';
import stores from './index';
import { toJS } from 'mobx';
import moment from 'moment';

const EMPTY_NEW_VACATION = {
  start_date: moment(),
  end_date: moment(),
  notes: '',
  user_id: []
}

const signupUserFromLocalStorage = Lockr.get("signupUser");

const EMPTY_SIGNUP_USER = signupUserFromLocalStorage ? signupUserFromLocalStorage : {
  isLoading: false,
  isError: false,
  object: {
    email: "",
    tenant: "",
    team_size: "",
    company_type: "",
    first_name: "",
    last_name: "",
    currency_symbol: "",
    country: "",
    timezone: "",
    currency: "",
    password: "",
    password_confirmation: ""
  }
};

class UserStore {

  @observable isDeactivateLoading = false;

  @observable users = [
    {
      email: '',
      first_name: '',
      last_name: '',
      role: 'technician',
      error: null
    }
  ];

  @observable user = {
    isLoading: false,
    isError: false,
    object: null
  };

  @observable quickbooks = {
    isLoading: false,
    isError: false,
    data: {}
  };

  @observable xeros = {
    isLoading: false,
    isError: false,
    data: {}
  };

  @observable signup = EMPTY_SIGNUP_USER;

  @observable vacation = {
    isLoading: false,
    isError: false,
    object: {
      start_date: moment(),
      end_date: moment(),
      notes: ''
    }
  };

  // in this needs to select technician
  @observable new_vacation = {
    isLoading: false,
    isError: false,
    object: EMPTY_NEW_VACATION
  };

  @observable vacations = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable invitation = observable({});
  @observable showInviteUserLoader = false;
  @observable inviteUserResponse = {success: [], failed: []};

  @action
  incrementTeamCount() {
    this.users.push({
      email: '',
      first_name: '',
      last_name: '',
      role: 'technician'
    })
  }

  @action
  loadUser(params) {
    this.user.isLoading = true;
    let promise = Api.get(`/users/${params.uuid}`)
    
    promise
      .then((response) => {
        this.user.isLoading = false;
        this.user.isError = false;
        this.user.object = response.data.data;
        if(stores.viewStore.currentView === 'Pages.Users.Show'){
          stores.viewStore.breadCrumbs =  [
            { href: `/dashboard/users/all`, text: "Users"},
            { href: `/dashboard/users/${params.uuid}/show`, text: this.user.object.attributes.full_name }
          ];
        }
        if(stores.viewStore.currentView === 'Pages.Users.MapShow'){
          stores.viewStore.breadCrumbs =  [
            { href: `/dashboard/users/all`, text: "Users"},
            { href: `/dashboard/users/map`, text: "Map"},
            { href: `/dashboard/users/${params.uuid}/show`, text: this.user.object.attributes.full_name }
          ];
        }
      })
      .catch((error) => {
        this.user.isLoading = false;
        this.user.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  loadQuickbooks() {
    this.quickbooks.isLoading = true;
    let promise = Api.get(`/quickbooks`)
    
    promise
      .then((response) => {
        this.quickbooks.isLoading = false;
        this.quickbooks.isError = false;
        this.quickbooks.data = response.data;
      })
      .catch((error) => {
        this.quickbooks.isLoading = false;
        this.quickbooks.isError = true;
        this.quickbooks.data = {};
      })

    return promise;
  }

  @action
  loadXeros() {
    this.xeros.isLoading = true;
    let promise = Api.get(`/xeros`)
    
    promise
      .then((response) => {
        this.xeros.isLoading = false;
        this.xeros.isError = false;
        this.xeros.data = response.data;
      })
      .catch((error) => {
        this.xeros.isLoading = false;
        this.xeros.isError = true;
      })

    return promise;
  }

  @action
  clearSelectedUser() {
    this.user = {
      isLoading: false,
      isError: false,
      user: null
    }
  }

  @action
  resetSignupUser() {
    Lockr.rm('signupUser');
    this.signup = EMPTY_SIGNUP_USER;
  }

  @action
  saveUserSignupToLocalStorage() {
    Lockr.set('signupUser', this.signup);
  }

  @action
  updateUser(user) {
    this.user.isLoading = true;
    let promise = Api.put(`/users/${user.attributes.id}`, {user: toJS(user.attributes)})

    promise
      .then((response) => {
        this.user.isLoading = false;
        this.user.isError = false;
        if(stores.viewStore.currentUser.id === response.data.data.id){
          stores.viewStore.reloadUser();
        }else{
          stores.viewStore.users();
        }
      })
      .catch((error) => {
        this.user.isLoading = false;
        this.user.isError = true;
      })
  }

  inviteUser() {
    this.showInviteUserLoader = true;
    const promise = Api.post('/invitations', { invitations: this.users })

    promise
      .then((response) => {
        this.inviteUserResponse = response.data;
        this.showInviteUserLoader = false;
      })
      .catch((error) => {
        this.showInviteUserLoader = false;
      })

    return promise;
  }

  @action
  loadUsersVacations() {
    this.vacations.isLoading = true;
    const {
      where,
      per_page,
      page,
      order
    } = stores.viewStore.vacationsSearch;
    let promise = Api.post(`/vacations/search`, 
      {
        search: {
          where: where,
          order: order,
          per_page: per_page,
          page: page
        }
      }
    );

    promise
      .then((response) => {
        this.vacations.isLoading = false;
        this.vacations.isError = false;
        this.vacations.objects = response.data.data
        this.vacations.meta = response.data.meta;
      })
      .catch((error) => {
        this.vacations.isLoading = false;
        this.vacations.isError = true;
      })

    return promise;

  }

  resendUserInvite(invitation_id) {
    this.showInviteUserLoader = true;
    const promise = Api.post('/invitations/resend', { id: invitation_id })

    promise
      .then((response) => {
        this.showInviteUserLoader = false;
      })
      .catch((error) => {
        this.showInviteUserLoader = false;
      })

    return promise;
  }

  @action
  loadUserInvitation(params) {
    const promise = Api.get(`/invitations/get_invitation/${params.token}`)

    promise
      .then((response) => {
        this.invitation = response.data;
      })
      .catch((error) => {

      })
  }

  @action
  acceptInvitation() {
    const promise = Api.post('/invitations/accept_invitation', { user: this.invitation })

    promise
      .then((response) => {
        stores.viewStore.invitationAccepted();
        // stores.viewStore.currentUser = response.data.data;
        // Lockr.set('currentUser', response.data.data);
        // stores.viewStore.dashboard();
      })
      .catch((error) => {

      })

    return promise;
  }

  @action
  createTenant() {
    this.signup.isLoading = true;
    let promise = Api.post(`/registration`, {user: this.signup.object})

    promise
      .then((response) => {
        this.signup.isLoading = false;
        this.signup.isError = false;
        let user = response.data.data;
        stores.viewStore.currentUser = user;
        Lockr.set('currentUser', user);
        stores.viewStore.inviteUsers();
        window.$FPROM.trackSignup({
          email: user.attributes.email,
          function(){
          }
        });
        window.location.reload();
      })
      .catch((error) => {
        this.signup.isLoading = false;
        this.signup.isError = true;
      })

    return promise;
  }

  @action
  createUserVacation() {
    this.vacation.isLoading = true;
    let promise = Api.post(`/vacations`, {vacation: this.vacation.object})

    promise
      .then((response) => {
        this.vacation.isLoading = false;
        this.vacation.isError = false;
        let vacation = response.data.data;
        this.loadUser({uuid: vacation.attributes.user_id})
      })
      .catch((error) => {
        this.vacation.isLoading = false;
        this.vacation.isError = true;
      })

    return promise;
  }

  @action
  createTechnicianVacation() {
    this.new_vacation.isLoading = true;
    let vacation = this.new_vacation.object;
    this.new_vacation.object.user_id = this.new_vacation.object.user_id[0];
    let promise = Api.post(`/vacations`, {vacation: this.new_vacation.object})

    promise
      .then((response) => {
        this.new_vacation.isLoading = false;
        this.new_vacation.isError = false;
        this.new_vacation.object = EMPTY_NEW_VACATION;
        // this.loadUser({uuid: new_vacation.attributes.user_id})
      })
      .catch((error) => {
        this.new_vacation.isLoading = false;
        this.new_vacation.isError = true;
        this.new_vacation.object.user_id = [this.new_vacation.object.user_id];
      })

    return promise;
  }

  @action
  updateUserVacation() {
    this.vacation.isLoading = true;
    let promise = Api.put(`/vacations/${this.vacation.object.id}`, {vacation: this.vacation.object})

    promise
      .then((response) => {
        this.vacation.isLoading = false;
        this.vacation.isError = false;
        let vacation = response.data.data;
        this.loadUser({uuid: vacation.attributes.user_id})
      })
      .catch((error) => {
        this.vacation.isLoading = false;
        this.vacation.isError = true;
      })

    return promise;
  }

  deleteVacation(vacation) {
    this.vacation.isLoading = true;
    let promise = Api.delete(`/vacations/${vacation.attributes.id}`)

    promise
      .then((response) => {
        this.vacation.isLoading = false;
        this.vacation.isError = false;
        this.loadUser({uuid: vacation.attributes.user_id})
      })
      .catch((error) => {
        this.vacation.isLoading = false;
        this.vacation.isError = true;
      })

    return promise;
  }

  checkTechnicianVacationConflicts(technician_ids, start_date, end_date) {
    let params = {
      technician_ids, start_date, end_date
    }
    let promise = Api.post(`users/check_conflicts`, params)

    promise
      .then((response) => {
      })
      .catch((error) => {
      })

    return promise;
  }

  deactivateUser(user) {
    this.isDeactivateLoading = true;
    let promise = Api.post(`users/${user.attributes.id}/deactivate`)

    promise
      .then((response) => {
        this.isDeactivateLoading = false;
        this.user.object = response.data.data;
      })
      .catch((error) => {
        this.isDeactivateLoading = false;
      })

    return promise;
  }

  activateUser(user) {
    this.isDeactivateLoading = true;
    let promise = Api.post(`users/${user.attributes.id}/activate`)

    promise
      .then((response) => {
        this.isDeactivateLoading = false;
        this.user.object = response.data.data;
      })
      .catch((error) => {
        this.isDeactivateLoading = false;
      })

    return promise;
  }

};

export default UserStore;