import Pusher from 'pusher-js';
import Lockr from 'lockr';

let env = {};

if (process.env.NODE_ENV==='development') {
  env.pusher_app_key = '7af9f0e67ad67bb60a5e'
} else {
  env.pusher_app_key = 'd78c66868988a0e1f29a'
}

let socket = new Pusher(env.pusher_app_key, {
  cluster: 'us2',
  encrypted: true
});

let channels = {
  dispatchBoard: () => {
    const currentUser = Lockr.get('currentUser');
    if (currentUser) {
      return socket.subscribe(`${currentUser.attributes.tenant_name}-dispatchBoard`)
    } else {
      return socket.subscribe(`dispatchBoard`)
    }
  }
}

export { channels, socket }
