import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';
import Toast from 'helpers/toaster';
import { Intent } from "@blueprintjs/core";
import _ from 'lodash';
import $ from 'jquery';
import qs from 'qs';
import moment from 'moment';
import { currentTime } from '../helpers/time';
import JobModel from '../models/job_model';
var FileDownload = require('js-file-download');

const EMPTY_JOB = {
  isLoading: false,
  isError: false,
  object: {
    client_id: null,
    title: null,
    start_date: currentTime(),
    end_date: currentTime(),
    po_number: null,
    service_type_id: null,
    technician_ids: [],
    selectedServiceType: [],
    location: null,
    client: null,
    reason_for_call: '',
    is_po_provided: true
  }
};


const EMPTY_NOTES = {
  isLoading: false,
  isError: false,
  object: {
    body:'',
  },
};

const EMPTY_TIMELOG_OBJECT = {
  start_time: moment()._d,
  end_time: moment().add(1, 'hours')._d,
  user_id: "",
  description: "",
  work_report_id: "",
  is_overtime: false,
  hours: 1,
  job_id: "",
  status: 'draft'
}

class JobStore {

  @observable showFutureJobsLoader = false;
  @observable showUntilJobsLoader = false;
  @observable showMarkAsInvoicedLoader = false;
  @observable showChangeAssignmrntStatusLoader = false;
  @observable requestingRestoreJob = false;

  @observable jobs = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable unassignedJobs = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable incompleteJobs = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable clientJobs = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable job = {
    isLoading: false,
    isError: false,
    object: null
  };

  @observable materialLineItems = {
    isLoading: false,
    isError: false,
    object: []
  };

  @observable images = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable jobAssociations = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable notes = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable deleteJob = {
    isLoading: false,
    isError: false,
    object: {
      work_reports: true,
      estimates: true,
      invoices: true,
      id: '',
      event_number: ''
    }
  };

  @observable timelog = {
    isLoading: false,
    isError: false,
    object: EMPTY_TIMELOG_OBJECT
  };

  @observable timeLogs = {
    isLoading: false,
    isError: false,
    objects: []
  }

  @observable jobWorkReports = [];
  @observable jobEstimates = [];
  @observable jobInvoices = [];

  @observable persistJob = EMPTY_JOB;
  @observable persistNote = EMPTY_NOTES;

  @observable isServiceAgreement = false;

  @action
  createTimeLog(timeLog) {
    this.timelog.isLoading = true;
    let promise = Api.post(`/timesheets/`, {time_log: timeLog})

    promise
      .then((response) => {
        this.timelog.isLoading = false;
        this.timelog.isError = false;       
        this.timelog.object = EMPTY_TIMELOG_OBJECT;
        this.refreshJob({ uuid : timeLog.job_id })        
      })
      .catch((error) => {
        this.timelog.isLoading = false;
        this.timelog.isError = true;     
      })

    return promise;
  }

  @action
  loadJobTimesheets(job) {
    this.timeLogs.isLoading = true;
    let promise = Api.post('/timesheets/search', { 
      search: { 
        where: {
          job_number: job.attributes.event_number
        },
        order: { created_at: 'desc' },
        page: 1,
        per_page: 25
      }
    });

    promise
      .then((response) => {
        this.timeLogs.isLoading = false;
        this.timeLogs.isError = false;
        this.timeLogs.objects = response.data.data;
      })
      .catch((error) => {
        this.timeLogs.isLoading = false;
        this.timeLogs.isError = false;
      })
  }

  @action
  loadJobContents(job) {
    this.jobAssociations.isLoading = true;
    let promise = Api.post('/work_reports/search', { 
      search: { 
        where: {
          event_number: job.attributes.event_number
        },
        order: { created_at: 'desc' },
        page: 1,
        per_page: 60
      }
    });

    promise
      .then((response) => {
        this.jobAssociations.isLoading = false;
        this.jobAssociations.isError = false;
        this.jobAssociations.objects = response.data.data;
        this.jobWorkReports = _.filter(response.data.data, function(content) { return content.attributes.kind === 'WorkReport'})
        this.jobEstimates = _.filter(response.data.data, function(content) { return content.attributes.kind === 'Quote'})
        this.jobInvoices = _.filter(response.data.data, function(content) { return content.attributes.kind === 'Invoice'})
      })
      .catch((error) => {
        this.jobContents.isLoading = false;
        this.jobContents.isError = true;
      })
    
  }

  @action
  clearJob() {
    this.persistJob = EMPTY_JOB;
  }

  @action
  createJob() {
    this.persistJob.isLoading = true;
    let promise = Api.post(`/jobs/`, {job: this.persistJob.object})

    promise
      .then((response) => {
        this.persistJob.isLoading = false;
        this.persistJob.isError = false;
        stores.store.isOpen = false;
        this.clearJob()
        stores.viewStore.dashboard();
        $('#calendar').fullCalendar('refetchEvents');
      })
      .catch((error) => {
        this.persistJob.isLoading = false;
        this.persistJob.isError = true;
        if(error.response && error.response.data && error.response.data.errors) {
          console.error(error.response.data.errors.join(", "))
          Toast.show({ icon: 'warning-sign', message: error.response.data.errors.join(", "), intent: Intent.DANGER })
        }
      })

    return promise;
  }

  @action
  createNotes(job,notes) {
    this.persistNote.isLoading = true;
    let params = {
      commentable_type: "Job",
        commentable_id: job.attributes.id,
        comment: {
          body: notes
        }
    }
    let promise = Api.post(`/comments/`, params)   
    promise
      .then((response) => {
        this.persistNote.isLoading = false;
        this.persistNote.isError = false;       
        this.notes.objects = response.data.comments.data;        
      })
      .catch((error) => {
        this.persistNote.isLoading = false;
        this.persistNote.isError = true;     
      })

    return promise;
    
  }

  @action
  updateNotes(job,notes,commentObject) {
    this.persistNote.isLoading = true;
    let params = {
      commentable_type: "Job",
        commentable_id: job.attributes.id,
        comment: {
          body: notes,
          id:commentObject.id
        }
    }
    let promise = Api.put(`/comments/${commentObject.id}`, params)   
    promise
      .then((response) => {
        this.persistNote.isLoading = false;
        this.persistNote.isError = false;       
        this.notes.objects = response.data.comments.data;        
      })
      .catch((error) => {
        this.persistNote.isLoading = false;
        this.persistNote.isError = true;     
      })

    return promise;
    
  }

  @action
  deleteNote(note, job) {
    let promise = Api.delete(`/comments/${note.attributes.id}`)   
    promise
      .then((response) => {
        this.loadNotes(job);   
      })
      .catch((error) => {   
      })

    return promise;
  }

  @action
  updateJob(job) {
    this.job.isLoading = true;
    let promise = Api.put(`/jobs/${job.id}`, {job: job})

    return promise
      .then((response) => {
        this.job.isLoading = false;
      })
      .catch((error) => {
        this.job.isLoading = false;
      })
  }

  @action
  remindTechs(job) {
    this.job.isLoading = true;
    let promise = Api.post(`/jobs/${job.id}/remind_techs`, {})

     promise
      .then((response) => {
        this.job.isLoading = false;
      })
      .catch((error) => {
        this.job.isLoading = false;
      })
  }

  @action
  updateSidebarJob() {
    this.persistJob.isLoading = true;
    let promise = Api.put(`/jobs/${this.persistJob.object.id}/update_job`, {job: this.persistJob.object})

     promise
      .then((response) => {
        this.persistJob.isLoading = false;
        this.persistJob.isError = false;
        $('#calendar').fullCalendar('refetchEvents');
        stores.store.isOpen = false;
        if(this.persistJob.object.isServiceAgreement) {
          stores.serviceAgreementStore.loadServiceAgreement({uuid: this.persistJob.object.service_agreement_id})
        } else {
          this.loadJob({uuid: this.persistJob.object.id});
        }
        this.clearJob();
      })
      .catch((error) => {
        this.persistJob.isLoading = false;
        this.persistJob.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  changeJobStatus(job, event) {
    this.showMarkAsInvoicedLoader = true;
    let promise = Api.post(`/jobs/${job.attributes.id}/change_status`, {event: event})

    promise
      .then((response) => {
        this.showMarkAsInvoicedLoader = false;
        this.job.object = new JobModel(response.data.data);
      })
      .catch((error) => {
        this.showMarkAsInvoicedLoader = false;
      })

    return promise;
  }

  @action
  changeAssignmentStatus(event, assignment) {
    this.showChangeAssignmrntStatusLoader = true;
    let promise = Api.post(`/assignments/${assignment.id}/change_status`, {event: event})

    promise
      .then((response) => {
        this.showChangeAssignmrntStatusLoader = false;
      })
      .catch((error) => {
        this.showChangeAssignmrntStatusLoader = false;
      })

    return promise;
  }

  @action
  refreshJob({ uuid }) {
    this.showChangeAssignmrntStatusLoader = true;
    let promise = Api.get(`/jobs/${uuid}`)
    
    promise
      .then((response) => {
        this.showChangeAssignmrntStatusLoader = false;
        this.job.object = new JobModel(response.data.data);
      })
      .catch((error) => {
        this.showChangeAssignmrntStatusLoader = false;
      })

    return promise;
  }

  @action
  loadClientJobs() {
    this.clientJobs.isLoading = true;
    let promise = Api.post('/jobs/search', {
      search: window.stores.viewStore.clientJobSearch
    });

    promise
      .then((response) => {
        this.clientJobs.isLoading = false;
        this.clientJobs.isError = false;
        this.clientJobs.objects = response.data.data;
        this.clientJobs.meta = response.data.meta;
      })
      .catch((error) => {
        this.clientJobs.isLoading = false;
        this.clientJobs.isError = true;
      })
    
  }

  @action
  loadIncompleteJobs() {
    this.incompleteJobs.isLoading = true;
    
    let promise = Api.post('/jobs/search', {
      search: window.stores.viewStore.incompleteJobSearch
    });

    promise
      .then((response) => {
        this.incompleteJobs.isLoading = false;
        this.incompleteJobs.isError = false;
        this.incompleteJobs.objects = response.data.data;
        this.incompleteJobs.meta = response.data.meta;
      })
      .catch((error) => {
        this.incompleteJobs.isLoading = false;
        this.incompleteJobs.isError = true;
      })
    
      return promise;
  }

  @action
  loadUnassignedJobs() {
    this.unassignedJobs.isLoading = true;
    let promise = Api.post('/assignments/search', {
      search: window.stores.viewStore.unassignedJobSearch
    });

    promise
      .then((response) => {
        this.unassignedJobs.isLoading = false;
        this.unassignedJobs.isError = false;
        this.unassignedJobs.objects = response.data.data;
        this.unassignedJobs.objects.map((job) => {
          if(job.attributes && job.attributes.service_type && job.attributes.service_type.id) {
            job.attributes.serviceType = job.attributes.service_type;
          } else {
            // hard coded
            job.attributes.service_type = {color: "f13b52", name: "PMA", id: "05fb4684-5705-40e9-b919-3a01bc71b147"};
            job.attributes.serviceType = {color: "f13b52", name: "PMA", id: "05fb4684-5705-40e9-b919-3a01bc71b147"};
          }
          return job;
        })
        this.unassignedJobs.meta = response.data.meta;
      })
      .catch((error) => {
        this.unassignedJobs.isLoading = false;
        this.unassignedJobs.isError = true;
      })
    
      return promise;
  }

  @action
  loadJob({ uuid }) {
    this.job.isLoading = true;
    let promise = Api.get(`/jobs/${uuid}`)
    
    promise
      .then((response) => {
        this.job.isLoading = false;
        this.job.isError = false;
        this.job.object = new JobModel(response.data.data);
        stores.viewStore.ShowJob({ uuid },response.data.data.attributes.event_number);
      })
      .catch((error) => {
        this.job.isLoading = false;
        this.job.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  loadImages() {
    this.images.isLoading = true;
    let promise = Api.get(`/jobs/${this.job.object.id}/images`)
    
    promise
      .then((response) => {
        this.images.isLoading = false;
        this.images.isError = false;
        this.images.objects = response.data.data;
      })
      .catch((error) => {
        this.images.isLoading = false;
        this.images.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  deleteImage(work_report_id, image_id) {
    this.images.isLoading = true;
    let promise = Api.delete(`/work_reports/${work_report_id}/detached_image`, {params: {image_id: image_id}});
    
    promise
      .then((response) => {
        this.images.isLoading = false;
        this.images.isError = false;
      })
      .catch((error) => {
        this.images.isLoading = false;
        this.images.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  loadWorkReportLineItems(uuid) {
    this.materialLineItems.isLoading = true;
    let promise = Api.get(`/jobs/${uuid}/work_report_line_items/`)
    
    promise
      .then((response) => {
        //this.job.isLoading = false;
       
        this.materialLineItems.isError = false;
        this.materialLineItems.object = response.data;
      })
      .catch((error) => {
        this.materialLineItems.isLoading = false;
        this.materialLineItems.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  loadJobForBooking({ uuid }) {
    this.job.isLoading = true;
    let promise = Api.get(`/jobs/${uuid}`)
    
    promise
      .then((response) => {
        this.job.isLoading = false;
        this.job.isError = false;
        this.job.object = new JobModel(response.data.data);
      })
      .catch((error) => {
        this.job.isLoading = false;
        this.job.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  loadNotes(job) {
    
    this.notes.isLoading = true;
    let promise = Api.get(`/comments?` + qs.stringify({commentable_id: job.attributes.id, commentable_type: 'Job'}))
    
    promise
      .then((response) => {
        this.notes.isLoading = false;
        this.notes.isError = false;
        this.notes.objects = response.data.comments.data;
      })
      .catch((error) => {
        this.notes.isLoading = false;
        this.notes.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  loadJobs() {
    this.jobs.isLoading = true;
    const {
      only_deleted,
      where,
      per_page,
      page,
      order
    } = stores.viewStore.jobSearch;
    let promise = Api.post(`/jobs/search`, 
      {
        search: {
          only_deleted: only_deleted,
          where: where,
          order: order,
          per_page: per_page,
          page: page
        }
      }
    );
    
    promise
      .then((response) => {
        this.jobs.isLoading = false;
        this.jobs.isError = false;
        this.jobs.objects = response.data.data
        this.jobs.meta = response.data.meta;
      })
      .catch((error) => {
        this.jobs.isLoading = false;
        this.jobs.isError = true;
      })

    return promise;
  }

  @action
  loadAssignments() {
    this.jobs.isLoading = true;
    const {
      where,
      per_page,
      page,
      order
    } = stores.viewStore.jobSearch;
    let promise = Api.post(`/assignments/search`, 
      {
        search: {
          where: where,
          order: order,
          per_page: per_page,
          page: page
        }
      }
    );
    
    promise
      .then((response) => {
        this.jobs.isLoading = false;
        this.jobs.isError = false;
        this.jobs.objects = response.data.data
        this.jobs.meta = response.data.meta;
      })
      .catch((error) => {
        this.jobs.isLoading = false;
        this.jobs.isError = true;
      })

    return promise;
  }

  @action
  destroyJob() {
    this.deleteJob.isLoading = true
    let promise = Api.post(`/jobs/${this.deleteJob.object.id}/destroy_job`, {job: this.deleteJob.object})
    
    promise
      .then((response) => {     
        this.deleteJob.isLoading = false
        this.deleteJob.isError = false
        if(this.deleteJob.object.isServiceAgreement) {
          stores.serviceAgreementStore.loadServiceAgreement({uuid: this.deleteJob.object.service_agreement_id})
        } else {
          stores.viewStore.dashboard();
        }
      })
      .catch((error) => {
        this.deleteJob.isLoading = false
        this.deleteJob.isError = true
      })

    return promise;
  }

  @action
  generateFutureJobs(no_of_jobs, service_agreement_id) {
    this.showFutureJobsLoader = true;
    let promise = Api.post(`/jobs/generate_future_jobs`, {job: {no_of_jobs: no_of_jobs, service_agreement_id: service_agreement_id}})
    
    promise
      .then((response) => {   
        this.showFutureJobsLoader = false; 
        stores.serviceAgreementStore.loadServiceAgreement({uuid: service_agreement_id})
      })
      .catch((error) => {
        this.showFutureJobsLoader = false;
      })

    return promise;
  }

  @action
  generateUntilJobs(untilDate, service_agreement_id) {
    this.showUntilJobsLoader = true;
    let promise = Api.post(`/jobs/generate_untildate_jobs`, {job: {until_date: untilDate, service_agreement_id: service_agreement_id}})
    
    promise
      .then((response) => {   
        this.showUntilJobsLoader = false; 
        stores.serviceAgreementStore.loadServiceAgreement({uuid: service_agreement_id})
      })
      .catch((error) => {
        this.showUntilJobsLoader = false;
      })

    return promise;
  }

  @action
  download_excel(searchObj) {
    let promise = Api.post('/jobs/excel_bulk_download', { search: searchObj }, { responseType: 'arraybuffer' })

    promise
      .then((response) => {
        FileDownload(response.data, 'Jobs.xls');
      })
      .catch((error) => {
      })
  }

  @action
  restoreJob(job) {
    this.requestingRestoreJob = true;
    let promise = Api.post(`/jobs/${job.attributes.id}/restore_job`, {job: job.attributes})
    
    promise
      .then((response) => { 
        this.requestingRestoreJob = false;    
      })
      .catch((error) => {
        this.requestingRestoreJob = false;
      })

    return promise;
  }

};

export default JobStore;
