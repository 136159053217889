import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import DefaultItem from './DefaultItem';
import styled from '@emotion/styled';
import SettingsSidebar from '../../pages/preferences/settings-sidebar';
import Toast from 'helpers/toaster';

const PreferenceGrid = styled('div')`
  width: 100%;  
`
const PageWrapGray = styled('div')`
  padding: 20px 20px 0px 20px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
  height: auto;
`

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit * 0}px`};
`

@inject('store', 'preferenceStore') @observer
class DefaultLineItemsList extends Component {

  createDefaultLineItem() {
    let { preferenceStore } = this.props;
    preferenceStore.addDefaultBlankLineItem()
      .then(this.onSuccessfullyCreateLineItem.bind(this))
      .catch(this.onFailCreateLineItem.bind(this))
  }

  onSuccessfullyCreateLineItem(response) {
    Toast.show({ message: "Default line item created successfully.", intent: Intent.SUCCESS })
  }

  onFailCreateLineItem(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ message: "Something went wrong. Failed to create default line item.", intent: Intent.DANGER })
    }
  }

  render() {
    let { 
      preferenceStore, 
      preferenceStore: {
        showLoader,
        preference: {
          object: {
            attributes: {
              default_line_items
            }
          }
        }
      } 
    } = this.props;
    return(
      <PreferenceGrid>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <SettingsSidebar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
              <CardContent>
                <div style={{ paddingLeft: '10px', display:'flex', justifyContent:'flex-end'}}>
                  <h6>
                    <strong>
                    <ButtonGroup><Button icon='add' className="bp3-minimal" onClick={() => {this.createDefaultLineItem()} } loading={showLoader} >Add Default Blank Line
                    Items</Button></ButtonGroup></strong>
                  </h6>
                </div>

                <div style={{ marginBottom: '1rem' }}>
                  <div>
                    {
                      default_line_items && default_line_items.length === 0 && <div className="bp3-callout warning-alert-message bp3-intent-warning bp3-icon-info-sign table-card" style={{ padding: '10px' }}>
                        No default line items have been added yet.
                      </div>
                    }
                  </div>
                  { 
                    default_line_items && default_line_items.length > 0 && 
                    <div className="card rounded-0 table-responsive">
                      <table className='table table-hover'>
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Value</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            default_line_items && default_line_items.map((line_item) => {
                              return (
                                <DefaultItem line_item={line_item} key={line_item.id} />
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
              </CardContent>
            </PageWrapGray>
          </div>
        </div>
      </PreferenceGrid>
    )
  }

};

export default DefaultLineItemsList;