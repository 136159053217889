import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import CustomerDetails from 'components/Quote/CustomerDetails';

@inject("quoteStore")
@observer
class QuoteBodyHeader extends Component {
  render() {
    const {
      quoteStore: {
        quote: { object }
      }
    } = this.props;
    return (
      <div className="payment-padding">
        <div className="review-page-header">
          <div className="tenant-logo">
            <img
              src={object.attributes.tenant.logo}
              alt="tenant logo"
            />
          </div>
          
          <div style={{ display: "flex", alignItems:  'flex-end' }} >
            <h2 style={{ fontWeight: 400, marginBottom: 20 }}>Estimate {`#${object.attributes.number}`}</h2>
          </div>
          <div className="details-1 review-details">
            <span>
            <strong> {object.attributes.tenant.name}</strong>
            </span>
            <span>
              {object.attributes.tenant.street_address}<br />
              {object.attributes.tenant.city},{object.attributes.tenant.province},{object.attributes.tenant.country}, {object.attributes.tenant.postal_code} <br/>
            </span>
            <span>
              <strong>Phone: </strong>
              {object.attributes.tenant.phone}
            </span>
          </div>
        </div>

        <div style={{ marginTop: "2.5rem" }}>
         
          <div>
            <div className="invoice-customer-details" style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
            <div className="details-1" style={{ display: 'flex', flexDirection: 'column' }}>
              <span>
                <strong>Prepared by: </strong>
                {object.attributes.created_by.full_name}
              </span>
              <span>
                <strong>Email: </strong>
                {object.attributes.created_by.email}
              </span>
              <span>
                <strong>Phone: </strong>
                {object.attributes.created_by.phone}
              </span>
            </div>
            <div className="details-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <h6>
                <strong>
                  {object.attributes.client.name}
                </strong>
              </h6>
              <span>
                {object.attributes.location.full_address}
              </span>
              {
                object.attributes.po_number && <span>
                  {object.attributes.po_number}
                </span>
              }
            </div>
          </div>
          <div className="invoice-customer-details" style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
            <div className="details-1" style={{ display: 'flex', flexDirection: 'column' }}>
              <h5 style={{ color: "#91A4B9"}}>Scope of Work</h5>
              <div style={{marginBottom: 40}}>
                <div dangerouslySetInnerHTML={{__html: object.attributes.proposal_markup}} />
              </div>
            </div>
            </div>
          </div>
          </div>
        <br />
        <hr />               
      </div>
    );
  }
}

export default QuoteBodyHeader;
