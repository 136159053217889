import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Button,
  Dialog,
  Classes
} from "@blueprintjs/core";

@inject("viewStore", "invoiceStore")
@observer
class TransactionModal extends Component {
  
  handleTransactionDailog() {
    this.props.onClose();
  }

  render() {
    return(
      <Dialog
        title="Add Transaction"
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="transaction-type">
            <div className="interac" onClick={() => {this.props.onSuccess("card")}}>
              <img src="/mastercard.svg" width="50" />
              <span>Credit Card</span>
            </div>
            <div className="interac" onClick={() => {this.props.onSuccess("cash")}}>
              <img src="/interac-eTransfer.svg" width="50" />
              <span>Cash</span>
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.handleTransactionDailog.bind(this)}
              style={{ width: "100px" }}
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default TransactionModal;