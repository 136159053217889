import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  Button,
  Dialog,
  Classes,
  Intent,
  TextArea
} from "@blueprintjs/core";
import Toast from "helpers/toaster";

@inject("supplierStore")
@observer
class AddSupplierModal extends Component {
  handleCloseSupplierDailog() {
    this.props.onClose();
  }

  createSupplier() {
    let {
      props: {
        supplierStore: {
          supplier: {
            object
          }
        }
      }
    } = this;
    if(object.name === '' || object.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter name.', intent: Intent.DANGER })
      return;
    }
    if(object.email === '' || object.email === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter email.', intent: Intent.DANGER })
      return;
    }
    if(object.phone === '' || object.phone === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter phone number.', intent: Intent.DANGER })
      return;
    }
    if(object.address === '' || object.address === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter address.', intent: Intent.DANGER })
      return;
    }
    
    this.props.supplierStore
      .createSupplier()
      .then(this.onSuccessfulAddSupplier.bind(this))
      .catch(this.onFailAddSupplier.bind(this));
  }

  onSuccessfulAddSupplier(response) {
    this.props.onSuccess(response.data.data);
  }

  onFailAddSupplier(error) {
    Toast.show({
      icon: "warning-sign",
      message: error.response.data.errors.join(", "),
      intent: Intent.DANGER
    });
    console.log(error);
  }

  render() {
    let { 
      supplierStore: {
        supplier: {
          object,
          isLoading
        }
      }
    } = this.props;
    return (
      <Dialog
        title="Add Supplier"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseSupplierDailog.bind(this)}        
        style={{width:'700px'}}
      >
        <div className={Classes.DIALOG_BODY}>
          <p><small>All the fields, marked with star (*) are mandatory.</small></p>
          <div className="row">
            <div className="col-12">
              <p>
                <strong>Supplier Details</strong>
              </p>
              <hr />
              <FormGroup label="Supplier Name" labelFor="text-input" labelInfo="*">
                <input
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Name"
                  onChange={(event) => {object.name = event.target.value} }
                />
              </FormGroup>
              
              <FormGroup label="Email" labelFor="text-input" labelInfo="*">
                <input
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Email"
                  onChange={event => {
                    object.email = event.target.value;
                  }}
                />
              </FormGroup>
              <FormGroup label="Phone" labelFor="text-input" labelInfo="*">
                <input
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Phone"
                  onChange={event => {
                    object.phone = event.target.value;
                  }}
                />
              </FormGroup>
              <FormGroup label="Address" labelFor="text-input" labelInfo="*">
                <TextArea
                  type="text"
                  className="bp3-input bp3-fill"
                  placeholder="Address"
                  onChange={event => {
                    object.address = event.target.value;
                  }}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.handleCloseSupplierDailog.bind(this)}
              style={{ width: "100px" }}
            >
              Cancel
            </Button>
            <Button
              intent={Intent.PRIMARY}
              icon="add"
              loading={this.props.supplierStore.supplier.isLoading}
              onClick={this.createSupplier.bind(this)}
              style={{ width: "200px" }}
            >
              Add Supplier
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddSupplierModal;
