import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Pagination from 'components/Pagination';
import Footer from '../Footer';
import { Tag, Button, Alert, Intent } from '@blueprintjs/core';
import qs from 'qs';
import { formatTimeOnly, formatDateOnly } from 'helpers/time';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';
import Toast from 'helpers/toaster';

@inject('store', 'jobStore', 'viewStore') @observer
class JobsTable extends Component {

  @observable isRestoreDialogVisible = false;
  @observable job;

  handleRestoreCancel() {
    this.isRestoreDialogVisible = false;
  }

  handleRestoreConfirm() {
    this.props.jobStore.restoreJob(this.job)
      .then(this.onSuccessfulRestoreJob.bind(this))
      .catch(this.onFailedRestoreJob.bind(this))
  }

  onSuccessfulRestoreJob(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Job restore successfully!', intent: Intent.SUCCESS })
    this.job = null;
    this.isRestoreDialogVisible = false;
    this.props.jobStore.loadJobs();
  }

  onFailedRestoreJob(error) {
    this.isRestoreDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to restore job!', intent: Intent.DANGER })
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        jobSearch
      }
    } = this.props;
    this.props.viewStore.jobSearch = {
      ...jobSearch,
      page: newPage
    };
    this.props.viewStore.jobs();
  }

  handleJobClick(event, job) {
    switch (event.label) {
      case 'Recover':
        this.isRestoreDialogVisible = true;
        this.job = job;
        break;
      default:
    }
  }

  render() {
    let {
      viewStore,
      viewStore: {
        jobSearch,
        jobTab: {
          section
        }
      },
      jobStore: {
        jobs: {
          objects,
          meta
        }
      }
    } = this.props;

    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }

    return(
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
        <table className="table table-hover table-data-presentation" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Job #</th>
              <th width="15%">Job Title</th>
              <th>Job Created</th>
              <th>Start - End Time</th>
              {/* <th>Status</th> */}
              <th>Technicians</th>
              <th>Client</th>
              <th>Status</th>
              {jobSearch.only_deleted && <th>Deleted At</th>}
              {jobSearch.only_deleted && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {
              objects.map((job) => {
                return(
                  <tr key={job.id}> 
                    <td>
                      <TableCellWithSubtext
                        mainText={job.attributes.event_number}
                        mainLink={`/dashboard/jobs/${job.attributes.id}/${section}`}
                      />
                      <Tag style={{backgroundColor: `#${job.attributes.service_type_color}`}}>
                        {job.attributes && job.attributes.service_type_name}
                      </Tag>
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={job.attributes.title}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(job.attributes.created_at)}
                        subText={`${job.attributes.created_by ? job.attributes.created_by.full_name : ''}`}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(job.attributes.start_date)}
                        subText={`${formatTimeOnly(job.attributes.start_date)} - ${formatTimeOnly(job.attributes.end_date)}`}
                      />
                    </td>
                    {/* <td>
                      <span className="docs-color-bubble" style={{backgroundColor: job.attributes.color}}></span>
                      <span style={{marginLeft:'10px'}}>{job.attributes && job.attributes.status != null ? job.attributes.status : 'Unassigned'}</span>
                    </td> */}
                    <td>
                      { job.attributes.assignments && job.attributes.assignments.map((task) => {
                          return (
                            <div style={{display:'flex',alignItems:'center'}} key={Math.random()}>
                              <span className="docs-color-bubble" style={{marginRight:'5px', backgroundColor: task.color}}></span>
                              <TableCellWithSubtext
                                mainText={task.name}
                              />
                            </div>
                          ) 
                        })
                      }
                    </td>

                    <td>
                      <TableCellWithSubtext
                        mainText={job.attributes.client_name}
                        mainLink={`/dashboard/clients/${job.attributes.client.id}?${qs.stringify(viewStore.clientShowSearch, { encode: false })}`}
                        subText={job.attributes.location && job.attributes.location.full_address}
                      />
                    </td>
                    <td>
                      <Tag style={{backgroundColor: job.attributes.status === 'invoiced' ? '#449a61' : '#868f95'}}>
                        {job.attributes && job.attributes.status === 'invoiced' ? 'Invoiced' : 'Not Invoiced'}
                      </Tag>
                    </td>
                    {jobSearch.only_deleted && <td>
                      {job.attributes.deleted_at && <TableCellWithSubtext
                        mainText={formatDateOnly(job.attributes.deleted_at)}
                      />}
                    </td>}
                    {jobSearch.only_deleted && <td>
                      {job.attributes.deleted_at && <div className="action-buttons" minimal={true}>
                        <Button title="Restore Job" minimal={true} small={true} onClick={() => this.handleJobClick({ label: 'Recover' }, job)}>
                          <img src="/restore-icon.svg" alt={'recover icon'}/>
                        </Button>
                      </div>}
                    </td>}
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Restore"
          icon="history"
          intent={Intent.PRIMARY}
          isOpen={this.isRestoreDialogVisible}
          loading={this.props.jobStore.requestingRestoreJob}
          onCancel={this.handleRestoreCancel.bind(this)}
          onConfirm={this.handleRestoreConfirm.bind(this)}
        >
          <p>
            Are you sure you want to Restore <b>{this.job && this.job.attributes.event_number}</b> job?
          </p>
        </Alert>
      </React.Fragment>
    )
  }
};

export default JobsTable;
