import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { 
  FormGroup, Button, Dialog, Classes, Intent, Checkbox, Spinner
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import TaxXeroModal from 'components/Taxes/TaxXeroModal';

@inject('taxStore', 'quickbooksStore','xeroStore') @observer
class TaxModal extends Component {
  @observable isTaxXeroDialogVisible = false;

  handleCloseDailog() {
    // clearing object
    this.props.taxStore.tax.object = {}
    this.props.onClose()
  }

  updateTax() {
    let { 
      taxStore,
      taxStore:{
        tax: {
          object
        }
      }
     } = this.props;
     
    if(object.name === '' || object.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter name.', intent: Intent.DANGER })
      return;
    }
    if(object.percentage_value === '' || object.percentage_value === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter percentage value.', intent: Intent.DANGER })
      return;
    }
    taxStore.update()
      .then(this.onSuccessfullyUpdateTax.bind(this))
      .catch(this.onFailCreateTax.bind(this))
  }

  onCloseTaxXeroDialog() {
    this.isTaxXeroDialogVisible = false;
  }

  openXeroTaxModal() {
    let { taxStore } = this.props;
    this.props.taxStore.tax.object.tax_type = "OUTPUT";

    if(this.props.taxStore.tax.object.name === "" || this.props.taxStore.tax.object.name == null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    if(this.props.taxStore.tax.object.percentage_value === "" || this.props.taxStore.tax.object.percentage_value == null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid percentage value!', intent: Intent.DANGER })
      return;
    }
    
    taxStore.createTaxOnXero()
      .then(this.onSuccessfullyCreateTaxZero.bind(this))
      .catch(this.onFailCreateTaxZero.bind(this))
  }

  onSuccessfullyCreateTaxZero(response) {
    this.props.xeroStore.loadXeroTaxCodes();
    Toast.show({ message: "Tax created successfully.", intent: Intent.SUCCESS })
  }

  onFailCreateTaxZero(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ message: "Something went wrong. Failed to create/update tax", intent: Intent.DANGER })
    }
  }

  onSucessUpdateTaxXero() {
    this.isTaxXeroDialogVisible = true;
  }

  onSuccessfullyUpdateTax(response) {
    this.props.onSuccess()
    Toast.show({ message: "Tax updated successfully.", intent: Intent.SUCCESS })
  }

  createTax() {
    let { 
      taxStore,
      taxStore:{
        tax: {
          object
        }
      }
     } = this.props;

    if(object.name === '' || object.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter name.', intent: Intent.DANGER })
      return;
    }
    if(object.percentage_value === '' || object.percentage_value === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter percentage value.', intent: Intent.DANGER })
      return;
    }
    taxStore.create()
      .then(this.onSuccessfullyCreateTax.bind(this))
      .catch(this.onFailCreateTax.bind(this))
  }

  onSuccessfullyCreateTax(response) {
    this.props.onSuccess()
    Toast.show({ message: "Tax created successfully.", intent: Intent.SUCCESS })
  }

  onFailCreateTax(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ message: "Something went wrong. Failed to create/update tax", intent: Intent.DANGER })
    }
  }

  render() {
    let {
      title,
      edit,
      taxStore: {
        tax: {
          isLoading,
          object
        }
      },
      quickbooksStore: {
        quickbooksTaxCodes: {
          objects
        }
      },
      xeroStore: {
        xeroTaxCodes
      }
    } = this.props;
    return(
      <div>
        <Dialog
          title={title}
          isOpen={this.props.isOpen}
          isCloseButtonShown={false}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              label="Name"
              labelFor="text-input"
              labelInfo="(*)"
            >
              <input 
                type='text' 
                className='bp3-input bp3-fill' 
                placeholder='Name'
                defaultValue={object.name}
                onChange={(event) => { object.name = event.target.value } } 
              />
            </FormGroup>

            <FormGroup
              label="Percentage (%)"
              labelFor="text-input"
              labelInfo="(*)"
            >
              <input 
                type='number' 
                className='bp3-input bp3-fill' 
                placeholder='Tax percentage (%)'
                defaultValue={object.percentage_value || ""}
                onChange={(event) => { object.percentage_value = event.target.value } } 
              />
            </FormGroup>
            
            <div className="row">
              <div className="col-md-8">
                {objects && objects.length > 0 ?
                  <FormGroup
                    label="QuickBooks Tax Code"
                    labelFor="checkbox"
                    labelInfo=""
                  >
                    {this.props.quickbooksStore.quickbooksTaxCodes.isLoading && <Spinner size={Spinner.SIZE_SMALL} />}
                    {!this.props.quickbooksStore.quickbooksTaxCodes.isLoading && <div className="bp3-select bp3-fill">
                      <select
                        id={"quickbooks-tax-code"}
                        value={object.quickbooks_tax_code_id}
                        onChange={event => {
                          object.quickbooks_tax_code_id = event.target.value;
                        }}
                      >
                        <option value="">Select QuickBooks tax code</option>
                        {objects && objects.length > 0 && objects.map((taxCode) => {
                          return(
                            <option value={taxCode.id} key={Math.random()}>{taxCode.name}</option>
                          )
                        })}
                      </select>
                    </div>}
                  </FormGroup>
                  :''
                }
              </div>
              <div className="col-md-4">
                {objects && objects.length > 0 ?
                  <a href="https://quickbooks.intuit.com/" style={{marginLeft:"10px",marginTop:"23px"}}  target="_blank">
                    <p style={{marginTop:"10px"}}>Add to QuickBooks</p>
                  </a>
                  :''
                }
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                {xeroTaxCodes.objects && xeroTaxCodes.objects.length > 0 ?
                  <FormGroup
                    label="Xero Tax Rates"
                    labelFor="checkbox"
                    labelInfo=""
                  >
                    {this.props.xeroStore.xeroTaxCodes.isLoading && <Spinner size={Spinner.SIZE_SMALL} />}
                    {!this.props.xeroStore.xeroTaxCodes.isLoading && <div className="bp3-select bp3-fill">
                      <select
                        id={"xero-tax-code"}
                        value={object.xero_tax_code_name}
                        onChange={event => {
                          object.xero_tax_code_name = event.target.value;
                        }}
                      >
                        <option value="">Select xero tax code</option>
                        {xeroTaxCodes.objects && xeroTaxCodes.objects.length > 0 && xeroTaxCodes.objects.map((taxCode) => {
                          return(
                            <option value={taxCode.TaxType} key={Math.random()}>{taxCode.Name}</option>
                          )
                        })}
                      </select>
                    </div>}
                  </FormGroup>
                  :''
                }
              </div>
              <div className="col-md-4">
                {xeroTaxCodes.objects && xeroTaxCodes.objects.length > 0 ?
                  <Button style={{marginLeft:"10px",marginTop:"23px"}} icon='add' loading={xeroTaxCodes.isLoading} onClick={this.openXeroTaxModal.bind(this)}>
                    Add to Xero
                  </Button>
                  :''
                }
              </div>
            </div>
            <FormGroup
              label="Is Default?"
              labelFor="checkbox"
              labelInfo="(*)"
            >
              <Checkbox 
                checked={object.is_default}
                onChange={(event) => { object.is_default = event.target.checked }} 
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.handleCloseDailog.bind(this)}>Close</Button>
              {!edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.createTax.bind(this)}>
                Save
              </Button>}
              {edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.updateTax.bind(this)}>
                Update
              </Button>}
            </div>
          </div>
        </Dialog>
        <TaxXeroModal 
          isOpen={this.isTaxXeroDialogVisible} 
          title={"Add Tax to Xero"}
          onClose={this.onCloseTaxXeroDialog.bind(this)}
          onSuccess={this.onSucessUpdateTaxXero.bind(this)}
        />
      </div>
    )
  }
};

export default TaxModal;