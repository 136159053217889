import React, { Component } from "react";
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { 
  FormGroup, Button, Dialog, Classes, Intent
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';

@inject('store', 'jobStore') @observer
export default class DeleteJobModal extends Component {

  @observable classes = 'bp3-input bp3-fill';
  @observable errorMsg = '';
  @observable intent = 'Intent.NONE';

  handleCloseDailog() {
    this.props.onClose()
  }

  deleteJob() {
    let {job} = this.props;
    let {
      jobStore: {
        deleteJob: {
          object
        }
      }
    } = this.props;
    if(object.event_number !== job.attributes.event_number) {
      this.classes = 'bp3-input bp3-fill bp3-intent-danger';
      this.errorMsg = "Job# incorrect! Please enter correct job#.";
      this.intent = 'Intent.DANGER';
      return;
    } else {
      this.classes = 'bp3-input bp3-fill';
      this.errorMsg = "";
      this.intent = 'Intent.PRIMARY';
    }

    object.id = job.attributes.id;

    if(job.attributes.isServiceAgreement) {
      object.isServiceAgreement = job.attributes.isServiceAgreement;
      object.service_agreement_id = job.attributes.service_agreement_id
    } else {
      object.isServiceAgreement = false;
    }

    this.props.jobStore.destroyJob()
      .then(this.onSuccessfulDeleteJob.bind(this))
      .catch(this.onFailDeleteJob.bind(this))
  }

  onSuccessfulDeleteJob(response) {
    this.handleCloseDailog()
    Toast.show({ icon: 'thumbs-up', message: 'Job deleted successfully!', intent: Intent.SUCCESS })
  }

  onFailDeleteJob(error) {
    Toast.show({ icon: 'warning-sign', message: "Unable to delete job.", intent: Intent.DANGER })
  }
 
  render() {
    let {job} = this.props;
    let {
      jobStore: {
        deleteJob: {
          isLoading,
          object
        }
      }
    } = this.props;
    return(
      <Dialog
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <p style={{textAlign: 'center', fontWeight: 600}}>
            Are you sure you want to delete, job# {job.attributes.event_number}?
            <br/>
            And want would you like to delete that's associated to the job?
          </p>
          
          <input
            type='checkbox'
            className="bp3-checkbox"
            checked={object.work_reports}
            onChange={(event) => {object.work_reports = event.target.checked}}
            /> <span> Work Orders </span>

          <br/>

          <input
            type='checkbox'
            className="bp3-checkbox"
            checked={object.estimates}
            onChange={(event) => {object.estimates = event.target.checked}}
            /> <span> Estimates </span>
          
          <br/>
          
          <input
            type='checkbox'
            className="bp3-checkbox"
            checked={object.invoices}
            onChange={(event) => {object.invoices = event.target.checked}}
            /> <span> Invoices </span>

          <br/><br/>
          Type job number to delete the job below <br/>
          <FormGroup
            helperText={this.errorMsg}
            labelFor="text-input"
            intent={this.intent}
          >
            <input
              type='text' 
              className={this.classes}
              placeholder='Job#'
              onChange={(event) => {object.event_number = event.target.value} }
            />
          </FormGroup>

        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseDailog.bind(this)} className="btn-notes-close">Close</Button>
            <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.deleteJob.bind(this)}>Delete</Button>
          </div>
        </div>
      </Dialog>
    )
  }

};