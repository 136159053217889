import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Alert, Intent, Button, ButtonGroup, TextArea
} from '@blueprintjs/core';
import { observable, action } from 'mobx';
import DateTimeInput from 'components/DateTimeInput';
import LabourTaxItem from './LabourTaxItem';
import Toast from 'helpers/toaster';
import moment from 'moment';
import _ from 'lodash';
import { currencyFormattedAmount } from 'helpers/currency';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';
import ProductServiceAutocomplete from 'components/ProductServiceAutocomplete';

@inject('store', 'invoiceStore') @observer
class LabourItem extends Component {
  @observable isDeleteDialogVisible = false;
  @observable lineitem;

  componentDidMount() {
    const {
      labour
    } = this.props;
    if (labour.markup) {
      labour.total = parseFloat(labour.quantity) * parseFloat(labour.rate_per_unit) * parseFloat(labour.markup)
    } else {
      labour.total = parseFloat(labour.quantity) * parseFloat(labour.rate_per_unit)
    }
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.invoiceStore.deleteLineItem(this.lineitem)
      .then(this.onSuccessfulDeleteLabor.bind(this))
      .catch(this.onFailDeleteLabor.bind(this))
  }

  onSuccessfulDeleteLabor(response) {
    Toast.show({ icon: 'thumbs-up', message: 'successfully deleted labor!', intent: Intent.SUCCESS })
    this.lineitem = null;
    this.isDeleteDialogVisible = false;
  }

  onFailDeleteLabor(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete labor!', intent: Intent.DANGER })
  }

  handleLaborClick(event, lineitem) {
    switch (event.label) {
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.lineitem = lineitem;
        break;
      default:
    }
  }

  handleQuantityChange(event) {
    let { labour } = this.props;
    labour.quantity_display_value = event.target.value === "" ? 0 : event.target.value;
    labour.quantity = parseFloat(labour.quantity_display_value)
    this.calculateLabour();
  }

  handleHourlyRateChange(event) {
    let { labour } = this.props;
    var value = event.target.value === "" ? 0 : event.target.value.replace('$', '')
    labour.rate_per_unit = value;
    labour.rate_per_unit_display_value = currencyFormattedAmount(labour.rate_per_unit)
    this.calculateLabour();
  }

  @action
  onProductSelect(labourDetail) {
    let {
      labour
    } = this.props;

    if (labourDetail) {
      labour.product_service_id = labourDetail.id
      labour.product_service = labourDetail
    } else {
      labour.product_service_id = null
      labour.product_service = null
    }
  }


  calculateLabour() {
    let { labour } = this.props;
    if (labour.markup) {
      labour.total = parseFloat(labour.quantity) * parseFloat(labour.rate_per_unit) * parseFloat(labour.markup)
    } else {
      labour.total = parseFloat(labour.quantity) * parseFloat(labour.rate_per_unit)
    }
    labour.total_display_value = currencyFormattedAmount(labour.total)

    let {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;

    if (object.attributes.custom_line_items.length > 0) {
      _.each(object.attributes.custom_line_items, function (lineItem) {
        if (lineItem.default_line_item_type === "percentage_of_labour" && !lineItem.override) {
          // rate update
          lineItem.rate_per_unit = _.sumBy(object.attributes.labour, 'total') * lineItem.default_line_item_value / 100;
          lineItem.rate_per_unit_display_value = currencyFormattedAmount(lineItem.rate_per_unit)
        }

        if (lineItem.default_line_item_type === "percentage_of_materials_labour" && !lineItem.override) {
          // rate update
          lineItem.rate_per_unit = (_.sumBy(object.attributes.labour, 'total') + _.sumBy(object.attributes.materials, 'total')) * lineItem.default_line_item_value / 100;
          lineItem.rate_per_unit_display_value = currencyFormattedAmount(lineItem.rate_per_unit)
        }

        // total update
        if (lineItem.markup) {
          lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit) * parseFloat(lineItem.markup)
        } else {
          lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit)
        }
        lineItem.total_display_value = currencyFormattedAmount(lineItem.total)
      });
    }

  }

  onLabourDateChange(date) {
    let { labour } = this.props;
    labour.work_date = moment(date);
  }

  refreshLineitem() {
    this.props.refresh()
  }

  render() {
    const {
      labour
    } = this.props;
    return (
      <React.Fragment>


        <tr key={labour.id}>
          <td className="p-0 border-left-0">
            <ButtonGroup
              fill={true}
              alignText="left"
              large={true}
              minimal
            >
              <ProductServiceAutocomplete
                value={labour.product_service}
                onProductSelect={this.onProductSelect.bind(this)}
              />
            </ButtonGroup>
          </td>
          <td className="p-0">
            <DateTimeInput
              placeholder={'Labor Date'}
              defaultValue={moment(labour.work_date)}
              onChange={this.onLabourDateChange.bind(this)}
            />
          </td>
          <td className="p-0">
            <input
              type='number'
              className='bp3-input bp3-fill'
              placeholder='Hours'
              defaultValue={parseFloat(labour.quantity) || 0}
              onChange={(event) => this.handleQuantityChange(event)}
            />
          </td>
          <td className="p-0">
            <input
              type='number'
              className='bp3-input bp3-fill'
              placeholder='Hourly Rate'
              defaultValue={labour.rate_per_unit || ''}
              onChange={(event) => this.handleHourlyRateChange(event)}
            />
          </td>

          <td className="py-0">
            <strong> {currencyFormattedAmount(labour.total)}</strong>
          </td>
          <td rowSpan="3" className="border-0">
            <Button
              minimal
              type='button'
              icon={"cross"}
              onClick={() => this.handleLaborClick({ label: 'Delete' }, labour)}
              className="row-delete-btn"
            />
          </td>
        </tr>
        <tr>
          <td colSpan="5" className="p-0 border-left-0">
            <TextArea
              growVertically={true}
              className="bp3-input bp3-fill"
              placeholder="Description"
              defaultValue={labour.description}
              onChange={(event) => { labour.description = event.target.value; }}
            />
          </td>
        </tr>

        { labour.tax_line_items && labour.tax_line_items.length > 0 && labour.tax_line_items.map((tax) => {
          return (
            <LabourTaxItem taxItem={tax} key={tax.id} labour={labour} refresh={this.refreshLineitem.bind(this)} />
          )
        })
        }

        <div style={{ height: 10 }}></div>
        {/* <tr key={labour.id}>
        
        <tr key={Math.random()}>

          <td colSpan="2" width="25%">
            <ProductServiceAutocomplete
              value={labour.product_service}
              onProductSelect={this.onProductSelect.bind(this)} 
            />
          </td>
          <td colSpan="7"></td>
        </tr>
        <tr key={labour.id}>
          <td colSpan="2">
            <label className="only-mobile">Description</label>
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Description'
              defaultValue={labour.description}
              onChange={(event) => {labour.description = event.target.value;}} 
            />
          </td>
          <td style={{ width: '29%' }}>
            <label className="only-mobile">Date</label>
            <div className="fullWidth">
              <DateTimeInput 
                placeholder={'Labor Date'}
                defaultValue={moment(labour.work_date)}
                onChange={this.onLabourDateChange.bind(this)}
              />
            </div>
          </td>
          <td style={{ width: '15%' }}>
            <label className="only-mobile">Hours</label>
            <input 
              type='number' 
              className='bp3-input bp3-fill' 
              placeholder='Hours'
              defaultValue={parseFloat(labour.quantity) || 0}
              onChange={(event) => this.handleQuantityChange(event) }
            />         
          </td>
          <td>
            <label className="only-mobile">Hourly Rate($)</label>
            <input 
              type='number' 
              className='bp3-input bp3-fill' 
              placeholder='Hourly Rate'
              defaultValue={labour.rate_per_unit || ''}
              onChange={(event) => this.handleHourlyRateChange(event) }
            />    
          </td>
          <td>
            <label className="only-mobile">Total</label>
            {currencyFormattedAmount(labour.total)}
          </td>
          <td style={{ width: '5%' }}>
            <button 
              className='bp3-button bp3-minimal bp3-icon-trash bp3-small'
              type='button'
              onClick={() => this.handleLaborClick({ label: 'Delete' }, labour)}
            />
          </td>
          
        </tr> */}
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>Warnning! Deleting this labor will not be recoverable.</p>
          <p>Are you sure you want to do this?</p>
        </Alert>

      </React.Fragment>
    )
  }

};

export default LabourItem;