import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup, Button, Dialog, Classes, Intent, Checkbox, Spinner
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';

@inject('xeroStore') @observer
class ProductServiceXeroModal extends Component {

  handleCloseDailog() {
    this.props.onClose()
  }

  createProductService() {
    let { xeroStore } = this.props;
    xeroStore.createProductService()
      .then(this.onSuccessfullyCreateProductService.bind(this))
      .catch(this.onFailCreateProductService.bind(this))
  }

  onSuccessfullyCreateProductService(response) {
    this.props.onSuccess()
    Toast.show({ message: "Items created successfully.", intent: Intent.SUCCESS })
  }

  onFailCreateProductService(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ message: "Something went wrong. Failed to create/update items", intent: Intent.DANGER })
    }
  }

  render() {
    let {
      title,
      xeroStore: {
        xeroItem: {
          object,
          isLoading,
        }
      }
    } = this.props;
    return(
      <Dialog
        title={title}
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Code"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Code'
              defaultValue={object.code}
              onChange={(event) => { object.code = event.target.value } } 
            />
          </FormGroup>

          <FormGroup
            label="Name"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Name'
              defaultValue={object.name}
              onChange={(event) => { object.name = event.target.value } } 
            />
          </FormGroup>

          <FormGroup
            label="Description"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Description'
              defaultValue={object.description || ""}
              onChange={(event) => { object.description = event.target.value } } 
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseDailog.bind(this)}>Close</Button>
            <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.createProductService.bind(this)}>
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
};

export default ProductServiceXeroModal;