import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from '@emotion/styled';
import qs from 'qs';
import { observable } from 'mobx';

const SecondSidebar = styled('div')`     
    width: 100%;
    height: calc(100vh - 75px);
    border-right: 1px solid #E6E8F1;
    padding: 0px 0px 15px;       
    background-color:#ffffff;   
    position: sticky;
    top: 45px;    
`

@inject('viewStore') @observer
class ClientsSidebar extends Component {

  @observable isOpenClientSidebar = false;

  render(){
    let {
      viewStore
    } = this.props;
    return(
      <SecondSidebar className="sidebar-mobile">
        <div className="mobile-dropdown"> 
          <div className="setting-icon"><img src="/settings.svg" onClick={() => this.isOpenClientSidebar = !this.isOpenClientSidebar}/></div>  
          <div className={this.isOpenClientSidebar ? "ssidebar-wrap " : "ssidebar-wrap ssidebar-wrap-hide" }>
            <ul className="list-unstyled setting-list">              
              <li className={viewStore.currentView === "Pages.Clients.Index" ? 'active' : ''}>
                <a href={`/dashboard/clients?${qs.stringify(viewStore.clientSearch, { encode: false })}`} >Clients</a>
              </li>
              <li className={viewStore.currentView === "Pages.ServiceAgreements.Index" ? 'active' : ''}>
                <a href={`/dashboard/service_agreements?${qs.stringify(viewStore.serviceAgreementsSearch, { encode: false })}`} >Service Agreements</a>
              </li>
            </ul>            
          </div>
        </div> 
      </SecondSidebar>
    )
  }
}
  
export default ClientsSidebar;