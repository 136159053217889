import React, { Component } from 'react'
import { Button, ButtonGroup, Intent, Spinner } from '@blueprintjs/core';
import { action } from 'mobx';
import { observer, inject } from 'mobx-react';
import Toast from 'helpers/toaster';

var url_string = window.location.href;
var url = new URL(url_string);
var is_import = url.searchParams.get("import");

@inject('store', 'viewStore','clientStore') @observer
class Client extends Component {

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.clientSearch.page = 1;
    viewStore.clients();
  }

  @action
  onSearchTermChange(e) {
    let {
      props: {
        viewStore: {
          clientSearch
        }
      }
    } = this;
    this.props.viewStore.clientSearch = {
      ...clientSearch,
      term: e.target.value
    }
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.search()
    }
  }

  handleUploadingCustomerCSV = (event) => {
    var file = event.target.files[0];
    if(file.type == 'text/csv'){
      this.props.clientStore.showUploadAttachmentLoader = true;
      this.props.clientStore.uploadCsv(file)
      .then(this.onSuccessfullUploadAttachment.bind(this))
      .catch(this.onFailUploadAttachment.bind(this)) 
    }else{ 
      Toast.show({ icon: 'warning-sign', message: 'File type not matched, only .csv allowed!', intent: Intent.DANGER })
    }   
  };

  onSuccessfullUploadAttachment(response) {
    let {
      clientStore: {
        importClients: {
          objects
        }
      }
    } = this.props;
    this.props.clientStore.showUploadAttachmentLoader = false;
    Toast.show({ icon: 'thumbs-up', message: 'Client records imported successfully!', intent: Intent.SUCCESS })
    this.props.clientStore.loadClients();
  }

  onFailUploadAttachment(error) {
    this.props.clientStore.showUploadAttachmentLoader = false;
    if (error.response && error.response.data && error.response.data.error) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.error, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong, failed to upload file!', intent: Intent.DANGER })
    }
  }

  render() {
    let {
      props: {
        store,
        viewStore: {
          clientSearch: { term }
        },
        clientStore:{
          showUploadAttachmentLoader
        }
      }
    } = this;

    return (
      <nav className="bp3-navbar search search-bar-responsive bg-transparent navbar-overflow navbar-height">
        <div style={{display:'flex',justifyContent:'space-between'}}>
          <div className="bp3-navbar-group bp3-align-left search-responsive">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <input 
                  type="text"
                  className="bp3-input input-control"
                  placeholder="Client Name, Number, Email or Address"
                  onChange={this.onSearchTermChange.bind(this)}
                  defaultValue={term}
                  onKeyPress={this.onEnter.bind(this)}
                />
                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
                
                {/* <Button rightIcon='refresh' text='Reset' onClick={() => { this.search = {} }}/> */}
                {/* <Button rightIcon='grouped-bar-chart' onClick={() => { this.props.store.visible = !this.props.store.visible }}/> */}
              </div>
            </div>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            {
              is_import == 'true' &&
              <div className="bp3-button-group">
                <div className="bp3-control-group">
                  <span className="btn btn-light btn-sm btn-file">
                    Import Clients <input type="file" onChange={this.handleUploadingCustomerCSV.bind(this)}/>
                    {showUploadAttachmentLoader && 
                      <Spinner size={Spinner.SIZE_SMALL} /> }
                  </span>       
                </div>
              </div>
            }
            
            <ButtonGroup>
              {/* <Button icon='plus' onClick={() => { store.isOpenAddClientDialog = true }}>
                Add New Client
              </Button> */}
              {/* <Button rightIcon='caret-down'>
                Options
              </Button> */}
            </ButtonGroup>
          </div>
        </div>
      </nav>
    )
  }
}

export default Client;
