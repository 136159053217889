import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import Page from 'components/Page';
import ClientProfileCardSupplier from 'components/Client/ProfileCardSupplier';
import styled from '@emotion/styled';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 5px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);  
`

@inject('store', 'supplierStore', 'viewStore') @observer
class SuppliersShowPage extends Component {

  get canRender() {
    let {
      supplierStore: {
        supplier: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object && object.id);
  }

  updateSelectedResource = (resource) => {
    let {
      viewStore,
      supplierStore: {
        supplier: {
          object
        }
      }
    } = this.props;
    viewStore.supplierShowSearch.resource = resource;
    viewStore.supplierViewSearch(object.attributes.id,object.attributes.name);
  }

  render() {
    let content, title;
    let {
      viewStore,
      supplierStore: {
        supplier: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      title = 'Loading..';
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load Customer';
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      title = `${object.attributes.name} | Field Nexus`;
      content = <ClientProfileCardSupplier 
      supplier={object}
      selectedResource={viewStore.supplierShowSearch.resource}
      onResourceChange={this.updateSelectedResource.bind(this)}
    />
    }
    return (
      <div>
        {
          <Helmet>
            <title>{title}</title>
          </Helmet>
        }
        <PageWrapGray>
        <Page>
          {content}
        </Page>
        </PageWrapGray>
      </div>
    )
  }

};

export default SuppliersShowPage;