import React from 'react';
import { DateRangePicker } from "@blueprintjs/datetime";
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

@observer
class SearchDateRangePicker extends React.Component {

  @observable startDate = null;
  @observable endDate = null;

  constructor(props) {
    super(props);
    this.startDate = this.props.startDate;
    this.endDate = this.props.endDate;
  }

  onDateRangeChange(range) {
    this.startDate = range[0];
    this.endDate = range[1];
    if (this.startDate && this.endDate) {
      this.props.onChange(range)
    }
  }

  render() {
    return (
      <DateRangePicker 
        value={[this.startDate, this.endDate]}
        formatDate={(d) => { return d.toDateString() }} 
        parseDate={(d) => { return new Date(d) }}
        allowSingleDayRange={true}
        onChange={this.onDateRangeChange.bind(this)}
        className="responsive-calender"
        maxDate={moment().add(25, 'years').toDate()}
        allowSingleDayRange={true}
      />
    )
  }
}

SearchDateRangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
}

export default SearchDateRangePicker;