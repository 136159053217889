import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import FormSubmissionRow from './FormSubmissionRow';
import { Intent, Button } from '@blueprintjs/core';
import AddFormModal from 'components/WorkReport/AddFormModal';

@inject('store', 'workReportStore', 'formStore') @observer
export default class WorkFormSubmissions extends Component {

  @observable formSubmissions = [];
  @observable isOpenAddFormDialog = false;

  componentWillMount() {
    const {
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
    this.formSubmissions = object.attributes.forms;
  }

  handleCloseFormDailog() {
    this.isOpenAddFormDialog = false;
  }

  onSuccessAddForm(image) {
    this.isOpenAddFormDialog = false;
  }

  openAddFormModal() {
    this.props.formStore.loadForms();
    this.isOpenAddFormDialog = true;
  }

  render() {
    return (
      <React.Fragment>
        <div className="job-desription table-title" style={{ paddingLeft: '10px' }}>
          <h5><strong>Forms</strong></h5>
          <Button icon='add' className="bp3-minimal add-btn" onClick={() => this.openAddFormModal(this)}>Add Form</Button>
        </div>

        <div style={{ marginBottom: '2rem' }}>
          <div style={{ padding: '0px 10px' }} >
            {
              this.formSubmissions.length === 0 && <div className="bp3-callout warning-alert-message bp3-intent-warning bp3-icon-info-sign table-card">
                No forms have been added to this Work Order.
              </div>
            }
          </div>
          {
            this.formSubmissions.length > 0 &&
            <div className="wr-row">
              <table className='table table-bordered border-0' width="100%">
                <thead>
                  <tr>
                    <th style={{ width: 310 }}>Name</th>
                    <th style={{ width: 220 }}>Created By</th>
                    <th style={{ width: 200 }}>Created At</th>
                    <th style={{ width: 50 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.formSubmissions.map((formSubmission) => {
                      return (
                        <FormSubmissionRow formSubmission={formSubmission} key={formSubmission.id} />
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          }
        </div>
        <AddFormModal
          isOpen={this.isOpenAddFormDialog}
          onClose={this.handleCloseFormDailog.bind(this)}
          onSuccess={this.onSuccessAddForm.bind(this)}
        />
      </React.Fragment>
    )
  }

}