import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { FormGroup, ControlGroup, Dialog, Classes, Button, Intent } from '@blueprintjs/core';
import DateTimeInput from 'components/DateTimeInput';
import moment from 'moment';
import Toast from 'helpers/toaster';
import TechnicianPicker from 'components/TechnicianPicker';

@inject('userStore') @observer
class NewVacationModal extends React.Component {

  @observable minDateStart = moment().subtract(5, 'month');

  persistVacation() {
    const {
      userStore,
      userStore: {
        new_vacation: {
          object
        }
      }
    } = this.props;

    let promise = userStore.createUserVacation()
    

    promise
      .then(this.onSuccessfullCreateOrUpdateVacation.bind(this))
      .catch(this.onFailureCreateOrUpdateVacation.bind(this))
  }

  onSuccessfullCreateOrUpdateVacation(response) {
    this.props.onClose()
  }

  onFailureCreateOrUpdateVacation(error) {
    Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
  }

  handleCloseVacationDailog() {
    this.props.onClose()
  }

  handleStartDateChange(time) {
    let { object } = this.props.userStore.new_vacation;
    if(time) {
      object.start_date = moment(time)
    }
  }

  handleEndDateChange(time) {
    let { object } = this.props.userStore.new_vacation;
    if(time) {
      object.end_date = moment(time)
    }
  }

  onTechnicianChange(technicians) {
    let { object } = this.props.userStore.new_vacation;
    let selectedValues = technicians.map((status) => status.value);
    object.user_id = selectedValues;
  }

  createVacation() {
    this.props.userStore.createTechnicianVacation()
      .then(this.onSuccessfullCreateVacation.bind(this))
      .catch(this.onFailureCreateVacation.bind(this))
  }

  onSuccessfullCreateVacation(response) {
    this.props.onClose()
    Toast.show({ icon: 'thumbs-up', message: 'Vacation added successfully.', intent: Intent.SUCCESS })
  }

  onFailureCreateVacation(error) {
    Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
  }
 
  render() {
    const {
      isOpen,
      userStore: {
        new_vacation: {
          isLoading,
          object
        }
      }
    } = this.props;
    return(
      <Dialog
        title={"Add Vacation"}
        isOpen={isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <h6>Select User</h6>
          <div className="well">
            <FormGroup
              labelFor="text-input"
            >
              <TechnicianPicker 
                selectedOptions={object.user_id}
                onChange={this.onTechnicianChange.bind(this)}
                maximumSelections={1}
                showUser
                includeAdmins
              />
            </FormGroup>
          </div>
          <FormGroup
            label="When?"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <ControlGroup fill={true} vertical={false}>
              <DateTimeInput 
                placeholder={'Start Date'} 
                minDate={this.minDateStart}
                defaultValue={moment(object.start_date)} 
                onChange={this.handleStartDateChange.bind(this)} />
              
              <DateTimeInput 
                placeholder={'End Date'} 
                minDate={this.minDateStart}
                defaultValue={moment(object.end_date)} 
                onChange={this.handleEndDateChange.bind(this)} />
            </ControlGroup>
          </FormGroup>

          <FormGroup
            label="Notes"
            labelFor="text-input"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Notes'
              defaultValue={object.notes}
              onChange={(event) => { object.notes = event.target.value } } 
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseVacationDailog.bind(this)}>Close</Button>
            <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.createVacation.bind(this)}>Save</Button>
          </div>
        </div>
      </Dialog>
    )
  }

};

export default NewVacationModal;