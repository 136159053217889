import React, { Component } from 'react';
import { 
  Dialog, Classes, Button, Intent, ControlGroup
} from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Toast from 'helpers/toaster';

@inject('userStore') @observer
class InviteUserDialog extends Component {

  @observable users = [
    {
      email: '',
      first_name: '',
      last_name: ''
    }
  ];
  @observable showLoader = false;
  @observable user = {
    email: '',
    first_name: '',
    last_name: ''
  }

  clearDialog() {
    this.users = [
      {
        email: '',
        first_name: '',
        last_name: ''
      }
    ];
  }

  handleClose() {
    this.props.onClose();
    this.clearDialog();
  }

  incrementCount() {
    this.users = this.users.concat([
      {
        email: '',
        first_name: '',
        last_name: ''
      }
    ])
  }

  handleChange(event, index, key) {
    this.users[index][key] = event.target.value;
  }

  invite() {
    var error = false;
    if (this.users.some(user => user.email === "")) {
      error = true;
    }
    if (error) {
      Toast.show({ icon: 'warning-sign', message: 'Email is required!', intent: Intent.DANGER })
    } else {
      this.props.userStore.inviteUser(this.users)
        .then(this.onSuccessInviteUser.bind(this))
    }
  }

  onSuccessInviteUser(response) {
    this.props.onSuccess(response);
    this.clearDialog();
  }

  render() {
    return(
      <Dialog
        title="Invite User"
        icon="info-sign"
        isOpen={this.props.isOpen}
        onClose={this.handleClose.bind(this)}
        style={{width: 580, height: 300}}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="multipleUrsers">
            {
              this.users.map((user, index) => {
                return (
                  <ControlGroup key={index} style={{marginBottom: 10}}>
                    <input 
                      type='email' 
                      className='bp3-input' 
                      placeholder='Email'
                      defaultValue={user.email}
                      onChange={(event) => this.handleChange(event, index, 'email')} />

                    <input 
                      type='text' 
                      className='bp3-input' 
                      placeholder='First Name'
                      defaultValue={user.first_name}
                      onChange={(event) => this.handleChange(event, index, 'first_name')} />

                    <input 
                      type='text' 
                      className='bp3-input' 
                      placeholder='Last Name'
                      defaultValue={user.last_name}
                      onChange={(event) => this.handleChange(event, index, 'last_name')} />
                  </ControlGroup> 
                )
              })
            }
            <Button className="bp3-right bp3-intent-primary" onClick={this.incrementCount.bind(this)}>ADD MORE</Button>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleClose.bind(this)}>CLOSE</Button>
            <Button className="bp3-intent-primary" onClick={this.invite.bind(this)}>INVITE</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default InviteUserDialog;