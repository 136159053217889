import React from 'react';
const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

class JobMapView extends React.Component {
  componentDidMount() {
    let { location } = this.props.job.attributes;
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    var map = new mapboxgl.Map({
      container: 'mapbox',
      style: 'mapbox://styles/jasdeepsingh/cjq4g50wq5j4y2rn0zs11tehw',
      center: { lon: location.longitude, lat: location.latitude },
      zoom: 14,
      pitch: 60
    });
    
    map.addControl(new mapboxgl.NavigationControl());
    new mapboxgl.Marker()
      .setLngLat({ lon: location.longitude, lat: location.latitude })
      .addTo(map);
  }

  render() {
    return (
      <div id='mapbox' style={{ height: '100vh', width: '100%' }}></div>
    )
  }
}

export default JobMapView;