import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { 
  Spinner, NonIdealState,
  Button, Intent, Tag,
  ButtonGroup, Position, Popover, MenuItem, Menu
} from '@blueprintjs/core';
import styled from '@emotion/styled';
import ListPicker from 'components/ListPicker';
import WorkReportShow from 'components/WorkReport/Show';
import { TopBanner } from 'components/Styles';
import Toast from 'helpers/toaster';
import stores from '../../stores';
import SendWorkReportModal from 'components/WorkReport/SendWorkReportModal';
import AddInvoiceNumberModal from 'pages/work-reports/AddInvoiceNumberModal';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`
const options = [
  { label: 'Draft', value: 'draft', selected: false },
  { label: 'Submitted', value: 'submitted', selected: false },
  { label: 'Reviewed', value: 'reviewed', selected: false },
  { label: 'Invoiced', value: 'invoice', selected: false }
];

@inject('store', 'workReportStore') @observer
class WorkOrderShowHeader extends Component {

  @observable downloadingPdf = false;
  @observable isOpenSendWorkReportModal = false;
  @observable isOpenAddInvoiceNumberDialog = false;

  downloadWorkOrderPDF = () => {
    this.downloadingPdf = true;
    this.props.workReportStore.downloadWorkOrderPDF()
      .then((response) => {
        this.downloadingPdf = false;
      })
      .catch((error) => {
        this.downloadingPdf = false;
      })
  }

  sendWorkReportPDF = () => {
    this.isOpenSendWorkReportModal = true;
  }

  unlinkInvoice(workReport) {
    this.props.workReportStore.unlinkInvoice(workReport)
      .then((response) => {
        Toast.show({ message: "Invoice unlinked successfully.", intent: Intent.SUCCESS })
      })
      .catch((error) => {
        if(error.response.data) {
          Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ message: "Problem in unlinked invoice", intent: Intent.DANGER })
        }
      })
  }

  generateInvoice(workReport) {
    this.props.workReportStore.generateInvoice(workReport)
      .then((response) => {
        stores.viewStore.ShowInvoice({ uuid: response.data.data.id })
        Toast.show({ message: "Invoice generated successfully.", intent: Intent.SUCCESS })
      })
      .catch((error) => {
        if(error.response && error.response.data) {
          Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ message: "Problem in generating invoice", intent: Intent.DANGER })
        }
      })
  }

  openInvoice(workReport){
    stores.viewStore.ShowInvoice({ uuid: workReport.attributes.invoice_id })
  }

  handleCloseWorkReportSendDailog() {
    this.isOpenSendWorkReportModal = false;
  }

  onSuccessAddInvoiceNumber() {
    this.isOpenAddInvoiceNumberDialog = false;
  }

  handleCloseInvoiceNumberDailog() {
    this.isOpenAddInvoiceNumberDialog = false;
  }

  onSuccessWorkReportSend() {
    this.isOpenSendWorkReportModal = false;
  }

  onWorkReportStatusChange(selections) {
    let {
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
    let selectedValues = selections.map((status) => status.value);
    object.attributes.status = selectedValues[0];
    
    if(object.attributes.status) {
      this.props.workReportStore.changeStatus(object, object.attributes.status)
        .then((response) => {
          if(response.data.data.attributes.status === 'invoiced' || response.data.data.attributes.status === 'invoice'){
            this.isOpenAddInvoiceNumberDialog = true;
          }
          Toast.show({ message: "Work Report status changed successfully.", intent: Intent.SUCCESS })
        })
        .catch((error) => {
          if(error.response && error.response.data) {
            Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
          } else {
            Toast.show({ message: "Something went wrong. Problem in changing work report status.", intent: Intent.DANGER })
          }
        })
    }
  }

  render() {
    let {
      workReportStore,
      workReportStore: {
        workReport: {
          object
        },
        isGeneratingInvoice
      }
    } = this.props;

    const moreAction = (
      <Menu>
        { 
          object.attributes.status !== 'invoiced' && 
            <MenuItem MenuItem icon="link" text="Generate Invoice" onClick={() => this.generateInvoice(object) } loading={isGeneratingInvoice} />
        }
        { 
          object.attributes.status === 'invoiced' && object.attributes.invoice_id && 
            <MenuItem text="View Invoice" icon='eye-open' onClick={() => this.openInvoice(object) } loading={isGeneratingInvoice} />
        }
        { 
          object.attributes.status === 'invoiced' && 
            <MenuItem text="Unlink Invoice" icon='undo' onClick={() => this.unlinkInvoice(object) } loading={isGeneratingInvoice} />
        }
        <MenuItem icon="eye-open" text="View PDF" onClick={() => workReportStore.showPdfViewer = true }/>
        <MenuItem icon="download" text="Download PDF" onClick={this.downloadWorkOrderPDF} loading={this.downloadingPdf}/>
      </Menu>
    );

    return (
      <TopBanner className="top-bar-styling">
        <div className="work-report-style" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="work-report-title">
            <h3>Work Order {object.attributes.number}</h3>
            <span className="bp3-tag">
              {object.attributes.status}
            </span>
          </div>
          <div className="btn-grid-style more-actions">
            <Popover 
              minimal={true}
              content={
                <ListPicker
                  selectedOptions={[object.attributes.status]}
                  options={options}
                  maximumSelections={1}
                  onChange={this.onWorkReportStatusChange.bind(this)}
                />
              }
              position={Position.BOTTOM_LEFT}
              usePortal={true} 
              className="status-selector"
              >
              <Button rightIcon='caret-down' className="status-btn">
                Status
                <span>&nbsp;</span>
                <Tag minimal={true} round={true}>{object.attributes.status}</Tag>
              </Button>
            </Popover>
            <div className="actions-dropdwon" options={false} {...this.props}>
              <Popover content={moreAction} position={Position.BOTTOM}>
                  <Button className="action-btn" icon="chevron-down" text="More Actions" />
              </Popover>
            </div>
            <Button icon='plus' className="status-btn"onClick={() => { this.isOpenAddInvoiceNumberDialog = true}}>
              {object && object.attributes && object.attributes.invoice_number ? 'Update Invoice Number' : 'Add Invoice Number'}
            </Button>
            <Button icon='upload' className="bp3-intent-primary primary-btn" onClick={this.sendWorkReportPDF}>
              Send Work Order
            </Button>
          </div>
        </div>
        <SendWorkReportModal
          isOpen={this.isOpenSendWorkReportModal}
          onClose={this.handleCloseWorkReportSendDailog.bind(this)}
          onSuccess={this.onSuccessWorkReportSend.bind(this)}
        />
        <AddInvoiceNumberModal 
          isOpen={this.isOpenAddInvoiceNumberDialog}
          onClose={this.handleCloseInvoiceNumberDailog.bind(this)}
          onSuccess={this.onSuccessAddInvoiceNumber.bind(this)}
        />
      </TopBanner>
    )
  }
}

@inject('store', 'workReportStore') @observer
class WorkReportShowPage extends Component {

  get canRender() {
    let {
      workReportStore: {
        workReport: {
          isError,
          object
        }
      }
    } = this.props;
    return (!isError && object && object.id);
  }

  render() {
    let content, title;
    let {
      workReportStore: {
        workReport: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError && object && !object.id) {
      title = 'Loading...'
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load Work Order'; 
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      title = `#${object.attributes.number} Work Order | Field Nexus`;
      content = <WorkReportShow />;
    }
    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        { this.canRender && <WorkOrderShowHeader /> }
        <PageWrapGray>
          {content}
        </PageWrapGray>
      </div>
    )
  }
};

export default WorkReportShowPage;