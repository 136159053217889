import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Button, Dialog, Classes, Intent
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import ProductService from './ProductService';
import Icons from '../../svgs';

@inject('invoiceStore', 'quickbooksStore', 'productServiceStore') @observer
class ProductServiceSyncWithQuickbooksModal extends Component {

  linkProductService() {
    this.props.productServiceStore.linkProductServiceWithQuickbooks()
      .then(this.onSuccessfullLink.bind(this))
      .catch(this.onFailLinkProductService.bind(this))
  }

  onSuccessfullLink(response) {
    this.props.onSuccess()
  }

  onFailLinkProductService(error) {
    //this.props.onClose();
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ message: "Something went wrong. Failed to link Product and Services", intent: Intent.DANGER })
    }
  }

  handleCloseDailog() {
    this.props.onClose();
  }

  render() {
    let {
      quickbooksStore: {
        productServiceStatus: {
          object
        }
      },
      productServiceStore: {
        productServiceLink: {
          isLoading
        }
      }
    } = this.props;
    return(
      <Dialog
        title="Sync Product and Services with QuickBooks"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        canOutsideClickClose={false}
        onClose={this.handleCloseDailog.bind(this)}
      >
        <div className={Classes.DIALOG_BODY}>
          {Object.keys(object).map((obj) => {
              return(
                <ProductService object={obj} key={Math.random()} />
              )
            })
          }
        </div>
        
        <div className="product-services-button">
          <Button
            onClick={this.linkProductService.bind(this)}
            loading={isLoading}
            style={{ width: 'auto' }}
          >
            <Icons.LinkIcon /> Link
          </Button>
        </div>
      </Dialog>
    )
  }
}

export default ProductServiceSyncWithQuickbooksModal;