import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { 
  Button,FormGroup,Checkbox, Divider,
  Intent, Spinner, ButtonGroup, RadioGroup, Radio, 
  MenuItem, Menu, Popover, Position, Dialog
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import DateTimeInput from 'components/DateTimeInput';
import moment from 'moment';
import GeoComplete from '../../helpers/GeoComplete';
import { currencyFormattedAmount } from 'helpers/currency';
import CopyLink from '../bookings/copy_link.js';
import QuestionRadio from 'components/Booking/QuestionRadio';
import QuestionSelect from 'components/Booking/QuestionSelect';
import QuestionTextbox from 'components/Booking/QuestionTextbox';

var startCountRange = 0;
var label= '';
var indexChecked = '';
var selectedAnswer = '';


const items=[ 
  { label: 'Cash', value: 'Cash' }, 
  { label: 'Interac', value: 'Interac' },
  { label: 'Cheque', value: 'Cheque' },
  { label: 'Debit/Credit', value: 'dr/cr' }
];

const PageWrapGray = styled('div')`
  min-height: calc(100vh - 75px);
  width: 100%;
`
@inject('store','bookingStore','serviceTypeStore','pricingParameterStore','frequencyStore','serviceAgreementStore','jobStore','questionGroupStore','serviceTypeCategoryStore','viewStore') @observer
class BookingShowPage extends Component {
  @observable pricingParameterArray = [];

  constructor(props) {
    super(props);
    this.state = {
      isServiceTypeChecked: 0,
      isPaymentSelect:0,
      isFrequencySelected: null
    };
  }

  @observable startCountRange = 0;
  @observable extra_service_total = 0;
  @observable pricing_parameter_total = 0;
  @observable pricing_range_total = 0;
  @observable isServiceExtraChecked = 0;
  @observable grand_total = 0.0;
  @observable showCopyNewBrandLogoLinkDialog = false;
  @observable showCopyLinkDialog = false;
  @observable showCopyNewLinkDialog = false;

  componentDidMount() {
    let {
      questionGroupStore,
      serviceTypeStore,
      serviceTypeCategoryStore,
      bookingStore:{
        booking:{
          object
        }
      }
    } = this.props;
    if(object && object.attributes){
      this.grand_total = object.attributes.total;
    }
    serviceTypeCategoryStore.loadServiceTypeCategories();
    serviceTypeStore.loadServiceTypesBooking();
    questionGroupStore.loadQuestionGroups();
  }

  startEditJob(job,booking_id) {
    let params = {
      uuid: job.id
    }
    
    this.props.jobStore.loadJobForBooking(params).then(response => {
      let {
        jobStore: {
          job
        }
      } = this.props;
      this.props.jobStore.persistJob = {
        object: {
          ...job,
          id: job.object.attributes.id,
          start_date: moment(job.object.attributes.start_date),
          end_date: moment(job.object.attributes.end_date),
          isServiceAgreement: true,
          title: job.object.attributes.title,
          po_number: job.object.attributes.po_number,
          reason_for_call: job.object.attributes.reason_for_call,
          //service_agreement_id: serviceAgreement.object.id,
          location_id: job.object.attributes.location_id,
          location: {
            attributes: {
              ...job.object.attributes.location,
              name: job.object.attributes.location.full_address
            }
          },
          client: {
            attributes: job.object.attributes.client
          },
          selectedServiceType: [
            {
              value: job.object.attributes.service_type_id, 
              selected: true, 
              label: job.object.attributes.service_type_name
            }
          ]
        }
      }
      this.props.store.isOpen = true;

    })


    
  }

  startEditServiceAgreement(id,booking_id) {
    let {
      serviceAgreementStore
    } = this.props;
    let params = {
      uuid: id
    }
    serviceAgreementStore.loadServiceAgreementForBooking(params).then(response => {
      let {
        serviceAgreementStore: {
          serviceAgreement: {
            object
          }
        }
      } = this.props;
      this.props.serviceAgreementStore.persistServiceAgreement = {
        object: {
          ...object.attributes,
          start_time: moment(object.attributes.start_time).toDate(),
          end_time: moment(object.attributes.end_time).toDate(),
          locations: [{
            attributes: {
              id: object.attributes.location.id,
              full_address: object.attributes.location.full_address,
              name: object.attributes.location.full_address,
              client_id: object.attributes.client_id,
              
            }
          }],
          selectedServiceType: [
            {
              label: object.attributes.service_type.name,
              value: object.attributes.service_type.id,
              selected: true
            }
          ],
          client: {
            attributes: object.attributes.client
          },
          booking_id: booking_id
        }
      }
      this.props.jobStore.isServiceAgreement = true;
      this.props.store.isOpen = true;
    })
  }


  onAddressSelect(suggestion) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    if(suggestion) {
      object.address = suggestion.label;
      suggestion.gmaps && suggestion.gmaps.address_components && suggestion.gmaps.address_components.length > 0 && suggestion.gmaps.address_components.map((address_component) => {
        if(_.includes(address_component.types, "locality")) {
          object.city = address_component.long_name;
        }

        if(_.includes(address_component.types, "administrative_area_level_1")) {
          object.state = address_component.long_name;
        }
        
        if(_.includes(address_component.types, "postal_code")) {
          object.zip = address_component.long_name;
        }
      })
    } else {
      object.address = '';
      object.zip = "";
      object.state = "";
      object.city = "";
    }
  }

  onAddressClear(value) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    if(!value) {
      object.address = '';
    }
  }

  onExtraServiceSelect(event,selectedOptions,key) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;

    let index = object.attributes.booking_service_type_extras.map(function(item) { return item.name; }).indexOf(selectedOptions.name);
    if(event.target.checked === true){
      object.attributes.booking_service_type_extras.push({service_type_extra_id:selectedOptions.id,name:selectedOptions.name,price:parseFloat(selectedOptions.price_cents/100)});
    }else{
        object.attributes.booking_service_type_extras[index].price = 0;
        object.attributes.booking_service_type_extras[index]._destroy = 1;
    }
    this.isServiceExtraChecked = key;
    this.calculateGrandtotal()
  }

  
  onFrequencySelect(event,selectedOptions,key) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    object.attributes.frequency_id = selectedOptions.id;
  }

  onPricingRangeSelectImage(event,selectedOptions,key) {
    
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        },
        serviceTypeStore: {
          serviceTypeDetail
        }
      }
    } = this;

    if(event.target.checked){
      let index = object.attributes.booking_pricing_parameters.map(function(item) { return item.pricing_parameter_id; }).indexOf(selectedOptions.pricing_parameter_id);
      if(index > -1){
        object.attributes.booking_pricing_parameters[index].pricing_parameter_id = selectedOptions.pricing_parameter_id;
        object.attributes.booking_pricing_parameters[index].name = selectedOptions.label;
        object.attributes.booking_pricing_parameters[index].price = selectedOptions.price;
        object.attributes.booking_pricing_parameters[index].total_amount =  selectedOptions.price;
      }else{
        object.attributes.booking_pricing_parameters.push({pricing_parameter_id:selectedOptions.pricing_parameter_id,name:selectedOptions.label,price:selectedOptions.price,total_amount:selectedOptions.price});
      }
      this.calculateGrandtotal()
    }
  }

  onPricingRangeSelect(event,selectedOptions) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    if(event.target.value != ''){
      let index = object.attributes.booking_pricing_parameters.map(function(item) { return item.pricing_parameter_id; }).indexOf(selectedOptions.id);
      if(index > -1){
        object.attributes.booking_pricing_parameters[index].pricing_parameter_id = selectedOptions.id;
        object.attributes.booking_pricing_parameters[index].name = event.target.options[event.target.selectedIndex].text;
        object.attributes.booking_pricing_parameters[index].price = parseFloat(event.target.value);
        object.attributes.booking_pricing_parameters[index].total_amount = parseFloat(event.target.selectedIndex) * parseFloat(event.target.value);

      }else{
        object.attributes.booking_pricing_parameters.push({pricing_parameter_id:selectedOptions.id,name:event.target.options[event.target.selectedIndex].text,price:parseFloat(event.target.value),total_amount:parseFloat(event.target.value)});
      
      }
      this.calculateGrandtotal()
    }
  }

  onPricingSelect(event,selectedOptions) {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    if(event.target.value != ''){
      let index = object.attributes.booking_pricing_parameters.map(function(item) { return item.pricing_parameter_id; }).indexOf(selectedOptions.id);
      if(index > -1){
        object.attributes.booking_pricing_parameters[index].name = event.target.value;
        object.attributes.booking_pricing_parameters[index].price = parseFloat(event.target.selectedIndex) * parseFloat((selectedOptions.price_cents/100))
        object.attributes.booking_pricing_parameters[index].quantity = event.target.selectedIndex;
        object.attributes.booking_pricing_parameters[index].total_amount = parseFloat(event.target.selectedIndex) * parseFloat((selectedOptions.price_cents/100));
      }else{
        let amount = parseFloat(event.target.selectedIndex) * parseFloat((selectedOptions.price_cents/100));
        object.attributes.booking_pricing_parameters.push({pricing_parameter_id:selectedOptions.id,name:selectedOptions.name,quantity:event.target.selectedIndex,total_amount:parseFloat((selectedOptions.price_cents/100)),price:amount});
      }
    } else {
      object.pricing_parameters = [];
    }
    this.calculateGrandtotal()
  }

  pricingList = (pricing) => {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    let pricingToReturn = [];
    this.pricingParameterArray = [];
    for (let i = pricing.range_start; i <= pricing.range_end; i++) {
      let label = `${pricing.name} ${i}`;
      let indexChecked = object.attributes.booking_pricing_parameters.map(function(item) { return item.name; }).indexOf(label);
      if(indexChecked > -1){
        pricingToReturn.push(<option selected={'selected'} id={pricing.id} key={i}>{pricing.name}{` ${i}`}</option>);
      }else{
        pricingToReturn.push(<option id={pricing.id} key={i}>{pricing.name}{` ${i}`}</option>);
      }
    }
    return pricingToReturn;
  };

  pricingRangeList = (pricing) => {
    let {
      props: {
        pricingParameterStore,
        pricingParameterStore: {
          pricingParameterDetailUpdate: { 
            object
           }
        },
        bookingStore: {
          booking
        }
      }
    } = this;

    let pricingRangeToReturn = [];
    
    if(pricing.id){
      let index = pricing.pricing_parameter_ranges.map(function(item) { return item.pricing_parameter_id; }).indexOf(pricing.id);
      if(index > -1){
        pricing.pricing_parameter_ranges.map((range) => {
          let label = `${range.label}`;
          let indexChecked = booking.object.attributes.booking_pricing_parameters.map(function(item) { return item.name; }).indexOf(label);
          if(indexChecked > -1){
            pricingRangeToReturn.push(<option selected={'selected'} innertext={range.label} value={range.price} key={Math.random()}>{range.label}</option>);
          }else{
            pricingRangeToReturn.push(<option innertext={range.label} value={range.price} key={Math.random()}>{range.label}</option>);
          }
        });
      }
    }
    return pricingRangeToReturn;
  };

  handleStartDateChange(date) {
    let { object } = this.props.bookingStore.booking;
    if(date) {
      object.attributes.start_date = moment(date);
    }
  }

  onPaymentSelect = (event,payment,key) => {
    let { object } = this.props.bookingStore.booking;
    object.attributes.payment_method = payment.value;
  }

  onContactWayChange = (event) =>{
    let {
      bookingStore:{
        booking:{
          object: {
            attributes
          }
        }
      }
    } = this.props;
    attributes.start_project_expected_period = event.target.value;
  }

  onTimePeriodChange = (event) =>{
    let {
      bookingStore:{
        booking:{
          object: {
            attributes
          }
        }
      }
    } = this.props;
    attributes.contact_way = event.target.value;
  }

  onServiceTypeCategoryChange = (event) => {
    let { object } = this.props.bookingStore.booking;
    this.props.viewStore.serviceTypeSearch.where.service_type_category_id = event.target.value;
    this.props.serviceTypeStore.loadServiceTypes() 
    object.attributes.service_type_category_id = event.target.value;
  }

  onServiceTypeChange = (event) => {
    let { object } = this.props.bookingStore.booking;
    let { serviceTypeStore, pricingParameterStore} = this.props;
    let { 
      serviceTypeStore: {
        serviceTypeDetail,
        princingRangeCombineArray
      }
    } = this.props;
    if(event.target.value !== "") {
      serviceTypeStore.princingRangeCombineArray = [];
      object.attributes.booking_service_type_extras = [];
      object.attributes.booking_pricing_parameters = [];
      object.attributes.service_type_id = event.target.value;
      serviceTypeStore.loadServiceTypeDetail({uuid:event.target.value})
        .then((response) => {
          serviceTypeDetail.object.attributes && serviceTypeDetail.object.attributes.pricing_parameters.length > 0 && serviceTypeDetail.object.attributes.pricing_parameters.map((pricing_attr) => {
          });
          this.calculateGrandtotal()
        })
    } else {
      this.props.serviceTypeStore.clearServiceTypeDetail()
      this.calculateGrandtotal()
    }
  }

  onServiceTypeSelect = (event,service,key) => {
    let { object } = this.props.bookingStore.booking;
    let { serviceTypeStore, pricingParameterStore} = this.props;
    let { 
      serviceTypeStore: {
        serviceTypeDetail,
        princingRangeCombineArray
      },
      
    } = this.props;
    if(service.attributes.id !== "") {
      this.setState({
        isServiceTypeChecked: service.attributes.id
      });
      serviceTypeStore.princingRangeCombineArray = [];
      object.attributes.booking_service_type_extras = [];
      object.attributes.booking_pricing_parameters = [];
      object.attributes.service_type_id = service.attributes.id;
      serviceTypeStore.loadServiceTypeDetail({uuid:service.attributes.id})
        .then((response) => {
          serviceTypeDetail.object.attributes && serviceTypeDetail.object.attributes.pricing_parameters.length > 0 && serviceTypeDetail.object.attributes.pricing_parameters.map((pricing_attr) => {
          });
          this.calculateGrandtotal()
        })
    }else{
      this.props.serviceTypeStore.clearServiceTypeDetail()
      this.calculateGrandtotal()
    }
  }

  calculateGrandtotal() {
    let { 
      bookingStore: {
        booking: {
          object
        }
      },
      serviceTypeStore: {
        serviceTypeDetail
      }
    } = this.props;

    object.attributes.total = 0.0;
    
    // Adding Service type price in grand total
    if(serviceTypeDetail && serviceTypeDetail.object && serviceTypeDetail.object.attributes) {
      object.attributes.total += serviceTypeDetail.object.attributes.rate;
    }
    // Adding pricing parameters flat to grand total
    if(object && object.attributes.booking_pricing_parameters && object.attributes.booking_pricing_parameters.length > 0) {
      object.attributes.total += _.sumBy(object.attributes.booking_pricing_parameters, 'price')
    }
    // Adding extra service types to grand total
    if(object && object.attributes.booking_service_type_extras && object.attributes.booking_service_type_extras.length > 0) {
      object.attributes.total += _.sumBy(object.attributes.booking_service_type_extras, 'price')
    }
  }

  updateBooking(status) {
    let {
      props: {
        store,
        bookingStore: {
          booking,
          booking: { 
            object
           }
        }
      }
    } = this;
    booking.isLoading = true;
    
    if(object.attributes.first_name === "" || object.attributes.first_name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid first name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.last_name === "" || object.attributes.last_name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid last name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.email === "" || object.attributes.email === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid email!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.phone === "" || object.attributes.phone === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid phone!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.address === "" || object.attributes.address === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid address!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.city === "" || object.attributes.city === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid city name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.state === "" || object.attributes.state === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid state name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.zip === "" || object.attributes.zip === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid zip code!', intent: Intent.DANGER })
      return;
    }

    
    object.attributes.status = status;
    object.attributes.booking_service_type_extras_attributes = object.attributes.booking_service_type_extras;
    object.attributes.booking_pricing_parameters_attributes = object.attributes.booking_pricing_parameters;
    object.attributes.booking_questions_attributes = object.attributes.booking_questions;

    this.props.bookingStore
      .updateBooking(object)
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Booking updated successfully!",
          intent: Intent.SUCCESS
        });
        if(response.data.data.attributes.status === 'confirmed'){
          console.log(response.data.data.attributes)
          if(response.data.data.attributes.frequency && response.data.data.attributes.frequency.title === 'One Time' && response.data.data.attributes.scheduled_events.length === 0){
            let { object } = this.props.jobStore.persistJob;
            object.reason_for_call = response.data.data.attributes.service_type.name;
            object.start_date = moment(response.data.data.attributes.start_date);
            object.end_date = moment(response.data.data.attributes.start_date);
            object.service_type_id = response.data.data.attributes.service_type_id;
            object.selectedServiceType = [{value: response.data.data.attributes.service_type_id,selected: true,label: response.data.data.attributes.service_type.name}];
            store.isOpen = true;
            object.booking_id = response.data.data.attributes.id;
          }else if(response.data.data.attributes.service_agreements && response.data.data.attributes.service_agreements.length != 0){
            let {object } = this.props.serviceAgreementStore.persistServiceAgreement;
            let frequencyType = response.data.data.attributes.frequency.title;
            let frequencyTypeString = response.data.data.attributes.frequency.title.toUpperCase();
            let startDate = moment(response.data.data.attributes.start_date).format('YYYYMMDDTHHmmssz');
            object.reason_for_call = response.data.data.attributes.service_type.name;
            object.selectedServiceType = [{value: response.data.data.attributes.service_type_id,selected: true,label: response.data.data.attributes.service_type.name}];
            object.service_type_id = response.data.data.attributes.service_type_id;
            var string = '';
            if(frequencyType === 'Weekly'){
              string = `DTSTART:${startDate} RRULE:FREQ=${frequencyTypeString};INTERVAL=1;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Bi-weekly'){
              string = `DTSTART:${startDate} RRULE:FREQ=${'WEEKLY'};INTERVAL=2;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Monthly'){
              string = `DTSTART:${startDate} RRULE:FREQ=${frequencyTypeString};INTERVAL=1;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Quarterly'){
              string = `DTSTART:${startDate} RRULE:FREQ=${'MONTHLY'};INTERVAL=3;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Semi-annually'){
              string = `DTSTART:${startDate} RRULE:FREQ=${frequencyTypeString};INTERVAL=6;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Annually'){
              string = `DTSTART:${startDate} RRULE:FREQ=${'YEARLY'};INTERVAL=1;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Yearly'){
              string = `DTSTART:${startDate} RRULE:FREQ=${'YEARLY'};INTERVAL=1;BYMONTHDAY=1`;
            }
            if(frequencyType === 'Every other year'){
              string = `DTSTART:${startDate} RRULE:FREQ=${frequencyTypeString};INTERVAL=1;BYMONTHDAY=1`;
            }
            object.booking_id = response.data.data.attributes.id;
            object.rrule = string;
            this.props.jobStore.isServiceAgreement = true;
            this.props.store.isOpen = true;
          }else{
            let { object } = this.props.jobStore.persistJob;
            object.reason_for_call = response.data.data.attributes.service_type.name;
            object.start_date = moment(response.data.data.attributes.start_date);
            object.end_date = moment(response.data.data.attributes.start_date);
            object.service_type_id = response.data.data.attributes.service_type_id;
            object.selectedServiceType = [{value: response.data.data.attributes.service_type_id,selected: true,label: response.data.data.attributes.service_type.name}];
            store.isOpen = true;
            object.booking_id = response.data.data.attributes.id;
          }
        }
        
      })

  }

  onCloseCopyBrandLinkDialog() {
    this.showCopyNewBrandLogoLinkDialog = false;
  }

  onSuccessCopyBrandLinkDialog() {
    this.showCopyNewBrandLogoLinkDialog = false;
  }

  onCloseCopyLinkDialog() {
    this.showCopyLinkDialog = false;
  }

  onSuccessCopyLinkDialog() {
    this.showCopyLinkDialog = false;
  }

  onChangeFile(event) {
    let {
      bookingStore:{
        booking:{
          object: { attributes }
        }
      }
    } = this.props;
    var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 10) {
      Toast.show({ icon: 'warning-sign', message: 'File size exceeds 10 MB!', intent: Intent.DANGER })
      return
    }
    this.props.bookingStore.uploadBookingAttachment(event.target.files[0], attributes.id)
      .then(this.onSuccessfullUploadAttachment.bind(this))
      .catch(this.onFailUploadAttachment.bind(this))
  }

  onSuccessfullUploadAttachment(response) {
    let {
      bookingStore:{
        booking:{
          object: {
            attributes
          }
        }
      }
    } = this.props;
    attributes.booking_attachments.push(response.data.data.attributes);
  }

  onFailUploadAttachment(error) {
    this.props.bookingStore.showUploadAttachmentLoader = false;
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong, failed to upload file!', intent: Intent.DANGER })
    }
  }

  removeAttachment(attachment) {
    let {
      bookingStore:{
        booking:{
          object: { attributes }
        }
      }
    } = this.props;
    this.props.bookingStore.removeAttachment(attachment, attributes.id)
  }

  render() {
    let {
      serviceTypeStore: {
        serviceType,
        serviceType:{
          objects
        },
        serviceTypeDetail,
      },
      bookingStore:{
        showUploadAttachmentLoader,
        booking:{
          object: {
            attributes
          },
          isLoading
        }
      },
      questionGroupStore:{
        groups
      },
      serviceTypeCategoryStore:{
        serviceTypeCategories
      }
    } = this.props;

    let service_type_extra = [];
    let pricing_parameters = [];
    let frequency = [];
    let questions = [];

    if(serviceTypeDetail && serviceTypeDetail.object && serviceTypeDetail.object.attributes) {
      service_type_extra = serviceTypeDetail.object.attributes.service_type_extra
      pricing_parameters = serviceTypeDetail.object.attributes.pricing_parameters
      frequency = serviceTypeDetail.object.attributes.frequency
      questions = serviceTypeDetail.object.attributes.questions;
    }
   
   
    if (isLoading) {
      return(
        <div className="spinner-center">
          <Spinner />
        </div>
      )
    }

    const moreAction = (
      <Menu>
        <MenuItem icon="document-share" text="Copy Link" onClick={() => { this.showCopyLinkDialog = true; }}/>
        <MenuItem icon="document-share" text="Copy New Booking Link With Brand" onClick={() => { this.showCopyNewBrandLogoLinkDialog = true; }}/>
      </Menu>
    );
    return (
      <div >
        <Helmet>
          <title>{`Booking | FieldNexus`}</title>
        </Helmet>
        <div className="more-actions" style={{display: 'flex', justifyContent: 'flex-end', margin: 10}}>
           <div className="actions-dropdwon" options={false}>
            <Popover content={moreAction} position={Position.BOTTOM}>
                <Button className="action-btn" icon="chevron-down" text="More Actions" />
            </Popover>
          </div>
        </div>
        <div className="row no-gutters company-page-layput">
          <style dangerouslySetInnerHTML={{__html: `
            .bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator:before,
            .bp3-control input:checked ~ .bp3-control-indicator,
            .bp3-control.bp3-radio input:checked ~ .bp3-control-indicator:before {
              background-color: ${attributes && attributes.booking_form_color} !important;
              border-color: ${attributes && attributes.booking_form_color} !important;
            }
            .save-draft-btn {
              border: 1px solid ${attributes && attributes.booking_form_color} !important;
              color: ${attributes && attributes.booking_form_color} !important;
            }

            .save-draft-btn:hover {
              border: 1px solid ${attributes && attributes.booking_form_color};
            }
            .bp3-input:focus, .bp3-input:hover, .bp3-input.bp3-active {
              border: 1px solid ${attributes && attributes.booking_form_color} !important;
            }
            .form-control:focus {
              box-shadow: none !important;
              border: 1px solid ${attributes && attributes.booking_form_color};
            }
            .service-card-active-inline {
              border: 1px solid ${attributes && attributes.booking_form_color} !important;
              -webkit-box-shadow: 0px 0px 6px 0px rgba(31, 144, 255, 0.55) !important;
              box-shadow: 0px 0px 6px 2px rgba(234, 234, 234, 0.73) !important;
            }
            .service-card:hover {
              border: 1px solid ${attributes &&  attributes.booking_form_color} !important;
              -webkit-box-shadow: 0px 0px 6px 0px rgba(31, 144, 255, 0.55) !important;
              box-shadow: 0px 0px 6px 2px rgba(234, 234, 234, 0.73) !important;
            }
            .DayPicker-Day--selected {
              background-color: ${attributes && attributes.booking_form_color} !important;
            }

            .next-visit-active {
              background-color: ${attributes && attributes.booking_form_color} !important;
              border: 1px solid ${attributes && attributes.booking_form_color} !important;
            }

            .next-visit:hover {
              background-color: ${attributes && attributes.booking_form_color} !important;
              border: 1px solid ${attributes && attributes.booking_form_color} !important;
              -webkit-box-shadow: 0px 0px 6px 0px rgba(31, 144, 255, 0.55) !important;
              box-shadow: 0px 0px 6px 2px rgba(234, 234, 234, 0.73) !important;
            }

            .booking-page {
              background-color: ${attributes && attributes.booking_form_bgcolor} !important;
            }
          `}} />
          {
            <PageWrapGray>
              <div className="booking-page">
                <div className="booking-page-container">
                  <div className="column-left">
                  <div className="booking-form">
                    <div className="booking-form-header">
                      <div className="header-title">Update Your Booking</div>
                      <div className="header-content">Please fill us the form to update your booking.</div>
                    </div>
                    <div className="form-block">
                      <div className="form-block-title">Step 1: Who Are You?</div>
                      <div className="block-instruction">Please give us your contact information.</div>
                      
                      <div className="form-fields">
                        <div className="field-type1">
                          <FormGroup
                            label={"First Name"}
                            labelFor="text-input"
                            labelInfo={"*"}
                          >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='First name'
                              defaultValue={attributes && attributes.first_name}
                              onChange={(event) => {attributes.first_name = event.target.value;}} 
                            />
                          </FormGroup>
                        </div>
                        <div className="field-type1">
                          <FormGroup
                            label={"Last Name"}
                            labelFor="text-input"
                            labelInfo={"*"}
                          >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='Last name'
                              defaultValue={attributes && attributes.last_name}
                              onChange={(event) => {attributes.last_name = event.target.value;}} 
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="form-fields">
                        <div className="field-type1">
                          <FormGroup
                            label={"Email Address"}
                            labelFor="text-input"
                            labelInfo={"*"}
                          >
                            <input 
                              type='email' 
                              className='form-control' 
                              placeholder='mail@domain.com'
                              defaultValue={attributes && attributes.email}
                              onChange={(event) => {attributes.email = event.target.value;}} 
                            />
                          </FormGroup>
                        </div>
                        <div className="field-type1">
                          <FormGroup
                            label={"Phone Number"}
                            labelFor="text-input"
                            labelInfo={"*"}
                          >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='Ex. 9876543210'
                              defaultValue={attributes && attributes.phone}
                              onChange={(event) => {attributes.phone = event.target.value;}} 
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="form-block">
                      <div className="form-block-title">Step 2: Your Address</div>
                      <div className="block-instruction">Please give us your contact information.</div>
                      
                      <div className="form-fields">
                        <div className="field-type2">
                          <FormGroup
                            label={"Address"}
                            labelFor="text-input"
                            labelInfo={"*"}
                          >
                            <GeoComplete
                              className='form-control geo-control'
                              placeholder="Search Address"
                              initialValue={attributes && attributes.address}
                              onSuggestSelect={this.onAddressSelect.bind(this)}
                              onChange={this.onAddressClear.bind(this)}
                            />
                          </FormGroup>
                        </div>
                        <div className="field-type3">
                          <FormGroup
                            label={"Address 2"}
                            labelFor="text-input"
                          >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='Address 2'
                              defaultValue={attributes && attributes.suite}
                              onChange={(event) => {attributes.suite = event.target.value;}} 
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="form-fields">
                        <div className="field-type4">
                          <FormGroup
                            label={"City"}
                            labelFor="text-input"
                            labelInfo={"*"}
                          >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='City'
                              defaultValue={attributes && attributes.city}
                              onChange={(event) => {attributes.city = event.target.value;}} 
                            />
                          </FormGroup>
                        </div>
                        <div className="field-type3">
                          <FormGroup
                            label={"State/Province"}
                            labelFor="text-input"
                            labelInfo={"*"}
                          >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='State/Province'
                              defaultValue={attributes && attributes.state}
                              onChange={(event) => {attributes.state = event.target.value;}} 
                            />
                            
                          </FormGroup>
                        </div>
                        <div className="field-type3">
                          <FormGroup
                            label={"Zip Code/Postal Code"}
                            labelFor="text-input"
                            labelInfo={"*"}
                          >
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='Ex. 0377'
                              defaultValue={attributes && attributes.zip}
                              onChange={(event) => {attributes.zip = event.target.value;}} 
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    {
                      serviceTypeCategories.objects &&
                      <div className="form-block">
                        { serviceTypeCategories.objects.length === 0 ?
                            <Spinner size='25' />
                            :
                            ""
                          }
                        <div className="row">
                          <div className="col col-xs-12 col-md-6" >
                            <FormGroup label={'Service Type Category'} labelFor="text-input" labelInfo={"*"}>
                              <select 
                                className='form-control'
                                onChange={this.onServiceTypeCategoryChange.bind(this)}
                                value={attributes && attributes.service_type_category_id || ''}
                              >
                                <option value="">Select category</option>
                                { serviceTypeCategories.objects.map((category) => {
                                    return(
                                      <option value={category.attributes.id} key={category.attributes.id}>{category.attributes.name}</option>
                                    )
                                  })
                                }
                              </select>
                            </FormGroup>
                          </div>
                        </div>
                      </div>

                    }
                    {
                      attributes && attributes.display_service_type === 'Icon' ?
                      <div className="form-block">
                        <div className="form-block-title">Step 3: {attributes && attributes.heading_text_for_service_type}</div>
                        <div className="block-instruction"></div>
                        { objects.length === 0 ?
                          <Spinner size='25' />
                          :
                          ""
                        }
                        <div className="form-fields fields-style">
                          { objects && objects.map((service,key) => {
                            if(service.attributes.display_on_booking_form) {
                              return (
                                <div className="field-type5">
                                  <div 
                                    className={service.id === attributes.service_type_id ? 'services-category service-card-active': 'services-category'}
                                  >
                                    <div className="service-card">
                                      <div className="service-icon">
                                        <img style={{ width: '40px' }} src={service.attributes.logo} />
                                      </div>
                                      <div className="service-checkbox">
                                        <Radio label="" type="radio" name="service_type_id" 
                                          checked={service.id === attributes.service_type_id ? true: false}
                                          onChange={(event) => {this.onServiceTypeSelect(event,service,key)} } 
                                        />
                                      </div>
                                    </div>
                                    <div className="service-label">{service.attributes.name}</div>
                                  </div>
                                </div>
                                )
                              }
                            })
                          }
                        </div>
                        <div className="form-fields">
                          <div className="col col-xs-12 col-md-12">
                            {pricing_parameters && pricing_parameters.length > 0 && <div className="">
                            
                              <div className="row">
                              {
                                pricing_parameters && pricing_parameters.length > 0 && pricing_parameters.map((pricing,pricingKey) => {
                                  if(pricing.pricing_type === 'flat'){
                                    return (
                                      <div className="col-md-6" key={Math.random()}>
                                        <FormGroup label={"Pricing"} labelFor="text-input">
                                          <select 
                                            className='form-control' 
                                            placeholder={`Select ${pricing.name}`}
                                            onChange={(event) => {this.onPricingSelect(event,pricing)} }
                                          >
                                            <option value="">Select any one</option>
                                          { this.pricingList(pricing)}
                                          </select>
                                        </FormGroup>
                                      </div>
                                    )
                                  }
                                })
                              }
                              </div>
                              <div className="row">
                              {
                                pricing_parameters && pricing_parameters.length > 0 && pricing_parameters.map((pricing) => {
                                  if(pricing.pricing_type === 'range'){
                                    var index = pricing.pricing_parameter_ranges.length === _.filter(pricing.pricing_parameter_ranges, function(obj) { return obj.image_url; }).length
                                    return (
                                      <div className="col-md-6" key={Math.random()}>
                                        <FormGroup
                                          label={pricing.name}
                                          labelFor="text-input"
                                        >
                                          {
                                            index === true ?
                                            <div className="d-flex flex-wrap mt-3">
                                              {pricing.pricing_parameter_ranges.map((pricing_range,key) => {
                                                let indexChecked = attributes.booking_pricing_parameters.map(function(item) { return item.name; }).indexOf(pricing_range.label);
                                                var checked_id ='';
                                                if(indexChecked > -1){
                                                  checked_id = pricing_range.id;
                                                }
                                                 return (
                                                  <div className="imgwrap">
                                                    <label className="font-weight-bold">{pricing_range.label}</label>
                                                    <div className="imgbox">
                                                    <Radio type="radio" name="checkboxgroup" 
                                                      checked={checked_id === pricing_range.id ? true: false}
                                                      onChange={(event) => {this.onPricingRangeSelectImage(event,pricing_range,key)} } 
                                                    />
                                                      <img width="150" height="150" src={pricing_range.image_url}  />
                                                    </div>
                                                  </div>
                                                )
                                               })}    
                                            </div>:
                                            <select 
                                              className='form-control' 
                                              placeholder={`Select ${pricing.name}`}
                                              onChange={(event) => {this.onPricingRangeSelect(event,pricing)} }
                                              >
                                              <option value="">Select any one</option>
                                              { this.pricingRangeList(pricing)}
                                            </select>
                                          }
                                          
                                        </FormGroup>
                                      </div>
                                    )
                                  }
                                })
                              }
                              </div>
                            </div>}
                          </div>
                        </div>

                      </div>
                      :
                        <div className="form-block">
                        <div className="form-block-title">
                          <span style={{marginRight: 10}}>Step 3: {attributes && attributes.heading_text_for_service_type}</span>
                          { objects.length === 0 ?
                            <Spinner size='25' />
                            :
                            ""
                          }
                        </div>
                        <div className="block-instruction"></div>
                        <div className="row">
                          <div className="col col-xs-12 col-md-6">
                          <FormGroup label={attributes && attributes.another_heading_text_for_service} labelFor="text-input" labelInfo={"*"}>
                            <select 
                              className='form-control'
                              value={attributes && attributes.service_type_id || ''}
                              onChange={this.onServiceTypeChange.bind(this)}
                            >
                              <option value="">Select service </option>
                              { objects && objects.map((service) => {
                                if(service.attributes.display_on_booking_form) {
                                  return(
                                    <option 
                                      value={service.attributes.id} 
                                      key={service.attributes.id}
                                    >
                                        {service.attributes.name}
                                    </option>
                                  )
                                }
                                })
                              }
                            </select>
                          </FormGroup>
                          </div>
                          <div className="col col-xs-12 col-md-12">
                            {pricing_parameters && pricing_parameters.length > 0 && <div className="">
                            
                              <div className="row">
                              {
                                pricing_parameters && pricing_parameters.length > 0 && pricing_parameters.map((pricing,pricingKey) => {
                                  if(pricing.pricing_type === 'flat'){
                                    return (
                                      <div className="col-md-6" key={Math.random()}>
                                        <FormGroup label={"Pricing"} labelFor="text-input">
                                          <select 
                                            className='form-control' 
                                            placeholder={`Select ${pricing.name}`}
                                            onChange={(event) => {this.onPricingSelect(event,pricing)} }
                                          >
                                            <option value="">Select any one</option>
                                          { this.pricingList(pricing)}
                                          </select>
                                        </FormGroup>
                                      </div>
                                    )
                                  }
                                })
                              }
                              </div>
                              <div className="row">
                              {
                                pricing_parameters && pricing_parameters.length > 0 && pricing_parameters.map((pricing) => {
                                  if(pricing.pricing_type === 'range'){
                                    var index = pricing.pricing_parameter_ranges.length === _.filter(pricing.pricing_parameter_ranges, function(obj) { return obj.image_url; }).length
                                   
                                    return (
                                      <div className="col-md-6" key={Math.random()}>
                                        <FormGroup
                                          label={pricing.name}
                                          labelFor="text-input"
                                        >
                                          {
                                            index === true ?
                                            <div className="d-flex flex-wrap mt-3">
                                              {pricing.pricing_parameter_ranges.map((pricing_range,key) => {
                                                let indexChecked = attributes.booking_pricing_parameters.map(function(item) { return item.name; }).indexOf(pricing_range.label);
                                                var checked_id ='';
                                                if(indexChecked > -1){
                                                  checked_id = pricing_range.id;
                                                }
                                                 return (
                                                  <div className="imgwrap">
                                                    <label className="font-weight-bold">{pricing_range.label}</label>
                                                    <div className="imgbox">
                                                    <Radio type="radio" name="checkboxgroup" 
                                                      checked={checked_id === pricing_range.id ? true: false}
                                                      onChange={(event) => {this.onPricingRangeSelectImage(event,pricing_range,key)} } 
                                                    />
                                                      <img width="150" height="150" src={pricing_range.image_url}  />
                                                    </div>
                                                  </div>
                                                )
                                               })}    
                                            </div>:

                                            <select 
                                              className='form-control' 
                                              placeholder={`Select ${pricing.name}`}
                                              onChange={(event) => {this.onPricingRangeSelect(event,pricing)} }
                                              >
                                              <option value="">Select any one</option>
                                              { this.pricingRangeList(pricing)}
                                            </select>

                                          }
                                          
                                        </FormGroup>
                                      </div>
                                    )
                                  }
                                })
                              }
                              </div>
                            </div>}
                          </div>
                        </div>
                      </div>
                    }


                    
                    {service_type_extra && service_type_extra.length > 0 && <div className="form-block">
                    <div className="form-block-title">Add ons</div>
                    <div className="block-instruction"></div>
                    <div className="form-fields fields-style">
                      {
                        service_type_extra.map((extra,key) => {
                          indexChecked = attributes.booking_service_type_extras.map(function(item) { return item._destroy !== 1 ? item.service_type_extra_id : ""; }).indexOf(extra.id);
                          if(indexChecked > -1){
                            label = extra.id;
                          } else {
                            label = ""
                          }
                          return (
                            <div className="field-type5" key={Math.random()}>
                              <div className={`services-category ${label === extra.id ? 'service-card-active' :''}`}>
                                <div className={`service-card ${label === extra.id ? 'service-card-active-inline' :''}`}>
                                  <div className="service-icon"><img src={extra.icon_file.thumb.url} width={50} /></div>
                                  <div className="service-checkbox">
                                    <Checkbox label="" type="checkbox"
                                      defaultChecked={label === extra.id ? true : false}
                                      onChange={(event) => {this.onExtraServiceSelect(event,extra,key)} }
                                    />
                                  </div>
                                </div>
                                <div className="service-label">{extra.name}</div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>}
                    {groups && questions && questions.length > 0 && <div className="form-block">
                      <div className="form-block-title">Additional information</div>
                      {
                        groups.objects.map(function(group) { 
                          var isInGroup = questions.map(function(item) { return item.question_group_id; }).indexOf(group.id);
                          if(isInGroup > -1) {
                            return(
                                <div className="form-fields">
                                  <div className="question-layout">
                                    <div className="questions">
                                      {group.attributes.question}
                                    </div>
                                    {questions && questions.length > 0 && questions.map((question, index) => 
                                      {
                                      indexChecked = attributes.booking_questions.map(function(item) { return item.question_id; }).indexOf(question.id);
                                      if(indexChecked > -1){
                                        label = question.id;
                                        selectedAnswer = attributes.booking_questions[indexChecked].answer;
                                      } else {
                                        selectedAnswer = ""
                                      }
                                      if(group.attributes.id === question.question_group_id){
                                      return(
                                        <div className="form-fields" key={Math.random()}>
                                          <div className="question-layout">
                                            <div className="questions">
                                              {question.field_label}
                                            </div>
                                            <div className="answer">
                                              {question.field_type === "Checkbox" && <div>
                                                <RadioGroup inline={true} className="bookings-radio field-type0" name="question_radio">
                                                  {question.field_value && question.field_value.split(/\r?\n/).map((answer) => {
                                                    return(
                                                      <QuestionRadio 
                                                        inline= {true}
                                                        bookingStatus={''}
                                                        question={question}
                                                        answer={answer}
                                                        checkedValue={label}
                                                        selectedAnswer={selectedAnswer}
                                                      /> 
                                                    )
                                                  })}
                                                </RadioGroup>
                                                </div>}
                                                {question.field_type === "Dropdown"  && <div className="field-type1">
                                                <QuestionSelect 
                                                    inline= {true}
                                                    question={question}
                                                    bookingStatus={''}
                                                    checkedValue={label}
                                                    selectedAnswer={selectedAnswer}
                                                  /> 
                                                </div>}
                                                {question.field_type === "Textbox"  && <div className="field-type1">
                                                  <QuestionTextbox 
                                                    inline= {true}
                                                    bookingStatus={''}
                                                    question={question}
                                                    selectedAnswer={selectedAnswer}
                                                  /> 
                                                </div>}
                                            </div>
                                          </div>
                                        </div>
                                      )}})
                                      }
                                      <Divider style={{ margin: '16px 0px' }}></Divider>
                                  </div>
                                </div>
                              )
                          }
                        })
                      }
                      </div>
                    }
                    <div className="form-block">
                      <div className="row">
                        <div className="col col-xs-12 col-md-6">
                          <div className="form-block-title">
                            How soon would you like to start this project?
                          </div>
                        <FormGroup  labelFor="text-input">
                          <select 
                            className='form-control' 
                            placeholder={`Select `}
                            onChange={this.onTimePeriodChange.bind(this)} 
                            defaultValue={attributes && attributes.start_project_expected_period && attributes.start_project_expected_period}
                          >
                            <option value="">Select any one</option>
                            { attributes && attributes.booking_start_project_expected_period && attributes.booking_start_project_expected_period.split(/\r?\n/).map((period) => { 
                              var selected = '';
                              if(attributes.start_project_expected_period === period.split(":")[1]){
                                selected = 'selected'
                              }
                              return(
                                <option selected value={period.split(":")[1]}>{period.split(":")[0]}</option>
                              )})}
                          </select>
                        </FormGroup>
                        </div>
                        <div className="col col-xs-12 col-md-6">
                          <div className="form-block-title">
                            What is the best way for us to contact you?
                          </div>
                        <FormGroup  labelFor="text-input">
                          <select 
                            className='form-control' 
                            placeholder={`Select `}
                            onChange={this.onContactWayChange.bind(this)} 
                            defaultValue={attributes && attributes.contact_way && attributes.contact_way}
                          >
                            <option value="">Select any one</option>
                            {attributes && attributes.booking_contact_way && attributes.booking_contact_way.split(/\r?\n/).map((way) => { 
                              var selected = '';
                              if(attributes.contact_way === way.split(":")[1]){
                                selected = 'selected'
                              }                             
                              return(
                                <option selected value={way.split(":")[1]}>{way.split(":")[0]}</option>
                              )})}
                          </select>
                        </FormGroup>
                        </div>
                      </div>
                    </div>
                    {attributes && attributes.enable_visit_us === true &&
                      <div className="form-block">
                        <div className="form-block-title">Step 4: When would you like us to visit?</div>
                        <div className="block-instruction"></div>
                        <div className="row">
                          <div className="col col-xs-12 col-md-6 booking_form_date">
                            <FormGroup
                              label={"Choose a date"}
                              labelFor="text-input"
                              labelInfo={"*"}
                            >
                              <DateTimeInput 
                                placeholder={'Date'} 
                                defaultValue={attributes && attributes.start_date && moment(attributes.start_date)}
                                onChange={this.handleStartDateChange.bind(this)}
                              />
                            </FormGroup>
                          </div>
                          <div className="col col-xs-12 col-md-6">
                            
                          </div>
                        </div>
                      </div>
                    }
                    {attributes && attributes.enable_often_visit_us === true &&
                      <div>
                        {frequency && frequency.length > 0 && <div className="form-block">
                        <div className="form-block-title">- How often you want us to visit?</div>
                        <div className="block-instruction"></div>
                        <div className="form-fields fields-style">
                          { 
                            frequency.map((freqncy,frequencyKey) => {
                              if(attributes && attributes.frequency_id){
                                label = attributes.frequency_id;
                              }
                              return (
                                <div className="field-type6" key={Math.random()}>
                                  <div onClick={(event) => {this.onFrequencySelect(event,freqncy,frequencyKey)} } className={`next-visit ${label == freqncy.id ? 'next-visit-active' : ''}`}>{freqncy.title}</div>
                                </div>
                              )

                            })
                          }
                        </div>
                      </div>}
                      </div>
                    }
                  {attributes && attributes.booking_payment_method === true &&
                    <div className="form-block">
                      <div className="form-block-title">Step 5: Select Payment Method</div>
                      <div className="block-instruction"></div>
                      <div className="form-fields">
                        {
                          items.map((payment,paymentKey) => {
                            if(attributes && attributes.payment_method){
                              label = attributes.payment_method;
                            }
                            return (
                              <div className="field-type6" key={Math.random()}>
                                <div onClick={(event) => {this.onPaymentSelect(event,payment,paymentKey)} } className={`next-visit ${label === payment.value ? 'next-visit-active' : ''}`}>{payment.label}</div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  }
                    <div className="form-block">
                      <div className="upload-btn-wrapper">
                        <label htmlFor="file-upload" className="custom-file-upload">
                          <img src="/upload-file-icon.svg" />
                          <span style={{marginRight: 10}}>Upload a file </span>(Optional) (PNG, JPG, GIF, PDF, DOC, DOCX, XLS, XLSX)
                          {showUploadAttachmentLoader && 
                            <Spinner size={Spinner.SIZE_SMALL} /> }
                        </label>
                        <input id="file-upload" type="file" multiple={true} onChange={this.onChangeFile.bind(this)} />
                      </div>
                      <div className="card rounded-0 table-responsive">
                        {attributes && attributes.booking_attachments.length > 0 && <table className="table table-vertical-align table-data-presentation table-hover" style={{ width: '100%' }}>
                          <thead>
                            <tr>
                              <th>File Name</th>
                              <th>Content Type</th>
                              <th width="10%">File Size</th>
                              <th className="item-center" width="10%">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {attributes.booking_attachments.map((attachment) => {
                              return(
                                <tr>
                                  <td><a href={attachment.asset.url} target="_blank" download>{attachment.asset.url.split("/").pop()}</a></td>
                                  <td>{attachment.asset_content_type}</td>
                                  <td>{attachment.asset_human_file_size}</td>
                                  <td>
                                  <div className="action-buttons" minimal={'true'}>
                                    <Button minimal={true} small={true} onClick={() => this.removeAttachment(attachment)}>
                                      <img src="/delete-light-icon.svg" />
                                    </Button>
                                  </div>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>}
                      </div>
                    </div>
                    <div className="form-block">
                      <div className="form-block-title">Step : Add Your Note (If necessary)</div>
                      <div className="block-instruction"></div>
                      <div className="form-fields">
                        <div className="field-type0">
                          <FormGroup>
                            <textarea 
                              type='text' 
                              className='form-control' 
                              placeholder='Type here...'
                              value={attributes && attributes.note || ''}
                              onChange={(event) => {attributes.note = event.target.value;}}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="column-right">
                  <div className="summary-column">
                    <div className="summary-header" style={{backgroundColor: attributes && attributes.booking_form_color}}>Booking Summary</div>
                    <div className="summary-data">
                      <div className="scrollbar-right-side">
                        <div className="summary-field">
                          <div className="summary-icon"><img src="/date-time.svg" /></div>
                          <div className="summary-description">
                            <div className="headline"></div>
                            <div className="description">{moment(attributes && attributes.start_date).format('MMMM Do YYYY, h:mm:ss a')}</div>
                          </div>
                        </div>
                        {attributes && attributes.address && <div className="summary-field">
                          <div className="summary-icon"><img src="/map-pin-icon.svg" /></div>
                          <div className="summary-description">
                            <div className="headline">{attributes && attributes.first_name} {attributes && attributes.last_name}</div>
                            <div className="description">
                              <div className="content">{attributes && attributes.address}{attributes && attributes.suite ? ',' :''} {attributes && attributes.suite} {attributes && attributes.state}{attributes && attributes.zip ? ',' :''} {attributes && attributes.zip}</div>
                            </div>
                          </div>
                        </div>}
                        {serviceTypeDetail && serviceTypeDetail.object && serviceTypeDetail.object.attributes && serviceTypeDetail.object.attributes && <div className="summary-field">
                          <div className="summary-icon"><img src="/note-pad-icon.svg" /></div>
                          <div className="summary-description">
                          <div className="headline">Services</div>
                            <div className="description">
                              <div className="content">
                                {serviceTypeDetail.object.attributes.name}
                                <div>
                                  {"1 x "} {attributes  && currencyFormattedAmount(parseFloat(serviceTypeDetail.object.attributes.rate))}
                                </div>
                              </div>
                              <div className="price">
                                {attributes  && currencyFormattedAmount(serviceTypeDetail.object.attributes.rate)}
                              </div>
                            </div>
                          </div>
                        </div>}
                        {attributes && attributes.booking_pricing_parameters && attributes.booking_pricing_parameters.length > 0 &&
                          <div className="summary-field">
                            <div className="summary-icon"><img src="/note-pad-icon.svg" /></div>
                            <div className="summary-description">
                            <div className="headline">{attributes && attributes.pricing_title != '' ? attributes.pricing_title:'Pricing'}</div>
                                { attributes && attributes.booking_pricing_parameters.map((parameter) => {
                                    return (
                                      <div className="description" key={Math.random()}>
                                        <div className="content">
                                          {parameter.name}
                                          <div></div>
                                        </div>
                                        <div className="price">
                                          {attributes && currencyFormattedAmount((parameter.price))}</div>
                                      </div>
                                    )
                                  })
                                }
                            </div>
                          </div>
                        }
                        { service_type_extra && service_type_extra.length > 0 &&
                          <div className="summary-field">
                            <div className="summary-icon"><img src="/note-pad-icon.svg" /></div>
                            <div className="summary-description">
                            <div className="headline">Add ons</div>
                                {
                                  attributes && attributes.booking_service_type_extras && attributes.booking_service_type_extras.map((ext_service) => {
                                    if(ext_service._destroy !== 1) {
                                      return (
                                        <div className="description">
                                          <div className="content">
                                            {ext_service.name}
                                            <div></div>
                                          </div>
                                          <div className="price">
                                            {attributes  && currencyFormattedAmount(ext_service.price)}
                                          </div>
                                        </div>
                                      )
                                    }
                                  })
                                }
                            </div>
                          </div>
                        }
                      </div>
                      {attributes && <div className="summary-total">
                      <div className="total-label">Total (approximate)</div>
                      <div className="price">
                        {currencyFormattedAmount(attributes && attributes.total)}
                      </div>
                    </div>}
                      <Button 
                        onClick={() => this.updateBooking('draft')} 
                        className="save-draft-btn"
                        loading={isLoading}
                        style={{backgroundColor:'#eee'}}
                      >
                        Save
                      </Button>
                      <br/><br/>
                      <Button 
                        onClick={() => this.updateBooking('confirmed')} 
                        className={attributes && attributes.status === 'confirmed' ? "confirm-btn disable-confirm-btn" : "confirm-btn"}
                        //className={"confirm-btn"}
                        loading={isLoading}
                        disabled={attributes && attributes.status === 'confirmed' ? true : false}
                        style={{backgroundColor: attributes && attributes.booking_form_color, borderColor: attributes && attributes.booking_form_color}}
                        >
                        { attributes && attributes.cta_title != '' ? attributes.cta_title : 'Confirm Booking' }
                      </Button>
                      <br/><br/>
                      {
                        attributes && attributes.frequency && attributes.frequency.title === 'One Time' && attributes.scheduled_events.length > 0 ?
                        <Button 
                          onClick={() => this.startEditJob(attributes && attributes.scheduled_events.length > 0 && attributes.scheduled_events[0],attributes && attributes.id)} 
                          className={"confirm-btn"}
                          loading={isLoading}
                          >
                          Edit Job
                        </Button>
                        :
                        ''
                      }

                      {
                        attributes && attributes.frequency && attributes.frequency.title != 'One Time' && attributes.service_agreements.length > 0 ?
                        <Button 
                          onClick={() => this.startEditServiceAgreement(attributes && attributes.service_agreements.length > 0 && attributes.service_agreements[0].id,attributes && attributes.id)} 
                          className={"confirm-btn"}
                          loading={isLoading}
                          >
                          Edit Service Agreement
                        </Button>
                        :
                        ''
                      }
                      
                      {
                        attributes && attributes.status === 'confirmed' ?
                        <div className="confirm-booking-text">You have already confirmed your booking.</div>
                        : ""
                      }
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </PageWrapGray>
          }
        </div>
        <CopyLink
          isOpen={this.showCopyLinkDialog}
          link={attributes && attributes.public_link}
          onClose={this.onCloseCopyLinkDialog.bind(this)}
          onSuccess={this.onSuccessCopyLinkDialog.bind(this)}
        />
        <CopyLink
          isOpen={this.showCopyNewBrandLogoLinkDialog}
          link={`${attributes && attributes.public_link}?is_brand=true`}
          onClose={this.onCloseCopyBrandLinkDialog.bind(this)}
          onSuccess={this.onSuccessCopyBrandLinkDialog.bind(this)}
        />
      </div>
    );
  }

};
export default BookingShowPage;