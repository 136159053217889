import React from 'react';
import {
  Card, Elevation, Button, Intent
} from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import styled from '@emotion/styled';
import CardList from 'components/Billing/CardList';
import Toast from 'helpers/toaster';

const BillingGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: ${props => `${props.theme.unit*2}px`};
  align-items: flex-start;
  font-size: 16px;
  color: rgba(0,0,0,.65);
 
`

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
`

@inject('store', 'billingStore') @observer
class BillingCreditCard extends React.Component {

  @observable cardHolderName;
  @observable buttonEnabled = false;
  @observable loading = false;

  componentDidMount() {
    if(window.stripe){
      var elements = window.stripe.elements();
      this.card = elements.create('card', { hidePostalCode: true });
      this.card.mount('#card-element');
      this.card.addEventListener('change', this.onCardChange.bind(this));
    }
  }

  onCardChange = (event) => {
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      this.buttonEnabled = false;
      displayError.textContent = event.error.message;
    } else if (event.complete && this.cardHolderName !== '') {
      this.buttonEnabled = true;
      displayError.textContent = '';
    } else {
      displayError.textContent = '';
    }
  }

  onCardHolderNameChange = (e) => {
    this.cardHolderName = e.target.value;
    if (this.cardHolderName === '') {
      this.buttonEnabled = false
    }
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    this.loading = true;
    window.stripe.createToken(this.card, {
      name: this.cardHolderName,
      currency: 'CAD'
    }).then((result) => {
      if (result.error) {
        // Inform the user if there was an error
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {
        // Send the token to your server
        let { billingStore } = this.props;
        billingStore.addCard(result.token)
          .then(this.onCardSuccess.bind(this))
          .catch(this.onFailAddCard.bind(this))
      }
    })
  }

  onCardSuccess = () => {
    this.form.reset()
    this.card.clear()
    this.loading = false;
    Toast.show({ icon: 'thumbs-up', message: 'Card added successfully!', intent: Intent.SUCCESS })

  }

  onFailAddCard = () => {
    this.loading = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to add card!', intent: Intent.DANGER })
  }

  render() {
    return(
      <BillingGrid>
        <div className="card">
          <div className="card-body payment-card-title">
            <h5>Add/Update Payment Info</h5>
            <form onSubmit={this.onFormSubmit.bind(this)} ref={(form) => this.form = form }>
              <div className="bp3-form-group">
                <label className="bp3-label">
                  Name on Card:
                </label>
                <div className="bp3-form-content">
                  <div className="bp3-input-group payment-input">
                    <span className="bp3-icon bp3-icon-user input-user-icon"></span>
                    <input id="form-group-input" type="text" className="bp3-input" placeholder="Name on Card" />
                  </div>
                </div>
              </div>

              <div className="bp3-form-group">
                <label className="bp3-label">
                  Card Details:
                  <div className="bp3-form-content">
                    <div className="bp3-input-group">
                      <div id='card-element' className="card-details-input">
                      </div>
                    </div>
                  </div>
                </label>
                <div id='card-errors' className='bp3-form-helper-text' />
              </div>
              <Button 
                type="submit" 
                disabled={!this.buttonEnabled} 
                className="bp3-intent-primary bp3-icon-credit-card"
                loading={this.loading}
              >
                Add Card
              </Button>
            </form>
         </div>
        </div>
        <div className="card">
          <div className="card-body">
            <CardList />
          </div>
          </div>
      </BillingGrid>
    )
  }
};

export default BillingCreditCard;