import React from 'react';
import { Spinner, Alert, Button, Intent } from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import qs from 'qs';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
import ActiveEquipmentDetailsTable from 'components/Equipment/ActiveEquipmentDetailsTable';
import _ from 'lodash';

@inject('viewStore','equipmentStore','workReportStore') @observer
class EquipmentsTable extends React.Component {
  
  @observable isSelectDialogVisible = false;
  @observable isSelectId;
  @observable showLoader = false;

  onPaginationChange(newPage) {
    let {
      viewStore: {
        equipmentSearchWorkReport
      }
    } = this.props;
    this.props.viewStore.equipmentSearchWorkReport = {
      ...equipmentSearchWorkReport,
      page: newPage
    };
    this.props.equipmentStore.loadEquipmentsForWorkReports(); 
  }
  
  render() {
    let {objects, meta, isLoading, workReportId} = this.props; 

    if (isLoading) {
      return(
        <div className="">
          <Spinner size='25' />
        </div>
      ) 
    }

    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
          No equipment have been added for this client yet.
        </div>
      )
    }
    
    return (
      <div className="table-responsive">
        <table className='table table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>             
              <th>Description</th>              
              <th>Make</th>
              <th>Model</th>
              <th>Serial</th>            
              <th width="20%">Location</th>
              <th width="28%">Action</th>           
            </tr>
          </thead>
          <tbody>
            {
              objects && objects.map((equipment) => {
                const equipmentItemIds = workReportId.attributes.equipments.map((equipment) => equipment.id );
                let valueInWorkReportEquipment = _.includes(equipmentItemIds,equipment.attributes.id);                  
                let value = _.find(workReportId.attributes.equipments, { 'id': equipment.attributes.id });
                if(value){
                  return (
                    <ActiveEquipmentDetailsTable equipment={equipment} servicedEquipment={value} valueInWorkReportEquipment={valueInWorkReportEquipment}  workReportId={workReportId} />
                  )
                }else{
                  return (
                    <ActiveEquipmentDetailsTable equipment={equipment}  servicedEquipment={''} valueInWorkReportEquipment={valueInWorkReportEquipment}  workReportId={workReportId} />
                  )
                }             
              })
            }
          </tbody>
        </table>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>       
      </div>
    )
  }
}

export default EquipmentsTable;