import { observable, action, toJS } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';
import qs from 'qs';

class FormSubmissionStore {

  @observable tenant_id = '';
  @observable form_submission_id = '';
  @observable token = '';

  @observable search = {
    isLoading: false,
    isError: false,
    isSearched: false,
  };
  @observable object = [];

  @observable formSubmission = {
    isLoading: false,
    isError: false,
    object: null,
  };

  @observable submission = {
    isLoading: false,
    isError: false,
    object: null,
  };

  @action
  getFormSubmission(formSubmission) {
    this.submission.isLoading = true;
    let promise = Api.get(`/form_submissions/${formSubmission.id}`);

    promise
      .then((response) => {
        this.submission.isLoading = false;
        this.submission.isError = false;
        this.submission.object = response.data.data;
      })
      .catch((error) => {
        this.submission.isLoading = false;
        this.submission.isError = false;
      })
  }

  @action
  updateFormSubmission() {
    let promise = Api.put(`/form_submissions/${this.submission.object.id}`, {form_submission: this.submission.object.attributes})

    promise
      .then((response) => {
      })
      .catch((error) => {
      })

    return promise;
  }

  @action
  updateFormSubmissionData(data) {
    // this.formSubmission.isLoading = true;
    let form_submission = {
      data: data,
      tenant_id: this.tenant_id,
      token: this.token
    }
    let promise = Api.put(`/form_submissions/${this.formSubmission.object.attributes.id}/update_via_token`, {form_submission: form_submission})

    promise
      .then((response) => {
        this.formSubmission.isLoading = false;
        this.formSubmission.isError = false;
      })
      .catch((error) => {
        this.formSubmission.isLoading = false;
        this.formSubmission.isError = true;
      })

    return promise;
  }

  @action
  loadFormSubmissionViaToken() {
    let form_submission = {
      form_submission_id: this.form_submission_id,
      token: this.token,
      tenant_id: this.tenant_id
    }
    this.formSubmission.isLoading = true;
    let promise = Api.get(`/form_submissions/fetch_via_token?` + qs.stringify(form_submission));

    promise
      .then((response) => {
        this.formSubmission.isLoading = false;
        this.formSubmission.isError = false;
        this.formSubmission.object = response.data.data;
      })
      .catch((error) => {
        this.formSubmission.isLoading = false;
        this.formSubmission.isError = true;
      })
  }

  @action
  loadFormSubmission() {
    this.search.isLoading = true;
    let promise = Api.get(`/form_submissions/${stores.viewStore.formSubmissionSearch.uuid}`);

    promise
      .then((response) => {
        this.search.isLoading = false;
        this.search.isSearched = true;
        this.object = response.data.data;
      })
      .catch((error) => {
        this.search.isLoading = false;
        this.search.isError = true;
      })
  }
};

export default FormSubmissionStore;