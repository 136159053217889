import React from 'react';
import {
  Spinner, Card, Elevation, Menu, MenuItem, Icon
} from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Api } from 'services/Api';
import styled from '@emotion/styled';

const BillingGrid = styled('div')`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: ${props => `${props.theme.unit*2}px`};
  align-items: flex-start;
  font-size: 16px;
  color: rgba(0,0,0,.65);  
  margin-top:15px;
`

const CardContent = styled('div')`
  
`

@observer
class BillingOverview extends React.Component {
  
  @observable billing = {
    isLoading: true,
    overview: null
  }

  componentDidMount() {
    let promise = Api.get(`/billing/overview`);

    promise
      .then((res) => {
        this.billing = {
          isLoading: false,
          overview: res.data
        }
      })
  }

  render() {
    const {
      billing: {
        isLoading,
        overview
      }
    } = this;
    if(isLoading) {
      return (
        <BillingGrid>
          <div>
            <Spinner />
          </div>
        </BillingGrid>
      )
    }
    return(
      <div className="responsive-card-layout">        
          <div className="card mb-3">
            <div className="card-body">        
              <h5>Your Charges Explained</h5>
              <hr/>
              <p>Following is an explanation & breakdown of your charges. If you have any questions about these charges or rates, please feel free to email: <a href="mailto:support@fieldnexus.com">support@fieldnexus.com</a></p>
              <br/>
              <Menu large={true}>
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='people'
                  text="Total Users"
                  label={`${overview.user_count} Users`}
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='dollar'
                  text="Rate"
                  label={`${overview.rate_per_user}/user/month`}
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='calculator'
                  text="Grand Total"
                  label={overview.grand_total}
                />
              </Menu>
          
          </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <h5>Whats Included?</h5>
              <hr/>
              <p>Following is a breakdown of all what is included with your subscription.</p>
              <br/>
              <Menu large={true}>
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='people'
                  text="User Seats"
                  label='Unlimited'
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='wrench'
                  text="Jobs"
                  label='Unlimited'
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='document'
                  text="Work Reports"
                  label='Unlimited'
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='time'
                  text="Time Tracking"
                  label={<Icon icon='tick' />}
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='drive-time'
                  text="Scheduling & Dispatch"
                  label={<Icon icon='tick' />}
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='projects'
                  text="Job Costing, Estimates & Proposals"
                  label={<Icon icon='tick' />}
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='person'
                  text="Customer CRM"
                  label={<Icon icon='tick' />}
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='geolocation'
                  text="GPS Routing"
                  label={<Icon icon='tick' />}
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='draw'
                  text="Service Agreements"
                  label={<Icon icon='tick' />}
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='form'
                  text="Job Forms"
                  label={<Icon icon='tick' />}
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='mobile-phone'
                  text="iOS & Android Mobile Apps"
                  label={<Icon icon='tick' />}
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='chart'
                  text="Reporting"
                  label={<Icon icon='tick' />}
                />
                <MenuItem
                  textClassName="bp3-overview-menu"
                  labelClassName="bp3-overview-menu"
                  icon='offline'
                  text="Offline Access from Apps"
                  label={<Icon icon='cross' />}
                />
              </Menu>
          </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <h5>How Billing Works?</h5>
              <hr/>
              <p>Billing is based strictly only on the number of users using the software. For instance, in any given month, if your total number of users goes up or down your charges for that month are adjusted automatically by the system.</p>
              <p>For each billing cycle (30 days), you will be emailed an invoice detailing the charges along with any applicable taxes.</p>
              <p>In case of any questions, please don't hesitate to email: <a href="mailto:support@fieldnexus.com">support@fieldnexus.com</a></p>
          </div>
          </div>
        
      </div>
    )
  }

};

export default BillingOverview;