import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PersistJob from './add_job';
import PersistServiceAgreement from './persist_service_agreement';

@inject('store', 'projectStore', 'jobStore') @observer
class AddNewJob extends Component {

  render() {
    let { isServiceAgreement } = this.props.jobStore;
    let { isOpen } = this.props.store;
    if (!isOpen) {
      return(null);
    }
    
    return (
      <React.Fragment>
        {!isServiceAgreement && <PersistJob />}
        {isServiceAgreement && <PersistServiceAgreement />}
      </React.Fragment>
    )
  }

};

export default AddNewJob;