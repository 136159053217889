import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { 
  Button, Intent, Dialog
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';

@inject('store') @observer
class CopyLink extends Component {

  handleCloseCopyDailog() {
    this.props.onClose();
  }

  handleSuccessCopyDailog() {
    this.props.onSuccess();
  }

  render() {
    return(
      <Dialog
        title="Copy Link"
        className="copy-link-dialog"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseCopyDailog.bind(this)}        
      >
        <div className="copy-link-dialog-content">
          <div className="copy-field">
            <input 
              type='text'
              className='bp3-input bp3-fill' 
              placeholder='Public Link'
              defaultValue={this.props.link}
            />
            <div className="copy-instruction">Copy the link and share it with your customer.</div>
            <CopyToClipboard text={this.props.link}
              onCopy={() => Toast.show({ message: "Copied!", intent: Intent.SUCCESS })}>
              <Button className='bp3-copy-link' onClick={this.handleSuccessCopyDailog.bind(this)}>Copy Link</Button>
            </CopyToClipboard>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default CopyLink;