import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Popover, Position, Tag, Intent } from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import ListPicker from 'components/ListPicker';

const options = [
  { label: 'Draft', value: 'draft', selected: false },
  { label: 'Submitted', value: 'submitted', selected: false },
  { label: 'Reviewed', value: 'reviewed', selected: false },
  { label: 'Invoiced', value: 'invoice', selected: false }
];
@inject('store', 'workReportStore') @observer
class WorkReportHeader extends Component {

  onWorkReportStatusChange(selections) {
    let {
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
    let selectedValues = selections.map((status) => status.value);
    object.attributes.status = selectedValues[0];

    if (object.attributes.status) {
      this.props.workReportStore.changeStatus(object, object.attributes.status)
        .then((response) => {
          if (response.data.data.attributes.status === 'invoiced' || response.data.data.attributes.status === 'invoice') {
            this.isOpenAddInvoiceNumberDialog = true;
          }
          Toast.show({ message: "Work Report status changed successfully.", intent: Intent.SUCCESS })
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
          } else {
            Toast.show({ message: "Something went wrong. Problem in changing work report status.", intent: Intent.DANGER })
          }
        })
    }
  }

  render() {
    const {
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
    return (
      <div className="invoice-header" style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', marginTop: '1rem' }}>
        <div className="logo-company-name">
          <div className="header-logo">
          <img src={object.attributes.tenant.logo} height='75' alt='tenant logo'/>
        </div>
          <div className="header-address" style={{ display: 'flex', flexDirection: 'column' }}>
          <h5>{object.attributes.tenant.name}</h5>
          <span>{object.attributes.tenant.street_address}</span>
          <span>{object.attributes.tenant.city}, {object.attributes.tenant.province}, {object.attributes.tenant.postal_code}</span>
          <span>
            <strong>Tel: </strong>
            {object.attributes.tenant.phone}
          </span>
        </div>
        </div>
        <div className="right-info">
          {/* <div className="status-box text-right">
            <Popover
              minimal={true}
              content={
                <ListPicker
                  selectedOptions={[object.attributes.status]}
                  options={options}
                  maximumSelections={1}
                  onChange={this.onWorkReportStatusChange.bind(this)}
                />
              }
              position={Position.BOTTOM_LEFT}
              usePortal={true}
              className="status-selector"
            >
              <Button rightIcon='caret-down' className="status-btn">
                Status
                <span>&nbsp;</span>
                <Tag minimal={true} round={true}>{object.attributes.status}</Tag>
              </Button>
            </Popover> */}
            {/* <Popover position={Position.BOTTOM_LEFT}>
                <Button rightIcon='caret-down' >In Draft</Button>
              </Popover> */}
          {/* </div> */}
          <div className="workorder-no-block">
            <p className="mb-0 mr-3">Work Order No.</p>
            <h4 className="mb-0 font-weight-bold">{object && object.attributes.number}</h4>
          </div>
        </div>
      </div>
    )
  }
}

export default WorkReportHeader;