import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {
  Menu, MenuItem, Spinner
} from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
// import JobCard from 'components/Job/Card';
import styled from '@emotion/styled';
import DispatchBoard from 'components/DispatchBoard';

const DashboardGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
`

// const JobsColumn = styled('div')`
//   background-color: #F8FAFB;
//   height: calc(100vh - 100px);
//   border-right: 1px solid #5453534d;
//   overflow: scroll;
//   overflow-x: hidden;
// `

const DispatchBoardColumn = styled('div')`
  width: 100%;
  height: calc(100vh - 75px);
  overflow:hidden;
`

@inject('store', 'dashboard') @observer
class DashboardIndexPage extends Component {

  @observable selectedDate = new Date();

  async componentDidMount() {
    let { 
      dashboard: { 
        users: { 
          objects 
        } 
      } 
    } = this.props;
    objects && objects.length == 0 && this.props.dashboard.fetchDashboardUsers();
  }

  renderContent = () => {
    return (
      <Menu>
        <MenuItem>Reassign</MenuItem>
        <MenuItem>Edit</MenuItem>
        <MenuItem>Delete</MenuItem>
      </Menu>
    )
  }

  render() {
    let { 
      dashboard: { 
        users: { 
          objects 
        } 
      } 
    } = this.props;
    return (
      <div>
        {/* <SearchBars.Dashboard /> */}
        <DashboardGrid>
          {/* <div style={{ boxShadow: '0px 0px 10px' }}>
            <nav className="bp3-navbar">
              <div className="bp3-navbar-group bp3-align-right">
                <Button rightIcon='caret-down' intent='primary'>
                  Options
                </Button>
              </div>
            </nav>
            <JobsColumn>
              <span>
                {
                  dashboard.jobs.isLoading && <Spinner />
                }
              </span>
              {
                dashboard.jobs && <ul style={{ padding: 0, margin: 0 }} className='job-card-container'>
                  {
                    dashboard.jobs.objects.map((job, index) => {
                      return <JobCard job={job} key={job.id} index={index} onClick={() => { store.showPanel = true }}/>
                    })
                  }
                </ul>
              }
            </JobsColumn>
          </div> */}
          <DispatchBoardColumn>
            { objects && objects.length > 0 ? <DispatchBoard /> : <Spinner className='spinner-center' size={48}></Spinner> }
          </DispatchBoardColumn>
        </DashboardGrid>
      </div>
    )
  }
};

export default DashboardIndexPage;