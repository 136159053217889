import React, { Component } from 'react';
import styled from '@emotion/styled';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`

const JobCardHolder = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #fff;
  padding: ${props => `${props.theme.unit*3}px`};
`

const PaymentGrid = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#F6F6F6;
`

const PaymentCardHolder = styled('div')`
  width: 768px;
  background: #fff;
  border: 1px solid #E6E8F1;
  padding: 10px;
`

const TopBanner = styled('div')`  
  background-color: #FCFCFD;
  border-bottom: 1px solid #E6E8F1;
  padding: ${props => `${props.theme.unit}px ${props.theme.unit*4}px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
`

const TopNavbar = styled('div')`
  background: #FBFCFE;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 9;
  height: 80px;  
  border-bottom:1px solid #E6E8F1;
  display: flex;
  flex-direction: column;    
  justify-content: center; 
`

const TaxesBanner = styled('div')`  
  //border-bottom: 1px solid #E8ECF0;
  padding: ${props => `${props.theme.unit}px ${props.theme.unit}px`};
  text-align: right;  
`

const PaymentBanner = styled('div')`
  height: ${props => `${props.theme.unit*23}px`};
  background-color: #E9EEF1;
  border-bottom: 1px solid #D0DBE1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ColumnContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 32px;
`

const Column = styled('div')`
  margin-right: 20px;
  width: 100%;
`

const StyledParent = styled('div')`
  background-color: ${props => props.theme.global.white};
  border:1px solid #E6E8F1;
`

class Card extends Component {
  render() {
    let { style } = this.props;
    return (
      <StyledParent style={style}>
        {this.props.children}
      </StyledParent>
    )
  }
};

export {
  CardHolder,
  TopBanner,
  TaxesBanner,
  PaymentBanner,
  PaymentGrid,
  PaymentCardHolder,
  ColumnContainer,
  Column,
  Card,
  TopNavbar,
  JobCardHolder
}