import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import { 
  Spinner, NonIdealState, Button, Intent, ButtonGroup, 
  MenuItem, Menu, Popover, Position, Alert, Dialog
} from '@blueprintjs/core';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import InvoiceShow from 'components/Invoice/Show';
import { TopBanner } from 'components/Styles';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Toast from 'helpers/toaster';
import SyncWithQuickbooksModal from 'components/Invoice/SyncWithQuickbooksModal';
import SyncWithXeroModal from 'components/Invoice/SyncWithXeroModal';
import SendInvoiceModal from 'components/Payment/SendInvoiceModal';
import ProductServiceSyncWithQuickbooksModal from 'components/Invoice/ProductServiceSyncWithQuickbooksModal';
import ManualTransactionModal from 'components/Invoice/ManualTransactionModal';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'invoiceStore', 'viewStore', 'quickbooksStore', 'xeroStore') @observer
class InvoiceShowHeader extends Component {

  @observable downloadingPdf = false;
  @observable showSyncWithQuickbooksDialog = false;
  @observable showSyncWithXeroDialog = false;
  @observable isOpenSyncInvoiceConfirm = false;
  @observable isOpenSyncXeroConfirm = false;
  @observable isOpenSyncProductServicesDialog = false;
  @observable isOpenSendInvoiceModal = false;
  @observable isOpenUpdateInvoiceToQuickbooksConfirm = false;
  @observable isOpenUpdateInvoiceToXeroConfirm = false;
  @observable showManualTransactionDialog = false;

  componentDidMount() {
    this.props.quickbooksStore.loadQuickbooksItems()
  }

  downloadInvoicePDF = () => {
    this.downloadingPdf = true;
    this.props.invoiceStore.downloadInvoicePDF()
      .then((response) => {
        this.downloadingPdf = false;
      })
      .catch((error) => {
        this.downloadingPdf = false;
      })
  }

  sendInvoicePDF = () => {
    this.isOpenSendInvoiceModal = true;
  }

  onCloseSyncWithQuickbooksDialog() {
    this.showSyncWithQuickbooksDialog = false;
  }

  onCloseSyncWithXeroDialog() {
    this.showSyncWithXeroDialog = false;
  }

  handleConnectWithQuickbooks() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    
    this.props.quickbooksStore.checkProductServicesStatus(object)
      .then(this.onSuccessfulCheckProductServiceStatus.bind(this))
      .catch(this.FailCheckProductServiceStatus.bind(this))
  } 
  

  handleConnectWithXero() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    
    this.props.xeroStore.checkXeroServicesStatus(object)
      .then(this.onSuccessfulCheckXeroServiceStatus.bind(this))
      .catch(this.FailCheckXeroServiceStatus.bind(this))
  }

  FailCheckXeroServiceStatus(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went worng.', intent: Intent.DANGER })
    }
  }

  onSuccessfulCheckXeroServiceStatus(response) {
    if(response.data.xero_product_services_link_required) {
      // Product/Service Sync required
      this.isOpenSyncProductServicesDialog = true;
    } else {
      // Product/Service Sync not required
      // Checking client sync required
      const {
        invoiceStore: {
          invoice: {
            object
          }
        }
      } = this.props;

      let client = object.attributes.client;
      if(client.synced_with_xero) {
        // client synced with xero
        if(!object.attributes.synced_with_xero) {
          this.isOpenSyncXeroConfirm = true;
        } else {
          this.isOpenUpdateInvoiceToXeroConfirm = true;
        }
        //this.isOpenUpdateInvoiceToXeroConfirm = true;
      } else {
        // Client not synced with quickbooks 
        // open client sync dialog
        this.showSyncWithXeroDialog = true 
        this.props.xeroStore.loadXeroCustomers();
      }
    }
  }

  onSuccessfulCheckProductServiceStatus(response) {
    if(response.data.product_services_link_required) {
      // Product/Service Sync required
      this.isOpenSyncProductServicesDialog = true;
    } else {
      // Product/Service Sync not required
      // Checking client sync required
      const {
        invoiceStore: {
          invoice: {
            object
          }
        }
      } = this.props;

      let client = object.attributes.client;
      if(client.synced_with_quickbooks) {
        // client synced with quickbooks
        if(!object.attributes.synced_with_quickbooks) {
          this.isOpenSyncInvoiceConfirm = true;
        } else {
          this.isOpenUpdateInvoiceToQuickbooksConfirm = true;
        }
      } else {
        // Client not synced with quickbooks 
        // open client sync dialog
        this.showSyncWithQuickbooksDialog = true 
        this.props.quickbooksStore.loadQuickbooksCustomers();
      }
    }
  }

  FailCheckProductServiceStatus(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went worng.', intent: Intent.DANGER })
    }
  }

  handleUpdateInvoiceToQuickbooksConfirmCancel() {
    this.isOpenUpdateInvoiceToQuickbooksConfirm = false;
  }

  handleUpdateInvoiceToQuickbooksConfirm() {
    let {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    this.isOpenUpdateInvoiceToQuickbooksConfirm = false
    this.props.quickbooksStore.updateInvoiceWithQuickbooks(object)
      .then(this.onSuccessfulUpdateInvoiceToQuickbooks.bind(this))
      .catch(this.onFailUpdateInvoiceToQuickbooks.bind(this))
  }

  handleUpdateInvoiceToXeroConfirmCancel () {
    this.isOpenUpdateInvoiceToXeroConfirm = false;
  }

  handleUpdateInvoiceToXeroConfirm() {
    let {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    this.isOpenUpdateInvoiceToXeroConfirm = false
    this.props.xeroStore.updateInvoiceWithXero(object)
      .then(this.onSuccessfulUpdateInvoiceToXero.bind(this))
      .catch(this.onFailUpdateInvoiceToXero.bind(this))
  }

  onSuccessfulUpdateInvoiceToXero(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Invoice updated successfully to Xero.', intent: Intent.SUCCESS })
  }

  onFailUpdateInvoiceToXero(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went worng. Unable to update invoice to Xero.', intent: Intent.DANGER })
    }
  }


  onSuccessfulUpdateInvoiceToQuickbooks(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Invoice updated successfully to QuickBooks.', intent: Intent.SUCCESS })
  }

  onFailUpdateInvoiceToQuickbooks(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went worng. Unable to update invoice to QuickBooks.', intent: Intent.DANGER })
    }
  }

  handleSyncInvoiceConfirmCancel() {
    this.isOpenSyncInvoiceConfirm = false;
  }

  handleSyncXeroInvoiceConfirmCancel() {
    this.isOpenSyncXeroConfirm = false;
  }

  handleSyncXeroInvoiceConfirm() {
    let {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    this.isOpenSyncInvoiceConfirm = false
    this.props.xeroStore.syncInvoiceWithXero(object)
      .then(this.onSuccessfulSyncedInvoiceXero.bind(this))
      .catch(this.onFailSyncInvoice.bind(this))
  }

  handleSyncInvoiceConfirm() {
    let {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    this.isOpenSyncInvoiceConfirm = false
    this.props.quickbooksStore.syncInvoiceWithQuickbooks(object)
      .then(this.onSuccessfulSyncedInvoice.bind(this))
      .catch(this.onFailSyncInvoice.bind(this))
  }

  onSuccessfulSyncedInvoiceXero(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Invoice synced successfully with xero.', intent: Intent.SUCCESS })
  }

  onSuccessfulSyncedInvoice(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Invoice synced successfully with QuickBooks.', intent: Intent.SUCCESS })
  }

  onFailSyncInvoice(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went worng. Unable to sync invoice.', intent: Intent.DANGER })
    }
  }

  onSuccessClientLink(client) {
    // closing Client link dialog
    this.showSyncWithQuickbooksDialog = false;
    // opening confirmation box for invoice sync
    this.isOpenSyncInvoiceConfirm = true;
  }

  onSuccessXeroClientLink(client) {
    // closing Client link dialog
    this.showSyncWithXeroDialog = false;
    this.isOpenSyncXeroConfirm = true;
  }

  onCloseProductServicesDialog() {
    this.isOpenSyncProductServicesDialog = false;
  }

  onSuccessProductServiceSync() {
    this.isOpenSyncProductServicesDialog = false;
    this.handleConnectWithQuickbooks()
  }

  handleCloseInvoiceSendDailog() {
    this.isOpenSendInvoiceModal = false;
  }

  onSuccessInvoiceSend() {
    this.isOpenSendInvoiceModal = false;
  }

  handleCloseCopyDailog() {
    let {
      store
    } = this.props;
    store.isOpenAddCopyDialog = false;
  }

  markInvoiceAsSent() {
    const {
      invoiceStore
    } = this.props;

    invoiceStore.changeStatus('sent')
      .then((response) => {
        Toast.show({ icon: 'thumbs-up', message: 'Invoice successfully marked as sent.', intent: Intent.SUCCESS })
      })
      .catch((error) => {
        if(error.response && error.response.data && error.response.data.errors) {
          Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ icon: 'warning-sign', message: 'Something went worng. Unable to mark invoice as sent.', intent: Intent.DANGER })
        }
      })
  }

  markInvoiceAsPaid() {
    this.showManualTransactionDialog = true;
  }

  onCloseManualTransactionDialog() {
    this.showManualTransactionDialog = false;
  }

  onSuccessManualTransaction() {
    this.showManualTransactionDialog = false;
  }

  render() {
    let {
      invoiceStore: {
        invoice: {
          object
        }
      }, 
      quickbooksStore: {
        productServiceStatus: {
          isLoading
        }
      },
      store
    } = this.props;

    const moreAction = (
      <Menu>
        <MenuItem icon="envelope" text="Mark as Sent" onClick={() => this.markInvoiceAsSent()} disabled={object.attributes.status === 'sent' ? true : false} />
        <MenuItem icon="dollar" text="Mark as Paid" onClick={() => this.markInvoiceAsPaid()} disabled={object.attributes.payment_status === 'paid' ? true : false} />
        <Menu.Divider />
        <MenuItem icon="new-link" text="Open In New Tab" href={object.attributes.public_link} target="_blank"/>
        <MenuItem icon="document-share" text="Copy Link" onClick={() => { store.isOpenAddCopyDialog = true }}/>
        <MenuItem icon="download" text="Download PDF" onClick={this.downloadInvoicePDF} loading={this.downloadingPdf}/>
      </Menu>
    );
    
    return (
      <div>
        <TopBanner className="top-bar-styling">
          <div className="work-report-style" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="work-report-title">
              <h3>Invoice {object.attributes.number}</h3>
            </div>
            <div className="invoice-download-copy more-actions" style={{display: 'flex', justifyContent: 'space-between'}}>
              {/* {object.attributes.synced_with_xero && 
                <div>
                  <span className="bp3-tag bp3-minimal bp3-intent-success" style={{marginRight: 5, minHeight: 30}}>
                    Last Synced at Xero {object.attributes.synced_at_with_xero && <span>&nbsp;&nbsp;{object.attributes.synced_at_with_xero}</span>}
                  </span>  
                </div>
              } */}
              {object.attributes.synced_with_quickbooks && 
                <div>
                  <span className="bp3-tag bp3-minimal bp3-intent-success" style={{marginRight: 5, minHeight: 30}}>
                    Last Synced at QuickBooks {object.attributes.synced_at_with_quickbooks && <span>&nbsp;&nbsp;{object.attributes.synced_at_with_quickbooks}</span>}
                  </span> 
                </div>
              }
              <ButtonGroup className="quickbooks-actions">
                <Popover 
                  minimal={true}
                  content={
                    <Menu>
                      <MenuItem text="QuickBooks" onClick={this.handleConnectWithQuickbooks.bind(this)} />
                      {/* <MenuItem text="Xero"  onClick={this.handleConnectWithXero.bind(this)} /> */}
                    </Menu>
                  } 
                  position={Position.BOTTOM_LEFT}
                  usePortal={true}>
                  <Button icon='cloud-upload' rightIcon='caret-down'>
                    {isLoading &&
                      <Spinner size={Spinner.SIZE_SMALL} />}
                    {!isLoading && <span>Push To</span>}
                  </Button>
                </Popover>
                
              </ButtonGroup>
              <div className="actions-dropdwon" options={'false'} {...this.props}>
                <Popover content={moreAction} position={Position.BOTTOM}>
                    <Button className="action-btn" icon="chevron-down" text="More Actions" />
                </Popover>
              </div>
              <Button icon='upload' className="bp3-intent-primary primary-btn" onClick={this.sendInvoicePDF}>
                Send Invoice
              </Button>
            </div>
          </div>
          <SyncWithQuickbooksModal
            isOpen={this.showSyncWithQuickbooksDialog}
            onClose={this.onCloseSyncWithQuickbooksDialog.bind(this)}
            onSuccess={this.onSuccessClientLink.bind(this)}
          />
          <SyncWithXeroModal
            isOpen={this.showSyncWithXeroDialog}
            onClose={this.onCloseSyncWithXeroDialog.bind(this)}
            onSuccess={this.onSuccessXeroClientLink.bind(this)}
          />
          <ProductServiceSyncWithQuickbooksModal
            isOpen={this.isOpenSyncProductServicesDialog}
            onClose={this.onCloseProductServicesDialog.bind(this)}
            onSuccess={this.onSuccessProductServiceSync.bind(this)}
          />
          <SendInvoiceModal
            isOpen={this.isOpenSendInvoiceModal}
            onClose={this.handleCloseInvoiceSendDailog.bind(this)}
            onSuccess={this.onSuccessInvoiceSend.bind(this)}
          />
          <Alert
            cancelButtonText="No"
            confirmButtonText="Yes"
            icon="help"
            intent={Intent.PRIMARY}
            isOpen={this.isOpenSyncInvoiceConfirm}
            onCancel={this.handleSyncInvoiceConfirmCancel.bind(this)}
            onConfirm={this.handleSyncInvoiceConfirm.bind(this)}          
          >
              Are you sure to sync this invoice with QuickBooks?         
          </Alert>
          <Alert
            cancelButtonText="No"
            confirmButtonText="Yes"
            icon="help"
            intent={Intent.PRIMARY}
            isOpen={this.isOpenUpdateInvoiceToQuickbooksConfirm}
            onCancel={this.handleUpdateInvoiceToQuickbooksConfirmCancel.bind(this)}
            onConfirm={this.handleUpdateInvoiceToQuickbooksConfirm.bind(this)}          
          >
              Are you sure to update this invoice to QuickBooks? 
              <p>
                <b>Warning:</b> By updating invoice to quickbooks, all previously synced data will be deleted and not be recoverable.
              </p>       
          </Alert>
          <Alert
            cancelButtonText="No"
            confirmButtonText="Yes"
            icon="help"
            intent={Intent.PRIMARY}
            isOpen={this.isOpenUpdateInvoiceToXeroConfirm}
            onCancel={this.handleUpdateInvoiceToXeroConfirmCancel.bind(this)}
            onConfirm={this.handleUpdateInvoiceToXeroConfirm.bind(this)}          
          >
              Are you sure to update this invoice to Xero? 
              <p>
                <b>Warning:</b> By updating invoice to xero, all previously synced data will be deleted and not be recoverable.
              </p>       
          </Alert>
          <Alert
            cancelButtonText="No"
            confirmButtonText="Yes"
            icon="help"
            intent={Intent.PRIMARY}
            isOpen={this.isOpenSyncXeroConfirm}
            onCancel={this.handleSyncXeroInvoiceConfirmCancel.bind(this)}
            onConfirm={this.handleSyncXeroInvoiceConfirm.bind(this)}          
          >
              Are you sure to sync this invoice with Xero?         
          </Alert>
          <ManualTransactionModal
            isOpen={this.showManualTransactionDialog}
            onClose={this.onCloseManualTransactionDialog.bind(this)}
            onSuccess={this.onSuccessManualTransaction.bind(this)}
          />
        </TopBanner>
        <Dialog
          title="Copy Link"
          className="copy-link-dialog"
          isOpen={store.isOpenAddCopyDialog}
          isCloseButtonShown={true}
          onClose={this.handleCloseCopyDailog.bind(this)}        
          style={{}}
        >
          <div className="copy-link-dialog-content">
            <div className="copy-field">
            <input 
                type='text'
                className='bp3-input bp3-fill' 
                placeholder='Public Link'
                defaultValue={object.attributes.public_link}
              />
              <div className="copy-instruction">Copy the link and share it with your customer.</div>
              <CopyToClipboard text={object.attributes.public_link}
                onCopy={() => Toast.show({ message: "Copied!", intent: Intent.SUCCESS })}>
                <Button className='bp3-copy-link' onClick={this.handleCloseCopyDailog.bind(this)}>Copy Link</Button>
              </CopyToClipboard>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

@inject('store', 'invoiceStore') @observer
class InvoiceShowPage extends Component {

  get canRender() {
    let {
      invoiceStore: {
        invoice: {
          isError,
          object
        }
      }
    } = this.props;
    return (!isError && object && object.id);
  }

  render() {
    let content, title;
    let {
      invoiceStore: {
        invoice: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError && object && !object.id) {
      title = 'Loading...'
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load quote'; 
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      title = `#${object.attributes.number} Invoice | Field Nexus`;
      content = <InvoiceShow />;
    }
    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        { this.canRender && <InvoiceShowHeader /> }
        <PageWrapGray>
          {content}
        </PageWrapGray>
      </div>
    )
  }
};

export default InvoiceShowPage;
