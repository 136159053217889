import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import {
  Button, FormGroup, Checkbox, DateTimePicker,
  Intent, Spinner, RadioGroup, Radio, Divider, Dialog, Classes, Breadcrumbs, IBreadcrumbProps
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import Lockr from 'lockr';
import _ from 'lodash';
import BookingDateTimeInput from 'components/BookingDateTimeInput';
import moment from 'moment';
import GeoComplete from '../../helpers/GeoComplete';
import { currencySymbol, currencyFormattedAmount } from 'helpers/currency';
import QuestionRadio from 'components/Booking/QuestionRadio';
import QuestionSelect from 'components/Booking/QuestionSelect';
import QuestionTextbox from 'components/Booking/QuestionTextbox';
import BookingConfirmationMessage from 'pages/BookingConfirmationMessage';

var startCountRange = 0
var label = '';
var indexChecked = '';
var selectedAnswer = '';
var url_string = window.location.href;
var url = new URL(url_string);
var is_brand = url.searchParams.get("is_brand");
var princingRangeCombineArray = [];

const items = [
  { label: 'Cash', value: 'Cash' },
  { label: 'Interac', value: 'Interac' },
  { label: 'Cheque', value: 'Cheque' },
  { label: 'Debit/Credit', value: 'dr/cr' }
];

const BREADCRUMBSS: IBreadcrumbProps = [
  { href: "#", text: "Services" },
  { href: "#", text: "Details" },
  { href: "#", text: "Your Info" },
  { href: "#", text: "Address" },
  { href: "#", text: "Schedule" },
  { href: "#", text: "Finish" },
];

const PageWrapGray = styled('div')`
  min-height: calc(100vh - 75px);
  width: 100%;
`
@inject('bookingStore', 'preferenceStore', 'serviceTypeStore', 'pricingParameterStore', 'frequencyStore', 'questionGroupStore', 'store', 'viewStore','serviceTypeCategoryStore') @observer
class BookingModal extends Component {

  @observable pricingParameterArray = [];
  @observable tenant_logo = '';
  @observable tenant_name = '';

  constructor(props) {
    super(props);
    this.state = {
      isServiceTypeChecked: 0,
      isPaymentSelect: 0,
      isFrequencySelected: null
    };
  }

  @observable startCountRange = 0;
  @observable extra_service_total = 0;
  @observable pricing_parameter_total = 0;
  @observable pricing_range_total = 0;
  @observable grand_total = 0.0;
  @observable isServiceTypeChecked = 0;

  componentDidMount() {
    let {
      serviceTypeStore,
      questionGroupStore,
      serviceTypeCategoryStore,
      bookingStore: {
        booking: {
          object
        }
      }
    } = this.props;

    if (object && object.attributes) {
      this.grand_total = object.attributes.total;
    }
    serviceTypeCategoryStore.loadServiceTypeCategories();
    questionGroupStore.loadPublicQuestionGroups();
    //serviceTypeStore.loadPublicServiceTypesBooking();
    const currentTenant = Lockr.get('currentTenant');
    if (currentTenant) {
      this.tenant_logo = currentTenant.logo;
      this.tenant_name = currentTenant.name;
    }
    window.$crisp.push(['do', 'chat:hide']);
  }

  onAddressSelect(suggestion) {
    let {
      props: {
        bookingStore: {
          booking: {
            object
          }
        }
      }
    } = this;
    if (suggestion) {
      object.attributes.address = suggestion.label;
      suggestion.gmaps && suggestion.gmaps.address_components && suggestion.gmaps.address_components.length > 0 && suggestion.gmaps.address_components.map((address_component) => {
        if (_.includes(address_component.types, "locality")) {
          object.attributes.city = address_component.long_name;
        }

        if (_.includes(address_component.types, "administrative_area_level_1")) {
          object.attributes.state = address_component.long_name;
        }

        if (_.includes(address_component.types, "postal_code")) {
          object.attributes.zip = address_component.long_name;
        }
      })
    } else {
      object.attributes.address = '';
      object.attributes.zip = "";
      object.attributes.state = "";
      object.attributes.city = "";
    }
  }

  onAddressClear(value) {
    let {
      props: {
        bookingStore: {
          booking: {
            object
          }
        }
      }
    } = this;
    if (!value) {
      object.attributes.address = '';
    }
  }

  onExtraServiceSelect(event, selectedOptions, key) {
    let {
      props: {
        bookingStore: {
          booking: {
            object
          }
        }
      }
    } = this;

    let index = object.attributes.booking_service_type_extras.map(function (item) { return item.name; }).indexOf(selectedOptions.name);
    if (event.target.checked === true) {
      if (index === -1) {
        object.attributes.booking_service_type_extras.push({ service_type_extra_id: selectedOptions.id, name: selectedOptions.name, price: parseFloat(selectedOptions.price_cents / 100) });
      } else {
        object.attributes.booking_service_type_extras[index].price = parseFloat(selectedOptions.price_cents / 100);
        object.attributes.booking_service_type_extras[index]._destroy = 0;
      }
    } else {
      object.attributes.booking_service_type_extras[index].price = 0;
      object.attributes.booking_service_type_extras[index]._destroy = 1;
    }
    this.calculateGrandtotal()
  }

  onFrequencySelect(event, selectedOptions, key) {
    let {
      props: {
        bookingStore: {
          booking: {
            object
          }
        }
      }
    } = this;
    object.attributes.frequency_id = selectedOptions.id;
    this.setState({
      isFrequencySelected: key,
    });
  }

  onPricingRangeSelectImage(event,selectedOptions,key) {  
    let {
      props: {
        bookingStore: {
          booking: {
            object
          }
        }
      }
    } = this;

    if(event.target.checked){
      let index = object.attributes.pricing_parameter_ranges.map(function(item) { return item.pricing_parameter_id; }).indexOf(selectedOptions.pricing_parameter_id);
      if(index > -1){
        object.attributes.pricing_parameter_ranges[index].pricing_parameter_id = selectedOptions.pricing_parameter_id;
        object.attributes.pricing_parameter_ranges[index].name = selectedOptions.label;
        object.attributes.pricing_parameter_ranges[index].price = selectedOptions.price;
        object.attributes.pricing_parameter_ranges[index].total_amount =  selectedOptions.price;
      }else{
        object.attributes.pricing_parameter_ranges.push({pricing_parameter_id:selectedOptions.pricing_parameter_id,name:selectedOptions.label,price:selectedOptions.price,total_amount:selectedOptions.price});
      }
      this.calculateGrandtotal()
    }
  }

  onPricingRangeSelect(event, selectedOptions) {
    let {
      props: {
        bookingStore: {
          booking: {
            object
          }
        }
      }
    } = this;
    // if(event.target.value != ''){
    //   object.attributes.pricing_parameter_ranges = [];
    //   object.attributes.pricing_parameter_ranges.push({pricing_parameter_id:selectedOptions.id,name:event.target.options[event.target.selectedIndex].text,price:parseFloat(event.target.value)});
    // } else {
    //   object.attributes.pricing_parameter_ranges = [];
    // }
    // this.calculateGrandtotal()

    if (event.target.value != '') {
      let index = object.attributes.pricing_parameter_ranges.map(function (item) { return item.pricing_parameter_id; }).indexOf(selectedOptions.id);
      if (index > -1) {
        object.attributes.pricing_parameter_ranges[index].pricing_parameter_id = selectedOptions.id;
        object.attributes.pricing_parameter_ranges[index].name = event.target.options[event.target.selectedIndex].text;
        object.attributes.pricing_parameter_ranges[index].price = parseFloat(event.target.value);
        object.attributes.pricing_parameter_ranges[index].total_amount = parseFloat(event.target.selectedIndex) * parseFloat(event.target.value);

      } else {
        object.attributes.pricing_parameter_ranges.push({ pricing_parameter_id: selectedOptions.id, name: event.target.options[event.target.selectedIndex].text, price: parseFloat(event.target.value), total_amount: parseFloat(event.target.value) });

      }
      this.calculateGrandtotal()
    }
  }

  onPricingSelect(event, selectedOptions) {
    let {
      props: {
        bookingStore: {
          booking: {
            object
          }
        }
      }
    } = this;

    if (event.target.value != '') {
      object.attributes.pricing_parameters = [];
      object.attributes.pricing_parameters.push({ pricing_parameter_id: selectedOptions.id, name: event.target.options[event.target.selectedIndex].text, price: parseFloat((selectedOptions.price_cents / 100)) });
      // NOTE: here price came from backend in cents
      object.attributes.pricing_parameters[0].quantity = event.target.selectedIndex;
      object.attributes.pricing_parameters[0].total_amount = parseFloat(event.target.selectedIndex) * parseFloat((selectedOptions.price_cents / 100));
    } else {
      object.attributes.pricing_parameters = [];
    }
    this.calculateGrandtotal()
  }

  pricingList = (pricing) => {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    let pricingToReturn = [];
    this.pricingParameterArray = [];
    for (let i = pricing.range_start; i <= pricing.range_end; i++) {
      let label = `${pricing.name} ${i}`;
      let indexChecked = object.attributes.booking_pricing_parameters.map(function(item) { return item.name; }).indexOf(label);
      if(indexChecked > -1){
        pricingToReturn.push(<option selected={'selected'} id={pricing.id} key={i}>{pricing.name}{` ${i}`}</option>);
      }else{
        pricingToReturn.push(<option id={pricing.id} key={i}>{pricing.name}{` ${i}`}</option>);
      }
    }
    return pricingToReturn;
  };

  pricingRangeList = (pricing) => {
    let {
      props: {
        pricingParameterStore,
        pricingParameterStore: {
          pricingParameterDetailUpdate: {
            object
          }
        },
        bookingStore: {
          booking
        }
      }
    } = this;
    let {
      serviceTypeStore: {
        serviceTypeDetail
      }
    } = this.props;
    let pricingRangeToReturn = [];

    if (pricing.id) {
      let index = pricing.pricing_parameter_ranges.map(function (item) { return item.pricing_parameter_id; }).indexOf(pricing.id);
      if (index > -1) {
        pricing.pricing_parameter_ranges.map((range) => {
          let label = `${range.label}`;
          let indexChecked = booking.object.attributes.pricing_parameter_ranges.map(function(item) { return item.name; }).indexOf(label);
          if(indexChecked > -1){
            pricingRangeToReturn.push(<option selected={'selected'} innertext={range.label} value={range.price} key={Math.random()}>{range.label}</option>);
          }else{
            pricingRangeToReturn.push(<option innerText={range.label} value={range.price}>{range.label}</option>);
          }
          
        });
      }
    }
    return pricingRangeToReturn;
  };

  handleStartDateChange(date) {
    let { object } = this.props.bookingStore.booking;
    if (date) {
      object.attributes.start_date = moment(date);
    }
  }

  onPaymentSelect = (event, payment, key) => {
    let { object } = this.props.bookingStore.booking;
    this.setState({
      isPaymentSelect: key,
    });
    object.attributes.payment_method = payment.value;
  }

  onContactWayChange = (event) =>{
    let {
      bookingStore:{
        booking:{
          object: {
            attributes
          }
        }
      }
    } = this.props;
    attributes.start_project_expected_period = event.target.value;
  }

  onTimePeriodChange = (event) =>{
    let {
      bookingStore:{
        booking:{
          object: {
            attributes
          }
        }
      }
    } = this.props;
    attributes.contact_way = event.target.value;
  }

  onServiceTypeSelect = (event, service, key) => {
    let { object } = this.props.bookingStore.booking;
    let { serviceTypeStore, pricingParameterStore } = this.props;
    let {
      serviceTypeStore: {
        serviceTypeDetail
      }
    } = this.props;
    if (service.attributes.id !== "") {
      this.setState({
        isServiceTypeChecked: service.attributes.id
      });
      princingRangeCombineArray = [];
      object.attributes.extra_service_types = [];
      object.attributes.pricing_parameters = [];
      object.attributes.pricing_parameter_ranges = [];
      object.attributes.service_type_id = service.attributes.id;
      serviceTypeStore.loadServiceTypeDetail({ uuid: service.attributes.id })
        .then((response) => {
          serviceTypeDetail.object.attributes && serviceTypeDetail.object.attributes.pricing_parameters.length > 0 && serviceTypeDetail.object.attributes.pricing_parameters.map((pricing_attr) => {
            // pricingParameterStore.loadPricingParameterDetail({uuid:pricing_attr.id})
            // .then(response => {
            //   princingRangeCombineArray.push(response.data.data)
            // })
          });
          this.calculateGrandtotal()
        })
    } else {
      this.props.serviceTypeStore.clearServiceTypeDetail()
      this.calculateGrandtotal()
    }
  }

  onServiceTypeCategoryChange = (event) => {
    let { object } = this.props.bookingStore.booking;
    this.props.viewStore.serviceTypeSearch.where.service_type_category_id = event.target.value;
    this.props.serviceTypeStore.loadServiceTypes() 
    object.attributes.service_type_category_id = event.target.value;
  }

  onServiceTypeChange = (event) => {
    let { object } = this.props.bookingStore.booking;
    let { serviceTypeStore, pricingParameterStore } = this.props;
    let {
      serviceTypeStore: {
        serviceTypeDetail
      }
    } = this.props;
    if (event.target.value !== "") {
      this.setState({
        isServiceTypeChecked: event.target.value
      });
      princingRangeCombineArray = [];
      object.attributes.extra_service_types = [];
      object.attributes.pricing_parameters = [];
      object.attributes.pricing_parameter_ranges = [];
      object.attributes.service_type_id = event.target.value;
      serviceTypeStore.loadServiceTypeDetail({ uuid: event.target.value })
        .then((response) => {
          serviceTypeDetail.object.attributes && serviceTypeDetail.object.attributes.pricing_parameters.length > 0 && serviceTypeDetail.object.attributes.pricing_parameters.map((pricing_attr) => {
          });
          this.calculateGrandtotal()
        })
    } else {
      this.props.serviceTypeStore.clearServiceTypeDetail()
      this.calculateGrandtotal()
    }
  }

  calculateGrandtotal() {
    let {
      bookingStore: {
        booking: {
          object
        }
      },
      serviceTypeStore: {
        serviceTypeDetail
      }
    } = this.props;

    this.grand_total = 0.0;
    
    // Adding Service type price in grand total
    if (serviceTypeDetail && serviceTypeDetail.object && serviceTypeDetail.object.attributes) {
      this.grand_total += serviceTypeDetail.object.attributes.rate;
    }

    // Adding pricing parameters flat to grand total
    if (object && object.attributes.pricing_parameters && object.attributes.pricing_parameters.length > 0) {
      this.grand_total += _.sumBy(object.attributes.pricing_parameters, 'total_amount')
    }

    // Adding pricing parameters range to grand total
    if (object && object.attributes.pricing_parameter_ranges && object.attributes.pricing_parameter_ranges.length > 0) {
      this.grand_total += _.sumBy(object.attributes.pricing_parameter_ranges, 'price')
    }

    // Adding extra service types to grand total
    if (object && object.attributes.extra_service_types && object.attributes.extra_service_types.length > 0) {
      this.grand_total += _.sumBy(object.extra_service_types, 'price')
    }
  }

   createBooking(status) {
    let {
      props: {
        store,
        frequencyStore:{
          frequencies
        },
        serviceTypeStore: {
          serviceType:{
            objects,
          }
        },
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
   
    
    if(object.attributes.first_name === "" || object.attributes.first_name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid first name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.last_name === "" || object.attributes.last_name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid last name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.email === "" || object.attributes.email === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid email!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.phone === "" || object.attributes.phone === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid phone!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.address === "" || object.attributes.address === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid address!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.city === "" || object.attributes.city === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid city name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.state === "" || object.attributes.state === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid state name!', intent: Intent.DANGER })
      return;
    }
    if(object.attributes.zip === "" || object.attributes.zip === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid zip code!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.service_type_category_id === "" || object.attributes.service_type_category_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please select service type category!', intent: Intent.DANGER })
      return;
    }
    
    if(object.attributes.service_type_id === "" || object.attributes.service_type_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please select service type!', intent: Intent.DANGER })
      return;
    }
    
    // if(object.attributes.frequency_id === "" || object.attributes.frequency_id === 0) {
    //   Toast.show({ icon: 'warning-sign', message: 'Please select frequency!', intent: Intent.DANGER })
    //   return;
    // }

    if(object.attributes.payment_method === "" || object.attributes.payment_method === null) {
      object.attributes.payment_method = 'Cash';
    }

    object.attributes.total = this.grand_total;
     if (object.attributes.pricing_parameter_ranges != "" || object.attributes.pricing_parameter_ranges != null) { object.attributes.booking_pricing_parameters_attributes = object.attributes.pricing_parameters.concat(object.attributes.pricing_parameter_ranges);}
     else {
       object.attributes.booking_pricing_parameters_attributes = object.attributes.pricing_parameters;
     }   
    object.attributes.booking_service_type_extras_attributes = object.attributes.extra_service_types;
    object.attributes.status = status;
    object.attributes.booking_questions_attributes = object.attributes.booking_questions;

    this.props.bookingStore
      .createBooking()
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Booking created successfully!",
          intent: Intent.SUCCESS
        });
        this.props.onClose();

      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to create Booking!",
          intent: Intent.DANGER
        });
      });

  }

  onQuestionChange = (event, question) => {
    let {
      props: {
        bookingStore: {
          booking: {
            object
          }
        }
      }
    } = this;
    let index = object.attributes.booking_questions_attributes.map(function (item) { return item.question_id; }).indexOf(question.id);
    if (index > -1) {
      object.attributes.booking_questions_attributes[index].answer = event.target.value;
    } else {
      object.attributes.booking_questions_attributes.push({ answer: event.target.value, question_id: question.id });
    }
  }

  onChangeFile(event) {
    let {
      bookingStore: {
        booking: {
          object: { attributes }
        }
      }
    } = this.props;
    var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 10) {
      Toast.show({ icon: 'warning-sign', message: 'File size exceeds 10 MB!', intent: Intent.DANGER })
      return
    }
    this.props.bookingStore.uploadBookingAttachment(event.target.files[0])
      .then(this.onSuccessfullUploadAttachment.bind(this))
      .catch(this.onFailUploadAttachment.bind(this))
  }

  onSuccessfullUploadAttachment(response) {
    let {
      bookingStore: {
        booking: {
          object
        }
      }
    } = this.props;
    object.attributes.booking_attachment_ids.push(response.data.data.attributes.id);
    object.attributes.booking_attachments.push(response.data.data.attributes);
  }

  onFailUploadAttachment(error) {
    this.props.bookingStore.showUploadAttachmentLoader = false;
    if (error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong, failed to upload file!', intent: Intent.DANGER })
    }
  }

  removeAttachment(attachment) {
    let {
      bookingStore: {
        booking: {
          object
        }
      }
    } = this.props;
    var removeIndex = object.attributes.booking_attachments.map(function (item) { return item.id; }).indexOf(attachment.id);
    if (removeIndex > -1) {
      object.attributes.booking_attachments.splice(removeIndex, 1);
      object.attributes.booking_attachment_ids.splice(removeIndex, 1);
    }
  }

  validate(bookingStep) {
    let {
      props: {
        bookingStore,
        bookingStore: {
          booking,
          booking: {
            object
          }
        }
      }
    } = this;
    if (bookingStep === 3) {
      if (object.attributes.first_name === "" || object.attributes.first_name === null) {
        Toast.show({ icon: 'warning-sign', message: 'Please enter valid first name!', intent: Intent.DANGER })
        return false;
      }
      if (object.attributes.last_name === "" || object.attributes.last_name === null) {
        Toast.show({ icon: 'warning-sign', message: 'Please enter valid last name!', intent: Intent.DANGER })
        return false;
      }
      if (object.attributes.email === "" || object.attributes.email === null) {
        Toast.show({ icon: 'warning-sign', message: 'Please enter valid email!', intent: Intent.DANGER })
        return false;
      }
      if (object.attributes.phone === "" || object.attributes.phone === null) {
        Toast.show({ icon: 'warning-sign', message: 'Please enter valid phone!', intent: Intent.DANGER })
        return false;
      }
      return true;
    }
    if (bookingStep === 4) {
      if (object.attributes.address === "" || object.attributes.address === null) {
        Toast.show({ icon: 'warning-sign', message: 'Please enter valid address!', intent: Intent.DANGER })
        return false;
      }
      if (object.attributes.city === "" || object.attributes.city === null) {
        Toast.show({ icon: 'warning-sign', message: 'Please enter valid city name!', intent: Intent.DANGER })
        return false;
      }
      if (object.attributes.state === "" || object.attributes.state === null) {
        Toast.show({ icon: 'warning-sign', message: 'Please enter valid state name!', intent: Intent.DANGER })
        return false;
      }
      if (object.attributes.zip === "" || object.attributes.zip === null) {
        Toast.show({ icon: 'warning-sign', message: 'Please enter valid zip code!', intent: Intent.DANGER })
        return false;
      }
      return true;
    }
    if (bookingStep === 1) {
      if(object.attributes.service_type_category_id === "" || object.attributes.service_type_category_id === null) {
        Toast.show({ icon: 'warning-sign', message: 'Please select service type category!', intent: Intent.DANGER })
        return;
      }
      if (object.attributes.service_type_id === "" || object.attributes.service_type_id === null) {
        Toast.show({ icon: 'warning-sign', message: 'Please select service type!', intent: Intent.DANGER })
        return false;
      }
      return true;
    }
    if (bookingStep === 5) {
      // if (object.attributes.frequency_id === "" || object.attributes.frequency_id === 0) {
      //   Toast.show({ icon: 'warning-sign', message: 'Please select frequency!', intent: Intent.DANGER })
      //   return false;
      // }
      return true;
    }
    if (bookingStep === 6) {
      // if (object.attributes.payment_method === "" || object.attributes.payment_method === null) {
      //   Toast.show({ icon: 'warning-sign', message: 'Please select payment method!', intent: Intent.DANGER })
      //   return false;
      // }
      return true;
    }
    if (bookingStep === 2) {
      return true;
    }

  }

  navigateToNextScreen() {
    let {
      props: {
        bookingStore,
        bookingStore: {
          bookingStep,
          booking,
          booking: {
            object
          }
        }
      }
    } = this;
    let result = this.validate(bookingStore.bookingStep);
    if (bookingStep < 7 && result === true) {
      bookingStore.bookingStep1 = false;
      bookingStore.bookingStep2 = false;
      bookingStore.bookingStep3 = false;
      bookingStore.bookingStep4 = false;
      bookingStore.bookingStep5 = false;
      bookingStore.bookingStep6 = false;

      bookingStore.bookingStep = bookingStep + 1;

      switch (bookingStore.bookingStep) {
        case 1:
          bookingStore.bookingStep1 = true;
          break;
        case 2:
          bookingStore.bookingStep2 = true;
          break;
        case 3:
          bookingStore.bookingStep3 = true;
          break;
        case 4:
          bookingStore.bookingStep4 = true;
          break;
        case 5:
          bookingStore.bookingStep5 = true;
          break;
        case 6:
          bookingStore.bookingStep6 = true;
          break;
        default:
        // code block
      }
    }
  }

  navigateToPreviousScreen() {
    let {
      bookingStore,
      bookingStore: {
        bookingStep
      }
    } = this.props;
    if (bookingStep > 1) {
      bookingStore.bookingStep = bookingStep - 1;

      bookingStore.bookingStep1 = false;
      bookingStore.bookingStep2 = false;
      bookingStore.bookingStep3 = false;
      bookingStore.bookingStep4 = false;
      bookingStore.bookingStep5 = false;
      bookingStore.bookingStep6 = false;
      switch (bookingStore.bookingStep) {
        case 1:
          bookingStore.bookingStep1 = true
          break;
        case 2:
          bookingStore.bookingStep2 = true
          break;
        case 3:
          bookingStore.bookingStep3 = true
          break;
        case 4:
          bookingStore.bookingStep4 = true
          break;
        case 5:
          bookingStore.bookingStep5 = true
          break;
        case 6:
          bookingStore.bookingStep6 = true
          break;
        default:
        // code block
      }
    }
  }

  handleCloseCopyDailog() {
    this.props.onClose();
  }

  handleSuccessCopyDailog() {
    this.props.onSuccess();
  }

  render() {
    let {
      store,
      serviceTypeStore: {
        serviceType,
        serviceType: {
          objects
        },
        serviceTypeDetail,
      },
      bookingStore: {
        bookingStep,
        bookingStep1,
        bookingStep2,
        bookingStep3,
        bookingStep4,
        bookingStep5,
        bookingStep6,
        showUploadAttachmentLoader,
        tenant,
        tenant: {
          object
        },
        booking:{
          object: {
            attributes
          },
          isLoading
        }
      },
      questionGroupStore: {
        groups
      },
      serviceTypeCategoryStore:{
        serviceTypeCategories
      }
    } = this.props;

    let service_type_extra = [];
    let pricing_parameters = [];
    let frequency = [];
    let questions = [];

    if (serviceTypeDetail && serviceTypeDetail.object && serviceTypeDetail.object.attributes) {
      service_type_extra = serviceTypeDetail.object.attributes.service_type_extra
      pricing_parameters = serviceTypeDetail.object.attributes.pricing_parameters
      frequency = serviceTypeDetail.object.attributes.frequency
      questions = serviceTypeDetail.object.attributes.questions;
    }


    return (
      <Dialog        
        className="booking-modal-wrap"
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
        onClose={this.handleCloseCopyDailog.bind(this)}
        style={{ width: '80%'}}
      >
        <div class="bp3-dialog-header" style={{minHeight:100, position:'relative'}}>
          <div className="booking-modal-branding">
            <div className="booking-modal-brand"><img src={this.tenant_logo} alt='Company logo' /></div>
            <h5 className="bp3-heading">{this.tenant_name}</h5>
          </div>
          <button aria-label="Close" class="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross" style={{ position: 'absolute', top: 10, right: 10 }} onClick={() => this.handleCloseCopyDailog(this)}></button>
        </div>
        <div className={Classes.DIALOG_BODY} style={{ margin: '0px' }}>          
          <div className="booking-modal">
            <div className="booking-modal-header">
              <div className="booking-modal-breadcrumb">
                {/* <Breadcrumbs 
                  currentBreadcrumbRenderer={this.renderCurrentBreadcrumb}
                  items={BREADCRUMBSS}
                  minVisibleItems={6}
                /> */}
                <ul>
                  <li className={bookingStep1 === true ? 'active' : ''}>Service</li>
                  <li className={bookingStep2 === true ? 'active' : ''}>Details</li>
                  <li className={bookingStep3 === true ? 'active' : ''}>Your Info</li>
                  <li className={bookingStep4 === true ? 'active' : ''}>Address</li>
                  <li className={bookingStep5 === true ? 'active' : ''}>Schedule</li>
                  <li className={bookingStep6 === true ? 'active' : ''}>Finish</li>
                </ul>
              </div>
            </div>
            <div className="booking-modal-body">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="booking-form-wrap">
                    {
                      bookingStep1 === true ?
                        <div className="step-one">
                            <div className="form-block">
                              <div className="row align-items-center">
                                {
                                  serviceTypeCategories.objects &&
                                  <div className="col-xs-12 col-md-6" >
                                    <FormGroup label={'Service Type Category'} labelFor="text-input" labelInfo={"*"}>
                                      <select 
                                        className='form-control'
                                        onChange={this.onServiceTypeCategoryChange.bind(this)}
                                        value={attributes.service_type_category_id}
                                      >
                                        <option value="">Select category</option>
                                        { serviceTypeCategories.objects.map((category) => {
                                            return(
                                              <option value={category.attributes.id} key={category.attributes.id}>{category.attributes.name}</option>
                                            )
                                          })
                                        }
                                      </select>
                                    </FormGroup>
                                  </div>
                                }
                                <div className="col-xs-12 col-md-6" >
                                  { (serviceTypeCategories.objects.length === 0 || serviceType.isLoading) ?
                                    <Spinner size='25' />
                                    :
                                    ""
                                  }
                                </div>
                              </div>
                            </div>
                          
                          <div className="form-block">
                            <div className="block-instruction"></div>
                            <div className="form-fields fields-style">
                              {attributes && attributes.service_type_category_id != '' && objects && objects.map((service, key) => {
                                if (service.attributes.display_on_booking_form) {
                                  return (
                                    <div className="field-type5">
                                      <div
                                        className={this.isServiceTypeChecked == service.attributes.id ? 'services-category service-card-active' : 'services-category'}
                                      >
                                        <div className="service-card">
                                          <div className="service-checkbox">
                                            <Radio label="" type="radio" name="service_type_id"
                                              onChange={(event) => { this.onServiceTypeSelect(event, service, key) }}
                                              checked={service.id === attributes.service_type_id ? true: false}
                                            />
                                          </div>
                                          <div className="service-icon">
                                            <img style={{ width: '40px' }} src={service.attributes.logo} />
                                          </div>
                                        </div>
                                        <div className="service-label">{service.attributes.name}</div>
                                      </div>
                                    </div>
                                  )
                                }
                              })
                              }
                            </div>
                          </div>
                        </div>
                        : ''
                    }
                    {
                      bookingStep2 === true ?

                        <div className="form-fields mt-4">
                          <div className="col col-xs-12 col-md-12">
                            {pricing_parameters && pricing_parameters.length > 0 && <div className="">

                              <div className="row">
                                {
                                  pricing_parameters && pricing_parameters.length > 0 && pricing_parameters.map((pricing, pricingKey) => {
                                    if (pricing.pricing_type === 'flat') {
                                      return (
                                        <div className="col-md-6">
                                          <FormGroup label={"Pricing"} labelFor="text-input">
                                            <select
                                              className='form-control first-latter-capital'
                                              placeholder={`Select ${pricing.name}`}
                                              onChange={(event) => { this.onPricingSelect(event, pricing) }}
                                            >
                                              <option value="">Select any one</option>
                                              {this.pricingList(pricing)}
                                            </select>
                                          </FormGroup>
                                        </div>
                                      )
                                    }
                                  })
                                }
                              </div>
                              <div className="row">
                                {
                                  pricing_parameters && pricing_parameters.length > 0 && pricing_parameters.map((pricing) => {
                                    if (pricing.pricing_type === 'range') {
                                      var index = pricing.pricing_parameter_ranges.length === _.filter(pricing.pricing_parameter_ranges, function(obj) { return obj.image_url }).length
                                      return (
                                        <div className="col-md-6">
                                          <FormGroup
                                            label={pricing.name}
                                            labelFor="text-input"
                                            labelInfo={""}
                                            className="first-latter-capital"
                                          >
                                            {
                                              index === true ?
                                              <div className="d-flex flex-wrap mt-3">
                                                {pricing.pricing_parameter_ranges.map((pricing_range,key) => {
                                                  let indexChecked = attributes && attributes.pricing_parameter_ranges.map(function(item) { return item.name; }).indexOf(pricing_range.label);
                                                  var checked_id ='';
                                                  if(indexChecked > -1){
                                                    checked_id = pricing_range.id;
                                                  }
                                                  return (
                                                    <div className="imgwrap">
                                                      <label className="font-weight-bold">{pricing_range.label}</label>
                                                      <div className="imgbox">
                                                      <Radio type="radio" name="checkboxgroup" 
                                                        checked={checked_id === pricing_range.id ? true: false}
                                                        onChange={(event) => {this.onPricingRangeSelectImage(event,pricing_range,key)} } 
                                                      />
                                                        <img width="150" height="150" src={pricing_range.image_url}  />
                                                      </div>
                                                    </div>
                                                  )
                                                })}    
                                              </div>:

                                              <select
                                                className='form-control'
                                                placeholder={`Select ${pricing.name}`}
                                                onChange={(event) => { this.onPricingRangeSelect(event, pricing) }}
                                                >
                                                <option value="">Select any one</option>
                                                {this.pricingRangeList(pricing)}
                                              </select>

                                            }
                                            
                                          </FormGroup>
                                        </div>
                                      )
                                    }
                                  })
                                }
                              </div>
                            </div>}
                          </div>
                        </div>

                      : ''
                    }
                    {
                      bookingStep3 === true ?
                        <div className="setp-two">
                          <div className="form-block">
                            {/* <div className="form-block-title">Step 1: Who Are You?</div>
                        <div className="block-instruction">Please give us your contact information.</div> */}

                            <div className="form-fields">
                              <div className="field-type1">
                                <FormGroup
                                  label={"First Name"}
                                  labelFor="text-input"
                                  labelInfo={"*"}
                                >
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='First name'
                                    defaultValue={attributes.first_name}
                                    onChange={(event) => { attributes.first_name = event.target.value; }}
                                  />
                                </FormGroup>
                              </div>
                              <div className="field-type1">
                                <FormGroup
                                  label={"Last Name"}
                                  labelFor="text-input"
                                  labelInfo={"*"}
                                >
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Last name'
                                    defaultValue={attributes.last_name}
                                    onChange={(event) => { attributes.last_name = event.target.value; }}
                                  />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="form-fields">
                              <div className="field-type1">
                                <FormGroup
                                  label={"Email Address"}
                                  labelFor="text-input"
                                  labelInfo={"*"}
                                >
                                  <input
                                    type='email'
                                    className='form-control'
                                    placeholder='mail@domain.com'
                                    defaultValue={attributes.email}
                                    onChange={(event) => { attributes.email = event.target.value; }}
                                  />
                                </FormGroup>
                              </div>
                              <div className="field-type1">
                                <FormGroup
                                  label={"Phone Number"}
                                  labelFor="text-input"
                                  labelInfo={"*"}
                                >
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Ex. 9876543210'
                                    defaultValue={attributes.phone}
                                    onChange={(event) => { attributes.phone = event.target.value; }}
                                  />
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                        : ''
                    }
                    {
                      bookingStep4 === true ?
                        <div className="setp-three">
                          <div className="form-block">
                            {/* <div className="form-block-title">Step 2: Your Address</div>
                        <div className="block-instruction">Please give us your contact information.</div> */}

                            <div className="form-fields">
                              <div className="field-type2">
                                <FormGroup
                                  label={"Address"}
                                  labelFor="text-input"
                                  labelInfo={"*"}
                                >
                                  <GeoComplete
                                    className='geo-control'
                                    placeholder="Search Address"
                                    initialValue={attributes.address}
                                    onSuggestSelect={this.onAddressSelect.bind(this)}
                                    onChange={this.onAddressClear.bind(this)}
                                  />
                                </FormGroup>
                              </div>
                              <div className="field-type3">
                                <FormGroup

                                  label={"Address 2"}
                                  labelFor="text-input"
                                >
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Address 2'
                                    defaultValue={attributes.suite}
                                    onChange={(event) => { attributes.suite = event.target.value; }}
                                  />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="form-fields">
                              <div className="field-type4">
                                <FormGroup
                                  label={"City"}
                                  labelFor="text-input"
                                  labelInfo={"*"}
                                >
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='City'
                                    defaultValue={attributes.city}
                                    onChange={(event) => { attributes.city = event.target.value; }}
                                  />
                                </FormGroup>
                              </div>
                              <div className="field-type3">
                                <FormGroup
                                  label={"State/Province"}
                                  labelFor="text-input"
                                  labelInfo={"*"}
                                >
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='State/Province'
                                    defaultValue={attributes.state}
                                    onChange={(event) => { attributes.state = event.target.value; }}
                                  />

                                </FormGroup>
                              </div>
                              <div className="field-type3">
                                <FormGroup
                                  label={"Zip code/Postal Code"}
                                  labelFor="text-input"
                                  labelInfo={"*"}
                                >
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Ex. 0377'
                                    defaultValue={attributes.zip}
                                    onChange={(event) => { attributes.zip = event.target.value; }}
                                  />
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                        : ''
                    }
                    {
                      bookingStep5 === true ?
                        <div className="setp-four">
                          <div>
                            <div className="form-block">
                              <div className="row">
                                <div className="col col-xs-12 col-md-6">
                                  <div className="form-block-title">
                                    How soon would you like to start this project?
                                  </div>
                                <FormGroup  labelFor="text-input">
                                  <select 
                                    className='form-control' 
                                    placeholder={`Select `}
                                    onChange={this.onTimePeriodChange.bind(this)} 
                                    defaultValue={attributes && attributes.start_project_expected_period}
                                  >
                                    <option value="">Select any one</option>
                                    {object && object.attributes && object.attributes.start_project_expected_period && object.attributes.start_project_expected_period.split(/\r?\n/).map((period) => { 
                                      var selected = '';
                                      if(attributes.start_project_expected_period === period.split(":")[1]){
                                        selected = 'selected'
                                      } 
                                      return(
                                        <option selected value={period.split(":")[1]}>{period.split(":")[0]}</option>
                                      )})}
                                  </select>
                                </FormGroup>
                                </div>
                                <div className="col col-xs-12 col-md-6">
                                  <div className="form-block-title">
                                    What is the best way for us to contact you?
                                  </div>
                                <FormGroup  labelFor="text-input">
                                  <select 
                                    className='form-control' 
                                    placeholder={`Select `}
                                    onChange={this.onContactWayChange.bind(this)} 
                                    defaultValue={attributes && attributes.contact_way}
                                  >
                                    <option value="">Select any one</option>
                                    {object && object.attributes && object.attributes.contact_way && object.attributes.contact_way.split(/\r?\n/).map((way) => { 
                                       var selected = '';
                                       if(attributes.contact_way === way.split(":")[1]){
                                         selected = 'selected'
                                       }                             
                                      return(
                                        <option selected value={way.split(":")[1]}>{way.split(":")[0]}</option>
                                      )})}
                                  </select>
                                </FormGroup>
                                </div>
                              </div>
                            </div>
                            {object && object.attributes && object.attributes.enable_visit_us === true &&
                              <div className="form-block">
                                <div className="form-block-title">When would you like us to visit?</div>
                                <div className="block-instruction"></div>
                                <div className="row">
                                  <div className="col col-xs-12 col-md-6 booking_form_date">
                                    <FormGroup
                                      label={"Choose a date"}
                                      labelFor="text-input"
                                      labelInfo={"*"}
                                    >
                                      <BookingDateTimeInput
                                        placeholder={'Date'}
                                        onChange={this.handleStartDateChange.bind(this)}
                                      />
                                    </FormGroup>
                                  </div>
                                  <div className="col col-xs-12 col-md-6">

                                  </div>
                                </div>
                              </div>
                            }
                            {object && object.attributes && object.attributes.enable_often_visit_us === true &&
                              <div>
                              {frequency && frequency.length > 0 && <div className="form-block">
                                <div className="form-block-title">- How often you want us to visit?</div>
                                <div className="block-instruction"></div>
                                <div className="form-fields fields-style">
                                  {
                                    frequency.map((freqncy, frequencyKey) => {
                                      return (
                                        <div className="field-type6">
                                          <div onClick={(event) => { this.onFrequencySelect(event, freqncy, frequencyKey) }} className={`next-visit ${frequencyKey == this.state.isFrequencySelected ? 'next-visit-active' : ''}`}>{freqncy.title}</div>
                                        </div>
                                      )

                                    })
                                  }
                                </div>
                              </div>}
                            </div>
                            }
                          </div>
                        </div>
                        : ''
                    }
                    {
                      bookingStep6 === true ?
                        <div className="setp-five">
                          <div>
                            {object && object.attributes && object.attributes.payment_method === true &&
                              <div className="form-block">
                                <div className="form-fields fields-style">
                                  {
                                    items.map((payment, paymentKey) => {
                                      return (
                                        <div className="field-type6" key={Math.random()}>
                                          <div onClick={(event) => { this.onPaymentSelect(event, payment, paymentKey) }} className={`next-visit ${paymentKey === this.state.isPaymentSelect ? 'next-visit-active' : ''}`}>{payment.label}</div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            }
                            <div className="form-block">
                              <div className="upload-btn-wrapper">
                                <label htmlFor="file-upload" className="custom-file-upload">
                                  <img src="/upload-file-icon.svg" />
                                  <span style={{ marginRight: 10 }}>Upload a file </span>(Optional) (PNG, JPG, GIF, PDF, DOC, DOCX, XLS, XLSX)
                          {showUploadAttachmentLoader &&
                                    <Spinner size={Spinner.SIZE_SMALL} />}
                                </label>
                                <input id="file-upload" type="file" multiple={true} onChange={this.onChangeFile.bind(this)} />
                              </div>
                              <div className="card rounded-0 table-responsive">
                                {attributes && attributes.booking_attachments.length > 0 && <table className="table table-vertical-align table-data-presentation table-hover" style={{ width: '100%' }}>
                                  <thead>
                                    <tr>
                                      <th>File Name</th>
                                      <th>Content Type</th>
                                      <th width="10%">File Size</th>
                                      <th className="item-center" width="10%">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {attributes.booking_attachments.map((attachment) => {
                                      return (
                                        <tr>
                                          <td><a href={attachment.asset.url} target="_blank" download>{attachment.asset.url.split("/").pop()}</a></td>
                                          <td>{attachment.asset_content_type}</td>
                                          <td>{attachment.asset_human_file_size}</td>
                                          <td>
                                            <div className="action-buttons" minimal={'true'}>
                                              <Button minimal={true} small={true} onClick={() => this.removeAttachment(attachment)}>
                                                <img src="/delete-light-icon.svg" />
                                              </Button>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>}
                              </div>
                            </div>
                          </div>
                          <div className="form-block">
                            <div className="form-block-title">Add Your Note (Optional)</div>
                            <div className="block-instruction"></div>
                            <div className="form-fields">
                              <div className="field-type0">
                                <FormGroup>
                                  <textarea
                                    type='text'
                                    className='form-control'
                                    placeholder='Type here...'
                                    defaultValue={attributes.note}
                                    onChange={(event) => { attributes.note = event.target.value; }}
                                  />
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                        : ''
                    }
                    {/* {
                      bookingStep7 === true ?
                        <div className="setp-six">

                        </div>
                        : ''
                    } */}
                    <div className="continue-btn-wrap">
                      <div>
                        <Button
                          disabled={bookingStep && bookingStep === 1 ? true : false}
                          onClick={this.navigateToPreviousScreen.bind(this)}
                          style={{ display: `${bookingStep && bookingStep === 1 ? 'none' : 'block'}` }}
                          icon="arrow-left"
                        >
                          Previous
                          </Button>
                      </div>
                      <div>
                        <Button
                          onClick={this.navigateToNextScreen.bind(this)}
                          text="Continue"
                          disabled={bookingStep && bookingStep === 6 ? true : false}
                          style={{ display: `${bookingStep && bookingStep === 6 ? 'none' : 'block'}` }}
                          rightIcon="arrow-right"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="booking-order-wrap">
                    <h4 className="bp3-heading" style={{ backgroundColor: object && object.attributes && object.attributes.booking_form_color, color:object && object.attributes && object.attributes.booking_form_bgcolor}}>Your Order</h4>
                    <div className="summary-column">
                      {/* <div className="summary-header">Booking Summary</div> */}
                      <div className="summary-data">
                        <div className="scrollbar-right-side">
                          <div className="summary-field">
                            <div className="summary-icon"><img src="/date-time.svg" /></div>
                            <div className="summary-description">
                              <div className="headline"></div>
                              <div className="description">{moment(attributes.start_date).format('MMMM Do YYYY, h:mm:ss a')}</div>
                            </div>
                          </div>
                          {attributes && attributes.address && <div className="summary-field">
                            <div className="summary-icon"><img src="/map-pin-icon.svg" /></div>
                            <div className="summary-description">
                              <div className="headline">{attributes && attributes.first_name} {attributes && attributes.last_name}</div>
                              <div className="description">
                                <div className="content">{attributes && attributes.address}{attributes && attributes.suite ? ',' : ''} {attributes && attributes.suite} {attributes && attributes.state}{attributes && attributes.zip ? ',' : ''} {attributes && attributes.zip}</div>
                              </div>
                            </div>
                          </div>}
                          {serviceTypeDetail && serviceTypeDetail.object && serviceTypeDetail.object.attributes && serviceTypeDetail.object.attributes && <div className="summary-field">
                            <div className="summary-icon"><img src="/note-pad-icon.svg" /></div>
                            <div className="summary-description">
                              <div className="headline">Services</div>
                              <div className="description">
                                <div className="content">
                                  {serviceTypeDetail.object.attributes.name}
                                  <div>
                                    {`1 x ${currencyFormattedAmount(parseFloat(serviceTypeDetail.object.attributes.rate))}`}
                                  </div>
                                </div>
                                <div className="price">
                                  {currencyFormattedAmount(serviceTypeDetail.object.attributes.rate)}
                                </div>
                              </div>
                            </div>
                          </div>}
                          {/* {attributes && attributes.pricing_parameters && attributes.pricing_parameters.length > 0 && */}
                          <div className="summary-field">
                            <div className="summary-icon"><img src="/note-pad-icon.svg" /></div>
                            <div className="summary-description">
                              <div className="headline">{object && object.attributes && object.attributes.pricing_title != '' ? object && object.attributes && object.attributes.pricing_title:'Pricing'}</div>
                              {attributes && attributes.pricing_parameters && attributes.pricing_parameters.map((parameter) => {
                                return (
                                  <div className="description">
                                    <div className="content">
                                      {parameter.name} x {parameter.quantity}
                                      <div></div>
                                    </div>
                                    <div className="price">{currencyFormattedAmount((parameter.total_amount))}</div>
                                  </div>
                                )
                              })
                              }
                              {
                                attributes && attributes.pricing_parameter_ranges && attributes.pricing_parameter_ranges.map((parameter_range) => {
                                  return (
                                    <div className="description">
                                      <div className="content">
                                        {parameter_range.name}
                                        <div></div>
                                      </div>
                                      <div className="price">{currencyFormattedAmount(parameter_range.price)}</div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                          {/* } */}
                          {service_type_extra && service_type_extra.length > 0 &&
                            <div className="summary-field">
                              <div className="summary-icon"><img src="/note-pad-icon.svg" /></div>
                              <div className="summary-description">
                                <div className="headline">Add ons</div>
                                {
                                  attributes && attributes.extra_service_types && attributes.extra_service_types.map((ext_service) => {
                                    return (
                                      <div className="description">
                                        <div className="content">
                                          {ext_service.name}
                                          <div></div>
                                        </div>
                                        <div className="price">{currencyFormattedAmount(ext_service.price)}</div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          }
                        </div>
                        <div className="summary-total">
                          <div className="total-label">Total (approximate)</div>
                          <div className="price">
                            {
                              currencyFormattedAmount(this.grand_total)
                            }
                          </div>
                        </div>
                        <div className="confirm-btn-wrap">
                          <Button
                            onClick={() => this.createBooking('draft')}
                            className={attributes && attributes.status === 'confirmed' ? "save-draft-btn disable-save-draft-btn" : "save-draft-btn"}
                            loading={isLoading}
                          >
                            Save
                        </Button>
                          <Button
                            onClick={() => this.createBooking('confirmed')}
                            className="confirm-btn"
                            loading={isLoading}
                            style={{ backgroundColor: object && object.attributes && object.attributes.booking_form_color, borderColor: object && object.attributes && object.attributes.booking_form_color }}
                          >
                            {object && object.attributes && object.attributes.cta_title != '' ? object.attributes.cta_title :`Confirm Booking`}
                        </Button>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER} style={{backgroundColor:'#f9f9f9', textAlign:'center', fontSize:10, margin:0, paddingTop:5, paddingBottom:5}}>
          Powered by <span>Field Nexus</span>
        </div>
      </Dialog>
    )
  }
}

export default BookingModal;