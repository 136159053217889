import React, { Component } from 'react'
import { 
  Button
} from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import ClientAutocomplete from 'components/ClientAutocomplete';

@inject('store', 'userStore', 'viewStore') @observer
class ServiceAgreement extends Component {

  @observable showServiceAgreementModal = false;

  handleCloseServiceAgreementModal() {
    this.showServiceAgreementModal = false;
  }

  onSuccessServiceAgreementModal() {
    this.showServiceAgreementModal = false;
  }

  @action
  onClientSelect(client) {
    let {
      props: {
        viewStore: {
          serviceAgreementSearch,
        }
      }
    } = this;
    if (client) {
      this.props.viewStore.serviceAgreementSearch = {
        ...serviceAgreementSearch,
        where: {
          ...serviceAgreementSearch.where,
          client_id: client.id
        }
      }
    } else {
      this.props.viewStore.serviceAgreementSearch = {
        ...serviceAgreementSearch,
        where: {
          client_id: "",
        }
      }
    }
  }

  @action
  onTermChange(e) {
    let {
      props: {
        viewStore: {
          serviceAgreementSearch
        }
      }
    } = this;
    this.props.viewStore.serviceAgreementSearch = {
      ...serviceAgreementSearch,
      term: e.target.value
    }
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.search()
    }
  }

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.serviceAgreementSearch.page = 1;
    viewStore.serviceAgreements();
  }

  render() {
    const {
      term,
      where
    } = this.props.viewStore.serviceAgreementSearch;
    return(
      <nav className="bp3-navbar search">
        <div> 
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <ClientAutocomplete
                  selectedClientId={where && where.client_id}
                  onClientSelect={this.onClientSelect.bind(this)}
                />

                <input 
                  type="text"
                  className="bp3-input"
                  placeholder="Title, Reason for call"
                  onChange={this.onTermChange.bind(this)}
                  defaultValue={term}
                  onKeyPress={this.onEnter.bind(this)}
                />

                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
              </div>
            </div>
          </div>
          {/* <div className="bp3-navbar-group bp3-align-right">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <Button icon='plus' text='Add' onClick={() => {this.showServiceAgreementModal = true;}} />
              </div>
            </div>
          </div> */}
        </div>
      </nav>
    )
  }

};

export default ServiceAgreement; 