import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Intent, Button, Alert, TextArea } from '@blueprintjs/core';
import AddEquipmentForWorkReportModal from 'components/Equipment/AddEquipmentForWorkReportModal';
import Toast from 'helpers/toaster';
import _ from 'lodash';
import { concat } from 'lodash';


@inject('store', 'viewStore', 'workReportStore', 'equipmentStore') @observer
class WorkReportEquipments extends Component {
  @observable isOpenAddEquipmentForWorkReportDialog = false;
  @observable isSelectDialogVisible = false;
  @observable isSelectId;
  @observable equipmentChangeTitleArray = [];
  @observable equipmentChangeDescriptionArray = [];


  handleSelectCancel() {
    this.isSelectDialogVisible = false;
  }

  handleSelectConfirm(id) {
    this.isSelectDialogVisible = true;
    this.isSelectId = id;
  }

  handleSaveConfirm() {
    const {
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
    let id = this.isSelectId;
    this.isSelectDialogVisible = false;
    this.props.equipmentStore.deleteServicedEquipment(id, object.id)
      .then(this.onSuccessfulDeleteEquipment.bind(this))
      .catch(this.onFailDeleteEquipment.bind(this))
  }

  onSuccessfulDeleteEquipment(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Equipment deleted successfully.', intent: Intent.SUCCESS })
  }

  onFailDeleteEquipment(error) {
    Toast.show({ icon: 'warning-sign', message: 'Problem in deleting equipment!', intent: Intent.DANGER })
  }

  handleClosEquipmentForWorkReportDailog() {
    this.props.store.isOpenAddEquipmentForWorkReportDialog = false;
  }

  onSuccessAddEquipmentForWorkReport(equipment) {
    const {
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
    this.props.viewStore.equipmentSearchWorkReport.where.client_id = object.attributes.client.id;
    this.props.equipmentStore.persistEquipment.object.client = {attributes: object.attributes.client, id: object.attributes.client.id, type: 'client'};
    this.props.equipmentStore.persistEquipment.object.client_id = object.attributes.client.id;
    this.props.equipmentStore.loadEquipmentsForWorkReports();
    this.props.store.isOpenAddEquipmentForWorkReportDialog = true;
  }

  render() {
    const {
      workReportStore: {
        workReport: {
          object
        }
      },
      store
    } = this.props;

    return (
      <React.Fragment>
        <div className="table-title" style={{ paddingLeft: '10px' }}>
          <h5><strong>Equipment</strong></h5>
          <Button icon='add' className="bp3-minimal add-btn" onClick={() => { this.onSuccessAddEquipmentForWorkReport() }}  >Add Equipment</Button>
        </div>
        <div style={{ padding: '0px 10px' }} >
          {
            object && object.attributes.equipments.length === 0 && <div className="bp3-callout bp3-intent-warning warning-alert-message bp3-icon-info-sign table-card">
              No equipment have been added to this Work Order.
            </div>
          }
        </div>

        <div style={{ marginBottom: '1rem' }}>
          {
            object.attributes.equipments.length > 0 && <div className="wr-row"><table className='table table-bordered border-0'>
              <thead style={{ backgroundColor: '#F6F6FA', color: '#91A4B9' }}>
                <tr>
                  <th>Image</th>
                  <th>Description</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Serial No.</th>
                  <th>Location</th>
                  <th style={{ width: 62 }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  object.attributes.equipments.map((equipment) => {
                    return (
                      <div style={{ display: 'contents' }}>
                        <tr key={Math.random()}>
                          <td rowSpan="2" width="100px" className="border-left-0">
                            {
                              equipment && equipment.images && equipment.images.length > 0 ?
                                <img src={equipment && equipment.images && equipment.images.length > 0 && equipment.images[0].asset.thumb.url} className="equipment_img" alt='Equipment Image' />
                                :
                                <img src="/../equipment_default.jpg" className="equipment_img" alt='Equipment Image' />
                            }
                          </td>
                          <td>{equipment && equipment.kind}</td>
                          <td>{equipment && equipment.make}</td>
                          <td>{equipment && equipment.model}</td>
                          <td>{equipment && equipment.serial_number}</td>
                          <td>{equipment && equipment.physical_location}</td>
                          <td rowSpan="2" vAlign="middle" className="border-0">
                            <Button
                              minimal
                              type='button'
                              icon={"cross"}
                              onClick={this.handleSelectConfirm.bind(this, equipment.serviced_equipment_id)}
                              className="row-delete-btn"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="5">{equipment && equipment.description}</td>
                        </tr>
                        <div style={{ height: 10 }}></div>
                      </div>

                    )
                  })
                }
              </tbody>
              {/* <tbody>
                {
                  object.attributes.equipments.map((equipment) => {
                    return (
                      <tr key={Math.random()}>
                        <td colSpan="7" style={{paddingRight:0, border:0}}>
                          
                          
                                <table className="table table-bordered">
                                  <tr>
                                    <td rowSpan="2">
                                      {
                                        equipment && equipment.images && equipment.images.length > 0 ?
                                        <img src={equipment && equipment.images && equipment.images.length > 0 && equipment.images[0].asset.thumb.url} className="equipment_img" alt='Equipment Image' />
                                        :
                                        <img src="/../equipment_default.jpg" className="equipment_img" alt='Equipment Image' />
                                      }
                                    </td>
                                    <td style={{ width: 200 }} className="py-0">{equipment && equipment.kind}</td>
                                    <td style={{ width: 120 }}>{equipment && equipment.make}</td>
                                    <td style={{ width: 150 }}>{equipment && equipment.model}</td>
                                    <td style={{ width: 150 }}>{equipment && equipment.serial_number}</td>
                                    <td>{equipment && equipment.physical_location}</td>
                                    <td rowSpan="2" vAlign="middle" className="border-0">
                                      <Button
                                        minimal
                                        type='button'
                                        icon={"cross"}
                                        onClick={this.handleSelectConfirm.bind(this, equipment.serviced_equipment_id)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>                              
                                    <td colSpan="6">
                                      {equipment && equipment.description}
                                    </td>
                                  </tr>
                                </table>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody> */}
              {/* <tbody>
                {
                  object.attributes.equipments.map((equipment) => {
                    return (
                      <tr key={equipment.id}>
                        <td>
                          <label className="only-mobile">Description</label>
                          {equipment.description}
                        </td>
                        <td>
                          <label className="only-mobile">Make</label>
                          {equipment.make}
                        </td>
                        <td>
                          <label className="only-mobile">Model</label>
                          {equipment.model}
                        </td>
                        <td>
                        <label className="only-mobile">Serial #</label>
                          {equipment.serial_number}
                        </td>
                        <td>
                          <label className="only-mobile">Location</label>
                          {equipment.physical_location}
                        </td>
                        <td className="item-center">
                          <Button 
                            className='bp3-button bp3-minimal bp3-icon-trash bp3-small'
                            type='button'
                            onClick={this.handleSelectConfirm.bind(this,equipment.serviced_equipment_id)}
                          />
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody> */}
            </table>
            </div>
          }
        </div>
        <AddEquipmentForWorkReportModal
          isOpen={store.isOpenAddEquipmentForWorkReportDialog}
          onClose={this.handleClosEquipmentForWorkReportDailog.bind(this)}
          workDetail={object}
          onSuccess={this.onSuccessAddEquipmentForWorkReport.bind(this)}
        />
        <Alert
          cancelButtonText="Close"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isSelectDialogVisible}
          onCancel={this.handleSelectCancel.bind(this)}
          onConfirm={this.handleSaveConfirm.bind(this)}
        >
          Are you sure to delete the selected equipment from your current work order?
        </Alert>
      </React.Fragment>
    )
  }
}

export default WorkReportEquipments;