import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Alert, Intent, Divider } from '@blueprintjs/core';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
import qs from 'qs';
import TextTruncate from 'react-text-truncate';

@inject('store', 'clientStore', 'viewStore') @observer
class ClientsTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable client;

  decideAppearance = (status) => {
    switch (status) {
      case 'active':
        return 'success'
      default:
        break;
    }
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        clientSearch
      }
    } = this.props;
    this.props.viewStore.clientSearch = {
      ...clientSearch,
      page: newPage
    };
    this.props.viewStore.clients();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.clientStore.deleteClient(this.client)
      .then(this.onSuccessfulDeleteClient.bind(this))
      .catch(this.onFailedClientDelete.bind(this))
  }

  onSuccessfulDeleteClient(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Client deleted successfully!', intent: Intent.SUCCESS })
    this.client = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedClientDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete client!', intent: Intent.DANGER })
  }

  handleClientClick(event, client) {
    switch (event.label) {
      case 'Edit':
        this.client = client;
        this.props.viewStore.clientShow({uuid: client.attributes.id})
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.client = client;
        break;
      default:
    }
  }

  render() {
    let {
      viewStore,
      clientStore,
      clientStore: {
        clients: {
          objects,
          meta
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left responsive-pagination">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
        <table className="table table-data-presentation" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th width="15%">Name & Number</th>
              <th>Contact Details</th>
              <th>Legal Name</th>
              <th className="width-40">Description</th>
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((client) => {
                return (
                  <tr key={client.id}>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/clients/${client.attributes.id}?${qs.stringify(viewStore.clientShowSearch, { encode: false })}`}
                        mainText={client.attributes.name}
                        subText={client.attributes.identifier}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        subText={[client.attributes.email, client.attributes.phone]}
                      />
                    </td>
                    <td>{client.attributes.legal_name}</td>
                    <td>
                      <TextTruncate
                        line={2}
                        truncateText="…"
                        text={client.attributes.description}
                      />
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.clientShow({uuid: client.attributes.id})}>
                          <img src="/edit-light-icon.svg"/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleClientClick({ label: 'Delete' }, client)}>
                          <img src="/delete-light-icon.svg" />
                        </Button>
                      </div>
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>

        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            All associated data of this client (such as work orders, equipments, timesheet entries, jobs etc) will also be deleted. Are you sure you want to do this?
          </p>
        </Alert>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left responsive-pagination">
            {/* <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            /> */}
          </div>
        </Footer>
      </React.Fragment>
    )
  }
}

export default ClientsTable;