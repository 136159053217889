import { observable, action, toJS } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

const EMPTY_FREQUENCY_OBJECT = {
  title: null
}


class FrequencyStore {
  
  @observable frequencies = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable frequency = {
    isLoading: false,
    isError: false,
    object: EMPTY_FREQUENCY_OBJECT
  };

  @observable frequencyUpdate = {
    isLoading: false,
    isError: false
  };

  @action
  deleteFrequency(frequencies) {
    this.frequencies.isLoading = true;
    let promise = Api.delete(`/frequencies/${frequencies.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadFrequencies();
      })
      .catch((error) => {
        this.frequencies.isLoading = false;
        this.frequencies.isError = true;
      })
    
    return promise
  }


  @action
  updateFrequency(object) {
    let params = {
      uuid: object.attributes.id
    }
    this.frequency.isLoading = true;
    let promise = Api.put(`/frequencies/${object.attributes.id}`, {frequency: toJS(object.attributes)})

    promise
      .then((response) => {
        this.frequency.isLoading = false;
        this.frequency.isError = false;
        //stores.viewStore.frequencyShow(params,response.data.data.attributes.title);
        //this.loadFrequency(params);
        stores.viewStore.frequencySearch.term = '*';
        stores.viewStore.frequencies();
        this.loadFrequencies(); 
      })
      .catch((error) => {
        this.frequency.isLoading = false;
        this.frequency.isError = true;
      })

    return promise;
  }

  @action
  createFrequency() {
    this.frequency.isLoading = true;
    let promise = Api.post(`/frequencies/`, {frequency: this.frequency.object})   

    promise
      .then((response) => {
        this.frequency.isLoading = false;
        this.frequency.isError = false;       
        this.frequency.object = response.data.data;  
        stores.viewStore.frequencies();
        this.loadFrequencies();    
      })
      .catch((error) => {
        this.frequency.isLoading = false;
        this.frequency.isError = true;       
      })

    return promise;
  }


  @action
  loadFrequencies = () => {
    this.frequencies.isLoading = true;
    const {
      per_page,
      page,
      order,
      where,
      term
    } = stores.viewStore.frequencySearch;
    let promise = Api.post('/frequencies/search', { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.frequencies.isLoading = false;
        this.frequencies.isError = false;
        this.frequencies.objects = response.data.data;
        this.frequencies.meta = response.data.meta;
      })
      .catch((error) => {
        this.frequencies.isLoading = false;
        this.frequencies.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadFrequency = (params) => {
    this.frequencyUpdate.isLoading = true;
    let promise = Api.get(`/frequencies/${params.uuid}`)

    promise
      .then((response) => {
        this.frequencyUpdate.isLoading = false;
        this.frequencyUpdate.object = response.data.data;
        stores.viewStore.frequencyShow(params,response.data.data.attributes.title);
      })
      .catch((error) => {
        this.frequencyUpdate.isLoading = false;
        this.frequencyUpdate.isError = true;
        console.error(error)
      })

    return promise;
  }

  

};

export default FrequencyStore;