import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { Survey, Model } from "survey-react";
import "./survey.css";

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`

@inject('viewStore', 'formSubmissionStore') @observer
class FormSubmission extends Component {

  @observable survey;

  componentDidMount() {
    this.props.formSubmissionStore.loadFormSubmissionViaToken();
  }

  get canRender() {
    let {
      formSubmissionStore: {
        formSubmission: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object && object.id);
  }

  onSurveyComplete(result) {
    let {
      formSubmissionStore: {
        formSubmission: {
          isLoading
        }
      }
    } = this.props;
    isLoading = true;
    this.props.formSubmissionStore.updateFormSubmissionData(result.data)
      .then(() => {
        isLoading = false;
        window.ReactNativeWebView.postMessage('goBack');
      })
    setTimeout(() => {
      window.document
        .querySelector('.sv_completed_page h3')
        .textContent = ""
    })
  }

  render() {
    let content, title;
    let {
      formSubmissionStore: {
        formSubmission: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;

    if (isLoading && !isError) {
      title = 'Loading..';
      content = <CardHolder><Spinner/></CardHolder>;
    }

    if (isError && !isLoading) {
      title = 'Failed to Load form submission';
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }

    if (this.canRender) {
      title = `${object.attributes.name} | Field Nexus`;
      this.survey = new Model(object.attributes.schema_version)
      this.survey.data = object.attributes.data
      this.survey.onComplete.add(this.onSurveyComplete.bind(this))
      content = <React.Fragment>
          <Survey model={this.survey}/>
      </React.Fragment>
    }
    return (
      <div>
        <Helmet>
          <title>Form Submission | FieldNexus</title>
        </Helmet>
          {content}
      </div>  
    )
  }
};

export default FormSubmission;