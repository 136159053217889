import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Menu, MenuDivider, MenuItem, Popover, Position, Tag } from "@blueprintjs/core";
import ListPicker from 'components/ListPicker';

const options = [
  { label: 'Draft', value: 'draft', selected: false },
  { label: 'Approved', value: 'approved', selected: false },
  { label: 'Submitted', value: 'submitted', selected: false },
  { label: 'Sent', value: 'sent', selected: false },
  { label: 'On Hold', value: 'on_hold', selected: false },
  { label: 'Rejected', value: 'rejected', selected: false }
];

@inject('store', 'quoteStore') @observer
class QuoteHeader extends Component {

  onQuoteStatusChange(selections) {
    let {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;
    let {
      props: {       
        store
      }
    } = this;
    let selectedValues = selections.map((status) => status.value);
    object.attributes.status = selectedValues[0];
    
    if(object.attributes.status) {
      this.props.quoteStore.changeStatus(object, object.attributes.status);
      if(object.attributes.status === 'approved'){
        setTimeout(() => {
          store.projectDetail =  object.attributes;        
          store.isOpenAddProjectDialog = true;
        }, 3000);
      }  
    }
  }


  render() {
    const {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;
    // const StatusDropDwon = (
    //   <Menu className="status-dropdwon">
    //     <MenuItem text="Draft" />
    //     <MenuItem text="More option" />
    //     <MenuItem text="More option" />
    //     <MenuItem text="More option" />
    //   </Menu>
    // );
    return (
      <div className="invoice-header"  style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', marginBottom: '1rem', marginTop: '1rem' }}>
        <div className="logo-company-name">
          <div className="header-logo">
          <img src={object.attributes.tenant.logo} height='75' alt='tenant logo'/>
        </div>
          <div className="header-address" style={{ display: 'flex', flexDirection: 'column' }}>
          <h5>{object.attributes.tenant.name}</h5>
          <span>{object.attributes.tenant.street_address}</span>
          <span>{object.attributes.tenant.city}, {object.attributes.tenant.province}, {object.attributes.tenant.postal_code}</span>
          <span>
            <strong>Tel: </strong>
            {object.attributes.tenant.phone}
          </span>
        </div>
        </div>
        {/* <div className="status-box text-right">
        <Popover 
                minimal={true}
                content={
                  <ListPicker
                    selectedOptions={[object.attributes.status]}
                    options={options}
                    maximumSelections={1}
                    onChange={this.onQuoteStatusChange.bind(this)}
                  />
                }
                position={Position.BOTTOM_LEFT}
                usePortal={true} 
                className="status-selector"
                >
                <Button rightIcon='caret-down' className="status-btn">
                  Status
                  <span>&nbsp;</span>
                  <Tag minimal={true} round={true}>{object.attributes.status}</Tag>
                </Button>
              </Popover>
        </div> */}
      </div>
    )
  }
}

export default QuoteHeader;