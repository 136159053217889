import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import SearchBars from 'components/SearchBars';
import Card from 'components/Card';
import SearchBarWrapper from 'components/SearchBarWrapper';
import { observer, inject } from 'mobx-react';
import { Spinner, NonIdealState, Icon, Checkbox } from "@blueprintjs/core";
import NotificationsTable from 'components/Tables/Notifications';

const PageWrapGray = styled('div')`
  padding: 15px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);
`
const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit * 3}px`};
`
@inject('store', 'notificationStore') @observer
class NotificationIndexPage extends Component {
  render() {
    let content;
    const {
      props: {
        notificationStore: {
          notifications: {
            isLoading,
            isError
          }
        }
      }
    } = this;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner /></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (!isError && !isLoading) {
      content = <NotificationsTable />
    }
    return (
      <div>
        <PageWrapGray>
          <Helmet>
            <title>Notifications Center | FieldNexus</title>
          </Helmet>
          <Card>
            {content}
          </Card>          
        </PageWrapGray>
      </div>
    )
  }
};
export default NotificationIndexPage;