import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import CustomItem from './CustomItem';
import _ from 'lodash';
import { currencyFormattedAmount } from 'helpers/currency';

@inject('store', 'workReportStore') @observer
class WorkReportCustomLineItems extends Component {

  calculateSubTotal(custom_line_items) {
    if(custom_line_items.length > 0) {
      return currencyFormattedAmount(_.sumBy(custom_line_items, 'total'))
    } else {
      return currencyFormattedAmount(0)
    }
  }

  calculateSubTotalBeforeMarkup(custom_line_items) {
    if(custom_line_items.length > 0) {
      var sum = 0;
      _.each(custom_line_items, function(line_item) {
        sum += parseFloat(line_item.quantity) * parseFloat(line_item.rate_per_unit)
      })
      return currencyFormattedAmount(sum)
    } else {
      return currencyFormattedAmount(0)
    }
  }
  

  render() {
    const {
      workReportStore,
      workReportStore: {
        workReport: {
          object
        },
        isLoadingBlankCustomLineItem
      },
    } = this.props;

    
    return (
      <React.Fragment>
        <div className="table-title" style={{ paddingLeft: '10px' }}>
          <h5><strong>Others</strong></h5>
          <Button icon='add' className="bp3-minimal add-btn" onClick={() => { workReportStore.addBlankLineItem('custom', object.attributes.id) }} loading={isLoadingBlankCustomLineItem}>Add Others</Button>
        </div>

        {/* <div style={{ marginBottom: '1rem' }}>
          {
            object.attributes.custom_line_items.length > 0 && <table className='responsive-table-layout table report-data-table' style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Rate({object.attributes.currency_symbol})</th>
                  <th>Markup</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  object.attributes.custom_line_items.map((lineItem,index) => { 
                    return (
                      <CustomItem lineItem={lineItem} key={lineItem.id} 
                        labour_sub_total={ this.props.labour_sub_total} 
                        material_sub_total={ this.props.material_sub_total} 
                        material_and_labour_total={this.props.material_and_labour_total}
                      />
                    )
                  })
                }
              </tbody>
            </table>
          }
        </div> */}
        {
          object.attributes.custom_line_items.length > 0 && <div className="wr-row">
            <div style={{ flex: 1 }}>
              <table className="table table-bordered border-0">
                <thead>
                  <tr>
                    <th scope="col">Description</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Rate({object.attributes.currency_symbol})</th>
                    <th scope="col">Markup</th>
                    <th scope="col" className="text-right">Total</th>
                    <th scope="col" style={{ width: 57 }}></th>
                  </tr>
                </thead>
                <tbody>
                 
                    {
                      object.attributes.custom_line_items.map((lineItem,index) => {
                        return (
                          <CustomItem lineItem={lineItem} key={lineItem.id} 
                            labour_sub_total={ this.props.labour_sub_total} 
                            material_sub_total={ this.props.material_sub_total} 
                            material_and_labour_total={this.props.material_and_labour_total}
                          />
                        )
                      })
                    }
                   
                </tbody>
              </table>
            </div>
          </div>
        }
        {object.attributes.custom_line_items.length > 0 && <div className="lineitem_sub_total">
          <p><span style={{ marginRight: 25 }}>Sub Total Before markup: {this.calculateSubTotalBeforeMarkup(object.attributes.custom_line_items)}</span> Sub Total: {this.calculateSubTotal(object.attributes.custom_line_items)}</p>
        </div>}
      </React.Fragment>
    )
  }
}

export default WorkReportCustomLineItems;