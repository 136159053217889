import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import Pagination from 'components/Pagination';
import Footer from '../Footer';
import qs from 'qs';
import { formatTime } from '../../helpers/time';
import { Icon, Checkbox } from "@blueprintjs/core";
import { observable } from 'mobx';
import moment from 'moment';
import Avatar from '@atlaskit/avatar';

@inject('store', 'notificationStore', 'viewStore') @observer
class NotificationsTable extends Component {
	@observable trackable;

	componentDidMount() {
    let {
      notificationStore,
      notificationStore: {
        notifications: {
          objects
        }    
      }
    } = this.props;
    //notificationStore.loadNotifications(); 
	}
	
	handleClick(event, trackable) {
    switch (event.label) {
      case 'Project':
        this.trackable = trackable;
        this.props.viewStore.projectShow({uuid: trackable.id})
        break;
      case 'Supplier':
        this.props.viewStore.supplierShow({uuid: trackable.id})
        this.trackable = trackable;
				break;
			case 'Client':
				this.props.viewStore.clientShow({uuid: trackable.id})
				this.trackable = trackable;
				break;
			case 'Job':
				this.props.viewStore.ShowJob({uuid: trackable.id})
				this.trackable = trackable;
				break;
			case 'ServiceAgreement':
				this.props.viewStore.serviceAgreementShow({uuid: trackable.id})
				this.trackable = trackable;
				break;
			case 'WorkReport':
				if(trackable.kind === 'Invoice'){
					this.props.viewStore.ShowInvoice({uuid: trackable.id})
				}
				if(trackable.kind === 'Quote'){
					this.props.viewStore.ShowQuote({uuid: trackable.id})
				}
				if(trackable.kind === 'WorkReport'){
					this.props.viewStore.ShowWorkOrder({uuid: trackable.id})
				}
				
				this.trackable = trackable;
				break;
      default:
    }
  }

	onPaginationChange(newPage) {
		let {
			viewStore: {
					notificationSearch
			}
		} = this.props;
		this.props.viewStore.notificationSearch = {
			...notificationSearch,
			page: newPage
		};
		this.props.viewStore.notifications();
	}

	render() {
		let {
			viewStore,
			notificationStore: {
				notifications: {
					objects,
					meta
				}
			}
		} = this.props;

		if (objects && objects.length === 0) {
			return (
				<div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
					<h4 className="bp3-heading">No Results</h4>
					Your search criteria returned no results. Please modify/expand your search parameters to see results.
				</div>
			)
		}
	
		return (
			<React.Fragment>
				<div className="card rounded-0 table-responsive">
					<Footer>
            <div className="bp3-navbar-group bp3-align-left">
              <Pagination
                value={meta.page}
                total={meta.total_pages}
                onChange={this.onPaginationChange.bind(this)}
              />
            </div>
          </Footer>
					{objects && objects.map((notification) => {
						return(
							<div className="notification-wrap" style={{marginLeft: 10, marginRight: 10}} key={Math.random()}>
							<div className="notification-header" style={{ 'backgroundColor':'#4431FF'}}>
								<div className="head-content" onClick={() => this.handleClick({ label: notification.attributes.trackable.type }, notification.attributes.trackable)}>
									<div dangerouslySetInnerHTML={{ __html: notification.attributes.message.html }}></div>
								</div>
								<div className="headr-white-text" style={{'color':'#fff'}}>
									<div style={{display:'flex',alignItems:'center'}} onClick={() => viewStore.userShow({uuid: notification.attributes.owner.id})}>
										<Avatar src={encodeURI(`https://ui-avatars.com/api/?name=${notification.attributes.owner.name}&size=34&background=2D4EF5&color=fff`)} size='small' /> 
										<div style={{marginLeft:10}}>{notification.attributes.owner.name}</div>
									</div>
									<div style={{'color':'#fff'}}>{moment(notification.attributes.created_at).fromNow()}</div>
								</div>
							</div>
							<div className="notification-content" onClick={() => this.handleClick({ label: notification.attributes.trackable.type }, notification.attributes.trackable)}>
								<ul className="list-unstyled mb-0" >
									<li>
										<div dangerouslySetInnerHTML={{ __html: notification.attributes.message.short_info }}></div>
									</li>
								</ul>
							</div>
						</div>
						)
					})} 
				</div>
				<Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
			</React.Fragment>
		)
	}

};

export default NotificationsTable;