import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { 
  Button,FormGroup,Checkbox, Radio,
  Intent, Spinner, RadioGroup
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import styled from '@emotion/styled';

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);
  width: 100%;
`

@inject('bookingStore') @observer
class QuestionTextbox extends React.Component {
	@observable isChecked = false;

  onTextboxChange = (event,question) => {
    let {
      props: {
        bookingStore: {
          booking: { 
            object
           }
        }
      }
    } = this;
    if(object && object.attributes){
      let index = object.attributes.booking_questions.map(function(item) { 
        return item.question_id; 
      }).indexOf(question.id);
      if(index > -1){
        object.attributes.booking_questions[index].answer = event.target.value;
      }else{
        object.attributes.booking_questions.push({answer:event.target.value,question_id:question.id});
      }

    }else{
      let index = object.booking_questions_attributes.map(function(item) { return item.question_id; }).indexOf(question.id);
      if(index > -1){
        object.booking_questions_attributes[index].answer = event.target.value;
      }else{
        object.booking_questions_attributes.push({answer:event.target.value,question_id:question.id});
      }
    }
    
  }

  render() {
    let {
			question, answer, checkedValue, selectedAnswer, bookingStatus
		} = this.props;
    return(
      <input type="text" 
        className='form-control' 
        defaultValue={selectedAnswer || ''}
        placeholder=''
        disabled={bookingStatus && bookingStatus === 'confirmed' ? true : false}
        onBlur={(event) => {this.onTextboxChange(event,question)}} 
      />
    )
  }
}



export default QuestionTextbox;