import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { 
  Spinner, Dialog, Classes, Intent
} from "@blueprintjs/core";
import { Survey, Model } from "survey-react";
import Toast from 'helpers/toaster';
import "pages/forms/survey.css";

@inject('store', 'formSubmissionStore') @observer
export default class FormSubmissionModal extends Component {

  onSurveyComplete(result) {
    this.props.formSubmissionStore.submission.object.attributes.data = result.data;
    this.props.formSubmissionStore.updateFormSubmission()
      .then(() => {
        Toast.show({ icon: 'thumbs-up', message: 'Form updated successfully.', intent: Intent.SUCCESS })
        this.props.onClose();
      })
  }

  render() {
    let survey;
    let {
      isLoading,
      object
    } = this.props.formSubmissionStore.submission;

    if(object && object.attributes && object.attributes.id) {
      survey = new Model(object.attributes.schema_version)
      survey.data = object.attributes.data
      survey.onComplete.add(this.onSurveyComplete.bind(this))
    }
    return(
      <Dialog
        style={{ width: '70vw' }}
        title="Form"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={() => this.props.onClose()}
        style={{width:'73%'}}
      >
        <div className={Classes.DIALOG_BODY} >
          {isLoading && <Spinner />}
          {!isLoading && object && object.attributes && object.attributes.id &&
            <Survey model={survey}/>
          }
        </div>
      </Dialog>
    )
  }

}
