import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { 
  Spinner, Button,
  NonIdealState,
  Icon, Tag,
  Tab, Tabs, Popover,
  Menu, MenuItem, Intent
} from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import Tooltip from '@atlaskit/tooltip';
import Page from 'components/Page';
import Card from 'components/Card';
import styled from '@emotion/styled';
import JobMapView from 'components/Job/MapView';
import JobHeader from 'components/Job/Header';
import WorkOrdersTable from 'components/Job/WorkOrdersTable';
import EstimatesTable from 'components/Job/EstimatesTable';
import InvoicesTable from 'components/Job/InvoicesTable';
import TimeLogsTable from 'components/Job/TimeLogsTable';
import LogsTable from 'components/Job/LogsTable';
import JobsActivityLogs from 'components/Job/JobsActivityLogs';
import NotesTable from 'components/Job/NotesTable';
import AddNotesModal from 'components/Job/AddNotesModal';
import AddWorkReportModal from 'components/WorkReport/AddWorkReportModal';
import AddEstimateModal from 'components/Quote/AddEstimateModal';
import AddInvoiceModal from 'components/Invoice/AddInvoiceModal';
import {
  CardHolder,
  JobCardHolder,
  TopBanner,
  ColumnContainer,
  Column
} from 'components/Styles';
import TableHeading from 'components/TableHeading';
import { router } from '../../App';
import { formatTime } from '../../helpers/time';
import { observable } from 'mobx';
import ImagesTable from 'components/Job/ImagesTable';
import Toast from 'helpers/toaster';

const PageWrapGray = styled('div')`
  background-color: #F6F6FA;
  height: calc(100vh - 150px);
  overflow:hidden;
`

const LabelRow = ({ icon, text, bold, children }) => (
  <div className="contact-info padding-style">
    {icon && <Icon icon={icon} />}
    {
      children && <span style={{ marginLeft: '8px' }}>
        {children}
      </span>
    }
    {
      !children && <p style={{ marginLeft: (icon ? '8px' : '0px'), display: 'inline' }}>
        {bold && <strong>{text}</strong>}
        {!bold && text}
      </p>
    }
  </div>
)

@inject('store', 'viewStore', 'jobStore') @observer
class CustomerInfoCard extends React.Component {

  handleChangeAssignmentStatus(selectedOption, assignment) {
    let {
      jobStore
    } = this.props;
    let event = "";
    switch(selectedOption) {
      case 'in_progress':
        event = "start";
        break;
      case "assigned":
        event = "assign";
        break;
      case "confirmed":
        event = "confirm";
        break;
      case "completed":
        event = "complete";
        break;
      default:
        event = selectedOption;
    }
    jobStore.changeAssignmentStatus(event, assignment)
      .then((response) => {
        jobStore.refreshJob({uuid: assignment.job_id})
          .then((response) => {
            Toast.show({ icon: "thumbs-up", message: "Status changed successfully.", intent: Intent.SUCCESS })
          })
      })
      .catch((error) => {
        if(error.response && error.response.data && error.response.data.errors) {
          Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ message: "Something went wrong. Failed to change status", intent: Intent.DANGER })
        }
      })
  }

  navigateToClient(job) {
    return () => { 
      router.navigate(`/dashboard/clients/${job.attributes.client.id}?resource=jobs`)
    }
  }
  render() {
    let { job, viewStore, jobStore: { showChangeAssignmrntStatusLoader } } = this.props;
    let options = [
      {label: 'Assigned',value: 'assigned'},
      {label: 'Confirmed',value: 'confirmed'},
      {label: 'In Progress',value: 'in_progress'},
      {label: 'Complete',value: 'completed'}
    ];
    return (
      <Card>
        
        {/* <ColumnContainer className="job-customer-details">
          <div className="customer-details-header">
            <h5 className='bp3-heading'>CUSTOMER DETAILS</h5>
          </div>
        </ColumnContainer> */}
        <ColumnContainer className="customer-contact-details">
          <Column className="scroll">
              {job.attributes.service_agreement_id && 
            <Column className="details-column">
              <h5 className='bp3-heading'>Service Agreement</h5>
              <LabelRow
                icon='repeat'
                bold={true}
              >
                <a style={{color: '#1800ff'}} onClick={() => {viewStore.serviceAgreementShow({uuid: job.attributes.service_agreement.id}, job.attributes.service_agreement.number)}}>{job.attributes.service_agreement.number}</a>
              </LabelRow>
            </Column>
          }
            <Column className="details-column">
            {/* <h5 className='bp3-heading'>SCHEDULE</h5> */}
              <div className="start-end-date customer-info-field">
                <div className="field-icon"><img src="/select-time-date.svg" /></div>
                <div className="field-data">
                  <div className="date-time">Created At - <span>{ formatTime(job.attributes.created_at) }</span></div>
                </div>
              </div>
              <div className="start-end-date customer-info-field">
                <div className="field-icon"><img src="/select-time-date.svg" /></div>
                <div className="field-data">
                  <div className="date-time">Start - <span>{ formatTime(job.attributes.start_date) }</span></div>
                  <div className="date-time">End - <span>{ formatTime(job.attributes.end_date) }</span></div>
                </div>
              </div>
          </Column>  
          {job && job.attributes && job.attributes.po_number && <Column className="details-column">
            <div className="start-end-date customer-info-field">
              <div className="field-icon"><img src="/note-pad-icon.svg" /></div>
              <div className="field-data">
                <div className="date-time">PO Number - <span>{job.attributes.po_number}</span></div>
              </div>
            </div>
          </Column>}  
          <Column className="details-column">
            <JobDescription job={job} />
          </Column>
            <Column  className="details-column">
              <div className="assigned-user">
                <h5 className='bp3-heading'>ASSIGNED TO {showChangeAssignmrntStatusLoader && <Spinner size={20} />} </h5>
                <ul className="assigned-user-avatar" style={{ listStyleType: 'none', padding: '0px' }}>
                  {
                    job.attributes.assignments && job.attributes.assignments.map((assignment) => {
                      return (
                        <li key={assignment.id}>
                          <div>
                            <AvatarItem
                              avatar={<Avatar borderColor={'#0a6cf1'} src={encodeURI(`https://ui-avatars.com/api/?name=${assignment.name}&size=128`)} size='large' />}
                            />
                          </div>
                          <div className="assignment-wrapper">
                            <div className="assigned-user-name">
                              {assignment.name}
                            </div>
                            {assignment.status != "unassigned" && <div>
                              <Popover minimal={true} inheritDarkTheme={true} className='add-job-wrapper'>
                                <Button minimal={true} rightIcon='chevron-down' className="more-actions-btn assignment-popover" >{assignment.formatted_status}</Button>
                                <Menu>
                                  <MenuItem text="Assigned" icon={assignment.status === "assigned" ? 'tick' : ''} onClick={() => {this.handleChangeAssignmentStatus('assigned', assignment)}} />
                                  <MenuItem text="Confirmed" icon={assignment.status === "confirmed" ? 'tick' : ''} onClick={() => {this.handleChangeAssignmentStatus('confirmed', assignment)}} />
                                  <MenuItem text="In Progress" icon={assignment.status === "in_progress" ? 'tick' : ''} onClick={() => {this.handleChangeAssignmentStatus('in_progress', assignment)}} />
                                  <MenuItem text="Complete" icon={assignment.status === "completed" ? 'tick' : ''} onClick={() => {this.handleChangeAssignmentStatus('completed', assignment)}} />
                                </Menu>
                              </Popover>
                            </div>}
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </Column>
            {
              job.attributes.project && <Column className="details-column">
                <div className="assigned-user">
                  <h5 className='bp3-heading'>PROJECT</h5>
                  <Menu>
                    {
                      job.attributes.project && <MenuItem text={job.attributes.project.name} icon='projects' labelElement={<Icon icon="caret-right" />}/>
                    }
                  </Menu>
                </div>
              </Column>
            }
            <Column className="details-column">
              <div className="customer-info-field">
                <div className="field-icon"><img src="/map-pin-icon.svg" /></div>
                <div className="field-data">
                  {/* <span className="name"><a onClick={this.navigateToClient(job)}>{job.attributes.client_name}</a></span> */}
                  <span className="field-info">{job.attributes.location_address}</span>
                  <span className="field-info field-style">
                  {
                    job.attributes.client.email && <a className="field-info" href={`mailto:${job.attributes.client.email}`}>{job.attributes.client.email}</a>
                  }
                  {
                    !job.attributes.client.email && "N/A"
                  }
                  </span>
                  <span className="field-info">{job.attributes.client.phone || "N/A"}</span>
                  <span>
                    {
                      (job.attributes.client.tags.length === 0) && <Tag
                          minimal={true}
                          intent='warning'
                          interactive={true}
                          className='bp3-tag-with-margin'
                        >
                        No Tags
                      </Tag>
                    }
                    {
                      job.attributes.client.tags.map((tag) => {
                        return <Tag minimal={true} interactive={true} className={'bp3-tag-with-margin'} key={tag.id}>{tag.name}</Tag>
                      })
                    }
                  </span>
                </div>
              </div>
            </Column>
          </Column>
        </ColumnContainer>
      </Card>
    )
  }
}

class JobDescription extends React.Component {
  render() {
    let { job } = this.props;
    return(
      <div className="job-reason-description">
        <div className="reason-descriptions customer-info-field">
          <div className="field-icon"><img src="/text-pad.svg" /></div>
          <div className="field-data">
          <h5 className='bp3-heading'>JOB TITLE</h5>
          <p>
            {job.attributes.title || "N/A"}
          </p>
          </div>
        </div>
        <div className="reason-descriptions customer-info-field">
          <div className="field-icon"><img src="/text-pad.svg" /></div>
          <div className="field-data">
            <h5 className='bp3-heading'>REASON FOR CALL?</h5>
            <p>
              {job.attributes.reason_for_call || "N/A"}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

// class Estimates extends React.Component {
//   render() {
//     return (
//       <table className='table-card' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
//       <tbody>
//         <tr>
//           <td width="10px">
//             <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=Milton Murray&size=128`)} size='large' />
//           </td>
//           <td width="150px">
//             <p className="primary">Milton Murray</p>
//             <p className="secondary">#03419-01</p>
//           </td>
//           <td>
//             <p className='time-entry primary'>$2205.83</p>
//             <p className='secondary'>Dec 20th, 2018</p>
//           </td>
//           <td>
//             <span className='bp3-tag bp3-minimal bp3-intent-success'>APPROVED</span>
//           </td>
//           <td>
//             <Icon icon="caret-right" />
//           </td>
//         </tr>
//         <tr>
//           <td width="10px">
//             <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=Milton Murray&size=128`)} size='large' />
//           </td>
//           <td width="150px">
//             <p className="primary">Milton Murray</p>
//             <p className="secondary">#03419-01</p>
//           </td>
//           <td>
//             <p className='time-entry primary'>$2205.83</p>
//             <p className='secondary'>Dec 20th, 2018</p>
//           </td>
//           <td>
//             <span className='bp3-tag bp3-minimal'>DRAFT</span>
//           </td>
//           <td>
//             <Icon icon="caret-right" />
//           </td>
//         </tr><tr>
//           <td width="10px">
//             <Avatar src={encodeURI(`https://ui-avatars.com/api/?name=Milton Murray&size=128`)} size='large' />
//           </td>
//           <td width="150px">
//             <p className="primary">Milton Murray</p>
//             <p className="secondary">#03419-01</p>
//           </td>
//           <td>
//             <p className='time-entry primary'>$2205.83</p>
//             <p className='secondary'>Dec 20th, 2018</p>
//           </td>
//           <td>
//             <span className='bp3-tag bp3-minimal bp3-intent-danger'>REJECTED</span>
//           </td>
//           <td>
//             <Icon icon="caret-right" />
//           </td>
//         </tr>
//       </tbody>
//     </table>
//     )
//   }
// }

@inject('store', 'jobStore','viewStore', 'activityStore', 'workReportStore', 'quoteStore', 'invoiceStore') @observer
class JobShowPage extends Component {

  get canRender() {
    let {
      jobStore: {
        job: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object);
  }

  @observable selectedTabId = 'location';
  @observable isOpenAddNotesDialog = false;
  @observable isOpenAddWorkReportDialog = false;
  @observable isOpenAddEstimateDialog = false;
  @observable isOpenAddInvoiceDialog = false;

  componentDidMount() {
    this.selectedTabId = this.props.viewStore.jobTab.section;
    this.handleTabChange(this.selectedTabId)
  }
  
  handleTabChange = (tabId) => {
    let {
      viewStore,
      jobStore: {
        job: { object }
      }

    } = this.props;
    this.selectedTabId = tabId; 
    viewStore.jobTab.section = tabId; 
    if(object && object.attributes && object.attributes.id) {
      window.history.pushState({}, '', `/dashboard/jobs/${object.attributes.id}/${tabId}`);
    }
  }

  handleCloseNotesDailog() {
    this.isOpenAddNotesDialog = false;
  }

  onSuccessAddNotes(note) {
    let {
      jobStore,
      jobStore: {
        job: {
          object
        }
      },
      activityStore
    } = this.props;
    this.isOpenAddNotesDialog = false;
    jobStore.refreshJob({ uuid: object.attributes.id })
      .then(() => {
        activityStore.loadActivityLogs(object.attributes.id);
      })
  }

  handleWorkReportClick() {
    let job = this.props.jobStore.job.object;
    let { object } = this.props.workReportStore.newWorkReport;

    // client pre selected for work order
    let client_details = {
      attributes: {
        name: ''
      }
    };
    client_details.attributes = job.attributes.client;
    object.selectedClient = client_details;
    object.client_id = job.attributes.client.id;

    // job pre selected for work order
    let job_details = [];
    job_details = [{
      id: job.attributes.id,
      label: job.attributes.title,
      value: job.attributes.id,
      selected: true
    }]
    object.selectedJob = job_details;
    object.scheduled_event_id = job.attributes.id;

    // location pre selected for work order
    let location_details = {
      attributes: job.attributes.location
    };
    object.location = location_details;
    object.location_id = job.attributes.location.id;

    // job search using client id for work order
    this.props.viewStore.jobSearch.where.client_id = job.attributes.client.id;
    this.isOpenAddWorkReportDialog = true;
  }

  handleCloseWorkReportDailog() {
    this.isOpenAddWorkReportDialog = false;
    let { object } = this.props.workReportStore.newWorkReport;
    object.selectedJob = null;
    object.selectedClient = null;
    object.client_id = '';
    object.scheduled_event_id = '';
  }

  onSuccessAddWorkReport(workReport) {
    this.isOpenAddWorkReportDialog = false;
  }

  handleEstimateClick() {
    let job = this.props.jobStore.job.object;
    let { object } = this.props.quoteStore.newEstimate;

    // client pre selected for work order
    let client_details = {
      attributes: {
        name: ''
      }
    };
    client_details.attributes = job.attributes.client;
    object.selectedClient = client_details;
    object.client_id = job.attributes.client.id;

    // job pre selected for work order
    let job_details = [];
    job_details = [{
      id: job.attributes.id,
      label: job.attributes.title,
      value: job.attributes.id,
      selected: true
    }]
    object.selectedJob = job_details;
    object.scheduled_event_id = job.attributes.id;

    // location pre selected for work order
    let location_details = {
      attributes: job.attributes.location
    };
    object.location = location_details;
    object.location_id = job.attributes.location.id;

    // job search using client id for work order
    this.props.viewStore.jobSearch.where.client_id = job.attributes.client.id;
    this.isOpenAddEstimateDialog = true;
  }

  handleCloseEstimateDailog() {
    this.isOpenAddEstimateDialog = false;
    let { object } = this.props.quoteStore.newEstimate;
    object.selectedJob = null;
    object.selectedClient = null;
    object.client_id = '';
    object.scheduled_event_id = '';
  }

  onSuccessAddEstimate(estimate) {
    this.isOpenAddEstimateDialog = false;
  }

  handleInvoiceClick() {
    let job = this.props.jobStore.job.object;
    let { object } = this.props.invoiceStore.newInvoice;

    // client pre selected for work order
    let client_details = {
      attributes: {
        name: ''
      }
    };
    client_details.attributes = job.attributes.client;
    object.selectedClient = client_details;
    object.client_id = job.attributes.client.id;

    // job pre selected for work order
    let job_details = [];
    job_details = [{
      id: job.attributes.id,
      label: job.attributes.title,
      value: job.attributes.id,
      selected: true
    }]
    object.selectedJob = job_details;
    object.scheduled_event_id = job.attributes.id;

    // location pre selected for work order
    let location_details = {
      attributes: job.attributes.location
    };
    object.location = location_details;
    object.location_id = job.attributes.location.id;

    // job search using client id for work order
    this.props.viewStore.jobSearch.where.client_id = job.attributes.client.id;
    this.isOpenAddInvoiceDialog = true;
  }

  handleCloseInvoiceDailog() {
    this.isOpenAddInvoiceDialog = false;
    let { object } = this.props.invoiceStore.newInvoice;
    object.selectedJob = null;
    object.selectedClient = null;
    object.client_id = '';
    object.scheduled_event_id = '';
  }

  onSuccessAddInvoice(invoice) {
    this.isOpenAddInvoiceDialog = false;
  }

  render() {
    let content, title;
    let {selectedTabId} = this;
    let {
      jobStore: {
        job: {
          isLoading,
          isError,
          object
        }
      },
      store
    } = this.props;
    if (isLoading && !isError) {
      title = 'Loading..';
      content = <JobCardHolder><Spinner/></JobCardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load Job';
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender && object.attributes) {
      title = `Job #${object.attributes.event_number} | Field Nexus`;
    }else{
      title = `Loading...`;
    }
    return (
      <React.Fragment>
        {
          <Helmet>
            <title>{title}</title>
          </Helmet>
        }
        {
          !this.canRender && object && object.attributes && content 
        }
        {
          this.canRender && object.attributes && <TopBanner>
            <JobHeader job={object} handleWorkReportClick={this.handleWorkReportClick.bind(this)} handleEstimateClick={this.handleEstimateClick.bind(this)} handleInvoiceClick={this.handleInvoiceClick.bind(this)}/>
          </TopBanner>
        }
        <PageWrapGray className="job-details-page">
          {
            this.canRender && object.attributes && <ColumnContainer>
              <div className="job-page-container fn-job-details">
              <Column
                className="column-left"
              >
                <CustomerInfoCard job={object} />
                <Card>
                  <ColumnContainer>
                    {/* <JobMapView job={object} /> */}
                  </ColumnContainer>
                </Card>
              </Column>
              <Column 
                className="column-center"
              >
                <div className="job-details-tabs">
                  <Tabs onChange={this.handleTabChange.bind(this)}  selectedTabId={selectedTabId} large={true}>
                    <Tab id="location" title="Location" ></Tab>
                    <Tab id="work-orders" title="Work Orders"><span>{object.attributes.counts.work_order}</span></Tab>
                    <Tab id="estimates" title="Estimates" ><span>{object.attributes.counts.estimate}</span></Tab>
                    <Tab id="invoices" title="Invoices" ><span>{object.attributes.counts.invoice}</span></Tab>
                    <Tab id="time-logs" title="Time Logs" ><span>{object.attributes.counts.time_log}</span></Tab>
                    <Tab id="images" title="Images" ><span>{object.attributes.counts.images}</span></Tab>
                    <Tab id="activities" title="Activity" ><span>{object.attributes.counts.activities}</span></Tab>
                  </Tabs>
                </div>
                <div className="tabs-data">
                {
                  selectedTabId === 'location' &&
                  <JobMapView job={object} />
                }
                {
                  selectedTabId === 'work-orders' &&
                  <WorkOrdersTable job={object} handleWorkReportClick={this.handleWorkReportClick.bind(this)}/>
                }
                {
                  selectedTabId === 'estimates' &&
                  <EstimatesTable job={object} handleEstimateClick={this.handleEstimateClick.bind(this)}/> 
                }
                {
                  selectedTabId === 'invoices' &&
                  <InvoicesTable job={object} handleInvoiceClick={this.handleInvoiceClick.bind(this)}/>  
                }
                {
                  selectedTabId === 'time-logs' &&
                  <TimeLogsTable job={object} />
                }
                {
                  selectedTabId === 'images' &&
                  <ImagesTable job={object} />
                }
                {
                  selectedTabId === 'activities' &&
                  <JobsActivityLogs job={object} />
                }
                </div>
              </Column>
              <Column
                className="column-right" 
                style={{ marginRight: '0px' }}
              >
                <Column className="notes-block">
                  <div className="notes-header">
                    <TableHeading heading="NOTES" /> 
                    {/* <div><textarea className="notes-textarea" placeholder="+  Type to add note..."></textarea></div> */}
                    { <Button icon='add' className="bp3-minimal" onClick={() => { this.isOpenAddNotesDialog = true }}><span style={{fontSize: 16}}><strong>ADD</strong></span></Button> }
                  </div>
                  <NotesTable job={object} /> 
                </Column>
              </Column>
              </div>
            </ColumnContainer>
          }
        </PageWrapGray>
        {/* <div style={{ backgroundColor: 'white', borderLeft: '1px solid #E8ECF0', width: '400px', marginTop: '2px', height: '100vh', boxShadow: '0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2)' }}>
          <p>hello boss {content}</p>
        </div> */}
        <AddNotesModal
          title={'Add Notes'}
          edit={false}
          isOpen={this.isOpenAddNotesDialog}
          onClose={this.handleCloseNotesDailog.bind(this)}
          onSuccess={this.onSuccessAddNotes.bind(this)}
        />
        <AddWorkReportModal
          isOpen={this.isOpenAddWorkReportDialog}
          onClose={this.handleCloseWorkReportDailog.bind(this)}
          onSuccess={this.onSuccessAddWorkReport.bind(this)}
          edit={true}
        />
        <AddEstimateModal
          isOpen={this.isOpenAddEstimateDialog}
          onClose={this.handleCloseEstimateDailog.bind(this)}
          onSuccess={this.onSuccessAddEstimate.bind(this)}
          edit={true}
        />
        <AddInvoiceModal
          isOpen={this.isOpenAddInvoiceDialog}
          onClose={this.handleCloseInvoiceDailog.bind(this)}
          onSuccess={this.onSuccessAddInvoice.bind(this)}
          edit={true}
        />
      </React.Fragment>
    )
  }

};

export default JobShowPage;