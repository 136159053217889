import { observable, action, toJS } from 'mobx';
import { Api, CurrentEnv} from 'services/Api';
import stores from './index';
import axios from 'axios';
var FileDownload = require('js-file-download');

const EMPTY_EQUIPMENT = {}

class EquipmentStore {

  @observable showUploadAttachmentLoader = false;

  @observable persistEquipment = {
    isLoading: false,
    isError: false,
    object: {
      images:[],
      equipment_parts_attributes:[]
    },
  };

  @observable equipmentPart = {
    isLoading: false,
    isError: false,
    object: {},
  };

  @observable equipments = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable clientEquipments = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable equipment = {
    isLoading: false,
    isError: false,
    object: null
  };

  @observable persisteEquipment = EMPTY_EQUIPMENT;
  @observable equipmentAnalytics = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @action
  loadAnalytics = (params) => {
    this.equipmentAnalytics.isLoading = true;
    let promise = Api.get(`/equipments/${params.uuid}/analytics`);

    promise
      .then((response) => {
        this.equipmentAnalytics.isLoading = false;
        this.equipmentAnalytics.isError = false;
        this.equipmentAnalytics.objects = response.data;
      })
      .catch((error) => {
        this.equipmentAnalytics.isLoading = false;
        this.equipmentAnalytics.isError = true;
      })
    
    return promise;
  }

  @action
  clearSelectedEquipment() {
    this.equipment = {
      isLoading: false,
      isError: false,
      object: null
    };
  }


  @action
  createEquipment() {
    this.persistEquipment.isLoading = true;
    let promise = Api.post(`/equipments/`, {equipment: this.persistEquipment.object})   

    promise
      .then((response) => {
        this.persistEquipment.isLoading = false;
        this.persistEquipment.isError = false;       
        this.persistEquipment.object = response.data.data;        
      })
      .catch((error) => {
        this.persistEquipment.isLoading = false;
        this.persistEquipment.isError = true;       
      })

    return promise;
  }

  @action
  download_bulk_excel(searchObj) {
    let promise = Api.post('/equipments/excel_bulk_download', { search: searchObj }, { responseType: 'arraybuffer' })

    promise
      .then((response) => {
        FileDownload(response.data, 'Equipments.xls');
      })
      .catch((error) => {
      })
  }

  @action
  createEquipmentParts(equipment) {
    this.equipment.isLoading = true;
    let promise = Api.put(`/equipments/${equipment.attributes.id}`, {equipment: toJS(equipment.attributes)})

    promise
      .then((response) => {
        this.equipment.isLoading = false;
        this.equipment.isError = false;
        this.loadEquipment({uuid:equipment.attributes.id});
      })
      .catch((error) => {
        this.equipment.isLoading = false;
        this.equipment.isError = true;
      })

    return promise;
  }

  @action
  deleteEquipmentPart(part) {
    this.equipments.isLoading = true;
    
    let data = {
      id:part.id
    }
    let promise = Api.delete('/equipments/remove_equipment_part/',{data})
    
    promise
      .then((response) => {
        this.loadEquipment({uuid:part.equipment_id});
      })
      .catch((error) => {
        this.equipments.isLoading = false;
        this.equipments.isError = true;
      })
    
    return promise
  }

  @action
  updateEquipment(equipment) {
    this.equipment.isLoading = true;
    let promise = Api.put(`/equipments/${equipment.attributes.id}`, {equipment: toJS(equipment.attributes)})

    promise
      .then((response) => {
        this.equipment.isLoading = false;
        this.equipment.isError = false;
        stores.viewStore.equipments();
      })
      .catch((error) => {
        this.equipment.isLoading = false;
        this.equipment.isError = true;
      })

    return promise;
  }

  @action
  deleteEquipment(equipment) {
    this.equipments.isLoading = true;
    let promise = Api.delete(`/equipments/${equipment.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadEquipments();
      })
      .catch((error) => {
        this.equipments.isLoading = false;
        this.equipments.isError = true;
      })
    
    return promise
  }

  @action
  deleteServicedEquipment(equipment,workOrderId) {
    this.equipments.isLoading = true;
    let data = {
      serviced_equipment_id:equipment
    }
    let promise = Api.delete('/equipments/remove_serviced_equipment/',{data})
    
    promise
      .then((response) => {
        stores.workReportStore.loadWorkReport({ uuid: workOrderId});
      })
      .catch((error) => {
        this.equipments.isLoading = false;
        this.equipments.isError = true;
      })
    
    return promise
  }


  @action
  create_serviced_equipment = (equipment_id,work_report_id) => {
    this.persistEquipment.isLoading = true;    
    let promise = Api.post('/equipments/create_serviced_equipment',{ 
      serviced_equipment: { 
        equipment_id: equipment_id,
        work_report_id: work_report_id
      }
    });
    promise
      .then((response) => {
        this.persistEquipment.isLoading = false;
        this.persistEquipment.isError = false;       
        this.persistEquipment.object = response.data.data; 
        stores.workReportStore.loadWorkReport({ uuid: work_report_id});
        stores.equipmentStore.loadEquipmentsForWorkReports();       
      })
      .catch((error) => {
        this.persistEquipment.isLoading = false;
        this.persistEquipment.isError = true;
        console.error(error)
      })
    
    return promise;
  }


  @action
  loadEquipmentsForWorkReports = () => {
    this.equipments.isLoading = true;
    const {
      term,
      per_page,
      page,
      order,
      where
    } = stores.viewStore.equipmentSearchWorkReport;
    let promise = Api.post('/equipments/search', { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.equipments.isLoading = false;
        this.equipments.isError = false;
        this.equipments.objects = response.data.data;
        this.equipments.meta = response.data.meta;
      })
      .catch((error) => {
        this.equipments.isLoading = false;
        this.equipments.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadEquipments = () => {
    this.equipments.isLoading = true;
    const {
      per_page,
      page,
      order,
      where
    } = stores.viewStore.equipmentSearch;
    let promise = Api.post('/equipments/search', { 
      search: { 
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.equipments.isLoading = false;
        this.equipments.isError = false;
        this.equipments.objects = response.data.data;
        this.equipments.meta = response.data.meta;
      })
      .catch((error) => {
        this.equipments.isLoading = false;
        this.equipments.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadClientEquipments = () => {     
    this.clientEquipments.isLoading = true;
    let promise = Api.post('/equipments/search', { 
      search: window.stores.viewStore.clientEquipmentSearch
    });

    promise
      .then((response) => {
        this.clientEquipments.isLoading = false;
        this.clientEquipments.isError = false;
        this.clientEquipments.objects = response.data.data;
        this.clientEquipments.meta = response.data.meta;
      })
      .catch((error) => {
        this.clientEquipments.isLoading = false;
        this.clientEquipments.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadEquipment(params) {
    this.equipment.isLoading = true;
    let promise = Api.get(`/equipments/${params.uuid}`)

    promise
      .then((response) => {
        this.equipment.isLoading = false;
        this.equipment.isError = false;
        this.equipment.object = response.data.data;
        stores.viewStore.equipmentShow(params,response.data.data.attributes.make);
      })
      .catch((error) => {
        this.equipment.isLoading = false;
        this.equipment.isError = true;
        console.error(error)
      })

    return promise;
  }

  @action
  uploadEquipmentAttachment(file, equipment_id = null) {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('equipment_id', equipment_id)
    this.showUploadAttachmentLoader = true;
    let promise = axios.post(`${CurrentEnv.domain()}/api/v2/images`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Api-Token': stores.viewStore.currentUser.attributes.api_token,
          'X-Tenant':stores.viewStore.currentUser.attributes.tenant_id,
          'X-Client-Identifier': 'React-Web'
        }
      }
    )

    promise
      .then((response) => {
        this.showUploadAttachmentLoader = false;
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = true;
      })

    return promise;

  }

  @action
  attach_image(files, equipment_id = null) {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('file[]',files[i])
    };
    formData.append('equipment_id', equipment_id)
    this.showUploadAttachmentLoader = true;
    let promise = axios.post(`${CurrentEnv.domain()}/api/v2/equipments/${equipment_id}/attach_image/`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Api-Token': stores.viewStore.currentUser.attributes.api_token,
          'X-Tenant':stores.viewStore.currentUser.attributes.tenant_id,
          'X-Client-Identifier': 'React-Web'
        }
      }
    )

    promise
      .then((response) => {
        this.showUploadAttachmentLoader = false;
        // let params = {
        //   uuid: equipment_id
        // }
        // this.loadEquipmentForImage(params)
        // .then((response) => {
        //   this.equipment.object = response.data.data;
        // })
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = true;
      })

    return promise;

  }

  @action
  removeAttachment(attachment, equipment_id = null) {
    this.showUploadAttachmentLoader = true;
    let promise = Api.delete(`/equipments/${attachment.id}/remove_attachment`)

    promise
      .then((response) => {
        if(equipment_id) {
          this.showUploadAttachmentLoader = false;
        }
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = false;
      })

    return promise;
  }

  @action
  loadEquipmentForImage(params) {
    let promise = Api.get(`/equipments/${params.uuid}`)
    
    promise
      .then((response) => {
        this.equipment.object = response.data.data;
      })
      .catch((error) => {
        console.error(error)
      })

    return promise;
  }

};

export default EquipmentStore;