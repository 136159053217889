import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Intent, Alert, Button, ButtonGroup, TextArea } from '@blueprintjs/core';
import { observable } from 'mobx';
import Toast from 'helpers/toaster';
import _ from 'lodash';
import { currencyFormattedAmount } from 'helpers/currency';

@inject('store', 'workReportStore', 'viewStore', 'preferenceStore') @observer
class CustomItem extends Component {

  @observable isDeleteDialogVisible = false;
  @observable lineitem;

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.workReportStore.deleteLineItem(this.lineitem)
      .then(this.onSuccessfulDeleteCustom.bind(this))
      .catch(this.onFailDeleteCustom.bind(this))
  }

  onSuccessfulDeleteCustom(response) {
    Toast.show({ icon: 'thumbs-up', message: 'successfully deleted default lineitem!', intent: Intent.SUCCESS })
    this.lineitem = null;
    this.isDeleteDialogVisible = false;
  }

  onFailDeleteCustom(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete default lineitem!', intent: Intent.DANGER })
  }

  handlemCustomClick(event, lineitem) {
    switch (event.label) {
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.lineitem = lineitem;
        break;
      default:
    }
  }

  updateLineItem() {
    this.props.workReportStore.updateLineItem(this.props.lineItem)
      .then(this.onSuccessfullUpdateLineItem.bind(this))
      .catch(this.onFailUpdateLineItem.bind(this))
  }

  onSuccessfullUpdateLineItem(response) {
    this.props.lineItem.edit = false;
    Toast.show({ icon: 'thumbs-up', message: 'Updated successfully!', intent: Intent.SUCCESS })
  }

  onFailUpdateLineItem(error) {
    Toast.show({ icon: 'warning-sign', message: 'Unable to update!', intent: Intent.DANGER })
  }

  handleQuantityChange(event) {
    let { lineItem } = this.props;
    lineItem.quantity_display_value = event.target.value === "" ? 0 : event.target.value;
    lineItem.quantity = parseFloat(lineItem.quantity_display_value)
    this.calculatelineItem();
  }

  handleRateChange(event) {
    let { lineItem } = this.props;
    var value = event.target.value === "" ? 0 : event.target.value.replace('$', '').replace(',', '')
    lineItem.rate_per_unit = value;
    lineItem.rate_per_unit_display_value = currencyFormattedAmount(lineItem.rate_per_unit)
    this.calculatelineItem();
  }

  handleOverRide(event) {
    this.props.lineItem.override = event.target.checked
    let {
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
    if (event.target.checked) {
      // true case
      // new value needs to enter in input box
    } else {
      // false case
      if (object.attributes.custom_line_items.length > 0) {
        _.each(object.attributes.custom_line_items, function (lineItem) {
          if (lineItem.default_line_item_id) {
            if (lineItem.default_line_item_type === "percentage_of_labour" && !lineItem.override) {
              // rate update
              lineItem.rate_per_unit = _.sumBy(object.attributes.labour, 'total') * lineItem.default_line_item_value / 100;
            }
            if (lineItem.default_line_item_type === "percentage_of_material" && !lineItem.override) {
              // rate update
              lineItem.rate_per_unit = _.sumBy(object.attributes.materials, 'total') * lineItem.default_line_item_value / 100;
            }

            if (lineItem.default_line_item_type === "percentage_of_materials_labour" && !lineItem.override) {
              // rate update
              lineItem.rate_per_unit = (_.sumBy(object.attributes.labour, 'total') + _.sumBy(object.attributes.materials, 'total')) * lineItem.default_line_item_value / 100;
            }

            if (lineItem.default_line_item_type === "dollar_value" && !lineItem.override) {
              lineItem.rate_per_unit = lineItem.default_line_item_value
            }

            lineItem.rate_per_unit_display_value = currencyFormattedAmount(lineItem.rate_per_unit)

            // total update
            if (lineItem.markup) {
              lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit) * parseFloat(lineItem.markup)
            } else {
              lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit)
            }
            lineItem.total_display_value = currencyFormattedAmount(lineItem.total)
          }
        });
      }
    }
  }

  handleMarkupChange(event) {
    let { lineItem } = this.props;
    lineItem.markup = event.target.value === "" ? 1 : parseFloat(event.target.value)
    this.calculatelineItem();
  }

  componentWillMount() {
    let {
      preferenceStore
    } = this.props;
    preferenceStore.loadPreferences()
  }

  calculatelineItem() {
    let { lineItem } = this.props;
    if (lineItem.description === "Truck Charge") {
      let { currentUser } = this.props.viewStore;
      if (currentUser.attributes.truck_charge.enabled) {
        lineItem.total = (parseFloat(lineItem.quantity) / 4) * parseFloat(currentUser.attributes.truck_charge.rate)
      } else {
        if (lineItem.markup) {
          lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit) * parseFloat(lineItem.markup)
        } else {
          lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit)
        }
      }
    } else {
      if (lineItem.markup) {
        lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit) * parseFloat(lineItem.markup)
      } else {
        lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit)
      }
    }

    lineItem.total_display_value = currencyFormattedAmount(lineItem.total)
  }

  render() {
    const {
      lineItem,
      viewStore: {
        currentUser
      }
    } = this.props;
    return (
      <React.Fragment>
        {/* <tr key={lineItem.id}>
          <td>
            <label className="only-mobile">Description</label>
            <input
              type='text'
              className='bp3-input bp3-fill'
              placeholder='Description'
              defaultValue={lineItem.description}
              onChange={(event) => { lineItem.description = event.target.value; }}
            />
          </td>
          <td>
            <label className="only-mobile">Quantity</label>
            <input
              type='number'
              className='bp3-input bp3-fill'
              placeholder='Hours'
              defaultValue={parseFloat(lineItem.quantity)}
              onChange={(event) => this.handleQuantityChange(event)}
            />
          </td>
          <td>
            <label className="only-mobile">Rate($)</label>
            <input
              type='number'
              className='bp3-input bp3-fill'
              placeholder='Rate'
              readOnly={lineItem.default_line_item_id ? !lineItem.override : false}
              defaultValue={lineItem.rate_per_unit || ''}
              onChange={(event) => this.handleRateChange(event)}
            />
            {lineItem.default_line_item_id && <input
              type='checkbox'
              className="bp3-checkbox"
              checked={lineItem.override}
              onChange={(event) => this.handleOverRide(event)}
              />} {lineItem.default_line_item_id && <span> Override  </span>}
          </td>
          <td>
          <label className="only-mobile">Markup</label>
            <input
              type='number'
              readOnly={currentUser.attributes.truck_charge.enabled}
              className='bp3-input bp3-fill'
              placeholder='Markup'
              defaultValue={lineItem.markup || 1}
              onChange={(event) => this.handleMarkupChange(event)}
            />
          </td>
          <td>
            <div>
              <label className="only-mobile">Total</label>
              {currencyFormattedAmount(lineItem.total)}
            </div>
          </td>
          <td style={{ width: '5%' }}>
            <button
              className='bp3-button bp3-minimal bp3-icon-trash bp3-small'
              type='button'
              onClick={() => this.handlemCustomClick({ label: 'Delete' }, lineItem)}
              style={{marginTop: -13}}
            />
          </td>
        </tr> */}


        <tr key={lineItem.id}>
          <td className="p-0 border-left-0">
            <input
              type='text'
              className='bp3-input bp3-fill'
              placeholder='Description'
              defaultValue={lineItem.description}
              onChange={(event) => {
                lineItem.description = event.target.value;
              }}
            />
          </td>
          <td className="p-0">
            <input
              type='number'
              className='bp3-input bp3-fill'
              placeholder='Hours'
              defaultValue={parseFloat(lineItem.quantity)}
              onChange={(event) => this.handleQuantityChange(event)}
            />
          </td>
          <td className="p-0">
            <div className={lineItem.default_line_item_id ? (lineItem.override ? 'rate-wrapper' : 'rate-wrapper rate-wrapper-disabled') : 'rate-wrapper'}>
              <input
                type='number'
                className='bp3-input bp3-fill'
                placeholder='Rate'
                readOnly={lineItem.default_line_item_id ? !lineItem.override : false}
                defaultValue={lineItem.rate_per_unit || ''}
                onChange={(event) => this.handleRateChange(event)}
              />
              {lineItem.default_line_item_id &&
                <div className='checkbox-input'>
                  <input
                    type='checkbox'
                    className="override-checkbox-icon"
                    checked={lineItem.override}
                    onChange={(event) => this.handleOverRide(event)}
                  />
                  <span> Override  </span>
                </div>
              }
            </div>
          </td>
          <td className="p-0">
            <input
              type='number'
              readOnly={currentUser.attributes.truck_charge.enabled}
              className='bp3-input bp3-fill'
              placeholder='Markup'
              defaultValue={lineItem.markup || 1}
              onChange={(event) => this.handleMarkupChange(event)}
            />
          </td>
          <td className="py-0" style={{ textAlign: 'right' }}>
            <strong>{currencyFormattedAmount(lineItem.total)}</strong>
          </td>
          <td vAlign="middle" className="border-0 py-0">
            <Button
              minimal
              type='button'
              icon={"cross"}
              onClick={() => this.handlemCustomClick({ label: 'Delete' }, lineItem)}
              className="row-delete-btn"
            />
          </td>
        </tr>
        {/* <tr>
                  <td colSpan="7" className="p-0">
                    <TextArea
                      growVertically={true}
                      className="bp3-input bp3-fill"
                      placeholder="Description"
                      defaultValue={lineItem.description}
                      onChange={(event) => {
                        lineItem.description = event.target.value;
                      }}
                    />
                  </td>
                </tr> */}

        <div style={{ height: 10 }}></div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>Warnning! Deleting this default line item will not be recoverable.</p>
          <p>Are you sure you want to do this?</p>
        </Alert>
      </React.Fragment>
    )
  }

};

export default CustomItem;