import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Intent
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';

const options = [
  { label: 'Pending order', value: 'pending order', selected: false },
  { label: 'Ordered', value: 'ordered', selected: false },
  { label: 'In transit', value: 'in transit', selected: false },
  { label: 'Received', value: 'received', selected: false },
  { label: 'Picked up by the technician', value: 'picked up by the technician', selected: false },
  { label: 'Archived', value: 'archived', selected: false }
];

var materialLineItemIdArray = {
  materialLineItemIds: []
}

@inject('store', 'workReportStore','viewStore') @observer
class PurchaseMaterialItem extends Component {
  @observable selectedStatus = '';
  @observable selectedMaterial = '';

  componentDidMount() {
    let {
      workReportStore: {
        deleteLineItem
      },
      material
    } = this.props;
    if(material){
      this.selectedStatus = material.status;      
    }
  }

  handleSelectedMaterial(event) {
    if(event.target.checked) {
      var checkboxValue = event.target.value;
      materialLineItemIdArray.materialLineItemIds.push(checkboxValue);
    } else {
      var index = materialLineItemIdArray.materialLineItemIds.indexOf(event.target.value); 
      if (index !== -1){
        materialLineItemIdArray.materialLineItemIds.splice(index, 1);
      }
    }
  }

  updateMaterialLineItem(event,materialItem) { 
    let {
      workReportStore,
      workReportStore: {
        deleteLineItem
      },
      material
    } = this.props;     
    this.selectedStatus = event.target.value;
    this.props.workReportStore.updateMaterialLineItems(materialItem,event.target.value)
    if(material){
      this.onSuccessfullUpdateLineItem();      
    }else{
      this.onFailUpdateLineItem();      
    }    
  }

  onSuccessfullUpdateLineItem(response) {    
    Toast.show({ icon: 'thumbs-up', message: 'Updated successfully!', intent: Intent.SUCCESS })
  }

  onFailUpdateLineItem(error) {
    Toast.show({ icon: 'warning-sign', message: 'Unable to update!', intent: Intent.DANGER })
  }


  render() {
    let {
      workReportStore,
      workReportStore: {
        deleteLineItem
      },
      material
    } = this.props;
    
    return(
      <tr key={material.id}>
        <td>
          {material.description}
        </td>        
        <td>
          {material.part_number}
        </td>
        <td>
          {parseFloat(material.quantity) || 0}  
        </td>
        
        <td width="15%">
          <div className="bp3-select bp3-fill">
            <select onChange={(event) => {this.updateMaterialLineItem(event,material)} } value={this.selectedStatus}>
              <option value='' disabled>Select Status</option>
              {
                options.length > 0 && options.map((items) => {
                  return(
                    <option value={items.value} key={items.value}>{items.label}</option>
                  )
                })
              }
            </select>
          </div>
        </td>
      </tr>
    )
  }

};

export default PurchaseMaterialItem;