import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Spinner, NonIdealState
} from '@blueprintjs/core';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import InvoicePayment from 'components/Payment/Payment';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`

@inject('paymentStore') @observer
class PayInvoice extends Component {

  componentWillMount() {
    const {
      props: {
        paymentStore,
      }
    } = this;
    paymentStore.loadInvoiceToPay()
  }

  render() {
    let content;
    const {
      paymentStore: {
        invoice: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;

    if (isLoading && !isError) {
      content =  <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }

    if (!isError && !isLoading && object && object.attributes && object.attributes.id) {
      content = <InvoicePayment />
    }
    return(
      <React.Fragment>
        <Helmet>
          <title>{(object && object.attributes && object.attributes.tenant.name) || ' '} | FieldNexus</title>
        </Helmet>
        {content}
      </React.Fragment>
    )
  }
};

export default PayInvoice;