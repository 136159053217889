import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { 
  Divider, Intent,
  Button,
  FormGroup, Spinner
} from '@blueprintjs/core';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Toast from "helpers/toaster";
import BookingsSidebar from '../bookings/bookings-sidebar';
import { Helmet } from 'react-helmet';

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`

@inject("questionGroupStore")
@observer
class QuestionGroupShowPage extends Component {

  updateQuestionGroup() {
    let {
      props: {
        questionGroupStore: {
          group: { 
            object
           }
        }
      }
    } = this;
    object.isLoading = true;
   
    if(object.attributes.name === "" || object.attributes.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    if(object.attributes.question === "" || object.attributes.question === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid question!', intent: Intent.DANGER })
      return;
    }


    this.props.questionGroupStore
      .updateQuestionGroup(object)
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "question updated successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to update question!",
          intent: Intent.DANGER
        });
      });
    
  }
 
  render() {
    let {
      questionGroupStore: {
        group: {
          object,
          isLoading
        }
      }
    } = this.props;
    
    if (isLoading) {
      return <Spinner size='25' />
    }

    return (
      <div>
        <Helmet>
          <title>{'Question Group | Fieldnexus'}</title>
        </Helmet>
       {
        object && object.attributes &&
        <div className="row no-gutters">
            <div className="col-lg-2 sidebar-responsive">
              <BookingsSidebar />
            </div>
            <div className="col-lg-10">
              <PageWrapGray>
                <div className="pricing-parameter-table">
                  <Card>
                    <CardContent>
                      <div>
                        <div className="left-side-panel-servie-type">
                          <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                            Update Question Group
                          </h4>
                        </div>
                      </div>
                      <Divider style={{ margin: '16px 0px' }}></Divider>
                      <div className="left-side-panel-servie-type pricing-parameter">
                        <FormGroup label="Name" >
                          <input 
                            type='text' 
                            className='form-control' 
                            placeholder='Name'
                            defaultValue={object.attributes.name}
                            onChange={(event) => {object.attributes.name = event.target.value;}}            
                          />
                        </FormGroup>
                        <FormGroup label="Question" >
                          <input 
                            type='text' 
                            className='form-control' 
                            placeholder=''
                            defaultValue={object.attributes.question}
                            onChange={(event) => {object.attributes.question = event.target.value;}}            
                          />
                        </FormGroup>
                      </div>
                      <div className="">
                        <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.updateQuestionGroup.bind(this)}>Update</Button>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </PageWrapGray>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default QuestionGroupShowPage;
