import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';
import Page from 'components/Page';
import styled from '@emotion/styled';
import { 
  NonIdealState, Spinner
} from "@blueprintjs/core";
import InvoiceSettingDetails from 'components/Preference/InvoiceSettingDetails'

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`

const ThinDrawer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

@inject('store', 'viewStore', 'preferenceStore') @observer
class InvoiceSettings extends Component {

  componentDidMount() {
    let { preferenceStore } = this.props;
    preferenceStore.loadTenant()
  }

  get canRender() {
    let {
      preferenceStore: {
        tenant: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object);
  }

  render() {
    let content;
    let {
      preferenceStore: {
        tenant: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      content = <InvoiceSettingDetails object={object} />
    }
    return(
      <div>
        <Helmet>
          <title>Invoice Settings | FieldNexus</title>
        </Helmet>
        <Page>
          <ThinDrawer>
            {content}
          </ThinDrawer>
        </Page>
      </div>
    )
  }
}

export default InvoiceSettings;