import React, { Component } from 'react'
import { Button, Popover, Menu, MenuItem, Position } from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import AddFormModal from 'components/Form/AddFormModal';
import styled from '@emotion/styled';

const SearchBarWrap = styled('div')`       
    width: 100%;
`

@inject('store', 'viewStore') @observer
class Form extends Component {

  @observable showAddFormDialog = false

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.formSearch.page = 1;
    viewStore.forms();
  }

  @action
  onSearchTermChange(e) {
    let {
      props: {
        viewStore: {
          formSearch
        }
      }
    } = this;
    this.props.viewStore.formSearch = {
      ...formSearch,
      term: e.target.value
    }
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.search()
    }
  }

  onCloseAddFormModal() {
    this.showAddFormDialog = false
  }

  onSuccessfulCreateForm() {
    this.showAddFormDialog = false
  }

  render() {
    let {
      props: {
        viewStore: {
          formSearch: { term }
        }
      }
    } = this;
    return (
      <SearchBarWrap>
      <nav className="bp3-navbar search bg-transparent navbar-overflow">
        <div>
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <input 
                  type="text"
                  className="bp3-input"
                  placeholder="Form Name"
                  style={{ width: '300px' }}
                  onChange={this.onSearchTermChange.bind(this)}
                  defaultValue={term}
                  onKeyPress={this.onEnter.bind(this)}
                />
                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
                {/* <Button rightIcon='refresh' text='Reset' onClick={() => { this.search = {} }}/> */}
                {/* <Button rightIcon='grouped-bar-chart' onClick={() => { this.props.store.visible = !this.props.store.visible }}/> */}
              </div>
            </div>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            <Button icon='plus' text='Add Form' onClick={() => {this.showAddFormDialog = true;} }/>
          </div>
        </div>
        <AddFormModal
          isOpen={this.showAddFormDialog}
          onClose={this.onCloseAddFormModal.bind(this)}
          onSuccess={this.onSuccessfulCreateForm.bind(this)}
        />
      </nav>
      </SearchBarWrap>
    )
  }
}

export default Form;