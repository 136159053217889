import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  ControlGroup,
  Button,
  Dialog,
  Classes,
  Intent,
  TextArea
} from "@blueprintjs/core";
import ClientAutocomplete from "components/ClientAutocomplete";
import TechnicianPicker from "components/TechnicianPicker";
import Toast from "helpers/toaster";
import JobPicker from "components/JobPicker";
import LocationAutocomplete from "components/LocationAutocomplete";
import { observable, toJS } from 'mobx';

@inject("viewStore", "workReportStore")
@observer
class AddWorkReportModal extends Component {
  @observable selectedOptions = null;
  @observable job_detals = null;

  componentDidMount() {
    const {
      viewStore: {
        currentUser
      },
      workReportStore: {
        newWorkReport: { 
          object 
        }
      }
    } = this.props;
    object.technician_id = [currentUser.id]
  }

  createWorkReport() {
    let {
      props: {
        workReportStore: {
          newWorkReport: { object }
        }
      }
    } = this;
    object.kind = "WorkReport";

    if(object.client_id === '' || object.client_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please choose customer name.', intent: Intent.DANGER })
      return;
    }
    if(object.technician_id === '' || object.technician_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please choose created by.', intent: Intent.DANGER })
      return;
    }

    this.props.workReportStore
      .createEmpty()
      .then(response => {
        this.props.onClose();
        Toast.show({
          icon: "thumbs-up",
          message: "Work order created successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to create work order!",
          intent: Intent.DANGER
        });
      });
  }

  handleCloseWorkReportDailog() {
    this.props.onClose();
  }

  onClientClear = () => {
    let { object } = this.props.workReportStore.newWorkReport;
    object.client_id = null;
    object.selectedClient = '';
  };

  onJobSelect(selectedOptions, allOptions) {
    let {
      props: {
        workReportStore: {
          newWorkReport: { object }
        }
      }
    } = this;
    this.selectedOptions = selectedOptions;
    object.selectedJob = [selectedOptions[0]];
    object.scheduled_event_id = selectedOptions[0].value;
  }

  onLocationClear = () => {
    let {
      props: {
        workReportStore: {
          newWorkReport: { object }
        }
      }
    } = this;
    object.location_id = null;
    object.location = null;
  };

  onTechnicianChange(technicians) {
    let {
      props: {
        workReportStore: {
          newWorkReport: { object }
        }
      }
    } = this;
    let selectedValues = technicians.map(status => status.value);
    object.technician_id = selectedValues.length > 0 ? [selectedValues[0]] : '';
  }

  render() {
    let { object } = this.props.workReportStore.newWorkReport;
    let { selectedClient } = this;
    let {edit} = this.props;
    
    return (
      <Dialog
        title="Add Work Order"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseWorkReportDailog.bind(this)}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Enter Customer Name, Phone Number or Email"
            labelFor="text-input"
            labelInfo="*"
          >
            <ClientAutocomplete
              style={{ width: "600px" }}
              inputProps={{
                placeholder:
                  "Start typing name, phone number or email..."
              }}
              value={object.selectedClient}
              onClientSelect={client => {
                if (object && client) {
                  object.client_id = client.id;
                  selectedClient = client;
                } else {
                  this.onClientClear();
                }
              }}
            />
          </FormGroup>
          {object.client_id && (
            <FormGroup
              label="Location"
              labelFor="text-input"
              labelInfo="(search for location)"
            >
              <ControlGroup fill={true} vertical={false}>
                <LocationAutocomplete
                  scope={{ client_id: object.client_id }}
                  style={{ width: "400px" }}
                  inputProps={{
                    fill: true,
                    placeholder: "Start typing location.."
                  }}
                  onLocationClear={this.onLocationClear.bind(this)}
                  value={object.location}
                  onLocationSelect={location => {
                    if (object && location) {
                      object.location_id = location.id;
                      object.location = location;
                    } else {
                      this.onLocationClear();
                    }
                  }}
                />
              </ControlGroup>
            </FormGroup>
          )}
          { edit === true &&
            <FormGroup>
              <JobPicker
                selectedOptions={toJS(object.selectedJob)}
                onChange={this.onJobSelect.bind(this)}
                maximumSelections={1}
              />
            </FormGroup>
          }
          <FormGroup labelFor="text-input">
            <TechnicianPicker
              fill="true"
              selectedOptions={object.technician_id || ""}
              onChange={this.onTechnicianChange.bind(this)}
              includeAdmins={true}
              showCreatedBy={true}
              maximumSelections={1}
            />
          </FormGroup>

          <FormGroup label="Description" labelFor="text-input">
            <TextArea
              className="bp3-fill"
              placeholder="Write a short description."
              onChange={event => {
                object.description = event.target.value;
              }}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button 
            onClick={this.handleCloseWorkReportDailog.bind(this)}
            style={{ width: "100px" }}
            >
              Cancel
            </Button>
            <Button
              intent={Intent.PRIMARY}
              loading={this.props.workReportStore.newWorkReport.isLoading}
              onClick={this.createWorkReport.bind(this)}
              icon="add"
              style={{ width: "200px" }}
            >
              Add Work Order
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddWorkReportModal;
