import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';
import Lockr from 'lockr';
import qs from 'qs';
import moment from 'moment-timezone';

class ViewStore {
  @observable currentUser = '';
  @observable currentPath = '';
  @observable currentView = '';
  @observable routingParams = {};
  @observable performingChangePassword = false;

  @observable jobTab = {
    section: "location"
  };

  @observable projectSearch = {
    where: {
      start_date: { gte: moment().subtract(2, 'weeks').startOf('day').unix() },
      end_date: { lte: moment().endOf('day').unix() },
      status: ['active', 'invoiced', 'completed', 'in_progress'],
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  };

  @observable userSearch = {
    section: 'all'
  }

  @observable userShowSearch = {
    resource: 'vacations'
  }

  @observable projectShowSearch = {
    resource: 'jobs'
  }

  @observable equipmentShowSearch = {
    resource: 'workreports'
  }

  @observable dashboardSearch = {
    date: moment().unix().toString(),
    view: (this.currentUser && this.currentUser.attributes && this.currentUser.attributes.dispatch_board_default_view) || 'timelineDay',
    service_type_id: [],
    technician_id: []
  }

  @observable CalendarSearchTimeline = {
    date: moment().unix().toString(),
    view: 'timelineDay'
  }

  @observable CalendarSearchBasicWeek = {
    date: moment().unix().toString(),
    view: 'basicWeek'
  }

  @observable CalendarSearchListWeek = {
    date: moment().unix().toString(),
    view: 'listWeek'
  }

  @observable CalendarSearchListDay = {
    date: moment().unix().toString(),
    view: 'listDay'
  }

  @observable categorySearch = {
    term: '',
    order: {
      title: 'asc'
    },
    per_page: 50,
    page: 1
  }

  @observable serviceTypeCategorySearch = {
    term: '',
    order: {
      name: 'asc'
    },
    per_page: 50,
    page: 1
  }

  @observable clientSearch = {
    term: '',
    order: {
      name: 'asc'
    },
    per_page: 50,
    page: 1,
    import:false
  }

  @observable serviceTypeSearch = {
    term: '*',
    where: {
      name: null,
      description: null,
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable bookingSearch = {
    term: '*',
    where: {
      start_date: { 
        gte: moment().subtract(1, 'month').startOf('day').unix(),
        lte: moment().endOf('day').unix()
      },
      first_name: null,
      last_name: null,
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable pricingParameterSearch = {
    term: '*',
    where: {
      name: null
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable questionSearch = {
    term: '*',
    where: {
      name: null
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable questionGroupSearch = {
    term: '*',
    where: {
      name: null
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable serviceTypeExtraSearch = {
    term: '*',
    where: {
      name: null
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable frequencySearch = {
    term: '*',
    where: {
      title: ''
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable spotSearch = {
    term: '*',
    where: {
      title: ''
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable notificationSearch = {
    term: '*',
    where: {
      trackable_id: ''
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable serviceAgreementSearch = {
    term: '',
    where: {
      client_id: '',
      location_id: ''
    },
    order: {
      name: 'asc'
    },
    per_page: 50,
    page: 1
  }

  @observable clientServiceAgreementSearch = {
    term: '',
    where: {
      client_id: '',
      location_id: ''
    },
    order: {
      name: 'asc'
    },
    per_page: 50,
    page: 1
  }

  @observable equipmentSearch = {
    term: '*',
    where: {
      client_id: null,
      location_id: null,
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable equipmentSearchWorkReport = {
    term: '*',
    where: {
      client_id: null,
      location_id: null,
      active: true
    },
    order: {
      created_at: 'desc'
    },
    per_page: 5,
    page: 1
  }

  @observable formSearch = {
    term: '',
    order: {
      name: 'asc'
    },
    per_page: 50,
    page: 1
  }

  @observable geolocationSearch = {
    where: {
      user_id: null,
      timestamp: {
        gte: moment().subtract(4, 'hours').unix(), 
        lte: moment().unix()
      }
    },
    order: { timestamp: "asc" },
    per_page: 5000,
    page: 1
  }

  @observable workReportSearch = {
    where: {
      created_at: { 
        gte: moment().subtract(1, 'month').startOf('day').unix(),
        lte: moment().endOf('day').unix()
      },
      kind: 'WorkReport',
      event_number: null,
      grand_total: {
        gte: null,
        lte:  null
      }
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }


  @observable jobSearch = {
    where: {
      start_date: { 
        gte: moment().subtract(1, 'month').startOf('day').unix()
      },
      end_date: { 
        lte: moment().endOf('day').unix()
      },
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1,
    only_deleted: false,
  }

  @observable quoteSearch = {
    where: {
      created_at: { 
        gte: moment().subtract(1, 'month').startOf('day').unix(),
        lte: moment().endOf('day').unix()
      },
      kind: 'Quote',
      event_number: null,
      grand_total: {
        gte: null,
        lte:  null
      }
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable invoiceSearch = {
    where: {
      created_at: { 
        gte: moment().subtract(1, 'month').startOf('day').unix(),
        lte: moment().endOf('day').unix()
      },
      kind: 'Invoice',
      event_number: null,
      grand_total: {
        gte: null,
        lte:  null
      }
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable clientLocationSearch = {
    where: {
      client_id: ''
    },
    order: {
      created_at: 'desc'
    },
    per_page: 25,
    page: 1
  }

  @observable clientJobSearch = {
    where: {
      client_id: ''
    },
    order: {
      created_at: 'desc'
    },
    per_page: 25,
    page: 1
  }

  @observable unassignedJobSearch = {
    term: '',
    where: {
      status: 'unassigned',
      start_date: { 
        gte: moment().startOf('day').unix()
      },
      end_date: { 
        lte: moment().add(7, 'days').endOf('day').unix()
      },
    },
    order: {
      created_at: 'desc'
    },
    per_page: 25,
    page: 1
  }

  @observable incompleteJobSearch = {
    where: {
      _not: {
        'assignments.status': 'completed'
      },
      start_date: { 
        gte: moment().startOf('day').unix()
      },
      end_date: { 
        lte: moment().subtract(14, 'days').endOf('day').unix()
      },
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable clientProjectSearch = {
    where: {
      client_id: ''
    },
    order: {
      created_at: 'desc'
    },
    per_page: 25,
    page: 1
  }

  @observable clientQuoteSearch = {
    where: {
      client_id: '',
      kind: 'Quote'
    },
    order: {
      created_at: 'desc'
    },
    per_page: 25,
    page: 1
  }

  @observable clientInvoicSearch = {
    where: {
      client_id: '',
      kind: 'Invoice'
    },
    order: {
      created_at: 'desc'
    },
    per_page: 25,
    page: 1
  }

  @observable clientEquipmentSearch = {
    where: {
      client_id: '',
    },
    order: {
      created_at: 'desc'
    },
    per_page: 25,
    page: 1
  }

  @observable clientShowSearch = {
    resource: 'jobs'
  }

  @observable supplierShowSearch = {
    resource: 'materials'
  }

  

  @observable timesheetSearch = {
    where: {
      start_time: { gte: moment().subtract(2, 'weeks').unix() },
      end_time: { lte: moment().unix() },
    },
    order: { created_at: 'desc' },
    per_page: 50,
    page: 1
  }


  @observable locationWorkReportSearch = {
    where: {
      created_at: { 
        gte: moment().subtract(1, 'month').unix(),
        lte: moment().unix()
      },
      kind: 'WorkReport',
      client_id:'',
      location_id:'',
      event_number: null,
      grand_total: {
        gte: null,
        lte:  null
      }
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable clientWorkReportSearch = {
    where: {
      client_id: '',     
      kind: 'WorkReport'
    },
    order: { created_at: 'desc' },
    per_page: 25,
    page: 1
  }

  @observable materialLineItemSearch = {
    where: {
      work_report_id: '',
      supplier_id: '',
      status: ['pending order']
    },
    order: { created_at: 'desc' },
    per_page: 25,
    page: 1
  }

  @observable materialLineItemSearchById = {
    where: {      
      line_item_id: ''
    },
    order: { created_at: 'desc' },
    per_page: 25,
    page: 1
  }

  @observable purchaseOrderItemSearch = {
    where: {
      material_line_item_id: '',
      supplier_id:''
    },
    order: { created_at: 'desc' },
    per_page: 25,
    page: 1
  }

  @observable productServiceSearch = {
    where: {
      supplier_id:'',
      product_service_type:''
    },
    order: { name: 'asc' },
    per_page: 25,
    page: 1
  }

  @observable productServiceIndexSearch = {
    term: "*",
    where: {
      supplier_id:'',
      product_service_type:''
    },
    order: { created_at: 'desc' },
    per_page: 25,
    page: 1
  }

  @observable countrySearch = {
    term: "*",
    order: { created_at: 'desc' },
    per_page: 1000,
    page: 1
  }

  @observable stateSearch = {
    term: "*",
    where: {
      country_id: ''
    },
    order: { created_at: 'desc' },
    per_page: 1000,
    page: 1
  }

  @observable suppliersSearch = {
    where: {
      supplier_id: ''
    },
    order: { created_at: 'desc' },
    per_page: 25,
    page: 1
  }


  
  @observable supplierSearch = {
    term: '*',
    order: {
      name: 'asc'
    },
    per_page: 50,
    page: 1
  }

  @observable clientWorkReportWithLocationSearch = {
    where: {
      client_id: '',
      location_id:'',
      kind: 'WorkReport'
    },
    order: { created_at: 'desc' },
    per_page: 25,
    page: 1
  }

  @observable billingInfo = {
    section: 'overview'
  }

  @observable vacationsSearch = {
    where: {
      start_date: { 
        gte: moment().subtract(1, 'month').startOf('day').unix()
      },
      end_date: { 
        lte: moment().endOf('day').unix()
      },
    },
    order: {
      created_at: 'desc'
    },
    per_page: 50,
    page: 1
  }

  @observable breadCrumbs = [
    { href: "/", text: "Dispatch Board" }
  ];

  @action
  touch() {
    this.currentPath = this.currentPath;
    this.currentView = this.currentView;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  @action
  setCurrentUser() {
    this.currentUser = Lockr.get('currentUser');
    if (this.currentUser) {
      Api.setToken(this.currentUser);
    }
  }

  @action
  users() {
    let section = this.userSearch.section;
    this.currentPath = `/dashboard/users/${section}`;
    this.currentView = 'Pages.Users.Index';
    this.breadCrumbs = [
      { href: "/dashboard/users/all", text: "Users" },
      { href: `/dashboard/users/${section}`, text: this.capitalizeFirstLetter(section)}
    ];
  }

  @action
  projects() {    
    let query = qs.stringify(this.projectSearch, { encode: false });
    this.currentPath = `/dashboard/projects?${query}`;
    this.currentView = 'Pages.Projects.Index';
    this.breadCrumbs = [     
      { href: `/dashboard/projects?${query}`, icon: "folder-close", text: "Projects"}
    ];
  }

  @action
  dashboard() {      
    let query = qs.stringify(this.dashboardSearch, { encode: false });
    this.currentPath = `/dashboard?${query}`;
    this.currentView = 'Pages.Dashboard.Index';
    this.breadCrumbs = [
      { href: `dashboard?${query}`, text: "Dispatch Board" },
    ];
  }

  @action
  workReports() {
    let query = qs.stringify(this.workReportSearch, { encode: false });
    this.currentPath = `/dashboard/work-reports?${query}`;
    this.currentView = 'Pages.WorkReports.Index';
    this.breadCrumbs = [      
      { href: `work-reports?${query}`, text: "Work Orders"}
    ];
  }

  @action
  MaterialOrder() {
   
    let query = qs.stringify(this.materialLineItemSearch, { encode: false });
    this.currentPath = `/dashboard/materialorders?${query}`;
    this.currentView = 'Pages.WorkReports.MaterialOrders';
    this.breadCrumbs = [      
      { href: `materialorders?${query}`, text: "Material Order"}
    ];
  }

  @action
  MaterialOrders() {
   
    let query = qs.stringify(this.materialLineItemSearch, { encode: false });
    this.currentPath = `/dashboard/materialorders?${query}`;
    this.currentView = 'Pages.WorkReports.MaterialOrders';
    this.breadCrumbs = [      
      { href: `materialorders?${query}`, text: "Material Order"}
    ];
    
  }

  @action
  PurchaseOrder() {
    let query = qs.stringify(this.purchaseOrderItemSearch, { encode: false });
    this.currentPath = `/dashboard/purchase-orders?${query}`;
    this.currentView = 'Pages.WorkReports.PurchaseOrders';
    this.breadCrumbs = [      
      { href: `purchase-orders?${query}`, text: "Purchase Order"}
    ];
  }

 

  @action
  jobsForCustomer(id) {
    let jobSearchForCustomer = {
      where: {
        start_date: { 
          gte: moment().subtract(1, 'month').unix()
        },
        end_date: { 
          lte: moment().unix()
        },
        user_id:id,
      },
      order: {
        created_at: 'desc'
      },
      
      per_page: 50,
      page: 1
    }
    let query = qs.stringify(jobSearchForCustomer, { encode: false });
    this.currentPath = `/dashboard/jobs?${query}`;
    this.currentView = 'Pages.Jobs.Index';
    this.breadCrumbs = [      
      { href: `dashboard/jobs?${query}`, text: "Jobs"}
    ];
  }

  @action
  workReportsForCustomer(id) {
    let workReportSearchForCustomer = {
      where: {
        created_at: { 
          gte: moment().subtract(1, 'month').unix(),
          lte: moment().unix()
        },
        kind: 'WorkReport',
        created_by_id:id,
        event_number: null,
        grand_total: {
          gte: null,
          lte:  null
        }
      },
      order: {
        created_at: 'desc'
      },
      per_page: 50,
      page: 1
    }

    let query = qs.stringify(workReportSearchForCustomer, { encode: false });
    this.currentPath = `/dashboard/work-reports?${query}`;
    this.currentView = 'Pages.WorkReports.Index';
    this.breadCrumbs = [      
      { href: `dashboard/work-reports?${query}`, text: "Work Orders"}
    ];
  }

  @action
  quotes() {    
    let query = qs.stringify(this.quoteSearch, { encode: false });
    this.currentPath = `/dashboard/quotes?${query}`;
    this.currentView = 'Pages.Quotes.Index';
    this.breadCrumbs = [      
      { href: `/dashboard/quotes?${query}`, text: "Estimates"}
    ];
  }

  @action
  quotesForCustomer(id) {  
    let quoteSearchForCustomer = {
      where: {
        created_at: { 
          gte: moment().subtract(1, 'month').unix(),
          lte: moment().unix()
        },
        kind: 'Quote',
        created_by_id:id,
        event_number: null,
        grand_total: {
          gte: null,
          lte:  null
        }
      },
      order: {
        created_at: 'desc'
      },
      per_page: 50,
      page: 1
    }  
    let query = qs.stringify(quoteSearchForCustomer, { encode: false });
    this.currentPath = `/dashboard/quotes?${query}`;
    this.currentView = 'Pages.Quotes.Index';
    this.breadCrumbs = [      
      { href: `/dashboard/quotes?${query}`, text: "Estimate"}
    ];
  }

  @action
  invoicesForCustomer(id) {  
    let invoiceSearchForCustomer = {
      where: {
        created_at: { 
          gte: moment().subtract(1, 'month').unix(),
          lte: moment().unix()
        },
        kind: 'Invoice',
        created_by_id:id,
        event_number: null,
        grand_total: {
          gte: null,
          lte:  null
        }
      },
      order: {
        created_at: 'desc'
      },
      per_page: 50,
      page: 1
    }  
    let query = qs.stringify(invoiceSearchForCustomer, { encode: false });
    this.currentPath = `/dashboard/invoices?${query}`;
    this.currentView = 'Pages.Invoices.Index';
    this.breadCrumbs = [      
      { href: `dashboard/invoices?${query}`, text: "Invoices"}
    ];
  }

  @action
  invoices() {    
    let query = qs.stringify(this.invoiceSearch, { encode: false });
    this.currentPath = `/dashboard/invoices?${query}`;
    this.currentView = 'Pages.Invoices.Index';
    this.breadCrumbs = [      
      { href: `/dashboard/invoices?${query}`, text: "Invoices"}
    ];
  }

  @action
  clients() {    
    let query = qs.stringify(this.clientSearch, { encode: false });
    this.currentPath = `/dashboard/clients?${query}`;
    this.currentView = 'Pages.Clients.Index';    
    this.breadCrumbs = [   
      { href: `/dashboard/clients?${query}`, text: "Clients" },
    ];
  }

  @action
  suppliers() {    
    let query = qs.stringify(this.supplierSearch, { encode: false });
    this.currentPath = `/dashboard/suppliers?${query}`;
    this.currentView = 'Pages.Suppliers.Index';    
    this.breadCrumbs = [   
      { href: `/dashboard/suppliers?${query}`, text: "Suppliers" },
    ];
  }
  
  serviceAgreements() {    
    let query = qs.stringify(this.serviceAgreementSearch, { encode: false });
    this.currentPath = `/dashboard/service_agreements?${query}`;
    this.currentView = 'Pages.ServiceAgreements.Index';    
    this.breadCrumbs = [   
      { href: `/dashboard/service_agreements?${query}`, text: "Service Agreements" },
    ];
  }

  @action
  serviceAgreementShow(params,data) {
    let number = '';
    if(data) {
      number = data;
    }  
    let query = qs.stringify(this.serviceAgreementSearch, { encode: false });
    this.currentPath = `dashboard/service_agreements/${params.uuid}/show`;
    this.currentView = 'Pages.ServiceAgreements.Show';
    
    this.breadCrumbs = [   
      { href: `/dashboard/service_agreements?${query}`, text: "Service Agreements"},    
      { href: `/dashboard/service_agreements/${params.uuid}/show`, text: number}
    ];
  }

  @action
  equipments() {     
    let query = qs.stringify(this.equipmentSearch, { encode: false });
    this.currentPath = `/dashboard/equipments?${query}`;
    this.currentView = 'Pages.Equipments.Index';
    this.breadCrumbs = [      
      { href: `equipments?${query}`, text: "Equipment"}
    ];
  }
  
  @action
  pricingParameters() {     
    let query = qs.stringify(this.pricingParameterSearch, { encode: false });
    this.currentPath = `/dashboard/pricing-parameter?${query}`;
    this.currentView = 'Pages.PricingParameter.Index';
    this.breadCrumbs = [      
      { href: `pricing-parameter?${query}`, text: "Pricing Parameters"}
    ];
  }

  @action
  questions() {     
    let query = qs.stringify(this.questionSearch, { encode: false });
    this.currentPath = `/dashboard/question?${query}`;
    this.currentView = 'Pages.Question.Index';
    this.breadCrumbs = [      
      { href: `question?${query}`, text: "Questions"}
    ];
  }

  @action
  questionGroups() {     
    let query = qs.stringify(this.questionGroupSearch, { encode: false });
    this.currentPath = `/dashboard/question-group?${query}`;
    this.currentView = 'Pages.QuestionGroup.Index';
    this.breadCrumbs = [      
      { href: `/dashboard/question-group?${query}`, text: "Question Groups"}
    ];
  }

  @action
  serviceTypeExtras() {     
    let query = qs.stringify(this.serviceTypeExtraSearch, { encode: false });
    this.currentPath = `/dashboard/service-type-extra?${query}`;
    this.currentView = 'Pages.ServiceTypeExtra.Index';
    this.breadCrumbs = [      
      { href: `service-type-extra?${query}`, text: "Service Type Extra"}
    ];
  }

  @action
  frequencies() {     
    let query = qs.stringify(this.frequencySearch, { encode: false });
    this.currentPath = `/dashboard/frequency?${query}`;
    this.currentView = 'Pages.Frequency.Index';
    this.breadCrumbs = [      
      { href: `frequency?${query}`, text: "Frequency"}
    ];
  }

  @action
  spots() {     
    let query = qs.stringify(this.spotSearch, { encode: false });
    this.currentPath = `/spots?${query}`;
    this.currentView = 'Pages.Spot.Index';
    this.breadCrumbs = [      
      { href: `spots?${query}`, text: "Spot"}
    ];
  }

  @action
  serviceTypes() {     
    let query = qs.stringify(this.serviceTypeSearch, { encode: false });
    this.currentPath = `/dashboard/service-type?${query}`;
    this.currentView = 'Pages.ServiceType.Index';
    this.breadCrumbs = [      
      { href: `service-type?${query}`, text: "Service Types"}
    ];
  }

  @action
  clientShow(params,data) {   
    let userName = '';
    if(data) {
      userName = data;
    }  
    let query = qs.stringify(this.clientShowSearch, { encode: false });
    this.currentPath = `/dashboard/clients/${params.uuid}?${query}`;
    this.currentView = 'Pages.Clients.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/clients?${query}`, text: "Clients" },
      { href: `/dashboard/clients/${params.uuid}?resource=jobs`, text: userName}
    ];
    
  }


  @action
  supplierShow(params,data) {   
    let userName = '';
    if(data) {
      userName = data;
    }  
    let query = qs.stringify(this.supplierSearch, { encode: false });
    this.currentPath = `/dashboard/suppliers/${params.uuid}/show`;
    this.currentView = 'Pages.Suppliers.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/suppliers?${query}`, text: "Suppliers" },     
      { href: `/dashboard/suppliers/${params.uuid}/show`, text: userName}
    ];
    
  }

  forms() {    
    let query = qs.stringify(this.formSearch, { encode: false });
    this.currentPath = `/dashboard/forms?${query}`;
    this.currentView = 'Pages.Forms.Index';
    this.breadCrumbs = [      
      { href: `forms?${query}`, text: "Forms"}
    ];
  }

  @action
  formShow(params) {
    let query = qs.stringify(this.formShowSearch, { encode: false });
    this.currentPath = `/dashboard/forms/${params.uuid}?${query}`;
    this.currentView = 'Pages.Forms.Show';   
  }

  @action
  AddNewJob() {
    this.currentPath = '/dashboard/jobs/new';
    this.currentView = 'Pages.Jobs.AddNew';
  }

  @action
  ShowWorkOrder(params,number) {
    let workOrderId = '';
    if(number) {
      workOrderId = number;
    }
    let query = qs.stringify(this.workReportSearch, { encode: false });
    this.currentPath = `/dashboard/work-reports/${params.uuid}/show`;
    this.currentView = 'Pages.WorkReports.Show';
    this.breadCrumbs = [
      { href: `/dashboard/work-reports?${query}`, text: "Work Orders" },
      { href: `/dashboard/work-reports/${params.uuid}/show`, text: workOrderId}
    ];
  }

  @action
  ShowPurchaseOrder(params,number) {
    let purchaseOrderNumber = '';
    if(number) {
      purchaseOrderNumber = number;
    }
    this.currentPath = `/dashboard/purchase-orders/${params.uuid}/show-purchase-order`;
    this.currentView = 'Pages.WorkReports.ShowPurchaseOrder';
    this.breadCrumbs = [
      { href: `/dashboard/purchase-orders`, text: "Purchase Orders" },
      { href: `/dashboard/purchase-orders/${params.uuid}/show-purchase-order`, text: purchaseOrderNumber}
    ];
  }

  @action
  ShowQuote(params,data) {
    let quoteNumber = '';
    if(data) {
      quoteNumber = data;
    }
    this.currentPath = `/dashboard/quotes/${params.uuid}/show`;
    this.currentView = 'Pages.Quotes.Show';
    let query = qs.stringify(this.quoteSearch, { encode: false });
    this.breadCrumbs = [   
      { href: `/dashboard/quotes?${query}`, text: "Estimates"},    
      { href: `/dashboard/quotes/${params.uuid}/show`, text: quoteNumber}
    ];
  }

  @action
  ShowInvoice(params, data) {
    let invoiceNumber = '';
    if(data) {
      invoiceNumber = data;
    }
    this.currentPath = `/dashboard/invoices/${params.uuid}/show`;
    this.currentView = 'Pages.Invoices.Show';
    let query = qs.stringify(this.invoiceSearch, { encode: false });
    this.breadCrumbs = [  
      { href: `/dashboard/invoices?${query}`, text: "Invoices"},    
      { href: `/dashboard/invoices/${params.uuid}/show`, text: invoiceNumber}
    ];
  }

  @action
  ShowJob(params, job_id) {
    let section = this.jobTab.section;
    if(params.section) {
      section = params.section;
      this.jobTab.section = params.section;
    }
    this.currentPath = `/dashboard/jobs/${params.uuid}/${section}`;
    this.currentView = 'Pages.Jobs.Show';
    let query = qs.stringify(this.jobSearch, { encode: false });
    this.breadCrumbs = [      
      { href: `/dashboard/jobs?${query}`, text: "Jobs"},
      { href: `/dashboard/jobs/${params.uuid}/${section}`, text: job_id}
    ];
  }

  @action
  jobs() {
    let query = qs.stringify(this.jobSearch, { encode: false });
    this.currentPath = `/dashboard/jobs?${query}`;
    this.currentView = 'Pages.Jobs.Index';
    this.breadCrumbs = [      
      { href: `/dashboard/jobs?${query}`, text: "Jobs"},
    ];
  }

  @action
  assignments() {
    let query = qs.stringify(this.jobSearch, { encode: false });
    this.currentPath = `/dashboard/assignments?${query}`;
    this.currentView = 'Pages.Assignments.Index';
    this.breadCrumbs = [      
      { href: `/dashboard/assignments?${query}`, text: "Assignments"},
    ];
  }

  @action
  timesheets() {
    let query = qs.stringify(this.timesheetSearch, { encode: false });
    this.currentPath = `/dashboard/timesheets?${query}`;
    this.currentView = 'Pages.Timesheets.Index';
    this.breadCrumbs = [      
      { href: `timesheets?${query}`, text: "Timesheets"}
    ];
  }

  @action
  userMap() {    
    let query = qs.stringify(this.userMap, { encode: false });
    this.currentPath = `/dashboard/users/map?${query}`;
    this.currentView = 'Pages.Users.Map';
    this.breadCrumbs = [
      { href: "/users/all", text: "Users" },
      { href: `map`, text: "Map"}
    ];
  }

  @action
  usersVacations() {    
    let query = qs.stringify(this.vacationsSearch, { encode: false });
    this.currentPath = `/dashboard/users/vacations?${query}`;
    this.currentView = 'Pages.Users.Vacations';
    this.breadCrumbs = [
      { href: "all", text: "Users" },
      { href: `vacations?${query}`, text: "Vacations"}
    ];
  }

  @action
  userProfile(params) {
    let currentUser = Lockr.get('currentUser');
    //this.currentPath = `/dashboard/users/profile`;
    //this.currentView = 'Pages.Users.Profile';
    let query = qs.stringify(this.userShowSearch, { encode: false });
    this.currentPath = `/dashboard/users/${currentUser.id}/show?${query}`;
    this.breadCrumbs = [  
      { href: "all", text: "Users" },    
      { href: `show?${query}`, text: "Profile"}
    ];
  }

  @action
  changePassword(params, query) {
    this.currentPath = `/dashboard/users/change-password`;
    this.currentView = 'Pages.Users.ChangePassword';
    let dashboardQuery = qs.stringify(this.dashboardSearch, { encode: false });
    this.breadCrumbs = [
      { href: `/dashboard?${dashboardQuery}`, text: "Dashboard" },
      { href: `change-password`, text: "ChangePassword"}
    ];
  }

  @action
  userMapShow() {
    let query = qs.stringify(this.geolocationSearch, { encode: false });
    this.currentPath = `/dashboard/users/map-show?${query}`;
    this.currentView = 'Pages.Users.MapShow';
    this.breadCrumbs = [
      { href: "/", text: "Users" },
      { href: `dashboard/users/map-show`, text: "Map"},
      { href: `dashboard/users/${this.geolocationSearch.where.user_id}`, text: ""}
    ];
  }

  @action
  clientViewSearch(client_id) {
    let query = qs.stringify(this.clientShowSearch, { encode: false });
    this.currentPath = `/dashboard/clients/${client_id}?${query}`;
    this.currentView = 'Pages.Clients.Show';
    this.breadCrumbs = [      
      { href: `dashboard/users/clients`, text: "Clients"}
    ];
  }

  // @action
  // supplierViewSearch(client_id) {
  //   let query = qs.stringify(this.supplierShowSearch, { encode: false });
  //   this.currentPath = `/dashboard/suppliers/${client_id}/show`;
  //   this.currentView = 'Pages.Suppliers.Show';
  //   this.breadCrumbs = [      
  //     { href: `dashboard/suppliers`, text: "Suppliers"}
  //   ];
  // }

  @action
  supplierViewSearch(client_id,data) {  
   
    let query = qs.stringify(this.supplierSearch, { encode: false });
    this.currentPath = `/dashboard/suppliers/${client_id}/show`;
    this.currentView = 'Pages.Suppliers.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/suppliers?${query}`, text: "Suppliers" },     
      { href: `/dashboard/suppliers/${client_id}/show`, text: data}
    ];
    
  }

  @action
  userViewSearch(user_id) {
    let query = qs.stringify(this.userShowSearch, { encode: false });
    this.currentPath = `/dashboard/users/${user_id}/show?${query}`;
    this.currentView = 'Pages.Users.Show';
  }

  @action
  projectViewSearch(project_id) {
    let query = qs.stringify(this.projectShowSearch, { encode: false });
    this.currentPath = `/dashboard/projects/${project_id}/show?${query}`;
    this.currentView = 'Pages.Projects.Show';
  }

  @action
  equipmentViewSearch(equipment_id) {
    let query = qs.stringify(this.equipmentShowSearch, { encode: false });
    this.currentPath = `/dashboard/equipments/${equipment_id}/show?${query}`;
    this.currentView = 'Pages.Equipments.Show';
    
  }

  @action
  userInvitation(params) {
    this.currentPath = `/users/invitation/${params.token}`;
    this.currentView = 'Pages.Invitation';
  }

  @action
  invitationAccepted() {
    this.currentPath = `/invitation-accepted/download`;
    this.currentView = 'Pages.InvitationAccepted';
  }

  @action
  formViewSearch(client_id) {
    let query = qs.stringify(this.formShowSearch, { encode: false });
    this.currentPath = `/dashboard/forms/${client_id}?${query}`;
    this.currentView = 'Pages.Forms.Show';
  }

  @action
  performLogin(user) {
    const promise = Api.post('/auth', { user: user })

    const onSuccess = (response) => {
      user = response.data.data;
      this.currentUser = user;
      Lockr.set('currentUser', user);
      Lockr.set('currentTenant', response.data.included[0].attributes);
      if(window.$crisp && user.attributes){
        window.$crisp.push(["set", "user:email", user.attributes.email]);
        window.$crisp.push(["set", "user:nickname", user.attributes.full_name]);
        window.$crisp.push(["set", "user:phone", user.attributes.phone]);
      }
      if(process.env.NODE_ENV === 'production' && user.attributes.email && window.configure){
        window.configure({
          payload: {
            person: {
              id: user.id,
              email: user.attributes.email
            },
          }
        });
      }
      this.dashboardSearch.view = user.attributes.dispatch_board_default_view;
      this.dashboard();
    }

    const onError = (error) => {
      
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  reloadUser() {
    const promise = Api.post('/auth/me');

    const onSuccess = (response) => {
      let user = response.data.data;
      this.currentUser = user;
      Lockr.set('currentUser', user);
      if(window.$crisp && user.attributes){
        window.$crisp.push(["set", "user:email", user.attributes.email]);
        window.$crisp.push(["set", "user:nickname", user.attributes.full_name]);
        window.$crisp.push(["set", "user:phone", user.attributes.phone]);
      }
      if(process.env.NODE_ENV === 'production'){
        window.configure({
          payload: {
            person: {
              id: user.id,
              email: user.attributes.email
            },
          }
        });
      }
    }

    const onError = (error) => {
      
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  performLogout() {
    this.redirectToLoginScreen()
  }

  @action
  redirectToLoginScreen() {
    this.currentPath = '/login';
    this.currentView = 'Login';
    this.currentUser = null;
    Lockr.flush()
  }

  @action
  login() {
    this.currentPath = '/login';
    this.currentView = 'Login';
  }

  @action
  showPublicBooking(params) {
    stores.preferenceStore.tenant_id = params.tenant_id;
    stores.bookingStore.tenant_id = params.tenant_id;
    stores.bookingStore.service_id = params.service_id;
    this.currentPath = `/booking_public/${params.tenant_id}/bookings/${params.service_id}/show`;
    this.currentView = 'BookingShowPublicPage';
  }

  @action
  newPublicBooking(params) {
    stores.preferenceStore.tenant_id = params.tenant_id;
    stores.bookingStore.tenant_id = params.tenant_id;
    this.currentPath = `/booking_public/${params.tenant_id}/bookings/new`;
    this.currentView = 'BookingNewPublicPage';
  }

  @action
  newPublicModalBooking(params) {
    stores.preferenceStore.tenant_id = params.tenant_id;
    stores.bookingStore.tenant_id = params.tenant_id;
    this.currentPath = `/booking_public/${params.tenant_id}/bookings/booking-modal`;
    this.currentView = 'BookingNewPublicModalPage';
  }

  @action
  bookingConfirmationMessage(params) {
    this.currentPath = `/booking-confirmation`;
    this.currentView = 'BookingConfirmationMessage';
  }

  quickbooksAuth() {
    this.currentPath = '/quickbooks/auth';
    this.currentView = 'QuickBooks';
    let promise = Api.post(
      '/quickbooks/authentications'
    );

    promise
      .then((response) => {
        // debugger;
      })
      .catch((error) => {
      })
  }

  @action
  signup() {
    this.currentPath = '/signup';
    this.currentView = 'Signup';
  }

  @action
  signupCompany() {
    this.currentPath = '/signup/company';
    this.currentView = 'SignupCompany';
  }

  @action
  signupCompanyType() {
    this.currentPath = '/signup/company/type';
    this.currentView = 'SignupCompanyType';
  }

  @action
  signupCompanyTeamSize() {
    this.currentPath = '/signup/company/team';
    this.currentView = 'SignupCompanyTeamSize';
  }

  @action
  signupDetails() {
    this.currentPath = '/signup/details';
    this.currentView = 'SignupDetails';
  }

  @action
  inviteUsers() {
    this.currentPath = '/invite/users';
    this.currentView = 'Pages.Users.Invite';
  }

  @action
  forgetPassword() {
    this.currentPath = '/forget-password';
    this.currentView = 'ForgotPassword';
  }

  @action
  loadCompanyDetails() {
    this.currentPath = '/preferences/company_details';
    this.currentView = 'CompanyDetails';
  }

  @action
  loadBookingConfig() {
    this.currentPath = '/dashboard/bookings/bookingConfiguration';
    this.currentView = 'BookingConfiguration';
  }

  @action
  loadBooking() { 
    this.currentPath = '/preferences/booking';
    this.currentView = 'Booking';
  }

  @action
  bookingShow(params,data) {
    let name = '';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.bookingSearch, { encode: false });
    this.currentPath = `/dashboard/bookings/${params.uuid}/show`;
    this.currentView = 'Pages.Bookings.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/bookings?${query}`, text: "Booking Requests"},
      { href: `/dashboard/bookings/${params.uuid}/show`, text: name}
    ];
  }

  @action
  loadBookings() {
    this.currentPath = '/preferences/bookings';
    this.currentView = 'Bookings';
  }

  @action
  payInvoice(params) {
    stores.paymentStore.tenant_id = params.tenant_id;
    stores.paymentStore.invoice_id = params.invoice_id;
    this.currentPath = `/pay/${params.tenant_id}/invoices/${params.invoice_id}`;
    this.currentView = 'PayInvoice';
  }

  @action
  reviewQuote(params) {
    stores.quoteStore.tenant_id = params.tenant_id;
    stores.quoteStore.work_report_id = params.work_report_id;
    this.currentPath = `/review/${params.tenant_id}/quote/${params.work_report_id}`;
    this.currentView = 'ReviewQuote';
  }
  

  @action
  formSubmission(params) {
    stores.formSubmissionStore.tenant_id = params.tenant_id;
    stores.formSubmissionStore.form_submission_id = params.form_submission_id;
    stores.formSubmissionStore.token = params.token;
    this.currentPath = `/forms/${params.tenant_id}/form_submission/${params.form_submission_id}/${params.token}`;
    this.currentView = 'FormSubmission';
  }

  @action
  resetPassword(params) {
    this.resetToken = params.token;
    this.currentPath = `/password-reset/${params.token}`;
    this.currentView = 'ResetPassword';
  }

  @action
  invalidResetPasswordToken() {
    this.currentPath = '/password/expired';
    this.currentView = 'InvalidResetPasswordToken';
  }

  @action
  performPasswordReset(user) {
    const promise = Api.post('/password/password_reset', { user: user })

    const onSuccess = (response) => {
      this.login()
    }

    const onError = (error) => {
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  performForgetPassword(user) {
    const promise = Api.post('/password/generate_reset_code', { user: user })

    const onSuccess = (response) => {
      this.login()
    }

    const onError = (error) => {
      
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  loadUsers() {
    let section = this.userSearch.section;
    let promise = Api.post(
      '/users/search',
      { role: section }
    );

    promise
      .then((response) => {
        this.users.objects = response.data.data;
      })
      .catch((error) => {
      })
  }

  @action
  userShow(params) {
    let query = qs.stringify(this.userShowSearch, { encode: false });
    this.currentPath = `/dashboard/users/${params.uuid}/show?${query}`;
    this.currentView = 'Pages.Users.Show';
    this.breadCrumbs = [
      { href: `/dashboard/users/all`, text: "Users"},
      { href: `/dashboard/users/${params.uuid}/show`, text: '' }
    ];
  }

  @action
  projectShow(params,data) {
    let name = '';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.projectShowSearch, { encode: false });
    this.currentPath = `/dashboard/projects/${params.uuid}/show`;
    this.currentView = 'Pages.Projects.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/projects?${query}`, text: "Projects"},
      { href: `/dashboard/projects/${params.uuid}`, text: name}
    ];
  }

  @action
  pricingParameterShow(params, data) {
    let name = '';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.pricingParameterSearch, { encode: false });
    this.currentPath = `/dashboard/pricing-parameter/${params.uuid}/show`;
    this.currentView = 'Pages.PricingParameter.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/pricing-parameter?${query}`, text: "Pricing Parameter"},
      { href: `/dashboard/pricing-parameter/${params.uuid}/show`, text: name}
    ];
  }

  @action
  questionShow(params,data) {
    let name = '';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.questionSearch, { encode: false });
    this.currentPath = `/dashboard/question/${params.uuid}/show`;
    this.currentView = 'Pages.Question.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/question`, text: "Questions"},
      { href: `/dashboard/question/${params.uuid}/show`, text: name}
    ];
  }

  @action
  questionGroupShow(params,data) {
    let name = '';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.questionGroupSearch, { encode: false });
    this.currentPath = `/dashboard/question-group/${params.uuid}/show`;
    this.currentView = 'Pages.QuestionGroup.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/question-group?${query}`, text: "Question Groups"},
      { href: `/dashboard/question-group/${params.uuid}/show`, text: name}
    ];
  }


  @action
  serviceTypeExtraShow(params,data) {
    let name = '';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.serviceTypeExtraSearch, { encode: false });
    this.currentPath = `/dashboard/service-type-extra/${params.uuid}/show`;
    this.currentView = 'Pages.ServiceTypeExtra.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/service-type-extra?${query}`, text: "Service Type Extra"},
      { href: `/dashboard/service-type-extra/${params.uuid}/show`, text: name}
    ];
  }

  @action
  frequencyShow(params,data) {
    let name = '';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.frequencySearch, { encode: false });
    this.currentPath = `/dashboard/frequency/${params.uuid}/show`;
    this.currentView = 'Pages.Frequency.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/frequency?${query}`, text: "Frequency"},
      { href: `/dashboard/frequency/${params.uuid}/show`, text: name}
    ];
  }

  @action
  frequencyAdd(params,data) {
    let name = 'Add';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.frequencySearch, { encode: false });
    this.currentPath = `/dashboard/frequency/add`;
    this.currentView = 'Pages.Frequency.Add';
    this.breadCrumbs = [ 
      { href: `/dashboard/frequency`, text: "Frequency"},
      { href: `/dashboard/frequency/add`, text: name}
    ];
  }

  @action
  spotAdd(params,data) {
    let name = 'Add';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.spotSearch, { encode: false });
    this.currentPath = `/spots/add`;
    this.currentView = 'Pages.Spot.Add';
    this.breadCrumbs = [ 
      { href: `/spots`, text: "Booking Spots"},
      { href: `/spots/add`, text: name}
    ];
  }

  @action
  serviceTypeExtraAdd(params,data) {
    let name = 'Add';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.serviceTypeExtraSearch, { encode: false });
    this.currentPath = `/dashboard/service-type-extra/add`;
    this.currentView = 'Pages.ServiceTypeExtra.Add';
    this.breadCrumbs = [ 
      { href: `/dashboard/service-type-extra?${query}`, text: "Service Type Extra"},
      { href: `/dashboard/service-type-extra/add`, text: name}
    ];
  }

  @action
  pricingParameterAdd(params,data) {
    let name = 'Add';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.pricingParameterSearch, { encode: false });
    this.currentPath = `/dashboard/pricing-parameter/add`;
    this.currentView = 'Pages.PricingParameter.Add';
    this.breadCrumbs = [ 
      { href: `/dashboard/pricing-parameter?${query}`, text: "Pricing Parameter"},
      { href: `/dashboard/pricing-parameter/add`, text: name}
    ];
  }

  @action
  questionAdd(params,data) {
    let name = 'Add';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.questionSearch, { encode: false });
    this.currentPath = `/dashboard/question/add`;
    this.currentView = 'Pages.Question.Add';
    this.breadCrumbs = [ 
      { href: `/dashboard/question?${query}`, text: "Question"},
      { href: `/dashboard/question/add`, text: name}
    ];
  }

  @action
  questionGroupAdd(params,data) {
    let name = 'Add';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.questionGroupSearch, { encode: false });
    this.currentPath = `/dashboard/question-group/add`;
    this.currentView = 'Pages.QuestionGroup.Add';
    this.breadCrumbs = [ 
      { href: `/dashboard/question-group?${query}`, text: "Question Groups"},
      { href: `/dashboard/question-group/add`, text: name}
    ];
  }

  @action
  serviceTypeShow(params,data) {
    let name = '';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.serviceTypeSearch, { encode: false });
    this.currentPath = `/dashboard/service-type/${params.uuid}/show`;
    this.currentView = 'Pages.ServiceType.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/service-type?${query}`, text: "Service Type"},
      { href: `/dashboard/service-type/${params.uuid}/show`, text: name}
    ];
  }

  @action
  equipmentShow(params,data) {
    let name = '';
    if(data) {
      name = data;
    }
    let query = qs.stringify(this.equipmentShowSearch, { encode: false });
    this.currentPath = `/dashboard/equipments/${params.uuid}/show?${query}`;
    this.currentView = 'Pages.Equipments.Show';
    this.breadCrumbs = [ 
      { href: `/dashboard/equipments?${query}`, text: "Equipment"},
      { href: `/dashboard/equipments/${params.uuid}/show`, text: name}
    ];
  }

  @action
  deleteUser(user) {
    let promise = Api.delete(`/users/${user.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadUsers();
      })
      .catch((error) => {
      })
    
    return promise
  }

  @action
  loadBillingOverview() {
    let query = qs.stringify(this.billingInfo, { encode: false });
    this.currentPath = `/billing?${query}`;
    this.currentView = 'Pages.Billing.Index';
    this.breadCrumbs = [      
      { href: `/billing`, text: "Billing"}
    ];
  }

  @action
  loadTaxes() {
    this.currentPath = `/taxes`;
    this.currentView = 'Pages.Taxes.Index';
    this.breadCrumbs = [      
      { href: `/taxes`, text: "Taxes"}
    ];
  }

  @action
  loadPaymentMethods() {
    this.currentPath = `/payment_methods`;
    this.currentView = 'Pages.PaymentMethod.Index';
    this.breadCrumbs = [      
      { href: `/payment_methods`, text: "PaymentMethods"}
    ];
  }

  @action
  loadProductsServices() {
    this.currentPath = `/products_services`;
    this.currentView = 'Pages.ProductsServices.Index';
    this.breadCrumbs = [      
      { href: `/products_services`, text: "Pricebook"}
    ];
  }

  @action
  loadProductCategory() {
    this.currentPath = `/product_category`;
    this.currentView = 'Pages.ProductCategory.Index';
    this.breadCrumbs = [      
      { href: `/product_category`, text: "Products Category"}
    ];
  }

  @action
  loadServiceTypeCategory() {
    this.currentPath = `/service_type_category`;
    this.currentView = 'Pages.ServiceTypeCategory.Index';
    this.breadCrumbs = [      
      { href: `/service_type_category`, text: "Service Type Category"}
    ];
  }

  @action
  loadPreferences() {
    this.currentPath = `/preferences`;
    this.currentView = 'Pages.Preferences.Index';
     this.breadCrumbs = [      
      { href: `/preferences`, text: "Payment Settings"}
    ];
  }

  @action
  loadDefaultLineItems() {
    this.currentPath = `/preferences/default_line_items`;
    this.currentView = 'Pages.Preferences.DefaultLineItems';
    this.breadCrumbs = [      
      { href: `/preferences/default_line_items`, text: "Default Line Items"}
    ];
  }

  @action
  invoiceSettings() {
    this.currentPath = `/preferences/invoice_settings`;
    this.currentView = 'Pages.Preferences.InvoiceSettings';
    this.breadCrumbs = [      
      { href: `/preferences/invoice_settings`, text: "Invoice Settings"}
    ];
  }

  @action
  accountingSetup() {
    this.currentPath = `/preferences/accounting_setup`;
    this.currentView = 'Pages.Preferences.AccountingSetup';
    this.breadCrumbs = [      
      { href: `/preferences/accounting_setup`, text: "Accounting Setup"}
    ];
  }

  @action
  companyDetails() {
    this.currentPath = `/preferences/company_details`;
    this.currentView = 'Pages.Preferences.CompanyDetails';
    this.breadCrumbs = [      
      { href: `/preferences/company_details`, text: "Company Details"}
    ];
  }

  @action
  bookingConfigurations() {
    let query = qs.stringify(this.bookingSearch, { encode: false });
    this.currentPath = `/dashboard/bookings/booking_configuration`;
    this.currentView = 'Pages.Bookings.BookingConfiguration';
    this.breadCrumbs = [      
      { href: `/dashboard/bookings/booking_configuration`, text: "Booking Configuration"}
    ];
  }

  @action
  newBooking() {
    this.currentPath = `/dashboard/bookings/new`;
    this.currentView = 'Pages.Bookings.New';
    this.breadCrumbs = [      
      { href: `/dashboard/bookings`, text: "Booking Requests"}
    ];
  }


  @action
  bookings() {    
    let query = qs.stringify(this.bookingSearch, { encode: false });
    this.currentPath = `/dashboard/bookings?${query}`;
    this.currentView = 'Pages.Bookings.Index';
    this.breadCrumbs = [      
      { href: `/dasdboard/bookings?${query}`, text: "Booking Requests"}
    ];
  }

  @action
  loadForms() {
    this.currentPath = `/dashboard/forms`;
    this.currentView = 'Pages.Forms.Index';
  }

  @action
  notifications() {
    let query = qs.stringify(this.notificationSearch, { encode: false });
    this.currentPath = `/dashboard/notifications?${query}`;
    this.currentView = 'Pages.Notifications.Index';
    this.breadCrumbs = [
      { href: `/dashboard/notifications?${query}`, text: "Notifications Center" },
    ];
  }

  @action
  performPasswordChange(user, user_id) {
    this.performingChangePassword = true;
    const promise = Api.post(`/users/${user_id}/update_password`, {user: user})

    const onSuccess = (response) => {
      this.performingChangePassword = false;
      this.performLogout()
    }

    const onError = (error) => {
      this.performingChangePassword = false;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

}

export default ViewStore;
