import React from 'react'
import { MenuItem, Menu, Intent } from '@blueprintjs/core';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import Select from 'react-select';

let option = [
  {label: '1-5 Members - Small team',value: '1-5'},
  {label: '5-25 Members - Medium sized team',value: '5-25'},
  {label: '25-50 Members - Large team',value: '25-50'}
];
@inject('preferenceStore','viewStore','store') @observer
class TeamSize extends React.Component {

  state = {
    selectedOption: null
  };

  componentDidMount() {
    let {
      preferenceStore,
      preferenceStore: {
        tenant: {
          object
        }    
      }
    } = this.props;
    preferenceStore.loadTenant(); 
  }

  handleChange = selectedOption => {
    this.setState(
      { selectedOption }
    ); 
    let {
      preferenceStore: {
        tenant: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props; 
    if (selectedOption.value) {      
      object.attributes.team_size = selectedOption.value;
    }  
  };

  render() {  
    let {
      preferenceStore: {
        tenant: {
          isLoading,
          isError,
          object
        }     
      }
    } = this.props;  
    let { selectedOption } = this.state;
   
    return (
      <div>
        { object &&        
          <Select
            options={option} 
            value={{label: object.attributes.team_size, value: object.attributes.team_size}}                                                                   
            onChange={this.handleChange}        
            isSearchable={true}
            defaultValue={{label: object.attributes.team_size, value: object.attributes.team_size}}
            isClearable={false}
            placeholder={"Select Team Size"}        
          />
        }
      </div>
    )
  }
}



export default TeamSize;