import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PaymentHeader from './Header';
// import PaymentCreditCard from './CreditCard';
// import PaymentInvoiceDownload from './PDFDownload';
import PaymentInvoiceBody from './InvoiceBody';
import PaymentFooter from './PaymentFooter';

@inject('paymentStore') @observer
class InvoicePayment extends Component {

  render() {
    return(
      <React.Fragment>
        <PaymentHeader />
        {/* {due_amount > 0 && <PaymentCreditCard />} */}
        {/* <PaymentInvoiceDownload /> */}
        <PaymentInvoiceBody />
        <PaymentFooter />
      </React.Fragment>
    )
  }

};

export default InvoicePayment;