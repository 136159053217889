import axios from 'axios';

window.requests = {};

const MobileApps = {
  ios: {
    storeUrl: 'https://apps.apple.com/bm/app/fieldnexus/id1472915965#?platform=iphone'
  },
  android: {
    storeUrl: 'https://play.google.com/store/apps/details?id=app.fieldnexus'
  }
}

const Config = {
  development: {
    scheme: 'http://',
    host: 'localhost',
    port: ':8900',
    namespace: '/api/v2',
    baseURL: function() {
      return [this.domain(), this.namespace].join('');
    },
    domain: function() {
      return [this.scheme, this.host, this.port].join('');
    },
    appUrl: "http://localhost:3000"
  },
  developmentOnline: {
    scheme: 'http://',
    host: 'c927-103-18-120-63.ngrok.io',
    port: '',
    namespace: '/api/v2',
    baseURL: function() {
      return [this.domain(), this.namespace].join('');
    },
    domain: function() {
      return [this.scheme, this.host, this.port].join('');
    },
    appUrl: "http://localhost:3000"
  },
  production: {
    scheme: 'https://',
    host: 'api.fieldnexus.com',
    port: '',
    namespace: '/api/v2',
    baseURL: function() {
      return [this.domain(), this.namespace].join('');
    },
    domain: function() {
      return [this.scheme, this.host, this.port].join('');
    },
    appUrl: "https://app.fieldnexus.com"
  },
  staging: {
    scheme: 'https://',
    host: 'api-staging.fieldnexus.com',
    port: '',
    namespace: '/api/v2',
    baseURL: function() {
      return [this.domain(), this.namespace].join('');
    },
    domain: function() {
      return [this.scheme, this.host, this.port].join('');
    },
    appUrl: "https://app.fieldnexus.com"
  }
};

// const CurrentEnv = Config[process.env.NODE_ENV];
// const CurrentEnv = Config['development'];
//const CurrentEnv = Config['developmentOnline'];
const CurrentEnv = Config['production'];
// const CurrentEnv = Config['staging'];

var instance = axios.create({
  baseURL: CurrentEnv.baseURL(),
  timeout: 88000,
  headers: { 'X-Client-Identifier': 'React-Web' }
});


// hide crisp chat for mobile
let url_string = window.location.href;
let url = new URL(url_string);
let hide_crisp = url.searchParams.get("hide_crisp");
if(hide_crisp){
  window.$crisp.push(['do', 'chat:hide']);
}  

instance.setToken = user => {
  instance.defaults.headers.common['Accept'] = 'application/json';
  instance.defaults.headers.common['X-Api-Token'] = user.attributes.api_token;
  instance.defaults.headers.common['X-Tenant'] = user.attributes.tenant_id;
  instance.defaults.headers.common['X-Client-Identifier'] = 'React-Web';
};

instance.getToken = () => {
  return instance.defaults.headers.common['Api-Token'];
};

instance.getDomain = () => {
  return CurrentEnv.domain();
};

instance.interceptors.request.use(
  (config) => {
    var source = axios.CancelToken.source();
    config.cancelToken = source.token;
    if (window.requests[config.url] && JSON.stringify(window.requests[config.url].data) === JSON.stringify(config.data)) {
      let inFlightSource = window.requests[config.url].source;
      inFlightSource.cancel();
    }
    window.requests[config.url] = {
      source: source,
      data: config.data
    };
    return config;
  }, 
  (error) => {
    return Promise.reject(error);
  }
)

export {
  instance as Api,
  CurrentEnv,
  MobileApps
}
