import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import Page from 'components/Page';
import ClientProfileCard from 'components/Client/ProfileCard';
import styled from '@emotion/styled';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 5px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);  
`

@inject('store', 'clientStore', 'viewStore') @observer
class ClientsShowPage extends Component {

  get canRender() {
    let {
      clientStore: {
        client: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (object && object.id);
  }

  componentWillUnmount() {
    let {
      clientStore
    } = this.props;
    clientStore.clearSelectedClient();
  }

  updateSelectedResource = (resource) => {
    let {
      viewStore,
      clientStore: {
        client: {
          object
        }
      }
    } = this.props;
    viewStore.clientShowSearch.resource = resource;
    viewStore.clientViewSearch(object.attributes.id);
  }

  render() {
    let content, title;
    let {
      viewStore,
      clientStore: {
        client: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError && object && !object.id) {
      title = 'Loading..';
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading && object && !object.id) {
      title = 'Failed to Load Customer';
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      title = `${object.attributes.name} | Field Nexus`;
      content = <ClientProfileCard 
        client={object}
        selectedResource={viewStore.clientShowSearch.resource}
        onResourceChange={this.updateSelectedResource.bind(this)} 
      />
    }
    return (
      <div>
        {
          <Helmet>
            <title>{title}</title>
          </Helmet>
        }
        <PageWrapGray>
        <Page>
          {content}
        </Page>
        </PageWrapGray>
      </div>
    )
  }

};

export default ClientsShowPage;