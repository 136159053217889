import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup, Button, Dialog, Classes, Intent, TextArea
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';

@inject('productCategoryStore', 'quickbooksStore') @observer
class ProductCategory extends Component {

  componentDidMount() {
    //this.props.quickbooksStore.loadQuickbooksItems()
  }

  handleCloseDailog() {
    // clearing object
    this.props.productCategoryStore.productCategory.object = {}
    this.props.onClose()
  }

  createProductCategory() {
    let { 
      productCategoryStore,
      productCategoryStore:{
        productCategory: {
          object
        }
      }
     } = this.props;

    if(object.title === "" || object.title === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter title!', intent: Intent.DANGER })
      return;
    }
    productCategoryStore.create()
      .then(this.onSuccessfullyCreateProductCategory.bind(this))
      .catch(this.onFailCreateProductCategory.bind(this))
  }

  onSuccessfullyCreateProductCategory(response) {
    this.props.onSuccess()
    Toast.show({ message: "Product category created successfully.", intent: Intent.SUCCESS })
  }

  onFailCreateProductCategory(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ message: "Something went wrong. Failed to create/update product category.", intent: Intent.DANGER })
    }
  }

  updateProductCategory() {
    let { 
      productCategoryStore,
      productCategoryStore:{
        productCategory: {
          object
        }
      }
     } = this.props;
     
    if(object.title === "" || object.title === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter title!', intent: Intent.DANGER })
      return;
    }
    productCategoryStore.update()
      .then(this.onSuccessfullyUpdateProductCategory.bind(this))
      .catch(this.onFailCreateProductCategory.bind(this))
  }

  onSuccessfullyUpdateProductCategory(response) {
    this.props.onSuccess()
    Toast.show({ message: "Product category updated successfully.", intent: Intent.SUCCESS })
  }

  render() {
    let {
      title,
      edit,
      productCategoryStore: {
        productCategory: {
          isLoading,
          object
        }
      },
      quickbooksStore: {
        quickbooksItems: {
          objects
        }
      }
    } = this.props;
    return(
      <Dialog
        title={title}
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Title"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Title'
              defaultValue={object.title}
              onChange={(event) => { object.title = event.target.value } } 
            />
          </FormGroup>
          <FormGroup
            label="Description"
            labelFor="text-input"
            labelInfo=""
          >
            <TextArea
              type="text"
              className="bp3-input bp3-fill"
              placeholder="Description"
              defaultValue={object.description}
              onChange={(event) => { object.description = event.target.value } } 
            />
            
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseDailog.bind(this)}>Close</Button>
            {!edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.createProductCategory.bind(this)}>
              Save
            </Button>}
            {edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.updateProductCategory.bind(this)}>
              Update
            </Button>}
          </div>
        </div>
      </Dialog>
    )
  }

}

export default ProductCategory;