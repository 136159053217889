import React from 'react'
import { Button, Spinner, Menu, MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Api } from 'services/Api';
import axios from 'axios';
import debounce from 'services/debounce';

const WorkRenderer = (work, { handleClick, modifiers }) => {
  return (
    <MenuItem
      active={modifiers.active}
      key={work.id}
      label={work.attributes.number}
      onClick={handleClick}
      text={work.attributes.number}
    />
  );
};

const ListRenderer = ({ items, itemsParentRef, query, renderItem, loading, failed }) => {
  const renderedItems = items.map(renderItem).filter(item => item != null);
  return (
    <Menu ulRef={itemsParentRef}>
      {
        failed && !loading && <MenuItem disabled={true} text={'Failed to load results :('} />
      }
      {
        loading && !failed && <MenuItem disabled={true} text={'Loading..'} label={<Spinner size={20}/>} />
      }
      {
        !loading && !failed && <MenuItem
                      disabled={true}
                      text={`Found ${renderedItems.length} work report matching "${query}"`}
                    />
      }
      {renderedItems}
    </Menu>
  );
}

@observer
class WorkReportAutocomplete extends React.Component {

  @observable loading = true;
  @observable failed = false;
  @observable results = [];
  @observable selectedWork = null;

  componentDidUpdate(){
    if(this.props.value){
      this.selectedWork = this.props.value;
    }
  }

  componentDidMount() {
   
    let {
      selectedWorkId
    } = this.props;
    selectedWorkId = selectedWorkId;
     
    if (selectedWorkId) {
      let workReportSearch = {
        where: {
          client_id: '',
          location_id:'',
          id:selectedWorkId,
          kind: 'WorkReport'
        },
        order: { created_at: 'desc' },
        per_page: 25,
        page: 1
      }

      let promise = Api.post('/work_reports/search', { search:workReportSearch})
      promise.then(response => {
        this.selectedWork = response.data.data[0];
      })
    }
    if(this.props.value){
      this.selectedWork = this.props.value;
    }
  }

  @action
  onQueryChange(query) {
    this.failed = false;
    this.loading = true;
    if (query === '') {
      return;
    }
    let workReportSearch = {
      where: {
        client_id: '',
        location_id:'',
        number:query,
        kind: 'WorkReport'
      },
      order: { created_at: 'desc' },
      per_page: 25,
      page: 1
    }

    
    let promise = Api.post('/work_reports/search', { search:workReportSearch})
    promise
      .then(this.onWorksLoad.bind(this))
      .catch(this.onWorksLoadFail.bind(this))
    return promise;
  }

  @action
  onWorksLoadFail(error) {
    if (axios.isCancel(error)) {
      this.loading = true;
    } else {
      this.loading = false;
      this.failed = true;
    }
  }

  @action
  onWorksLoad(response) {
    this.loading = false;
    this.failed = false;
    this.results = response.data.data;
  }

  @action
  onWorkReportSelect(work) {
    this.selectedWork = work;
    this.props.onWorkReportSelect(work);
  }

  @action
  clearSelectedWork() {
    this.selectedWork = null;
    this.props.onWorkReportSelect(null);
  }

  render() {
    let {
      failed,
      results,
      onQueryChange,
      loading,
      onWorkReportSelect,
      selectedWork,
      clearSelectedWork,
      props: {
        inputProps
      }
    } = this;
   
    return(
      <div>
        {
          selectedWork ? <Button 
              text={this.selectedWork.attributes.number} 
              rightIcon='cross'
              onClick={clearSelectedWork.bind(this)}
            /> : <Suggest
              inputProps={{
                icon: 'cross',
                placeholder: 'Search work report...',
                ...inputProps
              }}
              closeOnSelect={true}
              openOnKeyDown={true}
              resetOnQuery={true}
              resetOnSelect={true}
              popoverProps={{ minimal: true }}
              onQueryChange={debounce(onQueryChange.bind(this)).bind(this)}
              items={toJS(results)}
              itemRenderer={WorkRenderer}
              itemListRenderer={(props) => <ListRenderer {...props} loading={loading} failed={failed} />}
              inputValueRenderer={(work) => work.attributes.number }
              onItemSelect={onWorkReportSelect.bind(this)}
            />
        }
      </div>
    )
  }
}

WorkReportAutocomplete.propTypes = {
  inputProps: PropTypes.object,
  onWorkReportSelect: PropTypes.func.isRequired
}

export default WorkReportAutocomplete;