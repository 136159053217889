import React from 'react'
import { MenuItem, Menu, Intent } from '@blueprintjs/core';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const selectAllId = "selectAll";

@observer
class ListPicker extends React.Component {

  @observable selectedOptions = [];
  @observable options = [];

  componentDidMount() {
    if (!Array.isArray(this.props.selectedOptions)) {
      this.selectedOptions = Array.of(this.props.selectedOptions)
    } else {
      this.selectedOptions = this.props.selectedOptions;
    }
    this.options = this.props.options;
    let _component = this;
    this.options = this.options.map((option) => {
      if (_component.selectedOptions.includes(option.value)) {
        return { ...option, selected: true };
      } else {
        const object = _component.selectedOptions.find((selectedOption) => {
          return selectedOption && selectedOption.value === option.value;
        });
        if (object && object !== null) {
          return { ...object, selected: true };
        } else {
          return { ...option, selected: false };
        }
      }
    })
  }

  onClick(option, index) {
    const {
      props: {
        maximumSelections,
        onChange
      }
    } = this;
    this.options[index].selected = !this.options[index].selected;
    let allOptionItems = toJS(this.options);
    let selectedItems = allOptionItems.filter((e) => e.selected === true);
    if (!maximumSelections || (selectedItems.length <= maximumSelections)) {
      onChange(selectedItems.filter((e) => e.selected === true),this.options[index]);
    }else{
      let selectedSingleOption = [];
      allOptionItems.map(function(item,newIndex){
          if(newIndex === index){
            item.selected = true;
            selectedSingleOption.push(item);
          } else {
             item.selected = false;
             selectedSingleOption.push(item);
          } 
      });
      this.options = selectedSingleOption;
      onChange(selectedSingleOption.filter((e) => e.selected === true),this.options[index]);
    }
  }

  isSelected(option) {
    return option.selected ? 'tick' : null;
  }

  render() {
    const {
      options,
      props: {
        shouldDismissPopover
      }
    } = this;
    return (
      <Menu>
        {
          options.map((option, index) => {
            return (
              <div>
                {shouldDismissPopover === false && (<MenuItem
                  shouldDismissPopover={false}
                  icon={this.isSelected(option)}
                  text={option.label}
                  intent={option.selected ? Intent.SUCCESS : null}
                  onClick={() => this.onClick(option, index)}
                  key={option.label + Math.random()}
                />)}
                {shouldDismissPopover != false && (<MenuItem
                  shouldDismissPopover={true}
                  icon={this.isSelected(option)}
                  text={option.label}
                  intent={option.selected ? Intent.SUCCESS : null}
                  onClick={() => this.onClick(option, index)}
                  key={option.label + Math.random()}
                />)}
                {option.value === selectAllId && <Menu.Divider />}
              </div>
            )
          })
        }
      </Menu>
    )
  }
}

ListPicker.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  maximumSelections: PropTypes.number,
  shouldDismissPopover: PropTypes.bool,
  selectedOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]).isRequired
}

export default ListPicker;