import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  Button,
  Dialog,
  Classes,
  Intent,
  ControlGroup
} from "@blueprintjs/core";
import moment from 'moment-timezone';
import DateTimeInput from "components/DateTimeInput";
import Toast from "helpers/toaster";
import { observable } from "mobx";
import ClientAutocomplete from "components/ClientAutocomplete";

@inject("projectStore", "quoteStore", "store")
@observer
class AddProjectModal extends Component {
  @observable client_id = null;
  @observable client_name = null;

  componentDidMount() {
    let {
      props: {
        projectStore: {
          project: { object }
        }
      }
    } = this;
    object.start_date = moment();
    object.end_date = moment().add(1, 'hours');
  }

  createProject() {
    let {
      props: {
        projectStore: {
          project: { object }
        }
      }
    } = this;

    let {
      props: {       
        store
      }
    } = this;
   
    if (this.props.client_id) {
      object.client_id = this.props.client_id;
    } else {
      object.client_id = this.client_id;
    }

    if(store.projectDetail.client){
      object.client_id = store.projectDetail.client.id;  
      object.status =  'active';    
    }
    

    if (!object.client_id) {
      Toast.show({
        icon: "warning-sign",
        message: "Client must be selected.",
        intent: Intent.DANGER
      });
      return;
    }

    if (!object.name) {
      Toast.show({
        icon: "warning-sign",
        message: "Please enter project name.",
        intent: Intent.DANGER
      });
      return;
    }

    if (!object.status) {
      Toast.show({
        icon: "warning-sign",
        message: "Please select project status.",
        intent: Intent.DANGER
      });
      return;
    }

    this.props.projectStore
      .createProject()
      .then(this.onSuccessfullyCreateProject.bind(this))
      .catch(this.onFailCreateProject.bind(this));
  }

  onSuccessfullyCreateProject(response) {
    this.props.onSuccess(response);
  }

  onFailCreateProject(error) {
    console.log(error);
  }

  handleCloseProjectDailog() {
    this.props.onClose();
  }

  onProjectStatus(selectedOptions) {
    this.projectStatusSelectedOptions = selectedOptions;
    let { object } = this.props.projectStore.project;
    if (selectedOptions.length > 0) {
      object.status = selectedOptions[0].value;
    }
  }

  handleStartDateChange(date) {
    let { object } = this.props.projectStore.project;
    object.start_date = moment(date)
  }

  handleEndDateChange(date) {
    let { object } = this.props.projectStore.project;
    object.end_date = moment(date)
  }

  render() {
    let { object } = this.props.projectStore.project;
    let { client_id } = this.props;
    let {
      props: { store }
    } = this;
    let clientId = "";
    let selectedStatusId = "";

    if (store.projectDetail.client) {
      clientId = store.projectDetail.client.id;
      selectedStatusId = "active";
    }

    return (
      <Dialog
        title="Add New Project"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseProjectDailog.bind(this)}
        hasBackdrop="true"
      >
        <div className={Classes.DIALOG_BODY}>
          {!client_id && (
            <FormGroup
              label="Customer Name"
              labelFor="text-input"
              labelInfo="*"
            >
              <ControlGroup
                fill={true}
                vertical={false}
                style={{ width: "100%" }}
              >
                <ClientAutocomplete
                  style={{ width: "100%" }}
                  selectedClientId={clientId}
                  inputProps={{
                    id: "add-project",
                    placeholder: "Start typing name, phone number or email.."
                  }}
                  onClientSelect={client => {
                    if (client) {
                      this.client_id = client.id;
                    } else {
                      this.client_id = null;
                    }
                  }}
                />
              </ControlGroup>
            </FormGroup>
          )}
          <FormGroup label="Project Name" labelFor="text-input" labelInfo="*">
            <input
              type="text"
              className="bp3-input bp3-fill"
              placeholder="Project Name"
              onChange={event => {
                object.name = event.target.value;
              }}
            />
          </FormGroup>

          <div className="row">
            <div className="col-6">
              <FormGroup label="Start Date" labelFor="text-input" labelInfo="*">
                <DateTimeInput 
                  placeholder={'Start Date'} 
                  defaultValue={object.start_date}
                  onChange={this.handleStartDateChange.bind(this)}
                />
              </FormGroup>
            </div>
            <div className="col-6">
              <FormGroup label="End Date" labelFor="text-input" labelInfo="*">
                <DateTimeInput 
                  placeholder={'End Date'} 
                  defaultValue={object.end_date} 
                  onChange={this.handleEndDateChange.bind(this) } 
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup label="PO Number" labelFor="text-input">
            <input
              type="text"
              className="bp3-input bp3-fill"
              placeholder="PO Number"
              onChange={event => {
                object.po_number = event.target.value;
              }}
            />
          </FormGroup>

          <FormGroup label="Project Status" labelFor="text-input" labelInfo="*">
            <div className="bp3-select bp3-fill">
              <select
                id={"project-status"}
                defaultValue={selectedStatusId}
                onChange={event => {
                  object.status = event.target.value;
                }}
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="in_progress">In Progress</option>
                <option value="completed">Completed</option>
                <option value="invoiced">Invoiced</option>
              </select>
            </div>
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              fill="false"
              onClick={this.handleCloseProjectDailog.bind(this)}
              style={{ width: "100px" }}
            >
              Cancel{" "}
            </Button>
            <Button
              fill="false"
              icon="add"
              intent={Intent.PRIMARY}
              loading={this.props.projectStore.project.isLoading}
              onClick={this.createProject.bind(this)}
              style={{ width: "200px" }}
            >
              Add Project
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddProjectModal;
