import React, { Component } from 'react';
import {
  Button, Spinner, Checkbox, Intent, Divider
} from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import styled from '@emotion/styled';
import SettingsSidebar from '../../pages/preferences/settings-sidebar';
import { CurrentEnv } from '../../services/Api';
import Toast from 'helpers/toaster';
import { observable } from 'mobx';
import TaxModal from 'components/Taxes/TaxModal';

const PreferenceGrid = styled('div')`
  width: 100%;  
`
const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit * 0}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('preferenceStore', 'viewStore', 'userStore', 'quickbooksStore','taxStore','productServiceStore','paymentMethodStore','xeroStore') @observer
class QuickbooksCard extends Component {
  @observable isTaxDialogVisible = false;
  @observable tax;
  @observable selectedAccountCode = '200';

  componentDidMount() {
    let { preference, preferenceStore, userStore, taxStore, productServiceStore, paymentMethodStore, xeroStore } = this.props;
    if (preference.custom_bambora_env === '') {
      preference.custom_bambora_env = "development"
    }
    xeroStore.loadAccountsXero();
    userStore.loadQuickbooks();
    userStore.loadXeros();
    taxStore.loadTaxes();
    productServiceStore.loadProductsServices();
    paymentMethodStore.loadPaymentMethods();
    preferenceStore.loadTenant()
  }

  handleTaxClick(event, tax) {
    switch (event.label) {
      case 'Sync':
        this.tax = tax;
        this.props.taxStore.tax.object = tax.attributes;
        this.isTaxDialogVisible = true;
        this.props.quickbooksStore.loadQuickbooksTaxCodes()
        break;
      default:
    }
  }

  onCloseTaxDialog() {
    this.isTaxDialogVisible = false;
  }

  onSucessUpdateTax() {
    this.isTaxDialogVisible = false;
  }

  handlePreferenceChange(event, key) {
    if (key === "payment_gateway") {
      event.target.checked ? this.props.preference[key] = "QuickBooks" : this.props.preference[key] = event.target.checked
    } else {
      this.props.preference[key] = event.target.value;
    }
  }

  updatePreference() {
    this.props.preferenceStore.updatePreference()
      .then(this.onSuccessfulUpdatePreference.bind(this))
      .catch(this.onFailUpdatePreference.bind(this))
  }

  redirectToXeroAuth = () => {
    let {
      props: {
        viewStore: {
          currentUser: {
            attributes: {
              tenant_id,
              api_token
            }
          }
        }
      }
    } = this;
    window.location.href = `${CurrentEnv.domain()}/auth/xero_redirect/?tenant_id=${tenant_id}&api_token=${api_token}`;
  }

  redirectToAuth = () => {
    let {
      props: {
        viewStore: {
          currentUser: {
            attributes: {
              tenant_id,
              api_token
            }
          }
        }
      }
    } = this;
    window.location.href = `${CurrentEnv.domain()}/auth/quickbooks_redirect/?tenant_id=${tenant_id}&api_token=${api_token}`;
  }

  disconnectQuickbooks() {
    this.props.quickbooksStore.disconnectFromQuickbooks()
      .then(this.onSuccessfulDisconnectQuickbooks.bind(this))
      .catch(this.onFailDisconnectQuickbooks.bind(this))
  }

  disconnectXero() {
    this.props.xeroStore.disconnectFromXero()
      .then(this.onSuccessfulDisconnectXero.bind(this))
      .catch(this.onFailDisconnectXero.bind(this))
  }


  onSuccessfulDisconnectXero(response) {
    this.props.userStore.xeros.data = []
    this.props.userStore.loadXeros()
    Toast.show({ icon: 'thumbs-up', message: 'Disconnected from Xero successfully!', intent: Intent.SUCCESS })
  }

  onFailDisconnectXero(error) {
    if(error && error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Problem in disconnecting from xero.', intent: Intent.DANGER })
    }
  }

  

  onSuccessfulDisconnectQuickbooks(response) {
    this.props.userStore.loadQuickbooks()
    Toast.show({ icon: 'thumbs-up', message: 'Disconnected from QuickBooks successfully!', intent: Intent.SUCCESS })
  }

  onFailDisconnectQuickbooks(error) {
    if(error && error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Problem in disconnecting from quickbooks.', intent: Intent.DANGER })
    }
  }

  updateTanent(event) {
    let {
      preferenceStore,
      preferenceStore: {
        tenant: {
          object
        }
      }
    } = this.props;
    this.props.preferenceStore.tenant.object.attributes.xero_account_code = event.target.value;
    this.selectedAccountCode = event.target.value;
    this.props.preferenceStore.updateTenant(object)
      .then(this.onSuccessfulUpdateTenant.bind(this))
      .catch(this.onFailUpdateTenant.bind(this))

  }

  onSuccessfulUpdateTenant(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Xero account code updated successfully.', intent: Intent.SUCCESS })
  }

  onFailUpdateTenant(error) {
    Toast.show({ icon: 'warning-sign', message: 'Problem in updating Xero account code!', intent: Intent.DANGER })
  }

  render() {
    let { preference } = this.props;
    let {
      props: {
        userStore: {
          quickbooks: {
            data,
            isError,
            isLoading
          },
          xeros
        },
        xeroStore:{
          xeroAccounts
        },
        quickbooksStore: {
          showQuickbooksConnectionLoader,
          showXeroConnectionLoader
        },
        taxStore: {
          taxes: {
            objects
          }
        },
        productServiceStore: {
          productsServices
        },
        paymentMethodStore: {
          paymentMethods
        },
        preferenceStore:{
          tenant
        }
      }
    } = this;

    if(xeroAccounts && xeroAccounts.objects.length > 0){
      console.log(xeroAccounts)
    }

    
    return (
      <PreferenceGrid>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <SettingsSidebar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
              <div className="pmt-gateway-wrap">
                <ul className="list-unstyled gateway-list row">
                  <li className="col-sm-3">
                    <div className="pmg-box bg-white quickbooks_online">
                      <img src="../qb-logo.png" width="250px" />
                    </div>
                  </li>
                  {/* <li className="col-sm-3">
                    <div className="pmg-box bg-white">
                      <img src="../xero_logo.png" width="40%" />
                    </div>
                  </li> */}
                  
                </ul>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {
                    !isLoading &&
                    <div style={{width: '100%', overflow:'hidden'}}>
                      <div className="card position-sticky"> 
                        <div className="card-body">
                          <div className="row">
                            <div className="col-xs-12 col-lg-12">
                              {!isLoading && data && !data.company_name && <div style={{paddingTop: 5}}>
                                <a onClick={() => { this.redirectToAuth() }}><img src="/quickbooks_connect.png" alt="Connect to QuickBooks" /></a>
                              </div>}
                              {!isLoading && data && data.company_name && <div>
                                <Button className="bp3-button bp3-intent-danger" onClick={this.disconnectQuickbooks.bind(this)} loading={showQuickbooksConnectionLoader} >Disconnect from QuickBooks</Button>
                              </div>}
                            </div>
                           
                          </div>
                          <br/>
                          { data && data.company_name && <div>
                            <div className="row">
                              <div className="col-lg-12">
                                <b>Status: <span style={{color: '#2c9f1c'}}>Connected with QuickBooks Online</span></b>
                              </div>
                            </div>
                            <br/>
                            <div className="row">
                              <div className="col-lg-12">
                                <b>Company Name:</b>  {data.company_name}
                              </div>
                            </div>
                            <br/>
                            <div className="row">
                              <div className="col-lg-12">
                                <b>Company Legal Name:</b> {data.legal_name}
                              </div>
                            </div>
                            <br/>
                            <div className="row">
                              <div className="col-lg-12">
                                <b>Address:</b>  {data.company_address && data.company_address.line1}, {data.company_address && data.company_address.city}, {data.company_address && data.company_address.postal_code}
                              </div>
                            </div>
                            <br/>
                            <div className="row">
                              <div className="col-lg-12">
                                <b>Company Email:</b>  {data.email && data.email.address}
                              </div>
                              
                            </div>
                          </div>}
                          {data && isError && <div style={{color: '#E32249'}}>
                            <p><b>Status: QuickBooks Disconnected</b></p>
                            <p>Looks like the QuickBooks Online integration has been disconnected. You will no longer to be able to send invoices directly to your QuickBooks account from FieldNexus.</p>
                          </div>}
                        </div>
                      </div>
                    </div>
                  }

                </div>
                <div className="col-md-6">
                  {/* {
                    !xeros.isLoading &&
                    <div style={{width: '100%', overflow:'hidden'}}>
                      <div className="card position-sticky"> 
                        <div className="card-body">
                          <div className="row">
                            <div className="col-xs-12 col-lg-12">
                              {!xeros.isLoading && xeros.data[0] && !xeros.data[0].Name && <div style={{paddingTop: 5}}>
                                <a onClick={() => { this.redirectToXeroAuth() }}><img src="/xero_icon.svg" width="10%" alt="Connect to Xero" />  <b>Connect to Xero</b></a>
                              </div>}
                              {!xeros.isLoading && xeros.data[0] && xeros.data[0].Name && <div>
                                <Button className="bp3-button bp3-intent-danger" onClick={this.disconnectXero.bind(this)} loading={showXeroConnectionLoader} >Disconnect from Xero</Button>
                              </div>}
                            </div>
                          </div>
                          <br/>
                          { xeros.data[0] && xeros.data[0].Name && <div>
                            <div className="row">
                              <div className="col-lg-12">
                                <b>Status: <span style={{color: '#2c9f1c'}}>Connected with Xero Online</span></b>
                              </div>
                            </div>
                            <br/>
                            <div className="row">
                              <div className="col-lg-12">
                                <b>Company Name:</b>  {xeros.data[0].Name}
                              </div>
                            </div>
                            <br/>
                            <div className="row">
                              <div className="col-lg-12">
                                <b>Company Legal Name:</b>  {xeros.data[0].LegalName}
                              </div>
                            </div>
                            <br/>
                            <div className="row">
                              <div className="col-lg-12">
                                <b> Organisation Type:</b>  {xeros.data[0].OrganisationType && xeros.data[0].OrganisationType}
                              </div>
                            </div>
                            <br/>
                            <div className="row">
                              <div className="col-lg-12">
                                <b>Line Of Business:</b>  {xeros.data[0].LineOfBusiness && xeros.data[0].LineOfBusiness}
                              </div>
                            </div>
                          </div>}
                          {xeros.data && xeros.isError && <div>
                            <div className="row">
                              <div className="col-lg-12">
                                <a onClick={() => { this.redirectToXeroAuth() }}><img src="/xero_icon.svg" width="10%" alt="Connect to Xero" />  <b>Connect to Xero</b></a>
                              </div>
                            </div>
                            <br/>
                            <div style={{color: '#E32249'}}>
                              <p><b>Status: Xero Disconnected</b></p>
                              <p>Looks like the Xero Online integration has been disconnected. You will no longer to be able to send invoices directly to your Xero account from FieldNexus.</p>
                            </div>
                          </div>}
                        </div>
                      </div>
                    </div>
                  } */}

                </div>
                
              </div>
              {/* <Divider/>
              {xeroAccounts && xeroAccounts.objects.length > 0 &&
                <div>
                  <h5>Xero Account Code</h5>
                  <div className="row">
                    <div className="col-md-12">
                      <div style={{width: '100%', overflow:'hidden'}}>
                        <div className="card position-sticky"> 
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="bp3-select bp3-fill">
                                  <select defaultValue={this.selectedAccountCode} onChange={(event) => {this.updateTanent(event)} } value={tenant && tenant.object && tenant.object.attributes && tenant.object.attributes.xero_account_code}>
                                    <option value=''>Select Xero Account Code</option>
                                    {
                                      xeroAccounts && xeroAccounts.objects.length > 0 && xeroAccounts.objects.map((accounts) => {
                                        return(
                                          <option value={accounts.Code} key={accounts.AccountID}>{accounts.Name} - {accounts.Code}</option>
                                        )
                                      })
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <Divider/> */}
              {
                !isLoading &&
                  <div>
                    { data && data.company_name &&
                      <div>
                        <h5>Synced Modules</h5>
                        <React.Fragment>
                          <div className="card rounded-0 table-responsive">
                            <table className="table table-hover" style={{ width: '100%'}}>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Count</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                              <tbody>
                                { objects && objects.length >0 &&
                                  <tr>
                                    <td width="20%"><strong>Taxes</strong></td>
                                    <td>{`${objects[0].attributes.tax_counts.link_tax_count} / ${objects[0].attributes.tax_counts.total_tax_count}`}</td>
                                    <td>
                                      { objects[0].attributes.tax_counts.link_tax_count < objects[0].attributes.tax_counts.total_tax_count ?
                                        <a href={`/taxes`} >Sync</a>
                                        :''
                                      }
                                    </td>
                                  </tr>
                                }
                                { productsServices.objects && productsServices.objects.length >0 &&
                                  <tr>
                                    <td width="20%"><strong>Product/Services</strong></td>
                                    <td>{`${productsServices.objects[0].attributes.product_service_counts.link_product_count} / ${productsServices.objects[0].attributes.product_service_counts.total_product_count}`}</td>
                                    <td>
                                      { productsServices.objects[0].attributes.product_service_counts.link_product_count < productsServices.objects[0].attributes.product_service_counts.total_product_count ?
                                        <a href={`/products_services`} >Sync</a>
                                        :''
                                      }
                                    </td>
                                  </tr>
                                }
                                { paymentMethods && paymentMethods.objects && paymentMethods.objects.length >0 && 
                                  <tr>
                                    <td width="20%"><strong>Payment Methods</strong></td>
                                    <td>{`${paymentMethods.objects[0].attributes.payment_method_counts.link_payment_method_count } / ${paymentMethods.objects[0].attributes.payment_method_counts.total_payment_method_count}`}</td>
                                    <td>
                                      { paymentMethods.objects[0].attributes.payment_method_counts.link_payment_method_count < paymentMethods.objects[0].attributes.payment_method_counts.total_payment_method_count ?
                                        <a href={`/payment_methods`} >Sync</a>
                                        :''
                                      }
                                    </td>
                                  </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                        </React.Fragment>
                      </div>
                    } 
                  </div>
                }
              
                {
                  isLoading &&
                    <Spinner />
                }
              
            </PageWrapGray>
          </div>
        </div>
        <TaxModal 
          isOpen={this.isTaxDialogVisible} 
          title={"Update Tax"}
          edit={true}
          onClose={this.onCloseTaxDialog.bind(this)}
          onSuccess={this.onSucessUpdateTax.bind(this)}
        />
      </PreferenceGrid>
      
    )
  }

};

export default QuickbooksCard;