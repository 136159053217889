
import Lockr from 'lockr';

let numeral = require('numeral');

const currencyFormattedAmount = (amount) => {
  const currentTenant = Lockr.get('currentTenant');
  if(currentTenant && currentTenant.currency_symbol) {
    return  currentTenant.currency_symbol + numeral(amount).format('0,0.00')
  } else{
    return "$"+ numeral(amount).format('0,0.00');
  }
}

const currencySymbol = () => {
  const currentTenant = Lockr.get('currentTenant');
  if(currentTenant && currentTenant.currency_symbol) {
    return currentTenant.currency_symbol
  } else {
    return "$"
  }
}

const currency = () => {
  const currentTenant = Lockr.get('currentTenant');
  if(currentTenant && currentTenant.currency) {
    return currentTenant.currency
  } else {
    return "$"
  }

}

export {
  currencyFormattedAmount,
  currencySymbol,
  currency
};