import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup, Button, Dialog, Classes, 
  Intent, TextArea, ControlGroup
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';

@inject('equipmentStore','store') @observer
class AddEquipmentPartModal extends Component {

  handleClosEquipmentDailog() {
    this.props.onClose()
  }

  createEquipment() {
    let { 
      equipmentStore: {
        equipmentPart:{
          object
        }
      },
      equipment
    } = this.props;

    object.equipment_id = equipment.attributes.id;

    if(!object.name) {
      Toast.show({ icon: 'warning-sign', message: "Please enter name", intent: Intent.DANGER })  
      return   
    }

    if(!object.eq_type) {
      Toast.show({ icon: 'warning-sign', message: "Please enter type", intent: Intent.DANGER })  
      return   
    }

    // if(!object.eq_size) {
    //   Toast.show({ icon: 'warning-sign', message: "Please enter size", intent: Intent.DANGER })  
    //   return   
    // }

    if(!object.description) {
      Toast.show({ icon: 'warning-sign', message: "Please enter description", intent: Intent.DANGER })  
      return   
    }
    equipment.attributes.equipment_parts_attributes = [];
    equipment.attributes.equipment_parts_attributes.push(object);
 
    this.props.equipmentStore.createEquipmentParts(equipment)
      .then(this.onSuccessAddEquipment.bind(this))
      .catch(this.onFailAddEquipment.bind(this))  
  }

  onSuccessAddEquipment(response) {
    this.props.onSuccess(response.data.data)  
    Toast.show({ icon: 'thumbs-up', message: 'Equipment saved successfully.', intent: Intent.SUCCESS })
  }

  onFailAddEquipment(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: "Problem in saving equipment!", intent: Intent.DANGER })
    }
  }

  render() {
    let { 
      equipmentStore: {
        equipmentPart:{
          object,
          isLoading
        }
      },
      title
    } = this.props;

    return(
      <Dialog
        title={title}
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleClosEquipmentDailog.bind(this)}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Name"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Name'
              defaultValue={object.name}
              onChange={(event) => {object.name = event.target.value;}}          
            />
          </FormGroup>

          <FormGroup
            label="Type"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <div className="bp3-select bp3-fill">
              <select
                onChange={event => {
                  object.eq_type = event.target.value;
                }}
                defaultValue={object.eq_type}
              >
                <option value="" disabled selected>Select Type</option>
                <option value="filter">Filter</option>
                <option value="belt">Belt</option>
                <option value="others">Others</option>
              </select>
            </div>
          </FormGroup>

          <FormGroup
            label="Size"
            labelFor="text-input"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Size'
              defaultValue={object.eq_size}   
              onChange={(event) => {object.eq_size = event.target.value;}}         
            />
          </FormGroup>

          <FormGroup
            label="Description"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <TextArea
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Description'
              defaultValue={object.description}
              onChange={(event) => {object.description = event.target.value;}} 
              
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleClosEquipmentDailog.bind(this)} className="btn-notes-close">Close</Button>
            <Button onClick={this.createEquipment.bind(this)} loading={isLoading} >Save</Button>
          </div>
        </div>
      </Dialog>

    )
  }
};

export default AddEquipmentPartModal;