import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';
import Toast from "helpers/toaster";

@inject('pricingParameterStore') @observer
class Range extends Component {

  removeRange(rangeIndex,page){
    if(page === 'edit'){
      
      let {
        pricingParameterStore,
        pricingParameterStore: {
          pricingParameterDetailUpdate: {
            object
          }
        }
      } = this.props;
      
      pricingParameterStore.deletePricingParameterRange(object.attributes.pricing_parameter_ranges[rangeIndex]);
      object.attributes.pricing_parameter_ranges.splice(rangeIndex, 1);

    }else{
      let {
        pricingParameterStore: {
          pricingParameterDetail: {
            object
          }
        }
      } = this.props;
      object.pricing_parameter_ranges_attributes.splice(rangeIndex, 1);
    }
    
  }

  onChangeFile(event) {
    let {
      pricingParameterStore:{
        pricingParameterDetail:{
          object: { attributes }
        }
      }
    } = this.props;
    var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 10) {
      Toast.show({ icon: 'warning-sign', message: 'File size exceeds 10 MB!', intent: Intent.DANGER })
      return
    }
    this.props.pricingParameterStore.uploadAttachment(event.target.files[0])
      .then(this.onSuccessfullUploadAttachment.bind(this))
      .catch(this.onFailUploadAttachment.bind(this))
  }

  onSuccessfullUploadAttachment(response) {
    let { range, page } = this.props;
    range.image_id = response.data.data.attributes.id;
    Toast.show({ icon: 'thumbs-up', message: 'Image uploded successfully.', intent: Intent.SUCCESS })
  }

  onFailUploadAttachment(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ icon: 'warning-sign', message: 'Something went wrong, failed to upload file!', intent: Intent.DANGER })
    }
  }

  render() {
    let { range, page } = this.props;
    return (
      <tr className={`pricing-range${this.props.error ? 'has-error' : ''}`}>
        <td>
          <input 
            type='text' 
            className='form-control' 
            placeholder='label'
            defaultValue={range.label}
            onChange={(event) => {range.label = event.target.value}} 
          />
        </td>
        <td>
          <input 
            type='number' 
            className='form-control' 
            placeholder='Min Range'
            defaultValue={range.min_range}
            onChange={(event) => {range.min_range = event.target.value}} 
          />
        </td>
        <td>
          <input 
            type='number' 
            className='form-control' 
            placeholder='Max Range'
            defaultValue={range.max_range}
            onChange={(event) => {range.max_range = event.target.value}} 
          />
        </td>
        <td>
          <input 
            type='number' 
            className='form-control' 
            placeholder='Duration'
            defaultValue={range.duration}
            onChange={(event) => {range.duration = event.target.value}} 
          />
        </td>
        <td>
          <input 
            type='number' 
            className='form-control' 
            placeholder='Price'
            defaultValue={range.price}
            onChange={(event) => {range.price = event.target.value}} 
          />
        </td>
        <td className="d-flex">
          <input 
            type='file' 
            className='form-control' 
            placeholder='File'
            onChange={this.onChangeFile.bind(this)}  
          />
          {
            range.image_id && range.image_url &&
            <img src={range.image_url} style={{width:50, height:38, objectFit:'cover', marginLeft:5}}/>
          }
        </td>
        <td>
          <div className="action-buttons" minimal={true}>
            <Button minimal={true} small={true} onClick={this.removeRange.bind(this, this.props.rangeIndex, page)}>
              <img src="/delete-light-icon.svg" />
            </Button>
          </div>
        </td>
        {
          this.props.error &&
            <div className="col-sm-6 invitation-error-message">
              { this.props.error }
            </div>
        }
      </tr>
    )
  }

}

export default Range;