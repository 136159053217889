import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { currencyFormattedAmount, currency, currencySymbol } from 'helpers/currency';

@inject('paymentStore') @observer
class InvoiceLineItems extends Component {

  formatNumber(total){
    return currencyFormattedAmount(total)
  }

  render() {
    const {
      paymentStore: {
        total,
        due_amount,
        subTotal,
        taxes,
        invoice: {
          object
        }
      }
    } = this.props;
    return(
      <React.Fragment>
        {
          <div className="payment-padding"> <table className='table table-borderless' style={{ width: '100%'}}>
            <thead>
              <tr>
                <th width="50%" style={{ color: '#91A4B9', fontSize:12}}>Description</th>
                <th width="10%" style={{ color: '#91A4B9', fontSize: 12}}>Hours/Qty</th>
                <th width="25%" style={{ color: '#91A4B9', fontSize: 12}}>Hourly Rate/Unit Price</th>
                <th style={{ color: '#91A4B9', fontSize: 12}}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                object.attributes.labour.map((labour) => {
                  if (labour.total > 0) {
                    return (
                      <tr key={labour.id}>
                        <td width="50%" style={{ height: "auto", borderColor: "#fff", borderTopWidth: 5}}>{labour.description}</td>
                        <td width="10%" style={{ height: "auto", borderColor: "#fff", borderTopWidth: 5}}>{labour.quantity}</td>
                        <td width="25%" style={{ height: "auto", borderColor: "#fff", borderTopWidth: 5}}>{currencyFormattedAmount(labour.rate_per_unit)}</td>
                        <td style={{ height: "auto", borderColor: "#fff", borderTopWidth: 5}}>{currencyFormattedAmount(labour.total)}</td>
                      </tr>
                    )
                  } else {
                    return null;
                  }
                })
              }
              {
                object.attributes.materials.length > 0 && object.attributes.materials.map((material) => {
                  if (material.total > 0) {
                    return (
                      <tr key={material.id}>
                        <td width="50%" style={{ height: "auto", borderColor: "#fff", borderTopWidth: 5 }}>{material.description}</td>
                        <td width="10%" style={{ height: "auto", borderColor: "#fff", borderTopWidth: 5 }}>{material.quantity}</td>
                        <td width="25%" style={{ height: "auto", borderColor: "#fff", borderTopWidth: 5 }}>{currencyFormattedAmount(material.rate_per_unit)}</td>
                        <td style={{ height: "auto", borderColor: "#fff", borderTopWidth: 5 }}>{currencyFormattedAmount(material.total)}</td>
                      </tr>
                    )
                  } else {
                    return null;
                  }
                })
              }
              {
                object.attributes.custom_line_items.length > 0 && object.attributes.custom_line_items.map((lineitem) => {
                  if (lineitem.total > 0) {
                    return (
                      <tr key={lineitem.id}>
                        <td width="50%" style={{ height: "auto", borderColor: "#fff", borderTopWidth: 5, backgroundColor:"#F6F6F6" }}>{lineitem.description}</td>
                        <td width="10%" style={{ height: "auto", borderColor: "#fff", borderTopWidth: 5, backgroundColor: "#F6F6F6" }}>{lineitem.quantity}</td>
                        <td width="25%" style={{ height: "auto", borderColor: "#fff", borderTopWidth: 5, backgroundColor: "#F6F6F6" }}>{currencyFormattedAmount(lineitem.rate_per_unit)}</td>
                        <td style={{ height: "auto", backgroundColor: "#F6F6F6", borderColor:"#fff", borderTopWidth:5 }}>{currencyFormattedAmount(lineitem.total)}</td>
                      </tr>
                    )
                  } else {
                    return null;
                  }
                }) 
              }
            </tbody>
          </table>
          <hr />
          </div>
        }
        <div style={{height: 40}}></div>
        <div className="payment-padding">
        <table style={{width: '100%'}}>
          <tbody>
            <tr>
              <td width="50%"></td>
              <td>
                <table style={{width: '100%'}}>
                  <tbody>
                    <tr>
                      <td align="right">
                          <div style={{ marginBottom: 10, marginRight: 0, color: "#91A4B9"}}>
                          Sub Total:
                        </div>
                      </td>
                      <td width="21%" align="right">
                        <div style={{marginBottom: 10}}>
                          { 
                            this.formatNumber(subTotal)
                          }
                        </div>
                      </td>
                    </tr>
                    {
                      taxes.length > 0 && taxes.map((obj) => {
                        if (obj.total > 0) {
                          return (
                            <tr key={Math.random()}>
                              <td width="67%" align="right">
                                <div style={{ marginBottom: 10, marginRight: 0, color: "#91A4B9"}}>
                                  {obj.name} {obj.tax_percentage_value}%:
                                </div>
                              </td>
                              <td align="right">
                                <div style={{marginBottom: 10}}>
                                  {this.formatNumber(obj.total) }
                                </div>
                              </td>
                            </tr>
                          )
                        } else {
                          return null;
                        }
                      })
                    }
                    <tr>
                      <td width="67%" align="right">
                          <div style={{ marginTop: 10, marginBottom: 10, marginRight: 0, color: "#91A4B9"}}>
                          Total:
                        </div>
                      </td>
                      <td align="right">
                        <div style={{marginTop: 10, marginBottom: 10}}>
                          { this.formatNumber(total) }
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="67%" align="right">
                          <div style={{ marginTop: 10, marginBottom: 10, marginRight: 0, color: "#91A4B9"}}>
                          Amount Due ({ currency() } { currencySymbol() }):
                        </div>
                      </td>
                      <td align="right">
                        <div style={{ marginTop: 10, marginBottom: 10, fontSize: 16, fontWeight: 700, color: "#1800FF"}}>
                          { this.formatNumber(due_amount) }
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <br/>
        <div className="payment-padding">
          <h6 style={{ color: "#91A4B9"}}>Customer Notes</h6>
          <div className="customer-notes">
            {object.attributes.description}
          </div>
        </div>
        {object.attributes.payment_terms && <div className="payment-padding">
          <h6 style={{ color: "#91A4B9"}}>Payment Terms</h6>
          <div className="customer-notes">
            {object.attributes.payment_terms}
          </div>
        </div>}
        {object.attributes.tenant && object.attributes.tenant.show_invoice_agreement_on_pdf && object.attributes.agreement_copy && <div className="payment-padding">
          <h6 style={{ color: "#91A4B9"}}>Agreement Copy</h6>
          <div className="customer-notes">
            <div dangerouslySetInnerHTML={{__html: object.attributes.agreement_copy}} />
          </div>
        </div>}
      </React.Fragment>
    )
  }

};

export default InvoiceLineItems;