import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Intent, Label } from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import ListPicker from 'components/ListPicker';
import JobPicker from "components/JobPicker";
import TechnicianPicker from "components/TechnicianPicker";
import { observable, toJS } from 'mobx';
import { router } from 'App';
import moment from 'moment';
import _ from 'lodash';

const options = [
  { label: 'Draft', value: 'draft', selected: false },
  { label: 'Submitted', value: 'submitted', selected: false },
  { label: 'Reviewed', value: 'reviewed', selected: false },
  { label: 'Invoiced', value: 'invoice', selected: false }
];

@inject('store', 'workReportStore', 'jobStore', 'viewStore') @observer
class WorkReportCustomerDetails extends Component {
  @observable selectedOptions = null;

  // componentDidMount(){
  //   let {
  //     workReportStore: {
  //       workReport: {
  //         object
  //       }
  //     }
  //   } = this.props;
  //   let job_detals = [];
  //   job_detals = [{
  //     id: object.attributes.job.id,
  //     label: object.attributes.job.title,
  //     value: object.attributes.job.id,
  //     selected: true
  //   }]
  //   object.selectedJob = job_detals;
  // }

  onJobSelect(selectedOptions, allOptions) {
    let {
      workReportStore: {
        workReport: {
          object
        }
      },
      selectedJob
    } = this.props;
    this.selectedOptions = selectedOptions;
    selectedJob = [selectedOptions[0]];
    //object.attributes.scheduled_event_id = selectedOptions[0].value;

    if (object.attributes.job) {
      this.props.workReportStore.changeJob(object, selectedJob[0].value)
        .then((response) => {  
          Toast.show({ message: "Work Report job changed successfully.", intent: Intent.SUCCESS })
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
          } else {
            Toast.show({ message: "Something went wrong. Problem in changing work report job.", intent: Intent.DANGER })
          }
        })
    }
  }

  onTechnicianChange(technicians) {
    let {
      workReportStore: {
        workReport: {
          object
        }
      },
      selectedTechnician
    } = this.props;
    let selectedValues = technicians.map(status => status.value);
    selectedTechnician = selectedValues.filter((v) => v !== object.attributes.created_by.id);
    
    if (object.attributes.created_by) {
      this.props.workReportStore.changeTechnician(object, selectedTechnician[0])
        .then((response) => {  
          Toast.show({ message: "Work Report technician changed successfully.", intent: Intent.SUCCESS })
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
          } else {
            Toast.show({ message: "Something went wrong. Problem in changing work report technician.", intent: Intent.DANGER })
          }
        })
    }
  }

  onWorkReportStatusChange(selections) {
    let {
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
    let selectedValues = selections.map((status) => status.value);
    object.attributes.status = selectedValues[0];

    if (object.attributes.status) {
      this.props.workReportStore.changeStatus(object, object.attributes.status)
        .then((response) => {
          if (response.data.data.attributes.status === 'invoiced' || response.data.data.attributes.status === 'invoice') {
            this.isOpenAddInvoiceNumberDialog = true;
          }
          Toast.show({ message: "Work Report status changed successfully.", intent: Intent.SUCCESS })
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
          } else {
            Toast.show({ message: "Something went wrong. Problem in changing work report status.", intent: Intent.DANGER })
          }
        })
    }
  }

  startEditJob() {
    const {
      workReportStore: {
        workReport: {
          object
        }
      }
    } = this.props;
   
    this.props.jobStore.persistJob = {
      object: {
        ...object.attributes.job,
        start_date: moment(object.attributes.job.start_date),
        end_date: moment(object.attributes.job.end_date),
        isServiceAgreement: false,
        reason_for_call: object.attributes.job.reason_for_call,
        location: {
          attributes: object.attributes.location
        },
        client: {
          attributes: object.attributes.client
        },
        client_id:object.attributes.client.id,
        location_id:object.attributes.location.id,
        technician_ids:[{value:object.attributes.created_by.id,label:object.attributes.created_by.full_name,selected: true}],
        is_po_provided: object.attributes.job.is_po_provided,
        po_number: object.attributes.job.po_number,
        selectedServiceType: [
          {
            value: object.attributes.job.service_type_id, 
            selected: true, 
            label: object.attributes.job.service_type_name
          }
        ]
      }
    }
    this.props.store.isOpen = true;
  }

  render() {
    const {
      workReportStore: {
        workReport: {
          object
        }
      },
      viewStore: {
        jobTab: {
          section
        }
      }
    } = this.props;
    let {
      selectedJob,selectedTechnician
    } = this.props;
   
    return (
      <div className="invoice-customer-details" style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', position:'relative' }}>
        <div className="details-1" style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="technician-details tech-contact-block">
            {/* <div className="tech-label">
              <strong>Client</strong>
            </div> */}
            <div className="tech-contact">
              <div className="tech-name d-flex align-items-center">
                <h5><a href={`/dashboard/clients/${object.attributes.client.id}`} >
                {object.attributes.client.name}
                </a></h5> <span style={{ marginLeft: 15, color:'#91A4B9'}}>Client</span>
              </div>
              <div className="info-field">
                {
                  object.attributes && object.attributes.current_location ?
                    <div className="more-info">{object.attributes.current_location.full_address}</div>
                    :
                    <div className="more-info">{object && object.attributes && object.attributes.location && object.attributes.location.full_address}</div>
                }
                <div className="contact-info-block">
                  { object.attributes.client.phone &&
                    <div className="more-info">{object.attributes.client.phone}</div>
                  }
                  { object.attributes.client.email && 
                    <div className="more-info long-email-decorator">{object.attributes.client.email}</div> 
                  }                   
                </div>
              </div>
            </div>
          </span>
          <Label className="font-weight-bold mt-3">
            PO No.
            <input
            type='text'
            className='bp3-input bp3-fill mb-2'
            placeholder='PO No.'
            defaultValue={object.attributes.po_number}
            onChange={(event) => { object.attributes.po_number = event.target.value }}
          />
          </Label>
        </div>
        {/* <div className="details-1" style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="technician-details">
            <div className="tech-label">
              <strong>Technician</strong>
            </div>
            <div className="tech-contact">
              <div className="tech-name">
                <a href={`/dashboard/users/${object.attributes.created_by.id}/show`} >
                  { object.attributes.created_by.full_name }
                </a>
              </div>
              <div className="info-field">
                <div className="more-info">{object.attributes.created_by.email}</div>
                <div className="more-info">{object.attributes.created_by.phone}</div>
              </div>
            </div>
          </span>
        </div> */}
        
            <div className="details-1 wr-desktop-right-details" style={{ display: 'flex', flexDirection: 'column' }}>
              {/* <div className="status-box text-right">
              <Popover
                minimal={true}
                content={
                  <ListPicker
                    selectedOptions={[object.attributes.status]}
                    options={options}
                    maximumSelections={1}
                    onChange={this.onWorkReportStatusChange.bind(this)}
                  />
                }
                position={Position.BOTTOM_LEFT}
                usePortal={true}
                className="status-selector"
              >
                <Button rightIcon='caret-down' className="status-btn">
                  Status
                <span>&nbsp;</span>
                  <Tag minimal={true} round={true}>{object.attributes.status}</Tag>
                </Button>
              </Popover>
              </div> */}
              {/* <div className="workorder-no-block">
                <p className="mb-0 mr-3">Work Order No.</p>
                <h4 className="mb-0 font-weight-bold">{object && object.attributes.number}</h4>
              </div> */}
          {
            !(_.isEmpty(object.attributes.job)) &&
            <div className="d-md-flex align-items-start justify-content-between">
              <div className="side-text">Job</div>
              <div className="job-detail-block">
                {/* <JobPicker
                  selectedOptions={toJS(selectedJob)}
                  onChange={this.onJobSelect.bind(this)}
                  maximumSelections={1}
                /> */}
                {/* <select className="form-control">
                    <option>{object.attributes.job.number}</option>
                  </select> */}
                <a className="job-number-link" href={`/dashboard/jobs/${object.attributes.job.id}/${section}`} >
                  {object.attributes.job.number}
                </a>
                <div className="job-meta-details">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div><span className="font-weight-bold text-muted">Title:</span> {object.attributes.job && object.attributes.job.title}</div>
                      <div><span className="font-weight-bold text-muted">Reason for call:</span> {object.attributes.job && object.attributes.job.reason_for_call}</div>
                      <div className="job-type-name">{object.attributes.job.service_type_name}</div>
                    </div>
                    {/* <div><Button text="Edit" style={{height:20, fontSize:11}} onClick={this.startEditJob.bind(this)}/></div> */}
                  </div>
                </div>
              </div>
            </div>
          }
              <div className="d-md-flex align-items-start justify-content-between mt-4">
                <div className="side-text">Technician</div>
                <div className="job-detail-block">
                <TechnicianPicker
                  fill="true"
                  selectedOptions={toJS(selectedTechnician)}
                  onChange={this.onTechnicianChange.bind(this)}
                  includeAdmins={true}
                  hideHeading={true}
                />
                {/* <a className="job-number-link" href={`/dashboard/users/${object.attributes.created_by.id}/show`}>{object.attributes.created_by.full_name}</a> */}
                  <div className="job-meta-details">
                    <div>{object.attributes.created_by && object.attributes.created_by.email}</div>
                    <div>{object.attributes.created_by && object.attributes.created_by.phone}</div>
                  </div>
                </div>
              </div>
              {/* <span className="technician-details">                
                <div className="tech-label">
                  <strong>Job</strong>
                </div>
                <div className="tech-contact">
                  <div className="tech-name">
                    <a href={`/dashboard/jobs/${object.attributes.job.id}/${section}`} >
                    {object.attributes.job.number}
                    </a>
                  </div>
                  <div className="info-field">
                    <div className="more-info">{object.attributes.job.title}</div>
                    <div className="more-info">{object.attributes.job.service_type_name}</div>
                  </div>
                </div>
              </span> */}
            </div>
        
      </div>
    )
  }
}

export default WorkReportCustomerDetails;