import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Icon, Button } from '@blueprintjs/core';
import styles from './styles';

@inject('viewStore', 'userStore') @observer
class NewUserSidebar extends Component {

  render() {
    const {
      viewStore,
      userStore: {
        signup: {
          object
        }
      }
    } = this.props;
    return (
      <div style={styles.sidebar}>
        <ul style={styles.sidebarAttributes}>
          <li style={styles.sidebarList}>
            <div className="d-flex align-items-center justify-content-between"><label style={styles.label}>Email</label>
              <Button onClick={() => { viewStore.signup() }} minimal={false} icon="edit" style={styles.sidebarButton} />
            </div>
            <div><p>{object.email}</p></div>
          </li>
          {
            object.tenant &&
            <li style={styles.sidebarList}>
              <div className="d-flex align-items-center justify-content-between"><label style={styles.label}>Company Name</label>
                <Button onClick={() => { viewStore.signupCompany() }} minimal={false} icon="edit" style={styles.sidebarButton} />
              </div>
              <div><p>{object.tenant}</p></div>
            </li>
          }
          {
            object.company_type &&
            <li style={styles.sidebarList}>
              <div className="d-flex align-items-center justify-content-between"><label style={styles.label}>Business Type</label>
                <Button onClick={() => { viewStore.signupCompanyType() }} minimal={false} icon="edit" style={styles.sidebarButton} />
              </div>
              <div><p>{object.company_type}</p></div>
            </li>
          }
          {
            object.team_size &&
            <li style={styles.sidebarList}>
              <div className="d-flex align-items-center justify-content-between"><label style={styles.label}>Team Size</label>
                <Button onClick={() => { viewStore.signupCompanyTeamSize() }} minimal={false} icon="edit" style={styles.sidebarButton} />
              </div>
              <div><p>{object.team_size}</p></div>
            </li>
          }
          {
            (object.first_name || object.last_name) &&
            <li style={styles.sidebarList}>
              <div className="d-flex align-items-center justify-content-between"><label style={styles.label}>Name</label>
                <Button onClick={() => { viewStore.signupDetails() }} minimal={false} icon="edit" style={styles.sidebarButton} />
              </div>
              <div><p>{object.first_name} {object.last_name}</p></div>
            </li>
          }
          {
            object.phone &&
            <li style={styles.sidebarList}>
              <div className="d-flex align-items-center justify-content-between"><label style={styles.label}>Phone Number</label>
                <Button onClick={() => { viewStore.signupDetails() }} minimal={false} icon="edit" style={styles.sidebarButton} />
              </div>
              <div><p>{object.phone}</p></div>
            </li>
          }
        </ul>
      </div>
    )
  }

};

export default NewUserSidebar;
