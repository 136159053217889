import { router } from 'App';
import { autorun } from 'mobx';

import RootStore from './Root';
import ProjectStore from './Project';
import ClientStore from './Client';
import SupplierStore from './Supplier';
import WorkReportStore from './WorkReport';
import QuoteStore from './Quote';
import InvoiceStore from './Invoice';
import ViewStore from './View';
import DashboardStore from './Dashboard';
import TimesheetStore from './Timesheet';
import JobStore from './Job';
import FormStore from './Form';
import EquipmentStore from './Equipment';
import UserStore from './User';
import BillingStore from './Billing';
import TaxStore from './Tax';
import PreferenceStore from './Preference';
import ServiceTypeStore from './ServiceType';
import PaymentStore from './Payment';
import SearchStore from './Search';
import GeolocationStore from './Geolocation';
import FormSubmissionStore from './FormSubmission';
import ServiceAgreementStore from './ServiceAgreement';
import PricingParameterStore from './PricingParameter';
import ServiceTypeExtraStore from './ServiceTypeExtra';
import FrequencyStore from './Frequency';
import SpotStore from './Spot';
import QuickbooksStore from './Quickbooks';
import ProductServiceStore from './ProductService';
import ContactsStore from './ContactsStore';
import ProductCategoryStore from './ProductCategory';
import QuestionStore from './Question';
import BookingStore from './Booking';
import QuestionGroupStore from './QuestionGroup';
// import data from '../fakeData/projects';
import PaymentMethodStore from './PaymentMethod';
import XeroStore from './Xero';
import NotificationStore from './Notification';
import ServiceTypeCategoryStore from './ServiceTypeCategory';
import ActivityStore from './Activity';

let store = new RootStore();

let dashboard = new DashboardStore();
let projectStore = new ProjectStore();
let clientStore = new ClientStore();
let supplierStore = new SupplierStore();
let workReportStore = new WorkReportStore();
let quoteStore = new QuoteStore();
let invoiceStore = new InvoiceStore();
let viewStore = new ViewStore();
let timesheetStore = new TimesheetStore();
let jobStore = new JobStore();
let formStore = new FormStore();
let equipmentStore = new EquipmentStore();
let userStore = new UserStore();
let billingStore = new BillingStore();
let taxStore = new TaxStore();
let preferenceStore = new PreferenceStore();
let serviceTypeStore = new ServiceTypeStore();
let pricingParameterStore = new PricingParameterStore();
let serviceTypeExtraStore = new ServiceTypeExtraStore();
let paymentStore = new PaymentStore();
let searchStore = new SearchStore();
let geolocationStore = new GeolocationStore();
let formSubmissionStore = new FormSubmissionStore();
let serviceAgreementStore = new ServiceAgreementStore();
let frequencyStore = new FrequencyStore();
let spotStore = new SpotStore();
let quickbooksStore = new QuickbooksStore();
let productServiceStore = new ProductServiceStore();
let productCategoryStore = new ProductCategoryStore();
let contactsStore = new ContactsStore();
let paymentMethodStore = new PaymentMethodStore();
let xeroStore = new XeroStore();
let questionStore = new QuestionStore();
let bookingStore = new BookingStore();
let questionGroupStore = new QuestionGroupStore();
let notificationStore = new NotificationStore();
let serviceTypeCategoryStore = new ServiceTypeCategoryStore();
let activityStore = new ActivityStore();

const stores = {
  store,
  dashboard,
  projectStore,
  clientStore,
  supplierStore,
  workReportStore,
  quoteStore,
  invoiceStore,
  viewStore,
  timesheetStore,
  jobStore,
  formStore,
  equipmentStore,
  userStore,
  billingStore,
  taxStore,
  preferenceStore,
  serviceTypeStore,
  pricingParameterStore,
  serviceTypeExtraStore,
  paymentStore,
  searchStore,
  geolocationStore,
  formSubmissionStore,
  serviceAgreementStore,
  frequencyStore,
  spotStore,
  quickbooksStore,
  productServiceStore,
  productCategoryStore,
  contactsStore,
  paymentMethodStore,
  xeroStore,
  questionStore,
  questionGroupStore,
  bookingStore,
  notificationStore,
  serviceTypeCategoryStore,
  activityStore
}

window.stores = stores;

autorun(() => {
  let path = stores.viewStore.currentPath;
  if ((window.location.origin + path) !== window.location.href) {
    if (router) {
      router.navigate(path);
    }
  }
})

export default stores;