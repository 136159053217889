import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import ProjectProfileCard from 'components/Project/ProfileCard';
import styled from '@emotion/styled';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'viewStore', 'projectStore') @observer
class ProjectsShowPage extends Component {

  get canRender() {
    let {
      projectStore: {
        project: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    return (!isLoading && !isError && object && object.id);
  }

  componentWillUnmount() {
    let {
      projectStore
    } = this.props;
    projectStore.clearSelectedProject();
  }

  updateSelectedResource = (resource) => {
    let {
      viewStore,
      projectStore: {
        project: {
          object
        }
      }
    } = this.props;
    viewStore.projectShowSearch.resource = resource;
    viewStore.projectViewSearch(object.attributes.id);
  }

  render() {
    let content, title;
    let {
      viewStore,
      projectStore: {
        project: {
          isLoading,
          isError,
          object
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      title = 'Loading..';
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      title = 'Failed to Load Project';
      content = <NonIdealState title="Failed" description="Failed to load project!" />;
    }
    if (this.canRender) {
      title = `${object.attributes.name} | Field Nexus`;
      content = <ProjectProfileCard 
        project={object}
        selectedResource={viewStore.projectShowSearch.resource}
        onResourceChange={this.updateSelectedResource.bind(this)} 
      />
    }
    return(
      <div className="profile-page-grid">
        {
          <Helmet>
            <title>{title}</title>
          </Helmet>
        }
       <PageWrapGray>
          {content}
        </PageWrapGray>        
      </div>
    )
  }
};

export default ProjectsShowPage;