import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import FooterIllustration from 'components/FooterIllustration';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import { TopNavbar } from 'components/Styles';
import { MobileApps } from 'services/Api';

@inject('viewStore') @observer
class ResetPassword extends Component {
  render() {
    return (
      <UnauthenticatedPage>
        <Helmet>
          <title>Invitation Accepted | FieldNexus</title>
        </Helmet>
        <TopNavbar>
          <div className="tp-nav">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-7">
                  <div className="brand">
                    <a href="https://fieldnexus.com"><img style={{ maxWidth: '200px' }} src="/brand.svg" alt='Field Nexus' /></a>
                  </div>
                </div>
                <div className="col-5 text-right">
                  <div className="right-link-tp"></div>
                </div>
              </div>
            </div>      
          </div>
        </TopNavbar>
        <div className="form-wrap">
          <br></br>
          <h3 className="login-heading">
            Congratulations!
          </h3>
          <p>
            You've successfully accepted your Field Nexus invite. You can now go ahead and download our App from one of the links below:
          </p>

          <div class="home-app-store-link">
            <a href={MobileApps.ios.storeUrl} target="_blank" class="column-view-mobile">
              <noscript><img src="https://cdn.shortpixel.ai/client/q_lossless,ret_img,w_165/https://fieldnexus.com/wp-content/uploads/2020/03/app-store..png" alt="" width="165px" /></noscript>
              <img class=" lazyloaded" src="https://cdn.shortpixel.ai/client/to_webp,q_lossless,ret_img,w_165/https://fieldnexus.com/wp-content/uploads/2020/03/app-store..png" data-src="https://cdn.shortpixel.ai/client/to_webp,q_lossless,ret_img,w_165/https://fieldnexus.com/wp-content/uploads/2020/03/app-store..png" alt="" width="165px" /> 
            </a>
            <a href={MobileApps.android.storeUrl} target="_blank" class="column-view-mobile">
              <noscript><img src="https://cdn.shortpixel.ai/client/q_lossless,ret_img,w_165/https://fieldnexus.com/wp-content/uploads/2020/03/goole-play-store.png" alt="" width="165px" /></noscript>
              <img class=" lazyloaded" src="https://cdn.shortpixel.ai/client/to_webp,q_lossless,ret_img,w_165/https://fieldnexus.com/wp-content/uploads/2020/03/goole-play-store.png" data-src="https://cdn.shortpixel.ai/client/to_webp,q_lossless,ret_img,w_165/https://fieldnexus.com/wp-content/uploads/2020/03/goole-play-store.png" alt="" width="165px" /> 
            </a>
          </div>
        </div>
        <FooterIllustration />
      </UnauthenticatedPage>
    )
  }

}

export default ResetPassword;