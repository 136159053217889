import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';
import _ from 'lodash';

class QuickbooksStore {

  @observable isLoadingCreateQuickbooksCustomer = false;
  @observable showQuickbooksConnectionLoader = false;
  @observable quickbooksSearchCustomers = false;

  @observable quickbooksCustomers = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable quickbooksTaxCodes = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable quickbooksPaymentMethods = {
    isLoading: false,
    isError: false,
    objects: []
  };

  // Product & Services in Quickbooks
  @observable quickbooksItems = {
    isLoading: false,
    isError: false,
    objects: []
  };

  @observable productServiceStatus = {
    isLoading: false,
    isError: false,
    object: {},
    product_services_link_required: false
  };

  @action
  disconnectFromQuickbooks() {
    this.showQuickbooksConnectionLoader = true;
    let promise = Api.get('quickbooks/disconnect_from_quickbooks');

    promise
      .then((response) => {
        this.showQuickbooksConnectionLoader = false;
      })
      .catch((error) => {
        this.showQuickbooksConnectionLoader = false;
      })

    return promise;
  }

  @action
  loadQuickbooksItems() {
    this.quickbooksItems.isLoading = true;
    let promise = Api.get('quickbooks/products_services');

    promise
      .then((response) => {
        this.quickbooksItems.objects = response.data;
        this.quickbooksItems.isLoading = false;
        this.quickbooksItems.isError = false;
      })
      .catch((error) => {
        this.quickbooksItems.isLoading = false;
        this.quickbooksItems.isError = true;
      })

    return promise;

  }

  @action
  loadQuickbooksTaxCodes() {
    this.quickbooksTaxCodes.isLoading = true;
    let promise = Api.get('quickbooks/tax_codes');

    promise
      .then((response) => {
        this.quickbooksTaxCodes.objects = response.data;
        this.quickbooksTaxCodes.isLoading = false;
        this.quickbooksTaxCodes.isError = false;
      })
      .catch((error) => {
        this.quickbooksTaxCodes.isLoading = false;
        this.quickbooksTaxCodes.isError = true;
      })

    return promise;

  }

  @action
  loadQuickbooksPaymentMethods() {
    this.quickbooksPaymentMethods.isLoading = true;
    let promise = Api.get('quickbooks/payment_methods');

    promise
      .then((response) => {
        this.quickbooksPaymentMethods.objects = response.data;
        this.quickbooksPaymentMethods.isLoading = false;
        this.quickbooksPaymentMethods.isError = false;
      })
      .catch((error) => {
        this.quickbooksPaymentMethods.isLoading = false;
        this.quickbooksPaymentMethods.isError = true;
      })

    return promise;

  }

  @action
  linkClientWithQuickbooksCustomer(quickBookCustomer) {
    let promise = Api.post('quickbooks/link_quickbooks_customer', {invoice_id: stores.invoiceStore.invoice.object.attributes.id, quickbooks_customer_id: quickBookCustomer.id});

    promise
      .then((response) => {
        // for reloading client via invoice
        stores.invoiceStore.update();
      })
      .catch((error) => {
      })
    
    return promise;
  }

  @action
  createQuickbooksCustomer() {
    this.isLoadingCreateQuickbooksCustomer = true;
    let promise = Api.post('quickbooks/create_quickbooks_customer', {invoice_id: stores.invoiceStore.invoice.object.attributes.id});

    promise
      .then((response) => {
        this.isLoadingCreateQuickbooksCustomer = false;
        stores.invoiceStore.update();
      })
      .catch((error) => {
        this.isLoadingCreateQuickbooksCustomer = false;
      })
    
    return promise;
  }

  @action
  loadQuickbooksCustomers() {
    this.quickbooksCustomers.isLoading = true;
    let promise = Api.get('quickbooks/customers');

    promise
      .then((response) => {
        this.quickbooksCustomers.objects = response.data;
        this.quickbooksCustomers.isLoading = false;
        this.quickbooksCustomers.isError = false;
      })
      .catch((error) => {
        this.quickbooksCustomers.isLoading = false;
        this.quickbooksCustomers.isError = true;
      })

    return promise;
    
  }

  searchQuickbooksCustomers(query) {
    this.quickbooksSearchCustomers = true;
    let promise = Api.post('quickbooks/search_customers', {query: query});

    promise
      .then((response) => {
        this.quickbooksCustomers.objects = response.data;
        this.quickbooksSearchCustomers = false;
      })
      .catch((error) => {
        this.quickbooksSearchCustomers = false;
      })

    return promise;
  }

  @action
  updateInvoiceWithQuickbooks(invoice) {
    this.productServiceStatus.isLoading = true;
    let promise = Api.post('quickbooks/sync_update_invoice', {invoice_id: invoice.attributes.id});

    promise
      .then((response) => {
        this.productServiceStatus.isLoading = false;
        stores.invoiceStore.getInvoice({uuid: invoice.attributes.id})
      })
      .catch((error) => { 
        this.productServiceStatus.isLoading = false;
      })

    return promise;
  }

  @action
  syncInvoiceWithQuickbooks(invoice) {
    this.productServiceStatus.isLoading = true;
    let promise = Api.post('quickbooks/sync_invoice', {invoice_id: invoice.attributes.id});

    promise
      .then((response) => {
        this.productServiceStatus.isLoading = false;
        stores.invoiceStore.getInvoice({uuid: invoice.attributes.id})
      })
      .catch((error) => { 
        this.productServiceStatus.isLoading = false;
      })

    return promise;
  }

  @action
  checkProductServicesStatus(invoice) {
    this.productServiceStatus.isLoading = true;
    
    let promise = Api.post('quickbooks/check_product_service_status', {invoice_id: invoice.attributes.id});

    promise
      .then((response) => {
        this.productServiceStatus.object = _.omitBy(response.data.product_services_status, (value, key) => value === true);
        this.productServiceStatus.product_services_link_required = response.data.product_services_link_required;
        this.productServiceStatus.isLoading = false;
        this.productServiceStatus.isError = false;
      })
      .catch((error) => { 
        this.productServiceStatus.isLoading = false;
        this.productServiceStatus.isError = true;
      })

    return promise;

  }

};

export default QuickbooksStore;