import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import qs from 'qs';

class SearchStore {

  @observable search = {
    isLoading: false,
    isError: false,
    isSearched: false,
    objects: {}
  };

  @action
  doTermSearch(term) {
    let params = {term: term}
    this.search.isLoading = true;
    let promise = Api.get(`/search?` + qs.stringify(params)) 

    promise
      .then((response) => {
        this.search.isLoading = false;
        this.search.isSearched = true;
        this.search.objects = response.data
      })
      .catch((error) => {
        this.search.isLoading = false;
        this.search.isError = true;
      })
  }



};

export default SearchStore;