import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { 
  Tab, Tabs, Spinner, Button, Dialog, Classes
} from "@blueprintjs/core";
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import UsersTable from 'components/Tables/Users';
import styled from '@emotion/styled';
import InviteUserDialog from 'components/User/inviteUser';
import UserSidebar from './user-sidebar';
import Invite from '../users/invite';

const TabHolder = styled('div')`
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;  
  min-height: calc(100vh - 75px);
`

@inject('store', 'dashboard', 'viewStore', 'userStore') @observer
class UsersIndexPage extends Component {

  @observable selectedTabId = 'all';
  @observable isOpenInviteUserDialog = false;
  @observable isOpenResponseDialog = false;

  componentDidMount() {
    let viewStore = this.props.viewStore;
    this.selectedTabId = viewStore.userSearch.section;
  } 

  handleTabChange = (tabId) => {
    this.selectedTabId = tabId;
    let viewStore = this.props.viewStore;
    viewStore.userSearch = { section: tabId };
    if (tabId === 'invite-user') {
    } else {
      viewStore.users();
    }
  }

  showInviteUserDialog() {
    this.isOpenInviteUserDialog = true
  }

  closeInviteUserDialog() {
    this.isOpenInviteUserDialog = false
  }

  onSuccessInviteUser() {
    this.isOpenInviteUserDialog = false
    this.isOpenResponseDialog = true
  }

  handleClose() {
    this.isOpenResponseDialog = false
  }

  render() {
    const {
      props: {
        userStore: {
          inviteUserResponse
        },
        viewStore,
        viewStore: {
          section
        },
        dashboard: {
          users: {
            isLoading
          }
        }
      }
    } = this;
    let {selectedTabId} = this;
    return (
      <div className="user-page-layout">
        <Helmet>
          <title>Users | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <UserSidebar />
          </div>
          <div className="col-lg-10">

            <PageWrapGray> 
              <div style={{marginBottom:'20px'}}>      
                <TabHolder>
                  <Button text={'Invite User'} className='bp3-right' onClick={() => viewStore.inviteUsers()} />
                  <Tabs onChange={this.handleTabChange} selectedTabId={section} large={true}>
                    <Tab id="all" title="All" />
                    <Tab id="admin" title="Admins" />
                    <Tab id="technician" title="Technicians" />
                    <Tab id="invitations" title="Invitations" />
                    <Tab id="invite-user" title="Invite User" />
                  </Tabs>
                </TabHolder>
              </div>
              { 
                isLoading ? 
                  <TabHolder>
                    <Spinner />
                  </TabHolder> : 
                ''
              } 
              { 
                selectedTabId === 'invite-user' ? 
                  <TabHolder>
                    <Invite />
                  </TabHolder> : 
                  <UsersTable />
              }   
            </PageWrapGray>
          </div>
        </div>
        <InviteUserDialog
          isOpen={this.isOpenInviteUserDialog}
          onClose={this.closeInviteUserDialog.bind(this)}
          onSuccess={this.onSuccessInviteUser.bind(this)}
        />
        <Dialog
          title="Invite User"
          icon="info-sign"
          isOpen={this.isOpenResponseDialog}
          onClose={this.handleClose.bind(this)}
        >
          <div className={Classes.DIALOG_BODY}>
            {
              inviteUserResponse.success.length > 0 && 
              <div>
                <h5>Successfull invitation sent:</h5>
                { inviteUserResponse.success.map((user) => {
                  return(
                    <div key={Math.random()}>
                      {user.first_name && <span>{user.first_name} {user.last_name}:</span>} {user.email}
                    </div>
                  )
                })}
              </div>
            }
            
            {
              inviteUserResponse.failed.length > 0 && 
              <div>
                <h5>Failed to sent invitations to the following users:</h5>
                { 
                  inviteUserResponse.failed.map((user) => {
                    return(
                      <div key={Math.random()}>
                        {user.first_name && <span>{user.first_name} {user.last_name}:</span>} {user.email}
                        <br/>
                        {user.errors}
                        <br/> <br/>
                      </div>
                    )
                  })
                }
              </div>
            }
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.handleClose.bind(this)}>CLOSE</Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
};

export default UsersIndexPage;