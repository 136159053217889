import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Card from "components/Card";
import Page from "components/Page";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  ControlGroup,
  AnchorButton,
  TextArea,
  Button, Checkbox, Intent
} from "@blueprintjs/core";
import moment from 'moment-timezone';
import styled from "@emotion/styled";
import DateTimeInput from "components/DateTimeInput";
import ClientAutocomplete from "components/ClientAutocomplete";
import LocationAutocomplete from "components/LocationAutocomplete";
import ProjectAutocomplete from "components/Job/ProjectAutocomplete";
import ServiceTypePicker from "components/ServiceTypePicker";
import TechnicianPicker from "components/TechnicianPicker";
import AddProjectModal from "components/Project/AddProjectModal";
import AddLocationModal from "components/Client/AddLocationModal";
import AddClientModal from "components/Client/AddClientModal";
import Toast from 'helpers/toaster';
import { observable } from "mobx";

const CardHolder = styled("div")`
  padding: ${props => `${props.theme.unit * 3}px`};
`;

@inject("store", "projectStore", "jobStore", "userStore")
@observer
class PersistJob extends Component {
  @observable isOpenAddProjectDialog = false;
  @observable isOpenAddLocationDialog = false;
  @observable isOpenAddClientDialog = false;
  @observable selectedLocation = null;
  @observable selectedClient = null;
  @observable minDateStart = moment().subtract(365, 'days');
  @observable startDateDefault = moment();
  @observable minDateEnd = moment().subtract(364, 'days');
  @observable endDateDefault = moment().add(1, 'hour');
  @observable mandatoryField = false;
  @observable techsVacationConflicts = [];

  componentDidMount() {
    let { object } = this.props.jobStore.persistJob;
    if (object && !object.id) {
      if(object.start_date == ''){
        object.start_date = this.startDateDefault;
        object.end_date = this.endDateDefault;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.forceUpdate();
  }

  onServiceTypeSelect(selectedOptions) {
    let { object } = this.props.jobStore.persistJob;
    object.selectedServiceType = selectedOptions;
    if (selectedOptions.length > 0) {
      object.service_type_id = selectedOptions[0].value;
    }
  }

  onTechnicianChange(technicians) {
    let {
      props: {
        jobStore: {
          persistJob: { object }
        }
      }
    } = this;
    let selectedValues = technicians.map(status => status.value);
    object.technician_ids = selectedValues;
    this.checkTechnicianVacationConflicts(object.technician_ids, object.start_date, object.end_date)
  }

  checkTechnicianVacationConflicts(technician_ids, start_date, end_date) {
    this.props.userStore.checkTechnicianVacationConflicts(technician_ids, start_date, end_date)
      .then((response) => {
        this.techsVacationConflicts = response.data.conflicts;
      })
  }

  createJob() {
    let { object } = this.props.jobStore.persistJob;
    if(this.mandatoryField === true && object.po_number === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid PO#!', intent: Intent.DANGER })
      return;
    }
    if(this.mandatoryField === true && object.po_number === '') {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid PO#!', intent: Intent.DANGER })
      return;
    }
    if(object.title === '' || object.title === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter title.', intent: Intent.DANGER })
      return;
    }
    if(object.reason_for_call === '' || object.reason_for_call === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter reason for call.', intent: Intent.DANGER })
      return;
    }
    if(object.service_type_id === '' || object.service_type_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter service type.', intent: Intent.DANGER })
      return;
    }
    this.props.jobStore.createJob().then(function(response) {
      const data = response.data.data;
      Toast.show({ icon: 'thumbs-up', message: <p>{`Job #${data.attributes.event_number} has been successfully created. Please `}<a href={`/dashboard/jobs/${data.attributes.job_id}`}>click here</a> to go to the job page.</p>, intent: Intent.SUCCESS })
    })
  }

  updateJob() {
    let { object } = this.props.jobStore.persistJob;
    if(this.mandatoryField === true && object.po_number === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid PO#!', intent: Intent.DANGER })
      return;
    }
    if(this.mandatoryField === true && object.po_number === '') {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid PO#!', intent: Intent.DANGER })
      return;
    }
    if(object.title === '' || object.title === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter title.', intent: Intent.DANGER })
      return;
    }
    if(object.reason_for_call === '' || object.reason_for_call === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter reason for call.', intent: Intent.DANGER })
      return;
    }
    if(object.service_type_id === '' || object.service_type_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter service type.', intent: Intent.DANGER })
      return;
    }
    // if(object.technician_ids && object.technician_ids.length === 0 ) {
    //   Toast.show({ icon: 'warning-sign', message: 'Please select technician.', intent: Intent.DANGER })
    //   return;
    // }
    this.props.jobStore.updateSidebarJob()
      .then((response) => {
        Toast.show({ icon: 'thumbs-up', message: 'Job updated successfully!', intent: Intent.SUCCESS })
      })
      .catch((error) => {
        if(error.response && error.response.data && error.response.data.errors) {
          Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ icon: 'warning-sign', message: 'Something went wrong. Unable to update job!', intent: Intent.DANGER })
        }
      })
  }

  handleCloseProjectDailog() {
    this.isOpenAddProjectDialog = false;
  }

  handleCloseClientDailog() {
    this.isOpenAddClientDialog = false;
  }

  handleCloseLocationDailog() {
    this.isOpenAddLocationDialog = false;
  }

  onSuccessAddLocation(location) {
    let { object } = this.props.jobStore.persistJob;
    this.isOpenAddLocationDialog = false;
    object.location_id = location.id;
    object.location = location;
  }

  onSuccessAddClient(client) {
    let { object } = this.props.jobStore.persistJob;
    this.isOpenAddClientDialog = false;
    object.client_id = client.id;
    object.client = client;
  }

  onSuccessAddProject(project) {
    this.isOpenAddProjectDialog = false;
  }

  onLocationClear = () => {
    let { object } = this.props.jobStore.persistJob;
    object.location_id = null;
    object.location = null;
  };

  onClientClear = () => {
    let { object } = this.props.jobStore.persistJob;
    object.client_id = null;
    object.client = null;
    this.onLocationClear();
  };

  onStartDateChange = time => {
    let { object } = this.props.jobStore.persistJob;
    object.start_date = moment(time);
    this.startDateDefault = moment(time);
    if(object.start_date > this.minDateEnd){
      object.end_date = moment(time).add(1, 'hour');
      this.minDateEnd = moment(time).add(1, 'hour');
      this.endDateDefault = moment(time).add(1, 'hour');
    }else{
      object.end_date = moment(time).add(1, 'hour');
      this.minDateEnd = moment(time).add(1, 'hour');
    }
  };

  onEndDateChange = time => {
    let { object } = this.props.jobStore.persistJob;
    object.end_date = moment(time);
    this.endDateDefault = moment(time);
  }

  handleChange(event){
    let { object } = this.props.jobStore.persistJob;
    if(event.target.checked){
      object.is_po_provided = event.target.checked;
      this.mandatoryField = true;
    }else{
      this.mandatoryField = false;
    }
  }

  render() {
    let { object, isLoading } = this.props.jobStore.persistJob;
    let { isOpen } = this.props.store;
    
    if (!isOpen) {
      return null;
    }

    return (
      <div className="add-new-jobs-layout">
        <Helmet>
          <title>{object && object.id ? "Update Job" : "Add New Job"}</title>
        </Helmet>
        <Page style={{ padding: 0 }}>
          <Card style={{ height: 'calc(100vh)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <CardHolder style={{ backgroundColor: "#F8FAFB", border: "0px" }}>
              <h3 className="bp3-heading">
                {object && object.id ? "Update Job" : "Add New Job"}{" "}
              </h3>
              <h6>Customer Information</h6>
              <div className="well job-client-name">
                <FormGroup
                  label="Enter Customer Name, Phone Number or Email"
                  labelFor="text-input"
                  labelInfo="*"
                >
                  <ControlGroup fill={true} vertical={false}>
                    <ClientAutocomplete
                      inputProps={{
                        placeholder:
                          "Start typing name, phone number or email..."
                      }}
                      value={object.client}
                      onClientSelect={client => {
                        if (object && client) {
                          object.client_id = client.id;
                          object.client = client;
                        } else {
                          this.onClientClear();
                        }
                      }}
                    />
                    <AnchorButton
                      text="Add New"
                      onClick={() => {
                        this.isOpenAddClientDialog = true;
                      }}
                      style={{ maxWidth: "100px", minHeight: "32px" }}
                      className='add-new-btn'
                    />
                  </ControlGroup>
                  {object && object.client && object.client.attributes.do_not_service &&
                    <div className="alert mt-2 alert-danger do-not-service" role="alert">
                      Do not service this client: {object.client.attributes.do_not_service_reason}
                    </div>}
                  {object && object.client && object.client.attributes.is_on_alert &&
                    <div className="alert mt-2 alert-danger do-not-service" role="alert">
                      Alert on this client: {object.client.attributes.on_alert_reason}
                    </div>}
                </FormGroup>
                {object && object.client &&
                  //Clients Information card START
                  <div className="clients-info-card-wrapper">
                    <div className="user-info">
                      <div className="user-details">
                        <div className="client-name">{object.client.attributes.name || "Not Available"}</div>
                        <div className="client-email">{object.client.attributes.email || "Not Available"}</div>
                      </div>
                      <a href={`/dashboard/clients/${object.client.id}?resource=jobs`} className="edit-user-info" target="_blank">
                        <img src="/edit-icon.svg" alt="Edit Client Info" />
                      </a>
                    </div>
                    <div className="contact-details">
                      <div className="contact-cols">
                        <div className="cols-title">Phone</div>
                        <div className="cols-content">{object.client.attributes.phone || "Not Available"}</div>
                      </div>
                      <div className="contact-cols">
                        <div className="cols-title">Fax</div>
                        <div className="cols-content">{object.client.attributes.fax || "Not Available"}</div>
                      </div>
                    </div>
                    {object.client.attributes.description &&
                      <div className="client-description">
                        <div className="contact-cols">
                          <div className="cols-title">Description</div>
                          <p>{object.client.attributes.description}</p>
                        </div>
                      </div>
                    }
                  </div>
                  //Clients Information card END
                }
                {object.client_id && (
                  <FormGroup
                    label="Location"
                    labelFor="text-input"
                    labelInfo="(search for location)"
                  >
                    <ControlGroup fill={true} vertical={false}>
                      <LocationAutocomplete
                        scope={{ client_id: object.client_id }}
                        style={{ width: "400px" }}
                        inputProps={{
                          fill: true,
                          placeholder: "Start typing location.."
                        }}
                        onLocationClear={this.onLocationClear.bind(this)}
                        value={object.location}
                        onLocationSelect={location => {
                          if (object && location) {
                            object.location_id = location.id;
                            object.location = location;
                          } else {
                            this.onLocationClear();
                          }
                        }}
                      />
                      <AnchorButton
                        text="Add New"
                        onClick={() => {
                          this.isOpenAddLocationDialog = true;
                        }}
                        style={{ maxWidth: "100px", minHeight: "32px" }}
                        className='add-new-btn'
                      />
                    </ControlGroup>
                  </FormGroup>
                )}
              </div>
              <h6>Job details</h6>
              <div className="well job-client-name">
                <FormGroup
                  label="Job Title"
                  labelFor="text-input"
                  labelInfo="*"
                >
                  <input
                    type="text"
                    className="bp3-input bp3-fill"
                    placeholder="Title"
                    defaultValue={object.title}
                    onChange={event => {
                      object.title = event.target.value;
                    }}
                  />
                </FormGroup>
                <FormGroup
                  label="Reason for Call"
                  labelFor="text-input"
                  labelInfo="*"
                >
                  <TextArea
                    className="bp3-fill"
                    placeholder="Write a short description of why you are scheduling this Job/Call?"
                    value={object.reason_for_call}
                    onChange={event => {
                      object.reason_for_call = event.target.value;
                    }}
                  />
                </FormGroup>

                <div className="form-row">
                  <div className="col">
                    <FormGroup
                      label="Start Time"
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <ControlGroup fill={true} vertical={false}>
                        <DateTimeInput 
                          //minDate={this.minDateStart}
                          placeholder={'Start Date'}
                          defaultValue={object.start_date}
                          onChange={this.onStartDateChange.bind(this)}
                        />                     
                      </ControlGroup>
                    </FormGroup>
                  </div>
                  <div className="col">
                    <FormGroup
                      label="End Time"
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <ControlGroup fill={true} vertical={false}>                       
                        <DateTimeInput 
                          //minDate={this.minDateEnd}
                          placeholder={'End Date'} 
                          defaultValue={object.end_date} 
                          onChange={this.onEndDateChange.bind(this)} 
                        />
                      </ControlGroup>
                    </FormGroup>
                  </div>
                </div>

                <FormGroup
                  label="Is PO Provided ?"
                  labelFor="text-input"
                >
                  <Checkbox 
                    onChange={(event) => this.handleChange(event)}
                    defaultChecked={object.is_po_provided === object.is_po_provided ? false: true}
                  />
                </FormGroup>
                { this.mandatoryField === true ?
                  <FormGroup
                    label="PO Number"
                    labelFor="text-input"
                    labelInfo={this.mandatoryField === true ? '*' :''}
                  >
                    <input
                      type="text"
                      className="bp3-input bp3-fill"
                      placeholder="PO Number"
                      defaultValue={object.po_number}
                      onChange={event => {
                        object.po_number = event.target.value;
                      }}
                    />
                  </FormGroup>
                  :''
                }

                {object.client_id && (
                  <FormGroup
                    label="Project"
                    labelFor="text-input"
                    labelInfo="(search for project)"
                  >
                    <ControlGroup fill={false} vertical={false} className="bp3-fill">
                      <ProjectAutocomplete
                        scope={{ client_id: object.client_id }}
                        style={{ width: "400px" }}
                        inputProps={{
                          fill: true,
                          placeholder: "Start typing project name.."
                        }}
                        onProjectClear={() => {
                          object.project_id = null;
                        }}
                        onProjectSelect={project => {
                          if (object && project) {
                            object.project_id = project.id;
                          } else {
                            object.project_id = null;
                          }
                        }}
                      />
                      <AnchorButton
                        text="Add New"
                        onClick={() => {
                          this.isOpenAddProjectDialog = true;
                        }}
                        style={{ maxWidth: "100px", minHeight: "32px" }}
                        className='add-new-btn'
                      />
                    </ControlGroup>
                  </FormGroup>
                )}
                <FormGroup 
                  label="Service Type"
                  labelFor="text-input"
                  labelInfo="*"
                >
                  <ServiceTypePicker
                    selectedOptions={object.selectedServiceType}
                    onChange={this.onServiceTypeSelect.bind(this)}
                    maximumSelections={1}
                  />
                </FormGroup>
              </div>
              
              {this.techsVacationConflicts && this.techsVacationConflicts.length > 0 && <div className="alert alert-danger" role="alert">
                {this.techsVacationConflicts.map((conflict) => {
                  return(
                    <p key={Math.random()}>
                      {conflict.message}
                    </p>
                  )
                })}
              </div>}
              <h6>Technicians</h6>
              <div className="well field-mb">
                <FormGroup labelFor="text-input">
                  <TechnicianPicker
                    includeAdmins={true}
                    selectedOptions={object.technician_ids || ""}
                    onChange={this.onTechnicianChange.bind(this)}
                    shouldDismissPopover={false}
                  />
                </FormGroup>
              </div>
            </CardHolder>
            <div className="btn-block-addjob">
              {object && !object.id && (
                <Button
                  icon="floppy-disk"
                  text="Create Job"
                  intent="primary"
                  onClick={this.createJob.bind(this)}
                  loading={isLoading}
                />
              )}
              {object && object.id && (
                <Button
                  icon="floppy-disk"
                  text="Update Job"
                  intent="primary"
                  onClick={this.updateJob.bind(this)}
                  loading={isLoading}
                />
              )}
            </div>
          </Card>
          <AddProjectModal
            isOpen={this.isOpenAddProjectDialog}
            client_id={object.client_id}
            onClose={this.handleCloseProjectDailog.bind(this)}
            onSuccess={this.onSuccessAddProject.bind(this)}
          />
          <AddLocationModal
            isOpen={this.isOpenAddLocationDialog}
            client_id={object.client_id}
            onClose={this.handleCloseLocationDailog.bind(this)}
            onSuccess={this.onSuccessAddLocation.bind(this)}
          />
          <AddClientModal
            isOpen={this.isOpenAddClientDialog}
            onClose={this.handleCloseClientDailog.bind(this)}
            onSuccess={this.onSuccessAddClient.bind(this)}
          />
        </Page>
      </div>
    );
  }
}

export default PersistJob;
