import React from 'react';
import { inject, observer } from 'mobx-react';
import { Select } from "@blueprintjs/select";
import {
  Spinner, Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import { observable } from 'mobx';
import Pagination from 'components/Pagination';
import Footer from '../Footer';
import Toast from 'helpers/toaster';
import moment from 'moment';
import VacationModal from '../User/VacationModal';
import { formatTime } from '../../helpers/time';

@inject('userStore', 'viewStore') @observer
class VacationsTable extends React.Component {

  @observable isOpenVacationModal = false;
  @observable isDeleteDialogVisible = false;
  @observable vacation;
  @observable edit = false;

  onPaginationChange(newPage) {
    let {
      viewStore: {
        vacationsSearch
      }
    } = this.props;
    this.props.viewStore.vacationsSearch = {
      ...vacationsSearch,
      page: newPage
    };
    this.props.viewStore.usersVacations();
  }

  handleVacationClick(event, vacation) {
    switch (event.label) {
      case 'Edit':
        this.vacation = vacation;
        this.props.userStore.vacation.object = this.vacation.attributes;
        this.edit = true
        this.isOpenVacationModal = true
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.vacation = vacation;
        break;
      default:
    }
  }

  handleCloseVacationModal() {
    this.isOpenVacationModal = false
    this.edit = false;
    this.vacation = null;
    this.props.userStore.vacation.object = {
      start_date: moment(), 
      end_date: moment()
    }
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.userStore.deleteVacation(this.vacation)
      .then(this.onSuccessfulDeleteVacation.bind(this))
      .catch(this.onFailedVacationDelete.bind(this))
  }

  onSuccessfulDeleteVacation(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Vacation deleted successfully!', intent: Intent.SUCCESS })
    this.vacation = null;
    this.isDeleteDialogVisible = false;
    this.props.userStore.loadUsersVacations();
  }

  onFailedVacationDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete vacation!', intent: Intent.DANGER })
  }

  render() {
    let {
      userStore: {
        vacations: {
          objects,
          meta
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return(
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left" style={{marginLeft:'10px'}}>
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
        <table className='table table-hover' style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Technician</th>
              <th>Status</th>
              <th>Approved By</th>
              <th>Notes</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((vacation) => {
                return (
                  <tr key={vacation.id}>
                    <td>
                      <p className='primary'>{ formatTime(vacation.attributes.start_date) }</p>
                    </td>
                    <td>
                      <p className='secondary'>{ formatTime(vacation.attributes.end_date)}</p>
                    </td>
                    <td>{vacation.attributes.full_name}</td>
                    <td width="150px">
                      {vacation.attributes.status === "approved" && <span className='bp3-tag bp3-minimal bp3-intent-success'>{vacation.attributes.status}</span>}
                      {vacation.attributes.status === "rejected" && <span className='bp3-tag bp3-minimal bp3-intent-danger'>{vacation.attributes.status}</span>}
                    </td>
                    <td>
                      <p className="primary">{vacation.attributes.created_by_fullname}</p>
                    </td>
                    <td>
                      {vacation.attributes.notes &&<span className='bp3-tag bp3-minimal bp3-intent-primary'>
                        {vacation.attributes.notes}
                      </span>}
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit" minimal={true} small={true} onClick={() => this.handleVacationClick({ label: 'Edit' }, vacation)}>
                          <img src="/edit-light-icon.svg"/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleVacationClick({ label: 'Delete' }, vacation)}>
                          <img src="/delete-light-icon.svg" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        </div>
        <VacationModal
          isOpen={this.isOpenVacationModal}
          onClose={this.handleCloseVacationModal.bind(this)}
          user={this.vacation && this.vacation.attributes.user}
          vacation={this.edit ? this.vacation.attributes : null}
        />

        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to do this?
          </p>
        </Alert>
      </React.Fragment>
    )
  }

};

export default VacationsTable;