import Project from './Project';
import Client from './Client';
import WorkReport from './WorkReport';
import Quote from './Quote';
import Dashboard from './Dashboard';
import Timesheet from './Timesheet';
import Form from './Form';
import Equipment from './Equipment';
import Invoice from './Invoice';
import Map from './Map';
import Job from './Job';
import Vacations from './Vacations';
import ServiceAgreement from './ServiceAgreement';
import MaterialOrders from './MaterialOrders';
import SupplierSearch from './SupplierSearch';
import PurchaseOrders from './PurchaseOrders';
import ServiceType from './ServiceType';
import PricingParameterSearch from './PricingParameterSearch';
import ServiceTypeExtraSearch from './ServiceTypeExtraSearch';
import FrequencySearch from './FrequencySearch';
import BookingSearch from './BookingSearch';
import QuestionSearch from './QuestionSearch';
import QuestionGroupSearch from './QuestionGroupSearch';
import ProductService from './ProductService';
import Assignment from './Assignment';

const SearchBars = {
  Project,
  Client,
  SupplierSearch,
  WorkReport,
  Quote,
  Dashboard,
  Timesheet,
  Form,
  Equipment,
  Invoice,
  Map,
  Job,
  Vacations,
  ServiceAgreement,
  MaterialOrders,
  PurchaseOrders,
  ServiceType,
  PricingParameterSearch,
  ServiceTypeExtraSearch,
  FrequencySearch,
  BookingSearch,
  QuestionSearch,
  QuestionGroupSearch,
  ProductService,
  Assignment
}

export default SearchBars;