import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup, Button, Dialog, Classes, Intent, Checkbox, Spinner
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';

@inject('paymentMethodStore', 'quickbooksStore') @observer
class PaymentMethodModal extends Component {

  handleCloseDailog() {
    // clearing object
    this.props.paymentMethodStore.paymentMethod.object = {}
    this.props.onClose()
  }

  updatePaymentMethod() {
    let { paymentMethodStore } = this.props;
    paymentMethodStore.update()
      .then(this.onSuccessfullyUpdatePaymentMethod.bind(this))
      .catch(this.onFailCreatePaymentMethod.bind(this))
  }

  onSuccessfullyUpdatePaymentMethod(response) {
    this.props.onSuccess()
    Toast.show({ message: "Payment method updated successfully.", intent: Intent.SUCCESS })
  }

  createPaymentMethod() {
    let { paymentMethodStore } = this.props;
    paymentMethodStore.create()
      .then(this.onSuccessfullyCreatePaymentMethod.bind(this))
      .catch(this.onFailCreatePaymentMethod.bind(this))
  }

  createPaymentMethodOnQuickbooks(){
    let { 
      paymentMethodStore,
      paymentMethodStore: {
        paymentMethod:{
          object
        }
      }
     } = this.props;
    object.payment_method_name = 'Credit Card (FieldNexus)';
    paymentMethodStore.createOnQuickBooks()
      .then(this.onSuccessfullyCreatePaymentMethodOnQuickbooks.bind(this))
      .catch(this.onFailCreatePaymentMethod.bind(this))

  }

  onSuccessfullyCreatePaymentMethodOnQuickbooks(response) {
    this.props.quickbooksStore.loadQuickbooksPaymentMethods();
    Toast.show({ message: "Payment method created successfully.", intent: Intent.SUCCESS })
  }

  onSuccessfullyCreatePaymentMethod(response) {
    this.props.onSuccess()
    Toast.show({ message: "Payment method created successfully.", intent: Intent.SUCCESS })
  }

  onFailCreatePaymentMethod(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ message: "Something went wrong. Failed to create/update payment method", intent: Intent.DANGER })
    }
  }

  render() {
    let {
      title,
      edit,
      paymentMethodStore: {
        paymentMethod: {
          isLoading,
          object
        }
      },
      quickbooksStore: { 
        quickbooksPaymentMethods: {
          objects
        }
      }
    } = this.props;
    let credit_card_fieldnexus = ';'

    if(objects && objects.length > 0){
      credit_card_fieldnexus = objects.map(function(item) { return item.name; }).indexOf('Credit Card (FieldNexus)');
    }
     
    return(
      <Dialog
        title={title}
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Name"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Name'
              readOnly={this.props.edit}
              defaultValue={object.name}
              onChange={(event) => { object.name = event.target.value } } 
            />
          </FormGroup>
          <div className={credit_card_fieldnexus === -1 && !edit ? 'row' : ''}>
            <div className={credit_card_fieldnexus === -1 && !edit  ? 'col-md-6' : ''}>
              <FormGroup
                label="QuickBooks Payment Method"
                labelFor="checkbox"
                labelInfo="(*)"
              >
                {this.props.quickbooksStore.quickbooksPaymentMethods.isLoading && <Spinner size={Spinner.SIZE_SMALL} />}
                {!this.props.quickbooksStore.quickbooksPaymentMethods.isLoading && <div className="bp3-select bp3-fill">
                  <select
                    id={"quickbooks-payment-method"}
                    value={object.quickbooks_payment_method_id}
                    onChange={event => {
                      object.quickbooks_payment_method_id = event.target.value;
                    }}
                  >
                    <option value="">Select QuickBooks payment method</option>
                    {objects && objects.length > 0 && objects.map((paymentMethod) => {
                      return(
                        <option value={paymentMethod.id} key={Math.random()}>{paymentMethod.name}</option>
                      )
                    })}
                  </select>
                </div>}
              </FormGroup>

            </div>
            { credit_card_fieldnexus === -1 && !edit  &&
              <div className={credit_card_fieldnexus === -1 ? 'col-md-6' : ''} style={{display:'flex',alignItems:'center',marginTop:'5px'}}>
                <Button intent={Intent.PRIMARY}  loading={isLoading} onClick={this.createPaymentMethodOnQuickbooks.bind(this)}>
                  Add to QuickBooks
                </Button>
              </div>
            }
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseDailog.bind(this)}>Close</Button>
            {!edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.createPaymentMethod.bind(this)}>
              Save
            </Button>}
            {edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.updatePaymentMethod.bind(this)}>
              Update
            </Button>}
          </div>
        </div>
      </Dialog>
    )
  }
};

export default PaymentMethodModal;