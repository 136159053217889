import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { 
  Divider, Intent,
  Button,
  FormGroup
} from '@blueprintjs/core';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Toast from "helpers/toaster";
import BookingsSidebar from '../bookings/bookings-sidebar';
import { Helmet } from 'react-helmet';

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`

@inject("questionGroupStore")
@observer
class QuestionGroupAddPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: true,
    };
  }

  componentDidMount() {
    let {
      questionGroupStore: {
        group: {
          object
        }
      }
    } = this.props;

  }

  createQuestionGroup() {
    let {
      props: {
        questionGroupStore: {
          group: { 
            object
           }
        }
      }
    } = this;
    object.isLoading = true;

    if(object.name === "" || object.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }

    if(object.question === "" || object.question === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid question!', intent: Intent.DANGER })
      return;
    }

    this.props.questionGroupStore
      .createQuestionGroup()
      .then(response => {
        Toast.show({
          icon: "thumbs-up",
          message: "Question group created successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        if(error.reseponse && error.response.data && error.response.data.errors) {
          Toast.show({
            icon: "warning-sign",
            message: error.response.data.errors,
            intent: Intent.DANGER
          });
        } else {
          Toast.show({
            icon: "warning-sign",
            message: "Something went wrong, Fail to create question group!",
            intent: Intent.DANGER
          });
        }
      });
  }

  
  render() {
    let {
      questionGroupStore: {
        group: {
          object,
          isLoading
        }
      }
    } = this.props;

    return (
      <div className="row no-gutters">
        <Helmet>
          <title>{'Question Group | Fieldnexus'}</title>
        </Helmet>
        <div className="col-lg-2 sidebar-responsive">
          <BookingsSidebar />
        </div>
        <div className="col-lg-10">
          <PageWrapGray>
            <div className="pricing-parameter-table bookings-sub-forms">
              <Card>
                <CardContent>
                  <div>
                    <div className="left-side-panel-servie-type">
                      <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                        Add Question Group
                      </h4>
                    </div>
                  </div>
                  <Divider style={{ margin: '16px 0px' }}></Divider>
                  <div className="left-side-panel-servie-type pricing-parameter">
                    <FormGroup label="Name" >
                      <input 
                        type='text' 
                        className='form-control' 
                        placeholder=''
                        defaultValue={object.name}
                        onChange={(event) => {object.name = event.target.value;}}            
                      />
                    </FormGroup>
                    <FormGroup label="Question" >
                      <input 
                        type='text' 
                        className='form-control' 
                        placeholder=''
                        defaultValue={object.question}
                        onChange={(event) => {object.question = event.target.value;}}            
                      />
                    </FormGroup>
                    
                
                  </div>
                  <div className="">
                    <Button className="btn-save" loading={isLoading} onClick={this.createQuestionGroup.bind(this)}>Save</Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </PageWrapGray>
        </div>
      </div>
    );
  }
}

export default QuestionGroupAddPage;
