import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import SearchBars from 'components/SearchBars';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import EquipmentsTable from 'components/Tables/Equipments';
import Card from 'components/Card';
import Sticky from 'react-stickynode';
import styled from '@emotion/styled';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);  
`

@inject('store', 'equipmentStore') @observer
class EquipmentsIndexPage extends Component {

  @observable visible = true;

  render() {
    let content;
    let {
      equipmentStore: {
        equipments: {
          isLoading,
          isError
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="no results" description="No records found." />;
    }
    if (!isError && !isLoading) {
      content = <EquipmentsTable />
    }
    return (
      <div>
        <Helmet>
          <title>Equipment | FieldNexus</title>
        </Helmet>
        <PageWrapGray>
        <Sticky enabled={true} top={0} innerZ={9}>
          <SearchBars.Equipment />
        </Sticky>
        
          <Card>
            {content}
          </Card>
        
        </PageWrapGray>
      </div>
    )
  }

};

export default EquipmentsIndexPage;