import { observable, action, computed } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';
var FileDownload = require('js-file-download');

class TimesheetStore {
  @observable allEntriesSelected = false;
  @observable timelogs = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable timelog = {
    isLoading: false,
    isError: false,
    object: null
  };

  @action
  update(timelog) {
    this.timelog.isLoading = true;
    let promise = Api.put(`/timesheets/${timelog.attributes.id}`, {time_log: timelog.attributes})

    promise
      .then((response) => {
        this.timelog.isLoading = false;
        this.loadTimelogs();
      })
      .catch((error) => {
        this.timelog.isLoading = false;
        this.timelog.isError = true;
      })
    
    return promise;
  }

  @action
  deleteTimelog(timelog) {
    this.timelogs.isLoading = true;
    let promise = Api.delete(`/timesheets/${timelog.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadTimelogs();
      })
      .catch((error) => {
        this.timelogs.isLoading = false;
        this.timelogs.isError = true;
      })
    
    return promise
  }

  @computed
  get timelogEntriesSelected() {
    return this.timelogs.objects.some((timelog) => {
      return timelog.checked;
    })
  }

  @computed
  get selectedTimelogs() {
    return (
      this.timelogs.objects
        .filter((timelog) => timelog.checked)
    )
  }

  @action
  approveTimesheets = () => {
    let promise = Api.post('/timesheets/change_status', 
      {
        action: 'approve',
        time_log_ids: this.selectedTimelogs.map((timelog) => timelog.id)
      }
    )

    promise
      .then((response) => {
        this.loadTimelogs();
      })

    return promise;
  }

  @action
  rejectTimesheets = () => {
    let promise = Api.post('/timesheets/change_status', 
      {
        action: 'reject',
        time_log_ids: this.selectedTimelogs.map((timelog) => timelog.id)
      }
    )

    promise
      .then((response) => {
        this.loadTimelogs();
      })
      
    return promise;
  }

  @action
  loadTimelogs() {
    this.allEntriesSelected = false;
    this.timelogs.isLoading = true;
    const {
      per_page,
      page,
      order,
      where
    } = stores.viewStore.timesheetSearch;
    let promise = Api.post('/timesheets/search', 
      {
        search: {
          where: where,
          order: order,
          per_page: per_page,
          page: page
        }
      }
    );

    promise
      .then((response) => {
        this.timelogs.isError = false;
        this.timelogs.isLoading = false;
        this.timelogs.isError = false;
        this.timelogs.objects = response.data.data.map(timelog => {
          return { ...timelog, checked: false }
        })
        this.timelogs.meta = response.data.meta;
      })
      .catch((error) => {
        this.timelogs.isLoading = false;
        this.timelogs.isError = true;
        this.timelogs.objects = [];
      })
    
    return promise;
  }

  @action
  download_excel(searchObj) {
    let promise = Api.post('/timesheets/excel_bulk_download', { search: searchObj }, { responseType: 'arraybuffer' })

    promise
      .then((response) => {
        FileDownload(response.data, 'TimeSheets.xls');
      })
      .catch((error) => {
      })

    return promise;
  }
};

export default TimesheetStore