import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import TextTruncate from 'react-text-truncate';
import Pagination from 'components/Pagination';
import Footer from '../Footer';
import { 
  Button, Tag, Alert, Intent, Divider
} from '@blueprintjs/core';
import Toast from 'helpers/toaster';
import qs from 'qs';
import { formatTimeOnly, formatDateOnly } from 'helpers/time';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';
import { currencyFormattedAmount } from 'helpers/currency';

@inject('store', 'workReportStore', 'viewStore', 'userStore', 'clientStore') @observer
class WorkReportsTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable workReport;

  decideAppearance = (status) => {
    switch (status) {
      case 'submitted':
        return 'success'
      default:
        break;
    }
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        workReportSearch
      }
    } = this.props;
    this.props.viewStore.workReportSearch = {
      ...workReportSearch,
      page: newPage
    };
    this.props.viewStore.workReports();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.workReportStore.deleteWorkReport(this.workReport)
      .then(this.onSuccessfulDeleteWorkReport.bind(this))
      .catch(this.onFailedWorkReportDelete.bind(this))
  }

  onSuccessfulDeleteWorkReport(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Work Report deleted successfully!', intent: Intent.SUCCESS })
    this.workReport = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedWorkReportDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete work report!', intent: Intent.DANGER })
  }

  handleWorkReportClick(event, workReport) {
    switch (event.label) {
      case 'Edit':
        this.props.viewStore.ShowWorkOrder({ uuid: workReport.attributes.id })
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.workReport = workReport;
        break;
      default:
    }
  }

  render() {
    let {
      viewStore,
      viewStore: {
        jobTab: {
          section
        }
      },
      workReportStore: {
        workReports: {
          objects,
          meta
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
        <table className="table table-data-presentation table-hover" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Job</th>
              <th>Work Order #</th>
              <th>Invoice Number</th>
              <th>Customer</th>
              <th>Created By</th>
              <th>Description</th>
              <th>PO</th>
              <th>Grand Total</th>
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((workReport) => {
                return (
                  <tr key={workReport.id}>
                    {/* <td>
                      <AvatarGroup
                        appearance="stack"
                        onAvatarClick={console.log}
                        data={_.sampleSize(toJS(store.avatars), 2)}
                        size="small"
                      />
                    </td> */}
                    <td>
                      <TableCellWithSubtext
                        mainText={formatDateOnly(workReport.attributes.created_at)}
                        subText={formatTimeOnly(workReport.attributes.created_at)}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/jobs/${workReport.attributes.job.id}/${section}`}
                        mainText={workReport.attributes.event_number}
                      />
                    </td>
                    <td>
                      <div className="field-title" style={{marginRight: 5}}>
                        <a href={`/dashboard/work-reports/${workReport.id}/show`} >
                          {workReport.attributes.number}
                        </a>
                      </div>
                      <Tag>
                        {workReport.attributes.status}
                      </Tag>
                    </td>
                    <td>
                      {workReport && workReport.attributes && workReport.attributes.invoice_number ? workReport.attributes.invoice_number :''}
                    </td>
                    <td>
                      <TableCellWithSubtext
                        mainLink={`/dashboard/clients/${workReport.attributes.client.id}?${qs.stringify(viewStore.clientShowSearch, { encode: false })}`}
                        mainText={workReport.attributes.client.name}
                        subText={workReport.attributes.location && workReport.attributes.location.full_address}
                      />
                    </td>
                    {/* <td>{workReport.attributes.requested_by}</td> */}
                    <td>
                      {/* <AvatarGroup
                        appearance="stack"
                        onAvatarClick={console.log}
                        data={_.sampleSize(toJS(store.avatars), 1)}
                        size="small"
                      /> */}
                      {/* <div className="field-title">
                        <a 
                          href={`/dashboard/users/${workReport.attributes.created_by.id}/show?${qs.stringify(viewStore.userShowSearch, { encode: false })}`} 
                        >
                          {workReport.attributes.created_by.full_name}
                        </a>
                      </div> */}
                      <TableCellWithSubtext
                        mainLink={`/dashboard/users/${workReport.attributes.created_by.id}/show?resource=vacations`}
                        mainText={workReport.attributes.created_by.full_name}
                        subText={workReport.attributes.created_by.email}
                      />
                    </td>
                    <td width='200'>
                      <TextTruncate
                        line={2}
                        truncateText="…"
                        text={workReport.attributes.description}
                      />
                    </td>
                    <td>
                      <TableCellWithSubtext
                        subText={workReport.attributes.po_number}
                      />
                    </td>
                    {/* <td>
                      <Tag>
                        {workReport.attributes.status}
                      </Tag>
                    </td> */}
                    <td>
                      <Tag>
                        { 
                          currencyFormattedAmount(workReport.attributes.grand_total)
                        }
                      </Tag>
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit" minimal={true} small={true} onClick={() => window.location.href = `/dashboard/work-reports/${workReport.attributes.id}/show` }>
                          <img src="/edit-light-icon.svg"/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleWorkReportClick({ label: 'Delete' }, workReport)}>
                          <img src="/delete-light-icon.svg" />
                        </Button>
                      </div>
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            All associated data of this work order (such as line items, equipments, images, etc) will also be deleted. Are you sure you want to do this?
          </p>
        </Alert>
        <Footer>
          <div className="bp3-navbar-group responsive-pagination bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </React.Fragment>
    )
  }
}

export default WorkReportsTable;