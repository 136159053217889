import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import SearchBars from 'components/SearchBars';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import FrequencyTable from 'components/Tables/Frequency';
import Card from 'components/Card';
import Sticky from 'react-stickynode';
import styled from '@emotion/styled';
import SettingsSidebar from '../preferences/settings-sidebar';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`

@inject('store', 'frequencyStore') @observer
class FrequencyIndexPage extends Component {

  @observable visible = true;

  render() {
    let content;
    let {
      frequencyStore: {
        frequencies: {
          isLoading,
          isError
        }
      },store
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="no results" description="No records found." />;
    }
    if (!isError && !isLoading) {
      content = <FrequencyTable />
    }
    return (
      <div>
        <Helmet>
          <title>Frequency | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
              <SettingsSidebar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
              <Sticky enabled={true} top={0} innerZ={9}>
                <SearchBars.FrequencySearch />
              </Sticky>
              <Card>
                {content}
              </Card>
            </PageWrapGray>
          </div>
        </div>
      </div>
    )
  }
};

export default FrequencyIndexPage;