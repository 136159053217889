import React, { Component } from 'react';
import { 
  Tab, Tabs, Spinner, Button, Dialog, Classes
} from "@blueprintjs/core";
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import styled from '@emotion/styled';

const SecondSidebar = styled('div')`     
    width: 100%;
    height: calc(100vh - 75px);
    border-right: 1px solid #E6E8F1;
    padding: 0px 0px 15px;        
    background-color: #ffffff;
    position: sticky;
    top: 45px;
`

@inject('store', 'viewStore', 'userStore') @observer
class UserSidebar extends Component {

  @observable isOpenUserSidebar = false;

  render(){
    let {
      viewStore
    } = this.props;
    return(
      <SecondSidebar className="sidebar-mobile">
        <div className="mobile-dropdown">
          <div className="setting-icon"><img src="/settings.svg" onClick={() => this.isOpenUserSidebar = !this.isOpenUserSidebar}/></div>  
          <div className={this.isOpenUserSidebar ? "ssidebar-wrap " : "ssidebar-wrap ssidebar-wrap-hide" }>
            {/* <p className="mb-2"><strong>User</strong></p> */}
            <ul className="list-unstyled setting-list">              
              <li className={(viewStore.currentView === "Pages.Users.Index" || viewStore.currentView === "Pages.Users.Show") ? 'active' : ''}><a onClick={() => { viewStore.users()  }}>List</a></li>
              <li className={(viewStore.currentView === "Pages.Users.Map" || viewStore.currentView === "Pages.Users.MapShow") ? 'active' : ''}><a onClick={() => { viewStore.userMap()  }}>Map</a></li>
              <li className={viewStore.currentView === "Pages.Users.Vacations" ? 'active' : ''}><a onClick={() => { viewStore.usersVacations() }}>Vacations</a></li>              
            </ul>            
            </div>
        </div>
      </SecondSidebar>
    )
  }
}
  
export default UserSidebar;