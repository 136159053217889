import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Button, Intent, Alert, Icon
} from "@blueprintjs/core";
import { observable } from 'mobx';
import Toast from 'helpers/toaster';
import Icons from '../../svgs';

@inject('invoiceStore', 'quickbooksStore') @observer
class QuickbooksCustomer extends Component {

  @observable showLoader = false;
  @observable isLinkCustomerWithQuickbooks = false;

  linkCustomer() {
    this.isLinkCustomerWithQuickbooks = true
  }

  handleCustomerLinkCancel() {
    this.isLinkCustomerWithQuickbooks = false
  }

  handleSaveConfirm() {
    this.isLinkCustomerWithQuickbooks = false
    this.showLoader = true;
    this.props.quickbooksStore.linkClientWithQuickbooksCustomer(this.props.customer)
      .then((response) => {
        this.showLoader = false;
        this.props.onSuccess();
        Toast.show({ icon: 'thumbs-up', message: "Client Linked with QuickBooks customer successfully.", intent: Intent.SUCCESS })
      })

  }
 
  render() {
    let {customer} = this.props;
    return(
      <React.Fragment>
        <div className="quickboos-client-info" key={Math.random()}>
          <div className="qb-name-field">
            <div className="qb-field-lable">
              <div><span>Client name:</span> {customer.given_name} {customer.family_name}</div>
              <div><span>Company name:</span> {customer.display_name}</div>
              <div><span>Address:</span> {customer.shipping_address && customer.shipping_address.line1} {customer.shipping_address && customer.shipping_address.city} {customer.shipping_address && customer.shipping_address.postal_code}</div>
            </div>
            <div className="qb-link">
              <Button
                onClick={this.linkCustomer.bind(this)}
                loading={this.showLoader}
                className=""
              >
                <Icons.LinkIcon /> Link
              </Button>
            </div>
          </div>
        </div>
        <Alert
          cancelButtonText="No"
          confirmButtonText="Yes"
          icon="help"
          intent={Intent.PRIMARY}
          isOpen={this.isLinkCustomerWithQuickbooks}
          onCancel={this.handleCustomerLinkCancel.bind(this)}
          onConfirm={this.handleSaveConfirm.bind(this)}          
        >
            Are you sure you want to link client with this QuickBooks customer?
            <div className="dark-text" style={{paddingTop: 5}}><b>{customer.display_name}</b></div>
            {customer.shipping_address && <div><Icon icon="map-marker" iconSize={12} style={{marginRight: 5}} />{customer.shipping_address.line1} {customer.shipping_address.city} {customer.shipping_address.postal_code}</div>}
        </Alert> 
      </React.Fragment>
    )
  }

}

export default QuickbooksCustomer;