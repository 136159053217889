import { observable, action, toJS } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

const EMPTY_QUESTION_OBJECT = {
  name: null,
  question: null
}

class QuestionGroupStore {

  @observable groups = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable group = {
    isLoading: false,
    isError: false,
    object: EMPTY_QUESTION_OBJECT
  };

  @observable EMPTY_QUESTION_OBJECT = {
    isLoading: false,
    isError: false
  };

  @action
  deleteQuestion(group) {
    this.group.isLoading = true;
    let promise = Api.delete(`/question_groups/${group.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadQuestionGroups();
      })
      .catch((error) => {
        this.group.isLoading = false;
        this.group.isError = true;
      })
    
    return promise
  }

  @action
  updateQuestionGroup(object) {
    let params = {
      uuid: object.attributes.id
    }
    this.group.isLoading = true;
    let promise = Api.put(`/question_groups/${object.attributes.id}`, {question_group: toJS(object.attributes)})

    promise
      .then((response) => {
        this.group.isLoading = false;
        this.group.isError = false;
        stores.viewStore.questionGroupSearch.term = '*';
        stores.viewStore.questionGroups();
        stores.question.loadQuestionGroups(); 
      })
      .catch((error) => {
        this.group.isLoading = false;
        this.group.isError = true;
      })

    return promise;
  }

  @action
  createQuestionGroup() {
    this.group.isLoading = true;
    let promise = Api.post(`/question_groups/`, {question_group: this.group.object})   

    promise
      .then((response) => {
        this.group.isLoading = false;
        this.group.isError = false;       
        this.group.object = response.data.data;  
        stores.viewStore.questionGroups();
        stores.question.loadQuestionGroups();    
      })
      .catch((error) => {
        this.group.isLoading = false;
        this.group.isError = true;       
      })

    return promise;
  }

  @action
  loadPublicQuestionGroups = () => {
    this.groups.isLoading = true;
    const {
      per_page,
      page,
      order,
      where,
      term
    } = stores.viewStore.questionGroupSearch;
    let promise = Api.post(`/public/question_groups/search?x_tenant_id=${stores.preferenceStore.tenant_id}`, {
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.groups.isLoading = false;
        this.groups.isError = false;
        this.groups.objects = response.data.data;
        this.groups.meta = response.data.meta;
      })
      .catch((error) => {
        this.groups.isLoading = false;
        this.groups.isError = true;
        console.error(error)
      })
    
    return promise;
  }
  

  @action
  loadQuestionGroups = () => {
    this.groups.isLoading = true;
    const {
      per_page,
      page,
      order,
      where,
      term
    } = stores.viewStore.questionGroupSearch;
    let promise = Api.post('/question_groups/search', { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.groups.isLoading = false;
        this.groups.isError = false;
        this.groups.objects = response.data.data;
        this.groups.meta = response.data.meta;
      })
      .catch((error) => {
        this.groups.isLoading = false;
        this.groups.isError = true;
      })
    
    return promise;
  }

  @action
  loadQuestionGroup = (params) => {
    this.group.isLoading = true;
    let promise = Api.get(`/question_groups/${params.uuid}`)

    promise
      .then((response) => {
        this.group.isLoading = false;
        this.group.object = response.data.data;
        stores.viewStore.questionGroupShow(params,response.data.data.attributes.name);
      })
      .catch((error) => {
        this.group.isLoading = false;
        this.group.isError = true;
        console.error(error)
      })

    return promise;
  }


};

export default QuestionGroupStore;