import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup, Button, Dialog, Classes, Intent, Checkbox, Spinner
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';

@inject('taxStore', 'xeroStore') @observer
class TaxXeroModal extends Component {

  handleCloseDailog() {
    // clearing object
    this.props.taxStore.tax.object = {}
    this.props.onClose()
  }

  updateTax() {
    let { taxStore } = this.props;
    taxStore.update()
      .then(this.onSuccessfullyUpdateTax.bind(this))
      .catch(this.onFailCreateTax.bind(this))
  }

  onSuccessfullyUpdateTax(response) {
    this.props.onSuccess()
    Toast.show({ message: "Tax updated successfully.", intent: Intent.SUCCESS })
  }

  createTax() {
    let { taxStore } = this.props;
    this.props.taxStore.tax.object.tax_type = "OUTPUT";
    taxStore.createTaxOnXero()
      .then(this.onSuccessfullyCreateTax.bind(this))
      .catch(this.onFailCreateTax.bind(this))
  }

  onSuccessfullyCreateTax(response) {
    this.props.onSuccess()
    Toast.show({ message: "Tax created successfully.", intent: Intent.SUCCESS })
  }

  onFailCreateTax(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ message: "Something went wrong. Failed to create/update tax", intent: Intent.DANGER })
    }
  }

  render() {
    let {
      title,
      edit,
      taxStore: {
        tax: {
          isLoading,
          object
        }
      },
      xeroStore: {
        xeroTaxCodes: {
          objects
        }
      }
    } = this.props;
    return(
      <Dialog
        title={title}
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Name"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Name'
              defaultValue={object.name}
              onChange={(event) => { object.name = event.target.value } } 
            />
          </FormGroup>

          <FormGroup
            label="Percentage (%)"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <input 
              type='number' 
              className='bp3-input bp3-fill' 
              placeholder='Tax percentage (%)'
              defaultValue={object.percentage_value || ""}
              onChange={(event) => { object.percentage_value = event.target.value } } 
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseDailog.bind(this)}>Close</Button>
            <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.createTax.bind(this)}>
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
};

export default TaxXeroModal;