import React from 'react';
import PropTypes from 'prop-types';
import StartOnDate from './OnDate';

const Start = ({
  id,
  start: {
    onDate,
  },
  handleChange,
  translations
}) => (
  <div>
    <div className="form-group row">
      <div className="col-md-12 mb-3">
        <label>When would you like the recurring job to start?</label>
        <StartOnDate id={id} onDate={onDate} handleChange={handleChange} translations={translations} />
      </div>
    </div>
  </div>
);

Start.propTypes = {
  id: PropTypes.string.isRequired,
  start: PropTypes.shape({
    onDate: PropTypes.object.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Start;
