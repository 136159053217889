import React, { Component } from 'react';
import { observable } from 'mobx';
import {
  Button,
  ButtonGroup,
  Popover, Position,
  Spinner, Icon
} from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import {
  Navbar, Alignment
} from '@blueprintjs/core';
import moment, { parseZone } from 'moment';
import $ from 'jquery';
import _ from 'lodash';
import 'fullcalendar';
import 'fullcalendar-scheduler';
import 'fullcalendar/dist/fullcalendar.css';
import 'fullcalendar-scheduler/dist/scheduler.css';
import { inject, observer } from 'mobx-react';
import { socket, channels } from '../services/pusher';
import Toast from 'helpers/toaster';
import { router } from '../App';
import Lockr from 'lockr';
import qs from 'qs';
import { currentTimezone } from '../helpers/time';
import { Helmet } from 'react-helmet';
import UnassignedJob from '../pages/jobs/unassigned_job';
import IncompleteJob from '../pages/jobs/incomplete_job';
import ServiceTypePicker from 'components/ServiceTypePicker';
import TechnicianPicker from 'components/TechnicianPicker';
import { formatTimeOnly, formatDateOnly } from '../helpers/time';


@inject('dashboard', 'viewStore', 'store', 'jobStore') @observer
class DispatchBoard extends Component {

  @observable zoom_in = false;
  @observable currentSideTab = "";

  constructor(props) {
    super(props);
    let {
      dashboard,
      dashboard: {
        loadCalendarUsers
      }
    } = this.props;
    this.loadUsers = loadCalendarUsers.bind(dashboard);
  }

  state = {
    isBoxVisible: false
  };

  toggleBox = (sideTab) => {
    if(sideTab != this.currentSideTab) {
      if(this.state.isBoxVisible === true) {
        this.currentSideTab = sideTab;
      } else {
        this.currentSideTab = sideTab;
        this.setState(prevState => ({ isBoxVisible: !prevState.isBoxVisible }));
      }
    } else {
      this.currentSideTab = sideTab;
      this.setState(prevState => ({ isBoxVisible: !prevState.isBoxVisible }));
    }
  };

  componentWillUnmount() {
    const currentUser = Lockr.get('currentUser');
    channels.dispatchBoard().unbind();
    if (currentUser) {
      socket.unsubscribe(`${currentUser.attributes.tenant_name}-dispatchBoard`);
    }
  }

  datePicker(date) {
    return (
      <DatePicker
        defaultValue={moment.unix(date).toDate()}
        onChange={this.goToDate.bind(this)}
        showActionsBar={true}
        maxDate={moment().add(500, 'years').toDate()}
        minDate={moment().subtract(500, 'years').toDate()}
      />
    )
  }

  goToDate = (date) => {
    if (date && this.props.viewStore.dashboardSearch.date !== moment(date).unix()) {
      this.props.viewStore.dashboardSearch.date = moment(date).unix();
      this.props.viewStore.dashboard();
      this.renderCalendar();
      $('#calendar').fullCalendar('gotoDate', date);
    }
  }

  renderCalendar() {
    let {
      updateJob,
      props: {
        viewStore: {
          dashboardSearch: {
            view,
            date
          }
        },
        jobStore: {
          loadUnassignedJobs
        },
        dashboard,
        dashboard: {
          loadCalendarAssignments,
          loadCalendarUsers
        }
      }
    } = this;

    $('#calendar').fullCalendar({
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      timeZone: currentTimezone(),
      header: false,
      height: 'parent',
      defaultView: view,
      editable: true,
      defaultDate: moment.unix(Number(date)),
      businessHours: {
        dow: [1, 2, 3, 4, 5],
        start: '06:00',
        end: '17:30'
      },
      resourceAreaWidth: "250px",
      resourceLabelText: "Team",
      nowIndicator: true,
      eventBackgroundColor: '#D5DDF6',
      eventBorderColor: '#97B0F8',
      eventTextColor: '#1A1A1A',
      scrollTime: `${(new Date()).getHours()}:00:00`,
      slotDuration: { hours: 0.25 },
      droppable: true,
      dropAccept: '.fc-events',
      eventReceive: function (info) {
        let endDate = moment(info.end_date);
        let startDate = moment(info.start_date);
        let seconds = endDate.diff(startDate, 'seconds');
        info.end = moment(info.start);
        info.end.add(seconds, 'seconds');
        info.end_date = info.end;
        info.start_date = info.start;
        updateJob(info)
          .finally(() => {
            $('#calendar').fullCalendar('refetchEvents');
            setTimeout(() => {
              window.stores.jobStore.loadUnassignedJobs()
              .then(() => {
                $('#external-events .fc-events').each(function () {
                  $(this).draggable({
                    zIndex: 9999999,
                    revert: true,
                    revertDuration: 0
                  });
                });
              })
            }, 3000);
          })
      },
      eventDrop: function (info) {
        updateJob(info)
          .finally(() => {
            $('#calendar').fullCalendar('refetchEvents');
          })
      },
      eventResize: function (info) {
        console.log(info);
        updateJob(info)
          .finally(() => {
            $('#calendar').fullCalendar('refetchEvents');
          })
      },
      eventMouseover: function (calEvent, jsEvent, view) {
        if (view.name === 'timelineDay') {
          $(this).css('border-color', '#FFC200');
          $(this).css('background-color', '#FFF786');
        }
      },
      resourceRender: function (resource, el, view) {
        let targetResource = el[0];
        if (targetResource) {
          let html = `<img src="${resource.avatar_url}" class="resourceImage" />`
          $(targetResource).prepend(html)
        }
      },
      eventMouseout: function (calEvent, jsEvent, view) {
        if (view.name === 'timelineDay') {
          $(this).css('border-color', '#97B0F8');
          $(this).css('background-color', '#D5DDF6');
        }
      },
      eventClick: function (calEvent, jsEvent, view) {
        !calEvent.isVacation && router.navigate(`/dashboard/jobs/${calEvent.jobId}`);
      },
      eventRender: function (event, element, view) {
        if (event.rendering === 'background') {
          element
            .css('background-color', "#F5F7FA");

          var text = event.notes;
          var canvas = document.createElement("canvas");
          var fontSize = 20;
          canvas.setAttribute('height', 34);
          var context = canvas.getContext('2d');

          var color1 = "#FFB1CF",color2="#F5F7FA";
          var numberOfStripes = 100;		
          for (var i=0;i<numberOfStripes*2;i++){
            var thickness = 600 / numberOfStripes;
            context.beginPath();
            context.strokeStyle = i % 2?color1:color2;
            context.lineWidth = i % 2?thickness/5:thickness;
            context.lineCap = 'round';

            context.moveTo(i*thickness + thickness/2 - 300,0);
            context.lineTo(0 + i*thickness+thickness/2,300);
            context.stroke();
          }

          context.fillStyle = '#1D3041';
          context.fontSize = fontSize + 'px';
          context.fillText(text, 0, fontSize);

          element.css({ 'background-image': "url(" + canvas.toDataURL("image/png") + ")" });
          return null;
        }
        if (view.name === 'basicWeek' && event.isVacation) {
          element
            .find('.fc-title')
            .css('height', 15)
            .css('border-radius', 10)
            .css('padding', '0 5')
          element
            .find(".fc-content")
            .wrap( "<div class='vacation-card'></div>")
          
          const endDate = event.end || event.start
          const startDateFormatted = moment(event.start).format("MMM D, YY h:mma");
          const endDateFormatted = moment(endDate).format("MMM D, YY h:mma");
          const combined = moment(event.start).format("MMM D, YY h:mma") + " - " + moment(endDate).format("h:mma");
          const sameStartAndEnd = moment(event.start).format("MM/DD/YYYY") === moment(endDate).format("MM/DD/YYYY");
          const morethanOnedayJob = `${startDateFormatted} - ${endDateFormatted}`;

          element
            .find(".fc-content")
            .append("<div class='technician-name'><span>User -</span> " + event.user + "</div>")
            .append(`<div class='vacation-date'>${sameStartAndEnd ? combined : morethanOnedayJob}</div>`)

        } else {
          element
            .find('.fc-title')
            //.css('background-color', event.statusColor)
            .css('height', 15)
            //.css('color', '#fff')
            .css('border-radius', 10)
            .css('padding', '0 5')
          if (view.name === 'listDay' || view.name === 'listWeek') {
            element
              .find('.fc-event-dot')
              .css('background-color', event.statusColor)

            element
              .find(".fc-list-item-title")
              .prepend("<div class='card-top'>" + "<div class='event-number'>" + '#' + event.eventNumber + "</div>" + "<div class='event-tag'>" + event.serviceType.name + "</div>" + "</div>")
              .append("<div class='client-name'>" + event.clientName + "</div>")
              .append("<div class='technician-name'>" + event.technicianName + "</div>")

            element.find(".fc-list-item-title a").hide()

            // For day list fix - removed undefined with time
            if (view.name === 'listDay') {
              const timeRange = moment(event.start).format("h:mma") + " - " + moment(event.endForDisplay).format("h:mma");
              element
                .find(".fc-list-item-time")
                .html(timeRange)
            }

          } else {
            let sideStatusIndicator = $("<div class='fc-event-status-indicator'></div>");
            if (view.name === 'basicWeek') {
              sideStatusIndicator = sideStatusIndicator.css('backgroundColor', '#FFFFFF');
            } else {
              sideStatusIndicator = sideStatusIndicator.css('backgroundColor', event.statusColor);
            }

            element
              .find(".fc-content")
              .before(sideStatusIndicator)

            // if (event.isRecurringJob) {
            //   element
            //     .find(".fc-content")
            //     .append("<span class='bp3-icon-standard bp3-icon-repeat'></span>");
            // }

            const startDateFormatted = moment(event.start).format("MMM D, YY h:mma");
            const endDateFormatted = moment(event.endForDisplay).format("MMM D, YY h:mma");
            const combined = moment(event.start).format("MMM D, YY h:mma") + " - " + moment(event.endForDisplay).format("h:mma");
            const sameStartAndEnd = moment(event.start).format("MM/DD/YYYY") === moment(event.endForDisplay).format("MM/DD/YYYY")

            var morethanOnedayJob = `${startDateFormatted} - ${endDateFormatted}`
            var html = `<div class='card-top not-draggable'> <div class='event-number not-draggable'> #${event.eventNumber} </div> <div class='event-tag not-draggable'> ${event.serviceType.name} </div> </div>
          <div class='h-client-title not-draggable'><span class="for-span not-draggable">For </span> ${event.title} </div>
          <div class="not-draggable"><ul class="list-unstyled">
          <li class='h-client icon-marker not-draggable'>${event.clientName}</li>
          <li class='h-client-address not-draggable'>${event.fullAddress}</li>
          <li class='h-client icon-user not-draggable'>${event.technicianName}</li>
          <li class='h-card-date icon-calender not-draggable'>${sameStartAndEnd ? combined : morethanOnedayJob}</li>
          </ul></div>                   
          <div class='add-task-button not-draggable'><button>More Info</button></div>
          `;
            let afterNinePm = (moment(event.endForDisplay).get('date') == moment.unix(Number(date)).get('date') && moment(event.endForDisplay).get('hour') >= 21) || (moment(event.endForDisplay).get('date') > moment.unix(Number(date)).get('date'));

            let card = element
              .find(".fc-content")
              .prepend(`<div class='card-hover-block ${afterNinePm ? 'bottom-config' : ''}'>` + html + "</div>")
              .prepend(`<div class='card-top'><div class='event-wrapper'><div class='event-number'> #${event.eventNumber}</div><div class='repeat-job ${event.isRecurringJob ? '' : 'hide'}'><span class='bp3-icon-standard bp3-icon-repeat repeat-icon-new'></span></div><div class='invoiced-job ${event.job_status === "invoiced" ? '' : 'hide'}'><span class='bp3-icon-standard bp3-icon-tick-circle bp3-intent-success tick-icon'></span></div></div><div class='event-tag'>${event.serviceType.name}</div></div>`)
              .append("<div class='card-row'>" + "<div class='client-name-bold'>" + event.clientName + "</div>" + "</div>")
              .append("<div class='card-row'>" + "<div class='full-address'>" + event.fullAddress + "</div>" + "</div>")

            if (_.isArray(event.technicianName)) {
              _.forEach(event.technicianName, function (technician) {
                if (event.assignments && event.assignments.length > 0) {
                  let assignment = _.find(event.assignments, function (obj) { return obj.technician_name === technician })
                  if (assignment && assignment.color) {
                    card.append("<div class='card-row'>" + "<div class='technician-name'>" + "<div class='docs-color-bubble' style='margin-right: 5px; background-color:" + assignment.color + "'" + "></div>" + technician + "</div>" + "</div>")
                  } else {
                    card.append("<div class='card-row'>" + "<div class='technician-name'>" + technician + "</div></div>")
                  }
                } else {
                  card.append("<div class='card-row'>" + "<div class='technician-name'>" + technician + "</div></div>")
                }
              });
            } else {
              card.append("<div class='card-row'>" + "<div class='technician-name'>" + event.technicianName + "</div></div>")
            }
            if (sameStartAndEnd) {
              card.append("<div class='card-bottom'>" + "<div class='card-date'>" + combined + "</div>" + "</div>")
            } else {
              card.append("<div class='card-bottom'>" + "<div class='card-date'>" + startDateFormatted + " - " + endDateFormatted + "</div>" + "</div>")
            }
          }
          element.find(".event-tag").css('backgroundColor', `#${event.serviceType.color}`)

          if (event.job_status === "invoiced") {
            element
              .find(".fc-content")
              .parent()
              .addClass("invoicedJobBgColor")
          }
        }
      },
      eventAfterAllRender: function() {
        $('.fc-event').on(
          {
            'mouseover': function () {
              $('.card-hover-block').hide();
              $(this).find('.card-hover-block').show();
            }
          }
        )

        $('.fc-event').find('.card-hover-block').on(
          {
            'mouseout': function() {
              $('.card-hover-block').hide();
            }
          }
        )
      },
      resources: loadCalendarUsers.bind(dashboard),
      events: loadCalendarAssignments.bind(dashboard),
      eventOverlap: function (stillEvent, movingEvent) {
        return stillEvent.rendering !== "background" && !stillEvent.isVacation;
      }
    });
    if (view === 'basicWeek' || view === 'timelineDay') {
      $('#calendar').fullCalendar('option', 'displayEventTime', false);
    } else {
      $('#calendar').fullCalendar('option', 'displayEventTime', true);
    }
  }

  componentDidMount() {
    channels
      .dispatchBoard()
      .bind('assignment-state-change', () => {
        $('#calendar').fullCalendar('refetchEvents');
      })
      .bind('toast-notification', (event) => {
        try {
          var audio = new Audio('music_marimba_chord.wav');
          audio.play();
        } catch (err) {
          // log to rollbar here
        }
        Toast.show({
          icon: 'notifications',
          message: event.message,
          intent: 'primary',
          timeout: 15000
        })
      })

    this.renderCalendar();
    var pageX_Drag, pageY_Drag;
    var disableDragSelector = '.fc-event';

    var calendarElement = $('#calendar');
    calendarElement.on('mousedown', '.fc-scroller', function (e) {
      var dragDisabledElms = $(e.target).parents(disableDragSelector).addBack(disableDragSelector);
      if (dragDisabledElms.length) return;

      pageX_Drag = e.pageX;
      pageY_Drag = e.pageY;

      $(window).off("mousemove", __mouseMove_Drag);
      $(window).on("mousemove", __mouseMove_Drag);
    });

    $(window).on('mouseup', __mouseUp_Drag);

    function __mouseUp_Drag() {
      $(window).off("mousemove", __mouseMove_Drag);
    }

    function __mouseMove_Drag(evt) {
      var offsetX = pageX_Drag - evt.pageX;
      var offsetY = pageY_Drag - evt.pageY;

      $('.fc-scroller', calendarElement).each(function () {
        var scroller = $(this);
        var newX = scroller.scrollLeft() + offsetX;
        var newY = scroller.scrollTop() + offsetY;

        scroller.scrollLeft(newX);
        scroller.scrollTop(newY);
      });

      pageX_Drag = evt.pageX;
      pageY_Drag = evt.pageY;
    }

    setTimeout(() => {
      this.onUnassignedOpen();
    }, 4000)

    setTimeout(() => {
      this.onInconpleteJobsOpen();
    }, 4500)
  }

  changeView = (view) => {
    let {
      viewStore
    } = this.props;
    viewStore.dashboardSearch.view = view;
    viewStore.dashboard();
    this.renderCalendar();
    $('#calendar').fullCalendar('changeView', view);
    $('#calendar').fullCalendar('render');
  }

  updateJob = (job) => {
    let status;
    if (job.resourceId !== "0" && job.status === 'unassigned') {
      status = 'assigned';
    } else if (job.resourceId === "0" && job.status !== 'unassigned') {
      status = "unassigned";
    } else {
      status = job.status;
    }
    return this.props.jobStore.updateJob({
      technician_id: job.resourceId === 0 ? null : job.resourceId,
      start_date: job.start.toISOString(),
      end_date: job.end.toISOString(),
      id: job.id,
      status: status
    })
  }

  onInconpleteJobsOpen = () => {
    this.props.jobStore.loadIncompleteJobs()
  }

  onUnassignedOpen = () => {
    this.props.jobStore.loadUnassignedJobs()
      .then(() => {
        $('#external-events .fc-events').each(function () {
          $(this).draggable({
            zIndex: 9999999,
            revert: true,
            revertDuration: 0
          });
        });
      })
    //this.props.store.isOpenUnassignedJob = !this.props.store.isOpenUnassignedJob
  }

  onServiceTypeChange(selections) {
    let selectedValues = selections.map((status) => status.value);
    let {
      props: {
        viewStore: {
          dashboardSearch
        }
      }
    } = this;

    this.props.viewStore.dashboardSearch = {
      ...dashboardSearch,
      service_type_id: selectedValues
    }
    this.props.viewStore.dashboard();
    $('#calendar').fullCalendar('refetchEvents');
  }

  onTechnicianChange(technicians) {
    let selectedValues = technicians.map((status) => status.value);
    let {
      props: {
        viewStore: {
          dashboardSearch
        }
      }
    } = this;

    this.props.viewStore.dashboardSearch = {
      ...dashboardSearch,
      technician_id: selectedValues
    }
    this.props.viewStore.dashboard();
    $('#calendar').fullCalendar('refetchResources');
    $('#calendar').fullCalendar('refetchEvents');
  }

  render() {
    const { isBoxVisible } = this.state;
    let {
      props: {
        dashboard,
        viewStore,
        viewStore: {
          dashboardSearch: {
            date,
            service_type_id,
            technician_id,
            view
          }
        }
      }
    } = this;
    let buttonDate = moment.unix(date).format("dddd, MMMM Do YYYY");
    const activeStyle = { backgroundColor: '#ebf1f5', boxShadow: 'none', textDecoration: 'none' };

    return (
      <div className="responsive-layout-navbar" style={{ zIndex: -2, height: 'calc(100vh - 130px)', backgroundColor: '#ffffff', paddingRight: '10px', paddingLeft: '10px' }}>
        <Helmet>
          <title>Dispatch Board | Field Nexus</title>
        </Helmet>

        <Navbar>
          <Navbar.Group className="leftSideCTA" align={Alignment.LEFT}>
            {/* <Navbar.Heading>Dispatch Board</Navbar.Heading> */}
            {/* <Navbar.Divider /> */}
            <Popover
              content={this.datePicker.bind(this)(date)}
              className="actionItem"
              position={Position.BOTTOM_LEFT}
              style={{ padding: '10px' }}
              usePortal={true} >
              <Button rightIcon='caret-down' icon='calendar'>
                {buttonDate}
              </Button>
            </Popover>
            <ServiceTypePicker 
              selectedOptions={service_type_id || []}
              maximumSelections={10}
              onChange={this.onServiceTypeChange.bind(this)}
              includeSelectAll={true}
            />
            <TechnicianPicker 
              selectedOptions={technician_id || []}
              onChange={this.onTechnicianChange.bind(this)}
              includeAdmins={true}
              includeUnassigned={true}
              includeSelectAll={true}
            />
            {
              dashboard.isLoading && <Button minimal={true}>
                <Spinner size={24}></Spinner>
              </Button>
            }
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            <div className="job-legents">
              <div className="docs-color-bubble unassigned-color"></div>
              <span className="legents">Unassigned</span>
              <div className="docs-color-bubble assigned-color"></div>
              <span className="legents">Assigned</span>
              <div className="docs-color-bubble confirmed-color"></div>
              <span className="legents">Confirmed</span>
              <div className="docs-color-bubble in-progress-color"></div>
              <span className="legents">In Progress</span>
              <div className="docs-color-bubble complete-color"></div>
              <span className="legents" style={{ marginRight: 15 }}>Complete</span>
            </div>
            <ButtonGroup>
              {/* <Button rightIcon='menu' onClick={() => { this.props.store.isOpen = !this.props.store.isOpen }}>
              </Button> */}

              <a href={`/dashboard?${qs.stringify(viewStore.CalendarSearchTimeline, { encode: false })}`} style={view === 'timelineDay' ? activeStyle : { textDecoration: 'none' }} icon='gantt-chart' className="bp3-button" >
                <span icon="gantt-chart" className="bp3-icon bp3-icon-gantt-chart"></span>
                <span className="bp3-button-text">Timeline</span>
              </a>

              <a href={`/dashboard?${qs.stringify(viewStore.CalendarSearchBasicWeek, { encode: false })}`} style={view === 'basicWeek' ? activeStyle : { textDecoration: 'none' }} icon='timeline-events' className="bp3-button" >
                <span icon="timeline-events" className="bp3-icon bp3-icon-timeline-events"></span>
                <span className="bp3-button-text">Week</span>
              </a>

              {/* <a href={`/dashboard?${qs.stringify(viewStore.CalendarSearchListWeek, { encode: false })}`} style={{ textDecoration: 'none' }} icon='th' className="bp3-button" >
                <span icon="th" className="bp3-icon bp3-icon-th"></span>
                <span className="bp3-button-text">Week List</span>
              </a> */}

              <a href={`/dashboard/assignments?${qs.stringify(viewStore.jobSearch, { encode: false })}`} style={{ textDecoration: 'none' }} icon='th-list' className="bp3-button" >
                <span icon="th-list" className="bp3-icon bp3-icon-th-list"></span>
                <span className="bp3-button-text">Assignments</span>
              </a>

              <a href={`/dashboard/jobs?${qs.stringify(viewStore.jobSearch, { encode: false })}`} style={{ textDecoration: 'none' }} icon='th-list' className="bp3-button" >
                <span icon="th-list" className="bp3-icon bp3-icon-th-list"></span>
                <span className="bp3-button-text">Jobs List</span>
              </a>

              <Button className="zoom-btn" onClick={() => { this.zoom_in = true; }} icon='zoom-in' />

            </ButtonGroup>
          </Navbar.Group>
        </Navbar>

        <div className="dispatch-board-wrapper">
          {/* <input type="checkbox" id="navigation" className="btn-unssg" defaultChecked={true} /> */}
          {/* <label htmlFor="navigation" className="button-open"> <span>Unassigned Jobs</span> </label> */}
          <label htmlFor="navigation" className={`button-open ${isBoxVisible ? "" : "right"} ${(isBoxVisible && this.currentSideTab === "unassignedJobs") ? "active" : ""}`} onClick={() => {this.toggleBox("unassignedJobs")}}> <span>Unassigned Jobs</span> </label>
          <label htmlFor="navigation" className={`button-open inCompleteJobBtn ${isBoxVisible ? "" : "right"} ${(isBoxVisible && this.currentSideTab === "incompleteJobs") ? "active" : ""}`} onClick={() => {this.toggleBox("incompleteJobs")}}> <span>Incomplete Jobs</span> </label>
          <div className={`section ${isBoxVisible ? "" : "hidden"}`}>
            <div className="" id="full-calender" className={this.zoom_in ? 'zoom-in' : ''}>
              {
                this.zoom_in &&
                <div className="calender-status-bar" id="statusBar">
                  <div className="current-data-time">
                    <Popover
                      content={this.datePicker.bind(this)(date)}
                      position={Position.BOTTOM_LEFT}
                      style={{ padding: '10px' }}
                      usePortal={true} >
                      <Button rightIcon='caret-down' icon='calendar'>
                        {buttonDate}
                      </Button>
                    </Popover>
                    {
                      dashboard.isLoading && <Button minimal={true}>
                        <Spinner size={24}></Spinner>
                      </Button>
                    }
                  </div>
                  <div className="zoom-out">
                    <a href="javascript:;" onClick={() => { this.zoom_in = false; }} id="zoomOut">
                      <Icon icon="zoom-out" />
                    </a>
                  </div>
                </div>
              }
              <div id='calendar'></div>
              {/* <div className='calender-wrap'>
                <a
                  className="btn-unassigned-job"
                  href="javascript:"
                  onClick={this.onUnassignedOpen}>Unassigned Jobs</a>
              </div> */}
            </div>
          </div>
          <div className={`usg-job-panel ${isBoxVisible ? "" : "hidden"}`}>
            {this.currentSideTab === "unassignedJobs" && <UnassignedJob />}
            {this.currentSideTab === "incompleteJobs" && <IncompleteJob />}
          </div>
        </div>


      </div>

      

    )
  }
}

export default DispatchBoard;
