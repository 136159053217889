import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SearchBars from 'components/SearchBars';
import {
  Spinner, NonIdealState
} from "@blueprintjs/core";
import Card from 'components/Card';
import { observer, inject } from 'mobx-react';
import WorkReportsTable from 'components/Tables/WorkReports';
import styled from '@emotion/styled';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit * 3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'workReportStore') @observer
class WorkReportsIndexPage extends Component {

  render() {
    let content;
    let {
      workReportStore: {
        workReports: {
          isLoading,
          isError
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner /></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (!isError && !isLoading) {
      content = <WorkReportsTable />
    }
    return (
      <div>
        <Helmet>
          <title>Work Orders | FieldNexus</title>
        </Helmet>
        <PageWrapGray>
            <SearchBars.WorkReport />
          <Card>
            {content}
          </Card>

        </PageWrapGray>
      </div>
    )
  }
};

export default WorkReportsIndexPage;