import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import Pagination from 'components/Pagination';
import {
  Tag, 
  Button,
  MenuItem,
  Divider
} from '@blueprintjs/core';
import Footer from '../Footer';
import { Alert, Intent } from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import qs from 'qs';
import TableCellWithSubtext from 'components/Tables/TableCellWithSubtext';

const ActionOptions = [
  {
    label: 'Edit',
    icon: 'edit', 
    intent: 'default' 
  },
  { 
    label: 'Delete',
    icon: 'trash',
    intent: 'danger' 
  }
]

@inject('store', 'serviceAgreementStore', 'viewStore') @observer
class ServiceAgreementsTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable serviceAgreement;

  onPaginationChange(newPage) {
    let {
      viewStore: {
        serviceAgreementSearch
      }
    } = this.props;
    this.props.viewStore.serviceAgreementSearch = {
      ...serviceAgreementSearch,
      page: newPage
    };
    this.props.viewStore.serviceAgreements();
  }

  handleServiceAgreementClick() {
    
  }

  render() {
    let {
      viewStore,
      serviceAgreementStore: {
        serviceAgreements: {
          objects,
          meta
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group bp3-align-left">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
          
        </Footer>
        <div className="card rounded-0 table-responsive">
          <table className="table table-data-presentation" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Number</th>
                <th>Start & End Time</th>
                <th>Title</th>
                <th>Reason for Call</th>
                <th>Client</th>
                <th>Service Type & Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                objects.map((service_agreement) => {
                  return (
                    <tr key={service_agreement.attributes.id}>
                      <td>
                        <TableCellWithSubtext
                          mainText={service_agreement.attributes.number}
                        />
                      </td>
                      <td>
                        <TableCellWithSubtext
                          mainText={service_agreement.attributes.formatted_start_time}
                          subText={service_agreement.attributes.formatted_end_time}
                        />
                      </td>
                      <td>
                        <TableCellWithSubtext
                          subText={service_agreement.attributes.title}
                        />
                      </td>
                      <td>
                        <TableCellWithSubtext
                          subText={service_agreement.attributes.reason_for_call}
                        />
                      </td>
                      <td>
                        <TableCellWithSubtext
                          mainLink={`/dashboard/clients/${service_agreement.attributes.client_id}?${qs.stringify(this.clientShowSearch, { encode: false })}`}
                          mainText={service_agreement.attributes.client && service_agreement.attributes.client.legal_name}
                          subText={service_agreement.attributes.location && service_agreement.attributes.location.full_address}
                        />
                      </td>
                      <td>
                        <div style={{display: 'flex', marginBottom: 5}}>
                          <div style={{marginRight: 5}}>
                            <Tag>
                              {service_agreement.attributes.service_type && service_agreement.attributes.service_type.name}
                            </Tag>
                          </div>
                          <div>
                            <Tag>
                              {service_agreement.attributes.status}
                            </Tag>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="action-buttons" minimal={true}>
                          <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.serviceAgreementShow({uuid: service_agreement.attributes.id})}>
                            <img src="/edit-light-icon.svg"/>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </React.Fragment>
    )
  }

};

export default ServiceAgreementsTable;