import React, { Component } from 'react';
import { 
  Divider, Intent,
  Button, Icon, Spinner,
  FormGroup, Menu
} from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import Card from 'components/Card';
import styled from '@emotion/styled';
import { observable } from 'mobx';
import AddServiceTypeModal from 'components/ServiceType/AddServiceTypeModal';
import { currencyFormattedAmount, currencySymbol } from 'helpers/currency';

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

@inject('serviceTypeStore','store','viewStore') @observer
class ServiceTypeCard extends Component {
 // @observable isOpenAddServiceTypeDialog = false; 
 // @observable isSelectDialogVisible = false;

  componentDidMount(){
    let {
      serviceTypeStore: {
        serviceTypeDetail: {
          object
        }
      }
    } = this.props;

    if(object && object.attributes.hourly_service){
      this.setState({
        isChecked: object.attributes.hourly_service,
      });
    }
  }

  render() {
    let {
      viewStore,
      store,
      serviceTypeStore: {
        serviceTypeDetail: {
          object
        }
      }
    } = this.props;
    return(
      <div className="bookings-sub-forms">
        <Card>
          <CardContent>
            <div>
              <div className="left-side-panel-servie-type">
                <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                  Edit Service Type
                </h4>
                <Button icon="edit" text="Edit"  onClick={() =>  store.isOpenUpdateServiceTypeDialog = true }/>
              </div>
            </div>
            <Divider style={{ margin: '16px 0px' }}></Divider>
            <div className="left-side-panel-servie-type">
              <FormGroup label="Name" >
                {object.attributes.name}
              </FormGroup>
              <FormGroup label="Price" >
                { currencyFormattedAmount(object.attributes.rate) }
              </FormGroup>
              <FormGroup label="Currency" labelFor="text-input">
                {currencySymbol()}
              </FormGroup>
            </div>
            <div className="left-side-panel-servie-type">
              <FormGroup label="Icon" labelFor="text-input"  style={{ display: 'flex' }}>
                <div className="service-image-main">
                  <div className="service-image-preview">
                    <img src={object && object.attributes.logo}  height='40' alt='icon'/>
                  </div>
                </div>
              </FormGroup>
              <FormGroup label="Color" labelFor="text-input" >
                <div className="colorDisplay" style={{backgroundColor: object.attributes.color ? `${object.attributes.color}` : "#000" }} />
              </FormGroup>
              <FormGroup label="Hourly Service" labelFor="text-input">
                {object.attributes.hourly_service ? 'Yes' : 'No'}
              </FormGroup>
            </div>
            <div className="left-side-panel-servie-type">
              <FormGroup label="Description" >
                {object.attributes.description}
              </FormGroup>
              <FormGroup label="Display on Booking Form" >
                {object.attributes.display_on_booking_form === true ? "Yes" : "No"}
              </FormGroup>
            </div>
            <div>
              <div className="left-side-panel-servie-type" style={{marginBottom:10}}>
                <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                Extras
                </h4>
                <Button icon={'plus'} small={true} onClick={() => viewStore.serviceTypeExtraAdd()}>
                  Add Extra
                </Button>
              </div>
            </div>
            
            <div className="card rounded-0 table-responsive">
              <table className="table table-hover" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Price </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    object.attributes.service_type_extra.map((service) => {
                      return (
                        <tr key={service.id}>
                          <td>{service.name}</td>
                          <td>{service.description}</td>
                          <td>{currencyFormattedAmount(service.price_cents/100)}</td>
                        </tr> 
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <div>
              <div className="left-side-panel-servie-type" style={{marginBottom:10,marginTop:10}}>
                <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                Pricing Parameter
                </h4>
                <Button icon={'plus'} small={true} onClick={() => viewStore.pricingParameterAdd()}>
                  Add Pricing Parameter
                </Button>
              </div>
            </div>
            <div className="card rounded-0 table-responsive">
              <table className="table table-hover" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Price </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    object.attributes.pricing_parameters.map((pricings) => {
                      return (
                        <tr key={pricings.id}>
                          <td>{pricings.name}</td>
                          <td>{currencyFormattedAmount(pricings.price_cents/100)}</td>
                        </tr> 
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
        <AddServiceTypeModal
          isOpen={store.isOpenUpdateServiceTypeDialog}
          onClose={store.handleCloseUpdateServiceTypeDailog.bind(store)}
          service_type_object={object.attributes}
          service_type_id={object.id}
        />
      </div>
    )
  }
};

export default ServiceTypeCard;