import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

class ProductServiceStore {

  @observable productsServices = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable productService = {
    isLoading: false,
    isError: false,
    object: {
      name:'',
      product_service_type:''
    }
  };

  @observable productServiceLink = {
    isLoading: false,
    isError: false,
    obj: {}
  }

  @action
  linkProductServiceWithQuickbooks() {
    this.productServiceLink.isLoading = true;
    
    let promise = Api.post(`/quickbooks/link_product_service`, {products_services: this.productServiceLink.obj})
    
    promise
      .then((response) => {
        this.productServiceLink.isLoading = false;
        this.productServiceLink.isError = false;
        // Clear object
        this.productServiceLink.obj = {};
      })
      .catch((error) => {
        this.productServiceLink.isLoading = false;
        this.productServiceLink.isError = true;
      })
    
    return promise
  }


  @action
  loadProductsServices() {
    this.productsServices.isLoading = true;
    //let promise = Api.get(`/product_services`)
    const {
      term,
      per_page,
      page,
      order,
      where
    } = stores.viewStore.productServiceIndexSearch;
    let promise = Api.post('/product_services/search', { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.productsServices.isLoading = false;
        this.productsServices.objects = response.data.data;
        this.productsServices.meta = response.data.meta;
      })
      .catch((error) => {
        this.productsServices.isLoading = false;
        this.productsServices.isError = true;
      })
    
    return promise
  }

  @action
  loadProductAndServicesForWorkReports = () => {
    this.productsServices.isLoading = true;
    const {
      term,
      per_page,
      page,
      order,
      where
    } = stores.viewStore.productServiceSearch;
    let promise = Api.post('/product_services/search', { 
      search: { 
        term: term,
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.productsServices.isLoading = false;
        this.productsServices.isError = false;
        this.productsServices.objects = response.data.data;
        this.productsServices.meta = response.data.meta;
      })
      .catch((error) => {
        this.productsServices.isLoading = false;
        this.productsServices.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  create() {
    this.productService.isLoading = true;
    let promise = Api.post(`/product_services`, {product_service: this.productService.object})

    promise
      .then((response) => {
        this.productService.isLoading = false;
        this.loadProductsServices();
      })
      .catch((error) => {
        this.productService.isLoading = false;
        this.productService.isError = true;
      })
    
    return promise;
  }

  @action
  update() {
    this.productService.isLoading = true;
    let promise = Api.put(`/product_services/${this.productService.object.id}`, {product_service: this.productService.object})

    promise
      .then((response) => {
        this.productService.isLoading = false;
        this.loadProductsServices();
      })
      .catch((error) => {
        this.productService.isLoading = false;
        this.productService.isError = true;
      })
    
    return promise;
  }

  @action
  delete(productService) {
    this.productsServices.isLoading = true;
    let promise = Api.delete(`/product_services/${productService.attributes.id}`)

    promise
      .then((response) => {
        this.loadProductsServices();
      })
      .catch((error) => {
        this.productsServices.isLoading = false;
        this.productsServices.isError = true;
      })
    
    return promise;
  }

}

export default ProductServiceStore;