import React from 'react';
import { Spinner, Alert, Button, Intent } from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import qs from 'qs';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
import _ from 'lodash';

@inject('viewStore','equipmentStore','workReportStore') @observer
class ActiveEquipmentDetailsTable extends React.Component {
  
  @observable isSelectDialogVisible = false;
  @observable isDeleteDialogVisible = false;
  @observable isSelectId;
  @observable showLoader = false;  
 
  handleSelectCancel() {
    this.isSelectDialogVisible = false;
    this.showLoader = false;
  }

  handleSelectConfirm(id) {
    this.isSelectDialogVisible = true; 
    this.isSelectId = id;        
  }

  handleSaveConfirm() { 
    let id = this.isSelectId;
    this.isSelectDialogVisible = false;
    let {workReportId} = this.props;     
    this.showLoader = true;
    this.props.equipmentStore.create_serviced_equipment(id,workReportId.id)
      .then(this.onSuccessfulCreateEquipment.bind(this))
      .catch(this.onFailCreateEquipment.bind(this))
  }

  onSuccessfulCreateEquipment(response) {
    this.showLoader = false;
    Toast.show({ icon: 'thumbs-up', message: 'Equipment added successfully.', intent: Intent.SUCCESS })
  }

  onFailCreateEquipment(error) {
    this.showLoader = false;
    Toast.show({ icon: 'warning-sign', message: 'Problem in adding equipment!', intent: Intent.DANGER })
  } 

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
    this.showLoader = false;
  }

  handleDeleteConfirm(id) {
    this.isDeleteDialogVisible = true; 
    this.isSelectId = id;  
  }

  handleSaveDeleteConfirm() {
    let {workReportId} = this.props;  
    let id = this.isSelectId;
    this.isDeleteDialogVisible = false;
    this.showLoader = true;
    this.props.equipmentStore.deleteServicedEquipment(id,workReportId.id)
      .then(this.onSuccessfulDeleteEquipment.bind(this))
      .catch(this.onFailDeleteEquipment.bind(this))    
  }

  onSuccessfulDeleteEquipment(response) {
    this.showLoader = false;
    this.props.equipmentStore.loadEquipmentsForWorkReports();
    Toast.show({ icon: 'thumbs-up', message: 'Equipment deleted successfully.', intent: Intent.SUCCESS })
  }

  onFailDeleteEquipment(error) {
    this.showLoader = false;
    Toast.show({ icon: 'warning-sign', message: 'Problem in deleting equipment!', intent: Intent.DANGER })
  }
  
  render() {
    let {equipment,valueInWorkReportEquipment,servicedEquipment} = this.props;   

    return (
      <React.Fragment>
        <tr key={equipment.id} >          
          <td>
            <p className='secondary mb-0'> {equipment.attributes.description}</p>
          </td>
          <td>
            <p className="primary mb-0">{equipment.attributes.make}</p>
          </td>
          <td>
            <p className="secondary mb-0">{equipment.attributes.eq_model}</p>
          </td>
          <td>
            <p className='primary mb-0'>{equipment.attributes.serial_number}</p>
          </td>
          <td>
            <p className='secondary mb-0'>{equipment.attributes.eq_location}</p>
          </td>
          <td>
            { valueInWorkReportEquipment && servicedEquipment
            ?
            <Button icon={'trash'} onClick={this.handleDeleteConfirm.bind(this,servicedEquipment.serviced_equipment_id)} loading={this.showLoader}>Remove from Work Order</Button>
            :
            <Button icon={'plus'} onClick={this.handleSelectConfirm.bind(this,equipment.id)} loading={this.showLoader}>Add to Work Order</Button>
            }            
          </td>          
        </tr> 
        <Alert
          cancelButtonText="No"
          confirmButtonText="Yes"
          icon="info-sign"
          intent={Intent.PRIMARY}
          isOpen={this.isSelectDialogVisible}
          onCancel={this.handleSelectCancel.bind(this)}
          onConfirm={this.handleSaveConfirm.bind(this)}          
        >
            Are you sure to add this equipment to your current work order?         
        </Alert> 
        <Alert
          cancelButtonText="Close"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleSaveDeleteConfirm.bind(this)}
        >
          Are you sure to delete the selected equipment from your current work order?
        </Alert>
      </React.Fragment>    
    )
  }
}

export default ActiveEquipmentDetailsTable;