import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button } from '@blueprintjs/core';
import LabourItem from './LabourItem';
import _ from 'lodash';
import { currencyFormattedAmount } from 'helpers/currency';
import AddProductForWorkReportModal from 'components/WorkReport/AddProductForWorkReportModal';

@inject('store', 'workReportStore','viewStore','productServiceStore') @observer
class WorkReportLabourLineItems extends Component {

  calculateSubTotal(labours) {
    if(labours.length > 0) {
      return currencyFormattedAmount(_.sumBy(labours, 'total'))
    } else {
      return currencyFormattedAmount(0)
    }
  }

  calculateSubTotalBeforeMarkup(labours) {
    if(labours.length > 0) {
      var sum = 0;
      _.each(labours, function(labour) {
        sum += parseFloat(labour.quantity) * parseFloat(labour.rate_per_unit)
      })
      return currencyFormattedAmount(sum)
    } else {
      return currencyFormattedAmount(0)
    }
  }

  handleCloseMaterialForWorkReportDailog() {
    this.props.store.isOpenAddLaborForWorkReportDialog = false;
  }

  onSuccessAddMaterialForWorkReport(equipment) {
    const {
      productServiceStore: {
        productsServices: {
          objects
        }
      }
    } = this.props;
    this.props.viewStore.productServiceSearch.where.product_service_type = 'Service';
    this.props.productServiceStore.loadProductAndServicesForWorkReports(); 
    this.props.store.isOpenAddLaborForWorkReportDialog = true; 
    this.props.store.productServiceType = 'Service';    
  }

  render() {
    const {
      workReportStore,
      workReportStore: {
        workReport: {
          object
        },
        isLoadingBlankLabourLineItem
      },
      store
    } = this.props;
    
    return (
      <React.Fragment>
        <div className="table-title" style={{ paddingLeft: '10px' }}>
          <h5><strong>Labor </strong></h5>
          <Button icon='add' className="bp3-minimal add-btn" onClick={() => { this.onSuccessAddMaterialForWorkReport() }}  >Add Labor</Button>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div style={{ padding: '0px 10px' }} >
            {
              object.attributes.labour.length === 0 && <div className="bp3-callout warning-alert-message bp3-intent-warning bp3-icon-info-sign table-card">
                No labor line items have been added to this Work Order.
              </div>
            }
          </div>
          {/* {
            object.attributes.labour.length > 0 && <table className='responsive-table-layout table report-data-table table-product-service' style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th colSpan="2">Services</th>
                  <th>Date</th>
                  <th>Hours</th>
                  <th>Hourly Rate({object.attributes.currency_symbol})</th>
                  <th>Markup</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  object.attributes.labour.map((labour) => {
                    return (
                      <LabourItem labour={labour} key={labour.id} />
                    )
                  })
                }
              </tbody>
            </table>
          } */}
          {
            object.attributes.labour.length > 0 && <div className="wr-row">
              <div style={{ flex: 1 }}>
                <table className="table table-bordered border-0">
                  <thead>
                    <tr>
                      <th width="200px">Services</th>
                      <th width="200px">Date</th>
                      <th width="100px">Hours</th>
                      <th width="115px">Hourly Rate({object.attributes.currency_symbol})</th>
                      <th width="100px">Markup</th>
                      <th width="100px" style={{textAlign:'right'}}>Total</th>
                      <th scope="col" style={{ width: 62 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                   
                      {
                        object.attributes.labour.map((labour) => {
                          return (
                            <LabourItem labour={labour} key={labour.id} />
                          )
                        })
                      }
                    
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
        {object.attributes.labour.length > 0 && <div className="lineitem_sub_total"> 
          <p><span style={{ marginRight: 25 }}>Sub Total Before markup:  {this.calculateSubTotalBeforeMarkup(object.attributes.labour)}</span>Sub Total: {this.calculateSubTotal(object.attributes.labour)}</p>
        </div>}
        <AddProductForWorkReportModal
          isOpen={store.isOpenAddLaborForWorkReportDialog}
          onClose={this.handleCloseMaterialForWorkReportDailog.bind(this)}
          workDetail = {object}
          type={this.props.store.productServiceType}
          onSuccess={this.onSuccessAddMaterialForWorkReport.bind(this)}
          title="Select Labor"
          button_text="Create New Labor"
          itemType="Labor"
        />
      </React.Fragment>
      
    )
  }
}
export default WorkReportLabourLineItems;