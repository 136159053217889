import { observable, action } from 'mobx';
import { Api, CurrentEnv } from 'services/Api';
import stores from './index';
import axios from 'axios';
import moment from 'moment';
var FileDownload = require('js-file-download');

const EMPTY_MATERIAL = {
  isLoading: false,
  isError: false,
  object: {
    supplier_id: null,
    work_report_id: null
  }
};

class WorkReportStore {

  @observable showPdfViewer = false;
  @observable isGeneratingInvoice = false;
  @observable isLoadingBlankMaterialLineItem = false;
  @observable isLoadingBlankLabourLineItem = false;
  @observable isLoadingBlankCustomLineItem = false;
  @observable isaddedMaterialLineItem = false;
  @observable showUploadAttachmentLoader = false;
  @observable workReports = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable clientWorkReports = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };
  

  @observable workReport = {
    isLoading: false,
    isError: false,
    object: null
  };

  @observable materialLineItem = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable purchaseOrderItem = {
    isLoading: false,
    isError: false,
    object: null,
    objects: []
  };

  @observable suppliers = {
    isLoading: false,
    isError: false,
    object: null
  };

  @observable newWorkReport = {
    isLoading: false,
    isError: false,
    object: {
      client_id:'',
      technician_id:'',
      scheduled_event_id:'',
      location_id:''
    }
  };

  @observable SendWorkReport = {
    isLoading: false,
    isError: false,
    object: {
      emailTo: "",
      emailSubject: "",
      emailMessage: "",
      emailAttached: true
    }
  };

  @observable form = {
    isLoading: false,
    isError: false,
    object: {}
  };

  @observable persistMaterial = EMPTY_MATERIAL;

  // update full work report with line_items
  // this.workReport used as payload in this action
  @action
  update() {
    this.workReport.isLoading = true;
    let promise = Api.put(`/work_reports/${this.workReport.object.attributes.id}`, {work_report: this.workReport.object.attributes})

    promise
      .then((response) => {
        this.workReport.object = response.data.data;
        this.workReport.isLoading = false;
        this.workReport.isError = false;
      })
      .catch((error) => {
        this.workReport.isLoading = false;
        this.workReport.isError = true;
      })

    return promise;
  }

  @action
  createInvoiceNumber() {
    this.workReport.isLoading = true;
    let promise = Api.put(`/work_reports/${this.workReport.object.attributes.id}`, {work_report: this.workReport.object.attributes})

    promise
      .then((response) => {
        this.workReport.object = response.data.data;
        this.workReport.isLoading = false;
        this.workReport.isError = false;
      })
      .catch((error) => {
        this.workReport.isLoading = false;
        this.workReport.isError = true;
      })

    return promise;
  }

  

  @action
  loadMaterialLineItemById() {
    this.materialLineItem.isLoading = true;
    let promise = Api.post('/material_line_items/search', {
      search: window.stores.viewStore.materialLineItemSearchById
    });
    promise
      .then((response) => {
        this.materialLineItem.isLoading = false;
        this.materialLineItem.isError = false;
        this.materialLineItem.objects = response.data.data;
        
        this.materialLineItem.meta = response.data.meta;
      })
      .catch((error) => {
        this.materialLineItem.isLoading = false;
        this.materialLineItem.isError = true;
      })
    
  }


  @action
  loadMaterialLineItems() {
    this.materialLineItem.isLoading = true;
    let promise = Api.post('/material_line_items/search', {
      search: window.stores.viewStore.materialLineItemSearch
    });
    promise
      .then((response) => {
        this.materialLineItem.isLoading = false;
        this.materialLineItem.isError = false;
        this.materialLineItem.objects = response.data.data;
        
        this.materialLineItem.meta = response.data.meta;
      })
      .catch((error) => {
        this.materialLineItem.isLoading = false;
        this.materialLineItem.isError = true;
      })
    
  }

  @action
  loadPurchaseOrders() {
    this.purchaseOrderItem.isLoading = true;
    let promise = Api.post('/purchase_orders/search', {
      search: window.stores.viewStore.purchaseOrderItemSearch
    });
    promise
      .then((response) => {
        this.purchaseOrderItem.isLoading = false;
        this.purchaseOrderItem.isError = false;
        this.purchaseOrderItem.objects = response.data.data;
        this.purchaseOrderItem.meta = response.data.meta;
      })
      .catch((error) => {
        this.purchaseOrderItem.isLoading = false;
        this.purchaseOrderItem.isError = true;
      })
    
  }

  @action
  loadPurchaseOrder(params) {
    
    this.purchaseOrderItem.isLoading = true;
    let promise = Api.get(`/purchase_orders/${params.uuid}`)

    const onSuccess = (response) => {
      this.purchaseOrderItem.isLoading = false;      
      stores.viewStore.ShowPurchaseOrder(params,response.data.data.attributes.purchase_order_number);      
      this.purchaseOrderItem.object = response.data.data;
    }

    const onError = (error) => {
      this.purchaseOrderItem.isLoading = false;
      this.purchaseOrderItem.isError = true;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  loadSuppliers() {
    this.suppliers.isLoading = true;
    let promise = Api.post('/suppliers/search', {
      search: window.stores.viewStore.suppliersSearch
    });
    promise
      .then((response) => {
        this.suppliers.isLoading = false;
        this.suppliers.isError = false;
        this.suppliers.objects = response.data.data;
        
        this.suppliers.meta = response.data.meta;
      })
      .catch((error) => {
        this.suppliers.isLoading = false;
        this.suppliers.isError = true;
      })
    
  }


  @action
  deleteWorkReport(workReport) {
    this.workReports.isLoading = true;
    let promise = Api.delete(`/work_reports/${workReport.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadWorkReports();
      })
      .catch((error) => {
        this.workReports.isLoading = false;
        this.workReports.isError = true;
      })
    
    return promise
  }

  @action
  download_bulk_pdf(searchObj) {
    let promise = Api.post('/work_reports/pdfs_bulk_download', { search: searchObj})

    promise
      .then((response) => {
      })
      .catch((error) => {
      })

    return promise;
  }

  @action
  download_bulk_excel(searchObj) {
    let promise = Api.post('/work_reports/excel_bulk_download', { search: searchObj }, { responseType: 'arraybuffer' })

    promise
      .then((response) => {
        FileDownload(response.data, 'Bulk_WorkReports.xls');
      })
      .catch((error) => {
      })
  }

  get workOrderPDFDownloadUrl() {
    const {
      viewStore: {
        currentUser: {
          attributes: {
            api_token,
            tenant_id
          }
        }
      }
    } = stores;
    const {
      workReport: {
        object: {
          id
        }
      }
    } = this;
    if (id && api_token) {
      let path = `work_reports/${id}/download_pdf?api_token=${api_token}&x_tenant_id=${tenant_id}`
      return {
        fullUrl: `${CurrentEnv.baseURL()}/${path}`,
        baseUrl: CurrentEnv.baseURL(),
        path: path
      }
    }
  }

  get purchaseOrderPDFDownloadUrl() {
    const {
      viewStore: {
        currentUser: {
          attributes: {
            api_token,
            tenant_id
          }
        }
      }
    } = stores;
    const {
      purchaseOrderItem: {
        object: {
          id
        }
      }
    } = this;
    if (id && api_token) {
      let path = `purchase_orders/${id}/download_purchase_pdf?api_token=${api_token}&x_tenant_id=${tenant_id}`
      return {
        fullUrl: `${CurrentEnv.baseURL()}/${path}`,
        baseUrl: CurrentEnv.baseURL(),
        path: path
      }
    }
  }



  @action
  downloadPurchaseOrderPDF() {
    let downloadUrl = this.purchaseOrderPDFDownloadUrl;
    let promise = Api.get(downloadUrl.path, { responseType: 'arraybuffer' });
    
    const onSuccess = (response) => {
      FileDownload(response.data, 'PurchaseOrder.pdf');
    }

    const onError = (error) => {

    }

    promise
      .then(onSuccess)
      .catch(onError)
    return promise;
  }

  @action
  downloadWorkOrderPDF() {
    let downloadUrl = this.workOrderPDFDownloadUrl;
    let promise = Api.get(downloadUrl.path, { responseType: 'arraybuffer' });
    
    const onSuccess = (response) => {
      FileDownload(response.data, 'WorkOrder.pdf');
    }

    const onError = (error) => {

    }

    promise
      .then(onSuccess)
      .catch(onError)
    return promise;
  }

  @action
  deleteLineItem(lineItem, deleteTimeLog = null) {
    if(lineItem.kind === 'material') {
      this.isLoadingBlankMaterialLineItem = true;
    } else if(lineItem.kind === 'labour') {
      this.isLoadingBlankLabourLineItem = true;
    } else if(lineItem.kind === 'custom') {
      this.isLoadingBlankCustomLineItem = true;
    }
    
    let _lineltem = {
      data: {
        deleteTimeLog
      }
    }

    let promise = Api.delete(`/line_items/${lineItem.id }`, _lineltem);
    
    promise
      .then((response) => {
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
        this.update();
      })
      .catch((error) => {
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
      })
    
    return promise
  }

 

  @action
  loadClientWorkReportsWithLocation() {
    this.clientWorkReports.isLoading = true;
    let promise = Api.post('/work_reports/search', {
      search: window.stores.viewStore.clientWorkReportWithLocationSearch
    });
    promise
      .then((response) => {
        this.clientWorkReports.isLoading = false;
        this.clientWorkReports.isError = false;
        this.clientWorkReports.objects = response.data.data;
        this.clientWorkReports.meta = response.data.meta;
      })
      .catch((error) => {
        this.clientWorkReports.isLoading = false;
        this.clientWorkReports.isError = true;
      })
    
  }

  @action
  loadClientWorkReports() {
    this.clientWorkReports.isLoading = true;
    let promise = Api.post('/work_reports/search', {
      search: window.stores.viewStore.clientWorkReportSearch
    });
    promise
      .then((response) => {
        this.clientWorkReports.isLoading = false;
        this.clientWorkReports.isError = false;
        this.clientWorkReports.objects = response.data.data;
        this.clientWorkReports.meta = response.data.meta;
      })
      .catch((error) => {
        this.clientWorkReports.isLoading = false;
        this.clientWorkReports.isError = true;
      })
    
  }

  @action
  refreshWorkReport(params) {
    this.isLoadingBlankMaterialLineItem = true;
    let promise = Api.get(`/work_reports/${params.uuid}`)

    const onSuccess = (response) => {
      this.isLoadingBlankMaterialLineItem = false;      
      stores.viewStore.ShowWorkOrder(params,response.data.data.attributes.number);      
      this.workReport.object = response.data.data;
    }

    const onError = (error) => {
      this.isLoadingBlankMaterialLineItem = false;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  loadWorkReport(params) {
    this.workReport.isLoading = true;
    let promise = Api.get(`/work_reports/${params.uuid}`)

    const onSuccess = (response) => {
      this.workReport.isLoading = false;      
      stores.viewStore.ShowWorkOrder(params,response.data.data.attributes.number);           
      this.workReport.object = response.data.data;
    }

    const onError = (error) => {
      this.workReport.isLoading = false;
      this.workReport.isError = true;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  loadWorkReports() {
    this.workReports.isLoading = true;
    const {
      per_page,
      page,
      order,
      where
    } = stores.viewStore.workReportSearch;
    let promise = Api.post('/work_reports/search', { 
      search: { 
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.workReports.isLoading = false;
        this.workReports.isError = false;
        this.workReports.objects = response.data.data;
        this.workReports.meta = response.data.meta;        
      })
      .catch((error) => {
        this.workReports.isLoading = false;
        this.workReports.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  generateInvoice(workReport) {
    this.isGeneratingInvoice = true
    let promise = Api.post(`/work_reports/generate_invoice`, {work_report_id: workReport.attributes.id})

    promise
      .then((response) => {
        this.isGeneratingInvoice = false
        this.workReport.object = response.data.data
        // TODO: redirect to Invoice show page...
      })
      .catch((error) => {
        this.isGeneratingInvoice = false
      })

    return promise;
  }

  @action
  unlinkInvoice(workReport) {
    this.isGeneratingInvoice = true
    let promise = Api.post(`/work_reports/unlink_invoice`, {work_report_id: workReport.attributes.id})

    promise
      .then((response) => {
        this.isGeneratingInvoice = false
        this.workReport.object = response.data.data
      })
      .catch((error) => {
        this.isGeneratingInvoice = false
      })

    return promise;
  }

  @action
  updateLineItem(lineItem) {
    let promise = Api.put(`/line_items/${lineItem.id}`, {line_item: lineItem})

    promise
      .then((response) => {
        //this.loadWorkReport()
      })
      .catch((error) => {
      })

    return promise;
  }




  @action
  updateMaterialLineItems(material,status) {  
    let material_line_item = {
      id: material.id,    
      status: status
    }    
    let promise = Api.put(`/material_line_items/${material.id}`, {material_line_item: material_line_item})

    promise
      .then((response) => {
        this.materialLineItem.object = response.data.data;
      })
      .catch((error) => {
      })

  }

  @action
  addPurchaseOrderItem(material) {         
    let promise = Api.post(`/purchase_orders`, {material_item_ids: material})
    promise
      .then((response) => {
        this.purchaseOrderItem.isLoading = false;
        this.purchaseOrderItem.isError = false;
        this.purchaseOrderItem.object = response.data.data;
      })
      .catch((error) => {
        this.purchaseOrderItem.isLoading = false;
        this.purchaseOrderItem.isError = true;
      })

  }

  @action
  updateMaterialLineItemsWithPurchaseOrderId(purchaseOrderId,status) {
    let purchase_item = {
      id: purchaseOrderId,    
      status: status
    }        
    let promise = Api.post(`/purchase_orders/update_material_item`,{purchase_item: purchase_item})
    promise
      .then((response) => {
        this.materialLineItem.object = response.data.data;
      })
      .catch((error) => {
      })
  }


  @action
  updatePurchaseOrderItems(purchaseOrder,status) { 
    this.purchaseOrderItem.isLoading = true; 
    let purchase_item = {
      id: purchaseOrder,    
      status: status
    }    
    let promise = Api.put(`/purchase_orders/${purchaseOrder}`, {purchase_item: purchase_item})

    promise
      .then((response) => {
        this.purchaseOrderItem.isLoading = false;
        this.purchaseOrderItem.object = response.data.data;
        this.loadPurchaseOrder({uuid:response.data.data.id});
      })
      .catch((error) => {
      })

  }

  @action
  addMaterialLineItem(material) {
  
    let material_line_item = {
      work_report_id: material.work_report_id,
      line_item_id:material.id,
      supplier_id:material.supplier_id,
      kind: 'material',
      description:material.description,
      quantity:material.quantity,
      rate_per_unit:material.rate_per_unit,
      total:material.total,
      quantity_display_value:material.quantity_display_value,
      rate_per_unit_display_value:material.rate_per_unit_display_value,
      total_display_value:material.total_display_value,
      part_number:material.part_number,
      created_at:material.created_at,
      updated_at:material.updated_at,
      deleted_at:material.deleted_at,
      time_log_id:material.time_log_id,
      deleted_by_id:material.deleted_by_id,
      status: 'pending order'
    }    
    let promise = Api.post(`/material_line_items`, {material_line_item: material_line_item})

    return promise
      .then((response) => {
        this.materialLineItem.object = response.data.data;
        this.refreshWorkReport({uuid: this.workReport.object.attributes.id})
      })
      .catch((error) => {
      })

  }


  @action
  addLineItem(material, workReportId,kind) {
    let lineItem = {
      work_report_id: workReportId,
      is_overtime:false,
      markup:material.attributes.mark_up,
      override:false,
      part_number:material.attributes.part_number,
      product_service_id:material.attributes.id,
      quantity:1,
      quantity_display_value:1,
      rate_per_unit:material.attributes.unit_price,
      supplier_id:material.attributes.supplier_id,
      description:material.attributes.description,
      kind: kind,
      work_date: moment()
    }
    let work_report = {
      id: workReportId
    }

    let promise = Api.post(`/line_items`, {line_item: lineItem, work_report: work_report})

    promise
      .then((response) => {
        this.isaddedMaterialLineItem = true;
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
        this.update()
      })
      .catch((error) => {
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
      })
      return promise;

  }

  @action
  addBlankLineItem(kind, workReportId) {
    if(kind === 'material') {
      this.isLoadingBlankMaterialLineItem = true;
    } else if(kind === 'labour') {
      this.isLoadingBlankLabourLineItem = true;
    } else if(kind === 'custom') {
      this.isLoadingBlankCustomLineItem = true;
    }
    let lineItem = {
      work_report_id: workReportId,
      kind: kind,
      work_date: moment()
    }
    let work_report = {
      id: workReportId
    }

    let promise = Api.post(`/line_items`, {line_item: lineItem, work_report: work_report})

    promise
      .then((response) => {
        // this.workReport.object = response.data.data;
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
        this.update()
      })
      .catch((error) => {
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
      })

  }

  @action
  addBlankLineItemForProduct(kind, workReportId) {
    if(kind === 'material') {
      this.isLoadingBlankMaterialLineItem = true;
    } else if(kind === 'labour') {
      this.isLoadingBlankLabourLineItem = true;
    } else if(kind === 'custom') {
      this.isLoadingBlankCustomLineItem = true;
    }
    let lineItem = {
      work_report_id: workReportId,
      kind: kind,
      work_date: moment()
    }
    let work_report = {
      id: workReportId
    }

    let promise = Api.post(`/line_items`, {line_item: lineItem, work_report: work_report})

    promise
      .then((response) => {
        // this.workReport.object = response.data.data;
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
        this.update()
      })
      .catch((error) => {
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
      })
      return promise;
  }

  @action
  createEmpty() {
    this.newWorkReport.isLoading = true
    let promise = Api.post(`/work_reports/create_empty`, {work_report: this.newWorkReport.object})

    promise
      .then((response) => {
        this.newWorkReport.isLoading = false
        this.workReport.object = response.data.data
        this.newWorkReport.technician_id = ''
        this.newWorkReport.client_id = ''
        stores.viewStore.ShowWorkOrder({ uuid: this.workReport.object.attributes.id })
      })
      .catch((error) => {
        this.newWorkReport.isLoading = false
      })

    return promise;
  }

  @action
  SendWorkReportToEmail() {
    const {
      viewStore: {
        currentUser: {
          attributes: {
            tenant_id
          }
        }
      }
    } = stores;
    const {
      workReport: {
        object: {
          id
        }
      }
    } = this;
    this.SendWorkReport.isLoading = true;
    let promise = Api.post(`/work_reports/${tenant_id}/send_email/${id}/send_invoice_email`, { email_details: this.SendWorkReport.object })

     promise
      .then((response) => {
        this.SendWorkReport.isLoading = false;
        this.SendWorkReport.isError = false;
        stores.store.isOpen = false;
      })
      .catch((error) => {
        //console.error(error.response.data.errors.join(", "))
        this.SendWorkReport.isLoading = false;
        this.SendWorkReport.isError = true;
      })
    return promise;
  }

  @action
  changeStatus(work_report, event) {
    this.workReport.isLoading = true;
    let promise = Api.post(`/work_reports/change_status`, {work_report: work_report.attributes, event: event})

    const onSuccess = (response) => {
      this.workReport.object = response.data.data;
      this.workReport.isLoading = false;
      this.workReport.isError = false;
    }

    const onError = (error) => {
      this.workReport.isLoading = false;
      this.workReport.isError = true;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  uploadWorkReportAttachment(files, work_report_id = null) {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('file[]',files[i])
    };
   
    formData.append('work_report_id', work_report_id)
    this.showUploadAttachmentLoader = true;
    let promise = axios.post(`${CurrentEnv.domain()}/api/v2/work_reports/upload_attachments`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Api-Token': stores.viewStore.currentUser.attributes.api_token,
          'X-Tenant':stores.viewStore.currentUser.attributes.tenant_id,
          'X-Client-Identifier': 'React-Web'
        }
      }
    )

    promise
      .then((response) => {
        this.showUploadAttachmentLoader = false;
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = true;
      })

    return promise;

  }

  @action
  removeAttachment(attachment, work_report_id = null) {
    this.showUploadAttachmentLoader = true;
    let promise = Api.delete(`/work_reports/${attachment.id}/remove_attachment`)

    promise
      .then((response) => {
        if(work_report_id) {
          this.showUploadAttachmentLoader = false;
        }
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = false;
      })

    return promise;
  }

  @action
  updateWorkReportForm() {
    this.form.isLoading = true;
    let promise = Api.post(`/work_reports/attach_form`, {work_report: this.workReport.object.attributes})

    promise
      .then((response) => {
        this.form.object = response.data.data;
        this.form.isLoading = false;
        this.form.isError = false;
        stores.viewStore.ShowWorkOrder({ uuid: response.data.data.attributes.work_report_id })
      })
      .catch((error) => {
        this.form.isLoading = false;
        this.form.isError = true;
      })

    return promise;
  }

  @action
  changeJob(work_report, event) {
    this.workReport.isLoading = true;
    let promise = Api.post(`/work_reports/update_job`, {work_report: work_report.attributes, event: event})

    const onSuccess = (response) => {
      this.workReport.object = response.data.data;
      this.workReport.isLoading = false;
      this.workReport.isError = false;
    }

    const onError = (error) => {
      this.workReport.isLoading = false;
      this.workReport.isError = true;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  changeTechnician(work_report, event) {
    this.workReport.isLoading = true;
    let promise = Api.post(`/work_reports/update_created_by`, {id: work_report.id, event: event})

    const onSuccess = (response) => {
      this.workReport.object = response.data.data;
      this.workReport.isLoading = false;
      this.workReport.isError = false;
    }

    const onError = (error) => {
      this.workReport.isLoading = false;
      this.workReport.isError = true;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  updateEquipment(work_report_id, equipment) {
    this.workReport.isLoading = true;
    let promise = Api.post(`/work_reports/update_equipment`, {work_report_id: work_report_id, equipment: equipment})

    const onSuccess = (response) => {
      this.workReport.object = response.data.data;
      this.workReport.isLoading = false;
      this.workReport.isError = false;
    }

    const onError = (error) => {
      this.workReport.isLoading = false;
      this.workReport.isError = true;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

};

export default WorkReportStore;