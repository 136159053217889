import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { action } from 'mobx';
import { observable } from 'mobx';
import { 
  FormGroup, Button, Dialog, Classes, Intent, TextArea
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import SupplierAutocomplete from 'components/SupplierAutocomplete';
import ProductCategoryAutocomplete from 'components/ProductCategoryAutocomplete';
import { currency, currencySymbol } from 'helpers/currency';
import ProductServiceXeroModal from 'components/ProductsServices/ProductServiceXeroModal';

@inject('productServiceStore', 'quickbooksStore','viewStore','xeroStore') @observer
class ProductServiceModal extends Component {
  @observable isItemsXeroDialogVisible = false;

  componentDidMount() {
    this.props.quickbooksStore.loadQuickbooksItems();
    this.props.xeroStore.loadXeroItems();
  }

  onCloseItemsXeroDialog() {
    this.isItemsXeroDialogVisible = false;
  }

  onSucessUpdateItemsXero() {
    this.isItemsXeroDialogVisible = true;
  }

  handleCloseDailog() {
    // clearing object
    this.props.productServiceStore.productService.object = {}
    this.props.onClose()
  }

  createProductService() {
    let {
      itemType,
      productServiceStore,
      productServiceStore: {
        productService: {
          object
        }
      }
    } = this.props;

    if(itemType === 'Materials'){
      object.product_service_type = 'Products (Non-inventory)';
    }else{
      object.product_service_type = 'Service';
    }

    if(object.name === "" || object.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter name!', intent: Intent.DANGER })
      return;
    }

    productServiceStore.create()
      .then(this.onSuccessfullyCreateProductService.bind(this))
      .catch(this.onFailCreateProductService.bind(this))
  }

  onSuccessfullyCreateProductService(response) {
    this.props.onSuccess()
    Toast.show({ message: "Product/Service created successfully.", intent: Intent.SUCCESS })
  }

  onFailCreateProductService(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ message: "Something went wrong. Failed to create/update product/service.", intent: Intent.DANGER })
    }
  }

  updateProductService() {
    let {
      itemType,
      productServiceStore,
      productServiceStore: {
        productService: {
          object
        }
      }
    } = this.props;

    if(itemType === 'Materials'){
      object.product_service_type = 'Products (Non-inventory)';
    }else{
      object.product_service_type = 'Service';
    }

    if(object.name === "" || object.name === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter name!', intent: Intent.DANGER })
      return;
    }
    
    productServiceStore.update()
      .then(this.onSuccessfullyUpdateProductService.bind(this))
      .catch(this.onFailCreateProductService.bind(this))
  }

  onSuccessfullyUpdateProductService(response) {
    this.props.onSuccess()
    Toast.show({ message: "Product/Service updated successfully.", intent: Intent.SUCCESS })
  }

  openXeroProductServiceModal() {
    let { 
      xeroStore,
      productServiceStore: {
        productService: {
          object
        }
      },
     } = this.props;
     

    if(object.code === "" || object.code == null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid code!', intent: Intent.DANGER })
      return;
    }else{
      this.props.xeroStore.xeroItem.object.code = object.code;
    }

    if(object.name === "" || object.name == null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid name!', intent: Intent.DANGER })
      return;
    }else{
      this.props.xeroStore.xeroItem.object.name = object.name;
    }

    if(object.description === "" || object.description == null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter valid description!', intent: Intent.DANGER })
      return;
    }else{
      this.props.xeroStore.xeroItem.object.description = object.description;
    }

    xeroStore.createProductService()
      .then(this.onSuccessfullyCreateProductService.bind(this))
      .catch(this.onFailCreateProductService.bind(this))
    //this.isItemsXeroDialogVisible = true;
    //this.props.xeroStore.loadXeroItems()
  }

  onSuccessfullyCreateProductService(response) {
    this.props.xeroStore.loadXeroItems();
    Toast.show({ message: "Items created successfully.", intent: Intent.SUCCESS })
  }

  onFailCreateProductService(error) {
    if(error.response && error.response.data && error.response.data.errors) {
      Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
    } else {
      Toast.show({ message: "Something went wrong. Failed to create/update items", intent: Intent.DANGER });
    }
  }

  handleServiceTypeChange(event) {
    let {
      productServiceStore: {
        productService: {
          object
        }
      }
    } = this.props;
    object.product_service_type = event.target.value;
  }

  handleNameChange(event){
    let {
      productServiceStore: {
        productService: {
          object
        }
      }
    } = this.props;
    object.name = event.target.value;
    object.code = event.target.value;
  }

  @action
  onSupplierSelect(supplier) {
    let {
      props: {
        viewStore: {
          productServiceSearch,
          productServiceSearch: {
            where: {
              supplier_id,
              ...whereWithNoClientId
            }
          }
        }
      }
    } = this;

    let {
      productServiceStore: {
        productService: {
          object
        }
      }
    } = this.props;

    if (supplier) { 
      this.props.viewStore.productServiceSearch = {
        ...productServiceSearch,
        where: {
          ...productServiceSearch.where,
          supplier_id: supplier.id
        }
      }
      object.supplier_id = supplier.id;
    } else {
      this.props.viewStore.productServiceSearch = {
        ...productServiceSearch,
        where: {
          ...whereWithNoClientId,
          supplier_id: null
        }
      }
      object.supplier_id = '';
    }
    
  }

  @action
  onCategorySelect(category) {
    let {
      props: {
        viewStore: {
          productServiceSearch,
          productServiceSearch: {
            where: {
              product_category_id,
              ...whereWithNoClientId
            }
          }
        }
      }
    } = this;

    let {
      productServiceStore: {
        productService: {
          object
        }
      }
    } = this.props;

    if (category) { 
      this.props.viewStore.productServiceSearch = {
        ...productServiceSearch,
        where: {
          ...productServiceSearch.where,
          product_category_id: category.id
        }
      }
      object.product_category_id = category.id;
    } else {
      this.props.viewStore.productServiceSearch = {
        ...productServiceSearch,
        where: {
          ...whereWithNoClientId,
          product_category_id: null
        }
      }
      object.product_category_id = '';
    }
  }

  render() {
    let {
      title,
      edit,
      itemType,
      showItemTypeSelector,
      onChangeItemTypeSelector,
      productServiceStore: {
        productService: {
          isLoading,
          object
        }
      },
      quickbooksStore: {
        quickbooksItems: {
          objects
        }
      },
      xeroStore: {
        xeroItems,
        xeroItem
      }
    } = this.props;
    return(
      <div>
        <Dialog
          title={title}
          isOpen={this.props.isOpen}
          isCloseButtonShown={false}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              label="Name"
              labelFor="text-input"
              labelInfo="(*)"
            >
              <input 
                type='text' 
                className='bp3-input bp3-fill' 
                placeholder='Name'
                defaultValue={object.name}
                onChange={(event) => { object.name = event.target.value } } 
                onChange={this.handleNameChange.bind(this)}
              />
            </FormGroup>

            <FormGroup
              label="Description"
              labelFor="text-input"
              labelInfo=""
            >
              <input 
                type='text' 
                className='bp3-input bp3-fill' 
                placeholder='Description'
                defaultValue={object.description || ""}
                onChange={(event) => { object.description = event.target.value } } 
              />
            </FormGroup>

            { showItemTypeSelector ?
                <FormGroup
                  label="Product Category"
                  labelFor="text-input"
                  labelInfo=""
                >
                  <select onChange={onChangeItemTypeSelector}>
                    <option value="">Select one</option>
                    <option value="Materials">Material</option>
                    <option value="Labor">Labor</option>
                  </select>
                </FormGroup> : null
            }

            { object && itemType === 'Materials' ?
              <div>
                <div className="row">
                  <div className="col-6">
                    <FormGroup
                      label="Supplier"
                      labelFor="text-input"
                      labelInfo=""
                    >
                      <SupplierAutocomplete
                        selectedSupplierId={object && object.supplier_id}
                        onSupplierSelect={this.onSupplierSelect.bind(this)} 
                      />
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup
                      label="Part Number"
                      labelFor="text-input"
                      labelInfo=""
                    >
                      <input 
                        type='text' 
                        className='bp3-input bp3-fill' 
                        placeholder='Part Number'
                        defaultValue={object.part_number}
                        onChange={(event) => { object.part_number = event.target.value } } 
                      />
                    </FormGroup>
                  </div>
                  
                </div>
                <div className="row">
                  <div className="col-6">
                    <FormGroup
                      label={`Unit Price (${currency()}${currencySymbol()})`}
                      labelFor="text-input"
                      labelInfo=""
                    >
                      <input 
                        type='number' 
                        className='bp3-input bp3-fill' 
                        placeholder='Unit Price'
                        defaultValue={object.unit_price}
                        onChange={(event) => { object.unit_price = event.target.value } } 
                      />
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup
                      label="Markup"
                      labelFor="text-input"
                      labelInfo=""
                    >
                      <input 
                        type='number' 
                        className='bp3-input bp3-fill' 
                        placeholder='Markup'
                        defaultValue={object.mark_up}
                        onChange={(event) => { object.mark_up = event.target.value } } 
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              :''
            }

            { itemType === 'Labor' ?
              <div>
                <div className="row">
                  <div className="col-6">
                    <FormGroup
                      label="Markup"
                      labelFor="text-input"
                      labelInfo=""
                    >
                      <input 
                        type='number' 
                        className='bp3-input bp3-fill' 
                        placeholder='Markup'
                        defaultValue={object.mark_up}
                        onChange={(event) => { object.mark_up = event.target.value } } 
                      />
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup
                      label={`Hourly Rate (${currency()}${currencySymbol()})`}
                      labelFor="text-input"
                      labelInfo=""
                    >
                      <input 
                        type='number' 
                        className='bp3-input bp3-fill' 
                        placeholder='Hourly Rate'
                        defaultValue={object.unit_price}
                        onChange={(event) => { object.unit_price = event.target.value } } 
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              :''
            }
            {objects && objects.length > 0 ?

              <FormGroup
                label="QuickBooks Product and Services"
                labelFor="checkbox"
                labelInfo=""
              >
                <div className="bp3-select bp3-fill">
                  <select
                    id={"quickbooks-tax-code"}
                    value={object.quickbooks_item_id}
                    onChange={event => {
                      object.quickbooks_item_id = event.target.value;
                    }}
                  >
                    <option value="">Select Product/Service</option>
                    {objects && objects.length > 0 && objects.map((item) => {
                      return(
                        <option value={item.id} key={Math.random()}>{item.name}</option>
                      )
                    })}
                  </select>
                </div>
              </FormGroup>
              :''
            }

            <div className='row'>
              <div className="col-md-8">
                {xeroItems && xeroItems.objects && xeroItems.objects.length > 0 ?
                  <FormGroup
                    label="Xero Product and Services"
                    labelFor="checkbox"
                    labelInfo=""
                  >
                    <div className="bp3-select bp3-fill">
                      <select
                        id={"xero-tax-code"}
                        value={object.xero_item_id}
                        onChange={event => {
                          object.xero_item_id = event.target.value;
                        }}
                      >
                        <option value="">Select Product/Service</option>
                        {xeroItems.objects && xeroItems.objects.length > 0 && xeroItems.objects.map((item) => {
                          return(
                            <option value={item.ItemID} key={Math.random()}>{item.Name}</option>
                          )
                        })}
                      </select>
                    </div>
                  </FormGroup>
                  :''
                }
              </div>
              <div className="col-md-4">
                {xeroItems && xeroItems.objects && xeroItems.objects.length > 0 ?
                  <Button style={{marginLeft:"10px",marginTop:"23px"}} loading={xeroItem.isLoading} icon='add' onClick={this.openXeroProductServiceModal.bind(this)}>
                    Add to Xero
                  </Button>
                  :''
                }
              </div>
            </div>


          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.handleCloseDailog.bind(this)}>Close</Button>
              {!edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.createProductService.bind(this)}>
                Save
              </Button>}
              {edit && <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.updateProductService.bind(this)}>
                Update
              </Button>}
            </div>
          </div>
        </Dialog>
        <ProductServiceXeroModal 
          isOpen={this.isItemsXeroDialogVisible} 
          title={"Add Items to Xero"}
          onClose={this.onCloseItemsXeroDialog.bind(this)}
          onSuccess={this.onSucessUpdateItemsXero.bind(this)}
        />
      </div>
    )
  }

}

export default ProductServiceModal;
