import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';
import Page from 'components/Page';
import styled from '@emotion/styled';
import { 
  NonIdealState, Spinner
} from "@blueprintjs/core";
import SearchBars from 'components/SearchBars';
import SearchBarWrapper from 'components/SearchBarWrapper';
import Sticky from 'react-stickynode';
import ServiceAgreementsTable from 'components/Tables/ServiceAgreements';
import ClientsSideBar from 'components/Client/ClientsSideBar';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`

@inject('store', 'viewStore', 'serviceAgreementStore') @observer
class ServiceAgreementsIndex extends Component {

  get canRender() {
    let {
      serviceAgreementStore: {
        serviceAgreements: {
          isLoading,
          isError,
          objects
        }
      }
    } = this.props;
    return (!isLoading && !isError && objects);
  }

  render() {
    let content;
    let {
      serviceAgreementStore: {
        serviceAgreements: {
          isLoading,
          isError,
          objects
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      content = <ServiceAgreementsTable />
    }
    return(
      <div>
        <Helmet>
          <title>Service Agreements | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters"> 
          <div className="col-lg-2  sidebar-responsive">
            <ClientsSideBar />
          </div>
          <div className="col-lg-10">
            <Sticky enabled={true} top={0} innerZ={9}>
              <SearchBarWrapper>
                <SearchBars.ServiceAgreement />
              </SearchBarWrapper>
            </Sticky>
            
            <Page>
              {content}
            </Page>
          </div>
        </div>
      </div>
    )
  }

};

export default ServiceAgreementsIndex;