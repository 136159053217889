import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState, Button } from '@blueprintjs/core';
import SearchBars from 'components/SearchBars';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import FrequencyTable from 'components/Tables/Frequency';
import Card from 'components/Card';
import Sticky from 'react-stickynode';
import styled from '@emotion/styled';
import BookingsSidebar from '../bookings/bookings-sidebar';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`

@inject('store', 'viewStore', 'frequencyStore') @observer
class FrequencyIndexPage extends Component {

  @observable visible = true;

  render() {
    let content;
    let {
      frequencyStore: {
        frequencies: {
          isLoading,
          isError
        }
      },store, viewStore
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><div className="spinner-center">
        <Spinner />
        </div>
      </CardHolder>;
    }
    if (isError && !isLoading) {
      content = <div className="no-result-message" >
        <h4>No results</h4>
        <img src="/add-booking.svg" />
        <div className="add-new-record">
          <h5 className="message">No records found.</h5>
          <Button className="bp3-large add-btn bp3-intent-primary" icon={'plus'} small={true} onClick={() => viewStore.frequencyAdd()}>
            Add Frequency
          </Button>
        </div>
      </div>;
    }
    if (!isError && !isLoading) {
      content = <FrequencyTable />
    }
    return (
      <div>
        <Helmet>
          <title>Frequency | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
              <BookingsSidebar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
              <Sticky enabled={true} top={0} innerZ={9}>
                <SearchBars.FrequencySearch />
              </Sticky>
              <Card>
                {content}
              </Card>
            </PageWrapGray>
          </div>
        </div>
      </div>
    )
  }
};

export default FrequencyIndexPage;