import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { 
  FormGroup, Button, Dialog, Classes, Intent, TextArea
} from "@blueprintjs/core";
import GeoComplete from 'helpers/GeoComplete';
import Toast from 'helpers/toaster';

@inject('clientStore') @observer
class AddLocationModal extends Component {

  @observable initialLocation = '';

  handleCloseLocationDailog() {
    this.props.onClose()
  }

  onAddressSelect(suggestion) {
    let {
      props: {
        clientStore: {
          location: {
            object
          }
        }
      }
    } = this;
    if(suggestion) {
      object.full_address = suggestion.label;
      object.latitude = suggestion.location.lat;
      object.longitude = suggestion.location.lng;
      object.place_id = suggestion.placeId;
      object.client_id = this.props.client_id;
    } else {
      object.full_address = null;
      object.latitude = null;
      object.longitude = null;
      object.place_id = null;
    }
  }

  onAddressClear(value) {
    let {
      props: {
        clientStore: {
          location: {
            object
          }
        }
      }
    } = this;
    if(!value) {
      object.full_address = null;
      object.latitude = null;
      object.longitude = null;
      object.place_id = null;
    }
  }

  createLocation() {
    let {
      props: {
        clientStore: {
          location: {
            object
          }
        }
      }
    } = this;
    if(!object.full_address) {
      Toast.show({ icon: 'warning-sign', message: 'Please specify a location to save.', intent: Intent.DANGER })
      return;
    }

    this.props.clientStore.createLocation()
      .then(this.onSuccessfulAddLocation.bind(this))
      .catch(this.onFailAddLocation.bind(this))
  }

  onSuccessfulAddLocation(response) {
    this.props.onSuccess(response.data.data)
  }

  onFailAddLocation(error) {
    console.log(error);
  }

  render() {
    let { object, isLoading } = this.props.clientStore.location
    return(
      <Dialog
        title="Add Location"
        isOpen={this.props.isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
              label="Location"
              labelFor="text-input"
              labelInfo="(*)"
            >
              <GeoComplete
                placeholder="Search location"
                initialValue={this.initialLocation}
                onSuggestSelect={this.onAddressSelect.bind(this)}
                onChange={this.onAddressClear.bind(this)}
              />
            </FormGroup>

            <FormGroup
                label="Notes"
                labelFor="text-input"
                labelInfo="(*)"
              >
                <TextArea
                  className="bp3-fill"
                  placeholder='Write a short note for this location'
                  onChange={(event) => { object.notes = event.target.value } } 
                />
                
              </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseLocationDailog.bind(this)}>Close</Button>
            <Button loading={isLoading} intent={Intent.PRIMARY} onClick={this.createLocation.bind(this)}>Save</Button>
          </div>
        </div>
      </Dialog>

    )
  }

};

export default AddLocationModal;