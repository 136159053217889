import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Select } from "@blueprintjs/select";
import { 
  Button, MenuItem, Alert, Intent, Divider
} from '@blueprintjs/core';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import Toast from 'helpers/toaster';
import qs from 'qs';

@inject('store', 'formStore', 'viewStore') @observer
class FormsTable extends Component {

  @observable isDeleteDialogVisible = false;
  @observable form;

  decideAppearance = (status) => {
    switch (status) {
      case 'active':
        return 'success'
      default:
        break;
    }
  }

  onPaginationChange(newPage) {
    let {
      viewStore: {
        formSearch
      }
    } = this.props;
    this.props.viewStore.formSearch = {
      ...formSearch,
      page: newPage
    };
    this.props.viewStore.forms();
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.formStore.deleteForm(this.form)
      .then(this.onSuccessfulDeleteForm.bind(this))
      .catch(this.onFailedFormDelete.bind(this))
  }

  onSuccessfulDeleteForm(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Form deleted successfully!', intent: Intent.SUCCESS })
    this.form = null;
    this.isDeleteDialogVisible = false;
  }

  onFailedFormDelete(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete form!', intent: Intent.DANGER })
  }

  handleFormClick(event, form) {
    let {
      viewStore,
    } = this.props;
    switch (event.label) {
      case 'Edit':
        viewStore.formShow({uuid: form.id})
        break;
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.form = form;
        break;
      default:
    }
  }

  render() {
    let {
      viewStore,
      formStore,
      formStore: {
        forms: {
          objects,
          meta
        }
      }
    } = this.props;
    if (objects.length === 0) {
      return (
        <div className="bp3-callout bp3-icon-info-sign warning-alert-message bp3-intent-warning">
          <h4 className="bp3-heading">No Results</h4>
          Your search criteria returned no results. Please modify/expand your search parameters to see results.
        </div>
      )
    }
    return (
      <React.Fragment>
        <Footer>
          <div className="bp3-navbar-group">
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
        <div className="card rounded-0 table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th className="item-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              objects.map((form) => {
                return (
                  <tr key={form.attributes.id}>
                    <td>{form.attributes.id}</td>
                    <td>
                      <a onClick={() => { viewStore.formShow({uuid: form.id}) }} >
                        {form.attributes.name}
                      </a>
                    </td>
                    <td>
                      <div className="action-buttons" minimal={true}>
                        <Button title="Edit" minimal={true} small={true} onClick={() => viewStore.formShow({uuid: form.id})}>
                          <img src="/edit-light-icon.svg"/>
                        </Button>
                        <Divider/>
                        <Button title="Delete" minimal={true} small={true} onClick={() => this.handleFormClick({ label: 'Delete' }, form)}>
                          <img src="/delete-light-icon.svg" />
                        </Button>
                      </div>
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>
        </div>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            All associated data of this form (such as work orders, equipments, timesheet entries, jobs etc) will also be deleted. Are you sure you want to do this?
          </p>
        </Alert>
        <Footer>
          <div className="bp3-navbar-group" style={{'paddingLeft' : '20px'}}>
            <Pagination
              value={meta.page}
              total={meta.total_pages}
              onChange={this.onPaginationChange.bind(this)}
            />
          </div>
        </Footer>
      </React.Fragment>
    )
  }
}

export default FormsTable;