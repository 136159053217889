import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  FormGroup,Tab, Tabs, Button, Alert, Dialog, Classes, Spinner, 
  Intent,
  Menu, MenuItem, Position
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import Footer from 'components/Footer';
import AddEquipmentModal from 'components/Equipment/AddEquipmentModal';
import EquipmentsTable from 'components/Equipment/EquipmentsTable';
import RetiredEquipmentsTable from 'components/Equipment/RetiredEquipmentsTable';
import { observable } from 'mobx';
import styled from '@emotion/styled';

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;  
  min-height: calc(100vh - 75px);
`

@inject('equipmentStore','store','viewStore','workReportStore') @observer
class AddEquipmentForWorkReportModal extends Component {
  @observable isOpenAddEquipmentDialog = false;
  @observable selectedTabId = 'active-equipment';
  
  handleTabChange = (tabId) => {
    this.selectedTabId = tabId; 
    if(tabId === 'retired-equipment'){
      this.props.viewStore.equipmentSearchWorkReport.where.active = false;
      this.props.equipmentStore.loadEquipmentsForWorkReports(); 
    }else{
      this.props.viewStore.equipmentSearchWorkReport.where.active = true;
      this.props.equipmentStore.loadEquipmentsForWorkReports(); 
    }                  
  }

  handleClosEquipmentDailog() {
    this.props.store.isOpenAddEquipmentDialog = false;
  }

  onSuccessAddEquipment(equipment) {
    this.props.store.isOpenAddEquipmentDialog = false;   
  }

  handleClosEquipmentForWorkReportDailog() {
    this.props.onClose()
  }

  handleSearchChange(event) {
    this.props.viewStore.equipmentSearchWorkReport.term = event.target.value;    
  }

  handleSearch() { 
    if(this.props.viewStore.equipmentSearchWorkReport.term === ''){
      this.props.viewStore.equipmentSearchWorkReport.term = '*';
    }   
    this.props.equipmentStore.loadEquipmentsForWorkReports();
  }


  onPaginationChange(newPage) {
    let {
      viewStore: {
        equipmentSearchWorkReport
      }
    } = this.props;
    this.props.viewStore.equipmentSearchWorkReport = {
      ...equipmentSearchWorkReport,
      page: newPage
    };
    this.props.equipmentStore.loadEquipmentsForWorkReports(); 
  }

  render() {
    const {      
      section,
      workDetail,
      equipmentStore:{
        equipments:{
          objects,
          meta,
          isLoading
        }
      },
      store
    } = this.props;
   let {selectedTabId} = this;

   
    return(
      <div>
        <Dialog
          className="equipments-list-dialog"
          title="Add Equipment to Work Order"
          isOpen={this.props.isOpen}
          isCloseButtonShown={true}
          onClose={this.handleClosEquipmentForWorkReportDailog.bind(this)}
        >         
          <div className={Classes.DIALOG_BODY}>
            <div className="">
              <FormGroup
                label=""
                labelFor="text-input"
              >
                <div className="bp3-control-group">
                  <input 
                    type='text' 
                    className='bp3-input' 
                    placeholder='Search equipment...'                           
                    // style={{width:'90%'}} 
                    onChange={(event) => this.handleSearchChange(event) }
                  />
                  <Button rightIcon='search' text='Search' onClick={(event) => this.handleSearch(event) }/>
                </div>                   
              </FormGroup>           
            </div>

            <div className="tab-container">
              <div className="tab-block">
                <Tabs onChange={this.handleTabChange.bind(this)}  selectedTabId={section} large={true}>
                  <Tab id="active-equipment" title="Active" />
                  <Tab id="retired-equipment" title="Retired" />
                </Tabs>
              </div>
              <div className="text-right">
                <Button icon="add" text="Create New Equipment" onClick={() => store.isOpenAddEquipmentDialog = true} />
                {/* <Menu>
                  <MenuItem  icon={'plus'} onClick={() => store.isOpenAddEquipmentDialog = true} />
                </Menu> */}
              </div>
            </div>
            {
              selectedTabId === 'active-equipment' &&
              <EquipmentsTable workReportId={workDetail} objects={objects} isLoading={isLoading} meta={meta}/>
            }
            {
              selectedTabId === 'retired-equipment' &&
              <RetiredEquipmentsTable objects={objects} isLoading={isLoading} meta={meta}/>
            }

          </div>
        </Dialog>
        <AddEquipmentModal
          isOpen={store.isOpenAddEquipmentDialog}
          onClose={this.handleClosEquipmentDailog.bind(this)}
          onSuccess={this.onSuccessAddEquipment.bind(this)}
        />
        
    </div>
    )
  }

};

export default AddEquipmentForWorkReportModal;