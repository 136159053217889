import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import { Button, Dialog, Classes, Intent, FormGroup, TextArea, Checkbox
} from "@blueprintjs/core";
import Toast from 'helpers/toaster';
import MultipleEmailSelector from '../MultipleEmailSelector';

@inject("quoteStore", "paymentStore", "invoiceStore", "userStore")
@observer
class SendInvoiceModal extends Component {

  componentDidMount() {
    this.props.userStore.loadQuickbooks();
    let { object } = this.props.invoiceStore.sendPaymentInvoice;
    object.emailSubject = "Invoice [#"+this.props.invoiceStore.invoice.object.attributes.number+"] from "+this.props.invoiceStore.invoice.object.attributes.tenant.name
  }

  handleCloseQuoteInvoiceDialog() {
    this.props.onClose();
  }

  SendInvoiceToEmail() {
    let {
      props: {
        invoiceStore: {
          sendPaymentInvoice: { 
            object 
          },
          invoice
        },
        userStore: {
          quickbooks: {
            data
          }
        },
      }
    } = this;
    
    if(!object.emailTo) {
      Toast.show({ icon: 'warning-sign', message: "Please enter email", intent: Intent.DANGER })  
      return  
    }

    if(!object.emailSubject) {
      Toast.show({ icon: 'warning-sign', message: "Please enter email subject", intent: Intent.DANGER })
      return     
    }
    
    // if(!object.emailMessage) {
    //   Toast.show({ icon: 'warning-sign', message: "Please enter message", intent: Intent.DANGER })  
    //   return   
    // }

    if(invoice && invoice.object && invoice.object.attributes && invoice.object.attributes.synced_with_quickbooks === true){
      if(data && data.company_name){
        this.props.invoiceStore.SendInvoiceToEmailFromQuickBooks()
        .then(response => {
          this.props.onClose();
          Toast.show({
            icon: "thumbs-up",
            message: "Invoice sent successfully!",
            intent: Intent.SUCCESS
          });
        })
        .catch(error => {
          Toast.show({
            icon: "warning-sign",
            message: "Unable to send invoice!",
            intent: Intent.DANGER
          });
        });

      }else{
        Toast.show({ icon: 'warning-sign', message: "Please connect QuickBooks from account settings.", intent: Intent.DANGER })
        return
      }
      
    }else{
      this.props.invoiceStore.SendInvoiceToEmail()
      .then(response => {
        this.props.onClose();
        Toast.show({
          icon: "thumbs-up",
          message: "Invoice sent successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        if(error.response && error.response.data && error.response.data.errors) {
          Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
        } else {
          Toast.show({ icon: 'warning-sign', message: 'Something went wrong. Unable to send invoice!', intent: Intent.DANGER })
        }
      });
    } 
  }

  onEmailChange = (emails) => {
    let {
      props: {
        invoiceStore: {
          sendPaymentInvoice: { object }
        }
      }
    } = this;
    object.emailTo = emails.map((contact) => contact.email);
  }

  render() {
    const {
      invoiceStore: {
        sendPaymentInvoice : {object},
        invoice
      }
    } = this.props;
    return(
      <Dialog
        title="Send Invoice"
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseQuoteInvoiceDialog.bind(this)}
        className={'send-invoice-dialog'}
        canOutsideClickClose={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="col-12">
            <FormGroup label="To" labelFor="text-input">
              <MultipleEmailSelector 
                onEmailChange={this.onEmailChange.bind(this)}
                contacts={
                  invoice.object.attributes && invoice.object.attributes.client ? [
                    {
                      email: invoice.object.attributes.client.email,
                      name: invoice.object.attributes.client.name
                    } 
                  ] : []
                }
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup label="Subject" labelFor="text-input">
              <input
                type="text"
                className="bp3-input bp3-fill"
                placeholder="Subject"
                value={object.emailSubject}
                onChange={event => {
                  object.emailSubject = event.target.value;
                }}
              />
          </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup label="Message" labelFor="text-input">
              <TextArea
                className="bp3-fill"
                placeholder="Enter Your Message"
                onChange={event => {
                  object.emailMessage = event.target.value;
                }}
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <div style={{ marginBottom: '1rem'}} className="invoice_enable_signature">
              <Checkbox 
                label="Send a copy to myself"
                onChange={event => {
                  object.emailCopyToSelf = event.target.checked;
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <div style={{ marginBottom: '1rem'}} className="invoice_enable_signature">
              <Checkbox 
                label="Attach as PDF"  
                onChange={event => {
                  object.emailAttached = event.target.checked;
                }}
              />
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              fill="false"
              onClick={this.handleCloseQuoteInvoiceDialog.bind(this)}
              style={{ width: "100px" }}
            >
              Cancel{" "}
            </Button>
            <Button
              fill="false"
              intent={Intent.PRIMARY}
              loading={this.props.invoiceStore.sendPaymentInvoice.isLoading}
              onClick={this.SendInvoiceToEmail.bind(this)}
              style={{ width: "80px" }}
            >
              Send
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }

}

export default SendInvoiceModal;