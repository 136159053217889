import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from '@emotion/styled';
import qs from 'qs';
import _ from 'lodash';
import { observable } from 'mobx';

const SecondSidebar = styled('div')`     
    width: 100%;
    height: calc(100vh - 75px);
    border-right: 1px solid #E6E8F1;
    padding: 0px 0px 15px;       
    background-color:#ffffff;   
    position: sticky;
    top: 45px;    
`

@inject('viewStore') @observer
class SettingsSidebar extends Component {

  @observable isOpenBillingSetting = false;

  

  render(){
    let {
      viewStore
    } = this.props;
    return(
      <SecondSidebar className="sidebar-mobile"> 
          <div className="mobile-dropdown">
            <div className="setting-icon"><img src="/settings.svg" onClick={() => this.isOpenBillingSetting = !this.isOpenBillingSetting}/></div>
            <div className={this.isOpenBillingSetting ? "ssidebar-wrap " : "ssidebar-wrap ssidebar-wrap-hide" }>
              {/* <p className="mb-2"><strong>Setting</strong></p> */}
              <ul className="list-unstyled setting-list list-max-height">              
              <li className={viewStore.currentView === "Pages.Billing.Index" ? 'active' : ''}>
                <a href={`/billing?${qs.stringify(viewStore.billingInfo, { encode: false })}`} >Billing</a>
              </li>
              <li className={viewStore.currentView === "Pages.Taxes.Index" ? 'active' : ''}>
                <a href={`/taxes`} >Taxes</a>
              </li>
              <li className={viewStore.currentView === "Pages.PaymentMethod.Index" ? 'active' : ''}>
                <a href={`/payment_methods`} >Payment Methods</a>
              </li>
              <li className={viewStore.currentView === "Pages.ProductsServices.Index" ? 'active' : ''}>
                <a href={`/products_services`} >Pricebook</a>
              </li>
              {/* <li className={viewStore.currentView === "Pages.ProductCategory.Index" ? 'active' : ''}>
                <a href={`/product_category`} >Products Category</a>
              </li> */}
              
              <li className={viewStore.currentView === "Pages.Forms.Index" ? 'active' : ''}>
                <a href={`/dashboard/forms`} >Forms</a>
              </li>
              <li className={viewStore.currentView === "Pages.Preferences.Index" ? 'active' : ''}>
                <a href={`/preferences`} >Payment Settings</a>
              </li>                
              <li className={viewStore.currentView === "Pages.Preferences.DefaultLineItems" ? 'active' : ''}>
                <a href={`/preferences/default_line_items`} >Default Line Items</a>
              </li>  
              <li className={viewStore.currentView === "Pages.Preferences.CompanyDetails" ? 'active' : ''}>
                <a href={'/preferences/company_details'} >Company Details</a>
              </li>
              <li className={viewStore.currentView === "Pages.Preferences.AccountingSetup" ? 'active' : ''}>
                <a href={'/preferences/accounting_setup'} >Accounting Setup</a>
              </li>
              {/* <li className={viewStore.currentView === "Pages.Preferences.InvoiceSettings" ? 'active' : ''}><a onClick={() => { viewStore.invoiceSettings() }} >Invoice Settings</a></li>           */}
            </ul>            
            </div>
          </div>
        </SecondSidebar>
    )
  }
}
  
export default SettingsSidebar;