import React, { Component } from 'react';

const MultipleSubtexts = ({ texts }) => {
  return (
    <div>
      {texts.map((text) => {
        return (
          <div className="field-data" key={Math.random()}>
            {text}
          </div>
        )
      })}
    </div>
  )
}

const MultipleSubtextsHtml = ({ texts }) => {
  return (
    <div className="field-data-wrapper">
      {texts.map((text) => {
        return (
          <div className="field-data" style={{marginRight: 2}} key={Math.random()}>
            <div dangerouslySetInnerHTML={{__html: text}} />
          </div>
        )
      })}
    </div>
  )
}

class TableCellWithSubtext extends Component {
  render() {
    const {
      mainLink,
      mainText,
      subText,
      subTextHtml,
      onClick
    } = this.props;
    return (
      <React.Fragment>
        <div className="field-title">
          {mainLink && <a href={mainLink} onClick={onClick ? onClick : () => { }}>{mainText}</a>}
          {!mainLink && mainText}
        </div>
        {
          Array.isArray(subText) ? <MultipleSubtexts texts={subText} /> : <div className="field-data">{subText}</div>
        }
        {
          Array.isArray(subTextHtml) ? <MultipleSubtextsHtml texts={subTextHtml} /> : <div className="field-data" dangerouslySetInnerHTML={{__html: subTextHtml}}></div>
        }
      </React.Fragment>
    )
  }
}

export default TableCellWithSubtext;