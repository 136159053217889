import React, { Component } from 'react';
import { Button, Intent } from "@blueprintjs/core";
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Toast from 'helpers/toaster';
import FooterIllustration from 'components/FooterIllustration';
import UnauthenticatedPage from 'components/UnauthenticatedPage';
import { Helmet } from 'react-helmet';
import { TopNavbar } from 'components/Styles';
import MainSiteLogo from 'components/MainSiteLogo';

@inject('viewStore', 'userStore') @observer
class Invitation extends Component {

  @observable loading = false;
  @observable isDisabled = true;

  checkIsDiabled() {
    const {
      props: {
        userStore,
        userStore: {
          invitation
        }
      }
    } = this;

    if(!invitation.email || !invitation.first_name || !invitation.last_name || !invitation.password || !invitation.password_confirmation || invitation.password !== invitation.password_confirmation){
      this.isDisabled = true;
    }else{
      this.isDisabled = false
    }
  }

  onAccept() {
    var error = false;
    const {
      props: {
        userStore,
        userStore: {
          invitation
        }
      }
    } = this;
    this.loading = true;

    if (!invitation.first_name) {
      Toast.show({ icon: 'warning-sign', message: 'First name is required', intent: Intent.DANGER })
      error = true;
    }
    if (!invitation.password) {
      Toast.show({ icon: 'warning-sign', message: 'Password is required', intent: Intent.DANGER })
      error = true;
    }
    if (invitation.password !== invitation.password_confirmation) {
      Toast.show({ icon: 'warning-sign', message: 'Password and Password confirmation is not identical', intent: Intent.DANGER })
      error = true;
    }
    if (!error) {
      this.loading = true;
      userStore.acceptInvitation()
        .then((response) => {
          this.loading = false;
          Toast.show({ icon: 'thumbs-up', message: 'Invitation accepted successfully!', intent: Intent.SUCCESS })
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data) {
            Toast.show({ icon: 'warning-sign', message: error.response.data.error, intent: Intent.DANGER })
          } else {
            Toast.show({ icon: 'warning-sign', message: 'Unable to accept invitation, please ensure your internet is working fine.', intent: Intent.DANGER })
          }
        })
    }
  }

  _handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.onLogin();
    }
  }

  render() {
    const {
      props: {
        userStore: {
          invitation
        }
      },
      loading, 
      isDisabled
    } = this;
    return (
      <UnauthenticatedPage>
        <Helmet>
          <title>Accept Invitation | FieldNexus</title>
        </Helmet>
        <TopNavbar>
          <div className="tp-nav">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-7">
                  <MainSiteLogo />
                </div>
                <div className="col-5 text-right">
                  <div className="right-link-tp">
                    <div className="top-btn-back"><a href=""><img src="/back.svg" title="Go Back" alt='Back' />Dashboard</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div><div className="top-btn-back"></div>
        </TopNavbar>

        <div className="form-wrap">
          <br></br>
          <h3 className="login-heading">
            {invitation.first_name ? `Hello ${invitation.first_name},` : 'Accept Invitation'}
          </h3>
          <p>
            You've been invited to join Field Nexus by one of your team members. Accept this invitation by providing your first name, last name, phone number and a password of your choosing below and hit "Accept Invite"
          </p>
          <p>
            Also note the email below this email will become your username to login to Field Nexus.
          </p>
          <div className="row mt-3">
            <div className="col-sm-12">
              <input
                type="email"
                className="form-control-outline"
                placeholder="Email"
                defaultValue={invitation.email}
                readOnly
                disabled
                style={{ color: '#aeb4bd' }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control-outline"
                placeholder="First name"
                defaultValue={invitation.first_name}
                onChange={e => { 
                  invitation.first_name = e.target.value
                  this.checkIsDiabled();
                }}
                onKeyPress={this._handleKeyPress.bind(this)}
                required
              />
            </div>
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control-outline"
                placeholder="Last name"
                defaultValue={invitation.last_name}
                onChange={e => { 
                  invitation.last_name = e.target.value
                  this.checkIsDiabled();
                }}
                onKeyPress={this._handleKeyPress.bind(this)}
                required
              />
            </div>
          </div>
          <hr />
          <div className="row mt-3">
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control-outline"
                placeholder="Phone"
                defaultValue={invitation.phone_number}
                onChange={e => { 
                  invitation.phone_number = e.target.value
                  this.checkIsDiabled();
                }}
                onKeyPress={this._handleKeyPress.bind(this)}
                required
              />
            </div>

          </div>
          <div className="row mt-3">
            <div className="col-sm-6">
              <input
                type="password"
                className="form-control-outline"
                placeholder="Password"
                defaultValue={invitation.password}
                onChange={e => { 
                  invitation.password = e.target.value
                  this.checkIsDiabled();
                }}
                onKeyPress={this._handleKeyPress.bind(this)}
                required
              />
            </div>
            <div className="col-sm-6">
              <input
                type="password"
                className="form-control-outline"
                placeholder="Password confirmation"
                defaultValue={invitation.password_confirmation}
                onChange={e => { 
                  invitation.password_confirmation = e.target.value
                  this.checkIsDiabled();
                }}
                onKeyPress={this._handleKeyPress.bind(this)}
                required
              />
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-sm-6 col-5 fg-pass">
            </div>
            <div className="col-sm-6 col-7 fg-next">
              <Button
                className="btn-gray"
                disabled={isDisabled}
                onClick={this.onAccept.bind(this)} 
                loading={loading}
              >
                Accept Invite
              </Button>
            </div>
          </div>
        </div>

        <FooterIllustration />
      </UnauthenticatedPage>
    )
  }
};

export default Invitation;