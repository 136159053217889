import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  TextArea, Checkbox
} from '@blueprintjs/core';
import DateInput from 'components/DateInput';
import moment from 'moment';
import RichTextEditor from 'react-rte'; 
import { observable } from 'mobx';

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  ]
};

@inject('store', 'invoiceStore') @observer
class InvoiceDescription extends Component {

  @observable agreement_copy = "";
  componentWillMount() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;

    if(object && object.attributes.agreement_copy) {
      this.agreement_copy = RichTextEditor.createValueFromString(object.attributes.agreement_copy, 'html');
    } else {
      this.agreement_copy = RichTextEditor.createValueFromString("", 'html');
    }

    if(!object.attributes.invoice_due_date) {
      object.attributes.invoice_due_date = moment(object.attributes.created_at)
    }
  }

  onInvoiceDueDateChange(date) {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    object.attributes.invoice_due_date = moment(date);
  }

  onInvoiceCreatedAtChange(date) {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    object.attributes.created_at = moment(date);
  }

  onChangeAgreementCopy(val) {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props
    this.agreement_copy = val;
    object.attributes.agreement_copy = val.toString('html');
  }

  onInvoicePoNumberChange(event) {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    object.attributes.po_number = event.target.value;
  }

  render() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    return (
      <React.Fragment>
        <div className="invoice-customer-details" style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            {/* <div>
              <h6 className="details-1">
              {
                object.attributes.job.title &&
                  <div>
                    <strong>Reason for Call: </strong> 
                    <span>{object.attributes.job.title}</span>
                  </div>
              }
              </h6>
            </div> */}
            {/* <div>
              <h6 className="details-1">
                <strong>Invoice due date: </strong>
              </h6>
            </div> */}
          </div>
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
          <h6 className="details-1 date-time-input-invoice-div">
            <DateTimeInput 
              placeholder={'Invoice Due Date'}
              defaultValue={moment(object.attributes.invoice_due_date)}
              onChange={this.onInvoiceDueDateChange.bind(this)}
            />
          </h6>
        </div> */}
        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <h6 className="details-1 text-right" style={{paddingRight: 10}}>
              <strong>PO Number:</strong>
            </h6>
            <h6 className="details-1 date-time-input-invoice-div invoice-date-input-text-right">
              <input
                className="bp3-input"
                type="text"
                placeholder="PO Number"
                dir="auto"
                defaultValue={object.attributes.po_number}
                onChange={this.onInvoicePoNumberChange.bind(this)}
              />
            </h6>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <h6 className="details-1 text-right" style={{paddingRight: 10}}>
              <strong>Invoice date: </strong>
            </h6>
            <h6 className="details-1 date-time-input-invoice-div invoice-date-input-text-right">
              <DateInput 
                placeholder={'Invoice Date'}
                defaultValue={moment(object.attributes.created_at)}
                onChange={this.onInvoiceCreatedAtChange.bind(this)}
              />
            </h6>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 15}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <h6 className="details-1 text-right" style={{paddingRight: 10}}>
              <strong>Invoice due date: </strong>
            </h6>
            <h6 className="details-1 date-time-input-invoice-div invoice-date-input-text-right">
              <DateInput 
                placeholder={'Invoice Due Date'}
                defaultValue={moment(object.attributes.invoice_due_date)}
                onChange={this.onInvoiceDueDateChange.bind(this)}
              />
            </h6>
          </div>
        </div> */}
          {/* <div className="due-date">
            <h6 className="details-1">
              <strong>Invoice due date:</strong>
              <DateTimeInput 
                placeholder={'Invoice Due Date'}
                defaultValue={moment(object.attributes.invoice_due_date)}
                onChange={this.onInvoiceDueDateChange.bind(this)}
              />
            </h6>
            <h6 className="details-1">
              <strong>Payment Status:</strong>
              <Tag intent={object.attributes.payment_status === 'paid' ? 'success' : 'warning'}>
                {object.attributes.payment_status || "unpaid"}
              </Tag>
            </h6>
            <div className="reason-for-call">
              <h6 className="details-1">
                <strong>Reason for Call:</strong> {object.attributes.job.title}
              </h6>
            </div>
          </div>
          
        </div> */}

        {/* Enable invoice signature: */}
        {/* <div style={{ padding: '10px', marginBottom: '1rem'}} className="invoice_enable_signature">
          <Checkbox 
            checked={object.attributes.enable_invoice_signature} 
            large={false}
            label="Require Signature" 
            onChange={(event) => {object.attributes.enable_invoice_signature = event.target.checked}} />
        </div> */}

        {/* Job Description: */}
        <div className="row">
          <div className="col-md-6">
            <div className="job-desription" style={{ padding: '10px', marginBottom: '1rem' }}>
              <h5>
                <strong className="field-label">Customer Notes:</strong>
              </h5>
              <TextArea
                style={{ width: '100%', minHeight: '130px' }}
                className="description-textarea"
                growVertically={false}
                large={true}
                defaultValue={object.attributes.description}
                onChange={(event) => {object.attributes.description = event.target.value}}
              />
            </div>

          </div>
          <div className="col-md-6">
            <div className="job-desription" style={{ padding: '10px', marginBottom: '1rem' }}>
              <h5>
                <strong className="field-label">Payment Terms:</strong>
              </h5>
              <TextArea
                style={{ width: '100%', minHeight: '130px' }}
                className="description-textarea"
                growVertically={false}
                large={true}
                defaultValue={object.attributes.payment_terms}
                onChange={(event) => {object.attributes.payment_terms = event.target.value}}
              />
            </div>

          </div>
        </div>
        
        {/* Agreement copy: */}
        <div className="job-desription" style={{ padding: '10px', marginBottom: '1rem' }}>
          <h5>
            <strong className="field-label">Agreement Copy:</strong>
          </h5>
          <RichTextEditor
            value={this.agreement_copy}
            onChange={this.onChangeAgreementCopy.bind(this)}
            className='text-editor'
            toolbarConfig={toolbarConfig}
            placeholder="Agreement Copy"
          />
          {/* <TextArea
            style={{ width: '100%', minHeight: '130px' }}
            className="description-textarea"
            growVertically={false}
            large={true}
            defaultValue={object.attributes.agreement_copy}
            onChange={(event) => {object.attributes.agreement_copy = event.target.value}}
          /> */}
        </div>
      </React.Fragment>
    )
  }
}
export default InvoiceDescription;
