import React, { Component } from 'react'
import { Button, Popover, Position, Tag, Menu, MenuItem, ButtonGroup } from '@blueprintjs/core';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import ListPicker from 'components/ListPicker';
import ClientAutocomplete from 'components/ClientAutocomplete';
import SearchDateRangePicker from 'components/SearchDateRangePicker';
import moment from 'moment-timezone';
import AddProjectModal from 'components/Project/AddProjectModal';

const options = [
  { label: 'Active', value: 'active', selected: false },
  { label: 'Completed', value: 'completed', selected: false },
  { label: 'In Progress', value: 'in_progress', selected: false },
  { label: 'Invoiced', value: 'invoiced', selected: false },
  { label: 'Paused', value: 'paused', selected: false },
];

@inject('projectStore', 'viewStore', 'store') @observer
class Project extends Component {

  @observable startDate = null;
  @observable endDate = null;

  @action
  onProjectStatusChange(selections) {
    let selectedValues = selections.map((status) => status.value);
    let {
      props: {
        viewStore: {
          projectSearch
        }
      }
    } = this;
    this.props.viewStore.projectSearch = {
      ...projectSearch,
      where: {
        ...projectSearch.where,
        status: selectedValues
      }
    }
  }

  @action
  onProjectNumberChange(e) {
    let {
      props: {
        viewStore: {
          projectSearch
        }
      }
    } = this;
    this.props.viewStore.projectSearch = {
      ...projectSearch,
      where: {
        ...projectSearch.where,
        number: e.target.value
      }
    }
  }

  @action
  onPONumberChange(e) {
    let {
      props: {
        viewStore: {
          projectSearch
        }
      }
    } = this;
    this.props.viewStore.projectSearch = {
      ...projectSearch,
      where: {
        ...projectSearch.where,
        po_number: e.target.value
      }
    }
  }

  @action
  onDateRangeChange(range) {
    let {
      props: {
        viewStore: {
          projectSearch
        }
      }
    } = this;
    this.props.viewStore.projectSearch = {
      ...projectSearch,
      where: {
        ...projectSearch.where,
        start_date: { gte: moment(range[0]).startOf('day').unix() },
        end_date: { lte: moment(range[1]).endOf('day').unix() }
      }
    }
    this.startDate = range[0];
    this.endDate = range[1];
    this.search();
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.search()
    }
  }

  @action
  search() {
    let viewStore = this.props.viewStore;
    viewStore.projectSearch.page = 1;
    viewStore.projects();
  }

  // download_bulk_excel() {
  //   this.props.projectStore.download_bulk_excel(this.props.viewStore.projectSearch)
  // }

  @action
  onClientSelect(client) {
    let {
      props: {
        viewStore: {
          projectSearch
        }
      }
    } = this;
    if (client) {
      this.props.viewStore.projectSearch = {
        ...projectSearch,
        where: {
          ...projectSearch.where,
          client_id: client.id
        }
      }
    } else {
      this.props.viewStore.projectSearch = {
        ...projectSearch,
        where: {
          ...projectSearch.where,
          client_id: []
        }
      }
    }
  }

  handleCloseProjectDailog() {
    const { store } = this.props;
    store.isOpenAddProjectDialog = false;
  }

  onSuccessAddProject(project) {
    const { store, projectStore } = this.props;
    store.isOpenAddProjectDialog = false;
    projectStore.loadProjects()
  }

  render() {
    const { store } = this.props;
    const {
      where
    } = this.props.viewStore.projectSearch;
    let startDate = moment.unix(parseInt(where.start_date.gte)).toDate();
    let endDate = moment.unix(parseInt(where.end_date.lte)).toDate();
    return (
      <nav className="bp3-navbar search bg-transparent navbar-overflow">
        <div>
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <Popover
                minimal={true} 
                content={
                  <SearchDateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onChange={this.onDateRangeChange.bind(this)}
                  />
                }
                position={Position.BOTTOM_LEFT}
                usePortal={true} >
                <Button rightIcon='caret-down'>
                  {(!startDate || !endDate) && `Date Range`}
                  {startDate && endDate && `${startDate.toDateString()} - ${endDate.toDateString()}`}
                </Button>
              </Popover>
              <div className="bp3-control-group">
                <ClientAutocomplete
                  selectedClientId={where && where.client_id}
                  onClientSelect={this.onClientSelect.bind(this)}
                />
              
                <Popover
                  minimal={true}
                  content={
                    <ListPicker
                      selectedOptions={(where && where.status) || []}
                      options={options}
                      onChange={this.onProjectStatusChange.bind(this)}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                  usePortal={true} >
                  <Button rightIcon='caret-down'>
                    Status
                    <span>&nbsp;</span>
                    <Tag minimal={true} round={true}>{(where && where.status && where.status.length) || 0}</Tag>
                  </Button>
                </Popover>
                <input 
                  type="text"
                  className="bp3-input"
                  placeholder="Project Number"
                  onChange={this.onProjectNumberChange.bind(this)}
                  defaultValue={where.number}
                  onKeyPress={this.onEnter.bind(this)}
                />
                <input
                  type="text"
                  className="bp3-input"
                  placeholder="PO Number"
                  onChange={this.onPONumberChange.bind(this)}
                  defaultValue={where.po_number}
                  onKeyPress={this.onEnter.bind(this)}
                />
                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
              </div>
            </div>
          </div>
          
          <AddProjectModal 
            isOpen={store.isOpenAddProjectDialog}
            detailData= {store.projectDetail}
            onClose={this.handleCloseProjectDailog.bind(this)}
            onSuccess={this.onSuccessAddProject.bind(this)}
          />
        </div>
      </nav>
    )
  }
}

Project.propTypes = {
  onChange: PropTypes.func
}

export default Project