import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SearchBars from 'components/SearchBars';
import Card from 'components/Card';
import Page from 'components/Page';
import SearchBarWrapper from 'components/SearchBarWrapper';
import { observer, inject } from 'mobx-react';
import { 
  Spinner, NonIdealState
} from "@blueprintjs/core";
import ProjectsTable from 'components/Tables/Projects';
import Sticky from 'react-stickynode';
import styled from '@emotion/styled';
import ProjectSidebar from '../projects/project-sidebar';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'projectStore') @observer
class ProjectsIndexPage extends Component {

  render() {
    let content;
    const {
      props: {
        projectStore: {
          projects: {
            isLoading,
            isError
          }
        }
      }
    } = this;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (!isError && !isLoading) {
      content = <ProjectsTable />
    }
    return (
      <div>
        <PageWrapGray>
          
          <Helmet>
            <title>Projects | FieldNexus</title>
          </Helmet>
          <div className="row no-gutters">
            <div className="col-lg-2 sidebar-responsive">
                <ProjectSidebar />
            </div>
            <div className="col-lg-10">       
              <Page>
              <Sticky enabled={true} top={0} innerZ={9} className="sticky-mobile">
                <SearchBarWrapper>
                  <SearchBars.Project />
                </SearchBarWrapper>
              </Sticky>
                <Card>
                  {content}
                </Card>
              </Page>
            </div> 
          </div>
        </PageWrapGray>
        
      </div>
    )
  }

};

export default ProjectsIndexPage;