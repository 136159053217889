import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  TextArea, Checkbox
} from '@blueprintjs/core';
import { observable } from 'mobx';
import RichTextEditor from 'react-rte'; 

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  ]
};

@inject('store', 'quoteStore') @observer
class QuoteDescription extends Component {

  @observable value = RichTextEditor.createValueFromString('Jasdeep Singh Narang', 'html');
  @observable description_notes = RichTextEditor.createValueFromString('Jasdeep Singh Narang', 'html');
  @observable private_note = RichTextEditor.createValueFromString('Jasdeep Singh Narang', 'html');

  componentDidMount() {
    let {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;
    if (!object.attributes.agreement_copy) {
      this.value = RichTextEditor.createValueFromString(`
          <p><strong>  </strong></p>
        `, 'html');
    } else {
      this.value = RichTextEditor.createValueFromString(object.attributes.agreement_copy,'html');
    }
    if (!object.attributes.description) {
      this.description_notes = RichTextEditor.createValueFromString(`
          <p><strong>  </strong></p>
        `, 'html');
    } else {
      this.description_notes = RichTextEditor.createValueFromString(object.attributes.description,'html');
    }
    if (!object.attributes.private_notes) {
      this.private_note = RichTextEditor.createValueFromString(`
          <p><strong>  </strong></p>
        `, 'html');
    } else {
      this.private_note = RichTextEditor.createValueFromString(object.attributes.private_notes,'html');
    }
  }

  onChange(val) {
    let {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;

    this.value = val;
    object.attributes.agreement_copy = val.toString('html');
  }

  onChangePrivateNotes(val){
    let {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;

    this.private_note = val;
    object.attributes.private_notes = val.toString('html');
  }

  onChangeNotes(val) {
    let {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;

    this.description_notes = val;
    object.attributes.description = val.toString('html');
  }

  render() {
    const {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props; 
    return (
      <React.Fragment>
        {/* <div className="reason-description" style={{ display: 'flex', padding: '10px', marginBottom: '0rem' }}>
          <div className="reason-for-call">
            <div className="details-1">
              <strong className="label">Reason for Call:</strong> 
              <div>{object.attributes.job.title}</div>
            </div>
          </div>
        </div> */}

        {/* Enable invoice signature: */}
        <div style={{ padding: '10px', marginBottom: '1rem'}} className="invoice_enable_signature">
          <Checkbox 
            checked={object.attributes.enable_invoice_signature} 
            large={false}
            label="Require Signature" 
            onChange={(event) => {object.attributes.enable_invoice_signature = event.target.checked}} />
        </div>

        {/* Job Description: */}
        <div className="job-desription" style={{ padding: '10px', marginBottom: '1rem' }}>
          <h5>
            <strong>Customer Notes:</strong>
          </h5>
          <div className="editor-nd" style={{marginLeft: 0, marginRight: 10, marginBottom: 12}}>
            {/* <TextArea
              style={{ width: '100%', height: '130px' }}
              className="description-textarea"
              growVertically={false}
              defaultValue={object.attributes.description}
              onChange={(event) => {object.attributes.description = event.target.value}}
            /> */}
            <RichTextEditor
              value={this.description_notes}
              onChange={this.onChangeNotes.bind(this)}
              className='text-editor'
              toolbarConfig={toolbarConfig}
              placeholder="Customer Notes"
            />
          </div>
        </div>

        {/* Estimate Agreement: */}
        <div className="job-desription" style={{ padding: '10px', marginBottom: '1rem' }}>
          <h5>
            <strong>Estimate Agreement:</strong>
          </h5>
          <div className="editor-nd" style={{marginLeft: 0, marginRight: 10, marginBottom: 12}}>
            <RichTextEditor
              value={this.value}
              onChange={this.onChange.bind(this)}
              className='text-editor'
              toolbarConfig={toolbarConfig}
              placeholder="Estimate Agreement"
            />
          </div>
        </div>
        <div className="job-desription" style={{ padding: '10px', marginBottom: '1rem' }}>
          <h5>
            <strong>Private Notes:</strong>
          </h5>
          <div className="editor-nd" style={{ marginLeft: 0, marginRight: 10, marginBottom: 12 }}>
            <RichTextEditor
              value={this.private_note}
              onChange={this.onChangePrivateNotes.bind(this)}
              className='text-editor'
              toolbarConfig={toolbarConfig}
              placeholder="Private Notes"
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default QuoteDescription;