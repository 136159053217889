import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Button,
  Dialog,
  Classes,
  Intent,
  TextArea, Switch
} from "@blueprintjs/core";
import TechnicianPicker from "components/TechnicianPicker";
import JobPicker from "components/JobPicker";
import { observable, toJS } from 'mobx';
import Toast from "helpers/toaster";
import _ from 'lodash';
import { currencyFormattedAmount } from 'helpers/currency';
var sub_total = 0;
var total_tax = 0;
var grand_total = 0;
var total_discount = 0;
var custom_line_item={
  attributes:{
    description:'',
    total:'',
    tax_line_item_id:'',
    quantity:'',
    markup:'',
    rate_per_unit:'',
    tax_amount:''
  }
}
@inject("viewStore", "invoiceStore","jobStore","taxStore")
@observer
class InvoiceJobModal extends Component {

  @observable selectedOptions = null;
  @observable job_detals = null;
  @observable selectedMaterialTaxId = '';
  @observable selectedMaterialTaxValue = 0;
  @observable flat_rate = false;
  

  componentDidMount() {
    let {
      viewStore: {
        currentUser
      },
      invoiceStore: {
        jobInvoice: {
          object
        }
      }
    } = this.props; 
    object.technician_id = [currentUser.id]
    window.$crisp.push(['do', 'chat:hide']);
  }


  createInvoice() {
    let { object } = this.props.invoiceStore.jobInvoice;
    let {
      jobStore:{
        materialLineItems
      }
    } = this.props;
    object.line_items = [];
    if (object.client_id === '' || object.client_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please choose customer name.', intent: Intent.DANGER })
      return;
    }
    if (object.technician_id === '' || object.technician_id === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please choose created by.', intent: Intent.DANGER })
      return;
    }

    if(this.flat_rate == false){
      object.materials =  _.filter(materialLineItems.object.line_items.materials, function(material) { 
        return material.attributes.is_checked ? material: '';
      });
  
      object.labours =  _.filter(materialLineItems.object.line_items.labours, function(labor) { 
        return labor.attributes.is_checked ? labor: '';
      });
  
      object.customs =  _.filter(materialLineItems.object.line_items.customs, function(custom) { 
        return custom.attributes.is_checked ? custom: '';
      });
    }else{
      object.materials = []
      object.labours = []
      object.customs = []
      object.customs[0] = custom_line_item
    }

    object.flat_rate = this.flat_rate;
    object.grand_total = grand_total;
    object.sub_total = sub_total;

    object.description = materialLineItems.object.line_items.descriptions.map(x => x).join('\n')
    
    this.props.invoiceStore
      .createJobInvoice()
      .then(response => {
        this.props.onClose();
        Toast.show({
          icon: "thumbs-up",
          message: "Invoice created successfully!",
          intent: Intent.SUCCESS
        });
      })
      .catch(error => {
        Toast.show({
          icon: "warning-sign",
          message: "Unable to create invoice!",
          intent: Intent.DANGER
        });
      });
  }

  handleCloseInvoiceDailog() {
    this.props.onClose();
  }

  onClientClear = () => {
    let { object } = this.props.invoiceStore.jobInvoice;
    object.client_id = null;
    object.selectedClient = '';
  };

  onTechnicianChange(technicians) {
    let {
      props: {
        invoiceStore: {
          jobInvoice: { object }
        }
      }
    } = this;
    let selectedValues = technicians.map(status => status.value);
    object.technician_id = [selectedValues[0]];
  }

  onJobSelect(selectedOptions, allOptions) {
    let {
      props: {
        invoiceStore: {
          jobInvoice: { object }
        }
      }
    } = this;
    this.selectedOptions = selectedOptions;
    object.selectedJob = [selectedOptions[0]];
    object.scheduled_event_id = selectedOptions[0].value;
  }

  onLocationClear = () => {
    let {
      props: {
        invoiceStore: {
          jobInvoice: { object }
        }
      }
    } = this;
    object.location_id = null;
    object.location = null;
  };

  calculateTotalLaborTax(event,labor) {
    let {
      taxStore,
      taxStore: {
        taxes: {
          objects
        }
      },
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;

    let taxLineItem = _.find(objects, {attributes: {id: event.target.value}});
    var removeIndex = object.line_items.labours.map(function(item) { return item.id; }).indexOf(labor.id);
    if(taxLineItem && taxLineItem.attributes){
      if(removeIndex > -1){
        object.line_items.labours[removeIndex].attributes.tax_line_item_id = taxLineItem.attributes.id;
      }else{
        object.line_items.labours[removeIndex].attributes.tax_line_item_id = taxLineItem.attributes.id;
      }
      object.line_items.labours[removeIndex].attributes.tax_amount = (taxLineItem.attributes.percentage_value/100) * labor.attributes.total
    }else{
      if(removeIndex > -1){
        object.line_items.labours[removeIndex].attributes.tax_line_item_id = '';
        object.line_items.labours[removeIndex].attributes.tax_amount = 0;
      }
    }
    
    this.calculateSubTotal(); 
  }

  calculateTax(){
    let {
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;

    this.forceUpdate(); 

    total_tax = _.sumBy(object.line_items.labours, labour => {
      return labour.attributes.is_checked ? labour.attributes.tax_amount : 0;
    });

    total_tax = total_tax + _.sumBy(object.line_items.materials, material => {
      return material.attributes.is_checked ? material.attributes.tax_amount : 0;
    });

    total_tax = total_tax + _.sumBy(object.line_items.customs, custom => {
      return custom.attributes.is_checked ? custom.attributes.tax_amount : 0;
    });

    grand_total = (total_tax + sub_total) - total_discount;

  }

  calculateSubTotal(){
    let {
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;

    

    sub_total = _.sumBy(object.line_items.labours, labour => {
      return labour.attributes.is_checked ? labour.attributes.total : 0;
    });

    sub_total = sub_total + _.sumBy(object.line_items.customs, custom => {
      return custom.attributes.is_checked ? custom.attributes.total : 0;
    });

    sub_total = sub_total + _.sumBy(object.line_items.materials, material => {
      return material.attributes.is_checked ? material.attributes.total : 0;
    });

    this.calculateTax();
  }

  selectMaterialLineItem(event,material_line_item_id){
    let {
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;

    var removeIndex = object.line_items.materials.map(function(item) { return item.id; }).indexOf(material_line_item_id);
    if(removeIndex > -1){
      object.line_items.materials[removeIndex].attributes.is_checked= event.target.checked; 
      object.line_items.materials[removeIndex].attributes.rate_per_unit= object.line_items.materials[removeIndex].attributes.markup ? (object.line_items.materials[removeIndex].attributes.rate_per_unit * parseFloat(object.line_items.materials[removeIndex].attributes.markup)).toFixed(2) : object.line_items.materials[removeIndex].attributes.rate_per_unit; 
    }else{
      object.line_items.materials[removeIndex].attributes.is_checked= false;
    }
    this.calculateSubTotal();
  }

  selectLaborLineItem(event,labor_line_item_id){
    let {
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;

    var removeIndex = object.line_items.labours.map(function(item) { return item.id; }).indexOf(labor_line_item_id);
    if(removeIndex > -1){
      object.line_items.labours[removeIndex].attributes.is_checked= event.target.checked; 
      object.line_items.labours[removeIndex].attributes.rate_per_unit= object.line_items.labours[removeIndex].attributes.markup ? (object.line_items.labours[removeIndex].attributes.rate_per_unit * parseFloat(object.line_items.labours[removeIndex].attributes.markup)).toFixed(2) : object.line_items.labours[removeIndex].attributes.rate_per_unit; 
    }else{
      object.line_items.labours[removeIndex].attributes.is_checked= false;
    } 
    
    this.calculateSubTotal();
  }

  selectCustomLineItem(event,custome_line_item_id){
    let {
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;

    var removeIndex = object.line_items.customs.map(function(item) { return item.id; }).indexOf(custome_line_item_id);
    if(removeIndex > -1){
      object.line_items.customs[removeIndex].attributes.is_checked= event.target.checked;  
    }else{
      object.line_items.customs[removeIndex].attributes.is_checked= false;
    }
    this.calculateSubTotal();
  }

  calculateTotalMaterialTax(event,material) {
    let {
      taxStore,
      taxStore: {
        taxes: {
          objects
        }
      },
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;

    let taxLineItem = _.find(objects, {attributes: {id: event.target.value}});
    var removeIndex = object.line_items.materials.map(function(item) { return item.id; }).indexOf(material.id);
    if(taxLineItem && taxLineItem.attributes){
      if(removeIndex > -1){
        object.line_items.materials[removeIndex].attributes.tax_line_item_id = taxLineItem.attributes.id;
      }else{
        object.line_items.materials[removeIndex].attributes.tax_line_item_id = taxLineItem.attributes.id;
      }
      object.line_items.materials[removeIndex].attributes.tax_amount = (taxLineItem.attributes.percentage_value/100) * material.attributes.total
    }else{
      if(removeIndex > -1){
        object.line_items.materials[removeIndex].attributes.tax_line_item_id = '';
        object.line_items.materials[removeIndex].attributes.tax_amount = 0;
      }
    }
    this.calculateSubTotal();
  }

  calculateTotalCustomTax(event,custom) {
    let {
      taxStore,
      taxStore: {
        taxes: {
          objects
        }
      },
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;

    let taxLineItem = _.find(objects, {attributes: {id: event.target.value}});
    var removeIndex = object.line_items.customs.map(function(item) { return item.id; }).indexOf(custom.id);
    if(taxLineItem && taxLineItem.attributes){
      if(removeIndex > -1){
        object.line_items.customs[removeIndex].attributes.tax_line_item_id = taxLineItem.attributes.id;
      }else{
        object.line_items.customs[removeIndex].attributes.tax_line_item_id = taxLineItem.attributes.id;
      }
      object.line_items.customs[removeIndex].attributes.tax_amount = (taxLineItem.attributes.percentage_value/100) * custom.attributes.total
    }else{
      if(removeIndex > -1){
        object.line_items.customs[removeIndex].attributes.tax_line_item_id = '';
        object.line_items.customs[removeIndex].attributes.tax_amount = 0;
      }
    }
    this.calculateSubTotal();
    
  }

  taxForCustomLineItem(event,custom) {
    let {
      taxStore,
      taxStore: {
        taxes: {
          objects
        }
      },
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;
    let taxLineItem = _.find(objects, {attributes: {id: event.target.value}});
    if(taxLineItem && taxLineItem.attributes){
      custom_line_item.attributes.tax_line_item_id = taxLineItem.attributes.id;
      custom_line_item.attributes.tax_amount = (taxLineItem.attributes.percentage_value/100) * sub_total;
      total_tax = (taxLineItem.attributes.percentage_value/100) * sub_total;
      grand_total = (total_tax + sub_total) - total_discount;
      this.forceUpdate(); 
    }
    
  }

  titleForCustomLineItem(event,custom) {
    let {
      taxStore,
      taxStore: {
        taxes: {
          objects
        }
      },
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;
    custom_line_item.attributes.description = event.target.value; 
  }

  quantityForCustomLineItem(event,custom) {
    let {
      taxStore,
      taxStore: {
        taxes: {
          objects
        }
      },
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;
    custom_line_item.attributes.quantity = event.target.value; 
    let taxLineItem = _.find(objects, {attributes: {id: custom_line_item.attributes.tax_line_item_id}});
    sub_total =  parseFloat(custom_line_item.attributes.quantity).toFixed(2) * parseFloat(custom_line_item.attributes.rate_per_unit).toFixed(2);
    total_tax = (taxLineItem.attributes.percentage_value/100) * sub_total;
    grand_total = (total_tax + sub_total) - total_discount;
    this.forceUpdate();
  }

  totalForCustomLineItem(event,custom) {
    let {
      taxStore,
      taxStore: {
        taxes: {
          objects
        }
      },
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;
    custom_line_item.attributes.markup = 1;
    custom_line_item.attributes.rate_per_unit = event.target.value;
    custom_line_item.attributes.total = parseFloat(custom_line_item.attributes.quantity).toFixed(2) * parseFloat(custom_line_item.attributes.rate_per_unit).toFixed(2);
    sub_total =  parseFloat(custom_line_item.attributes.quantity).toFixed(2) * parseFloat(custom_line_item.attributes.rate_per_unit).toFixed(2);
    let taxLineItem = _.find(objects, {attributes: {id: custom_line_item.attributes.tax_line_item_id}});
    total_tax = (taxLineItem.attributes.percentage_value/100) * sub_total;
    grand_total = (total_tax + sub_total) - total_discount;
    this.forceUpdate(); 
  }

  changeDiscount(event){
    let { 
      invoiceStore: {
        jobInvoice:{
          object
        }
      }
    } = this.props;
    total_discount = event.target.value;
    grand_total = (total_tax + sub_total) - total_discount;
    object.discount = total_discount;
    this.forceUpdate();
  }

  changeFlatRate(event){
    let { 
      invoiceStore: {
        jobInvoice
      },
      jobStore:{
        materialLineItems:{
          object
        }
      },
      taxStore: {
        taxes: {
          objects
        }
      }
    } = this.props;
    if (event.target.checked) {
      if (custom_line_item.attributes.tax_line_item_id === '') {
        const defaultTax = _.find(objects, { attributes: { is_default: true } })
        custom_line_item.attributes.tax_line_item_id = defaultTax.attributes.id;
        custom_line_item.attributes.tax_amount = (defaultTax.attributes.percentage_value / 100) * sub_total;
      }

      object.line_items.labours.map((labor) => labor.attributes.is_checked = false);
      object.line_items.materials.map((material) => material.attributes.is_checked = false);
      object.line_items.customs.map((custom) => custom.attributes.is_checked = false);
    } else {
      custom_line_item = {
        attributes: {
          description: '',
          total: '',
          tax_line_item_id: '',
          quantity: '',
          markup: '',
          rate_per_unit: '',
          tax_amount: ''
        }
      }
    }

    sub_total = 0;
    total_tax = 0;
    grand_total = 0;
    total_discount = 0;

    this.flat_rate = event.target.checked;
  }

  onChangeDescription(event){
    let { 
      jobStore:{
        materialLineItems:{
          object
        }
      }
    } = this.props;
    object.line_items.descriptions = []
    object.line_items.descriptions.push(event.target.value)
  }
  
  render() {
    let { 
      invoiceStore: {
        jobInvoice
      },
      jobStore:{
        materialLineItems:{
          object
        }
      },
      job,
      taxStore: {
        taxes
      }
    } = this.props;
    
    return (
      <Dialog
        title={`Create invoice for job # ${job.attributes.event_number}`}
        isOpen={this.props.isOpen}
        isCloseButtonShown={true}
        onClose={this.handleCloseInvoiceDailog.bind(this)}
        style={{ width: '75%', backgroundColor: '#fff' }}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="ji-popup">
            <div className="ji-popup-header">
              <div className="client-details">
                <h4>{ job.attributes.client.name } <span>Client</span></h4>
                <p>{ job.attributes.client.ship_to }</p>
                <p className="mb-0">{ job.attributes.client.phone }</p>
                <p>{ job.attributes.client.email }</p>
              </div>
              <div className="job-meta-details">
                <div className="job-details-block">
                  <p className="box-text">Job</p>
                  <div className="job-details-box">
                    <div className="drp-block">
                      <JobPicker
                        selectedOptions={toJS(object.selectedJob)}
                        onChange={this.onJobSelect.bind(this)}
                        maximumSelections={1}
                      />
                      <div className="p-2">
                        <p className="mb-2">{job.attributes.title}</p>
                        <p className="mb-0">{job.attributes.event_number}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-details-block mt-4">
                  <p className="box-text">Technician</p>
                  <div className="job-details-box">
                    <div className="drp-block">
                      <TechnicianPicker
                        fill="true"
                        selectedOptions={jobInvoice.object.technician_id || ""}
                        onChange={this.onTechnicianChange.bind(this)}
                        includeAdmins={true}
                        showCreatedBy={true}
                      />
                    </div>
                    <div className="p-2">
                      <p className="mb-2">{job.attributes.created_by.full_name}</p>
                      <p className="mb-0">{job.attributes.created_by.phone}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ji-popup-body">
              <div className="py-4 mt-4 border-top border-bottom">
                <Switch checked={this.flat_rate == true ? true: false} large label="Flat Rate Invoice" className="font-weight-bold" 
                  onChange={(event) => {this.changeFlatRate(event)} }
                />
              </div>
              { this.flat_rate == false ?
                <div>
                  <div className="item-row">
                    <h5>Labor</h5>
                    <div className="table-resp">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th width="40"></th>
                            <th width="400">Details</th>
                            <th>Rate</th>
                            <th>Hours</th>
                            <th className="text-right">Total</th>
                            <th width="100">Tax</th>
                          </tr>
                        </thead>
                        <tbody>
                          { 
                            object && object.line_items && object.line_items.labours.map((labor,index) => {
                              return(
                                <div style={{display:'contents'}} key={Math.random()}>
                                  <div style={{ display: 'block', height: 15 }}></div>
                                  <tr>
                                    <td className="pl-0 align-middle">
                                      <Switch large  checked={labor.attributes.is_checked == true ? true: false}  onChange={(event) => {this.selectLaborLineItem(event,labor.id)} }/>
                                    </td>
                                    <td className={`border-left border-top border-bottom ${labor.attributes.is_checked === true ? 'active-bg' : ''}`}>
                                      <p className="mb-1 font-weight-bold">{labor.attributes.description}</p>
                                      {/* <p>Diagnosis and repair A/C problem and repair Heater.</p> */}
                                    </td>
                                    <td className={`border-top border-bottom ${labor.attributes.is_checked === true ? 'active-bg' : ''}`}>{currencyFormattedAmount(labor.attributes.rate_per_unit)}</td>
                                    <td className={`border-top border-bottom ${labor.attributes.is_checked === true ? 'active-bg' : ''}`}>{labor.attributes.quantity}</td>
                                    <td className={`text-right border-top border-bottom ${labor.attributes.is_checked === true ? 'active-bg' : ''}`}>
                                      <span className="font-weight-bold">{currencyFormattedAmount(labor.attributes.total)}</span>
                                      <p className="mb-0"><small>{labor.attributes.tax_amount != '' ? currencyFormattedAmount(labor.attributes.tax_amount):0}</small></p>
                                    </td>
                                    <td className={`border-right border-top border-bottom ${labor.attributes.is_checked === true ? 'active-bg' : ''}`}>
                                      <select  value={labor.attributes.tax_line_item_id}
                                        onChange={(event) => {this.calculateTotalLaborTax(event,labor)}} 
                                        disabled={labor.attributes.is_checked == false ? true: false} 
                                      >
                                        <option value=''>Select Tax</option>
                                        {
                                          taxes.objects.length > 0 && taxes.objects.map((tanent_tax) => {
                                            return(
                                              <option value={tanent_tax.attributes.id} key={tanent_tax.attributes.id}>{tanent_tax.attributes.name} {tanent_tax.attributes.percentage_value}%</option>
                                            )
                                          })
                                        }
                                      </select>
                                    </td>
                                  </tr>
                                </div>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="item-row">
                    <h5>Material</h5>
                    <div className="table-resp">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th width="40"></th>
                            <th width="400">Product</th>
                            <th>Unit Price</th>
                            <th>Qty</th>
                            <th className="text-right">Total</th>
                            <th width="100">Tax</th>
                          </tr>
                        </thead>
                        <tbody>
                          { 
                            object && object.line_items && object.line_items.materials.map((material) => {
                              return(
                                <div style={{display:'contents'}}>
                                  <div style={{ display: 'block', height: 15 }}></div>
                                  <tr>
                                    <td className="pl-0 align-middle">
                                      <Switch large  checked={material.attributes.is_checked == true ? true: false}  onChange={(event) => {this.selectMaterialLineItem(event,material.id)} }/>
                                    </td>
                                    <td className={`border-left border-top border-bottom ${material.attributes.is_checked === true ? 'active-bg' : ''}`}>
                                      <p className="mb-1 font-weight-bold">{material.attributes.description}</p>
                                      {/* <p className="mb-0">Diagnosis and repair A/C problem and repair Heater.</p> */}
                                    </td>
                                    <td className={`border-top border-bottom ${material.attributes.is_checked === true ? 'active-bg' : ''}`}>{currencyFormattedAmount(material.attributes.rate_per_unit)}</td>
                                    <td className={`border-top border-bottom ${material.attributes.is_checked === true ? 'active-bg' : ''}`}>{material.attributes.quantity}</td>
                                    <td className={`text-right border-top border-bottom ${material.attributes.is_checked === true ? 'active-bg' : ''}`}>
                                      <span className="font-weight-bold">{currencyFormattedAmount(material.attributes.total)}</span>
                                      <p className="mb-0"><small>{material.attributes.tax_amount != '' ? currencyFormattedAmount(material.attributes.tax_amount):0}</small></p>
                                    </td>
                                    <td className="border-right border-top border-bottom">
                                      <select value={material.attributes.tax_line_item_id}
                                        onChange={(event) => {this.calculateTotalMaterialTax(event,material)} }
                                        disabled={material.attributes.is_checked == false ? true: false}
                                      >
                                        <option value=''>Select Tax</option>
                                        {
                                          taxes.objects.length > 0 && taxes.objects.map((tanent_tax) => {
                                            return(
                                              <option value={tanent_tax.attributes.id} key={tanent_tax.attributes.id}>{tanent_tax.attributes.name} {tanent_tax.attributes.percentage_value}%</option>
                                            )
                                          })
                                        }
                                      </select>
                                    </td>
                                  </tr>
                                </div>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="item-row">
                    <h5>Others</h5>
                    <div className="table-resp">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th width="40"></th>
                            <th width="400">Title</th>
                            <th className="text-right">Total</th>
                            <th width="100">Tax</th>
                          </tr>
                        </thead>
                        <tbody>
                          { 
                            object && object.line_items && object.line_items.customs.map((custom) => {
                              if(custom.attributes.rate_per_unit > 0){
                                return(
                                  <div style={{display:'contents'}}>
                                    <div style={{ display: 'block', height: 15 }}></div>
                                    <tr>
                                      <td className="pl-0 align-middle">
                                        <Switch large  checked={custom.attributes.is_checked == true ? true: false}  onChange={(event) => {this.selectCustomLineItem(event,custom.id)} }/>
                                      </td>
                                      <td className={`border-left border-top border-bottom ${custom.attributes.is_checked === true ? 'active-bg' : ''}`}>
                                        <p className="mb-1 font-weight-bold">{custom.attributes.description}</p>
                                        {/* <p className="mb-0">Diagnosis and repair A/C problem and repair Heater.</p> */}
                                      </td>
                                      <td className={`text-right border-top border-bottom ${custom.attributes.is_checked === true ? 'active-bg' : ''}`}>
                                        <span className="font-weight-bold">{currencyFormattedAmount(custom.attributes.total)}</span>
                                        <p className="mb-0"><small> {custom.attributes.tax_amount != '' ? currencyFormattedAmount(custom.attributes.tax_amount):0}</small></p>
                                      </td>
                                      <td className="border-right border-top border-bottom">
                                        <select value={custom.attributes.tax_line_item_id}
                                          onChange={(event) => {this.calculateTotalCustomTax(event,custom)} }
                                          disabled={custom.attributes.is_checked == false ? true: false}
                                        >
                                          <option value=''>Select Tax</option>
                                          {
                                            taxes.objects.length > 0 && taxes.objects.map((tanent_tax) => {
                                              return(
                                                <option value={tanent_tax.attributes.id} key={tanent_tax.attributes.id}>{tanent_tax.attributes.name} {tanent_tax.attributes.percentage_value}%</option>
                                              )
                                            })
                                          }
                                        </select>
                                      </td>
                                    </tr>
                                  </div>
                                )
                              }
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                :
                <div className="item-row">
                  <h5>Custom</h5>
                  <div className="table-resp">
                    <table className="table table-borderless customLineItemTable">
                      <thead>
                        <tr>
                          <th width="300">Title</th>
                          <th width="300">Quantity</th>
                          <th width="200">Total</th>
                          <th width="100">Tax</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input 
                              type='text' 
                              className='form-control' 
                              placeholder='Title'
                              onChange={(event) => {this.titleForCustomLineItem(event)} }
                            />
                          </td>
                          <td>
                            <input 
                              type='number' 
                              className='form-control' 
                              placeholder='Quantity'
                              onChange={(event) => {this.quantityForCustomLineItem(event)} }
                            />
                          </td>
                          <td>
                            <input 
                              type='number' 
                              className='form-control' 
                              placeholder='Total'
                              onChange={(event) => {this.totalForCustomLineItem(event)} }
                            />
                          </td>
                          <td>
                            <select
                              value={custom_line_item.attributes.tax_line_item_id}
                              onChange={(event) => {this.taxForCustomLineItem(event)} }
                            >
                              <option value=''>Select Tax</option>
                              {
                                taxes.objects.length > 0 && taxes.objects.map((tanent_tax) => {
                                  return(
                                    <option value={tanent_tax.attributes.id} key={tanent_tax.attributes.id}>{tanent_tax.attributes.name} {tanent_tax.attributes.percentage_value}%</option>
                                  )
                                })
                              }
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              }

              <div className="ji-total-block">
                <div className="jitb">
                  <div className="total-row">
                    <div>Subtotal</div>
                    <div><span className="font-weight-bold">{currencyFormattedAmount(sub_total)}</span></div>
                  </div>
                  {/* <div className="total-row">
                    <div>Discount</div>
                    <div>
                      <input class="bp3-input discount-input" 
                        type="number" 
                        placeholder="00.00" 
                        onChange={(event) => {this.changeDiscount(event)} }
                      />
                    </div>
                    <div> {currencyFormattedAmount(total_discount)}</div>
                  </div> */}
                  <div className="total-row">
                    <div>Tax</div>
                    <div>{currencyFormattedAmount(total_tax)}</div>
                  </div>
                  <div className="total-row">
                    <div>Grand Total</div>
                    <div><span className="font-weight-bold">{currencyFormattedAmount(grand_total)}</span></div>
                  </div>
                </div>
              </div>

              <div className="ji-notes-block">
                <div>
                  <p>Customer Notes</p>
                  <TextArea
                    growVertically={true}
                    large={true}
                    placeholder="Write down your specific notes for client."
                    fill={true}
                    onChange={this.onChangeDescription.bind(this)}
                    value={object && object.line_items && object.line_items.descriptions.map(x => x).join('\n')}
                  />
                </div>
                <div>
                  <p>Payment Terms</p>
                  <TextArea
                    growVertically={true}
                    large={true}
                    placeholder="Add your payment terms here."
                    fill={true}
                    onChange={event => {
                      jobInvoice.object.payment_terms = event.target.value;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>


        </div>
        <div className={Classes.DIALOG_FOOTER}>
          
          {/* <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            
          </div> */}
          <div className="ji-bottom-strip">
            <Button
              onClick={this.handleCloseInvoiceDailog.bind(this)}
              style={{ width: "100px" }}
            >
              Close
            </Button>
            <Button
              intent={Intent.PRIMARY}
              loading={this.props.invoiceStore.jobInvoice.isLoading}
              onClick={this.createInvoice.bind(this)}
              icon="add"
            >
              Create Invoice For {currencyFormattedAmount(grand_total)}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}
export default InvoiceJobModal;