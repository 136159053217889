import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import SearchBars from 'components/SearchBars';
import SearchBarWrapper from 'components/SearchBarWrapper';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import ClientsTable from 'components/Tables/Clients';
import Page from 'components/Page';
import Card from 'components/Card';
import Sticky from 'react-stickynode';
import styled from '@emotion/styled';
import ClientsSideBar from 'components/Client/ClientsSideBar';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 5px 5px 0px 5px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);  
`

@inject('store', 'clientStore') @observer
class ClientsIndexPage extends Component {

  @observable visible = true;

  render() {
    let content;
    let {
      clientStore: {
        clients: {
          isLoading,
          isError
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (!isError && !isLoading) {
      content = <ClientsTable />
    }
    return (
      <div>
        <Helmet>
          <title>Clients | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters"> 
          <div className="col-lg-2 sidebar-responsive">
            <ClientsSideBar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
            <Sticky enabled={true} top={0} innerZ={9}>
              <SearchBarWrapper>
                <SearchBars.Client />
              </SearchBarWrapper>
            </Sticky>
            <Page>
              <Card>
                {content}
              </Card>
            </Page>
            </PageWrapGray>
          </div>
        </div>
      </div>
      
    )
  }

};

export default ClientsIndexPage;