import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { Select } from "@blueprintjs/select";
import { Button, MenuItem } from '@blueprintjs/core';

@inject('billingStore', 'viewStore') @observer
class PaymentTransactions extends Component {

  handleTransactionClick(event, transaction) {
    switch (event.label) {
      case 'View':
        this.props.viewStore.ShowInvoice({ uuid: transaction.attributes.invoice_id })
        break;
      default:
    }
  }

  render() {
    let { objects } = this.props;
    return(
      <div className="card rounded-0">
      <table className="table table-hover" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th width="10%">Invoice #</th>
            <th>Transaction Id</th>
            <th>Transaction Date</th>
            <th>Amount Paid</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          { objects.map((transaction) => {
              return (
                <tr key={transaction.attributes.id}>
                  <td width="10%">{transaction.attributes.invoice_number}</td>
                  <td>{transaction.attributes.transaction_id}</td>
                  <td>{transaction.attributes.transaction_date_formatted}</td>
                  <td>${transaction.attributes.amount_paid}</td>
                  <td>{transaction.attributes.status}</td>
                  <td>
                    <Select
                      filterable={false}
                      items={[ { label: 'View', icon: 'edit', intent: 'default' }]}
                      itemRenderer={(item) => { return (<MenuItem text={item.label} key={item.label} onClick={() => this.handleTransactionClick(item, transaction)} icon={item.icon} intent={item.intent} />) }}
                      onItemSelect={(item) => {  }}
                    >
                      <Button text={'Actions'} rightIcon='caret-down' className='bp3-minimal' small={true} />
                    </Select>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      </div>
    )
  }

};

export default PaymentTransactions;