import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import Card from 'components/Card';
import { 
  ButtonGroup, Button, NonIdealState, Spinner
} from "@blueprintjs/core";
import TaxesTable from 'components/Tables/Taxes';
import TaxModal from 'components/Taxes/TaxModal';
import SettingsSidebar from '../preferences/settings-sidebar';
import TaxQuickBookModal from 'components/Taxes/TaxQuickBookModal';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'taxStore', 'quickbooksStore','xeroStore') @observer
class TaxesIndexPage extends Component {
  @observable isTaxQuickDialogVisible = false;
  @observable isTaxXeroDialogVisible = false;
  @observable isTaxDialogVisible = false;

  componentDidMount() {
    let { taxStore } = this.props;
    taxStore.loadTaxes()
  }

  get canRender() {
    let {
      taxStore: {
        taxes: {
          isLoading,
          isError,
          objects
        }
      }
    } = this.props;
    return (!isLoading && !isError && objects);
  }
  
  onCloseTaxDialog() {
    this.isTaxDialogVisible = false;
  }

  onCloseTaxQuickDialog() {
    this.isTaxQuickDialogVisible = false;
  }

  onSucessCreateTax() {
    this.isTaxDialogVisible = false;
  }

  openTaxModal() {
    this.isTaxDialogVisible = true
    this.props.quickbooksStore.loadQuickbooksTaxCodes()
    this.props.xeroStore.loadXeroTaxCodes()
  }

  onSucessUpdateTaxQuick() {
    this.isTaxQuickDialogVisible = true;
  }

  render() {
    let content;
    let {
      taxStore: {
        taxes: {
          isLoading,
          isError
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (this.canRender) {
      content = <TaxesTable />;
    }
    return(
      <div className="taxed-page-layout">
        <Helmet>
          <title>Taxes | FieldNexus</title>
        </Helmet>
        <div className="row no-gutters">
          <div className="col-lg-2 sidebar-responsive">
            <SettingsSidebar />
          </div>
          <div className="col-lg-10">
            <PageWrapGray>
            <Card>

              <div>            
                <div className="text-right mb-2">
                  <ButtonGroup>
                    <Button icon='add' onClick={this.openTaxModal.bind(this)}>
                      Add
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
          
              {content}
          
            </Card>
            </PageWrapGray>
          </div>
        </div>
        <TaxModal 
          isOpen={this.isTaxDialogVisible} 
          title={"Add Tax"}
          edit={false}
          onClose={this.onCloseTaxDialog.bind(this)}
          onSuccess={this.onSucessCreateTax.bind(this)}
        />
        <TaxQuickBookModal 
          isOpen={this.isTaxQuickDialogVisible} 
          title={"Add Tax to QuickBooks"}
          edit={true}
          onClose={this.onCloseTaxQuickDialog.bind(this)}
          onSuccess={this.onSucessUpdateTaxQuick.bind(this)}
        />
      </div>
    )
  }

};

export default TaxesIndexPage;