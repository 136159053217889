import { observable, action } from 'mobx';
import { Api } from 'services/Api';
import stores from './index';

const EMPTY_SERVICE_AGREEMENT = {
  isLoading: false,
  isError: false,
  object: {
    client_id: null,
    title: null,
    reason_for_call: null,
    service_type_id: null,
    selectedServiceType: [],
    locations: [],
    client: null,
    rrule: ''
  }
};

class ServiceAgreementStore {

  @observable persistServiceAgreement = EMPTY_SERVICE_AGREEMENT;
  @observable showLoader = false;
  
  @observable serviceAgreements = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable clientServiceAgreements = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable serviceAgreement = {
    isLoading: false,
    isError: false,
    object: null
  };

  @action
  clearServiceAgreement() {
    this.persistServiceAgreement = EMPTY_SERVICE_AGREEMENT;
  }

  @action
  loadClientServiceAgreements() {
    this.clientServiceAgreements.isLoading = true;
    const {
      where,
      per_page,
      page,
      order
    } = stores.viewStore.clientServiceAgreementSearch;
    let promise = Api.post(`/service_agreements/search`,
      {
        search: {
          where: where,
          order: order,
          per_page: per_page,
          page: page
        }
      }
    );

    promise
      .then((response) => {
        this.clientServiceAgreements.isLoading = false;
        this.clientServiceAgreements.isError = false;
        this.clientServiceAgreements.objects = response.data.data
        this.clientServiceAgreements.meta = response.data.meta;
      })
      .catch((error) => {
        this.clientServiceAgreements.isLoading = false;
        this.clientServiceAgreements.isError = true;
      })

    return promise;
  }
  

  @action
  loadServiceAgreements() {
    this.serviceAgreements.isLoading = true;
    const {
      term,
      where,
      per_page,
      page,
      order
    } = stores.viewStore.serviceAgreementSearch;
    let promise = Api.post(`/service_agreements/search`,
      {
        search: {
          term: term,
          where: where,
          order: order,
          per_page: per_page,
          page: page
        }
      }
    );

    promise
      .then((response) => {
        this.serviceAgreements.isLoading = false;
        this.serviceAgreements.isError = false;
        this.serviceAgreements.objects = response.data.data
        this.serviceAgreements.meta = response.data.meta;
      })
      .catch((error) => {
        this.serviceAgreements.isLoading = false;
        this.serviceAgreements.isError = true;
      })

    return promise;
  }

  @action
  create() {
    this.persistServiceAgreement.isLoading = true;
    let promise = Api.post(`/service_agreements/`, {service_agreement: this.persistServiceAgreement.object})

     promise
      .then((response) => {
        this.persistServiceAgreement.isLoading = false;
        this.persistServiceAgreement.isError = false;
        stores.store.isOpen = false;
        this.clearServiceAgreement();
        stores.viewStore.serviceAgreementShow({uuid: response.data.data.id}, response.data.data.attributes.number)
      })
      .catch((error) => {
        this.persistServiceAgreement.isLoading = false;
        this.persistServiceAgreement.isError = true;
      })

    return promise;
  }

  @action
  update() {
    this.persistServiceAgreement.isLoading = true;
    let promise = Api.put(`/service_agreements/${this.persistServiceAgreement.object.id}`, {service_agreement: this.persistServiceAgreement.object})

     promise
      .then((response) => {
        this.persistServiceAgreement.isLoading = false;
        this.persistServiceAgreement.isError = false;
        this.serviceAgreement.object = response.data.data;
        stores.store.isOpen = false;
        this.clearServiceAgreement()
      })
      .catch((error) => {
        this.persistServiceAgreement.isLoading = false;
        this.persistServiceAgreement.isError = true;
      })

    return promise;
  }

  @action
  loadServiceAgreement(params) {
    this.serviceAgreement.isLoading = true;
    let promise = Api.get(`/service_agreements/${params.uuid}`)

     promise
      .then((response) => {
        this.serviceAgreement.isLoading = false;
        this.serviceAgreement.isError = false;
        this.serviceAgreement.object = response.data.data;
        stores.viewStore.serviceAgreementShow(params,response.data.data.attributes.number);
      })
      .catch((error) => {
        this.serviceAgreement.isLoading = false;
        this.serviceAgreement.isError = true;
      })

    return promise;
  }

  @action
  loadServiceAgreementForBooking(params) {
    this.serviceAgreement.isLoading = true;
    let promise = Api.get(`/service_agreements/${params.uuid}`)

     promise
      .then((response) => {
        this.serviceAgreement.isLoading = false;
        this.serviceAgreement.isError = false;
        this.serviceAgreement.object = response.data.data;
        //stores.viewStore.serviceAgreementShow(params,response.data.data.attributes.number);
      })
      .catch((error) => {
        this.serviceAgreement.isLoading = false;
        this.serviceAgreement.isError = true;
      })

    return promise;
  }

  @action
  changeStatus(status) {
    this.showLoader = true;
    let promise = Api.post(`/service_agreements/${this.serviceAgreement.object.id}/change_status`, {event: status})
    
    promise
      .then((response) => {   
        this.showLoader = false;
        this.serviceAgreement.object = response.data.data;
      })
      .catch((error) => {
        this.showLoader = false;
      })

    return promise;
  }

};

export default ServiceAgreementStore;