import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { FormGroup, ControlGroup, Dialog, Classes, Button, Intent } from '@blueprintjs/core';
import DateTimeInput from 'components/DateTimeInput';
import moment from 'moment';
import Toast from 'helpers/toaster';

@inject('userStore') @observer
class VacationModal extends React.Component {

  @observable minDateStart = moment().subtract(5, 'month');

  persistVacation() {
    const {
      userStore,
      userStore: {
        vacation: {
          object
        }
      },
      user
    } = this.props;
    let promise;

    if(this.props.vacation && this.props.vacation.id) {
      promise = userStore.updateUserVacation()
    } else {
      object.user_id = user.id;
      promise = userStore.createUserVacation()
    }

    promise
      .then(this.onSuccessfullCreateOrUpdateVacation.bind(this))
      .catch(this.onFailureCreateOrUpdateVacation.bind(this))
  }

  onSuccessfullCreateOrUpdateVacation(response) {
    this.props.onClose()
  }

  onFailureCreateOrUpdateVacation(error) {
    Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
  }

  handleCloseVacationDailog() {
    this.props.onClose()
  }

  handleStartDateChange(time) {
    let { object } = this.props.userStore.vacation;
    if(time) {
      object.start_date = moment(time)
    }
  }

  handleEndDateChange(time) {
    let { object } = this.props.userStore.vacation;
    if(time) {
      object.end_date = moment(time)
    }
  }

  render() {
    const {
      isOpen,
      userStore: {
        vacation: {
          isLoading,
          object
        }
      }
    } = this.props;
    return(
      <Dialog
        title={this.props.vacation ? "Update Vacation" : "Add Vacation"}
        isOpen={isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="When?"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <ControlGroup fill={true} vertical={false}>
              <DateTimeInput 
                placeholder={'Start Date'} 
                defaultValue={moment(object.start_date)} 
                onChange={this.handleStartDateChange.bind(this)} />
              
              <DateTimeInput 
                placeholder={'End Date'}
                defaultValue={moment(object.end_date)} 
                onChange={this.handleEndDateChange.bind(this)} />
            </ControlGroup>
          </FormGroup>

          <FormGroup
            label="Notes"
            labelFor="text-input"
          >
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Notes'
              defaultValue={object.notes}
              onChange={(event) => { object.notes = event.target.value } } 
            />
          </FormGroup>

          {this.props.vacation && <FormGroup
            label="Status"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <div className="bp3-select bp3-fill">
              <select defaultValue={object.status} onChange={(event) => {object.status = event.target.value} }>
                <option value=''>Select Status</option>
                <option value='approved'>Approved</option>
                <option value='rejected'>Rejected</option>
              </select>
            </div>
          </FormGroup>}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCloseVacationDailog.bind(this)}>Close</Button>
            <Button intent={Intent.PRIMARY} loading={isLoading} onClick={this.persistVacation.bind(this)}>{this.props.vacation ? 'Update' : 'Save'}</Button>
          </div>
        </div>
      </Dialog>
    )
  }

};

export default VacationModal;