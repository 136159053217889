import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from 'mobx';
import { 
  Divider, Intent,
  Button,
  FormGroup
} from '@blueprintjs/core';
import Card from 'components/Card';
import styled from '@emotion/styled';
import Toast from "helpers/toaster";
import SettingsSidebar from '../preferences/settings-sidebar';
import moment from 'moment';
import { TimePicker, DateTimePicker } from "@blueprintjs/datetime";

const CardContent = styled('div')`
  padding: ${props => `${props.theme.unit*2}px`};
  background-color:#ffffff;
`

const PageWrapGray = styled('div')`
  padding: 12px;
  background-color: #F6F6FA;
  min-height: calc(100vh - 75px);  
`


var times = [];
const weekArray = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

@inject("spotStore")
@observer
class SpotAddPage extends Component {
  @observable slots = [
    {
      day: '',
      start_time: '',
      end_time: ''
    }
  ];

  applyTimeSlot(days){
    let {
      spotStore,
      spotStore: {
        spot: {
          object,
          isLoading
        }
      }
    } = this.props;
    var startTime = new moment(object.start_time).format('HH:mm');
    var endTime = new moment(object.end_time).format('HH:mm');
    this.getTimeStops(startTime, endTime,days);
  }

  applyTimeSlotForAll(days){
    let {
      spotStore,
      spotStore: {
        spot: {
          object,
          isLoading
        }
      }
    } = this.props;
    var startTime = new moment(object.start_time).format('HH:mm');
    var endTime = new moment(object.end_time).format('HH:mm');
    this.getTimeStops(startTime, endTime,days);
  }

  incrementCount() {
    this.slots = this.slots.concat([
      {
        day: '',
        start_time: '',
        end_time: ''
      }
    ])
  }

  createUI(){
    let {
      spotStore,
      spotStore: {
        spot: {
          object,
          isLoading
        }
      }
    } = this.props;
    return object && object.booking_slots.length > 0 && object.booking_slots.map((i) => (
      <div>
        <input placeholder="First Name" name="firstName"  />
        <input placeholder="Last Name" name="lastName"  />
        <input type='button' value='remove' />
      </div>          
    ))
  }


  
  getTimeStops(start, end,days){
    let {
      spotStore,
      spotStore: {
        spot: {
          object,
          isLoading
        }
      }
    } = this.props;
    var startTime = moment(start, 'HH:mm');
    var endTime = moment(end, 'HH:mm');

    if( endTime.isBefore(startTime) ){
      endTime.add(1, 'day');
    }
    var times = [];
    var timeStops = [];
    var i = 0;
    while(startTime <= endTime){
      timeStops.push(new moment(startTime).format('HH:mm a'));
      startTime.add(120, 'minutes');
    }
    var count = timeStops.length;
    if(count > 0){
      for(var i=0;i <= count-1;i++){
        var j = i+1;
        if(timeStops[j]){
          times.push({days:days,slots:timeStops[i]+' - '+timeStops[j]});
        }
      }
    }
    object.booking_slots = times;
  }

  

  render() {
    let {
      spotStore,
      spotStore: {
        spot: {
          object,
          isLoading
        }
      }
    } = this.props;
    //this.getTimeStops('8:00', '17:00')
    return (
      <div className="row no-gutters">
        <div className="col-lg-2 sidebar-responsive">
          <SettingsSidebar />
        </div>
        <div className="col-lg-10">
          <PageWrapGray>
            <div className="pricing-parameter-table">
              <Card>
                <CardContent>
                  <div>
                    <div className="left-side-panel-servie-type">
                      <h4 className={'bp3-heading'} style={{ marginTop: '8px' }}>
                        Add Booking Spots
                      </h4>
                    </div>
                  </div>
                  <Divider style={{ margin: '16px 0px' }}></Divider>
                  <div className="card rounded-0 table-responsive">
                    <table className="table table-hover" style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th>Days</th>
                          <th>Booking Spots </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          weekArray.map((days,index) => {
                            var i = index;
                            return (
                              <tr key={days}>
                                <td>{days}
                                  <div className="row">
                                    <div className="col-md-4">
                                      <FormGroup
                                        label="Start Time"
                                        labelFor="text-input"
                                        labelInfo=""
                                      >
                                        <TimePicker 
                                          useAmPm={false}
                                          onChange={(date) => {object.start_time = moment(date).toDate()}}
                                         
                                        />
                                      </FormGroup>
                                    </div>

                                    <div className="col-md-4">
                                      <FormGroup
                                        label="End Time"
                                        labelFor="text-input"
                                        labelInfo=""
                                      >
                                        <TimePicker 
                                          useAmPm={false}
                                          onChange={(date) => {object.end_time = moment(date).toDate()}}
                                          //value={object.end_time}
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {
                                    object && object.booking_slots.length > 0 && object.booking_slots.map((bookingSlot) => {
                                      if(bookingSlot.days === days){
                                        return (
                                          <div>{bookingSlot.slots}</div>
                                        )
                                      }
                                      if(bookingSlot.days === 'All'){
                                        return (
                                          <div>{bookingSlot.slots}</div>
                                        )
                                      }
                                    })
                                    
                                  }
                                 
                                  
                                </td>
                                <td>
                                  <button onClick={this.applyTimeSlot.bind(this,days)} className="confirm-btn">Apply</button><br/>
                                  <button style={{ marginTop: '16px' }} onClick={this.applyTimeSlotForAll.bind(this,'All')} className="confirm-btn">Apply For All</button>
                                </td>
                              </tr> 
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
        
                </CardContent>
              </Card>
            </div>
          </PageWrapGray>
        </div>
      </div>
    );
  }
}

export default SpotAddPage;
